import { useMemo } from "react";
import { RejectionReason } from "../../../../../../../redux/apiSpecifications/apiCrud";
import { RejectPracticeFormModel } from "./useRejectPracticeForm";
import { SelectPair } from "../../../../../../../types/common.types";
import { useTranslation } from "react-i18next";
import { FormRow } from "../../../../../../../style/form";
import { SelectStyled } from "../../../../../../../style/Input";
import { Input } from "antd";

interface IRejectPracticeFormProps {
    model: RejectPracticeFormModel;
    allRejectionReasons: RejectionReason[];
    onChange: (value: any, field: string) => void;
}

const RejectPracticeForm = ({
    model,
    allRejectionReasons,
    onChange,
}: IRejectPracticeFormProps) => {
    const { t } = useTranslation();

    const rejectReasonOptions = useMemo(() => {
        const rejectReasonOptionsArray: SelectPair[] = [];

        if (allRejectionReasons) {
            allRejectionReasons.forEach((reason) => {
                rejectReasonOptionsArray.push({
                    value: reason.rejectionReasonCode,
                    label: t(
                        `rejection-reason-description-${reason.rejectionReasonCode}`
                    ),
                });
            });
        }

        return rejectReasonOptionsArray;
    }, [allRejectionReasons]);

    return (
        <>
            <FormRow>
                <SelectStyled
                    label={`${t("reject-practice-form-reject-reason-label")} *`}
                    tooltip={t("reject-practice-form-reject-reason-tooltip")}
                    onChange={(rejectionReasonCode) =>
                        onChange(rejectionReasonCode, "rejectionReasonCode")
                    }
                    value={model.rejectionReasonCode}
                    options={rejectReasonOptions}
                />
            </FormRow>

            <FormRow>
                <Input.TextArea
                    maxLength={1000}
                    onChange={(e) =>
                        onChange(e.target.value, "rejectionReasonNote")
                    }
                    value={model.rejectionReasonNote ?? ""}
                    className="note-input"
                    placeholder={
                        t(
                            "reject-practice-form-reject-reason-note-placeholder"
                        )!
                    }
                />
            </FormRow>
        </>
    );
};

export default RejectPracticeForm;
