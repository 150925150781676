import { useTranslation } from "react-i18next";
import {
    AppraiserUser,
    ManagementTool,
    TrustCompany,
} from "../../../../../redux/apiSpecifications/apiFesf";
import styled from "styled-components";
import { FormRow } from "../../../../../style/form";
import { InputTextStyled } from "../../../../../style/Input";
import { RowSpacer } from "../../../../../style/containers";
import SelectWithAddNew from "../../../../Inputs/SelectWithAddNew";
import { useMemo, useRef } from "react";
import { IconDelete } from "../../../../../config/icons";
import { mainTabs } from "../../../../../config/testIds";

const testIds = mainTabs.tabs.supplierNetwork.appraiserDetails.additionalInfo;

const AdditionalInfoWrapper = styled.div`
    margin-top: 2em;
    padding-top: 2em;
    border-top: 1px solid #eee;
    width: 90%;
    max-width: 80em;

    .dummy-input {
        width: 1px;
        height: 1px;
        opacity: 0;
    }
`;

const ToolsAndTrustsWrapper = styled.div`
    flex: 1;
    display: flex;
    flex.direction: row;
    gap: 2em;
    padding-top: 2em;

    .col-management,
    .col-trusts {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .title {
        font-size: 12.6px;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: 5px;
    }

    .select {
        display: flex;
        gap: 1em;
        margin-bottom: 1em;

        .delete-icon-button {
            font-size: 1.6em;
            color: red;
            svg {
                cursor: pointer;
            }
        }

        .empty-icon {
            height: 1em;
            width: 1em;
        }
    }
`;

interface IAppraiserDetailsSubjectAdditionalInfoProps {
    appraiser: AppraiserUser | undefined;
    onChange: (appraiser: AppraiserUser) => void;
}

const AppraiserDetailsSubjectAdditionalInfo = (
    props: IAppraiserDetailsSubjectAdditionalInfoProps
) => {
    const { t } = useTranslation();
    const { appraiser, onChange } = props;
    const {
        managementTools = [],
        trustCompanies = [],
        availableManagementTools = [],
        availableTrustCompanies = [],
    } = appraiser || {};

    const dummyRef = useRef<HTMLInputElement>(null);

    const handleOnChange = (value: any, field: string) => {
        const newAppraiser = { ...appraiser, [field]: value };
        onChange(newAppraiser);
    };

    const handleOnSelectManagementTool = (
        index: number,
        idAvailable: number
    ) => {
        let updatedManagementTools: ManagementTool[] = [];

        // set an existant management tool slot
        if (index < managementTools.length) {
            updatedManagementTools = managementTools.map((item, i) =>
                i === index
                    ? availableManagementTools.find(
                          (item) => item.id === idAvailable
                      )!
                    : item
            );
        }
        // set the first slot in an empty array
        else if (index === 0 && managementTools.length === 0) {
            updatedManagementTools = [
                availableManagementTools.find(
                    (item) => item.id === idAvailable
                )!,
            ];
        }
        // add another management tool slot
        else if (index === managementTools.length) {
            updatedManagementTools = [
                ...managementTools,
                availableManagementTools.find(
                    (item) => item.id === idAvailable
                )!,
            ];
        }
        handleOnChange(updatedManagementTools, "managementTools");
    };

    const handleOnSelectTrustCompany = (index: number, idAvailable: number) => {
        let updatedTrustCompanies: TrustCompany[] = [];

        // set an existant trust Companies slot
        if (index < trustCompanies.length) {
            updatedTrustCompanies = trustCompanies.map((item, i) =>
                i === index
                    ? availableTrustCompanies.find(
                          (item) => item.id === idAvailable
                      )!
                    : item
            );
        }
        // set the first slot in an empty array
        else if (index === 0 && trustCompanies.length === 0) {
            updatedTrustCompanies = [
                availableTrustCompanies.find(
                    (item) => item.id === idAvailable
                )!,
            ];
        }
        // add another trust Companiesl slot
        else if (index === trustCompanies.length) {
            updatedTrustCompanies = [
                ...trustCompanies,
                availableTrustCompanies.find(
                    (item) => item.id === idAvailable
                )!,
            ];
        }
        handleOnChange(updatedTrustCompanies, "trustCompanies");
    };

    const handleOnAddNewManagementTool = (value: string) => {
        const newItem = { id: -Date.now(), description: value };

        const updatedAvailableManagementTools = [
            ...availableManagementTools,
            newItem,
        ];
        handleOnChange(
            updatedAvailableManagementTools,
            "availableManagementTools"
        );

        const updatedManagementTools = [...managementTools, newItem];
        handleOnChange(updatedManagementTools, "managementTools");

        dummyRef.current?.focus();
    };
    const handleOnAddNewTrustCompany = (value: string) => {
        const newItem = { id: -Date.now(), description: value };

        const updatedAvailableTrustCompanies = [
            ...availableTrustCompanies,
            newItem,
        ];
        handleOnChange(
            updatedAvailableTrustCompanies,
            "availableTrsustCompanies"
        );

        const updatedTrustCompaniie = [...trustCompanies, newItem];
        handleOnChange(updatedTrustCompaniie, "trustCompanies");

        dummyRef.current?.focus();
    };

    const handleOnDeleteManagementTool = (index: number) => {
        const updatedManagementTools = managementTools.filter(
            (_, i) => i !== index
        );
        handleOnChange(updatedManagementTools, "managementTools");
    };

    const handleOnDeleteTrustCompany = (index: number) => {
        const updatedTrustCompany = trustCompanies.filter(
            (_, i) => i !== index
        );
        handleOnChange(updatedTrustCompany, "trustCompanies");
    };

    return (
        <AdditionalInfoWrapper data-testid={testIds.container}>
            <FormRow>
                <InputTextStyled
                    label={`${t(
                        "edit-expertizer-personal-data-registration-code-label"
                    )}`}
                    tooltip={t(
                        "edit-expertizer-personal-data-registration-code-tooltip"
                    )}
                    placeholder={t(
                        "edit-expertizer-personal-data-registration-code-placeholder"
                    )}
                    onChange={(registrationCode) =>
                        handleOnChange(registrationCode, "registrationCode")
                    }
                    value={appraiser?.registrationCode}
                    dataTestId={""}
                />

                <RowSpacer />
                
                <InputTextStyled
                    label={`${t(
                        "edit-expertizer-personal-data-sap-code-label"
                    )}`}
                    tooltip={t(
                        "edit-expertizer-personal-data-sap-code-tooltip"
                    )}
                    placeholder={t(
                        "edit-expertizer-personal-data-sap-code-placeholder"
                    )}
                    onChange={(sapCode) => handleOnChange(sapCode, "sapCode")}
                    value={appraiser?.sapCode}
                    dataTestId={""}
                />
            </FormRow>
            
            <FormRow>
                <ToolsAndTrusts
                    availableManagementTools={availableManagementTools || []}
                    availableTrustCompanies={availableTrustCompanies || []}
                    managementTools={managementTools || []}
                    trustCompanies={trustCompanies || []}
                    onSelectManagementTool={handleOnSelectManagementTool}
                    onSelectTrustCompany={handleOnSelectTrustCompany}
                    onAddNewManagementTool={handleOnAddNewManagementTool}
                    onAddNewTrustCompany={handleOnAddNewTrustCompany}
                    onDeleteManagementTool={handleOnDeleteManagementTool}
                    onDeleteTrustCompany={handleOnDeleteTrustCompany}
                />
            </FormRow>
            
            <input ref={dummyRef} className="dummy-input" />
        </AdditionalInfoWrapper>
    );
};

interface IToolsAndTrustsProps {
    managementTools: ManagementTool[];
    trustCompanies: TrustCompany[];
    availableManagementTools: ManagementTool[];
    availableTrustCompanies: TrustCompany[];
    onSelectManagementTool: (index: number, indexAvailable: number) => void;
    onSelectTrustCompany: (index: number, indexAvailable: number) => void;
    onAddNewManagementTool: (value: string) => void;
    onAddNewTrustCompany: (value: string) => void;
    onDeleteManagementTool: (index: number) => void;
    onDeleteTrustCompany: (index: number) => void;
}

const ToolsAndTrusts = (props: IToolsAndTrustsProps) => {
    const { t } = useTranslation();
    const {
        onSelectManagementTool,
        onSelectTrustCompany,
        onAddNewManagementTool,
        onAddNewTrustCompany,
        onDeleteManagementTool,
        onDeleteTrustCompany,
    } = props;

    const managementTools = useMemo(
        () => [
            ...props.managementTools,
            {
                id: 0,
                description: "",
            } as ManagementTool,
        ],
        [props.managementTools]
    );

    const trustCompanies = useMemo(
        () => [
            ...props.trustCompanies,
            {
                id: 0,
                description: "",
            } as TrustCompany,
        ],
        [props.trustCompanies]
    );

    const availableManagementTools = useMemo(
        () =>
            props.availableManagementTools
                .filter(
                    (item) =>
                        !props.managementTools.find((i) => i.id === item.id)
                )
                .map((item) => ({
                    value: item.id,
                    label: item.description || "",
                })),
        [props.availableManagementTools, props.managementTools]
    );

    const availableTrustCompanies = useMemo(
        () =>
            props.availableTrustCompanies
                .filter(
                    (item) =>
                        !props.trustCompanies.find((i) => i.id === item.id)
                )
                .map((item) => ({
                    value: item.id,
                    label: item.description || "",
                })),
        [props.availableTrustCompanies, props.trustCompanies]
    );

    return (
        <ToolsAndTrustsWrapper>
            <div className="col-management">
                <div className="title">
                    {t(
                        "edit-expertizer-additional-info-management-tools-label"
                    )}
                </div>
                {managementTools.map((item, index) => (
                    <div className="select" key={index}>
                        <SelectWithAddNew
                            items={availableManagementTools}
                            onSelect={(idAvailable) =>
                                onSelectManagementTool(index, idAvailable)
                            }
                            onAddNew={onAddNewManagementTool}
                            value={item.description || ""}
                        />

                        <div
                            className="delete-icon-button"
                            onClick={() => onDeleteManagementTool(index)}
                        >
                            {index < managementTools.length - 1 ? (
                                <IconDelete />
                            ) : (
                                <div className="empty-icon"> </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <div className="col-trusts">
                <div className="title">
                    {t("edit-expertizer-additional-info-trust-companies-label")}
                </div>

                {trustCompanies.map((item, index) => (
                    <div className="select" key={index}>
                        <SelectWithAddNew
                            items={availableTrustCompanies}
                            onSelect={(idAvailable) =>
                                onSelectTrustCompany(index, idAvailable)
                            }
                            onAddNew={onAddNewTrustCompany}
                            value={item.description || ""}
                        />

                        <div
                            className="delete-icon-button"
                            onClick={() => onDeleteTrustCompany(index)}
                        >
                            {index < trustCompanies.length - 1 ? (
                                <IconDelete />
                            ) : (
                                <div className="empty-icon"> </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {/* JUST FOR TESTING PURPOUSE */}
            <div style={{ display: "none" }}>
                <button
                    data-testid={testIds.buttonSetManagementTool}
                    onClick={() => onSelectManagementTool(1, 1)}
                ></button>
                <button
                    data-testid={testIds.buttonAddManagementTool}
                    onClick={() => onAddNewManagementTool("test")}
                ></button>
                <button
                    data-testid={testIds.buttonSetTrustCompany}
                    onClick={() => onSelectTrustCompany(1, 1)}
                ></button>
                <button
                    data-testid={testIds.buttonAddTrustCompany}
                    onClick={() => onAddNewTrustCompany("test")}
                ></button>
            </div>
        </ToolsAndTrustsWrapper>
    );
};

export default AppraiserDetailsSubjectAdditionalInfo;
