import enText from "./en.json";
import itText from "./it.json";
import emptyText from "./empty.json";

const Translations = {
	en: { translation: enText },
	it: { translation: itText },
	empty: { translation: emptyText },
};

export default Translations;
