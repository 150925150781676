import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import styled from "styled-components";

const Legend = styled.table`
    width: 200px;
`;

const LegendRow = styled.tr`
    td:first-child {
        width: 20px;
    }
`;

const Circle = styled.span<{ color: string }>`
    border-radius: 50%/50%;
    float: left;
    height: 16px;
    width: 16px;
    margin-right: 5px;
    margin-left: 0;
    border: 1px solid ${(props) => props.color};
    background-color: ${(props) => props.color};
`;

type LegendItem = {
    requestCategoryCode: string;
    color: string;
};

const legendList: LegendItem[] = [
    { requestCategoryCode: "AD", color: "#FFD740" },
    { requestCategoryCode: "ATI", color: "#26A69A" },
    { requestCategoryCode: "CIL", color: "#FF7043" },
    { requestCategoryCode: "API", color: "#D81B60" },
    { requestCategoryCode: "APN", color: "#448AFF" },
    { requestCategoryCode: "RAC", color: "#7FC782" },
    { requestCategoryCode: "RA", color: "#FFA726" },
    { requestCategoryCode: "RCTVP", color: "#4CAF50" },
    { requestCategoryCode: "RCTVN", color: "#42A5F5" },
    { requestCategoryCode: "RPAPN", color: "#A37666" },
    { requestCategoryCode: "S", color: "#FF5252" },
    { requestCategoryCode: "X", color: "#B0BEC5" },
];

const RequestsChartLegend = () => {
    const { t } = useTranslation();

    return (
        <Popover
            placement="leftTop"
            content={
                <Legend>
                    {legendList.map((item) => (
                        <LegendRow key={item.requestCategoryCode}>
                            <td>
                                <span>
                                    <Circle color={item.color} />
                                </span>
                            </td>
                            <td>
                                {
                                    t(
                                        `practice-request-category-description-${item.requestCategoryCode}`
                                    )!
                                }
                            </td>
                        </LegendRow>
                    ))}
                </Legend>
            }
        >
            <AiOutlineQuestionCircle size={20} />
        </Popover>
    );
};

export default RequestsChartLegend;
