import { useTranslation } from "react-i18next";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import { useMemo } from "react";

type RegisteredLetterSentDescriptionDetails = {
    registered_letter_result: string;
    registered_letter_type: string;
    receiver_role: string;
    receiver_name: string;
    receiver_address: string;
};

interface IRegisteredLetterSentDescriptionProps {
    modifyDetailsJson: Modifydetail[];
}

const RegisteredLetterSentDescription = ({
    modifyDetailsJson,
}: IRegisteredLetterSentDescriptionProps) => {
    const { t } = useTranslation();

    const registeredLetterSentDescriptionDetails: RegisteredLetterSentDescriptionDetails =
        useMemo(() => {
            const registered_letter_type = modifyDetailsJson.find(
                (_) => _.key === "registered_letter_type"
            );

            const receiver_role = modifyDetailsJson.find(
                (_) => _.key === "receiver_role"
            );

            return {
                registered_letter_result:
                    modifyDetailsJson.find(
                        (_) => _.key === "registered_letter_result"
                    )?.value ?? "",
                registered_letter_type: registered_letter_type
                    ? t(`communication_type-${registered_letter_type.value}`)
                    : "",
                receiver_role: receiver_role
                    ? t(`communication_receiver_role-${receiver_role.value}`)
                    : "",
                receiver_name:
                    modifyDetailsJson.find((_) => _.key === "receiver_name")
                        ?.value ?? "",
                receiver_address:
                    modifyDetailsJson.find((_) => _.key === "receiver_address")
                        ?.value ?? "",
            } as RegisteredLetterSentDescriptionDetails;
        }, [modifyDetailsJson]);

    return (
        <>
            {t(
                `historical-actions-registered-letter-sent-${registeredLetterSentDescriptionDetails.registered_letter_result}-description`,
                {
                    registered_letter_type:
                        registeredLetterSentDescriptionDetails.registered_letter_type,
                    receiver_role:
                        registeredLetterSentDescriptionDetails.receiver_role,
                    receiver_address:
                        registeredLetterSentDescriptionDetails.receiver_address,
                }
            )}
        </>
    );
};

export default RegisteredLetterSentDescription;
