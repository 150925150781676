import styled from "styled-components";
import { ButtonConfirm } from "../../../Layout/Buttons";
import { Popover } from "antd";
import { AiFillExclamationCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const ResumeInfoWrapper = styled.div`
    border-radius: 0.5em;
    background-color: #fff;
    padding: 0.75em 0;
    margin-bottom: 1em;
    display: flex;
    justify-content: space-around;
    gap: 2em;
    box-shadow: 0 0 2px #aaa;

    .field-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 0.9em;

        .field-label {
            display: flex;
            text-transform: uppercase;
            letter-spacing: 1px;
            line-height: 1.2em;
        }

        .field-value {
            display: flex;
            line-height: 1.2em;
            margin: 0.25em 0 0 0;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        gap: 1em;
    }
`;

const PopoverContentWrapper = styled.div`
    ul.errors {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            color: #ff4d4f;
        }
    }
`;

export type ResumeInfoItem = {
    label: string;
    value: string;
};

interface IResumeInfoProps {
    items: Array<ResumeInfoItem>;
    button1Text?: string | undefined;
    button1OnClick?: () => Promise<boolean | void>;
    button1Disabled?: boolean;
    formErrors: string[];
}

const ResumeInfo = (props: IResumeInfoProps) => {
    const { button1Text, button1OnClick, button1Disabled, formErrors } = props;

    const { t } = useTranslation();

    return (
        <ResumeInfoWrapper>
            {props.items.map((item, index) => (
                <div className="field-item" key={index}>
                    <div className="field-label">{item.label}</div>
                    <div className="field-value">{item.value}</div>
                </div>
            ))}
            <div className="buttons">
                {button1OnClick && button1Text && (
                    <>
                        <ButtonConfirm
                            disabled={button1Disabled}
                            onClick={button1OnClick}
                            backgroundColor="#dd8f00"
                        >
                            {button1Text || ""}
                        </ButtonConfirm>

                        {formErrors.length > 0 && (
                            <Popover
                                placement="leftTop"
                                content={() => (
                                    <PopoverContentWrapper>
                                        <ul className="errors">
                                            {formErrors.map((error, index) => (
                                                <li key={index}>{t(error)}</li>
                                            ))}
                                        </ul>
                                    </PopoverContentWrapper>
                                )}
                            >
                                <AiFillExclamationCircle
                                    size={30}
                                    color="#ff4d4f"
                                />
                            </Popover>
                        )}
                    </>
                )}
            </div>
        </ResumeInfoWrapper>
    );
};

export default ResumeInfo;
