import { useTranslation } from "react-i18next";
import { SelectStyled } from "../../style/Input";
import { selectGender as testId } from "../../config/testIds";

interface ISelectAppraiserCoverageTypeProps {
  classNameItem?: string;
  styleItem?: string;
  onChange: (val: string) => void;
  dataTestId?: string;
  value: string[] | undefined;
}

const SelectAppraiserCoverageType = (
  props: ISelectAppraiserCoverageTypeProps
) => {
  const { t } = useTranslation();

  return (
    <SelectStyled
      classNameItem={props.classNameItem}
      styleItem={props.styleItem}
      label={t("select-appraiser-coverage-type-label")}
      tooltip={t("select-appraiser-coverage-type-tooltip")}
      onChange={(type) => props.onChange(type)}
      value={props.value}
      mode="multiple"
      allowClear={true}
      options={[
        {
          value: "CL",
          label: t("edit-expertizer-select-coverage-type-CL"),
        },
        {
          value: "FL",
          label: t("edit-expertizer-select-coverage-type-FL"),
        },
        {
          value: "RE",
          label: t("edit-expertizer-select-coverage-type-RE"),
        },
        {
          value: "ML",
          label: t("edit-expertizer-select-coverage-type-ML"),
        },
        {
          value: "LE",
          label: t("edit-expertizer-select-coverage-type-LE"),
        },
        {
          value: "IN",
          label: t("edit-expertizer-select-coverage-type-IN"),
        },
        {
          value: "CO",
          label: t("edit-expertizer-select-coverage-type-CO"),
        },
      ]}
      dataTestId={props.dataTestId}
    />
  );
};

export default SelectAppraiserCoverageType;
