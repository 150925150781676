import { useTranslation } from "react-i18next";
import { AppraiserUser } from "../../../../../redux/apiSpecifications/apiFesf";
import styled from "styled-components";
import { FormRow } from "../../../../../style/form";
import SegmentedSubjectType from "../../../../Inputs/SegmentedSubjectType";
import {
    BusinessTypes,
    DateFormat,
    GenderTypes,
    SubjectType,
} from "../../../../../config/const";
import { RowSpacer } from "../../../../../style/containers";
import { BirthDateStyled, InputTextStyled } from "../../../../../style/Input";
import InputFiscalCode from "../../../../Inputs/InputFiscalCode";
import {
    InputFieldValidation,
    validateFiscalCode,
    validateVatNumber,
} from "../../../../../validation/subjectValidation";
import InputVatNumber from "../../../../Inputs/InputVatNumber";
import { useEffect, useState } from "react";
import SelectGender from "../../../../Inputs/SelectGender";
import BirthPlace from "../../../../Inputs/BirthPlace";
import CodiceFiscale from "codice-fiscale-js";
import SelectBusinessType from "../../../../Inputs/SelectBusinessType";
import { mainTabs } from "../../../../../config/testIds";

const testIds = mainTabs.tabs.supplierNetwork.appraiserDetails.subject;

const PersonalDataWrapper = styled.div`
    width: 90%;
    max-width: 80em;

    .ant-picker {
        width: 100%;
    }

    .ant-form-item-explain-connected {
        color: red;
    }
`;

interface IAppraiserDetailsSubjectPersonalDataProps {
    appraiser: AppraiserUser | undefined;
    onChange: (appraiser: AppraiserUser) => void;
}

const defaultValidationResult: InputFieldValidation = {
    validateStatus: "success",
    messageKey: "",
};

const AppraiserDetailsSubjectPersonalData = (
    props: IAppraiserDetailsSubjectPersonalDataProps
) => {
    const { t } = useTranslation();
    const { appraiser, onChange } = props;

    const [fiscalCodeValidation, setFiscalCodeValidation] =
        useState<InputFieldValidation>(defaultValidationResult);

    const [pIvaValidation, setPIvaValidation] = useState<InputFieldValidation>(
        defaultValidationResult
    );

    useEffect(() => {
        if (
            !appraiser ||
            // appraiser.subjectType !== "PF" ||
            !appraiser.fiscalCode
        ) {
            setFiscalCodeValidation(defaultValidationResult);

            return;
        }

        try {
            // check if fiscal code is coherent with other fields
            const fiscalCodeConsistencyValidation = validateFiscalCode(
                appraiser.fiscalCode,
                appraiser.name,
                appraiser.lastname,
                appraiser.gender as GenderTypes,
                appraiser.birth?.date,
                appraiser.birth?.city,
                appraiser.birth?.province,
                appraiser.birth?.country
            );

            setFiscalCodeValidation(fiscalCodeConsistencyValidation);
        } catch (e) {
            const errorValidation: InputFieldValidation = {
                validateStatus: "error",
                messageKey: "fiscal-code-invalid-format",
            };

            setFiscalCodeValidation(errorValidation);
        }
    }, [
        appraiser?.name,
        appraiser?.lastname,
        appraiser?.birth,
        appraiser?.gender,
    ]);

    useEffect(() => {
        if (
            !appraiser ||
            // appraiser.subjectType !== "PF" ||
            !appraiser.fiscalCode
        ) {
            setFiscalCodeValidation(defaultValidationResult);

            return;
        }

        try {
            let birth = appraiser.birth;
            let birthDate = appraiser.birth?.date;
            let birthCity = appraiser.birth?.city;
            let birthProvince = appraiser.birth?.province;
            let birthCountry = appraiser.birth?.country;
            let gender = appraiser.gender;

            let updatedAppraiser = false;

            const {
                day,
                month,
                year,
                gender: _gender,
                birthplace,
            } = new CodiceFiscale(appraiser.fiscalCode);

            const _birthDate = `${day < 10 ? "0" : ""}${day}/${
                month < 10 ? "0" : ""
            }${month}/${year}`;

            if (
                gender !== _gender ||
                birthDate !== _birthDate ||
                birthCity !== birthplace.nome ||
                birthProvince !== birthplace.prov
            ) {
                gender = _gender;
                birthDate = _birthDate;
                birthCity = birthplace.nome;
                birthProvince = birthplace.prov;

                birth = {
                    date: _birthDate,
                    city: birthplace.nome,
                    province: birthplace.prov,
                    country: "Italy",
                };

                onChange({ ...appraiser, birth, gender });
            }

            // check if fiscal code is coherent with other fields
            const fiscalCodeConsistencyValidation = validateFiscalCode(
                appraiser.fiscalCode,
                appraiser.name,
                appraiser.lastname,
                appraiser.gender as GenderTypes,
                birth?.date,
                birth?.city,
                birth?.province,
                birth?.country
            );
            setFiscalCodeValidation(fiscalCodeConsistencyValidation);
        } catch (e) {
            const errorValidation: InputFieldValidation = {
                validateStatus: "error",
                messageKey: "fiscal-code-invalid-format",
            };

            setFiscalCodeValidation(errorValidation);
        }
    }, [appraiser?.fiscalCode]);

    useEffect(() => {
        if (!appraiser || appraiser.subjectType !== "PG" || !appraiser.pIva)
            return;

        const _validationVatNumber = validateVatNumber(appraiser?.pIva || "");
        setPIvaValidation(_validationVatNumber);
    }, [appraiser?.pIva]);

    const handleOnChange = (value: any, field: string) => {
        let newAppraiser = { ...appraiser, [field]: value };

        if (field === "subjectType") {
            newAppraiser = {
                ...newAppraiser,
                name: "",
                lastname: "",
                fiscalCode: undefined,
                gender: undefined,
                businessName: undefined,
                giuridicalType: undefined,
                pIva: undefined,
                birth: undefined,
            };
        }

        onChange(newAppraiser);
    };

    const handleFormateNominative = (name: string | undefined): string => {
        let nominative: string = "";

        if (name != undefined) {
            nominative =
                name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
        }
        return nominative;
    };

    return (
        <PersonalDataWrapper data-testid={testIds.personalData}>
            <FormRow>
                <SegmentedSubjectType
                    value={(appraiser?.subjectType as SubjectType) || "PF"}
                    onChange={(type) => handleOnChange(type, "subjectType")}
                    dataTestId={""}
                />
                <RowSpacer />
            </FormRow>

            {appraiser?.subjectType === "PG" && (
                <>
                    <FormRow>
                        <InputTextStyled
                            label={`${t(
                                "edit-claim-tab-counterpart-owner-businessname-label"
                            )}`}
                            tooltip={t(
                                "edit-claim-tab-counterpart-owner-businessname-tooltip"
                            )}
                            placeholder={t(
                                "edit-claim-tab-counterpart-owner-businessname-placeholder"
                            )}
                            onChange={(businessname) =>
                                handleOnChange(businessname, "businessName")
                            }
                            value={appraiser?.businessName}
                            dataTestId={testIds.businessname}
                        />
                    </FormRow>
                    
                    <FormRow>
                        <SelectBusinessType
                            value={appraiser?.giuridicalType as BusinessTypes}
                            onChange={(giuridicalType) =>
                                handleOnChange(giuridicalType, "giuridicalType")
                            }
                            dataTestId={testIds.giuridicalType}
                        />

                        <RowSpacer />
                        
                        <InputVatNumber
                            onChange={(pIva) => handleOnChange(pIva, "pIva")}
                            value={appraiser?.pIva}
                            validation={{
                                state:
                                    pIvaValidation.validateStatus === "success",
                                messageKey: pIvaValidation.messageKey,
                            }}
                            dataTestId={testIds.pIva}
                        />
                    </FormRow>
                </>
            )}

            <FormRow>
                <InputTextStyled
                    label={`${t("edit-subject-tab-data-name")}`}
                    tooltip={t("edit-subject-tab-data-name-tooltip")}
                    placeholder={t("edit-subject-tab-data-name-placeholder")}
                    onChange={(name) => handleOnChange(name, "name")}
                    value={handleFormateNominative(appraiser?.name)}
                    dataTestId={testIds.name}
                />
                <RowSpacer />
                <InputTextStyled
                    label={`${t("edit-subject-tab-data-lastname")}`}
                    tooltip={t("edit-subject-tab-data-lastname-tooltip")}
                    placeholder={t(
                        "edit-subject-tab-data-lastname-placeholder"
                    )}
                    onChange={(lastname) =>
                        handleOnChange(lastname, "lastname")
                    }
                    value={handleFormateNominative(appraiser?.lastname)}
                    dataTestId={testIds.lastname}
                />
                <RowSpacer />
                <InputFiscalCode
                    onChange={(fiscalCode) =>
                        handleOnChange(fiscalCode.toUpperCase(), "fiscalCode")
                    }
                    value={appraiser?.fiscalCode}
                    validation={{
                        state:
                            fiscalCodeValidation.validateStatus === "success",
                        messageKey: fiscalCodeValidation.messageKey,
                    }}
                    dataTestId={testIds.fiscalcode}
                />
                <RowSpacer />
                <BirthDateStyled
                    label={`${t("edit-subject-tab-data-birth-date")}`}
                    tooltip={t("edit-subject-tab-data-birth-date-tooltip")}
                    placeholder={t(
                        "edit-subject-tab-data-birth-date-placeholder"
                    )}
                    onChange={(date) =>
                        handleOnChange({ ...appraiser?.birth, date }, "birth")
                    }
                    value={appraiser?.birth?.date}
                    dataTestId={""}
                />
            </FormRow>

            <FormRow>
                <BirthPlace
                    birthPlace={{
                        country: appraiser?.birth?.country,
                        province: appraiser?.birth?.province,
                        city: appraiser?.birth?.city,
                    }}
                    onChange={(place) =>
                        handleOnChange(
                            { ...appraiser?.birth, ...place },
                            "birth"
                        )
                    }
                />
                <RowSpacer />

                <div style={{ flex: 1, display: "flex" }}>
                    <SelectGender
                        value={appraiser?.gender as GenderTypes}
                        onChange={(gender) => handleOnChange(gender, "gender")}
                    />
                    <RowSpacer />
                    <div style={{ flex: 1 }}></div>
                </div>
            </FormRow>
        </PersonalDataWrapper>
    );
};

export default AppraiserDetailsSubjectPersonalData;
