export default {
	primaryColor: "#008143",
	secondaryColors: {
		black: "#000",
		white: "#FFF",
		darkgreen: "#006B52",
		lightgreen: "#64B32C",
		lightgrey: "#D5D5D5",
	},
	accentColors: {
		turquois: "#00ffff",
		darkblue: "#002e55",
		magenta: "#c7066e",
		orange: "#ef7c00",
		yellow: "#ffcc00",
		limeYellow: "#e8e000",
		purple: "#4c0f83",
	},
};
