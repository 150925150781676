import { useTranslation } from "react-i18next";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";

interface IPracticeCancelledDescription {
    modifyDetailsJson: Modifydetail[];
}
const PracticeCancelledDescription = ({
    modifyDetailsJson,
}: IPracticeCancelledDescription) => {
    const { t } = useTranslation();
    const role = modifyDetailsJson.find(
        (_) => _.key === "user_role"
    );
    const cancellationReason = modifyDetailsJson.find(
        (_) => _.key === "cancellation_reason"
    );
    return <>{t(`historical-actions-practice-cancelled-description-${role?.value}`, {
        cancellation_reason: cancellationReason?.value ?? ""
    })}</>;
}

export default PracticeCancelledDescription;