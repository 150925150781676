import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ButtonCancel, ButtonConfirm } from "../../../../Layout/Buttons";
import {
  AppraiserTenantsPermissions,
  AppraiserTenantsPermissionsItem,
  SaveAppraiserTenantsPermissionsApiResponse,
  useSaveAppraiserTenantsPermissionsMutation,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { Checkbox, Modal, Popconfirm, Radio, Segmented, Tooltip } from "antd";
import { useEffect, useMemo, useState } from "react";
import { set } from "yaml/dist/schema/yaml-1.1/set";
import { SegmentedLabeledOption } from "antd/lib/segmented";
import dekra from "../../../../../style/dekra";
import { InputTextStyled } from "../../../../../style/Input";
import { IconCancel, IconLoading } from "../../../../../config/icons";
import { RootState } from "../../../../../redux/store";
import { LoadingStatus } from "../AppraiserDetailsPresentational";
import { useSelector } from "react-redux";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { RefreshInTabWrapper } from "../../../../../style/DashbordWidgetWrappers";
import { mainTabs } from "../../../../../config/testIds";
import { useAuthorization } from "../../../../../hooks/useAuthorization";

const testIds = mainTabs.tabs.supplierNetwork.appraiserDetails.management;

const AppraiserDetailsManagementClientsPermissionsWrapper = styled.div`
  .button-client {
    font-size: 0.9em;
    margin-bottom: 0.25em;
    width: 12em;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 4em 3em 2em 3em;
  gap: 1em;

  .dialog-title {
    font-size: 1.4em;
    margin-bottom: 1em;
    text-align: center;
  }

  .ant-segmented-item {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 0.8em;
  }

  .ant-segmented-item-selected {
    background-color: green;
    color: white;
    font-weight: bold;

    &:nth-child(2) {
      background-color: orange;
    }

    &:nth-child(3) {
      background-color: red;
    }
  }

  .main{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    
    thead{
      td{
        padding: 0 2em;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;

        &:first-child{
          padding-left: 0;
          text-align: left;
        }
      }
    }

    .filter-text-wrapper{
      display: flex;
      align-items: center;
      
    }

    .filter-text{
      flex: 1;
      margin-left: -3px;

      input {
        color: inherit;
        padding: 0 0.5em;
      }
    }

    .icon-unselect-district {
      font-size: 1.6em;
      margin: 0 0.25em -6px 0.25em;
      color: #888;
      cursor: pointer;
      line-height: 1.2em;
    }
  }

  .client-permission-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .permission {
    

    .client-name {
      padding: 0.5em 0;
      font-size: 12px;
    }

    
    
    .client-permission-m {
      .ant-checkbox-checked .ant-checkbox-inner{
        background-color: ${dekra.primaryColor};
        border-color: ${dekra.primaryColor};
      }
    }

    

    .client-permission-a {
      .ant-checkbox-checked .ant-checkbox-inner{
        background-color: orange;
        border-color: orange;
      }
    }

    .client-permission-d {
      .ant-checkbox-checked .ant-checkbox-inner{
        background-color: red;
        border-color: red;
      }
    }

    .ant-checkbox-inner {
      transform: scale(1.2);
      
   }
  }

  .filter-row td{
    padding-bottom: 2em;
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin-top: 3em;
  }

  
  }
`;

export type AppraiserClientPermissionType = "A" | "M" | "D";

interface IAppraiserDetailsManagementClientsPermissionsProps {
  idAppraiser: number | undefined;
  permissions: AppraiserTenantsPermissionsItem[];
  onChange: (permissions: AppraiserTenantsPermissions) => void;
}

const AppraiserDetailsManagementClientsPermissions = (
  props: IAppraiserDetailsManagementClientsPermissionsProps
) => {
  const { t } = useTranslation();
  const { idAppraiser: appraiserId, onChange } = props;

  const { getAuthorization } = useAuthorization();

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");
  const [error, setError] = useState<string | undefined>(undefined);
  const [savePermissions] = useSaveAppraiserTenantsPermissionsMutation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [permissions, setPermissions] = useState(props.permissions);

  const [filterText, setFilterText] = useState<string>();
  const [filteredPermissions, setFilteredPermissions] = useState(
    props.permissions
  );

  useEffect(() => {
    setPermissions(props.permissions);
  }, [props.permissions]);

  useEffect(() => {
    if (permissions.length === 0) return;

    const lcFilterText = filterText?.toLowerCase() ?? "";
    const filteredPermissions =
      filterText === ""
        ? permissions
        : permissions.filter((permission) =>
            permission.tenantDescription?.toLowerCase().includes(lcFilterText)
          );

    setFilteredPermissions(filteredPermissions);
  }, [permissions, filterText]);

  const handleOnCancel = () => {
    setPermissions(props.permissions);
    setIsOpen(false);
  };

  const handleTogglePermission = (
    type: AppraiserClientPermissionType,
    id: number
  ) => {
    const updatedPermissions = permissions.map((permission) => ({
      ...permission,
      appraiserTenantPermissionCode:
        permission.tenantId === id
          ? type
          : permission.appraiserTenantPermissionCode,
    }));
    setPermissions(updatedPermissions);
  };

  const handleSelectAllColumn = (type: AppraiserClientPermissionType) => {
    const updatedPermissions = permissions.map((permission, i) => ({
      ...permission,
      appraiserTenantPermissionCode: type,
    }));
    setPermissions(updatedPermissions);
  };

  const handleOnSave = async () => {
    setLoadingStatus("saving");

    const response = await savePermissions({
      authorization: await getAuthorization(),
      id: appraiserId!,
      appraiserTenantsPermissions: { tenantsPermissions: permissions },
    });

    const correctResponse = response as {
      data: AppraiserTenantsPermissions;
    };

    const errorResponse = response as {
      error: FetchBaseQueryError | SerializedError;
    };
    if (correctResponse.data) {
      const tenantsPermissions = correctResponse.data.tenantsPermissions || [];
      onChange({ tenantsPermissions });
      setIsOpen(false);
    } else if (errorResponse.error) {
      setError(t("saving-data-error")!);
      if (errorResponse && errorResponse.error) {
        console.log(errorResponse);
      }
    }

    setLoadingStatus("idle");
  };
  const { isAllM, isAllA, isAllD } = useMemo(() => {
    let isAllM = true;
    let isAllA = true;
    let isAllD = true;

    permissions.forEach((permission) => {
      if (permission.appraiserTenantPermissionCode !== "M") isAllM = false;
      if (permission.appraiserTenantPermissionCode !== "A") isAllA = false;
      if (permission.appraiserTenantPermissionCode !== "D") isAllD = false;
    });

    return { isAllM, isAllA, isAllD };
  }, [permissions]);

  return (
    <AppraiserDetailsManagementClientsPermissionsWrapper
      data-testid={testIds.clientPermissions.modal}
    >
      <ButtonConfirm
        onClick={() => setIsOpen(true)}
        size="small"
        className="button-client"
        dataTestId={testIds.mainData.buttonClientPermissions}
      >
        {t("edit-expertizer-button-edit-client-permissions")!}
      </ButtonConfirm>
      {isOpen && (
        <Modal
          open={isOpen}
          onCancel={handleOnCancel}
          closable={false}
          maskClosable={false}
          footer={null}
          width={"80vw"}
        >
          <ContentWrapper data-testid={testIds.clientPermissions.container}>
            <div className="dialog-title">
              {t("edit-expertizer-edit-client-permissions-title")}
            </div>
            <div className="main">
              <table>
                <thead>
                  <tr>
                    <td>Cliente</td>
                    <td>Attivo con Automatismo</td>
                    <td>Attivo senza Automatismo</td>
                    <td>Disabilitato</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="filter-row">
                    <td>
                      <div className="filter-text-wrapper">
                        <InputTextStyled
                          placeholder={
                            t(
                              "edit-expertizer-client-permissions-filter-placeholder"
                            )!
                          }
                          classNameItem="filter-text"
                          onChange={setFilterText}
                          value={filterText}
                        />
                        <div
                          className="icon-unselect-district"
                          onClick={() => setFilterText("")}
                        >
                          <IconCancel />
                        </div>
                      </div>
                    </td>
                    <td className="client-permission-m">
                      <div className="client-permission-wrapper">
                        <Tooltip
                          title={t("edit-expertizer-client-permissions-all-M")}
                        >
                          <Checkbox
                            checked={isAllM}
                            onChange={(_) => handleSelectAllColumn("M")}
                            data-testid={testIds.clientPermissions.checkboxAllM}
                          />
                        </Tooltip>
                      </div>
                    </td>
                    <td className="client-permission-a">
                      <div className="client-permission-wrapper">
                        <Tooltip
                          title={t("edit-expertizer-client-permissions-all-A")}
                        >
                          <Checkbox
                            checked={isAllA}
                            onChange={(_) => handleSelectAllColumn("A")}
                          />
                        </Tooltip>
                      </div>
                    </td>
                    <td className="client-permission-d">
                      <div className="client-permission-wrapper">
                        <Tooltip
                          title={t("edit-expertizer-client-permissions-all-D")}
                        >
                          <Checkbox
                            checked={isAllD}
                            onChange={(_) => handleSelectAllColumn("D")}
                          />
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                  {filteredPermissions.map((permission, index) => (
                    <tr className="permission" key={index}>
                      <td className="client-name">
                        {permission.tenantDescription}
                      </td>
                      <td className="client-permission-m">
                        <div className="client-permission-wrapper">
                          <Checkbox
                            checked={
                              permission.appraiserTenantPermissionCode === "M"
                            }
                            onChange={(_) =>
                              handleTogglePermission("M", permission.tenantId!)
                            }
                          />
                        </div>
                      </td>
                      <td className="client-permission-a">
                        <div className="client-permission-wrapper">
                          <Checkbox
                            checked={
                              permission.appraiserTenantPermissionCode === "A"
                            }
                            onChange={(_) =>
                              handleTogglePermission("A", permission.tenantId!)
                            }
                          />
                        </div>
                      </td>
                      <td className="client-permission-d">
                        <div className="client-permission-wrapper">
                          <Checkbox
                            checked={
                              permission.appraiserTenantPermissionCode === "D"
                            }
                            onChange={(_) =>
                              handleTogglePermission("D", permission.tenantId!)
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="buttons-wrapper">
              <Popconfirm
                title={
                  t("edit-expertizer-client-permissions-button-cancel-confirm")!
                }
                onConfirm={handleOnCancel}
                onCancel={() => {}}
                okText={t("switch-yes")!}
                cancelText={t("switch-no")!}
              >
                <ButtonCancel onClick={() => {}}>
                  {t("button-cancel")!}
                </ButtonCancel>
              </Popconfirm>

              <Popconfirm
                title={
                  t("edit-expertizer-client-permissions-button-save-confirm")!
                }
                onConfirm={handleOnSave}
                onCancel={() => {}}
                okText={
                  <div
                    data-testid={testIds.clientPermissions.buttonSaveConfirm}
                  >
                    {t("switch-yes")!}
                  </div>
                }
                cancelText={t("switch-no")!}
              >
                <ButtonConfirm
                  onClick={() => {}}
                  dataTestId={testIds.clientPermissions.buttonSave}
                >
                  {t("button-confirm")!}
                </ButtonConfirm>
              </Popconfirm>
            </div>

            {!error && loadingStatus !== "idle" && (
              <RefreshInTabWrapper>
                <div className="box" data-testid={""}>
                  {IconLoading}
                </div>
              </RefreshInTabWrapper>
            )}

            {error && (
              <div className="error">
                <div className="message">{error}</div>
              </div>
            )}
          </ContentWrapper>
        </Modal>
      )}
    </AppraiserDetailsManagementClientsPermissionsWrapper>
  );
};

export default AppraiserDetailsManagementClientsPermissions;
