import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal } from "antd";
import { ButtonCancel } from "../../../Layout/Buttons";
import {
  PracticeActivityAppraiser,
  useLazyGetAppraisersListQuery,
} from "../../../../redux/apiSpecifications/apiCrud";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { LoadingInTabWrapper } from "../../../../style/DashbordWidgetWrappers";
import { IconLoading } from "../../../../config/icons";
import { addTab } from "../../../../redux/features/mainTabsSlice";
import { InputTextStyled } from "../../../../style/Input";
import { useAuthorization } from "../../../../hooks/useAuthorization";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 4em 3em 2em 3em;
  gap: 1em;

  .dialog-title {
    font-size: 1.4em;
    margin-bottom: 1em;
    text-align: center;
  }

  .main {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 2em;

    .map-wrapper {
      width: 450px;
      height: 350px;

      .coverage-areas-wrapper {
        margin-bottom: 1em;
        .coverage-areas {
          display: none;
        }

        .coverage-areas.show {
          display: block;
        }
      }
    }

    .ant-form-item-row {
      flex-direction: column;

      .ant-form-item-label {
        text-align: left;
      }
    }

    .select-coverare-area-type {
      margin-bottom: 1em;
      display: flex;
      align-items: end;

      .icon-unselect-district {
        font-size: 1.6em;
        margin-left: 0.25em;
        margin-right: 0.25em;
        color: #888;
        cursor: pointer;
        line-height: 1.2em;
      }
    }

    .form {
      flex: 1;
      display: flex;
      flex-direction: column;

      .ant-picker {
        width: 100%;
      }
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin-top: 1em;
  }

  .error {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    .message {
      font-size: 1.2em;
    }
  }

  .appraisers-list-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    max-height: 30vh;
    overflow-y: auto;

    .appraisers-list {
      display: flex;
      flex-direction: column;
      gap: 1em;
    }
  }

  .filter {
    width: 20em;
  }
`;

type AppraiserListItemType = {
  appraiser: PracticeActivityAppraiser;
  nominative: string;
};

interface IRatesClientModalAppraisersProps {
  onCancel: () => void;
}

const RatesClientModalAppraisers = (
  props: IRatesClientModalAppraisersProps
) => {
  const { onCancel } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getAuthorization } = useAuthorization();
  const activeRole = useSelector((state: RootState) => state.user.activeRole);

  const [getApprailsalsList] = useLazyGetAppraisersListQuery();

  const [appraisersList, setAppraisersList] = useState<AppraiserListItemType[]>(
    []
  );

  const [filter, setFilter] = useState("");

  const [loadingStatus, setLoadingStatus] = useState<"idle" | "loading">(
    "idle"
  );

  const loadAppraisers = async () => {
    setLoadingStatus("loading");

    const response = await getApprailsalsList({
      authorization: await getAuthorization(),
      activeRole: activeRole!,
    });

    if (response.isSuccess) {
      const appraisersToSort = (response.data as PracticeActivityAppraiser[])
        .filter((a) => a.isInternal !== true)
        .map((appraiser) => ({
          appraiser,
          nominative:
            appraiser.subjectType === "PF"
              ? `${appraiser.firstName} ${appraiser.lastName}`
              : appraiser.subjectType === "PG"
              ? appraiser.businessName || ""
              : "",
        }));

      const nominativeSortedAppraisers = appraisersToSort
        .slice()
        .sort((a, b) => (a.nominative > b.nominative ? 1 : -1));
      setAppraisersList(nominativeSortedAppraisers);
    } else {
      // to do
    }

    setLoadingStatus("idle");
  };

  useEffect(() => {
    loadAppraisers();
  }, []);

  const handleOnClickAppraiser = (idAppraiser: number) => {
    const nominative = appraisersList.find(
      (appraiser) => appraiser.appraiser.id === idAppraiser
    )?.nominative;

    dispatch(
      addTab({
        key: 0,
        label: nominative!,
        type: "appraiser-details",
        externalData: {
          idAppraiser,
          defaultTab: "rates",
        },
      })
    );

    onCancel();
  };

  return (
    <Modal open={true} onCancel={onCancel} footer={null}>
      {loadingStatus === "loading" && (
        <LoadingInTabWrapper>
          <div
            className="box"
            data-testid={""}
            style={{ marginBottom: "10em" }}
          >
            {IconLoading}
          </div>
        </LoadingInTabWrapper>
      )}

      {loadingStatus === "idle" && (
        <ContentWrapper data-testid={""}>
          <div className="dialog-title">
            {t("rate-clients-select-appraiser-modal-title")}
          </div>
          <div className="filter">
            <InputTextStyled
              placeholder={t(
                "rate-clients-select-appraiser-modal-filter-placeholder"
              )}
              onChange={(txt) => setFilter(txt.toLowerCase())}
            />
          </div>
          <div className="appraisers-list-wrapper">
            <div className="appraisers-list">
              {appraisersList
                .filter((a) =>
                  filter === ""
                    ? true
                    : a.nominative.toLowerCase().indexOf(filter) >= 0
                )
                .map((appraiser) => (
                  <AppraiserItem
                    key={appraiser.appraiser.id}
                    appraiser={appraiser}
                    onClick={handleOnClickAppraiser}
                  />
                ))}
            </div>
          </div>
          <div className="main">
            <div className="buttons">
              <ButtonCancel onClick={() => onCancel()}>
                {t("button-cancel")!}
              </ButtonCancel>
            </div>
          </div>
        </ContentWrapper>
      )}
    </Modal>
  );
};

const AppraiserItemWrapper = styled.div`
  .nominative {
    padding: 0.5em 1em;

    &:hover {
      background-color: #eee;
      cursor: pointer;
    }
  }
`;

type IAppraiserItemProps = {
  appraiser: AppraiserListItemType;
  onClick: (idAppraiser: number) => void;
};

const AppraiserItem = (props: IAppraiserItemProps) => {
  const { appraiser, onClick } = props;
  const {} = appraiser;

  return (
    <AppraiserItemWrapper onClick={() => onClick(appraiser.appraiser.id!)}>
      <div className="nominative">{appraiser.nominative}</div>
    </AppraiserItemWrapper>
  );
};

export default RatesClientModalAppraisers;
