import { useTranslation } from "react-i18next";
import { CreateReportFormModel } from "./CreateReportFormModel";
import { FormRow } from "../../../../../style/form";
import {
    InputTextStyled,
    SelectStyled,
    SwitchStyled,
} from "../../../../../style/Input";
import { RowSpacer } from "../../../../../style/containers";
import { useMemo } from "react";
import { SelectPair } from "../../../../../types/common.types";
import { WidgetWrapper } from "../../../../../style/DashbordWidgetWrappers";
import styled from "styled-components";
import PracticeExcludeInput from "./PracticeExcludeInput";
import InvoicedPracticesList from "./InvoicedPracticesList";
import { ExcludedPractice } from "../../../../../redux/apiSpecifications/apiFesf";

const NewPracticesConcludedWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    height: 100%;
    .body {
        padding: 1em 1em;
        display: block;
    }
`;

const PracticesToBeAccountedWrapper = styled(WidgetWrapper)`
    margin-top: 1em;
    flex-direction: column;
    height: 100%;
    .body {
        padding: 1em 1em;
        display: block;

        .empty-field {
            flex: 1;
        }
    }
`;

const SectionWrapper = styled.div`
    margin-top: 1em;
    flex-direction: column;
    height: 100%;
    .empty-field {
        flex: 1;
    }
`;

interface ICreateReportFormProps {
    model: CreateReportFormModel;
    onChange: (value: any, field: string) => void;
}

const CreateReportForm = ({ model, onChange }: ICreateReportFormProps) => {
    const { t } = useTranslation();

    const monthsOptions: SelectPair[] = useMemo(() => {
        const monthsOptionsArray: SelectPair[] = [];

        Array.from(new Array(12).keys()).forEach((iMonth) => {
            monthsOptionsArray.push({
                value: iMonth.toString(),
                label: t(`month-${iMonth}`),
            });
        });

        return monthsOptionsArray;
    }, []);

    const yearsOptions: SelectPair[] = useMemo(() => {
        const yearsOptionsArray: SelectPair[] = [];

        const today = new Date();
        const currentYear = today.getFullYear();

        Array.from(new Array(3).keys()).forEach((i) => {
            yearsOptionsArray.push({
                value: (currentYear - i).toString(),
                label: (currentYear - i).toString(),
            });
        });

        return yearsOptionsArray;
    }, []);

    const handleAddPracticeToExclude = (practice: ExcludedPractice) => {
        const findPrecticeResult = model.excludedPractices.find(
            (_) => _.id === practice.id
        );

        if (findPrecticeResult === undefined) {
            onChange(
                [...model.excludedPractices, practice],
                "excludedPractices"
            );
        }
    };

    const handleRemovePracticeToExclude = (practiceId: number) => {
        console.info(
            practiceId,
            model.excludedPractices.filter((_) => _.id === practiceId)
        );
        onChange(
            model.excludedPractices.filter((_) => _.id !== practiceId),
            "excludedPractices"
        );
    };

    return (
        <>
            <FormRow>
                <InputTextStyled
                    label={`${t("create-report-form-file-name-label")}`}
                    value={model.fileNamePrefix}
                    disabled={true}
                />

                <RowSpacer />

                <FormRow>
                    <SelectStyled
                        label=" "
                        onChange={(fileNameMonth) =>
                            onChange(fileNameMonth, "fileNameMonth")
                        }
                        value={model.fileNameMonth}
                        options={monthsOptions ?? []}
                    />

                    <RowSpacer />

                    <SelectStyled
                        label=" "
                        onChange={(fileNameYear) =>
                            onChange(fileNameYear, "fileNameYear")
                        }
                        value={model.fileNameYear}
                        options={yearsOptions ?? []}
                    />
                </FormRow>
            </FormRow>

            <NewPracticesConcludedWrapper>
                <div className="title">
                    {t("create-report-form-new-practices-concluded-title")}
                </div>

                <div className="body">
                    <FormRow>
                        <InputTextStyled
                            label={`${t(
                                "create-report-form-total-practices-label"
                            )}`}
                            onChange={(totalPractices) =>
                                onChange(totalPractices, "totalPractices")
                            }
                            value={model.totalPractices}
                            disabled={true}
                        />

                        <RowSpacer />

                        <PracticeExcludeInput
                            idTenant={model.idTenant}
                            startDate={model.startDate}
                            endDate={model.endDate}
                            excludedPractices={model.excludedPractices}
                            onAddPractice={handleAddPracticeToExclude}
                            onRemovePractice={handleRemovePracticeToExclude}
                        />
                    </FormRow>
                </div>
            </NewPracticesConcludedWrapper>

            <PracticesToBeAccountedWrapper>
                <div className="title">
                    {t("create-report-form-practices-to-be-accounted-title")}
                </div>

                <div className="body">
                    <FormRow>
                        <InputTextStyled
                            label={`${t(
                                "create-report-form-total-delta-label"
                            )}`}
                            onChange={(totalDelta) =>
                                onChange(totalDelta, "totalDelta")
                            }
                            value={model.totalDelta}
                            disabled={true}
                        />

                        <RowSpacer />

                        <div className="empty-field"></div>
                    </FormRow>

                    <FormRow>
                        <InvoicedPracticesList
                            practices={model.invoicedPractices ?? []}
                        />
                    </FormRow>
                </div>
            </PracticesToBeAccountedWrapper>

            <SectionWrapper>
                <FormRow>
                    <div className="empty-field"></div>
                    <RowSpacer />
                    <div className="empty-field"></div>
                    <RowSpacer />
                    <div className="empty-field"></div>
                    <RowSpacer />
                    <div className="empty-field"></div>
                    <RowSpacer />
                    <div className="empty-field"></div>
                    <RowSpacer />
                    <div className="empty-field"></div>
                    <RowSpacer />
                    <SwitchStyled
                        label={t("create-report-form-is-simulated-label")}
                        onChange={(isSimulated) =>
                            onChange(isSimulated, "isSimulated")
                        }
                        unCheckedChildren={t("switch-no")}
                        checkedChildren={t("switch-yes")}
                        checked={model?.isSimulated}
                    />
                </FormRow>
            </SectionWrapper>
        </>
    );
};

export default CreateReportForm;
