import styled from "styled-components";
import { TenantFeesByType } from "../../../../redux/apiSpecifications/apiFesf";
import { useTranslation } from "react-i18next";
import TenantFeesHeader from "./TenantFeesHeader";
import { useMemo } from "react";
import { InputTextStyled } from "../../../../style/Input";

const TenantFeesWrapper = styled.div`
    display: flex;
    // flex-direction: row;
    width: auto;
    flex-direction: column;

    div.tenants-fees-header {
        display: flex;
        flex-direction: row;
        margin-top: 1em;
        //margin-left: 16em;
    }

    div.tenant-name {
        padding-right: 1em;
        // width: 16em;
        .tenant-name-txt {
            text-align: center;
            background-color: #f5f5f5;
            padding: 0.5em 0;
        }
    }

    div.tenants-fees-body {
        display: flex;
        flex-direction: row;

        .fees-names {
            display: flex;
            flex-direction: column;
            gap: 1em;
            width: 1em;
            padding-left: 2em;

            .fee-name {
                height: 1.8em;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .fees-value-wrapper {
            display: flex;
            flex-direction: row;

            .fees-values {
                display: flex;
                flex-direction: column;
                gap: 1em;

                .fee-value {
                    height: 1.8em;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .value-input {
                border-radius: 0.5em;
                width: 8.5em;
                height: 1.8em;
                margin-right: 1em;
                text-align: right;
                cursor: pointer;

                &.readonly {
                    background-color: #ccc;
                }

                &.percent {
                    border-color: #f96767;
                }
            }
        }
    }
`;

export type ChangeFeeValueType = "fee-base";

interface ITenantFeesProps {
    tenantData?: TenantFeesByType;
    onChange: (
        idTenant: number,
        feeCode: string,
        value: string | undefined | null,
        type: ChangeFeeValueType
    ) => void;
    // onShowHistory: (id: number, name: string) => void;
}

const TenantFees = ({ tenantData, onChange }: ITenantFeesProps) => {
    const { t } = useTranslation();

    const { tenantId, tenantName, tenantFeeConfig } = tenantData || {};

    const tenantFeeCodes = useMemo(() => {
        if (!tenantData?.tenantFeeConfig) return [];

        const feeCodes = tenantData?.tenantFeeConfig.map((c) => c.feeType!);

        return feeCodes;
    }, [tenantData]);

    const handleOnChange = (
        feeCode: string,
        value: string | undefined | null,
        type: ChangeFeeValueType
    ) => {
        if (!tenantId) return;

        onChange(tenantId, feeCode, value, type);
    };

    const handleDoubleClick = (
        feeCode: string,
        value: string | undefined | null,
        type: ChangeFeeValueType
    ) => {
        if (!tenantId) return;

        const newValue = value === null || value === undefined ? "0" : null;

        onChange(tenantId, feeCode, newValue, type);
    };

    return (
        <TenantFeesWrapper>
            <div className="tenant-name">
                <div className="tenant-name-txt">{tenantName || ""}</div>
            </div>

            <div className="tenants-fees-header">
                <TenantFeesHeader feeCodes={tenantFeeCodes.slice(0, 10)} />
            </div>

            <div className="tenants-fees-body">
                <div className="fees-value-wrapper">
                    {tenantData?.tenantFeeConfig?.slice(0, 10).map((fee, i) => (
                        <div key={i} className="fees-values">
                            <div className="fee-value">
                                <ValueButton
                                    value={fee.feeAmount}
                                    type="number"
                                    onChange={(val: string) => {
                                        handleOnChange(
                                            fee.feeType!,
                                            val,
                                            "fee-base"
                                        );
                                    }}
                                    onDoubleClick={() =>
                                        handleDoubleClick(
                                            fee.feeType!,
                                            fee.feeAmount?.toString(),
                                            "fee-base"
                                        )
                                    }
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="tenants-fees-header">
                <TenantFeesHeader feeCodes={tenantFeeCodes.slice(10, 20)} />
            </div>

            <div className="tenants-fees-body">
                <div className="fees-value-wrapper">
                    {tenantData?.tenantFeeConfig
                        ?.slice(10, 20)
                        .map((fee, i) => (
                            <div key={i} className="fees-values">
                                <div className="fee-value">
                                    <ValueButton
                                        value={fee.feeAmount}
                                        type="number"
                                        onChange={(val: string) => {
                                            handleOnChange(
                                                fee.feeType!,
                                                val,
                                                "fee-base"
                                            );
                                        }}
                                        onDoubleClick={() =>
                                            handleDoubleClick(
                                                fee.feeType!,
                                                fee.feeAmount?.toString(),
                                                "fee-base"
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </TenantFeesWrapper>
    );
};

export default TenantFees;

type IValueButtonProps = {
    value: number | string | undefined;
    type: "number" | "percent";
    onChange: (val: string) => void;
    onDoubleClick?: () => void;
};

const ValueButton = (props: IValueButtonProps) => {
    const { type, onChange, onDoubleClick } = props;

    const readonly = (props.value?.toString().length || 0) < 1;

    let value = "";

    if (props.value?.toString()) {
        value = props.value?.toString();
    }

    const handleOnChange = (val: string) => {
        if (val === "") onChange("0");
        else if (
            val.length === 2 &&
            val.startsWith("0") &&
            !val.endsWith(",")
        ) {
            onChange(val.substring(1));
        } else if (/^\d+(\,\d{0,})?$/.test(val)) {
            onChange(val);
        }
    };

    return (
        <InputTextStyled
            classNameInput={`value-input ${readonly ? "readonly" : ""} ${
                type === "percent" ? "percent" : ""
            }`}
            value={value?.replace(".", ",")}
            readOnly={readonly}
            onChange={handleOnChange}
            onDoubleClick={onDoubleClick}
            onClick={(e) => e.target.select()}
        />
    );
};
