import { useEffect, useState } from "react";
import styled from "styled-components";

const FooterWrapper = styled.div`
  background-color: #006b52;
  color: #fefefe;
  font-size: small;
  width: 100%;
  height: 30px;
  padding: 0 1em;
  display: flex;

  .copyright {
    flex-grow: 1;
    width: 33%;
    text-align: left;
    display: grid;
    align-items: center;
  }

  .contact {
    flex-grow: 1;
    width: 34%;
    text-align: center;
    display: grid;
    align-items: center;
    text-transform: uppercase;

    a {
      text-decoration: underline;
    }
  }

  .version {
    flex-grow: 1;
    width: 33%;
    text-align: right;
    display: grid;
    align-items: center;
  }

  a:hover {
    color: #fefefe;
  }

  a:link {
    color: #fefefe;
  }

  a:visited {
    color: #fefefe;
  }

  a:active {
    color: #fefefe;
  }
`;

type VersionInfoType = {
  versionTag: string;
  buildTimeDate: string;
  branchName: string;
  versionHash: string;
};

const Footer = () => {
  const [versionInfo, setVersionInfo] = useState<VersionInfoType>({
    versionTag: "",
    buildTimeDate: "",
    branchName: "",
    versionHash: "",
  });

  useEffect(() => {
    fetch("/version.json")
      .then((response) => response.arrayBuffer())
      .then((buffer) => new TextDecoder("utf-16le").decode(buffer))
      .then((response) => JSON.parse(response))
      .then((data) => {
        setVersionInfo(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <FooterWrapper>
      <div className="copyright">
        Copyright © {new Date().getFullYear()} DEKRA Automobil GmbH. All rights
        reserved.
      </div>

      <div className="contact">
        <a href="mailto:info@dekra.com">Contact</a>
      </div>

      <div className="version">
        Version {versionInfo.versionHash} - Build date:{" "}
        {versionInfo.buildTimeDate}
      </div>
    </FooterWrapper>
  );
};

export default Footer;
