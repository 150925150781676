import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InvoicedPractice } from "../../../../../redux/apiSpecifications/apiFesf";
import { CellWrapper } from "../../../../../style/CellWrapper";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import styled from "styled-components";
import { WidgetWrapper } from "../../../../../style/DashbordWidgetWrappers";

const PracticeListWrapper = styled(WidgetWrapper)`
    display: flex;
    align-items: center;
    overflow: auto;
    margin-top: 1em;
    background-color: #fff;
    border-radius: 1em;
    box-shadow: 0 0 5px #aaa;
    padding: 1em 2em;

    .MuiPaper-root {
        box-shadow: none;
        width: 95%;
    }

    .MuiInputBase-root {
        &:before {
            border-bottom: 1px solid white;
        }
    }

    .MuiToolbar-root > .MuiBox-root {
        justify-content: flex-start;
    }

    .MuiInputBase-input {
        color: #555;
    }

    .MuiTableCell-root {
        padding: 0.5em 1em;
    }
`;

type PracticeItem = InvoicedPractice;

export interface IInvoicedPracticesListProps {
    practices: InvoicedPractice[];
}

const InvoicedPracticesList = ({ practices }: IInvoicedPracticesListProps) => {
    const { t } = useTranslation();

    const data = useMemo(
        () =>
            practices.map((practice) => {
                return {
                    ...practice,
                } as PracticeItem;
            }) || [],
        [practices]
    );

    const columns = useMemo<MRT_ColumnDef<PracticeItem>[]>(
        () => [
            {
                accessorKey: "practiceNumber",
                header: t(
                    "create-report-invoiced-practices-list-practice-number-column-header"
                ),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper>
                        {row.original.practiceNumber}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "reportName",
                header: t(
                    "create-report-invoiced-practices-list-report-name-column-header"
                ),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper>
                        {row.original.reportName}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "delta",
                header: t(
                    "create-report-invoiced-practices-list-delta-column-header"
                ),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper>
                        {row.original.delta}
                    </CellWrapper>
                ),
            },
        ],
        [practices]
    );

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });

    return (
        <>
            {data.length > 0 && (
                <MaterialReactTable
                    muiTablePaperProps={{ style: { width: "100%" } }}
                    columns={columns}
                    data={data}
                    enableDensityToggle={false}
                    enableColumnOrdering
                    muiTableBodyCellProps={{
                        sx: {
                            textAlign: "left",
                        },
                    }}
                    onPaginationChange={setPagination}
                    state={{ pagination }}
                    autoResetPageIndex={false}
                />
            )}
        </>
    );
};

export default InvoicedPracticesList;
