import { useMemo } from "react";
import { SelectPair } from "../../types/common.types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { InputProps, SelectStyled } from "../../style/Input";

interface ISelectUserTenantsProps extends InputProps {
  value: number | undefined;
  disabled?: boolean;
  showSearch?: boolean;
  allowClear?: boolean;
  onChange?: (val: number) => void;
  filterOption?: (input: any, option: SelectPair | undefined) => boolean;
}

const SelectUserTenants = (props: ISelectUserTenantsProps) => {
  const { value, filterOption, onChange, ...rest } = props;

  // let selectLabel = label;

  // if (props.required === true) selectLabel += " *";

  const { userDetails } = useSelector((state: RootState) => state.user);

  const tenantsOptions = useMemo(() => {
    const result: SelectPair[] = [];

    if (userDetails?.tenants) {
      for (let tenant of userDetails?.tenants) {
        result.push({
          value: tenant.id,
          label: tenant.companyName!,
        });
      }
    }

    return result;
  }, [userDetails?.tenants]);

  return (
    <SelectStyled
      // label={selectLabel}
      // required={}
      value={value}
      options={tenantsOptions}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      filterOption={filterOption ?? handleFilterSearchLabel}
      {...rest}
    />
  );
};

const handleFilterSearchLabel = (input: string, option: any) => {
  const text = input.toLowerCase();

  return option.label.toLowerCase().includes(text) || false;
};

export default SelectUserTenants;
