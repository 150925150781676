import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import {
  DamagedPartPair,
  ActiveClaimStateType,
  AdditionalInfoDetails,
  CardWizardDataType,
  ClaimCounterpartModel,
  ClaimResponsabilityModel,
  ClaimResponsabilityType,
} from "../../types/claim.types";
import { AdditionalValidationField } from "../../types/common.types";

import {
  Claim as ClaimModel,
  DamagedPart,
  PolicyMainData,
} from "../apiSpecifications/apiCrud";
import { ClaimStatusType, DateFormat } from "../../config/const";

export type ClaimAdditionalValidations = {
  // claim data
  receiptDate: AdditionalValidationField;
  dateOfReceiptCompany: AdditionalValidationField;
  dateOfReceiptDekra: AdditionalValidationField;
  occurrenceDate: AdditionalValidationField;
  occurrenceTime: AdditionalValidationField;
  occurrencePlace?: AdditionalValidationField;
  // counterpart data
  counterpartPlate?: AdditionalValidationField;

  // responsability data
  baremsResult?: AdditionalValidationField;
  // tabs
  isClaimDataCompleted: boolean;
  isCounterpartCompleted: boolean;
  isResponsabilityCompleted: boolean;
  isDamagedPartsCompleted: boolean;
};

export type ResponsabilityAdditionalValidations = {
  //
};

export interface IActiveClaimState {
  dataEntryStatus?: ActiveClaimStateType;

  claimStatus: ClaimStatusType;

  // additional data
  policyData?: PolicyMainData;
  cardWizardData: CardWizardDataType;

  // tabs
  claimData?: ClaimModel;
  counterpartData?: ClaimCounterpartModel;
  responsability?: ClaimResponsabilityModel;
  damagedParts: DamagedPart[];

  // additional validations
  additionalValidations: ClaimAdditionalValidations;
}

const today = (() => {
  return moment().format(DateFormat);
})();

const buildInitialState = () => {
  return {
    dataEntryStatus: ActiveClaimStateType.MandatoryData,
    claimStatus: "compliant",
    cardWizardData: {
      numberOfVehicles: 0,
      isVehicleAVisible: false,
      vehicleAType: "---",
      isVehicleBVisible: false,
      vehicleBType: "---",
      isCollisionVisible: false,
      collision: false,
      isInItalyVisible: false,
      inItaly: false,
      claimType: "---",
    },
    claimData: {
      number: Date.now().toString(),
      receiptDate: today,
      dateOfReceiptCompany: today,
      dateOfReceiptDekra: today,
      registrationDate: today,
      occurrenceDate: today,
      occurrenceTime: "00:00",
      occurrencePlace: undefined,
      policeIntervention: false,
      witnesses: false,
      note: "",
    },
    additionalValidations: {
      receiptDate: {
        state: false,
      },
      dateOfReceiptCompany: {
        state: false,
      },
      dateOfReceiptDekra: {
        state: false,
      },
      occurrenceDate: {
        state: false,
      },
      occurrenceTime: {
        state: false,
      },
      occurrencePlace: {
        state: false,
      },
      isClaimDataCompleted: false,
      isCounterpartCompleted: false,
      isResponsabilityCompleted: false,
      isDamagedPartsCompleted: false,
    },
    counterpartData: {
      subjectType: "PF",
    },
    responsability: {
      barems: {
        vehicleA: 0,
        vehicleB: 0,
        result: "",
      },
      forcedReason: "",
      responsabilityType: "",
      responsabilityPercentage: "",
      signatureType: "",
    },
    damagedParts: [
      {
        number: Date.now().toString(),
        subject: undefined,
        role: "CP",
        damages: [
          {
            type: "V",
            damagedPerson: undefined,
            damagedProperty: undefined,
            damagedVehicle: {
              collisionPoints: [],
            },
          },
        ],
      },
    ],
  } as IActiveClaimState;
};

export const activeClaimSlice = createSlice({
  name: "active-claim",
  initialState: buildInitialState(),
  reducers: {
    clearLocalStorage(state) {
      // handled in localstoareMiddleware
    },
    clear(state) {
      const newState = buildInitialState();

      state.dataEntryStatus = newState.dataEntryStatus;
      state.policyData = newState.policyData;
      state.cardWizardData = newState.cardWizardData;
      state.claimData = newState.claimData;
      state.damagedParts = newState.damagedParts;
      state.responsability = undefined;
      state.counterpartData = newState.counterpartData;
      state.additionalValidations = newState.additionalValidations;
    },
    setDataEntryStatus(state, action: PayloadAction<ActiveClaimStateType>) {
      state.dataEntryStatus = action.payload;
    },
    setClaimStatus(state, action: PayloadAction<ClaimStatusType>) {
      state.claimStatus = action.payload;
      if (state.claimData) state.claimData.status = action.payload;
    },
    setPolicyData(state, action: PayloadAction<PolicyMainData>) {
      state.policyData = action.payload;
    },
    updateResponsabilityData(
      state,
      action: PayloadAction<ClaimResponsabilityModel>
    ) {
      const { barems, forcedReason } = action.payload;
      state.responsability = action.payload;

      state.responsability.responsabilityType = (
        barems?.result === "T" && forcedReason !== "" ? "C" : barems?.result
      ) as ClaimResponsabilityType;
    },
    setDamagedPart(state, action: PayloadAction<DamagedPartPair>) {
      if (state.damagedParts?.length === 0 && action.payload.index === 0) {
        state.damagedParts = [action.payload.damagedPart];
      } else if (action.payload.index >= state.damagedParts?.length) {
        state.damagedParts = [
          ...state.damagedParts,
          action.payload.damagedPart,
        ];
      } else {
        state.damagedParts = state.damagedParts?.map((d, i) =>
          i === action.payload.index ? action.payload.damagedPart : d
        );
      }
    },
    addDamagedPart(state) {
      state.damagedParts = [
        ...state.damagedParts,
        {
          number: Date.now().toString(),
          subject: null,
          role: "",
          damages: [],
        } as DamagedPart,
      ];
    },
    removeDamagedPart(state, action: PayloadAction<number>) {
      state.damagedParts = state.damagedParts.filter(
        (d, i) => i !== action.payload
      );
    },
    updateCardWizardData(state, action: PayloadAction<CardWizardDataType>) {
      state.cardWizardData = action.payload;
    },
    updateClaimData(state, action: PayloadAction<ClaimModel>) {
      state.claimData = action.payload;
    },
    updateValidations(
      state,
      action: PayloadAction<ClaimAdditionalValidations>
    ) {
      state.additionalValidations = action.payload;
    },
    updateCounterpartData(state, action: PayloadAction<ClaimCounterpartModel>) {
      state.counterpartData = action.payload;
    },
    addAdditionalInfo(state, action: PayloadAction<AdditionalInfoDetails>) {
      const { damagedPartIndex } = action.payload;
      if (!state.damagedParts[damagedPartIndex].additionalInfo)
        state.damagedParts[damagedPartIndex].additionalInfo = [{}];
      else state.damagedParts[damagedPartIndex].additionalInfo?.push({});
    },
    setAdditionalInfo(state, action: PayloadAction<AdditionalInfoDetails>) {
      const { additionalInfo, additionalInfoIndex, damagedPartIndex } =
        action.payload;
      state.damagedParts[damagedPartIndex].additionalInfo![
        additionalInfoIndex!
      ] = additionalInfo!;
    },
    deleteAdditionalInfo(state, action: PayloadAction<AdditionalInfoDetails>) {
      const { additionalInfo, additionalInfoIndex, damagedPartIndex } =
        action.payload;
      state.damagedParts[damagedPartIndex].additionalInfo = state.damagedParts[
        damagedPartIndex
      ].additionalInfo!.filter((ai, i) => i !== additionalInfoIndex);
    },
    forceValidation(state) {},
    forceSerialize(state) {},
  },
});

export const {
  clearLocalStorage,
  clear,
  setDataEntryStatus,
  setClaimStatus,
  setPolicyData,
  updateCardWizardData,
  updateClaimData,
  updateValidations,
  updateCounterpartData,
  updateResponsabilityData,
  setDamagedPart,
  removeDamagedPart,
  addDamagedPart,
  addAdditionalInfo,
  setAdditionalInfo,
  deleteAdditionalInfo,
  forceValidation,
  forceSerialize,
} = activeClaimSlice.actions;

export default activeClaimSlice.reducer;
