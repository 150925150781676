import { useTranslation } from "react-i18next";
import {
    RequestMessage,
    SecondaryPracticeRequest,
} from "../../../../../../redux/apiSpecifications/apiCrud";
import { PanelStyled } from "../../../Commons/PanelHeaderWithButtons";
import { Collapse } from "antd";
import Message from "./Message";
import ManagePracticeRequestForm from "./ManagePracticeRequestForm";
import useManagePracticeRequestForm from "./useManagePracticeRequestForm";
import { ButtonConfirm } from "../../../../../Layout/Buttons";
import styled from "styled-components";

const FormWrapper = styled.div`
    margin-bottom: 1em;
    .button-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 1em;
        gap: 1em;
    }
`;

interface IManagePracticeRequestShowModeProps {
    practiceRequest: SecondaryPracticeRequest;
    mode: "closeWithMessage" | "reply" | "forward";
    onConfirmReplyMessage: (requestMessage: RequestMessage) => void;
    onConfirmForwardMessage: (requestMessage: RequestMessage) => void;
    onConfirmSendMessageAndCloseRequest: (requestMessage: RequestMessage) => void;
}

const ManagePracticeRequestEditMode = ({
    practiceRequest,
    mode,
    onConfirmReplyMessage,
    onConfirmForwardMessage,
    onConfirmSendMessageAndCloseRequest,
}: IManagePracticeRequestShowModeProps) => {
    const { t } = useTranslation();

    const { data, toList, canSubmit, handleDataChange } =
        useManagePracticeRequestForm(practiceRequest, mode);

    const handleOnSend = () => {
        const requestMessage = {
            messageText: data.text,
            receiverProfile: Number(data.to),
        } as RequestMessage;

        if (mode === "reply") {
            onConfirmReplyMessage(requestMessage);
        } else if (mode === "forward") {
            onConfirmForwardMessage(requestMessage);
        } else if (mode === "closeWithMessage") {
            onConfirmSendMessageAndCloseRequest(requestMessage);
        }
    };

    return (
        <>
            <FormWrapper>
                <ManagePracticeRequestForm
                    model={data}
                    toList={toList}
                    onChange={handleDataChange}
                />

                <div className="button-wrapper">
                    {mode === "closeWithMessage" ? (
                        <ButtonConfirm
                            onClick={handleOnSend}
                            disabled={!canSubmit}
                        >
                            {t("button-send-and-close-practice-request")!}
                        </ButtonConfirm>
                    ) : (
                        <ButtonConfirm
                            onClick={handleOnSend}
                            disabled={!canSubmit}
                        >
                            {t("button-send")!}
                        </ButtonConfirm>
                    )}
                </div>
            </FormWrapper>

            <Collapse>
                <PanelStyled
                    header={t(
                        "appraisals-details-tab-collapse-message-history"
                    )}
                    key="message-history"
                >
                    {practiceRequest.messages!.map((message) => (
                        <Message key={message.id!} message={message} />
                    ))}
                </PanelStyled>
            </Collapse>
            {/* <pre>{JSON.stringify(mode, null, 2)}</pre>*/}
            {/* <pre>{JSON.stringify(practiceRequest, null, 2)}</pre>  */}
        </>
    );
};

export default ManagePracticeRequestEditMode;
