import { useMemo } from "react";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import { useTranslation } from "react-i18next";

interface IPracticeRejectedForOutOfCoverageDescriptionProps {
    modifyDetailsJson: Modifydetail[];
}

const PracticeRejectedForOutOfCoverageDescription = ({
    modifyDetailsJson,
}: IPracticeRejectedForOutOfCoverageDescriptionProps) => {
    const { t } = useTranslation();

    const appraiserFullName: string = useMemo(() => {
        const value = modifyDetailsJson.find(
            (_) => _.key === "appraiser_full_name"
        );

        return value?.value ?? "";
    }, [modifyDetailsJson]);

    return (
        <>
            {t("historical-actions-practice-rejected-for-out-of-coverage-description", {
                appraiser_full_name: appraiserFullName,
            })}
        </>
    );
};

export default PracticeRejectedForOutOfCoverageDescription;
