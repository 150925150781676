import { useEffect, useState } from "react";
import { UserUICommunication } from "../../../../../types/common.types";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { logUnhandledException } from "../../../../../utils/console";
import {
    BaseTenant,
    RoleType,
    UserConfigLists,
    useLazyGetUserConfigListsQuery,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { useTranslation } from "react-i18next";
import { UserConfigFormModel } from "./UserConfigFormModel";
import { UserItem } from "../../UserItem";
import { updateObject } from "../../../../../utils/updateObject";

type UserConfigFormState = {
    data: UserConfigFormModel;
    canSubmit: boolean;
};

const useUserConfigForm = (user: UserItem) => {
    const { t } = useTranslation();

    const { authorization, activeRole } = useSelector(
        (rootState: RootState) => rootState.user
    );

    const checkIfCanSubmit = (data: UserConfigFormModel) => {
        const { userRoleIds, userTenantIds } = data;

        return userRoleIds.length > 0 && userTenantIds.length > 0;
    };

    const initState = (user: UserItem, roleTypes: RoleType[]) => {
        const data: UserConfigFormModel = {
            roleType: "",
            userRoleIds: [...user.roles!].map((role) => role.id!.toString()),
            userTenantIds: [...user.tenants!].map((tenant) =>
                tenant.id!.toString()
            ),
        };

        if (user.roles!.length > 0) {
            const roleCode = user.roles![0].roleCode;

            const matchingRoleType = roleTypes.find((roleType) =>
                roleType.roles!.some((role) => role.roleCode === roleCode)
            );

            if (matchingRoleType) {
                data.roleType = matchingRoleType.roleTypeCode!;
            }
        }

        return {
            data,
            canSubmit: checkIfCanSubmit(data),
        } as UserConfigFormState;
    };

    const [state, setState] = useState<UserConfigFormState>(
        initState(user, [])
    );

    const [userUICommunications, setUserUICommunications] =
        useState<UserUICommunication>({
            action: "idle",
        } as UserUICommunication);

    const [roleTypes, setRoleTypes] = useState<RoleType[]>([]);
    const [enabledTenants, setEnabledTenants] = useState<BaseTenant[]>([]);

    const [loadUserConfigList] = useLazyGetUserConfigListsQuery();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            setUserUICommunications({
                action: "load",
                status: "in-progress",
            } as UserUICommunication);

            const response = await loadUserConfigList({
                authorization: authorization,
                activeRole: activeRole!,
            });

            if (response.isSuccess && response.data) {
                const responseData = response.data as UserConfigLists;

                setRoleTypes(responseData.roleTypes!);
                setEnabledTenants(responseData.enabledTenants!);

                setState(initState(user, responseData.roleTypes!));

                setUserUICommunications({
                    action: "idle",
                } as UserUICommunication);
            } else {
                const status = "";
                setUserUICommunications({
                    action: "load",
                    status: "done",
                    result: "error",
                    errorDetails: t(`http-error-${status}`),
                } as UserUICommunication);
            }
        } catch (err) {
            const status = "";

            setUserUICommunications({
                action: "load",
                status: "done",
                result: "error",
                errorDetails: t(`http-error-${status}`),
            } as UserUICommunication);

            logUnhandledException(err); // if this point is reached then it's not an error, it's a bug
        }
    };

    const handleDataChange = (value: any, field: string) => {
        const { data } = state;

        if (field === "userTenantIds") {
            if (!Array.isArray(value)) {
                value = value.split(",");
            }
        }

        if (field === "userRoleIds") {
            if (!Array.isArray(value)) {
                value = [value];
            }
        }

        const updatedData = updateObject(data, value, field);

        if (field === "roleType") {
            updatedData.userRoleIds = [];
            updatedData.userTenantIds = [];
        }

        setState({
            ...state,
            data: { ...updatedData },
            canSubmit: checkIfCanSubmit(updatedData),
        });
    };

    return {
        data: state.data,
        canSubmit: state.canSubmit,
        userUICommunications,
        roleTypes,
        enabledTenants,
        handleDataChange,
    };
};

export default useUserConfigForm;
