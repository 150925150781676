import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const TenantFeesHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;

    .fee-name {
        width: 8.5em;
        margin-right: 1em;
        overflow: hidden;

        .fee-fee-text {
            font-size: 0.9em;
            text-transform: uppercase;
            text-align: center;
        }
    }
`;

interface ITenantFeesHeaderProps {
    feeCodes?: string[];
}

const TenantFeesHeader = ({ feeCodes }: ITenantFeesHeaderProps) => {
    const { t } = useTranslation();

    return (
        <TenantFeesHeaderWrapper>
            {feeCodes?.map((feeCode) => (
                <div key={feeCode} className="fee-name">
                    <div className="fee-fee-text">
                        <Tooltip title={t(`fee-name-tooltip-${feeCode}`)}>
                            {t(`fee-name-label-${feeCode}`)}
                        </Tooltip>
                    </div>
                </div>
            ))}
        </TenantFeesHeaderWrapper>
    );
};

export default TenantFeesHeader;
