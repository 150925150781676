import { useTranslation } from "react-i18next";
import {
  SearchedPoliciesItem,
  SearchedPoliciesList,
} from "../../../redux/apiSpecifications/apiPolicies";
import { TableWrapper } from "../../../style/TableWrapper";
import { useEffect, useMemo, useState } from "react";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";
import { CellWrapper } from "../../../style/CellWrapper";
import { dateToNumber } from "../../../utils/date";

interface IPoliciesListProps {
  data: SearchedPoliciesList;
  onSelectItem?: (item: PoliciesListItem) => void;
  onPaginationChange: (pageIndex: number, pageSize: number) => void;
}

const PoliciesList = ({
  data,
  onSelectItem,
  onPaginationChange,
}: IPoliciesListProps) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: data.currentPageNum! - 1,
    pageSize: data.pageLen!,
  });

  useEffect(() => {
    if (
      data.currentPageNum! - 1 !== pagination.pageIndex ||
      data.pageLen! !== pagination.pageSize
    ) {
      onPaginationChange(pagination.pageIndex + 1, pagination.pageSize);
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  const items = useMemo(
    () =>
      data.items?.map((item) => {
        return {
          ...item,
          effectDateNumber: dateToNumber(item.effectDate),
          expirationDateNumber: dateToNumber(item.expirationDate),
        } as PoliciesListItem;
      }) || [],
    [data.items]
  );

  const columns = useMemo<MRT_ColumnDef<PoliciesListItem>[]>(() => {
    return [
      {
        accessorKey: "company",
        header: t("policies-list-company-column-header"),
        size: 100,
        Cell: ({ row }) => <CellWrapper>{row.original.company}</CellWrapper>,
      },
      {
        accessorKey: "number",
        header: t("policies-list-number-column-header"),
        size: 100,
        Cell: ({ row }) => <CellWrapper>{row.original.number}</CellWrapper>,
      },
      {
        accessorKey: "insuredVehiclePlateNumber",
        header: t("policies-list-insured-vehicle-plate-number-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>{row.original.insuredVehiclePlateNumber}</CellWrapper>
        ),
      },
      {
        accessorKey: "vehicleOwnerName",
        header: t("policies-list-vehicle-owner-name-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>{row.original.vehicleOwnerName}</CellWrapper>
        ),
      },
      {
        accessorKey: "effectDateNumber",
        header: t("policies-list-effect-date-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>{row.original.effectDate}</CellWrapper>
        ),
      },
      {
        accessorKey: "expirationDateNumber",
        header: t("policies-list-expiration-date-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>{row.original.expirationDate}</CellWrapper>
        ),
      },
    ];
  }, [data.items]);

  const handleSelectItem = (item: PoliciesListItem) => {
    if (onSelectItem) onSelectItem(item);
  };

  return (
    <TableWrapper>
      <MaterialReactTable
        columns={columns}
        data={items}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableHiding={false}
        enableFilters={false}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableSorting={false}
        enableTopToolbar={false}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            handleSelectItem(row.original);
          },
          sx: {
            cursor: onSelectItem !== undefined ? "pointer" : "default",
          },
        })}
        state={{
          pagination,
        }}
        manualPagination
        rowCount={data.totalCount!}
        onPaginationChange={setPagination}
      />

      {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
    </TableWrapper>
  );
};

export default PoliciesList;

export type PoliciesListItem = SearchedPoliciesItem & {
  effectDateNumber?: string;
  expirationDateNumber?: string;
};
