import { Button, Tooltip } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import dekra from "../../style/dekra";
import styled from "styled-components";

const buttonMediumStyle = {
  textTransform: "uppercase",
  borderRadius: "5px",
};

const buttonSmallStyle = {
  textTransform: "uppercase",
  borderRadius: "5px",
  fontSize: "0.9em",
};

/**
 * @description
 * @interface ButtonProps
 */
interface ButtonProps {
  children: string | JSX.Element;
  icon?: JSX.Element;
  style?: any;
  backgroundColor?: string;
  className?: string;
  disabled?: boolean;
  onClick: (val: any) => void;
  size?: SizeType;
  dataTestId?: string | undefined;
  name?: string;
}

export const ButtonConfirm = (props: ButtonProps) => {
  const handleClick = (e: any) => {
    e.stopPropagation();
    props.onClick(e);
  };

  const backgroundColor = props.disabled
    ? "#fff"
    : props.backgroundColor || dekra.primaryColor;

  return (
    <Button
      type="primary"
      disabled={props.disabled}
      className={props.className}
      style={Object.assign(
        {},
        {
          ...buttonMediumStyle,
          backgroundColor: backgroundColor,
          borderColor: props.disabled ? "#ddd" : backgroundColor,
          color: props.disabled ? "#ddd" : dekra.secondaryColors.white,
        },
        props.style
      )}
      onClick={handleClick}
      icon={props.icon}
      size={props.size || "middle"}
      data-testid={props.dataTestId}
      name={props.name}
    >
      {props.children}
    </Button>
  );
};

export const ButtonCancel = (props: ButtonProps) => {
  const handleClick = (e: any) => {
    e.stopPropagation();
    props.onClick(e);
  };

  return (
    <Button
      type="default"
      size="middle"
      disabled={props.disabled}
      style={Object.assign({}, props.style, buttonMediumStyle)}
      className={props.className}
      onClick={handleClick}
      icon={props.icon}
      data-testid={props.dataTestId}
    >
      {props.children}
    </Button>
  );
};

export const ButtonDelete = (props: ButtonProps) => (
  <Button
    type="primary"
    danger
    disabled={props.disabled}
    size="small"
    style={Object.assign({}, props.style, buttonSmallStyle)}
    className={props.className}
    onClick={props.onClick}
    icon={props.icon}
    data-testid={props.dataTestId}
  >
    {props.children}
  </Button>
);

export const ButtonAdd = (props: ButtonProps) => (
  <Button
    type="ghost"
    size="middle"
    style={Object.assign({}, props.style, buttonMediumStyle)}
    className={props.className}
    onClick={props.onClick}
    icon={props.icon}
    disabled={props.disabled}
    data-testid={props.dataTestId}
  >
    {props.children}
  </Button>
);

export const ButtonClear = (props: ButtonProps) => (
  <Button
    type="default"
    size="middle"
    disabled={props.disabled}
    style={Object.assign({}, props.style, buttonMediumStyle)}
    className={props.className}
    onClick={props.onClick}
    icon={props.icon}
    data-testid={props.dataTestId}
  >
    {props.children}
  </Button>
);

const PanelHeaderIconButtonWrapper = styled.div`
  font-size: 1.2em;
  height: 22px;
`;

export interface IPanelHeaderIconButtonProps {
  icon?: React.ReactNode;
  tooltip?: string | undefined | null;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const PanelHeaderIconButton = ({
  icon,
  tooltip,
  onClick,
}: IPanelHeaderIconButtonProps) => {
  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="bottom">
        <PanelHeaderIconButtonWrapper onClick={onClick}>
          {icon}
        </PanelHeaderIconButtonWrapper>
      </Tooltip>
    );
  }

  return (
    <PanelHeaderIconButtonWrapper onClick={onClick}>
      {icon}
    </PanelHeaderIconButtonWrapper>
  );
};