import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { mainTabs as testIds } from "../../../config/testIds";
import {
  DashboardWrapper,
  LoadingInTabWrapper,
  RefreshInTabWrapper,
} from "../../../style/DashbordWidgetWrappers";
import { RootState } from "../../../redux/store";
import { useEffect, useMemo, useState } from "react";
import { IconLoading } from "../../../config/icons";
import { useTranslation } from "react-i18next";
import {
  Communication,
  DashboardE4Summary,
  GetRequestListApiArg,
  GetUserCommunicationsListApiArg,
  Request,
  useLazyGetE4PracticesSummaryByTenantQuery,
  useLazyGetRequestListQuery,
  useLazyGetUserCommunicationsListQuery,
} from "../../../redux/apiSpecifications/apiCrud";
import { useInterval } from "../../../hooks/useInterval";
import { setInfoAlwaysVisibleTop } from "../../../redux/features/userSlice";
import { AggregateSummaryDataE4 } from "./common-utils";
import AppraisalsCommunications, {
  AppraisalsCommunicationSelectionType,
} from "../../Widgets/Appraisals/AppraisalsCommunications";
import AppraisalsList from "../../Widgets/Appraisals/AppraisalsList";
import CommunicationList from "../../Widgets/Communications/CommunicationsList";
import PracticesInVerification from "../../Widgets/Appraisals/AppraisalsInVerificationChart";
import TimerPie, { TimerUpdateWrapper } from "../../Layout/TimerPie";
import { useAuthorization } from "../../../hooks/useAuthorization";
import AppraisalsToBeAssignedChart from "../../Widgets/Appraisals/AppraisalsToBeAssignedChart";
import AppraisalsRequestsChartST from "../../Widgets/Appraisals/AppraisalsRequestsCharts/AppraisalsRequestsChartST";
import { AppraisalsRequestsChartSelectionType } from "../../Widgets/Appraisals/AppraisalsRequestsCharts/AppraisalsRequestsChartSelectionType";
import { Tenant } from "../../../redux/apiSpecifications/apiFesf";
import PracticeRequestsList from "../../Widgets/PracticeRequests/PracticeRequestsList";

const DashboardE4Wrapper = styled(DashboardWrapper)`
  display: grid;
  grid-template-areas:
    "in-verification to-be-assigned refresh"
    "comunications comunications comunications"
    "resultTable resultTable resultTable";
  grid-template-columns: 3fr 2fr 0.15fr;
  column-gap: 1em;
  row-gap: 1em;
`;

type laodingStatusType = "loading" | "idle" | "refreshing";

type FilterThecnicalRoom =
  | "isNegative"
  | "isRejected"
  | "inVerification"
  | "isToBeAssigned";

export type FiltersValues = {
  donatType?: string;
  donatSelection?: string;
};

const defaultFilters: FiltersValues = {
  donatType: "inVerification",
  donatSelection: "RCA",
};

interface IDashBoard_E4Props {
  tabKey?: number;
}

const DashBoard_E4 = (props: IDashBoard_E4Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [retrieveSummaryData] = useLazyGetE4PracticesSummaryByTenantQuery();
  const [retriveCommunications] = useLazyGetUserCommunicationsListQuery();
  const [getRequestList] = useLazyGetRequestListQuery();

  const { getAuthorization } = useAuthorization();

  const { selectedTenants, activeRole } = useSelector(
    (state: RootState) => state.user
  );

  const [loadingStatus, setLoadingStatus] = useState<laodingStatusType>("idle");
  const [filteringType, setFilteringType] = useState<
    "in-verification" | "cancelled" | "communications" | "to-be-assigned"
  >("in-verification");

  const [filterThecnicalRoom, setFilterThecnicalRoom] =
    useState<FilterThecnicalRoom>("inVerification");

  const [communicationsFilterSelection, setCommunicationsFilterSelection] =
    useState<AppraisalsCommunicationSelectionType>({
      senderType: "",
      categoryCode: "",
      status: "",
    });

  const [requestsFilterSelection, setRequestsFilterSelection] =
    useState<AppraisalsRequestsChartSelectionType>({
      senderType: "",
      categoryCode: "",
    });

  const { activeKey } = useSelector((state: RootState) => state.mainTabs);
  const autoRefreshTabData = useSelector(
    (state: RootState) => state.user.configParams?.autoRefreshTabData || 600
  ); // 10 minutes

  const {
    restart: restartRefrehInterval,
    reset: resetRefrehInterval,
    clear: clearRefreshInterval,
    percentage,
    remainingTime,
  } = useInterval({
    seconds: autoRefreshTabData,
    callback: () => {
      setLoadingStatus("refreshing");
      loadPractices("refreshing", true);
    },
  });

  const [allData, setAllData] = useState<DashboardE4Summary>();
  const [communicationsData, setCommunicationsData] = useState<Communication[]>(
    []
  );

  const [requestsData, setRequestsData] = useState<Request[]>([]);

  const [filters, setFilters] = useState(defaultFilters);
  const [filteredByManagedData, setFilteredByManagedData] = useState<any>();
  const [blockTopOpen, setBlockTopOpen] = useState(true);

  useEffect(() => {
    if (activeKey === props.tabKey) {
      (async () => {
        setLoadingStatus("refreshing");
        await loadPractices("refreshing", true);
        restartRefrehInterval();
      })();
    } else clearRefreshInterval();
  }, [activeKey]);

  const loadPractices = async (
    loadingStatus: laodingStatusType,
    keepFilter?: boolean
  ) => {
    if (!selectedTenants) return;
    dispatch(
      setInfoAlwaysVisibleTop({
        type: "empty",
        message: "",
      })
    );

    const tenantsSummaryData: DashboardE4Summary[] = [];

    for (let tenant of selectedTenants) {
      const idTenant = tenant.id!;

      const response = await retrieveSummaryData({
        idTenant,
        authorization: await getAuthorization(),
      });

      const { data, error, isSuccess, isError } = response;

      if (isSuccess && data) {
        tenantsSummaryData.push(data as DashboardE4Summary);
      } else if (isError) {
        dispatch(
          setInfoAlwaysVisibleTop({
            type: "error",
            message: t("http-error-loading-dashboard"),
          })
        );
        setLoadingStatus("idle");
        return;
      } else {
        console.log("unknown error");
      }
    }

    const aggregatedSummaryData = AggregateSummaryDataE4(tenantsSummaryData);

    if (loadingStatus === "loading" || loadingStatus === "refreshing") {
      setAllData(aggregatedSummaryData);
    }

    setLoadingStatus("idle");
  };

  useEffect(() => {
    handleFilterInVerification("inVerification", "RCA");
  }, [allData]);

  const loadCommunications = async (
    selection: AppraisalsCommunicationSelectionType
  ) => {
    if (!selectedTenants) return;

    dispatch(
      setInfoAlwaysVisibleTop({
        type: "empty",
        message: "",
      })
    );

    setLoadingStatus("refreshing");

    const params: any = {
      authorization: await getAuthorization(),
      tenants: selectedTenants.map((t) => t.id!),
    };

    if (selection.senderType) params.senderType = selection.senderType;
    if (selection.categoryCode) params.categoryCode = selection.categoryCode;
    if (selection.status) params.statusCode = selection.status;

    const response = await retriveCommunications(
      params as GetUserCommunicationsListApiArg
    );
    const { data, error, isSuccess, isError } = response;

    if (isSuccess && data) {
      setCommunicationsData(data as Communication[]);
    } else if (isError) {
      dispatch(
        setInfoAlwaysVisibleTop({
          type: "error",
          message: t("http-error-loading-dashboard"),
        })
      );
      setLoadingStatus("idle");
      return;
    } else {
      console.log("unknown error");
    }

    dispatch(
      setInfoAlwaysVisibleTop({
        type: "empty",
        message: "",
      })
    );

    setLoadingStatus("idle");
  };

  const loadRequests = async (
    selection: AppraisalsRequestsChartSelectionType
  ) => {
    if (!selectedTenants) return;

    dispatch(
      setInfoAlwaysVisibleTop({
        type: "empty",
        message: "",
      })
    );

    setLoadingStatus("refreshing");

    const params: GetRequestListApiArg = {
      authorization: await getAuthorization(),
      activeRole: activeRole!,
      tenants: selectedTenants.map((t: Tenant) => t.id!),
      lastMessageSenderType: selection.lastMessageSenderType ?? "",
      lastMessageReceiverType: selection.lastMessageReceiverType ?? "",
      categoryCode: selection.categoryCode ?? "",
      statusCode: selection.statusCode,
    };

    const response = await getRequestList(params);

    const { data, error, isSuccess, isError } = response;

    if (isSuccess && data) {
      setRequestsData(data as Request[]);
    } else if (isError) {
      dispatch(
        setInfoAlwaysVisibleTop({
          type: "error",
          message: t("http-error-loading-dashboard"),
        })
      );
      setLoadingStatus("idle");
      return;
    } else {
      console.log("unknown error");
    }

    dispatch(
      setInfoAlwaysVisibleTop({
        type: "empty",
        message: "",
      })
    );

    setLoadingStatus("idle");
  };

  useEffect(() => {
    if (!selectedTenants) return;
    setLoadingStatus("loading");
    loadPractices("loading");
  }, [selectedTenants]);

  const handleFilterInVerification = (
    totalType: string | undefined,
    key: string | undefined
  ) => {
    const updatedFilters = {
      ...defaultFilters,
      donatType: totalType,
      donatSelection: key?.toUpperCase() || "",
    };
    setFilters(updatedFilters as FiltersValues);
    setFilterThecnicalRoom(totalType as FilterThecnicalRoom);

    const practices = (allData?.practiceList || []).filter((practice) => {
      let toInclude = true;

      switch (totalType) {
        case "isNegative":
          toInclude =
            toInclude &&
            practice.practiceStatusAggr === "1" &&
            practice.isNegative === true;
          break;
        case "isRejected":
          toInclude =
            (toInclude &&
              practice.practiceStatusAggr === "2" &&
              practice.isRejected === true) ||
            false;
          break;
        case "inVerification":
          toInclude = toInclude && practice.status === "IV";
      }

      return toInclude;
    });

    let listByCategory = allData?.practiceList
      ?.map((c) => c.rejectionReasonCode)
      .filter((l) => l !== null);

    const isReject = listByCategory?.reduce((obj, category) => {
      const valueRejectedByCategory =
        allData?.practiceList?.filter((f) => f.rejectionReasonCode === category)
          .length || 0;
      const indexCategory = category as keyof typeof valueRejectedByCategory;
      return { ...obj, [indexCategory]: valueRejectedByCategory };
    }, {});

    const practiceToVerification = {
      total: allData?.practiceList?.length,
      isNegative: {
        rca: allData?.practiceList?.filter(
          (practice) =>
            practice.status === "IV" &&
            practice.isNegative === true &&
            practice.claimsBranchGroup === "RCA"
        ).length,
        cvt: allData?.practiceList?.filter(
          (practice) =>
            practice.status === "IV" &&
            practice.isNegative === true &&
            practice.claimsBranchGroup === "CVT"
        ).length,
      },
      isRejected: isReject,
      inVerification: {
        rca: allData?.practiceList?.filter(
          (practice) =>
            practice.status === "IV" && practice.claimsBranchGroup === "RCA"
        ).length,
        cvt: allData?.practiceList?.filter(
          (practice) =>
            practice.status === "IV" && practice.claimsBranchGroup === "CVT"
        ).length,
      },
    };

    const communicationSummary = allData?.communicationSummary;

    const result = {
      practiceToVerification,
      communicationSummary: communicationSummary,
      practiceList: allData?.practiceList,
      toBeVerified: allData?.toBeVerified,
      negativeToBeVerified: allData?.negativeToBeVerified,
      rejectedByCategory: allData?.rejectedByCategory,
    };

    setFilteredByManagedData(result);
    setBlockTopOpen(true);
  };

  const handleFilterToBeAssigned = (
    totalType: string | undefined,
    key: string | undefined
  ) => {
    setFilteringType("to-be-assigned");

    const updatedFilters = {
      ...defaultFilters,
      donatType: totalType,
      donatSelection: key?.toUpperCase() || "",
    };

    setFilters(updatedFilters as FiltersValues);
    setFilterThecnicalRoom(totalType as FilterThecnicalRoom);

    setBlockTopOpen(true);
  };

  const handleFilterCommunications = (
    selection: AppraisalsCommunicationSelectionType
  ) => {
    setFilteringType("communications");
    setCommunicationsFilterSelection(selection);

    loadCommunications(selection);
    setBlockTopOpen(false);
  };

  const handleFilterRequests = (
    selection: AppraisalsRequestsChartSelectionType
  ) => {
    setFilteringType("communications");
    setRequestsFilterSelection(selection);

    loadRequests(selection);
    setBlockTopOpen(false);
  };

  const practicesToShowInList = useMemo(() => {
    const inVerification = allData?.practiceList?.filter(
      (x) => x.status === "IV" && x.claimsBranchGroup === filters.donatSelection
    );

    const isNegative = allData?.practiceList?.filter(
      (x) =>
        x.status === "IV" &&
        x.isNegative === true &&
        x.claimsBranchGroup === filters.donatSelection
    );

    const isRejected = allData?.practiceList?.filter(
      (x) =>
        x.practiceStatusAggr === "2" &&
        x.isRejected === true &&
        x.rejectionReasonCode === filters.donatSelection
    );

    const isToBeAssigned = allData?.practiceList?.filter(
      (x) =>
        x.status === "ST" &&
        (filters.donatSelection === "" ||
          x.practiceTypeCode === filters.donatSelection)
    );

    return filterThecnicalRoom === "isNegative" && loadingStatus === "idle"
      ? isNegative
      : filterThecnicalRoom === "isRejected" && loadingStatus === "idle"
      ? isRejected
      : filterThecnicalRoom === "inVerification" && loadingStatus === "idle"
      ? inVerification
      : filterThecnicalRoom === "isToBeAssigned" && loadingStatus === "idle"
      ? isToBeAssigned
      : [];
  }, [
    filters,
    allData,
    filteringType,
    loadingStatus,
    filteredByManagedData,
    filterThecnicalRoom,
  ]);

  return (
    <div data-testid={testIds.dashboards.E4}>
      {loadingStatus === "loading" && (
        <LoadingInTabWrapper>
          <div className="box" data-testid={testIds.E4.loader}>
            {IconLoading}
          </div>
        </LoadingInTabWrapper>
      )}
      {(loadingStatus === "refreshing" || loadingStatus === "idle") && (
        <>
          <DashboardE4Wrapper>
            <PracticesInVerification
              practices={filteredByManagedData?.practiceToVerification || []}
              onSelect={handleFilterInVerification}
              isOpen={blockTopOpen}
              totalSelected={undefined}
              statusSelected={undefined}
              gridArea="in-verification"
              isActive={filteringType === "in-verification"}
              filtersValues={filters}
            />

            <AppraisalsToBeAssignedChart
              toBeAssigned={allData?.toBeAssigned || []}
              onSelect={handleFilterToBeAssigned}
              isOpen={blockTopOpen}
              gridArea="to-be-assigned"
              isActive={filteringType === "to-be-assigned"}
              filtersValues={filters}
            />

            <AppraisalsRequestsChartST
              isActive={filteringType === "communications"}
              userRequestsSummary={allData?.userRequestsSummary!}
              gridArea={"comunications"}
              onSelect={handleFilterRequests}
              isOpen={false}
              selection={requestsFilterSelection}
            />

            {/* <AppraisalsCommunications
                            isActive={filteringType === "communications"}
                            communications={allData?.communicationSummary}
                            gridArea={"comunications"}
                            onSelect={handleFilterCommunications}
                            selection={communicationsFilterSelection}
                            isOpen={false}
                        /> */}

            <TimerUpdateWrapper height="50px">
              <TimerPie
                percentage={percentage as number}
                tooltip={`${t(
                  "timer-dashboard-refresh-tooltip-1"
                )!}  ${remainingTime}`}
                innerText={remainingTime as string}
                bgColor="#32b677"
                gridArea="refresh"
                onClick={() => {
                  setLoadingStatus("refreshing");
                  loadPractices("refreshing", true);
                  resetRefrehInterval();
                }}
              />
            </TimerUpdateWrapper>

            {filteringType === "in-verification" && (
              <AppraisalsList
                items={practicesToShowInList}
                gridArea={"resultTable"}
                isActive={activeKey === props.tabKey}
                scenario="dashboard_st"
                onRefresh={() => {}}
              />
            )}
            {filteringType === "to-be-assigned" && (
              <AppraisalsList
                items={practicesToShowInList}
                gridArea={"resultTable"}
                isActive={activeKey === props.tabKey}
                scenario="dashboard_st"
                onRefresh={() => {}}
              />
            )}
            {filteringType === "communications" && (
              <PracticeRequestsList
                items={requestsData}
                gridArea={"resultTable"}
              />
              // <CommunicationList
              //     items={communicationsData}
              //     gridArea={"resultTable"}
              // />
            )}
          </DashboardE4Wrapper>

          {loadingStatus === "refreshing" && (
            <RefreshInTabWrapper>
              <div className="box" data-testid={testIds.E4.loader}>
                {IconLoading}
              </div>
            </RefreshInTabWrapper>
          )}
        </>
      )}
    </div>
  );
};

export default DashBoard_E4;
