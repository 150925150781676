import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DonutChart, { DonutDataItemType } from "../../Charts/DonutChart";
import { useEffect, useState } from "react";
import { widgets as testIds } from "../../../config/testIds";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import WidgetHeader from "../WidgetHeader";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { FiltersValues as FilterValueE1 } from "../../MainTabs/Dashboards/Dashboard-E1";
import { FiltersValues as FilterValueE4 } from "../../MainTabs/Dashboards/Dashboard-E4";

const PracticeInVerificationWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    align-items: stretch;

    max-height: 2.5em;
    transition: max-height 1s;

    &.open {
        max-height: 100em;
    }
`;

export type PracticeInVerificationType = {
    total: number | undefined;
    isDocumental:
        | {
              urgent: number | undefined;
              due: number | undefined;
              new: number | undefined;
              suspended: number | undefined;
          }
        | undefined;
    isNegative:
        | {
              rca: number | undefined;
              cvt: number | undefined;
          }
        | undefined;
    isRejected: any;
    inVerification:
        | {
              rca: number | undefined;
              cvt: number | undefined;
          }
        | undefined;
};

export type ChartData = {
    items: DonutDataItemType[];
    label: string;
    type: string;
};

export interface IPracticesInVerificationProps {
    practices: PracticeInVerificationType | undefined;
    totalSelected: string | undefined;
    statusSelected: string | undefined;
    onSelect: (totalType: string | undefined, key: string | undefined) => void;
    gridArea?: string;
    isActive?: boolean;
    isOpen?: boolean;
    filtersValues?: FilterValueE1 | FilterValueE4;
}

const PracticesInVerification = (props: IPracticesInVerificationProps) => {
    const { t } = useTranslation();
    const {
        practices,
        onSelect,
        statusSelected = "in-verification",
        totalSelected,
        gridArea,
        isActive = false,
        filtersValues,
    } = props;

    const [donatsData, setDonatsData] = useState<ChartData[]>([]);
    const [isOpen, setIsOpen] = useState(props.isOpen);

    const { activeRole } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    const hasSomePractice = (type: any) => type?.rca || type?.cvt;

    const defaultTotalSelected = totalSelected
        ? totalSelected
        : hasSomePractice(practices?.isDocumental)
        ? "isDocumental"
        : hasSomePractice(practices?.isNegative)
        ? "isNegative"
        : hasSomePractice(practices?.isRejected)
        ? "isRejected"
        : "";

    const getFillIsRejected = (key: string): string => {
        let fill: string = "";

        switch (key) {
            case "MAD":
                fill = "#00B9E8";
                break;
            case "MAF":
                fill = "#72A0C1";
                break;
            case "VCE":
                fill = "#1E90FF";
                break;
            case "MID":
                fill = "#2072AF";
                break;
            /////////////////////////////
            case "IAE":
                fill = "#2072AF";
                break;
            case "DAE":
                fill = "#2072AF";
                break;
            case "EIC":
                fill = "#2072AF";
                break;
            case "ETG":
                fill = "#2072AF";
                break;
            //////////////////////////
            case "NRR":
                fill = "#5D8AA8";
                break;
            case "NFZ":
                fill = "#558B2F";
                break;
            case "NSG":
                fill = "#6CB4EE";
                break;
            case "NSA":
                fill = "#246BCE";
                break;
        }

        return fill;
    };

    useEffect(() => {
        if (!practices) return;

        let isDocumentalDonut: any[] = [];
        if (activeRole === "E1") {
            isDocumentalDonut = [
                {
                    key: "urgent",
                    name: t("appraisals-dashboard-to-manage-urgent"),
                    value: practices?.isDocumental?.urgent,
                    fill: "#E24D28",
                },
                {
                    key: "due",
                    name: t("appraisals-dashboard-to-manage-due"),
                    value: practices?.isDocumental?.due,
                    fill: "#FFCC00",
                },
                {
                    key: "new",
                    name: t("appraisals-dashboard-to-manage-new"),
                    value: practices?.isDocumental?.new,
                    fill: "#8FBC8F",
                },
                {
                    key: "suspended",
                    name: t("appraisals-dashboard-to-manage-suspended"),
                    value: practices?.isDocumental?.suspended,
                    fill: "#8B8D8F",
                },
            ];
        }

        const isNegativeDonut = [
            {
                key: "rca",
                name: t("appraisals-dashboard-to-manage-rca"),
                value: practices?.isNegative?.rca,
                fill: "#CA1F7B",
            },
            {
                key: "cvt",
                name: t("appraisals-dashboard-to-manage-cvt"),
                value: practices?.isNegative?.cvt,
                fill: "#F56FA1",
            },
        ];

        const isRejectedDonut = practices?.isRejected
            ? Object.keys(practices?.isRejected).map((key) => {
                  return {
                      key,
                      name: t(`appraisals-dashboard-to-manage-${key}`),
                      value: practices?.isRejected[key],
                      fill: getFillIsRejected(key),
                  };
              })
            : [];

        const inVerificationDonut = [
            {
                key: "rca",
                name: t("appraisals-dashboard-to-manage-rca"),
                value: practices?.inVerification?.rca,
                fill: "#CA1F7B",
            },
            {
                key: "cvt",
                name: t("appraisals-dashboard-to-manage-cvt"),
                value: practices?.inVerification?.cvt,
                fill: "#F56FA1",
            },
        ];

        let result: ChartData[] = [];
        if (activeRole === "E1") {
            result = [
                {
                    type: "inVerification",
                    items: inVerificationDonut,
                    label: t(
                        `appraisals-dashboard-to-manage-is-verification-donut`
                    ),
                },
                {
                    type: "isNegative",
                    items: isNegativeDonut,
                    label: t(
                        `appraisals-dashboard-to-manage-is-negative-donut`
                    ),
                },
                {
                    type: "isDocumental",
                    items: isDocumentalDonut,
                    label: t(
                        `appraisals-dashboard-to-manage-is-documental-donut`
                    ),
                },
                {
                    type: "isRejected",
                    items: isRejectedDonut,
                    label: t(
                        `appraisals-dashboard-to-manage-is-rejected-donut`
                    ),
                },
            ];
        } else {
            result = [
                {
                    type: "inVerification",
                    items: inVerificationDonut,
                    label: t(
                        `appraisals-dashboard-to-manage-is-verification-donut`
                    ),
                },
                {
                    type: "isNegative",
                    items: isNegativeDonut,
                    label: t(
                        `appraisals-dashboard-to-manage-is-negative-donut`
                    ),
                },
                {
                    type: "isRejected",
                    items: isRejectedDonut,
                    label: t(
                        `appraisals-dashboard-to-manage-is-rejected-donut`
                    ),
                },
            ];
        }

        setDonatsData(result);
    }, [practices, defaultTotalSelected]);

    const handleFilterReset = () => onSelect("inVerification", "RCA");
    const handleOnOpen = () => setIsOpen(true);
    const handleOnClose = () => setIsOpen(false);

    const getCenterValue = (items: DonutDataItemType[]): string => {
        const sum = items.reduce((acc, cur) => {
            return acc + cur.value;
        }, 0);

        return String(sum);
    };

    return (
        <PracticeInVerificationWrapper
            className={`${isActive || isOpen ? "open" : ""}`}
            data-testid={testIds.appraisalsToManage.container}
            gridArea={gridArea}
        >
            <WidgetHeader
                title={t("appraisals-dashboard-in-evidence-title")}
                isActive={isActive}
                filterReset={handleFilterReset}
                onOpen={handleOnOpen}
                onClose={handleOnClose}
                isOpen={isOpen}
            />

            <div className="body">
                {donatsData.map((donatData, index) => {
                    let selected: string | undefined = "";
                    if (
                        isActive &&
                        filtersValues?.donatType === donatData.type
                    ) {
                        if (filtersValues?.donatType === "isRejected") {
                            selected = filtersValues.donatSelection;
                        } else {
                            selected =
                                filtersValues.donatSelection?.toLocaleLowerCase();
                        }
                    }

                    return (
                        <div className="col" key={index}>
                            <DonutChart
                                title={donatData.label}
                                data={donatData.items}
                                onClick={(key) => onSelect(donatData.type, key)}
                                centerValue={getCenterValue(donatData.items)}
                                selected={selected}
                            />
                        </div>
                    );
                })}
            </div>
        </PracticeInVerificationWrapper>
    );
};

export default PracticesInVerification;
