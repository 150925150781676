import { useTranslation } from "react-i18next";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import { useMemo } from "react";

type AppointmentCreatedDescriptionDetails = {
    appointment_date: string;
    appointment_time_slot: string;
};

interface IAppointmentCreatedDescriptionProps {
    modifyDetailsJson: Modifydetail[];
}

const AppointmentCreatedDescription = ({
    modifyDetailsJson,
}: IAppointmentCreatedDescriptionProps) => {
    const { t } = useTranslation();

    const appraiserAssignedDescriptionDetails: AppointmentCreatedDescriptionDetails =
        useMemo(() => {
            return {
                appointment_date:
                    modifyDetailsJson.find((_) => _.key === "appointment_date")
                        ?.value ?? "",
                appointment_time_slot:
                    modifyDetailsJson.find(
                        (_) => _.key === "appointment_time_slot"
                    )?.value ?? "",
            } as AppointmentCreatedDescriptionDetails;
        }, [modifyDetailsJson]);

    return (
        <>
            {t("historical-actions-appointment-created-description", {
                appointment_date:
                    appraiserAssignedDescriptionDetails.appointment_date,
                appointment_time_slot_description: t(
                    `appointment_time_slot_${appraiserAssignedDescriptionDetails.appointment_time_slot}_description`
                ),
            })}
        </>
    );
};

export default AppointmentCreatedDescription;
