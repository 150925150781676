import styled from "styled-components";
import dekra from "../../style/dekra";

import {
  IconArrowDown,
  IconArrowUp,
  IconRemoveFilter,
  IconUpdate,
} from "../../config/icons";

import { widgets } from "../../config/testIds";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

const HeaderWrapper = styled.div`
  display: flex;
  color: #333;
  background-color: #ededed;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;

  &.active {
    background-color: ${dekra.primaryColor};
    color: #fff;
  }

  .title {
    padding: 0.5em 2em;
    flex: 1;
  }

  .actions {
    display: flex;
    align-items: center;

    &.active {
      background-color: ${dekra.primaryColor};
      color: #fff;

      path {
        stroke: #fff;
      }
    }

    .icon-wrapper {
      font-size: 1.4em;
      margin-top: 6px;
      margin-right: 1em;
      cursor: pointer;
    }
  }
`;

interface IWidgetHeaderProps {
  isActive: boolean;
  title: string;
  filterReset?: () => void;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onButton1?: () => void;
  button1Text?: string;
  button1DataTestId?: string;
  content?: React.ReactNode;
}

const WidgetHeader = (props: IWidgetHeaderProps) => {
  const {
    isActive,
    title,
    filterReset,
    onOpen,
    isOpen,
    onClose,
    onButton1,
    button1Text,
    button1DataTestId,
    content,
  } = props;

  const { t } = useTranslation();

  return (
    <HeaderWrapper data-testid={widgets.header.container}>
      <div className={`title ${isActive ? "active" : ""}`}>{title}</div>

      <div className={`actions  ${isActive ? "active" : ""}`}>
        {content}
        {onButton1 && (
          <>
            <Tooltip title={t("button-apply")} placement="bottom">
              <div
                className="icon-wrapper"
                onClick={onButton1}
                data-testid={button1DataTestId || widgets.header.button1}
              >
                <IconUpdate />
              </div>
            </Tooltip>
          </>
        )}
        {filterReset && (
          <div
            className="icon-wrapper"
            onClick={filterReset}
            data-testid={button1DataTestId || widgets.header.buttonResetFilters}
          >
            <IconRemoveFilter />
          </div>
        )}
        {!isActive && onOpen && !isOpen && (
          <div className="icon-wrapper" onClick={onOpen}>
            <IconArrowDown />
          </div>
        )}

        {!isActive && isOpen && (
          <div className="icon-wrapper" onClick={onClose}>
            <IconArrowUp />
          </div>
        )}
      </div>
    </HeaderWrapper>
  );
};

export default WidgetHeader;
