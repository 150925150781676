import { useTranslation } from "react-i18next";
import { InputTextProps, InputTextStyled } from "../../style/Input";
import { ValidateStatusTypes } from "../../validation/subjectValidation";
import { InputVatNumber as testId } from "../../config/testIds";
import { AdditionalValidationField } from "../../types/common.types";

interface VatNumberProps extends InputTextProps {
  style?: any;
  onValidationDate?: (isValid: boolean) => void;
  value: string | undefined;
  validation: AdditionalValidationField;
  required?: boolean;
}

/**
 * Render an input field for fiscal code.
 */

const InputVatNumber = (props: VatNumberProps) => {
  const { t } = useTranslation();

  const handleOnChange = (val: string) => {
    props.onChange!(val);
  };

  const validateStatus = props.validation
    ?.validateStatus as ValidateStatusTypes;
  const help = t(props.validation.messageKey || "") || "";
  const label =
    t("select-business-vat-number-label") + (props.required ? " *" : "");
  return (
    <InputTextStyled
      label={label}
      placeholder={`${t("select-business-vat-number-placeholder")}`}
      onChange={handleOnChange}
      value={props.value}
      styleInput={props.style}
      validateStatus={validateStatus}
      help={help}
      required={false}
      dataTestId={props.dataTestId || testId.vatNumber}
      disabled={props.readOnly}
    />
  );
};

export default InputVatNumber;
