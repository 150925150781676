import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MainTabsTypes } from "../../config/const";
import { BaseRole } from "../apiSpecifications/apiFesf";

export type MainTabType = {
  key: number;
  label: string;
  type: MainTabsTypes;
  externalData: any;
};

export interface MainTabsState {
  tabs: MainTabType[];
  activeKey: number;
}

const buildInitialState = () => {
  return {
    tabs: [] as MainTabType[],
    activeKey: Date.now(),
  } as MainTabsState;
};

const buildDashboardData = (id: number, type: MainTabsTypes) =>
  ({
    key: Date.now(), //(id + 1).toString(),
    label: "Home",
    type,
    externalData: null,
  } as MainTabType);

export const mainTabsSlice = createSlice({
  name: "main-tabs",
  initialState: buildInitialState(),
  reducers: {
    setDashboards(state, action: PayloadAction<BaseRole[]>) {
      if (action.payload.length === 0)
        state.tabs = [buildDashboardData(0, "dashboard-NoRole")];
      else
        state.tabs = action.payload.map((role, id) =>
          buildDashboardData(id, `dashboard-${role.roleCode!}` as MainTabsTypes)
        );

      //state.activeKey = "1";
      state.activeKey = state.tabs[0].key;
    },
    updateHomeDashboard(state, action: PayloadAction<BaseRole>) {
      const { roleCode } = action.payload;
      const newDashboard = buildDashboardData(
        0,
        `dashboard-${roleCode!}` as MainTabsTypes
      );
      state.tabs = [
        newDashboard,
        //TODO: in caso bisogna mostrare tab raggiungingibili da due role, togliere commento e aggiungere condizione
        /*...state.tabs.slice(1)*/
      ];
      state.activeKey = newDashboard.key;
    },
    addTab(state, action: PayloadAction<MainTabType>) {
      const key = Date.now(); //(state.tabs.length + 1).toString();
      const { type, label, externalData } = action.payload;
      state.tabs.push({
        key,
        label,
        type,
        externalData: { ...externalData, tabKey: key },
      } as MainTabType);

      state.activeKey = key;
    },
    removeTab(state, action: PayloadAction<number>) {
      state.tabs = state.tabs.filter((tab) => tab.key !== action.payload);
      if (action.payload === state.activeKey)
        state.activeKey = state.tabs[0].key;
    },
    setExternalData(state, action: PayloadAction<{ key: number; data: any }>) {
      const { key, data } = action.payload;
      const tab = state.tabs.find((tab) => tab.key === key);
      if (tab) {
        tab.externalData = data;
      }
    },
    setActiveTab(state, action: PayloadAction<number>) {
      const exists = state.tabs.find((tab) => tab.key === action.payload);
      if (exists) state.activeKey = action.payload;
    },
  },
});

export const {
  setDashboards,
  updateHomeDashboard,
  addTab,
  removeTab,
  setExternalData,
  setActiveTab,
} = mainTabsSlice.actions;
export default mainTabsSlice.reducer;
