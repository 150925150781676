import { useTranslation } from "react-i18next";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import styled from "styled-components";
import { FormRow } from "../../../../../style/form";
import { FormRowTitle } from "../../Commons/Wrappers";
import { InputTextStyled } from "../../../../../style/Input";
import { RowSpacer } from "../../../../../style/containers";
import { AppraisalSubject } from "../../../../../redux/apiSpecifications/apiCrud";
import practiceDetails from "../../../../../mockData/practiceDetails";

const RequestContactWrapper = styled.div`
    .button-wrapper {
        display: flex;
        justify-content: flex-end;
    }
`;

interface IRequestContactProps {
    communicationRecipient: AppraisalSubject | undefined;
    isPracticeInvoiced: boolean;
    onSend: () => void;
}

const RequestContact = (props: IRequestContactProps) => {
    const { communicationRecipient, onSend } = props;

    const { t } = useTranslation();

    return (
        <RequestContactWrapper>
            <FormRow>
                <FormRowTitle>
                    {t(
                        "appraisals-details-tab-activities-collapsable-request-contact-recipient"
                    )}
                </FormRowTitle>
            </FormRow>
            <FormRow>
                <InputTextStyled
                    label={`${t("edit-subject-tab-data-name")}`}
                    tooltip={t("edit-subject-tab-data-name-tooltip")}
                    placeholder={t("edit-subject-tab-data-name-placeholder")}
                    value={communicationRecipient?.name}
                    disabled={true}
                />
                <RowSpacer />
                <InputTextStyled
                    label={`${t("edit-subject-tab-data-lastname")}`}
                    tooltip={t("edit-subject-tab-data-lastname-tooltip")}
                    placeholder={t(
                        "edit-subject-tab-data-lastname-placeholder"
                    )}
                    value={communicationRecipient?.lastname}
                    disabled={true}
                />
                <RowSpacer />
                <div style={{ flex: 1 }}></div>
                <RowSpacer />
                <div style={{ flex: 1 }}></div>
            </FormRow>

            <FormRow>
                <InputTextStyled
                    label={`${t("subject-contact-type-E")}`}
                    tooltip={t("subject-contact-type-E")}
                    placeholder={t("subject-contact-type-E")}
                    value={communicationRecipient?.email}
                    disabled={true}
                />
                <RowSpacer />
                <InputTextStyled
                    label={`${t("subject-contact-type-P")}`}
                    tooltip={t("subject-contact-type-P")}
                    placeholder={t("subject-contact-type-P")}
                    value={communicationRecipient?.pec}
                    disabled={true}
                />

                <RowSpacer />
                <InputTextStyled
                    label={`${t("subject-contact-type-T")}`}
                    tooltip={t("subject-contact-type-T")}
                    placeholder={t("subject-contact-type-T")}
                    value={communicationRecipient?.phone}
                    disabled={true}
                />
                <RowSpacer />
                <div style={{ flex: 1 }}></div>
            </FormRow>

            <div className="button-wrapper">
                <ButtonConfirm onClick={() => onSend()} disabled={props.isPracticeInvoiced}>
                    {t("button-send")!}
                </ButtonConfirm>
            </div>
        </RequestContactWrapper>
    );
};

export default RequestContact;
