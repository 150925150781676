import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DocProcessingAlert } from "../../../../../../redux/apiSpecifications/apiCrud";
import { PostConcludePracticeStatuses } from "./PostConcludedPractice";

// Styled components
const SavedQuestionnaireResultWrapper = styled.div`
  display: flex;

  .ok {
    letter-spacing: 1px;
    margin-right: 5em;
  }

  .error {
    letter-spacing: 1px;
    margin-right: 5em;
    color: orange;
  }
`;

/**
 * @desccription
 * @interface ISavedQuestionnaireResultProps
 * @property {ConcludePracticeStatuses} status
 */

interface ISavedQuestionnaireResultProps {
  status?: PostConcludePracticeStatuses;
}

/**
 * ADZALerts component
 *
 * @component
 * @example
 * <ADZALerts alerts={[]} />
 */
const SavedQuestionnaireResult: React.FC<ISavedQuestionnaireResultProps> = ({
  status,
}) => {
  // COMMON VARIABLES
  const { t } = useTranslation();

  if (
    ["questionnaire-saved", "saving-questionnaire-error"].includes(
      status || ""
    ) === false
  )
    return null;

  return (
    <SavedQuestionnaireResultWrapper>
      {status === "questionnaire-saved" && (
        <div className="ok">
          {t("appraisals-conclude-practice-questionnaire-saved")!}
        </div>
      )}
      {status === "saving-questionnaire-error" && (
        <div className="error">
          {t("appraisals-conclude-practice-saving-questionnaire-error")!}
        </div>
      )}
    </SavedQuestionnaireResultWrapper>
  );
};

export default SavedQuestionnaireResult;
