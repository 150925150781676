import styled from "styled-components";

import { useEffect, useMemo, useState } from "react";
import {
  Appraiser,
  PracticeActivityAppraiser,
  useLazyGetAppraisersListQuery,
} from "../../redux/apiSpecifications/apiCrud";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { Popconfirm, Select } from "antd";
import { useTranslation } from "react-i18next";
import { ButtonConfirm } from "../Layout/Buttons";
import AppraiserDetails from "../MainTabs/MainTabContent/AppraisalsDetails/AppraisalDetailsActivities/AppraiserDetails";

import { selectAppraiser as testIds } from "../../config/testIds";
import { SelectPair } from "../../types/common.types";
import { useAuthorization } from "../../hooks/useAuthorization";

const SelectAppraiserWrapper = styled.div`
  flex: 1;

  .select-appraiser-label {
    text-transform: uppercase;
    font-size: 0.9em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
`;

const SelectStyled = styled(Select)`
  width: 100%;
  margin-bottom: 2em;
`;

interface ISelectAppraiserProps {
  appraiser: Appraiser | undefined;
  onChange: (name: string) => void;
  onSelect: (appraiser: Appraiser) => void;
  onDataLoaded?: (appraisers: Appraiser[]) => void;
}

const SelectAppraiser = (props: ISelectAppraiserProps) => {
  const { appraiser, onChange, onSelect } = props;
  const { t } = useTranslation();
  const { getAuthorization } = useAuthorization();
  const activeRole = useSelector((state: RootState) => state.user.activeRole);
  const { selectedTenants } = useSelector((state: RootState) => state.user);

  const [options, setOptions] = useState<SelectPair[]>([]);

  const [getApprailsalsList] = useLazyGetAppraisersListQuery();

  const buildAppraiserLabel = (appraiser: PracticeActivityAppraiser) => {
    const nominative =
      appraiser.subjectType === "PF"
        ? `${appraiser.firstName} ${appraiser.lastName}`
        : appraiser.subjectType === "PG"
        ? appraiser.businessName || ""
        : "";

    const intExt = appraiser.isInternal ? " (I)" : " (E)";

    const workload = `${appraiser.practiceCountDaily || 0}/${
      appraiser.maxPracticeCountDaily || 0
    }`;

    return nominative + " " + intExt + " " + workload;
  };

  useEffect(() => {
    (async () => {
      const response = await getApprailsalsList({
        authorization: await getAuthorization(),
        activeRole: activeRole!,
        tenantsId: selectedTenants?.map((t) => t.id as number) || [],
      });
      if (response.isSuccess) {
        const appraisersList = response.data as PracticeActivityAppraiser[];
        const _options = appraisersList
          .map((appraiser) => ({
            value: appraiser,
            label: buildAppraiserLabel(appraiser),
          }))
          .slice()
          .sort((a, b) => (a.label > b.label ? 1 : -1));

        setOptions([{ value: { id: 0 }, label: "" }, ..._options]);
        if (props.onDataLoaded) props.onDataLoaded(appraisersList);
      }
    })();
  }, [activeRole, selectedTenants]);

  const handleFilterSearchAppraiser = (input: string, option: any) =>
    option.children[0].toLowerCase().indexOf(input.toLowerCase()) > -1;

  const handleSelectAppraiser = (index: string) => {
    const iId = parseInt(index);
    const _appraiser = options[iId];
    if (_appraiser) {
      onSelect(_appraiser.value);
    }
  };

  return (
    <SelectAppraiserWrapper data-testid={testIds.container}>
      <div className="select-appraiser-label">
        {t("search-appraisals-expertizer-label")}
      </div>
      <SelectStyled
        placeholder={t(
          "appraisals-details-tab-activities-select-appraiser-default-message"
        )}
        showSearch
        filterOption={handleFilterSearchAppraiser}
        onChange={(index) => handleSelectAppraiser(index as string)}
      >
        {options.map((item, i) => (
          <Select.Option key={i}>{item.label} </Select.Option>
        ))}
      </SelectStyled>
    </SelectAppraiserWrapper>
  );
};

export default SelectAppraiser;
