import styled from "styled-components";
import { AccountingReportList } from "../../../redux/apiSpecifications/apiFesf";
import { useMemo, useState } from "react";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { CellWrapper } from "../../../style/CellWrapper";
import { useTranslation } from "react-i18next";
import { ReportItem } from "./ReportItem";
import { dateTimeToNumber, dateToNumber } from "../../../utils/date";
import ReportActions from "./ReportActions";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const ReportsListWrapper = styled.div`
    display: flex;
    align-items: center;
    overflow: auto;
    margin-top: 1em;
    background-color: #fff;
    border-radius: 1em;
    box-shadow: 0 0 5px #aaa;
    padding: 1em 2em;

    .MuiPaper-root {
        box-shadow: none;
        width: 95%;
    }

    .MuiInputBase-root {
        &:before {
            border-bottom: 1px solid white;
        }
    }

    .MuiToolbar-root > .MuiBox-root {
        justify-content: flex-start;
    }

    .MuiInputBase-input {
        color: #555;
    }

    .MuiTableCell-root {
        padding: 0.5em 1em;
    }
`;

interface IReportsListProps {
    reports: AccountingReportList;
    onChangeStatus: () => void;
}

const ReportsList = ({ reports, onChangeStatus }: IReportsListProps) => {
    const { t } = useTranslation();

    const { activeRole } = useSelector(
        (state: RootState) => state.user
    );

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });

    const data = useMemo(
        () =>
            reports?.map((report) => {
                return {
                    ...report,
                    startDateNumber: dateToNumber(report.startDate),
                    endDateNumber: dateToNumber(report.endDate),
                    creationDateNumber: dateTimeToNumber(report.creationDate),
                } as ReportItem;
            }) || [],
        [reports]
    );

    const columns = useMemo<MRT_ColumnDef<ReportItem>[]>(
        () => [
            {
                accessorKey: "name",
                header: t("report-list-name-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper>{row.original.name}</CellWrapper>
                ),
            },
            {
                accessorKey: "startDateNumber",
                header: t("report-list-start-date-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => {}}>
                        {row.original.startDate}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "endDateNumber",
                header: t("report-list-end-date-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => {}}>
                        {row.original.endDate}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "creationDateNumber",
                header: t("report-list-creation-date-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => {}}>
                        {row.original.creationDate}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "reportStatus",
                header: t("report-list-report-status-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => {}}>
                        {t(`report-status-code-${row.original.reportStatus}`)}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "actions",
                header: t("report-list-actions-column-header"),
                size: 100,
                enableSorting: false,
                enableColumnDragging: false,
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <ReportActions
                        reportId={row.original.id!}
                        generationStatus={row.original.generationStatus!}
                        reportStatus={row.original.reportStatus!}
                        canReview={activeRole === "G1"}
                        canClose={activeRole === "G1"}
                        onChangeStatus={onChangeStatus}
                    />
                ),
            },
        ],
        [reports]
    );

    return (
        <ReportsListWrapper>
            {data.length > 0 && (
                <MaterialReactTable
                    muiTablePaperProps={{ style: { width: "100%" } }}
                    columns={columns}
                    data={data}
                    enableDensityToggle={false}
                    enableColumnOrdering
                    muiTableBodyCellProps={{
                        sx: {
                            textAlign: "left",
                        },
                    }}
                    onPaginationChange={setPagination}
                    state={{ pagination }}
                    autoResetPageIndex={false}
                />
            )}
        </ReportsListWrapper>
    );
};

export default ReportsList;
