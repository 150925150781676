import styled from "styled-components";
import WidgetHeader from "../WidgetHeader";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { RegisteredLetterError } from "../../../redux/apiSpecifications/apiCrud";
import { useDispatch } from "react-redux";
import { addTab } from "../../../redux/features/mainTabsSlice";

const RegisteredMailErrorListWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    max-height: 2.5em;
    transition: max-height 1s;
    &.open {
        max-height: 100em;
    }

    div.body {
        display: block;
        overflow: auto;
    }
`;

const RegisteredMailErrorWrapper = styled.div`
    width: 100%;
    padding: 1em 1em 1em 1em;
    cursor: pointer;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
`;

export interface IRegisteredMailErrorListProps {
    registeredLetterErrors: RegisteredLetterError[];
    gridArea?: string;
    isActive?: boolean;
    isOpen?: boolean;
}

const RegisteredMailErrorList = (props: IRegisteredMailErrorListProps) => {
    const { registeredLetterErrors, gridArea, isActive = false } = props;

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(props.isOpen);

    useEffect(() => {
        if (!isActive) {
            setIsOpen(isActive || props.isOpen);
        } else setIsOpen(isActive);
    }, [isActive]);

    const handleOnOpen = () => setIsOpen(true);
    const handleOnClose = () => setIsOpen(false);

    const handleSelectError = (error: RegisteredLetterError) => {
        dispatch(
            addTab({
                key: 0,
                label: error.practiceNumber!,
                type: "appraisal-details",
                externalData: {
                    idPractice: error.idPractice,
                    defaultTabPanel: "vehicle-owner",
                },
            })
        );
    };

    return (
        <RegisteredMailErrorListWrapper
            className={`${isActive || isOpen ? "open" : ""}`}
            gridArea={gridArea}
        >
            <WidgetHeader
                title={t("appraisals-dashboard-registered-mail-error-title")}
                isActive={isActive}
                onOpen={handleOnOpen}
                onClose={handleOnClose}
                isOpen={isOpen}
            />

            <div className="body">
                {registeredLetterErrors &&
                    registeredLetterErrors.map((error, index) => {
                        return (
                            <RegisteredMailErrorWrapper
                                key={index}
                                onClick={() => handleSelectError(error)}
                            >
                                {error.practiceNumber} - {error.fileContent} -{" "}
                                {error.errorDate}
                            </RegisteredMailErrorWrapper>
                        );
                    })}
            </div>
        </RegisteredMailErrorListWrapper>
    );
};

export default RegisteredMailErrorList;
