import { Modal } from "antd";
import styled from "styled-components";
import SettingsForm from "./SettingsForm";

const ModalContent = styled.div`
    padding: 2em;

    .ant-form-item-explain {
        color: red !important;
    }
`;

interface ISettingsModalProps {
    // user: UserItem;
    onCancel: () => void;
    //onConfirm: (fullUser: FullUser) => void;
}

const SettingsModal = ({ onCancel }: ISettingsModalProps) => {
    return (
        <Modal
            open={true}
            footer={null}
            closable={false}
            onCancel={onCancel}
            width="800px"
        >
            <ModalContent>
                <SettingsForm/>
            </ModalContent>
        </Modal>
    );
};

export default SettingsModal;
