import styled from "styled-components";
import { DashboardWrapper } from "../../../../style/DashbordWidgetWrappers";

export const AssignmentMapWrapper = styled(DashboardWrapper)`
  display: flex;
  flex-direction: row;
  gap: 1em;

  .col-left {
    display: flex;
    flex-direction: column;
    gap: 1em;
    flex: 2;
  }

  .col-right {
    display: flex;
    flex-direction: column;
    gap: 1em;
    flex: 3;
  }
`;


export type SelectedAppraiser = {
    totalScore: number | undefined;
    id: number;
    name: string;
    idZone?: number;
    longitude?: number;
    latitude?: number;
    saturationClusterCode?: string;
    address?: string;
    totalManaged?: number;
    dailyCapacity?: number;
    montlyResidual?: number;
    monthlyCapacity?: number;
    score?: number;
    isInternal: boolean;
    districtCode: string;
    isPosition: boolean;
};

export type SelectedPractice = {
    id: number;
    number: string;
    status: string;
    longitude?: number;
    latitude?: number;
    assignedAppraiser: string;
    assignedAppraiserId: number;
    data: string;
    province: string;
    appraisalLocationAddress: string;
    receiptDate: string;
    practiceType: string;
};
