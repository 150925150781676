import styled from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;

  .MuiPaper-root {
    box-shadow: none;
    width: 100%;
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  th.MuiTableCell-root {
    padding: 0;
  }

  td.MuiTableCell-root {
    padding: 1em 0;
  }

  .MuiTableRow-head {
    .MuiInputBase-root {
      input {
        margin-left: 5px;
      }
    }
  }
`;
