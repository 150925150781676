import styled from "styled-components";
import { WidgetWrapper } from "../../../../../style/DashbordWidgetWrappers";
import { t } from "i18next";
import AssignedMapListView, {
  AssignedMapListViewValues,
  ListsTypes,
} from "../../../../Widgets/Appraisals-Appraisers/AssignedMapListView";
import { useState } from "react";
import {
  AppraiserMapData,
  PracticeMapData,
} from "../../../../../redux/apiSpecifications/apiCrud";
import AssignmentMapPracticesList from "../components/AssignmentMapPracticesList";
import AssignmentMapAppraisersList from "../components/AssignmentMapAppraisersList";

const ListWrapper = styled(WidgetWrapper)`
  flex-direction: column;
  .body {
    flex-direction: column;
    padding: 1em 2em;
    display: flex;
  }
  flex-direction: column;
  .body {
    flex-direction: column;
    padding: 1em 2em;
    display: flex;
  }

  .view {
    padding-bottom: 1em;
  }
`;

interface IListProps {
  appraisersList?: AppraiserMapData[];
  practicesList?: PracticeMapData[];
  onSelectAppraiser: (
    idAppraiser: number,
    positions: number[][],
  ) => void;
  onSelectPractice: (
    idPractice: number,
    latitude: number,
    longitude: number
  ) => void;
}

const List = ({
  appraisersList,
  practicesList,
  onSelectAppraiser,
  onSelectPractice,
}: IListProps) => {
  const [selectedList, setSelectedList] = useState<ListsTypes>("practicesList");

  return (
    <ListWrapper>
      <div className="title">{t("assignment-map-list-wiew-title")}</div>

      <div className="body">
        <div className="view">
          <AssignedMapListView
            onSelect={(type: ListsTypes) => setSelectedList(type)}
            selected={selectedList}
            values={
              {
                practices: practicesList?.length,
                appraisers: appraisersList?.length,
              } as AssignedMapListViewValues
            }
          />
        </div>

        {selectedList === "practicesList" ? (
          <AssignmentMapPracticesList
            practices={practicesList}
            onSelectPractice={onSelectPractice}
          />
        ) : (
          <AssignmentMapAppraisersList
            items={appraisersList}
            onSelectAppraiser={onSelectAppraiser}
          />
        )}
      </div>
    </ListWrapper>
  );
};

export default List;
