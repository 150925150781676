import React, { useMemo } from "react";
import styled from "styled-components";
import {
  Appointment,
  DailyAgenda,
  District,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { useTranslation } from "react-i18next";
import dekra from "../../../../../style/dekra";

// Styled components

const ProvinceListWrapper = styled.div`
  width: 100%;

  .header {
    padding: 0.25em;
    border-radius: 5px;
    background-color: #eee;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9em;
  }

  .provinces {
    margin-top: 1em;
  }

  .appointment-province,
  .leave-province,
  .saint-province,
  .illness-province,
  .suspended-province,
  .blocked-province,
  .closed-province {
    padding-left: 2em;
    display: flex;
    align-items: center;
    cursor: pointer;

    .square {
      width: 0.75em;
      height: 0.75em;
      border-radius: 3px;
      background-color: #d91b1b;
      display: inline-block;
      margin-right: 0.5em;
      margin-bottom: 0.2em;
    }
  }

  .appointment-province {
    &:hover {
      background-color: #f5f5f5;
    }
  }

  .appointment-province .square {
    background-color: ${dekra.primaryColor};
  }

  .leave-province .square,
  .saint-province .square {
    background-color: orange;
  }

  .illness-province .square {
    background-color: #d91b1b;
  }

  .suspended-province .square {
    background-color: #ffcc00;
  }

  .blocked-province .square {
    background-color: #afabab;
  }

  .closed-province .square {
    background-color: #3b3838;
  }
`;

export type SelectedDayType =
  | "appointments"
  | "leave"
  | "saint"
  | "illness"
  | "suspended"
  | "blocked"
  | "closed"
  | undefined;

interface IProvinceListProps {
  type: string | undefined;
  agenda: DailyAgenda | undefined;
  date: string | undefined;
  allDistricts: District[] | undefined;
  onSelect: (provinceCode: string, type: SelectedDayType) => void;
}

const ProvinceList = ({
  type,
  agenda,
  date,
  allDistricts,
  onSelect,
}: IProvinceListProps) => {
  const { t } = useTranslation();

  const provinceList = useMemo(() => {
    let allProvinces: string[] = [];
    const suspendedProvinces =
      agenda?.onSuspendedDistricts?.map((p) => p.districtCode!) || [];

    if (type === "appointments") {
      allProvinces =
        agenda?.appointments
          ?.filter(
            (p) => !suspendedProvinces.includes(p.appointmentDistrict || "")
          )
          .map((a) => a.appointmentDistrict!) || [];
    } else if (type === "leave") {
      allProvinces =
        agenda?.onLeaveDistricts
          ?.filter((p) => !suspendedProvinces.includes(p.districtCode || ""))
          .map((a) => a.districtCode!) || [];
    } else if (type === "saint") {
      allProvinces =
        agenda?.onPatronSaintDistricts
          ?.filter((p) => !suspendedProvinces.includes(p.districtCode || ""))
          .map((a) => a.districtCode!) || [];
    }

    if (type === "illness") {
      allProvinces =
        agenda?.onIllnessDistricts
          ?.filter((p) => !suspendedProvinces.includes(p.districtCode || ""))
          .map((a) => a.districtCode!) || [];
    }

    if (type === "suspended") {
      allProvinces =
        agenda?.onSuspendedDistricts?.map((a) => a.districtCode!) || [];
    }

    if (type === "blocked") {
      allProvinces =
        agenda?.onBlockedDistricts
          ?.filter((p) => !suspendedProvinces.includes(p.districtCode || ""))
          .map((a) => a.districtCode!) || [];
    }

    if (type === "closed") {
      allProvinces =
        agenda?.onClosedDistricts
          ?.filter((p) => !suspendedProvinces.includes(p.districtCode || ""))
          .map((a) => a.districtCode!) || [];
    }

    const uniqueProvinces = Array.from(new Set(allProvinces || []));
    const sortedProvinces = uniqueProvinces.sort((a, b) =>
      a!.localeCompare(b!)
    );

    return sortedProvinces;
  }, [type, agenda, date, allDistricts]);

  return (
    <ProvinceListWrapper>
      <div className="header">{t("agenda-provinces-list")}</div>
      <div className="provinces">
        {type === "appointments" &&
          provinceList.map((p, index) => {
            return (
              <div
                className="appointment-province"
                key={index}
                onClick={() => onSelect(p!, type)}
              >
                <div className="square"></div>
                {allDistricts?.find((d) => d.districtCode === p)
                  ?.districtName || t("province-not-assigned")}
              </div>
            );
          })}

        {type === "leave" &&
          provinceList.map((p, index) => {
            return (
              <div
                className="leave-province"
                key={index}
                onClick={() => onSelect(p!, type)}
              >
                <div className="square"></div>
                {allDistricts?.find((d) => d.districtCode === p)?.districtName}
              </div>
            );
          })}

        {type === "saint" &&
          provinceList.map((p, index) => {
            return (
              <div
                className="saint-province"
                key={index}
                onClick={() => onSelect(p!, type)}
              >
                <div className="square"></div>
                {allDistricts?.find((d) => d.districtCode === p)?.districtName}
              </div>
            );
          })}

        {type == "illness" &&
          provinceList.map((p, index) => {
            return (
              <div
                className="illness-province"
                key={index}
                onClick={() => onSelect(p!, type)}
              >
                <div className="square"></div>
                {allDistricts?.find((d) => d.districtCode === p)?.districtName}
              </div>
            );
          })}

        {type == "suspended" &&
          provinceList.map((p, index) => {
            return (
              <div
                className="suspended-province"
                key={index}
                onClick={() => onSelect(p!, type)}
              >
                <div className="square"></div>
                {allDistricts?.find((d) => d.districtCode === p)?.districtName}
              </div>
            );
          })}

        {type == "blocked" &&
          provinceList.map((p, index) => {
            return (
              <div
                className="blocked-province"
                key={index}
                onClick={() => onSelect(p!, type)}
              >
                <div className="square"></div>
                {allDistricts?.find((d) => d.districtCode === p)?.districtName}
              </div>
            );
          })}

        {type == "closed" &&
          provinceList.map((p, index) => {
            return (
              <div
                className="closed-province"
                key={index}
                onClick={() => onSelect(p!, type)}
              >
                <div className="square"></div>
                {allDistricts?.find((d) => d.districtCode === p)?.districtName}
              </div>
            );
          })}
      </div>
    </ProvinceListWrapper>
  );
};

export default ProvinceList;
