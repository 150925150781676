import { useExpertizeScoreContext } from "../ExpertizeScoreContext";
import ReportList from "./ReportList/ReportList";

const DownloadReport = () => {
    const { appraiserScoreReportList } = useExpertizeScoreContext();

    return <ReportList reports={appraiserScoreReportList} />;
};

export default DownloadReport;
