import { useTranslation } from "react-i18next";
import { BusinessTypes } from "../../config/const";
import { SelectStyled } from "../../style/Input";
import { selectBusinessType as testId } from "../../config/testIds";
import { useMemo } from "react";

interface SelectBusinessTypeProps {
    classNameItem?: string;
    styleItem?: string;
    onChange: (val: BusinessTypes) => void;
    value: BusinessTypes;
    dataTestId?: string | undefined;
}

const SelectBusinessType = (props: SelectBusinessTypeProps) => {
    const { t } = useTranslation();

    const options = useMemo(() => {
        return ["", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
            .map((code) => ({
                value: code,
                label: t(`select-business-type-${code}`),
            }))
            .sort((a, b) => (a!.label < b!.label ? -1 : 1));
    }, []);

    return (
        <SelectStyled
            classNameItem={props.classNameItem}
            styleItem={props.styleItem}
            label={t("select-business-type-label")}
            tooltip={t("select-business-type-tooltip")}
            onChange={(gender) =>
                props.onChange(gender === "n.d." ? undefined : gender)
            }
            value={props.value}
            options={options}
            dataTestId={props.dataTestId || testId.businessType}
        />
    );
};

export default SelectBusinessType;
