import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { widgets } from "../../../config/testIds";

import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { useEffect, useState } from "react";
import { IconLoading } from "../../../config/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
    AppraiserCoveragesByDistrict,
    AppraiserDistrictConfig,
    useLazyGetAllAppraiserStatusesQuery,
} from "../../../redux/apiSpecifications/apiFesf";
import MapBase, {
    Marker,
    MarkerDataExpertizerStatus,
} from "../../Maps/MapBase";
import { SelectPair } from "../../../types/common.types";
import SupplierNetworkAppraiserStatusDialogMapLegend from "./SupplierNetworkAppraiserStatusDialogMapLegend";

const testIds = widgets.mapAppraisals;

const MapContainerWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    height: 100%;
    // width: 100%;
`;

const MapWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: 100%;
    width: 100%;

    .appraiser {
        font-size: 1.4em;
        text-align: center;
        margin: 1em;
    }

    .legend {
        margin: 1em;
    }

    .map {
        display: flex;
        margin: 1em;
        aspect-ratio: 1 / 1;
        flex-direction: row;
        gap: 1em;
        // height: 100%;
        // width: 100%;
    }
`;

const LoadingWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .box {
        width: 20em;
    }
`;

interface ISupplierNetworkMapProps {
    appraiserId?: number;
    appraiserName?: string;
    date?: string;
    disable?: boolean;
}

const SupplierNetworkMap = (props: ISupplierNetworkMapProps) => {
    const { appraiserId, date, disable } = props;
    const { t } = useTranslation();

    const authorization = useSelector(
        (state: RootState) => state.user.authorization
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [mapMarkers, setMapMarkers] = useState<Marker[]>();
    const [districts, setDistricts] = useState<SelectPair[]>([]);
    const [appraiserDistrictConfigs, setAppraiserDistrictConfigs] = useState<
        AppraiserDistrictConfig[]
    >([]);

    const [AzureMapData, setAzureMapData] = useState<any>();

    const [getAppraisersStatuses] = useLazyGetAllAppraiserStatusesQuery();

    // useEffect(() => {
    //     if (disable) return;

    //     try {
    //         const azureMapAddressConfig = getAzureMapAddressConfig();
    //         import("react-azure-maps").then((Map) => {
    //             setAzureMapData({
    //                 azureMap: {
    //                     AuthenticationType: Map.AuthenticationType,
    //                     AzureMap: Map.AzureMap,
    //                     AzureMapsProvider: Map.AzureMapsProvider,
    //                 },
    //                 options: {
    //                     authOptions: {
    //                         authType: Map.AuthenticationType.subscriptionKey,
    //                         subscriptionKey:
    //                             azureMapAddressConfig.subscriptionKey,
    //                     },
    //                 },
    //             });
    //         });
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }, []);

    useEffect(() => {
        if (appraiserId) loadData();
    }, [appraiserId]);

    const loadData = async () => {
        if (!appraiserId) return;

        setIsLoading(true);

        const response = await getAppraisersStatuses({
            authorization,
            id: appraiserId,
            searchDate: date,
        });

        if (response.isSuccess) {
            const appraiserStatuses = (
                response.data as AppraiserCoveragesByDistrict
            ).appraiserDistrictConfigs;

            const markers = appraiserStatuses?.map(
                (status) =>
                    ({
                        lat:
                            status?.districtBaseCoverage?.districtLatitude || 0,
                        long:
                            status?.districtBaseCoverage?.districtLongitude ||
                            0,
                        type: "supplier-network-appriaser",
                        // onClick: () =>
                        //     setSelectedDistrictId(
                        //         status?.districtBaseCoverage?.districtCode
                        //     ),
                        data: {
                            status: status.districtStatus,
                            place: status?.districtBaseCoverage?.districtName,
                            districtCode:
                                status?.districtBaseCoverage?.districtCode,
                            dateFrom: "", //status.districtAppraiserStatus?.validityStartDate,
                            dateTo: "", //status.districtAppraiserStatus?.validityEndDate,
                            suspensionReason: "", //status.districtAppraiserStatus?.suspensionReason,
                            description: "", //status.districtAppraiserStatus?.description,
                        } as MarkerDataExpertizerStatus,
                    } as Marker)
            );

            const newDistricts =
                markers?.map((m) => {
                    const data = m.data as MarkerDataExpertizerStatus;
                    return {
                        value: data.districtCode,
                        label: data.place,
                    } as SelectPair;
                }) || [];

            setMapMarkers(markers);
            setDistricts([{ value: 0, label: " " }, ...newDistricts]);
            setAppraiserDistrictConfigs(appraiserStatuses || []);
        } else {
            setError(
                "Si è verificato un errore durante il caricamento dei dati"
            );
        }

        setIsLoading(false);
    };

    const renderMap = () => {
        const {
            azureMap: { AzureMap, AzureMapsProvider },
            options,
        } = AzureMapData;

        return (
            <AzureMapsProvider>
                <AzureMap options={options}></AzureMap>
            </AzureMapsProvider>
        );
    };

    return (
        <MapContainerWrapper data-testid={testIds.container}>
            <div className="title">VISTA GEOGRAFICA</div>
            <div className="body" style={{ height: "100%" }}>
                {error && (
                    <div className="error">
                        <div className="message">{error}</div>
                    </div>
                )}

                {!error && isLoading && (
                    <LoadingWrapper>
                        <div className="box">{IconLoading}</div>
                    </LoadingWrapper>
                )}

                {!error && !isLoading && (
                    <MapWrapper>
                        <div className="appraiser">
                            {t("supplier-network-map-appraiser", {
                                appraiserName: props.appraiserName!,
                                date: props.date!,
                            })}
                        </div>

                        <div className="legend">
                            <SupplierNetworkAppraiserStatusDialogMapLegend />
                        </div>

                        <div className="map">
                            <MapBase
                                wrapperId="appraiser-status-map"
                                markers={mapMarkers}
                                autoBoundary={false}
                            />

                            <table style={{ height: "fit-content" }}>
                                <thead>
                                    <tr>
                                        <th>{t("supplier-network-map-table-header-district-code")}</th>
                                        <th>{t("supplier-network-map-table-header-managed-practices")}</th>
                                        <th>{t("supplier-network-map-table-header-manageble-practices")}</th>
                                        <th>{t("supplier-network-map-table-header-remaning-practices")}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {appraiserDistrictConfigs.map(
                                        (config) => (
                                            <tr
                                                key={
                                                    config.districtBaseCoverage
                                                        ?.districtCode!
                                                }
                                            >
                                                <td>
                                                    {
                                                        config
                                                            .districtBaseCoverage
                                                            ?.districtCode!
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        config.districtPractices
                                                            ?.managedPractices
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        config.districtPractices
                                                            ?.manageblePractices
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        config.districtPractices
                                                            ?.remaningPractices
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </MapWrapper>
                )}
            </div>
        </MapContainerWrapper>
    );
};

export default SupplierNetworkMap;
