import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RequestMessage } from "../../../../../../redux/apiSpecifications/apiCrud";
import { Input } from "antd";

const MessageWrapper = styled.div`
    span.from {
        display: block;
        text-transform: uppercase;
    }

    span.date-time {
        display: block;
        text-transform: lowercase;
        text-align: right;
    }

    div.message-text {
        height: 150px;
        min-height: 50%;
        margin: 5px auto;
        text-align: left;
        position: relative;
        display: flex;
        flex-direction: column;

        textarea.message-text {
            color: rgba(0,0,0,.85);
            flex-grow: 1;
            width: 100%;
            box-sizing: border-box;
            margin: 5px auto;
            padding: 5px;
            text-align: left;
            position: relative;
        }
    }
`;

interface IMessageProps {
    message: RequestMessage;
}

const Message = ({ message }: IMessageProps) => {
    const { t } = useTranslation();

    return (
        <MessageWrapper>
            <span className="from">
                Da{" "}
                <i>
                    {
                        t(
                            `practice-request-user-profile-description-${message.senderProfile!}`
                        )!
                    }{" "}
                    ({message.sender})
                </i>
            </span>
            <div className="message-text">
                <Input.TextArea
                    className="message-text"
                    disabled={true}
                    value={message.messageText}
                />
            </div>
            <span className="date-time">{message.messageDate}</span>
        </MessageWrapper>
    );
};

export default Message;
