import { useTranslation } from "react-i18next";
import { AppraiserUser } from "../../../../../redux/apiSpecifications/apiFesf";
import { ContentWrapper, GenericWrapper } from "../../Commons/Wrappers";
import { Address } from "../../../../../redux/apiSpecifications/apiCrud";
import styled from "styled-components";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import AppraiserDetailsAddress from "./AppraiserDetailsAddress";
import { SubjectType } from "../../../../../config/const";
import { checkAddress } from "../../../../AzureMapsAddress/AddressAutocomplete";
import { setValidation } from "../../../../../redux/features/appraisersSlice";

const AddressesContainer = styled(ContentWrapper)`
  overflow: auto;
  max-height: calc(100vh - 16em);
  padding-right: 2em;
`;

const AddressesWrapper = styled(GenericWrapper)`
  display: flex;
  flex-direction: column;
  gap: 5em;

  align-items: center;
  padding: 3em;

  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 95%;
    max-width: 900px;

    .button-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

interface IAppraiserDetailsAddressesProps {
  appraiser: AppraiserUser | undefined;
  onChange: (appraiser: AppraiserUser) => void;
}

const AppraiserDetailsAddresses = (props: IAppraiserDetailsAddressesProps) => {
  const { appraiser, onChange } = props;
  const addresses = appraiser?.addresses || [];

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOnDeleteAddress = (index: number) => {
    const updatedAddresses = addresses?.map((a, i) =>
      i !== index ? a : { ...a, isDeleted: true }
    );

    validateAddresses(updatedAddresses);
    onChange({ ...appraiser, addresses: updatedAddresses });
  };

  const handleOnAddAddress = () => {
    const id = 0;
    const preferred = addresses?.length === 0;

    const updatedAddress = [...addresses, { id, preferred } as Address];
    validateAddresses(updatedAddress);

    onChange({ ...appraiser, addresses: updatedAddress });
  };

  const handleOnChangeAddress = (address: Address, index: number) => {
    let updatedAddresses = address.preferred
      ? addresses?.map((a) => ({ ...a, preferred: false }))
      : addresses;

    updatedAddresses = updatedAddresses?.map((a, i) =>
      i === index ? address : a
    );

    validateAddresses(updatedAddresses);

    onChange({ ...appraiser, addresses: updatedAddresses });
  };

  const validateAddresses = (_addresses: Address[]) => {
    let areAddressesValid = (_addresses?.length || 0) > 0;

    appraiser?.addresses?.forEach((a) => {
      if (!a.isDeleted) areAddressesValid &&= checkAddress(a);
    });

    dispatch(
      setValidation({
        id: appraiser?.id!,
        validationErrors: {
          addresses: areAddressesValid ? [] : ["invalid data"],
        },
      })
    );
  };

  return (
    <AddressesContainer>
      <AddressesWrapper>
        {addresses.map(
          (address, index) =>
            address.isDeleted !== true && (
              <AppraiserDetailsAddress
                key={index}
                index={index}
                address={address}
                subjectType={appraiser?.subjectType as SubjectType}
                onDelete={handleOnDeleteAddress}
                onChange={handleOnChangeAddress}
              />
            )
        )}

        <div className="buttons-wrapper">
          <div className="button-wrapper">
            <ButtonConfirm onClick={handleOnAddAddress}>
              {t("subject-address-button-add")!}
            </ButtonConfirm>
          </div>
        </div>
      </AddressesWrapper>
    </AddressesContainer>
  );
};

export default AppraiserDetailsAddresses;
