import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { mainTabs as testIds } from "../../../config/testIds";
import {
    DashboardWrapper,
    LoadingInTabWrapper,
    RefreshInTabWrapper,
} from "../../../style/DashbordWidgetWrappers";
import { RootState } from "../../../redux/store";
import { useEffect, useMemo, useState } from "react";
import { IconLoading } from "../../../config/icons";
import { useTranslation } from "react-i18next";
import {
    Communication,
    DashboardE1Summary,
    GetRequestListApiArg,
    GetUserCommunicationsListApiArg,
    Request,
    useLazyGetE1PracticesSummaryByTenantQuery,
    useLazyGetRequestListQuery,
    useLazyGetUserCommunicationsListQuery,
} from "../../../redux/apiSpecifications/apiCrud";
import { useInterval } from "../../../hooks/useInterval";
import { setInfoAlwaysVisibleTop } from "../../../redux/features/userSlice";
import { AggregateSummaryDataE1 } from "./common-utils";
import AppraisalsCommunications, {
    AppraisalsCommunicationSelectionType,
} from "../../Widgets/Appraisals/AppraisalsCommunications";
import AppraisalsList from "../../Widgets/Appraisals/AppraisalsList";
import CommunicationList from "../../Widgets/Communications/CommunicationsList";
import PracticesInVerification from "../../Widgets/Appraisals/AppraisalsInVerificationChart";
import TimerPie, { TimerUpdateWrapper } from "../../Layout/TimerPie";
import { useAuthorization } from "../../../hooks/useAuthorization";
import AppraisalsToBeAssignedChart from "../../Widgets/Appraisals/AppraisalsToBeAssignedChart";
import AppraisalsRequestsChartST from "../../Widgets/Appraisals/AppraisalsRequestsCharts/AppraisalsRequestsChartST";
import { AppraisalsRequestsChartSelectionType } from "../../Widgets/Appraisals/AppraisalsRequestsCharts/AppraisalsRequestsChartSelectionType";
import { Tenant } from "../../../redux/apiSpecifications/apiFesf";
import PracticeRequestsList from "../../Widgets/PracticeRequests/PracticeRequestsList";

const DashboardE1Wrapper = styled(DashboardWrapper)`
    display: grid;
    grid-template-areas:
        "in-verification to-be-assigned refresh"
        "comunications comunications comunications"
        "resultTable resultTable resultTable";
    grid-template-columns: 3fr 2fr 0.15fr;
    column-gap: 1em;
    row-gap: 1em;

    .timerHeight {
        height: 50px;
    }
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1em;
    grid-area: rightColumn;
`;

type laodingStatusType = "loading" | "idle" | "refreshing";

type FilterThecnicalRoom =
    | "isDocumental"
    | "isNegative"
    | "isRejected"
    | "inVerification"
    | "isToBeAssigned";

export type FiltersValues = {
    donatType?: FilterThecnicalRoom;
    donatSelection?: string;
};

const defaultFilters: FiltersValues = {
    donatType: "inVerification",
    donatSelection: "RCA",
};

interface IDashBoard_E1Props {
    tabKey?: number;
}

const DashBoard_E1 = (props: IDashBoard_E1Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [retrieveSummaryData] = useLazyGetE1PracticesSummaryByTenantQuery();
    const [retriveCommunications] = useLazyGetUserCommunicationsListQuery();
    const [getRequestList] = useLazyGetRequestListQuery();

    const { getAuthorization } = useAuthorization();

    const { selectedTenants, activeRole } = useSelector(
        (state: RootState) => state.user
    );

    const [loadingStatus, setLoadingStatus] =
        useState<laodingStatusType>("idle");
    const [filteringType, setFilteringType] = useState<
        "in-verification" | "cancelled" | "communications" | "to-be-assigned"
    >("in-verification");

    const [filterThecnicalRoom, setFilterThecnicalRoom] =
        useState<FilterThecnicalRoom>("isDocumental");

    const [communicationsFilterSelection, setCommunicationsFilterSelection] =
        useState<AppraisalsCommunicationSelectionType>({
            senderType: "",
            categoryCode: "",
            status: "",
        });

    const [requestsData, setRequestsData] = useState<Request[]>([]);

    const [requestsFilterSelection, setRequestsFilterSelection] =
        useState<AppraisalsRequestsChartSelectionType>({
            senderType: "",
            categoryCode: "",
        });

    const { activeKey } = useSelector((state: RootState) => state.mainTabs);
    const autoRefreshTabData = useSelector(
        (state: RootState) => state.user.configParams?.autoRefreshTabData || 600
    ); // 10 minutes

    const {
        restart: restartRefrehInterval,
        reset: resetRefrehInterval,
        clear: clearRefreshInterval,
        percentage,
        remainingTime,
    } = useInterval({
        seconds: autoRefreshTabData,
        callback: () => {
            setLoadingStatus("refreshing");
            loadPractices("refreshing", true);
        },
    });

    const [allData, setAllData] = useState<DashboardE1Summary>();
    const [communicationsData, setCommunicationsData] = useState<
        Communication[]
    >([]);
    const [filters, setFilters] = useState<FiltersValues>(defaultFilters);
    const [filteredByManagedData, setFilteredByManagedData] = useState<any>();
    const [blockTopOpen, setBlockTopOpen] = useState(true);

    useEffect(() => {
        if (activeKey === props.tabKey) {
            (async () => {
                setLoadingStatus("refreshing");
                await loadPractices("refreshing", true);
                restartRefrehInterval();
            })();
        } else clearRefreshInterval();
    }, [activeKey]);

    const loadPractices = async (
        loadingStatus: laodingStatusType,
        keepFilter?: boolean
    ) => {
        if (!selectedTenants) return;
        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        const tenantsSummaryData: DashboardE1Summary[] = [];

        for (let tenant of selectedTenants) {
            const idTenant = tenant.id!;

            const response = await retrieveSummaryData({
                idTenant,
                authorization: await getAuthorization(),
            });

            const { data, error, isSuccess, isError } = response;

            if (isSuccess && data) {
                tenantsSummaryData.push(data as DashboardE1Summary);
            } else if (isError) {
                dispatch(
                    setInfoAlwaysVisibleTop({
                        type: "error",
                        message: t("http-error-loading-dashboard"),
                    })
                );
                setLoadingStatus("idle");
                return;
            } else {
                console.log("unknown error");
            }
        }

        const aggregatedSummaryData =
            AggregateSummaryDataE1(tenantsSummaryData);

        if (loadingStatus === "loading" || loadingStatus === "refreshing") {
            setAllData(aggregatedSummaryData);
        }

        setLoadingStatus("idle");
    };

    useEffect(() => {
        handleFilterInVerification("inVerification", "RCA");
    }, [allData]);

    const loadCommunications = async (
        selection: AppraisalsCommunicationSelectionType
    ) => {
        if (!selectedTenants) return;

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("refreshing");

        const params: any = {
            authorization: await getAuthorization(),
            tenants: selectedTenants.map((t) => t.id!),
        };

        if (selection.senderType) params.senderType = selection.senderType;
        if (selection.categoryCode)
            params.categoryCode = selection.categoryCode;
        if (selection.status) params.statusCode = selection.status;

        const response = await retriveCommunications(
            params as GetUserCommunicationsListApiArg
        );
        const { data, error, isSuccess, isError } = response;

        if (isSuccess && data) {
            setCommunicationsData(data as Communication[]);
        } else if (isError) {
            dispatch(
                setInfoAlwaysVisibleTop({
                    type: "error",
                    message: t("http-error-loading-dashboard"),
                })
            );
            setLoadingStatus("idle");
            return;
        } else {
            console.log("unknown error");
        }

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("idle");
    };

    const loadRequests = async (
        selection: AppraisalsRequestsChartSelectionType
    ) => {
        if (!selectedTenants) return;

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("refreshing");

        const params: GetRequestListApiArg = {
            authorization: await getAuthorization(),
            activeRole: activeRole!,
            tenants: selectedTenants.map((t: Tenant) => t.id!),
            lastMessageSenderType: selection.lastMessageSenderType ?? "",
            lastMessageReceiverType: selection.lastMessageReceiverType ?? "",
            categoryCode: selection.categoryCode ?? "",
            statusCode: selection.statusCode,
        };

        const response = await getRequestList(params);

        const { data, error, isSuccess, isError } = response;

        if (isSuccess && data) {
            setRequestsData(data as Request[]);
        } else if (isError) {
            dispatch(
                setInfoAlwaysVisibleTop({
                    type: "error",
                    message: t("http-error-loading-dashboard"),
                })
            );
            setLoadingStatus("idle");
            return;
        } else {
            console.log("unknown error");
        }

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("idle");
    };

    useEffect(() => {
        if (!selectedTenants) return;
        setLoadingStatus("loading");
        loadPractices("loading");
    }, [selectedTenants]);

    const handleFilterInVerification = (
        totalType: string | undefined,
        key: string | undefined
    ) => {
        const updatedFilters = {
            ...defaultFilters,
            donatType: totalType,
            donatSelection: key?.toUpperCase() || "",
        };
        setFilters(updatedFilters as FiltersValues);
        setFilterThecnicalRoom(totalType as FilterThecnicalRoom);
        setFilteringType("in-verification");

        // const practices = (allData?.practiceList || []).filter((practice) => {
        //     let toInclude = true;

        //     switch (totalType) {
        //         case "isDocumental":
        //             toInclude = toInclude && practice.isDocumental === true;
        //             break;
        //         case "isNegative":
        //             toInclude =
        //                 toInclude &&
        //                 practice.practiceStatusAggr === "1" &&
        //                 practice.isNegative === true;
        //             break;
        //         case "isRejected":
        //             toInclude =
        //                 (toInclude &&
        //                     practice.practiceStatusAggr === "2" &&
        //                     practice.isRejected === true) ||
        //                 false;
        //             break;
        //         case "inVerification":
        //             toInclude = toInclude && practice.status === "IV";
        //     }

        //     return toInclude;
        // });

        let listByCategory = allData?.practiceList
            ?.filter((_) => _.status === "RE")
            ?.map((c) => c.rejectionReasonCode)
            .filter((l) => l !== null);

        const isReject = listByCategory?.reduce((obj, category) => {
            const valueRejectedByCategory =
                allData?.practiceList?.filter(
                    (f) => f.rejectionReasonCode === category
                ).length || 0;
            const indexCategory =
                category as keyof typeof valueRejectedByCategory;
            return { ...obj, [indexCategory]: valueRejectedByCategory };
        }, {});

        const practiceToVerification = {
            total: allData?.practiceList?.length,
            isDocumental: {
                urgent: allData?.practiceList?.filter(
                    (practice) =>
                        practice.priority === 1 &&
                        practice.isDocumental === true
                ).length,
                due: allData?.practiceList?.filter(
                    (practice) =>
                        practice.priority === 2 &&
                        practice.isDocumental === true
                ).length,
                new: allData?.practiceList?.filter(
                    (practice) =>
                        practice.priority === 3 &&
                        practice.isDocumental === true
                ).length,
                suspended: allData?.practiceList?.filter(
                    (practice) =>
                        practice.priority === 4 &&
                        practice.isDocumental === true
                ).length,
            },
            isNegative: {
                rca: allData?.practiceList?.filter(
                    (practice) =>
                        practice.status === "IV" &&
                        practice.isNegative === true &&
                        practice.claimsBranchGroup === "RCA"
                ).length,
                cvt: allData?.practiceList?.filter(
                    (practice) =>
                        practice.status === "IV" &&
                        practice.isNegative === true &&
                        practice.claimsBranchGroup === "CVT"
                ).length,
            },
            isRejected: isReject,
            inVerification: {
                rca: allData?.practiceList?.filter(
                    (practice) =>
                        practice.status === "IV" &&
                        practice.claimsBranchGroup === "RCA"
                ).length,
                cvt: allData?.practiceList?.filter(
                    (practice) =>
                        practice.status === "IV" &&
                        practice.claimsBranchGroup === "CVT"
                ).length,
            },
        };

        const communicationSummary = allData?.communicationSummary;

        const result = {
            practiceToVerification,
            documentalByPriority: allData?.documentalByPriority,
            communicationSummary: communicationSummary,
            practiceList: allData?.practiceList,
            toBeVerified: allData?.toBeVerified,
            negativeToBeVerified: allData?.negativeToBeVerified,
            rejectedByCategory: allData?.rejectedByCategory,
        };

        setFilteredByManagedData(result);
        setBlockTopOpen(true);
    };

    const handleFilterToBeAssigned = (
        totalType: string | undefined,
        key: string | undefined
    ) => {
        setFilteringType("to-be-assigned");

        const updatedFilters = {
            ...defaultFilters,
            donatType: totalType,
            donatSelection: key?.toUpperCase() || "",
        };

        setFilters(updatedFilters as FiltersValues);
        setFilterThecnicalRoom(totalType as FilterThecnicalRoom);

        setBlockTopOpen(true);
    };

    const handleFilterCommunications = (
        selection: AppraisalsCommunicationSelectionType
    ) => {
        setFilteringType("communications");
        setCommunicationsFilterSelection(selection);

        loadCommunications(selection);
        setBlockTopOpen(false);
    };

    const handleFilterRequests = (
        selection: AppraisalsRequestsChartSelectionType
    ) => {
        setFilteringType("communications");
        setRequestsFilterSelection(selection);

        loadRequests(selection);
        setBlockTopOpen(false);
    };

    const practicesToShowInList = useMemo(() => {
        const selection = filters.donatSelection?.toLocaleLowerCase() || "";
        const documentPriority =
            selection === "urgent"
                ? 1
                : selection === "due"
                ? 2
                : selection === "new"
                ? 3
                : selection === "suspended"
                ? 4
                : "";

        const inVerification = allData?.practiceList?.filter(
            (x) =>
                x.status === "IV" &&
                x.claimsBranchGroup === filters.donatSelection
        );

        const isNegative = allData?.practiceList?.filter(
            (x) =>
                x.status === "IV" &&
                x.isNegative === true &&
                x.claimsBranchGroup === filters.donatSelection
        );

        const isDocumental = allData?.practiceList?.filter(
            (x) => x.isDocumental === true && x.priority == documentPriority
        );

        const isRejected = allData?.practiceList?.filter(
            (x) =>
                x.practiceStatusAggr === "2" &&
                // x.isRejected === true &&
                x.status === "RE" &&
                x.rejectionReasonCode === filters.donatSelection
        );

        const isToBeAssigned = allData?.practiceList?.filter(
            (x) =>
                x.status === "ST" &&
                (filters.donatSelection === "" ||
                    x.practiceTypeCode === filters.donatSelection)
        );

        return filterThecnicalRoom === "isDocumental" &&
            loadingStatus === "idle"
            ? isDocumental
            : filterThecnicalRoom === "isNegative" && loadingStatus === "idle"
            ? isNegative
            : filterThecnicalRoom === "isRejected" && loadingStatus === "idle"
            ? isRejected
            : filterThecnicalRoom === "inVerification" &&
              loadingStatus === "idle"
            ? inVerification
            : filterThecnicalRoom === "isToBeAssigned" &&
              loadingStatus === "idle"
            ? isToBeAssigned
            : [];
    }, [
        filters,
        allData,
        filteringType,
        loadingStatus,
        filteredByManagedData,
        filterThecnicalRoom,
    ]);

    return (
        <div data-testid={testIds.dashboards.E1}>
            {loadingStatus === "loading" && (
                <LoadingInTabWrapper>
                    <div className="box" data-testid={testIds.E1.loader}>
                        {IconLoading}
                    </div>
                </LoadingInTabWrapper>
            )}
            {(loadingStatus === "refreshing" || loadingStatus === "idle") && (
                <>
                    <DashboardE1Wrapper>
                        <PracticesInVerification
                            practices={
                                filteredByManagedData?.practiceToVerification ||
                                []
                            }
                            onSelect={handleFilterInVerification}
                            isOpen={blockTopOpen}
                            totalSelected={undefined}
                            statusSelected={undefined}
                            isActive={filteringType === "in-verification"}
                            filtersValues={filters}
                            gridArea="in-verification"
                        />

                        <AppraisalsToBeAssignedChart
                            toBeAssigned={allData?.toBeAssigned || []}
                            onSelect={handleFilterToBeAssigned}
                            isOpen={blockTopOpen}
                            gridArea="to-be-assigned"
                            isActive={filteringType === "to-be-assigned"}
                            filtersValues={filters}
                        />

                        <div className="timerHeight">
                            <TimerUpdateWrapper height="50px">
                                <TimerPie
                                    percentage={percentage as number}
                                    tooltip={`${t(
                                        "timer-dashboard-refresh-tooltip-1"
                                    )!}  ${remainingTime}`}
                                    innerText={remainingTime as string}
                                    bgColor="#32b677"
                                    gridArea="refresh"
                                    onClick={() => {
                                        setLoadingStatus("refreshing");
                                        loadPractices("refreshing", true);
                                        resetRefrehInterval();
                                    }}
                                />
                            </TimerUpdateWrapper>
                        </div>

                        <AppraisalsRequestsChartST
                            isActive={filteringType === "communications"}
                            userRequestsSummary={allData?.userRequestsSummary!}
                            gridArea={"comunications"}
                            onSelect={handleFilterRequests}
                            isOpen={false}
                            selection={requestsFilterSelection}
                        />

                        {/* <AppraisalsCommunications
                            isActive={filteringType === "communications"}
                            communications={allData?.communicationSummary}
                            gridArea={"comunications"}
                            onSelect={handleFilterCommunications}
                            selection={communicationsFilterSelection}
                        /> */}

                        {filteringType === "in-verification" && (
                            <AppraisalsList
                                items={practicesToShowInList}
                                isActive={activeKey === props.tabKey}
                                onRefresh={() => {}}
                                scenario="dashboard_st"
                                gridArea={"resultTable"}
                            />
                        )}
                        {filteringType === "to-be-assigned" && (
                            <AppraisalsList
                                items={practicesToShowInList}
                                gridArea={"resultTable"}
                                isActive={activeKey === props.tabKey}
                                scenario="dashboard_st"
                                onRefresh={() => {}}
                            />
                        )}
                        {filteringType === "communications" && (
                            // <CommunicationList
                            //     items={communicationsData}
                            //     gridArea={"resultTable"}
                            // />

                            <PracticeRequestsList
                                items={requestsData}
                                gridArea={"resultTable"}
                            />
                        )}
                    </DashboardE1Wrapper>

                    {loadingStatus === "refreshing" && (
                        <RefreshInTabWrapper>
                            <div
                                className="box"
                                data-testid={testIds.E1.loader}
                            >
                                {IconLoading}
                            </div>
                        </RefreshInTabWrapper>
                    )}
                </>
            )}
        </div>
    );
};

export default DashBoard_E1;
