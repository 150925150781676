import { Steps } from "antd";
import CreateClaimQuickFormSummary from "./CreateClaimQuickFormSummary";
import { useEffect, useState } from "react";
import PolicyInfoStep from "./PolicyInfoStep";
import PoliciesListStep from "./PoliciesListStep";
import GuaranteesListStep from "./GuaranteesListStep";
import GuaranteeInfoStep from "./GuaranteeInfoStep";
import SummaryStep from "./SummaryStep";
import { useCreateClaimDispatch } from "../CreateClaimContext";
import { useNotifications } from "../../../../../../../hooks/useNotifications";
import { usePolicy } from "../../../../../../../hooks/usePolicy";
import { useTranslation } from "react-i18next";

interface IWizardProps {
  onCreateClaim: (complete: boolean) => void;
}

const Wizard = ({ onCreateClaim }: IWizardProps) => {
  const { t } = useTranslation();

  const dispatch = useCreateClaimDispatch();

  const { displayFetchBaseQueryErrorNotification } = useNotifications();

  const { getBranchesListPromise, addPolicyGuaranteePromise } = usePolicy();

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    loadBranches();
  }, []);

  const loadBranches = async () => {
    dispatch!({ type: "SET_LOADING_STATUS", payload: "refreshing" });

    return getBranchesListPromise()
      .then((branchesList) => {
        dispatch!({ type: "SET_ALL_BRANCHES", payload: branchesList });

        dispatch!({ type: "SET_LOADING_STATUS", payload: "idle" });
      })
      .catch((error) => {
        displayFetchBaseQueryErrorNotification(error);

        dispatch!({ type: "SET_LOADING_STATUS", payload: "idle" });
      });
  };

  const handleOnNextStep = () => {
    setCurrent(current + 1);
  };

  const handleOnPreviousStep = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <CreateClaimQuickFormSummary />

      <Steps
        current={current}
        items={[
          {
            title: t("create-claim-wizard-policies-list-step-title"),
          },
          {
            title: t("create-claim-wizard-policy-info-step-title"),
          },
          {
            title: t("create-claim-wizard-guarantees-list-step-title"),
          },
          {
            title: t("create-claim-wizard-guarantee-info-step-title"),
          },
          {
            title: t("create-claim-wizard-summary-step-title"),
          },
        ]}
      />

      <div>
        {current === 0 && <PoliciesListStep onNextStep={handleOnNextStep} />}

        {current === 1 && (
          <PolicyInfoStep
            onNextStep={handleOnNextStep}
            onPreviousStep={handleOnPreviousStep}
          />
        )}

        {current === 2 && (
          <GuaranteesListStep
            onNextStep={handleOnNextStep}
            onPreviousStep={handleOnPreviousStep}
          />
        )}

        {current === 3 && (
          <GuaranteeInfoStep
            onNextStep={handleOnNextStep}
            onPreviousStep={handleOnPreviousStep}
          />
        )}

        {current === 4 && (
          <SummaryStep
            onPreviousStep={handleOnPreviousStep}
            onCreateClaim={onCreateClaim}
          />
        )}
      </div>
    </>
  );
};

export default Wizard;
