import { useTranslation } from "react-i18next";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import { useMemo } from "react";

interface IAppraiserChangedDescriptionProps {
    modifyDetailsJson: Modifydetail[];
}

const AppraiserChangedDescription = ({
    modifyDetailsJson,
}: IAppraiserChangedDescriptionProps) => {
    const { t } = useTranslation();

    const appraiserFullNameNew: string = useMemo(() => {
        const value = modifyDetailsJson.find(
            (_) => _.key === "appraiser_full_name-new"
        );

        return value?.value ?? "";
    }, [modifyDetailsJson]);

    const appraiserFullNameOld: string = useMemo(() => {
        const value = modifyDetailsJson.find(
            (_) => _.key === "appraiser_full_name-old"
        );

        return value?.value ?? "";
    }, [modifyDetailsJson]);

    return (
        <>
            {t("historical-actions-appraiser-changed-description", {
                appraiser_full_name_new: appraiserFullNameNew,
                appraiser_full_name_old: appraiserFullNameOld,

            })}
        </>
    );
};

export default AppraiserChangedDescription;
