import styled from "styled-components";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import dekra from "../../../style/dekra";
import { useTranslation } from "react-i18next";

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;
`;

const TypesWrapper = styled(WidgetWrapper)`
    align-items: center;
    justify-content: space-between;
    font-size: 1.1em;

    .list-type {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 0.5em 1em;
        line-height: 1em;

        border-right: 2px solid #bbb;

        &.active-type {
            background-color: ${dekra.primaryColor};
            height: 100%;
            cursor: pointer;
            svg {
                color: ${dekra.secondaryColors.white};
            }
            .text {
                color: ${dekra.secondaryColors.white};
            }
            .value {
                color: ${dekra.secondaryColors.white};
            }

            &:hover {
                svg {
                    color: ${dekra.secondaryColors.black};
                }
                .text {
                    color: ${dekra.secondaryColors.black};
                }
                .value {
                    color: ${dekra.secondaryColors.black};
                }
            }
        }

        &.inactive-type {
            cursor: pointer;
            &:hover {
                svg {
                    color: ${dekra.primaryColor};
                }
                .text {
                    color: ${dekra.primaryColor};
                }
                .value {
                    color: ${dekra.primaryColor};
                }
            }
        }

        &:last-child {
            border-right: none;
        }

        svg {
            font-size: 1.4em;
            color: #aaa;
        }

        .list-type-details {
            display: flex;
            align-items: center;
            color: #333;
            font-size: 1em;
            text-transform: uppercase;
            letter-spacing: 1px;

            .text {
                margin-left: 0.5em;
                align-self: flex-end;
                text-wrap: nowrap;
            }

            .value {
                margin-left: 0.5em;
                font-weight: bold;
            }
        }
    }
`;

export type AssignedMapListViewValues = {
    practices: number;
    appraisers: number;
};

export interface IAssignedMapListViewProps {
    values?: AssignedMapListViewValues;
    selected?: ListsTypes;
    onSelect: (status: ListsTypes) => void;
}

export type ListsTypes = "appraisersList" | "practicesList";

const AssignedMapListView = ({
    selected = "practicesList",
    values,
    onSelect,
}: IAssignedMapListViewProps) => {
    const { t } = useTranslation();

    return (
        <RowWrapper>
            <TypesWrapper>
                <div
                    className={`list-type ${
                        selected === "practicesList"
                            ? "active-type"
                            : "inactive-type"
                    }`}
                    onClick={() => onSelect("practicesList")}
                >
                    <div className="list-type-details">
                        <div className="text">
                            {t("assignment-map-list-view-practices-list-type")}
                        </div>
                        <div className="value">{values?.practices}</div>
                    </div>
                </div>
                
                <div
                    className={`list-type ${
                        selected === "appraisersList"
                            ? "active-type"
                            : "inactive-type"
                    }`}
                    onClick={() => onSelect("appraisersList")}
                >
                    <div className="list-type-details">
                        <div className="text">
                            {t("assignment-map-list-view-appraisers-list-type")}
                        </div>
                        <div className="value">{values?.appraisers}</div>
                    </div>
                </div>
            </TypesWrapper>
        </RowWrapper>
    );
};
export default AssignedMapListView;
