import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  IconArrowDown,
  IconDelete,
  IconInbox,
  IconLoading,
} from "../../config/icons";
import { attachments as testId } from "../../config/testIds";
import dekra from "../../style/dekra";
import pdfIcon from "../../images/pdf-icon.png";
import adzIcon from "../../images/adz.png";
import emlIcon from "../../images/eml-icon.png";
import zipIcon from "../../images/zip-icon.png";
import { IoMdEye } from "react-icons/io";
import { PracticeAttachment } from "../../redux/apiSpecifications/apiCrud";
import {
  LoadingInTabWrapper,
  RefreshInTabWrapper,
} from "../../style/DashbordWidgetWrappers";
import { FcOldTimeCamera } from "react-icons/fc";
import { GrDocumentDownload } from "react-icons/gr";
import { FaFileDownload } from "react-icons/fa";
import { Popconfirm, Tooltip } from "antd";
import { MdDelete } from "react-icons/md";
import React, { useEffect } from "react";

const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 12em;
  font-size: 0.9em;
  //line-height: 1em;
  text-align: center;

  .type-name {
    margin-top: 12px;
    text-transform: uppercase;
    font-size: 1em;
  }
`;

const PreviewWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const FileOverlay = styled.div`
  max-width: 100px;
  max-height: 60px;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  opacity: 0;

  &:hover {
    z-index: 4;
    background-color: #fff;
    box-shadow: 0 0 5px #aaa;
    border-radius: 5px;
    opacity: 1;
  }
`;

const FilePreview = styled.img`
	max-width: 60px;
	max-height: 60px;
	position: absolute;
	z-index: 3;
	
	display: flex
	align-items: center;
	justyfy-content: center;

  
	&:hover {
		z-index: 1;
    
	}
`;

const IconWrapper = styled.div`
  color: ${dekra.primaryColor};
  cursor: pointer;
  font-size: 2em;
`;

export const LoadingWrapper = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: center;
  padding-top: 20%;

  .box {
    width: 5em;
  }
`;

const ImageIconWrapper = styled.div`
  font-size: 4em;
  z-index: 3;
`;

/**
 * @param {number} index - index of the attachment in the attachments array
 * @param {string} dataTestId - data-testid for testing
 * @param {function} onPreview - function to be called when the preview button is clicked
 * @param {function} onDelete - function to be called when the delete button is clicked
 * @param {PracticeAttachment} attachment - attachment
 */
interface IAttachmentPracticeProps {
  dataTestId?: string | undefined;
  onPreview: () => void;
  onDelete: () => void;
  onDownload: () => void;
  attachment?: PracticeAttachment;
  isLoading?: boolean;
  showFilename?: boolean;
  readonly?: boolean;
}

/**
 *
 * @param props $AttachmentProps
 * @returns
 */
const AttachmentPractice = (props: IAttachmentPracticeProps) => {
  const { attachment, onPreview, onDelete, isLoading, showFilename } = props;
  const { t } = useTranslation();
  const refDownload = React.useRef<HTMLAnchorElement>(null);
  const [toDownload, setToDownload] = React.useState(false);

  const extention = attachment?.originalName?.split(".").pop();

  const preview = extention === "eml" ? false : true;

  const fileIcon =
    extention === "pdf" ? (
      <FilePreview src={pdfIcon} />
    ) : extention?.toUpperCase() === "ADZ" ? (
      <FilePreview src={adzIcon} />
    ) : extention === "eml" ? (
      <FilePreview src={emlIcon} />
    ) : extention === "zip" ? (
      <FilePreview src={zipIcon} />
    ) : attachment?.base64File ? (
      <FilePreview src={attachment?.base64File} />
    ) : (
      <ImageIconWrapper>
        <FcOldTimeCamera />
      </ImageIconWrapper>
    );

  const onDownload = async () => {
    if (attachment?.base64File) {
      refDownload?.current?.click();
      return;
    } else {
      await props.onDownload();
      setToDownload(true);
    }
  };

  useEffect(() => {
    if (!toDownload || !attachment?.base64File) return;

    refDownload?.current?.click();
    setToDownload(false);
  }, [toDownload, attachment?.base64File]);

  return (
    <FileWrapper data-testid={props.dataTestId}>
      <PreviewWrapper>
        {isLoading && (
          <LoadingWrapper>
            <div className="box">{IconLoading}</div>
          </LoadingWrapper>
        )}
        {!isLoading && (
          <>
            {fileIcon}
            <FileOverlay>
              {preview && extention !== "zip" && (
                <IconWrapper
                  onClick={onPreview}
                  data-testid={testId.attachement.buttonPreview}
                >
                  <Tooltip
                    title={t(
                      "appraisal-details-tab-documents-icon-preview-tooltip"
                    )}
                  >
                    <IoMdEye />
                  </Tooltip>
                </IconWrapper>
              )}

              <IconWrapper onClick={onDownload}>
                <Tooltip
                  title={t(
                    "appraisal-details-tab-documents-icon-download-tooltip"
                  )}
                >
                  <FaFileDownload />
                </Tooltip>
              </IconWrapper>

              {!props.readonly && (
                <Popconfirm
                  placement="rightBottom"
                  icon={null}
                  title={t(
                    "appraisal-details-tab-documents-delete-documents-confirm"
                  )}
                  onConfirm={onDelete}
                  okText={
                    <div data-testid={testId.attachement.buttonDelete}>
                      {t("switch-yes")}
                    </div>
                  }
                  cancelText={t("switch-no")}
                >
                  <IconWrapper
                    onClick={() => {}}
                    data-testid={testId.attachement.buttonDelete}
                  >
                    <Tooltip
                      title={t(
                        "appraisal-details-tab-documents-icon-delete-tooltip"
                      )}
                    >
                      <MdDelete />
                    </Tooltip>
                  </IconWrapper>
                </Popconfirm>
              )}
            </FileOverlay>
          </>
        )}
      </PreviewWrapper>
      {showFilename && <div className="type-name">{attachment?.originalName}</div>}
      <a
        style={{ display: "none" }}
        ref={refDownload}
        download={attachment?.originalName}
        href={attachment?.base64File}
      >
        download
      </a>
    </FileWrapper>
  );
};

export default AttachmentPractice;
