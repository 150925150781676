import { useTranslation } from "react-i18next";
import { SelectStyled } from "../../style/Input";
import { SubjectPropertyValue } from "../../types/subject-data.types";
import { contactType as testId } from "../../config/testIds";
import { SubjectContactDataType } from "../../types/uses-data.types";
import { SelectPair } from "../../types/common.types";

interface SubjectContactTypeProps {
  classNameItem?: string;
  styleItem?: string;
  onChange: (val: SubjectPropertyValue) => void;
  type: SubjectContactDataType | undefined;
  index: number;
  required?: boolean | undefined;
  dataTestId?: string | undefined;
}

const SubjectContactType = (props: SubjectContactTypeProps) => {
  const { t } = useTranslation();
  const index = props.index;

  return (
    <SelectStyled
      classNameItem={props.classNameItem}
      styleItem={props.styleItem}
      label={`${t("subject-contact-type-label")} ${props.required ? "*" : ""}`}
      tooltip={t("subject-contact-type-tooltip")}
      onChange={(type) => props.onChange({ value: type, index })}
      value={props.type}
      options={
        [
          {
            value: "",
            label: t("subject-contact-type-empty"),
          },
          {
            value: "E",
            label: t("subject-contact-type-E"),
          },
          {
            value: "P",
            label: t("subject-contact-type-P"),
          },
          {
            value: "T",
            label: t("subject-contact-type-T"),
          },
          {
            value: "W",
            label: t("subject-contact-type-W"),
          },
        ] as SelectPair[]
      }
      dataTestId={props.dataTestId || testId.type}
    />
  );
};

export default SubjectContactType;
