import { addTab, setActiveTab } from "./features/mainTabsSlice";
import { RootState } from "./store";

export const mainTabsMiddleware =
  (store: any) => (next: any) => (action: any) => {
    const getState = (): RootState => store.getState();
    try {
      if (addTab.match(action)) {
        const tabs = getState().mainTabs.tabs;
        for (let t of tabs) {
          if (t.label === action.payload.label) {
            store.dispatch(setActiveTab(t.key));
            return;
          } else if (action.payload.type === "search-results-appraisals") {
            next(action);
            return;
          } else if (action.payload.type === "search-results-claims") {
            next(action);
            return;
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
    return next(action);
  };
