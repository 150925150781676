import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DistrictCoverage } from "../../../../../redux/apiSpecifications/apiFesf";
import { FormRow } from "../../../../../style/form";
import {
  InputNumberStyled,
  InputTextStyled,
  SelectStyled,
  SwitchStyled,
} from "../../../../../style/Input";
import { useEffect, useMemo, useState } from "react";
import { SelectPair } from "../../../../../types/common.types";
import { RowSpacer } from "../../../../../style/containers";
import { IconCancel, IconDelete } from "../../../../../config/icons";
import SelectAppraiserStatusType from "../../../../Inputs/SelectAppraiserStatus";
import AppraiserDetailsManagementCoverageArea from "./AppraiserDetailsManagementCoverageArea";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";

import { mainTabs } from "../../../../../config/testIds";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import { DateFormat } from "../../../../../config/const";
import moment from "moment";
import { Popconfirm } from "antd";

const testIds = mainTabs.tabs.supplierNetwork.appraiserDetails.management;

const AppraiserDetailsManagementCoverageDistrictWrapper = styled.div`
  margin-bottom: 3em;
  background-color: #f8f8f8;
  padding: 0.5em 1em 1em 1em;
  border-radius: 5px;

  .delete-icon-button {
    font-size: 1.6em;
    color: red;
    svg {
      cursor: pointer;
    }
    margin: 0 0.5em -5px 0;
  }

  .input-province {
    width: 20em;
  }

  .input-number {
    width: 8em;
  }

  .select-status {
    width: 10em;
  }

  .zip-code-delete-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }

  .switch-zip-code {
    .ant-form-item-row {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    label {
      margin-left: 15px;
    }
  }

  .toggle-zip-code-button {
    font-size: 2em;
    cursor: default;
    color: #eee;

    &.enable {
      cursor: pointer;
      color: #07a659;
    }

    .circle {
      box-shadow: 0 0 3px #aaa;
      border-radius: 10em;
      width: 1.25em;
      height: 1.25em;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2px;
    }
  }
`;

const AreaCoverageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  overflow: hidden;
  transition: max-height 1s ease-in-out;

  &.open {
    overflow: auto;
    max-height: 30em;
  }

  &.closed {
    max-height: 0;
  }

  .filter-area-zip-code-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;

    .filter-area-by-name-wrapper {
      padding: 0 1em;
      width: 18em;
      display: flex;
      align-items: center;
    }

    .filter-area-by-name {
      border: none;
      border-bottom: 1px solid #ccc;
      outline: none;
      margin: 0.25em 1em 0.25em 0.25em;
    }

    .filter-area-by-zip-code-wrapper {
      display: flex;
      align-items: center;
      flex: 1;
    }

    .filter-area-by-zip-code {
      border: none;
      border-bottom: 1px solid #ccc;
      outline: none;
      margin: 0.25em;
      padding: 0 1em;
      text-align: center;
    }

    .icon-clear-filter {
      cursor: pointer;
      color: #aaa;
      margin-left: 0.5em;
    }
  }

  .add-new-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;

    .new-district {
      width: 19em;
    }

    .new-postal-code {
      flex: 1;
    }
  }
`;

interface IAppraiserDetailsManagementCoverageDistrictProps {
  coverage: DistrictCoverage | undefined;
  provinceOptions: SelectPair[];
  areaOptionsByCity: SelectPair[];
  areaOptionsByZipCode: SelectPair[];
  onChange: (value: any, field: string) => void;
  onDeleteDistrinct: () => void;
  onChangeStatus: (coverage: DistrictCoverage, status: string) => void;
}

const AppraiserDetailsManagementCoverageDistrict = (
  props: IAppraiserDetailsManagementCoverageDistrictProps
) => {
  const { t } = useTranslation();

  const {
    coverage,
    provinceOptions,
    areaOptionsByCity,
    areaOptionsByZipCode,
    onChange,
    onDeleteDistrinct,
    onChangeStatus,
  } = props;

  const [isZipCodeOpen, setIsZipCodeOpen] = useState(
    coverage?.hasAreaCoverageParams
  );

  const [filterByArea, setFilterByArea] = useState("");
  const [filterByZipCode, setFilterByZipCode] = useState("");

  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);

  const filteredAreaCoverage = useMemo(() => {
    let updatedAreaCoverages = coverage?.areaCoverages || [];

    if (filterByArea) {
      const lcFilterByArea = filterByArea.toLowerCase();
      updatedAreaCoverages = updatedAreaCoverages.filter((ac) =>
        ac.city?.toLocaleLowerCase().includes(lcFilterByArea)
      );
    }

    if (filterByZipCode) {
      const lcFilterByZipCode = filterByZipCode.toLowerCase();
      updatedAreaCoverages = updatedAreaCoverages.filter((ac) =>
        ac.zipCode?.toLocaleLowerCase().includes(lcFilterByZipCode)
      );
    }

    return updatedAreaCoverages;
  }, [coverage?.areaCoverages, filterByArea, filterByZipCode]);

  const availableAreaOptions = useMemo(() => {
    const filteredOptions = areaOptionsByCity.filter(
      (a) => !coverage?.areaCoverages?.find((ac) => ac.city === a.label)
    );

    return [
      {
        value: "ADD-ALL",
        label: t("edit-expertizer-management-add-all-in-city-select"),
      },
      ...filteredOptions,
    ];
  }, [areaOptionsByCity, coverage?.areaCoverages]);

  const availablePostalCodeOptions = useMemo(() => {
    const filteredOptions = areaOptionsByZipCode.filter(
      (a) => !coverage?.areaCoverages?.find((ac) => ac.zipCode === a.label)
    );

    return [
      {
        value: "ADD-ALL",
        label: t("edit-expertizer-management-add-all-in-zipcode-select"),
      },
      ...filteredOptions,
    ];
  }, [areaOptionsByZipCode, coverage?.areaCoverages]);

  const handleOnChangeStatus = (status: string) => {
    onChangeStatus(coverage!, status);
  };

  const handleOnChangeCoverageArea = (value: any, field: string) => {
    onChange({ ...value, districtCode: coverage?.districtCode }, field);
  };

  const handleOnDeleteCoverageArea = (id: number) => {
    const updateAreaCoverages =
      coverage?.areaCoverages?.filter((ac) => ac.id !== id) || [];
    onChange(updateAreaCoverages, "areaCoverages");
  };

  const handleAddNewDistrict = (id: number) => {
    onChange(
      {
        id,
        districtCode: coverage?.districtCode,
      },
      "newAreaCoverage-city"
    );
  };

  const handleAddNewPostalCode = (id: string) => {
    onChange(
      {
        id,
        districtCode: coverage?.districtCode,
      },
      "newAreaCoverage-cap"
    );
  };

  const handleToggleZipCodeView = (checked: boolean) => {
    onChange(checked, "hasAreaCoverageParams");
    setIsZipCodeOpen(checked);
  };

  const handleFilterSearchLabel = (input: string, option: any) => {
    const text = input.toLowerCase();
    return option.label.toLowerCase().includes(text) || false;
  };
  const hasAreaCoverageParams = coverage?.hasAreaCoverageParams;

  const hasProvinceSelected =
    coverage?.districtCode && coverage.districtCode.length > 0;

  let appraiserStatusCode = "A";
  if (moment(coverage?.validityEndDate, DateFormat).isBefore(moment(), "day"))
    appraiserStatusCode = "C";
  else if (coverage?.isTest) appraiserStatusCode = "T";

  const canSelectStatus = ["A", "T"].includes(appraiserStatusCode);

  return (
    <AppraiserDetailsManagementCoverageDistrictWrapper
      data-testid={testIds.coverageData.district.container}
    >
      <FormRow style={{ alignItems: "flex-end" }}>
        <SelectStyled
          label={t("edit-expertizer-management-coverage-province-province")}
          classNameInput="input-province"
          value={coverage?.districtName}
          options={provinceOptions}
          onChange={(districtCode) => onChange(districtCode, "district")}
          showSearch
          filterOption={handleFilterSearchLabel}
        />
        <RowSpacer />
        <SwitchStyled
          label={t("edit-expertizer-management-coverage-by-zip-code-label")}
          tooltip={t("edit-expertizer-management-coverage-by-zip-code-tooltip")}
          classNameItem="switch-zip-code"
          checked={coverage?.hasAreaCoverageParams}
          checkedChildren={t("yes")}
          unCheckedChildren={t("no")}
          onChange={(checked) => handleToggleZipCodeView(checked)}
          disabled={!hasProvinceSelected}
          dataTestId={testIds.coverageData.district.toggleZipcode}
        />
        <RowSpacer />
        <InputNumberStyled
          classNameInput="input-number"
          label={t(
            "edit-expertizer-management-coverage-province-availability-daily-label"
          )}
          tooltip={t(
            "edit-expertizer-management-coverage-province-availability-daily-tooltip"
          )}
          value={coverage?.districtMaxPracticeCountDaily}
          onChange={(val) => onChange(val, "districtMaxPracticeCountDaily")}
          min={0}
          disabled={!hasProvinceSelected}
          dataTestId={testIds.coverageData.district.maxDaily}
        />
        <RowSpacer />
        <InputNumberStyled
          classNameInput="input-number"
          label={t(
            "edit-expertizer-management-coverage-province-availability-monthly-label"
          )}
          tooltip={t(
            "edit-expertizer-management-coverage-province-availability-monthly-tooltip"
          )}
          value={coverage?.districtMaxPracticeCountMonthly}
          onChange={(val) => onChange(val, "districtMaxPracticeCountMonthly")}
          min={0}
          disabled={!hasProvinceSelected}
          dataTestId={testIds.coverageData.district.maxMonthly}
        />
        <RowSpacer />

        {canSelectStatus && (
          <SelectStyled
            label={t(
              "supplier-network-dashboard-appriaser-status-dialog-status-label"
            )}
            classNameItem="select-status"
            onChange={(status) => handleOnChangeStatus(status)}
            value={appraiserStatusCode}
            options={[
              {
                value: "",
                label: t(
                  "supplier-network-dashboard-appriaser-status-dialog-status-"
                ),
              },
              {
                value: "T",
                label: t(
                  "supplier-network-dashboard-appriaser-status-dialog-status-T"
                ),
              },
              {
                value: "A",
                label: t(
                  "supplier-network-dashboard-appriaser-status-dialog-status-A"
                ),
              },
            ]}
          />
        )}

        {!canSelectStatus && (
          <SelectStyled
            label={t(
              "supplier-network-dashboard-appriaser-status-dialog-status-label"
            )}
            classNameItem="select-status"
            onChange={(status) => handleOnChangeStatus(status)}
            value={t(
              `supplier-network-dashboard-appriaser-status-dialog-status-${appraiserStatusCode}`
            )}
            disabled={true}
            options={[]}
          />
        )}

        <RowSpacer />

        <ButtonConfirm onClick={() => onChangeStatus(coverage!, "change")}>
          {t("supplier-network-dashboard-appriaser-change-status")!}
        </ButtonConfirm>

        <RowSpacer />
        <div className="zip-code-delete-wrapper">
          <div
            className={`toggle-zip-code-button ${
              hasAreaCoverageParams ? " enable" : ""
            }`}
            onClick={() =>
              hasAreaCoverageParams && setIsZipCodeOpen(!isZipCodeOpen)
            }
          >
            <div className="circle">
              {isZipCodeOpen ? (
                <MdKeyboardDoubleArrowUp
                  title={
                    hasAreaCoverageParams
                      ? t("edit-expertizer-management-hide-district-arrow")!
                      : ""
                  }
                />
              ) : (
                <MdKeyboardDoubleArrowDown
                  title={
                    hasAreaCoverageParams
                      ? t("edit-expertizer-management-show-district-arrow")!
                      : ""
                  }
                />
              )}
            </div>
          </div>

          <RowSpacer />

          {/* <div
            className="delete-icon-button"
            onClick={() => onDeleteDistrinct()}
            data-testid={testIds.coverageData.buttonDeleteProvince}
          >
            <IconDelete />
          </div> */}
          <Popconfirm
            placement="top"
            icon={null}
            open={openConfirmDelete}
            title={
              t("edit-expertizer-management-delete-district-confirm-message")!
            }
            onConfirm={onDeleteDistrinct}
            onCancel={() => setOpenConfirmDelete(false)}
            okText={t("switch-yes")!}
            cancelText={t("switch-no")!}
          >
            <div
              className="delete-icon-button"
              onClick={() => setOpenConfirmDelete(true)}
              data-testid={testIds.coverageData.buttonDeleteProvince}
            >
              <IconDelete />
            </div>
          </Popconfirm>
        </div>
      </FormRow>
      
      {hasProvinceSelected && hasAreaCoverageParams && (
        <AreaCoverageWrapper className={`${isZipCodeOpen ? "open" : "closed"}`}>
          {coverage?.areaCoverages && coverage.areaCoverages.length > 1 && (
            <div className="filter-area-zip-code-wrapper">
              <div className="filter-area-by-name-wrapper">
                <InputTextStyled
                  classNameInput="filter-area-by-name"
                  value={filterByArea}
                  onChange={setFilterByArea}
                  placeholder={t(
                    "edit-expertizer-management-filter-area-name-placeholer"
                  )}
                  dataTestId={testIds.coverageData.district.filterByCityName}
                />
                <div
                  className="icon-clear-filter"
                  onClick={() => setFilterByArea("")}
                >
                  <IconCancel />
                </div>
              </div>
              <RowSpacer />
              <div className="filter-area-by-zip-code-wrapper">
                <InputTextStyled
                  classNameInput="filter-area-by-zip-code"
                  value={filterByZipCode}
                  onChange={setFilterByZipCode}
                  placeholder={t(
                    "edit-expertizer-management-filter-area-cap-placeholer"
                  )}
                  dataTestId={testIds.coverageData.district.filterByZipcode}
                />
                <div
                  className="icon-clear-filter"
                  onClick={() => setFilterByZipCode("")}
                >
                  <IconCancel />
                </div>
              </div>
              <RowSpacer />
              <div style={{ flex: 1 }}></div>
              <RowSpacer />
              <div style={{ flex: 1 }}></div>
              <RowSpacer />
              <div style={{ flex: 1 }}></div>
              <RowSpacer />
              <div style={{ flex: 1 }}></div>
            </div>
          )}

          {filteredAreaCoverage.map((cov, i) => (
            <AppraiserDetailsManagementCoverageArea
              key={i}
              coverage={cov}
              onChange={handleOnChangeCoverageArea} // (value: any, field: string) => void;
              onDeleteZone={handleOnDeleteCoverageArea} //(id: number) => void;
            />
          ))}

          <div className="add-new-wrapper">
            <SelectStyled
              disabled={availableAreaOptions.length < 2}
              classNameInput="new-district"
              classNameItem="new-district"
              options={availableAreaOptions}
              value={t(
                "edit-expertizer-management-add-district-select-placeholder"
              )}
              onChange={handleAddNewDistrict}
              showSearch
              filterOption={handleFilterSearchLabel}
              dataTestId={testIds.coverageData.district.selectAddAreaByName}
            />
            <RowSpacer />
            <SelectStyled
              disabled={availablePostalCodeOptions.length < 2}
              classNameInput="new-postal-code"
              classNameItem="new-district"
              options={availablePostalCodeOptions}
              value={t(
                "edit-expertizer-management-add-postal-code-select-placeholder"
              )}
              onChange={handleAddNewPostalCode}
              showSearch
              filterOption={handleFilterSearchLabel}
              dataTestId={testIds.coverageData.district.selectAddAreaByZipcode}
            />
            <RowSpacer />
            <div style={{ flex: 1 }}></div>
            <RowSpacer />
            <div style={{ flex: 1 }}></div>
            <RowSpacer />
            <div style={{ flex: 1 }}></div>
            <RowSpacer />
            <div style={{ flex: 1 }}></div>
          </div>
        </AreaCoverageWrapper>
      )}
    </AppraiserDetailsManagementCoverageDistrictWrapper>
  );
};

export default AppraiserDetailsManagementCoverageDistrict;
