import { useMemo } from "react";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import { useTranslation } from "react-i18next";

interface IAssignmentAcceptedDescriptionProps {
    modifyDetailsJson: Modifydetail[];
}

const AssignmentAcceptedDescription = ({
    modifyDetailsJson,
}: IAssignmentAcceptedDescriptionProps) => {
    const { t } = useTranslation();

    const appraiserFullName: string = useMemo(() => {
        const value = modifyDetailsJson.find(
            (_) => _.key === "appraiser_full_name"
        );

        return value?.value ?? "";
    }, [modifyDetailsJson]);

    return (
        <>
            {t("historical-actions-assignment-accepted-description", {
                appraiser_full_name: appraiserFullName,
            })}
        </>
    );
};

export default AssignmentAcceptedDescription;
