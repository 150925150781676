import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IconPracticesAssigned, IconDaily, IconPracticesRejected, IconInVerification, IconDocumentCancelled, IconRemoveFilter } from "../../../config/icons";
import dekra from "../../../style/dekra";
import { mainTabs, widgets } from "../../../config/testIds";
import { AppraisalsStatusesType } from "../../../config/const";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";

const testIdsD1 = mainTabs.D1.statuses;
const testIdsD2 = mainTabs.D2.statuses;

const RowWrapper = styled.div<{ gridArea?: string }>`
	grid-area: ${(props) => (props.gridArea ? props.gridArea : "")};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 1em;
`;

const StatusesWrapper = styled(WidgetWrapper)`
	align-items: center;
	justify-content: space-between;
	font-size: 1.1em;

	.status {
		display: flex;
		flex: 1;
		align-items: center;
		padding: 0.5em 1em;
		line-height: 1em;

		border-right: 2px solid #bbb;

		&.active {
			background-color: #fff;
			svg {
				color: ${dekra.primaryColor};
			}

			.title {
				color: ${dekra.primaryColor};
			}

			.value {
				color: ${dekra.primaryColor};
			}
		}

		&.activeInverificationCancelled {
			background-color: ${dekra.primaryColor};
			height: 100%;
			cursor: pointer;
			svg {
				color: ${dekra.secondaryColors.white};
			}
			.text {
				color: ${dekra.secondaryColors.white};
			}
			.value {
				color: ${dekra.secondaryColors.white};
			}

			&:hover {
				svg {
					color: ${dekra.secondaryColors.black};
				}
				.text {
					color: ${dekra.secondaryColors.black};
				}
				.value {
					color: ${dekra.secondaryColors.black};
				}
			}
		}

		&.notActiveInverificationCancelled {
			cursor: pointer;
			&:hover {
				svg {
					color: ${dekra.primaryColor};
				}
				.text {
					color: ${dekra.primaryColor};
				}
				.value {
					color: ${dekra.primaryColor};
				}
			}
		}

		&:last-child {
			border-right: none;
		}

		svg {
			font-size: 1.4em;
			color: #aaa;
		}

		.status-appraisals {
			display: flex;
			align-items: center;
			color: #333;
			font-size: 1em;
			text-transform: uppercase;
			letter-spacing: 1px;

			.text {
				margin-left: 0.5em;
				align-self: flex-end;
			}

			.value {
				margin-left: 0.5em;
				font-weight: bold;
			}
		}	
	}

	.reset-filter {
		padding: 0 1em;
		font-size: 1.4em;
		color: #555;

		.icon-wrapper {
			cursor: pointer;
			margin-top: 5px;
		}
	}
`;

export interface IAppraisalsStatusesProps {
	daily?: number;
	assigned?: number;
	discarded?: number;
	inVerification?: number;
	cancelled?: number;
	showInVerfications?: boolean;
	showCancelled?: boolean; // add cancelled status
	onSelect: (status: AppraisalsStatusesType) => void;
	selected?: AppraisalsStatusesType;
	gridArea?: string;
}

const AppraisalsStatuses = (props: IAppraisalsStatusesProps) => {
	const { daily, assigned, discarded, inVerification, cancelled, showInVerfications, showCancelled, onSelect, selected = "daily", gridArea } = props;
	const { t } = useTranslation();

	const handleResetFilter = () => onSelect("daily");

	return (
		<RowWrapper gridArea={gridArea}>
			<StatusesWrapper data-testid={widgets.appraisalsStatuses.container} flex={1}>
				<div className={`status ${selected === "daily" ? "active" : ""}`} data-testid={testIdsD1.daily.container}>
					<IconDaily />
					<div className="status-appraisals">
						<div className="text">{t("appraisals-dashboard-statuses-daily")}</div>
						<div className="value" data-testid={testIdsD1.daily.value}>
							{daily}
						</div>
					</div>
				</div>
				<div className={`status ${selected === "assigned" ? "active" : ""}`} data-testid={testIdsD1.assigned.container}>
					<IconPracticesAssigned />
					<div className="status-appraisals">
						<div className="text">{t("appraisals-dashboard-statuses-assigned")}</div>
						<div className="value" data-testid={testIdsD1.assigned.value}>
							{assigned}
						</div>
					</div>
				</div>
				<div className={`status ${selected === "discarded" ? "active" : ""}`} data-testid={testIdsD1.discarded.container}>
					<IconPracticesRejected />
					<div className="status-appraisals">
						<div className="text">{t("appraisals-dashboard-statuses-to-manage-discarded")}</div>
						<div className="value" data-testid={testIdsD1.discarded.value}>
							{discarded}
						</div>
					</div>
				</div>
			</StatusesWrapper>
			<StatusesWrapper data-testid={widgets.appraisalsStatuses.extention}>
				{showInVerfications && (
					<div className={`status ${selected == "in-verification" ? "activeInverificationCancelled" : "notActiveInverificationCancelled"}`} data-testid={testIdsD2.inVerification.container}>
						<IconInVerification />
						<div className="status-appraisals" onClick={() => onSelect("in-verification")}>
							<div className="text">{t("appraisals-dashboard-statuses-in-verification")}</div>
							<div className="value" data-testid={testIdsD2.inVerification.value}>
								{inVerification}
							</div>
						</div>
					</div>
				)}
				{showCancelled && (
					<div className={`status ${selected == "cancelled" ? "activeInverificationCancelled" : "notActiveInverificationCancelled"}`} data-testid={testIdsD2.cancelled.container}>
						<IconDocumentCancelled />
						<div className="status-appraisals" onClick={() => onSelect("cancelled")}>
							<div className="text">{t("appraisals-dashboard-statuses-cancelled")}</div>
							<div className="value" data-testid={testIdsD2.cancelled.value}>
								{cancelled}
							</div>
						</div>
					</div>
				)}
			</StatusesWrapper>
			<StatusesWrapper>
				<div className="reset-filter">
					<div className="icon-wrapper" onClick={handleResetFilter}>
						<IconRemoveFilter />
					</div>
				</div>
			</StatusesWrapper>
		</RowWrapper>
	);
};

export default AppraisalsStatuses;
