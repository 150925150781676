import { Modal } from "antd";
import { ButtonCancel, ButtonConfirm } from "../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ModalContent = styled.div`
    padding: 2em;
    font-size: 1.2em;
`;

interface IPromptProps {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

export const Prompt = ({ open, onCancel, onConfirm }: IPromptProps) => {
    const { t } = useTranslation();

    const handleOnCancel = () => {
        onCancel();
    };

    const handleOnConfirm = () => {
        onConfirm();
    };

    return (
        <Modal
            open={open}
            onCancel={() => {}}
            closable={false}
            width="400px"
            footer={[
                <ButtonCancel key="cancel" onClick={handleOnCancel}>
                    {t("switch-no")!}
                </ButtonCancel>,

                <ButtonConfirm key="confirm" onClick={handleOnConfirm}>
                    {t("switch-yes")!}
                </ButtonConfirm>,
            ]}
        >
            <ModalContent>
                {t("expert-score-unsaved-changes-prompt-message")}
            </ModalContent>
        </Modal>
    );
};
