import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultLanguage } from "../../config/const";
import { ConfigParams } from "../../types/common.types";
import { Tenants, User as UserDetails } from "../apiSpecifications/apiFesf";
import { Tenant } from "../apiSpecifications/apiFesf";

export type InfoAlwaysVisibleTopTypeType =
  | "empty"
  | "info"
  | "warning"
  | "error"
  | "success";
export type InfoAlwaysVisibleTopType = {
  type: InfoAlwaysVisibleTopTypeType;
  message: string;
};

export interface UserState {
  language: string;
  environment: string;
  authorization: string;
  userDetails?: UserDetails;
  selectedTenants?: Tenants;
  userDashboards: any[];
  userError?: string;
  configParams?: ConfigParams;
  infoAlwaysVisibleTop?: InfoAlwaysVisibleTopType;
  activeRole?: string;
}

const buildInitialState = () => {
  return {
    language: defaultLanguage,
    environment: "localhost",
    authorization: "",
    userDashboards: [],
  } as UserState;
};

export const userSlice = createSlice({
  name: "user",
  initialState: buildInitialState(),
  reducers: {
    setActiveRole(state, action: PayloadAction<string>) {
      state.activeRole = action.payload;
    },
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
    },
    setAuthorization(state, action: PayloadAction<string>) {
      state.authorization = action.payload;
    },
    setEnvironment(state, action: PayloadAction<string>) {
      state.environment = action.payload;
    },
    setUserDetails(state, action: PayloadAction<UserDetails>) {
      state.userDetails = action.payload;

      if (action.payload.roles) {
        // set default role
        state.activeRole = action.payload.roles[0].roleCode;
      }

      if (action.payload.tenants && action.payload.tenants.length > 0) {
        //state.selectedTenants = [action.payload.tenants[0]]; // select first tenant for default
        state.selectedTenants = action.payload.tenants; // just for debug pourposes
      }
    },
    toggleSelectedTenants(state, action: PayloadAction<Tenant>) {
      const wasSelected = state.selectedTenants?.find(
        (t) => t.id === action.payload.id
      );
      if (wasSelected) {
        state.selectedTenants = state.selectedTenants?.filter(
          (t) => t.id !== action.payload.id
        );
      } else {
        state.selectedTenants?.push(action.payload);
      }
    },
    setUserError(state, action: PayloadAction<string>) {
      state.userError = action.payload;
    },
    setConfigParams(state, action: PayloadAction<ConfigParams>) {
      state.configParams = action.payload;
      state.configParams.id = 1; // just for indexedDB purposes
    },
    setInfoAlwaysVisibleTop(
      state,
      action: PayloadAction<InfoAlwaysVisibleTopType>
    ) {
      state.infoAlwaysVisibleTop = action.payload;
    },
    logout(state) {
      state.authorization = "";
      state.userDashboards = [];
      state.userDetails = undefined;
    }
  },
});

export const {
  setActiveRole,
  setLanguage,
  setAuthorization,
  setEnvironment,
  setUserDetails,
  setUserError,
  setConfigParams,
  setInfoAlwaysVisibleTop,
  toggleSelectedTenants,
  logout
} = userSlice.actions;

export default userSlice.reducer;
