import styled from "styled-components";
import { WidgetWrapper } from "../../../../../style/DashbordWidgetWrappers";
import { t } from "i18next";
import { IconRemoveFilter } from "../../../../../config/icons";
import { SelectedPractice } from "../../Maps/models";
import { PracticeMapData } from "../../../../../redux/apiSpecifications/apiCrud";
import { useMemo } from "react";

const PracticeDetailsWrapper = styled(WidgetWrapper)`
  flex-direction: column;
  display: flex;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0 5px #aaa;
  overflow: hidden;

  .header {
    color: #333;
    background-color: #ededed;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    display: flex;

    .title {
      flex: 1 1 0%;
      padding: 0.5em 2em;
    }

    .actions {
      .icon-wrapper {
        font-size: 1.4em;
        margin-top: 6px;
        margin-right: 1em;
        cursor: pointer;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 100%;
    padding: 1em 2em;

    .icon {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .info {
      flex: 1 1 auto;
      flex-direction: column;
      display: flex;
      gap: 1em;

      .info-row {
        display: flex;
        flex-direction: row;
        gap: 1em;
      }

      .info-row > div {
        width: 100%;
      }
    }
  }
`;

interface IPracticeDetailsProps {
  practice: PracticeMapData;
  onClearSelection: () => void;
}

const PracticeDetails = ({
  practice,
  onClearSelection,
}: IPracticeDetailsProps) => {
  const fullAddress = useMemo(() => {
    if (practice.appraisalLocationAddress) {
      const location = practice.appraisalLocationAddress;

      const tail: string[] = [];

      if (location.postalCode) tail.push(location.postalCode);

      if (location.city) tail.push(location.city);

      if (location.province) tail.push(`(${location.province})`);

      const head: string[] = [];

      if (location.street) head.push(location.street);

      if (location.civic) head.push(location.civic);

      if (tail.length > 0) head.push(tail.join(" "));

      if (head.length > 0) return head.join(", ");

      return "";
    }
    
    return "";
  }, [practice]);

  return (
    <PracticeDetailsWrapper>
      <div className="header">
        <div className="title">
          {t("assignment-map-practice-details-title")}
        </div>

        <div className="actions">
          <div className="icon-wrapper" onClick={onClearSelection}>
            <IconRemoveFilter />
          </div>
        </div>
      </div>

      <div className="body">
        {practice.assignedAppraiserId === null ? (
          <div className="icon">
            <img
              src={`/images/markers/new/practices/not-assigned-${practice.practiceStatus}-big.png`}
            />
          </div>
        ) : (
          <div className="icon">
            <img
              src={`/images/markers/new/practices/assigned-${practice.practiceStatus}-big.png`}
            />
          </div>
        )}

        <div className="info">
          <div>
            <div className="info-row">
              <div>{`${t("assignment-map-practice-details-number")} ${
                practice.practiceNumber
              }`}</div>
            </div>

            <div className="info-row">
              <div>
                {`${t(
                  "assignment-map-practice-details-address"
                )} ${fullAddress}`}
              </div>
              <div>{`${t("assignment-map-practice-details-receipt-date")} ${
                practice.receiptDate
              }`}</div>
            </div>

            <div className="info-row">
              <div>{`${t("assignment-map-practice-details-status")} ${t(
                `assignment-map-practice-details-status-code-${practice.practiceStatus}`
              )}`}</div>
              <div>
                {`${t("assignment-map-practice-details-practice-type")} ${t(
                  `assignment-map-practice-details-practice-type-code-${practice.practiceTypeCode}`
                )}`}
              </div>
            </div>

            <div className="info-row">
              <div>{`${t(
                "assignment-map-practice-details-assigned-appraiser"
              )}  ${practice.assignedAppraiser}`}</div>
            </div>
          </div>
        </div>

        {/* <pre>{JSON.stringify(practice, null, 2)}</pre> */}
      </div>
    </PracticeDetailsWrapper>
  );
};

export default PracticeDetails;
