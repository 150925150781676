import { Modal } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
    RequestMessage,
    SecondaryPracticeRequest,
} from "../../../../../../redux/apiSpecifications/apiCrud";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { ModalTitleWrapper } from "../../../../../../style/containers";
import { useState } from "react";
import ManagePracticeRequestShowMode from "./ManagePracticeRequestShowMode";
import ManagePracticeRequestEditMode from "./ManagePracticeRequestEditMode";

const ModalContent = styled.div`
    padding: 2em;
    .ant-form-item-explain {
        color: red !important;
    }
`;

const ManageRequestModalTitleUrgentWrapper = styled(ModalTitleWrapper)`
    padding: 0 0 1em 0;
    svg {
        margin-left: 0;
        margin-top: 0;
    }
    span {
        margin-left: 0.5em;
        color: #333;
        letter-spacing: 1px;
    }
`;

const ManageRequestModalTitleWrapper = styled(ModalTitleWrapper)`
    padding: 0 0 1em 0;
    span {
        margin-left: 0em;
        color: #333;
        letter-spacing: 1px;
    }
`;

interface IManageRequestModalProps {
    practiceRequest: SecondaryPracticeRequest;
    onCancel: () => void;
    onConfirmCloseRequest: (requestMessage?: RequestMessage) => void;
    // onConfirmSendMessageAndCloseRequest: (requestMessage: RequestMessage) => void;
    onConfirmForwardMessage: (requestMessage: RequestMessage) => void;
    onConfirmReplyMessage: (requestMessage: RequestMessage) => void;
    onConfirmHaveReadMessage: () => void;
}

const ManageRequestModal = ({
    practiceRequest,
    onCancel,
    onConfirmCloseRequest,
    // onConfirmSendMessageAndCloseRequest,
    onConfirmForwardMessage,
    onConfirmReplyMessage,
    onConfirmHaveReadMessage
}: IManageRequestModalProps) => {
    const { t } = useTranslation();

    const [mode, setMode] = useState<
        "show" | "close" | "closeWithMessage" | "reply" | "forward"
    >("show");

    const renderModalTitle = () => {
        if (practiceRequest.isUrgent) {
            return (
                <ManageRequestModalTitleUrgentWrapper>
                    <AiOutlineExclamationCircle color="red" size={30} />
                    <span>
                        {
                            t(
                                `practice-request-category-description-${practiceRequest.categoryCode!}`
                            )!
                        }
                    </span>
                </ManageRequestModalTitleUrgentWrapper>
            );
        } else {
            return (
                <ManageRequestModalTitleWrapper>
                    <span>
                        {
                            t(
                                `practice-request-category-description-${practiceRequest.categoryCode!}`
                            )!
                        }
                    </span>
                </ManageRequestModalTitleWrapper>
            );
        }
    };

    const handleOnCloseWithMessage = () => setMode("closeWithMessage");
    const handleOnForward = () => setMode("forward");
    const handleOnReply = () => setMode("reply");

    return (
        <Modal
            open={true}
            title={renderModalTitle()}
            footer={null}
            closable={true}
            onCancel={onCancel}
            width="800px"
            bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
        >
            <ModalContent>
                <>
                    {mode === "show" ? (
                        <ManagePracticeRequestShowMode
                            practiceRequest={practiceRequest}
                            onClose={() => onConfirmCloseRequest(undefined)}
                            onCloseWithMessage={handleOnCloseWithMessage}
                            onForward={handleOnForward}
                            onReply={handleOnReply}
                            onHaveReadMessage={onConfirmHaveReadMessage}
                        />
                    ) : mode === "closeWithMessage" ||
                      mode === "reply" ||
                      mode === "forward" ? (
                        <ManagePracticeRequestEditMode
                            practiceRequest={practiceRequest}
                            mode={mode}
                            onConfirmSendMessageAndCloseRequest={onConfirmCloseRequest}
                            onConfirmReplyMessage={onConfirmReplyMessage}
                            onConfirmForwardMessage={onConfirmForwardMessage}
                        />
                    ) : null}

                    {/* <pre>{JSON.stringify(mode, null, 2)}</pre> */}
                </>
            </ModalContent>
        </Modal>
    );
};

export default ManageRequestModal;
