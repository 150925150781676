import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FormRow } from "../../../../../style/form";
import SelectAppraiserType from "../../../../Inputs/SelectAppraiserType";
import {
  AppraiserManagementData,
  AppraiserTenantsPermissionsItem,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { InputTextStyled } from "../../../../../style/Input";
import { RowSpacer } from "../../../../../style/containers";
import SelectAppraiserCoverageType from "../../../../Inputs/SelectAppraiserCoverageType";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import AppraiserDetailsManagementClientsPermissions from "./AppraiserDetailsManagementClientsPermissions";

import { mainTabs } from "../../../../../config/testIds";

const testIds =
  mainTabs.tabs.supplierNetwork.appraiserDetails.management.mainData;

const AppraiserDetailsManagementMainDataWrapper = styled.div``;

interface IAppraiserDetailsManagementMainDataProps {
  idAppraiser: number | undefined;
  appraiserData: AppraiserManagementData | undefined;
  onChange: (value: any, field: string) => void;
}

const AppraiserDetailsManagementMainData = (
  props: IAppraiserDetailsManagementMainDataProps
) => {
  const { t } = useTranslation();
  const { appraiserData, idAppraiser, onChange } = props;
  const {
    appraiserRoleType = "",
    appraiserBusinessName,
    customersSettings,
    coverage,
  } = appraiserData || {};

  const handleAppraiserRoleType = (appraiserRoleType: string): string => {
    let _appraiserRoleType: string = "";

    switch (appraiserRoleType) {
      case "internal":
        _appraiserRoleType = "appraiser-role-type-internal";
        break;
      case "external":
        _appraiserRoleType = "appraiser-role-type-studio";
        break;
      // case 'studio':
      //   _appraiserRoleType = "appraiser-role-type-studio";
      //   break;
    }

    return _appraiserRoleType;
  };

  return (
    <AppraiserDetailsManagementMainDataWrapper data-testid={testIds.container}>
      <FormRow style={{ alignItems: "flex-end" }}>
        {/* TIPO RUOLO */}
        <InputTextStyled
          label={t("select-appraiser-type-label")}
          readOnly={true}
          value={t(handleAppraiserRoleType(appraiserRoleType))}
          onChange={(val) => onChange(val, "appraiserRoleType")}
          dataTestId={""}
        />

        {/* NOME STUDIO */}
        <RowSpacer />

        {appraiserRoleType === "studio" && (
          <InputTextStyled
            readOnly={true}
            label={t("edit-expertizer-management-studio-label")}
            onChange={(val) => onChange(val, "appraiserBusinessName")}
            value={appraiserBusinessName}
            dataTestId={""}
          />
        )}

        {appraiserRoleType !== "studio" && <div style={{ flex: 1 }}></div>}

        <RowSpacer />

        {/* TIPO COPERTURA */}
        <SelectAppraiserCoverageType
          value={coverage?.coverageTypeCodes}
          onChange={(coverageTypeCodes) =>
            onChange({ ...coverage, coverageTypeCodes }, "coverage")
          }
          dataTestId={""}
        />

        <RowSpacer />

        {/* CLIENTE */}
        <AppraiserDetailsManagementClientsPermissions
          idAppraiser={idAppraiser}
          permissions={
            (customersSettings?.tenantsPermissions as AppraiserTenantsPermissionsItem[]) ||
            []
          }
          onChange={(permissions) => onChange(permissions, "customersSettings")}
        />
      </FormRow>
    </AppraiserDetailsManagementMainDataWrapper>
  );
};

export default AppraiserDetailsManagementMainData;
