import { useTranslation } from "react-i18next";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import { useMemo } from "react";

type SmsSentDescriptionDetails = {
    sms_result: string;
    sms_type: string;
    receiver_role: string;
    receiver_telephone_number: string;
};

interface ISmsSentDescriptionProps {
    modifyDetailsJson: Modifydetail[];
}

const SmsSentDescription = ({
    modifyDetailsJson,
}: ISmsSentDescriptionProps) => {
    const { t } = useTranslation();

    const smsSentDescriptionDetails: SmsSentDescriptionDetails = useMemo(() => {
        const sms_type = modifyDetailsJson.find((_) => _.key === "sms_type");

        const receiver_role = modifyDetailsJson.find(
            (_) => _.key === "receiver_role"
        );

        return {
            sms_result:
                modifyDetailsJson.find((_) => _.key === "sms_result")?.value ??
                "",
            sms_type: sms_type ? t(`communication_type-${sms_type.value}`) : "",
            receiver_role: receiver_role
                    ? t(`communication_receiver_role-${receiver_role.value}`)
                    : "",
            receiver_telephone_number:
                modifyDetailsJson.find(
                    (_) => _.key === "receiver_telephone_number"
                )?.value ?? "",
        } as SmsSentDescriptionDetails;
    }, [modifyDetailsJson]);

    return (
        <>
            {t("historical-actions-sms-sent-description", {
                sms_type: smsSentDescriptionDetails.sms_type,
                receiver_role: smsSentDescriptionDetails.receiver_role,
                receiver_telephone_number:
                    smsSentDescriptionDetails.receiver_telephone_number,
            })}
        </>
    );
};

export default SmsSentDescription;
