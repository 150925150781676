import { useTranslation } from "react-i18next";
import { SegmentedStyled } from "../../style/Input";
import { SubjectType } from "../../config/const";
import { segmentedSubjectType } from "../../config/testIds";

interface SegmentedSubjectTypeProps {
  classNameItem?: string;
  styleItem?: string;
  onChange: (val: SubjectType) => void;
  value: SubjectType;
  dataTestId?: string;
  readonly?: boolean;
  required?: boolean;
}

const SegmentedSubjectType = (props: SegmentedSubjectTypeProps) => {
  const { t } = useTranslation();
  const label = t("subject-type") + (props.required ? " *" : "");

  return (
    <SegmentedStyled
      classNameItem={props.classNameItem}
      styleItem={props.styleItem}
      label={label}
      //tooltip={t("subject-type-select-type-toolip") || ""}
      onChange={(translatedValue) => {
        const value =
          translatedValue === t("PF")
            ? "PF"
            : translatedValue === t("PG")
            ? "PG"
            : undefined;
        props.onChange(value || "PF");
      }}
      value={t(props.value || "PF")}
      options={[t("PF"), t("PG")]}
      dataTestId={props.dataTestId || segmentedSubjectType.subjectType}
      readOnly={props.readonly}
    />
  );
};

export default SegmentedSubjectType;
