import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import i18next from "i18next";
import { HtmlMarker } from "azure-maps-control";

import { useDispatch } from "react-redux";
import { addTab } from "../../redux/features/mainTabsSlice";

import { map as testIds } from "../../config/testIds";
import { getAzureMapAddressConfig } from "../../config/azureConfig";
import { useTranslation } from "react-i18next";

const tIdsExpertizer = testIds.expertizerPopup;
const tIdsSupplierNetwork = testIds.supplyNetworkAppraiserPopup;

const MapBaseWrapper = styled.div`
  width: 100%;
  height: 100%;

  .popup-close {
    color: white;
  }
`;

export type MarkerDataExpertizer = {};

export type MarkerDataAppraisal = {
  id: number;
  numberExpertize: string | number;
  practiceNumber: string;
  plate: string;
  expertizeType: string;
};

export type MarkerDataExpertizerStatus = {
  status: string;
  place: string;
  districtCode: string | undefined;
  dateFrom: string;
  dateTo: string;
  suspensionReason: string;
  description: string;
};

export type MarkerType =
  | "expertizer"
  | "appraisalReceiptConfirmation"
  | "appraisalAppointmentToBeFixed"
  | "appraisalAppointmentFixed"
  | "appraisalFinished"
  | "supplier-network-appriaser"
  | "practiceRejected"
  | "practiceConfirmed"
  | "practiceToDoAppointment"
  | "practiceWithAppointment"
  | "practiceAppointmentToday"
  | "practiceToConclude"
  | "practiceOtherStatus"
  | undefined;

export type Marker = {
  lat: number;
  long: number;
  type: MarkerType;
  data: MarkerDataExpertizer | MarkerDataAppraisal | MarkerDataExpertizerStatus;
  onClick?: (m: Marker) => void;
};

const isSupplierNetworkAppraiser = (type: MarkerType) =>
  type === "supplier-network-appriaser";

const isExpertizer = (type: MarkerType) => type === "expertizer";

const isAppraisal = (type: MarkerType) =>
  type === "appraisalReceiptConfirmation" ||
  type === "appraisalAppointmentToBeFixed" ||
  type === "appraisalAppointmentFixed" ||
  type === "appraisalFinished" ||
  type === "practiceRejected" ||
  type === "practiceConfirmed" ||
  type === "practiceToDoAppointment" ||
  type === "practiceWithAppointment" ||
  type === "practiceAppointmentToday" ||
  type === "practiceToConclude" ||
  type === "practiceOtherStatus";

const getLanguageCode = (language: string) =>
  language === "it" ? "it-IT" : language === "en" ? "en-US" : "en-US";

const buildHtmlContent = (testId: string, imageName: string | undefined) =>
  `<img data-testid="${testId}" src="/images/markers/${imageName}.png"/>`;

interface IMapBaseProps {
  wrapperId: string;
  center?: [number, number];
  zoom?: number;
  markers?: Array<Marker>;
  autoBoundary?: boolean;
}

const MapBase = (props: IMapBaseProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { wrapperId, center, zoom = 5, markers, autoBoundary } = props;

  const [map, setMap] = useState<any>();
  const [atlas, setAtlas] = useState<any>();

  useEffect(() => {
    const defaultCenter = [41.902782, 12.496366];
    const azureMapAddressConfig = getAzureMapAddressConfig();
    import("azure-maps-control").then((_atlas) => {
      const mapInstance = new _atlas.Map(wrapperId, {
        language: getLanguageCode(i18next.language),
        authOptions: {
          authType: _atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: azureMapAddressConfig.subscriptionKey,
        },
        showLogo: false,
        showFeedbackLink: false,
      });

      setMap(mapInstance);
      setAtlas(_atlas);
    });
  }, []);

  const buildPopupField = (label: string, value: string, testId: string) =>
    `<div class="map-popup-field" data-testid="${testId}">
        <div class="map-popup-field-label">${t(label)}</div>
        <div class="map-popup-field-value">${value}</div>
    </div>`;

  const buildPopupAppraisal = useCallback(
    (index: number, marker: Marker) => {
      const { practiceNumber, numberExpertize, plate, expertizeType } =
        marker.data as MarkerDataAppraisal;

      return new atlas.Popup({
        content: `
          <div class="map-popup-wrapper" data-testid="${
            tIdsExpertizer.container
          }${index}">
              <div class="map-popup-header"></div>
              <div class="map-popup-fields" >
                ${buildPopupField(
                  "map-pop-up-appraisal-practice-number",
                  practiceNumber,
                  tIdsExpertizer.idAssignment
                )}   
                ${buildPopupField(
                  "map-pop-up-appraisal-expertizer-number",
                  numberExpertize.toString(),
                  tIdsExpertizer.numberExpertise
                )} 
                ${buildPopupField(
                  "map-pop-up-appraisal-practice-type",
                  expertizeType,
                  tIdsExpertizer.typeExpertise
                )} 
                ${buildPopupField(
                  "map-pop-up-appraisal-plate",
                  plate,
                  tIdsExpertizer.plate
                )}                                 
              </div>
          </div>`,
        pixelOffset: [0, -30],
      });
    },
    [atlas]
  );

  const buildPopupSupplierNetworkAppraiser = useCallback(
    (index: number, marker: Marker) => {
      const { place, dateFrom, dateTo, status } =
        marker.data as MarkerDataExpertizerStatus;

      return new atlas.Popup({
        content: `
          <div class="map-popup-wrapper" data-testid="${
            tIdsSupplierNetwork.container
          }${index}">
              <div class="map-popup-header"></div>
              <div class="map-popup-fields" >
                ${buildPopupField(
                  "map-pop-up-supplier-network-appraiser-place",
                  place,
                  tIdsSupplierNetwork.place
                )}     
                ${buildPopupField(
                  "map-pop-up-supplier-network-appraiser-status",
                  t(
                    `supplier-network-dashboard-appriaser-status-dialog-status-${status}`
                  ),
                  tIdsSupplierNetwork.status
                )} 
                ${buildPopupField(
                  "map-pop-up-supplier-network-appraiser-date-from",
                  dateFrom,
                  tIdsSupplierNetwork.dateFrom
                )} 
                ${buildPopupField(
                  "map-pop-up-supplier-network-appraiser-date-to",
                  dateTo,
                  tIdsSupplierNetwork.dateTo
                )}                                                
              </div>
          </div>`,
        pixelOffset: [0, -30],
      });
    },
    [atlas]
  );

  const handleOpenPracticeDetailsTab = (
    practiceNumber: string,
    idPractice: number
  ) => {
    dispatch(
      addTab({
        key: 0,
        label: practiceNumber,
        type: "appraisal-details",
        externalData: {
          idPractice,
        },
      })
    );
  };

  useEffect(() => {
    if (!map || !atlas || !markers) return;

    map.popups.clear();
    map.markers.clear();

    map.events.add("ready", () => {
      markers.forEach((m, index) => {
        const position = [m.long, m.lat];

        if (isAppraisal(m.type)) {
          const htmlContent = buildHtmlContent(testIds.marker + index, m.type);
          const popup = buildPopupAppraisal(index, m);

          const marker = new atlas.HtmlMarker({
            htmlContent,
            position,
            popup,
          });

          map.markers.add(marker);

          map.events.add("click", marker, () => {
            marker.togglePopup();
          });

          map.events.add("dblclick", marker, () => {
            const { practiceNumber, id: idPractice } =
              m.data as MarkerDataAppraisal;
            handleOpenPracticeDetailsTab(practiceNumber, idPractice);
          });
        } else if (isExpertizer(m.type)) {
          map.markers.add(
            new atlas.HtmlMarker({
              htmlContent: buildHtmlContent(testIds.expertizer, m.type),
              position,
            })
          );
        } else if (isSupplierNetworkAppraiser(m.type)) {
          const imageName = `${m.type}-${
            (m.data as MarkerDataExpertizerStatus).status
          }`;

          const marker = new atlas.HtmlMarker({
            htmlContent: buildHtmlContent(
              testIds.supplyNetworkAppraiser,
              imageName
            ),
            position,
            popup: buildPopupSupplierNetworkAppraiser(index, m),
          });

          map.markers.add(marker);

          map.events.add("click", marker, () => {
            map.popups.clear();
            marker.togglePopup();
            if (m.onClick) m.onClick(m);
          });
        }
      });

      if (!autoBoundary) {
        const bounds = atlas.data.BoundingBox.fromPositions(
          markers.map((m) => [m.long, m.lat])
        );

        map.setCamera({
          bounds,
          padding: 50,
        });
      }
    });
  }, [map, markers, atlas]);

  return <MapBaseWrapper id={wrapperId}></MapBaseWrapper>;
};

export default MapBase;
