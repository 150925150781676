import { InputTextStyled } from "../../../style/Input";

type IExpertizerScoreInputTextProps = {
    value: number | string | undefined;
    onChange: (val: string) => void;
};

const ExpertizerScoreInputText = (props: IExpertizerScoreInputTextProps) => {
    const { onChange } = props;

    let value = "";

    if (props.value?.toString()) {
        value = props.value?.toString();
    }

    const handleOnChange = (value: string) => {
        if (value === "") onChange("0");
        else if (/^\d+(\d{0,})?$/.test(value)) {
            onChange(Number(value).toString());
        }
    };

    return (
        <InputTextStyled
            classNameItem="number"
            value={value?.replace(".", ",")}
            onChange={handleOnChange}
        />
    );
};

export default ExpertizerScoreInputText;
