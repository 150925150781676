import { useEffect, useRef, useState } from "react";
import { getNow } from "../components/MainTabs/Dashboards/common-utils";

interface IUseInterval {
	seconds: number;
	callback: () => void;
}

export const useInterval = (props: IUseInterval) => {
	const { seconds, callback } = props;
	const currentTimer = useRef<number>(0);
	const [now, setNow] = useState<number>(getNow());
	const [lastUpdate, setLastUpdate] = useState<number>(getNow());
	const [percentage, setPercentage] = useState<number>(0);
	const [remainingTime, setRemainingTime] = useState<string>("00:00");

	useEffect(() => {
		start();
		return () => window.clearInterval(currentTimer.current);
	}, []);

	useEffect(() => {
		const _percentage = Math.round(((now - lastUpdate) / seconds) * 100);
		setPercentage(_percentage);

		calcRemainingTime();

		if (now - lastUpdate >= seconds) {
			callback();
			setLastUpdate(now);
			setPercentage(0);
		}
	}, [now]);

	const calcRemainingTime = () => {
		const remTime = seconds * ((100 - (percentage as number)) / 100);
		let remMin: number | string = Math.floor(remTime / 60);
		let remSec: number | string = Math.floor(remTime % 60);

		if (remMin < 10) remMin = `0${remMin}`;
		if (remSec < 10) remSec = `0${remSec}`;

		setRemainingTime(`${remMin}:${remSec}`);
	};

	const start = () => {
		currentTimer.current = window.setInterval(() => {
			setNow(getNow());
		}, 10000);
	};

	const restart = () => {
		clear();
		setLastUpdate(getNow);
		start();
	};

	const reset = () => {
		setLastUpdate(getNow());
		calcRemainingTime();
	};

	const clear = () => {
		window.clearInterval(currentTimer.current);

		let remMin: number | string = Math.floor(seconds / 60);
		let remSec: number | string = seconds % 60;
		if (remMin < 10) remMin = `0${remMin}`;
		if (remSec < 10) remSec = `0${remSec}`;

		setRemainingTime(`${remMin}:${remSec}`);
		setPercentage(0);
	};

	return { restart, reset, clear, percentage, remainingTime };
};
