import { AppraisersByPracticeMapData } from "../../../../../redux/apiSpecifications/apiCrud";
import { useTranslation } from "react-i18next";
import { WidgetWrapper } from "../../../../../style/DashbordWidgetWrappers";
import styled from "styled-components";
import AssignmentMapSelectableAppraisersList from "../components/AssignmentMapSelectableAppraisersList";

const ListWrapper = styled(WidgetWrapper)`
  flex-direction: column;
  .body {
    flex-direction: column;
    padding: 1em 2em;
    display: flex;
  }
`;

interface IPracticeAppraisersListProps {
  appraisersByPracticeMapData: AppraisersByPracticeMapData;
  onAssignAppraiser: (idAppraiser: number, idPractice: number) => void;
}

const PracticeAppraisersList = ({
  appraisersByPracticeMapData,
  onAssignAppraiser,
}: IPracticeAppraisersListProps) => {
  const { t } = useTranslation();

  return (
    <ListWrapper>
      <div className="title">{t("assignment-map-appraisers-list-title")}</div>

      <div className="body">
        <AssignmentMapSelectableAppraisersList
          practice={appraisersByPracticeMapData.practice!}
          appraisers={appraisersByPracticeMapData.appraisers}
          onAssignAppraiser={onAssignAppraiser}
        />
      </div>
    </ListWrapper>
  );
};

export default PracticeAppraisersList;
