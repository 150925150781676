import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  DailyAgenda,
  District,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { useTranslation } from "react-i18next";
import { Checkbox, DatePicker, Input, Popconfirm, Tooltip } from "antd";
import { MdDoneAll, MdOutlineCancel } from "react-icons/md";
import { IconDelete } from "../../../../../config/icons";
import { SelectStyled } from "../../../../../style/Input";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import type { Moment } from "moment";
import moment from "moment";
import { SelectPair } from "../../../../../types/common.types";
import { RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { DayContent } from "../../../../Inputs/AgendaBase";
import {
  getAppraiserStatusSelectsOptions,
  getAppraiserStatusSelectsOptionsNF,
} from "./utils";
import {
  setAppraiserActiveStatusValues,
  setValidation,
} from "../../../../../redux/features/appraisersSlice";

export type RangeValue = [string | null, string | null] | null;

// Styled components

const AppraiserAgendaInsertLeaveWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  gap: 1em 1em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "header header "
    "reason-range provinces-filter "
    "reason-range provinces-list ";

  .header-wrapper {
    grid-area: header;
    padding: 0.25em;
    border-radius: 5px;
    background-color: #eee;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9em;
  }
  .provinces-list-wrapper {
    grid-area: provinces-list;
    overflow-y: auto;
    padding: 0 0 0 1em;
    margin-left: 1em;
  }

  .province-filter {
    grid-area: provinces-filter;
    padding: 2.2em 1em 0 0;
    position: relative;
    display: flex;
    flex-direction: row;

    .clear-filter-provinces {
      position: relative;
      cursor: pointer;
      font-size: 1.6em;
      margin-left: 0.5em;
      top: 0.2em;
    }

    .clear-selected-provinces,
    .select-all-provinces {
      font-size: 1.6em;
      cursor: pointer;
      margin-left: 0.25em;
      position: relative;
      top: 0.2em;
    }
  }

  .reason-range-column {
    grid-area: reason-range;

    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-left: 1em;
  }

  .date-wrapper {
    margin-bottom: 0.5em;

    .range-label {
      text-transform: uppercase;
      font-size: 0.9em;
      letter-spacing: 1px;
      margin-bottom: 0.5em;
    }
  }

  .reason-wrapper {
    gap: 1em;
    .ant-form-item-row {
      flex-direction: column;
    }

    .ant-form-item-label {
      text-align: left;
    }
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    gap: 1em;

    .already-taken-range-message {
      width: 100%;
      color: red;
    }
  }
`;

const today = moment();

export type ModeType =
  | "new"
  | "add-leave"
  | "add-saint"
  | "add-illness"
  | "add-suspension"
  | "add-blocked"
  | "add-closed"
  | "modify-leave"
  | "modify-saint"
  | "modify-illness"
  | "modify-suspension"
  | "modify-blocked"
  | "modify-closed";

export type ProvinceSelection = District & { selected: boolean };

interface IAppraiserAgendaAppraiserStatusNFProps {
  idAppraiser: number | undefined;
  allProvinces: District[];
  //agenda: DailyAgenda | undefined;
  //minDate: Moment | undefined;
  daysContent: DayContent[];
  readonly?: boolean;
}

const AppraiserAgendaAppraiserStatusLeaveNF = ({
  idAppraiser,
  allProvinces,
  //agenda,
  //minDate,
  daysContent = [],
  readonly,
}: IAppraiserAgendaAppraiserStatusNFProps) => {
  const { t } = useTranslation();
  // const activeRole = useSelector((state: RootState) => state.user.activeRole);
  const dispatch = useDispatch();

  const appraiserData = useSelector((state: RootState) =>
    state.appraisers.appraisers.find((a) => a.id === idAppraiser)
  );

  const { myAgenda } = appraiserData || {};

  const {
    days,
    defaultStatusValues: defaultValues,
    activeStatusValues,
  } = myAgenda || {};

  const {
    status: appraiserStatus,
    statusCode: appraiserStatusCode,
    range: rangeDate,
    provinces: provincesSelection,
  } = activeStatusValues || {};

  const [originalProvincesSelection, setOriginalProvincesSelection] =
    useState<ProvinceSelection[]>();

  const [visibleProvinces, setVisibleProvinces] = React.useState<
    ProvinceSelection[]
  >(provincesSelection || []);

  const [provinceFilter, setProvinceFilter] = useState<string>("");
  const [mode, setMode] = useState<ModeType>("new");
  const [rangeIsAlreadySet, setRangeIsAlreadySet] = useState<boolean>(false);
  const [reasonsOptions, setReasonsOptions] = useState<SelectPair[]>([]);

  useEffect(() => {
    if (allProvinces.length < 1) return;

    const newProvincesSelection = allProvinces.map((province) => ({
      ...province,
      selected:
        defaultValues?.provinces?.includes(province?.districtCode || "") ||
        false,
    }));

    let newMode: ModeType = "new";

    if ((defaultValues?.provinces?.length || 0) > 0) {
      if (defaultValues?.status === "illness") newMode = "modify-illness";
      if (defaultValues?.status === "leave") newMode = "modify-leave";
      if (defaultValues?.status === "saint") newMode = "modify-saint";
      if (defaultValues?.status === "suspended") newMode = "modify-suspension";
      if (defaultValues?.status === "blocked") newMode = "modify-blocked";
      if (defaultValues?.status === "closed") newMode = "modify-closed";

      // if the status is set by outside
      if (defaultValues?.status === "add-blocked") {
        setMode("add-blocked");
        newMode = "add-blocked";
      }
      if (defaultValues?.status === "add-suspension") {
        setMode("add-suspension");
        newMode = "add-suspension";
      }
    }

    setMode(newMode);

    let newStatus = newMode === "new" ? "" : (newMode as string);
    if (defaultValues?.provinces?.length === 0) newStatus = "";

    dispatch(
      setAppraiserActiveStatusValues({
        id: appraiserData!.id,
        range: defaultValues?.range!,
        provinces: newProvincesSelection!,
        status: newStatus,
        statusCode: defaultValues?.statusCode!,
        isUpdate: false,
      })
    );

    setOriginalProvincesSelection(newProvincesSelection);
  }, [defaultValues, allProvinces]);

  useEffect(() => {
    const provinces = provincesSelection?.filter((p) =>
      p.districtName?.toLowerCase().includes(provinceFilter.toLowerCase())
    );
    setVisibleProvinces(provinces || []);
  }, [provincesSelection, provinceFilter]);

  useEffect(() => {
    if (appraiserStatus == "" || rangeDate == null || rangeDate[0] == null)
      setRangeIsAlreadySet(false);
  }, [appraiserStatus, rangeDate]);

  const optionReasonCode = useMemo<SelectPair[]>(() => {
    const reasonCodes = [
      "add-suspension",
      "modify-suspension",
      "remove-suspension",
    ].includes(appraiserStatus || "")
      ? ["", "SPN", "SRC", "SRP", "SPS", "STRA", "SNOS", "SPCB", "SPME"]
      : ["add-blocked", "modify-blocked", "remove-blocked"].includes(
          appraiserStatus || ""
        )
      ? ["", "BRC", "BNCT", "BTRA", "BNOS", "BPCB", "BPME", "BVG", "BCA"]
      : [];

    return reasonCodes.map((rc) => ({
      value: rc,
      label: t(`supplier-network-appraiser-status-reason-${rc}`),
    }));
  }, [appraiserStatus]);

  useEffect(() => {
    // SOSPENSIONE
    if (
      ["add-suspension", "modify-suspension", "remove-suspension"].includes(
        appraiserStatus || ""
      )
    ) {
      // REASON NOT COMPATIBLE WITH STATUS
      if (
        ["SPN", "SRC", "SRP", "SPS", "STRA", "SNOS", "SPCB", "SPME"].includes(
          appraiserStatusCode || ""
        ) !== true
      ) {
        handleChangeAppraiserStatusCode("");
      }
    }
    // BLOCKED
    else if (
      ["add-blocked", "modify-blocked", "remove-blocked"].includes(
        appraiserStatus || ""
      )
    ) {
      // REASON NOT COMPATIBLE WITH STATUS
      if (
        ["BRC", "BNCT", "BTRA", "BNOS", "BPCB", "BPME", "BVG", "BCA"].includes(
          appraiserStatusCode || ""
        ) !== true
      ) {
        handleChangeAppraiserStatusCode("");
      }
    }
  }, [appraiserStatus]);

  const selectedOptions = useMemo(
    () => getAppraiserStatusSelectsOptionsNF(t),
    []
  );

  useEffect(() => {
    let newReasonOptions = selectedOptions.defaultSelectOptions;

    if (appraiserStatus === "modify-leave")
      newReasonOptions = [...newReasonOptions, ...selectedOptions.leaveOptions];
    else if (appraiserStatus === "modify-saint")
      newReasonOptions = [...newReasonOptions, ...selectedOptions.saintOptions];
    else if (appraiserStatus === "modify-illness")
      newReasonOptions = [
        ...newReasonOptions,
        ...selectedOptions.illnessOptions,
      ];
    else if (appraiserStatus === "modify-suspension")
      newReasonOptions = [
        ...newReasonOptions,
        ...selectedOptions.suspensionOptions,
      ];
    else if (appraiserStatus === "modify-blocked")
      newReasonOptions = [
        ...newReasonOptions,
        ...selectedOptions.blockedOptions,
      ];
    else if (appraiserStatus === "modify-closed")
      newReasonOptions = [
        ...newReasonOptions,
        ...selectedOptions.closedOptions,
      ];

    setReasonsOptions(newReasonOptions);
  }, [mode, appraiserStatus]);

  useEffect(() => {
    let isValid = true;

    if ((provincesSelection?.filter((p) => p.selected)?.length || 0) < 1)
      isValid = false;

    if (!appraiserStatus || appraiserStatus === "") isValid = false;

    if (
      [
        "add-suspension",
        "add-blocked",
        "modify-suspension",
        "modify-blocked",
      ].includes(appraiserStatus || "")
    ) {
      isValid = isValid && !!appraiserStatusCode;
    }

    if (
      ["add-saint", "modify-saint", "add-closed", "modify-closed"].includes(
        appraiserStatus || ""
      )
    ) {
      isValid = isValid && (rangeDate?.[0] ? true : false);
      // check if the user is trying to add leaves over illnes or viceversa
      let _rangeIsAlreadySet = false;
      if (isValid) {
        const _dayContent = daysContent.find((d) =>
          d.day.isSame(rangeDate?.[0], "day")
        );

        if (_dayContent?.content != null) _rangeIsAlreadySet = true;

        setRangeIsAlreadySet(_rangeIsAlreadySet);
      }
    }

    if (
      [
        "add-illness",
        "add-leave",
        "add-suspension",
        "add-blocked",
        "add-closed",
      ].includes(appraiserStatus || "")
    ) {
      // check if the user is trying to add a status over another
      let _rangeIsAlreadySet = false;
      if (isValid) {
        let currentDay = moment(rangeDate?.[0]);
        while (currentDay.isSameOrBefore(rangeDate?.[1])) {
          const _dayContent = daysContent.find((d) =>
            d.day.isSame(currentDay, "day")
          );

          if (_dayContent?.content != null) {
            _rangeIsAlreadySet = true;
            currentDay = moment(rangeDate?.[1]).add(1, "days"); // break the loop
          } else currentDay.add(1, "days");
        }

        setRangeIsAlreadySet(_rangeIsAlreadySet);
      }
    }

    if (
      [
        "add-illness",
        "add-leave",
        "add-suspension",
        "add-blocked",
        "modify-illness",
        "modify-leave",
        "modify-suspension",
        "modify-blocked",
      ].includes(appraiserStatus || "")
    ) {
      isValid =
        isValid &&
        (rangeDate?.[0] && rangeDate?.[1] ? true : false) &&
        (rangeDate?.[1] && rangeDate?.[1] ? true : false);
    }

    if (["remove-illness", "remove-leave"].includes(appraiserStatus || "")) {
      setRangeIsAlreadySet(false);
    }

    if (
      [
        "add-suspension",
        "add-leave",
        "add-blocked",
        "modify-illness",
        "modify-leave",
        "modify-suspension",
        "modify-blocked",
      ].includes(appraiserStatus || "")
    ) {
      const haveValues =
        rangeDate && rangeDate[0] && rangeDate[1] ? true : false;
      if (!haveValues) isValid = false;
      else {
        const startDate = rangeDate?.[0];
        const endDate = rangeDate?.[1];
        const originalStartDate = defaultValues?.range
          ? defaultValues?.range[0]
          : undefined;
        const originalEndDate = defaultValues?.range
          ? defaultValues?.range[1]
          : undefined;

        const sameProvinces =
          JSON.stringify(
            originalProvincesSelection?.filter((p) => p.selected).sort()
          ) ===
          JSON.stringify(provincesSelection?.filter((p) => p.selected).sort());

        let sameStatusCode = ["modify-suspension", "modify-blocked"].includes(
          appraiserStatus || ""
        )
          ? appraiserStatusCode === defaultValues?.statusCode
          : true;

        isValid =
          isValid &&
          (startDate !== originalStartDate ||
            endDate != originalEndDate ||
            !sameProvinces ||
            !sameStatusCode)
            ? true
            : false;
      }
    }

    dispatch(
      setValidation({
        id: appraiserData!.id,
        validationErrors: {
          agenda: isValid ? [] : ["dati incompleti"],
        },
      })
    );
  }, [appraiserStatus, appraiserStatusCode, rangeDate, provincesSelection]);

  const onChangeProvinceSelection = (districtCode: string) => {
    const provinces = provincesSelection?.map((p) =>
      p.districtCode === districtCode ? { ...p, selected: !p.selected } : p
    );
    handleChangeAppraiserProvinces(provinces || []);

    if (
      ["add-leave", "add-illness", "add-saint"].includes(appraiserStatus || "")
    ) {
    } else {
      setMode("new");
    }
  };

  const handleClearProvincesSelection = () => setAllProvincesTo(false);

  const handleSelectAllProvinces = () => setAllProvincesTo(true);

  const setAllProvincesTo = (selected: boolean) => {
    const provinces = allProvinces.map((province) => ({
      ...province,
      selected,
    }));
    handleChangeAppraiserProvinces(provinces);
  };

  const handleDisableRangeDates = (current: Moment) => {
    let disableDay: boolean = current.isBefore(today, "day");

    // check if the day already has a status
    const day = days?.find((d) => d?.date === current.format("DD/MM/YYYY"));
    const allDistricts = [
      ...(day?.onLeaveDistricts || []),
      ...(day?.onIllnessDistricts || []),
      ...(day?.onPatronSaintDistricts || []),
      ...(day?.onSuspendedDistricts || []),
      ...(day?.onBlockedDistricts || []),
      ...(day?.onClosedDistricts || []),
    ];

    // take the disctrict except those with the same status
    const otherDistricts = [
      "add-leave",
      "add-illness",
      "add-saint",
      "add-suspension",
      "add-blocked",
      "add-closed",
    ].includes(activeStatusValues?.status || "")
      ? allDistricts
      : activeStatusValues?.status === "modify-leave"
      ? [
          // all without leave
          ...(day?.onIllnessDistricts || []),
          ...(day?.onPatronSaintDistricts || []),
          ...(day?.onSuspendedDistricts || []),
          ...(day?.onBlockedDistricts || []),
          ...(day?.onClosedDistricts || []),
        ]
      : activeStatusValues?.status === "modify-illness"
      ? [
          // all without illness
          ...(day?.onLeaveDistricts || []),
          ...(day?.onPatronSaintDistricts || []),
          ...(day?.onSuspendedDistricts || []),
          ...(day?.onBlockedDistricts || []),
          ...(day?.onClosedDistricts || []),
        ]
      : activeStatusValues?.status === "modify-saint"
      ? [
          // all without saint
          ...(day?.onLeaveDistricts || []),
          ...(day?.onIllnessDistricts || []),
          ...(day?.onSuspendedDistricts || []),
          ...(day?.onBlockedDistricts || []),
          ...(day?.onClosedDistricts || []),
        ]
      : activeStatusValues?.status === "modify-suspension"
      ? [
          // all without suspension
          ...(day?.onLeaveDistricts || []),
          ...(day?.onIllnessDistricts || []),
          ...(day?.onPatronSaintDistricts || []),
          ...(day?.onBlockedDistricts || []),
          ...(day?.onClosedDistricts || []),
        ]
      : activeStatusValues?.status === "modify-blocked"
      ? [
          // all without blocked
          ...(day?.onLeaveDistricts || []),
          ...(day?.onIllnessDistricts || []),
          ...(day?.onPatronSaintDistricts || []),
          ...(day?.onSuspendedDistricts || []),
          ...(day?.onClosedDistricts || []),
        ]
      : activeStatusValues?.status === "modify-closed"
      ? [
          // all without closed
          ...(day?.onLeaveDistricts || []),
          ...(day?.onIllnessDistricts || []),
          ...(day?.onPatronSaintDistricts || []),
          ...(day?.onSuspendedDistricts || []),
          ...(day?.onBlockedDistricts || []),
        ]
      : [];

    const _selectedProvinces =
      activeStatusValues?.provinces
        ?.filter((ps) => ps.selected)
        .map((ps) => ps.districtCode) || [];

    const hasDayContent =
      otherDistricts.filter(
        (d) => _selectedProvinces.find((ps) => ps === d.districtCode)
        //defaultValues?.provinces?.includes(d.districtCode || "")
      ).length > 0;

    disableDay = disableDay || hasDayContent;
    return disableDay;
  };

  const handleChangeStatus = (value: string) => {
    let newRange = [
      "modify-leave",
      "modify-illness",
      "modify-saint",
      "modify-suspension",
      "modify-blocked",
      "modify-closed",
      "remove-leave",
      "remove-illness",
      "remove-saint",
      "remove-suspension",
      "remove-blocked",
      "remove-closed",
    ].includes(value)
      ? activeStatusValues?.range
      : undefined;

    if (
      [
        "add-leave",
        "add-illness",
        "add-saint",
        "add-suspension",
        "add-blocked",
        "add-closed",
      ].includes(value)
    ) {
      setAllProvincesTo(false);
    }

    dispatch(
      setAppraiserActiveStatusValues({
        id: appraiserData!.id,
        range: newRange,
        provinces: activeStatusValues?.provinces || [],
        status: value,
        statusCode: activeStatusValues?.statusCode || "",
        isUpdate: true,
      })
    );
  };

  const handleChangeAppraiserStatusCode = (value: string) => {
    dispatch(
      setAppraiserActiveStatusValues({
        id: appraiserData!.id,
        range: activeStatusValues?.range,
        provinces: activeStatusValues?.provinces || [],
        status: activeStatusValues?.status || "",
        statusCode: value,
        isUpdate: true,
      })
    );
  };

  const handleChangeAppraiserRange = (value: RangeValue) => {
    dispatch(
      setAppraiserActiveStatusValues({
        id: appraiserData!.id,
        range: value,
        provinces: activeStatusValues?.provinces || [],
        status: activeStatusValues?.status || "",
        statusCode: activeStatusValues?.statusCode || "",
        isUpdate: true,
      })
    );
  };

  const handleChangeAppraiserProvinces = (value: ProvinceSelection[]) => {
    dispatch(
      setAppraiserActiveStatusValues({
        id: appraiserData!.id,
        range: activeStatusValues?.range,
        provinces: value,
        status: activeStatusValues?.status || "",
        statusCode: activeStatusValues?.statusCode || "",
        isUpdate: true,
      })
    );
  };

  const disableProvincesCheckbox =
    !appraiserStatus ||
    appraiserStatus == "" ||
    [
      "modify-leave",
      "modify-illness",
      "modify-saint",
      "modify-suspension",
      "modify-blocked",
      "modify-closed",
      "remove-leave",
      "remove-illness",
      "remove-saint",
      "remove-suspension",
      "remove-blocked",
      "remove-closed",
    ].includes(appraiserStatus || "");

  const disableDatesRange =
    readonly ||
    (provincesSelection?.filter((ps) => ps.selected).length || 0) < 1 ||
    [
      "",
      "remove-leave",
      "remove-illness",
      "remove-suspension",
      "remove-blocked",
    ].includes(appraiserStatus || "");

  const displaySingleDate = [
    "add-saint",
    "modify-saint",
    "remove-saint",
    "add-closed",
    "modify-closed",
    "remove-closed",
  ].includes(appraiserStatus || "");

  const displayRange = !displaySingleDate;

  const headerText = useMemo(() => {
    let text = t("agenda-send-header-new");

    if (["modify-leave", "remove-leave"].includes(appraiserStatus || ""))
      text = t("agenda-send-header-leave");

    if (["modify-saint", "remove-saint"].includes(appraiserStatus || ""))
      text = t("agenda-send-header-saint");

    if (
      ["modify-suspension", "remove-suspension"].includes(appraiserStatus || "")
    )
      text = t("agenda-send-header-suspension");

    if (["modify-blocked", "remove-blocked"].includes(appraiserStatus || ""))
      text = t("agenda-send-header-blocked");

    if (["modify-closed", "remove-closed"].includes(appraiserStatus || ""))
      text = t("agenda-send-header-closed");

    return text;
  }, [appraiserStatus]);

  const showRangeValue = appraiserStatus && appraiserStatus !== "" && rangeDate;

  return (
    <>
      <AppraiserAgendaInsertLeaveWrapper>
        <div className="header-wrapper">{headerText}</div>
        <div className="province-filter">
          <Input
            value={provinceFilter}
            onChange={(e) => setProvinceFilter(e.target.value)}
            placeholder={t("agenda-clear-provinces-filter-placeholder")!}
          />

          <Tooltip title={t("agenda-clear-provinces-filter")!}>
            <div
              className="clear-filter-provinces"
              onClick={() => setProvinceFilter("")}
            >
              <MdOutlineCancel />
            </div>
          </Tooltip>

          <Tooltip title={t("agenda-clear-selected-provinces")!}>
            <div
              className="clear-selected-provinces"
              onClick={handleClearProvincesSelection}
            >
              <IconDelete />
            </div>
          </Tooltip>

          <Tooltip title={t("agenda-select-all-provinces")!}>
            <div
              className="select-all-provinces"
              onClick={handleSelectAllProvinces}
            >
              <MdDoneAll />
            </div>
          </Tooltip>
        </div>

        <div className="provinces-list-wrapper">
          {visibleProvinces.map((p, index) => {
            return (
              <div key={index}>
                <Checkbox
                  checked={
                    provincesSelection?.find(
                      (ps) => ps.districtCode === p.districtCode
                    )?.selected
                  }
                  onChange={() => onChangeProvinceSelection(p.districtCode!)}
                  disabled={readonly || disableProvincesCheckbox}
                >
                  {p.districtName}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className="reason-range-column">
          <div className="reason-wrapper">
            <SelectStyled
              onChange={handleChangeStatus}
              value={appraiserStatus}
              options={reasonsOptions}
              label={t("agenda-select-reason-label")}
              disabled={readonly}
            />

            {[
              "add-suspension",
              "modify-suspension",
              "remove-suspension",
              "add-blocked",
              "modify-blocked",
              "remove-blocked",
            ].includes(appraiserStatus || "") && (
              <SelectStyled
                label={t("agenda-select-reason-code-label")}
                options={optionReasonCode}
                value={appraiserStatusCode}
                onChange={handleChangeAppraiserStatusCode}
                disabled={["remove-suspension", "remove-blocked"].includes(
                  appraiserStatus || ""
                )}
              />
            )}
          </div>

          {displayRange && (
            <div className="date-wrapper">
              <div className="range-label">
                {t("agenda-select-date-range-label")}
              </div>
              <DatePicker.RangePicker
                style={{ width: "100%" }}
                placeholder={[
                  t("agenda-select-range-start")!,
                  t("agenda-select-range-end")!,
                ]}
                format={"DD/MM/YYYY"}
                onChange={(r) =>
                  handleChangeAppraiserRange([
                    r![0]!.format("DD/MM/YYYY") || null,
                    r![1]!.format("DD/MM/YYYY") || null,
                  ])
                }
                value={
                  showRangeValue
                    ? [
                        moment(rangeDate[0], "DD/MM/YYYY"),
                        moment(rangeDate[1], "DD/MM/YYYY"),
                      ]
                    : undefined
                }
                disabled={disableDatesRange}
                disabledDate={handleDisableRangeDates}
              />
            </div>
          )}

          {displaySingleDate && (
            <div className="date-wrapper">
              <div className="range-label">
                {t("agenda-select-date-date-label")}
              </div>
              <DatePicker
                style={{ width: "100%" }}
                format={"DD/MM/YYYY"}
                placeholder={t("agenda-select-date-date-label")!}
                onChange={(d) =>
                  handleChangeAppraiserRange([
                    d?.format("DD/MM/YYYY") || null,
                    null,
                  ])
                }
                value={
                  showRangeValue
                    ? moment(rangeDate[0], "DD/MM/YYYY")
                    : undefined
                }
                disabled={disableDatesRange}
                disabledDate={handleDisableRangeDates}
              />
            </div>
          )}
        </div>
      </AppraiserAgendaInsertLeaveWrapper>
    </>
  );
};

export default AppraiserAgendaAppraiserStatusLeaveNF;
