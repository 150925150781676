import { Reducer } from "react";
import {
  BasePolicy,
  ClaimAddress,
  Guarantee,
} from "../../../../../../redux/apiSpecifications/apiClaims";
import moment from "moment";
import { Branch } from "../../../../../../redux/apiSpecifications/apiPolicies";

export const CreateClaimReducer: Reducer<
  CreateClaimState,
  CreateClaimActions
> = (state, action) => {
  // console.info(state, action);

  switch (action.type) {
    case "CHANGE_CLAIM_DATA": {
      const fieldSplitted = action.payload.field.split(".");

      if (fieldSplitted.length === 1) {
        return {
          ...state,
          createClaimData: {
            ...state.createClaimData,
            [action.payload.field]: action.payload.value,
          },
        };
      } else if (fieldSplitted[0] === "selectedPolicy") {
        if (fieldSplitted.length === 2) {
          return {
            ...state,
            createClaimData: {
              ...state.createClaimData,
              selectedPolicy: {
                ...state.createClaimData.selectedPolicy,
                [fieldSplitted[1]]: action.payload.value,
              },
            },
          };
        } else if (fieldSplitted[1] === "insuredProperty") {
          return {
            ...state,
            createClaimData: {
              ...state.createClaimData,
              selectedPolicy: {
                ...state.createClaimData.selectedPolicy,
                insuredProperty: {
                  ...state.createClaimData.selectedPolicy?.insuredProperty,
                  [fieldSplitted[2]]: action.payload.value,
                },
              },
            },
          };
        }
      } else if (fieldSplitted[0] === "selectedGuarantee") {
        return {
          ...state,
          createClaimData: {
            ...state.createClaimData,
            selectedGuarantee: {
              ...state.createClaimData.selectedGuarantee,
              [fieldSplitted[1]]: action.payload.value,
            },
          },
        };
      }

      return state;
    }

    // case "INIT_NEW_POLICY": {
    //   return {
    //     ...state,
    //     createClaimData: {
    //       ...state.createClaimData,
    //       selectedPolicy: {

    //       },
    //     },
    //   };
    // }

    case "SET_LOADING_STATUS": {
      return {
        ...state,
        loadingStatus: action.payload,
      };
    }

    case "SET_OPEN_WIZARD": {
      return {
        ...state,
        createClaimData: {
          ...state.createClaimData,
          selectedPolicy: undefined,
          selectedGuarantee: undefined,
        },
        openWizard: action.payload,
        loadingStatus: action.payload ? "idle" : state.loadingStatus,
      };
    }

    case "SET_ALL_BRANCHES": {
      return {
        ...state,
        allBranches: action.payload,
      };
    }

    default:
      return state;
  }
};

export type CreateClaimData = {
  companyId?: number;
  address?: ClaimAddress;
  date?: string;
  time?: string;
  plateNumber?: string;
  selectedPolicy?: BasePolicy;
  selectedGuarantee?: Guarantee;
};

export type LoadingStatusType = "idle" | "loading" | "refreshing" | undefined;

export type CreateClaimState = {
  createClaimData: CreateClaimData;
  openWizard: boolean;
  loadingStatus?: LoadingStatusType;
  allBranches: Branch[];
};

const CreateClaimDataMock: CreateClaimData = {
  address: {
    country: "Italia",
    province: "Livorno",
    // fullAddress:
    //   "Via della Principessa & Via delle Caldanelle, 57027 San Vincenzo",
    city: "San Vincenzo",
    street:
      "Via della Principessa, Strada Provinciale 23 & Via delle Caldanelle",
    postalCode: "57027",
    latitude: 43.02866,
    longitude: 10.52797,
  },
  companyId: 100,
  date: "14/10/2024",
  time: "12:13",
  plateNumber: "EA887ZX",
};

export const initialCreateClaimState: CreateClaimState = {
  createClaimData: { address: {} }, //CreateClaimDataMock, //
  openWizard: false,
  loadingStatus: undefined,
  allBranches: [],
};

type ChangeClaimData = {
  type: "CHANGE_CLAIM_DATA";
  payload: { value: any; field: string };
};

type InitNewPolicy = {
  type: "INIT_NEW_POLICY";
};

type SetLoadingStatus = {
  type: "SET_LOADING_STATUS";
  payload: LoadingStatusType;
};

type SetOpenWizard = {
  type: "SET_OPEN_WIZARD";
  payload: boolean;
};

type SetAllBranches = {
  type: "SET_ALL_BRANCHES";
  payload: Branch[];
};

export type CreateClaimActions =
  | ChangeClaimData
  | InitNewPolicy
  | SetLoadingStatus
  | SetOpenWizard
  | SetAllBranches;
