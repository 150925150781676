import { useTranslation } from "react-i18next";
import { SubjectType } from "../../../../../config/const";
import { AppraisalSubject } from "../../../../../redux/apiSpecifications/apiCrud";
import { InputTextStyled } from "../../../../../style/Input";
import { RowSpacer } from "../../../../../style/containers";
import { FormRow } from "../../../../../style/form";
import SegmentedSubjectType from "../../../../Inputs/SegmentedSubjectType";
import AddressAutocomplete from "../../../../AzureMapsAddress/AddressAutocomplete";
import { useCallback, useEffect, useState } from "react";
import { InputFieldValidation } from "../../../../../validation/subjectValidation";
import styled from "styled-components";
import {
    checkEmailFormat,
    checkTelephone,
} from "../../../../../validation/common";

const isNotEmptyOrNull = (str: string | undefined | null) =>
    !!str && str.length > 0;

const SubjectDetailsFormWrapper = styled.div`
    .contacts-message {
        padding-top: 2em;
        color: red;
    }
`;

const highlightInput = {
    backgroundColor: "#ffffae",
};

const defaultValidationResult: InputFieldValidation = {
    validateStatus: "success",
    messageKey: "",
};

interface ISubjectDetailsFormProps {
    subject: AppraisalSubject | undefined;
    readonly?: boolean;
    onChange: (subject: AppraisalSubject, isValid: boolean) => void;
}

const SubjectDetailsForm = ({
    subject,
    readonly,
    onChange,
}: ISubjectDetailsFormProps) => {
    const { t } = useTranslation();

    const [contactValidation, setContactValidation] = useState<boolean>(true);
    const [emailValidation, setEmailValidation] =
        useState<InputFieldValidation>(defaultValidationResult);
    const [pecValidation, setPecValidation] = useState<InputFieldValidation>(
        defaultValidationResult
    );
    const [phoneValidation, setPhoneValidation] =
        useState<InputFieldValidation>(defaultValidationResult);

    useEffect(() => {
        const validationResult = validateSubject(subject);

        setContactValidation(validationResult.thereIsContact);
    }, [subject]);

    const validateSubject = useCallback(
        (subject: AppraisalSubject | undefined) => {
            const { email, pec, phone } = subject || {};

            const neEmail = isNotEmptyOrNull(email);
            const nePec = isNotEmptyOrNull(pec);
            const nePhone = isNotEmptyOrNull(phone);

            const _emailValidation: InputFieldValidation =
                !neEmail || checkEmailFormat(email || "")
                    ? defaultValidationResult
                    : {
                          validateStatus: "error",
                          messageKey: t("subject-contact-invalid-email"),
                      };

            const _pecValidation: InputFieldValidation =
                !nePec || checkEmailFormat(pec || "")
                    ? defaultValidationResult
                    : {
                          validateStatus: "error",
                          messageKey: t("subject-contact-invalid-pec"),
                      };
            const _phoneValidation: InputFieldValidation =
                !nePhone || checkTelephone(phone || "")
                    ? defaultValidationResult
                    : {
                          validateStatus: "error",
                          messageKey: t("subject-contact-invalid-phone"),
                      };

            let subjectBasicDatavalidated = false;

            // if subject type is PF check fiscal code, if PG check pIva
            if (subject?.subjectType === "PF") {
                subjectBasicDatavalidated =
                    isNotEmptyOrNull(subject?.name) &&
                    isNotEmptyOrNull(subject?.lastname);
            } else if (subject?.subjectType === "PG") {
                subjectBasicDatavalidated = isNotEmptyOrNull(
                    subject?.businessName
                );
            }

            // if an email is present, check its format
            if (neEmail) {
                setEmailValidation(_emailValidation);
            } else {
                setEmailValidation(defaultValidationResult);
            }

            // if a pec is present, check its format
            if (nePec) {
                setPecValidation(_pecValidation);
            } else {
                setPecValidation(defaultValidationResult);
            }

            // if a phone is present, check its format
            if (nePhone) {
                setPhoneValidation(_phoneValidation);
            } else {
                setPhoneValidation(defaultValidationResult);
            }

            const thereIsContact =
                (neEmail && _emailValidation.validateStatus === "success") ||
                (nePec && _pecValidation.validateStatus === "success") ||
                (nePhone && _phoneValidation.validateStatus === "success");

            const isAllContactsValidOrEmpty =
                _emailValidation.validateStatus === "success" &&
                _pecValidation.validateStatus === "success" &&
                _phoneValidation.validateStatus === "success";

            return {
                thereIsContact,
                isValid:
                    subjectBasicDatavalidated &&
                    thereIsContact &&
                    isAllContactsValidOrEmpty,
            };
        },
        []
    );

    const handleOnChange = (value: any, field: string) => {
        if (field === "phone" && !/^[0-9\s]*$/.test(value)) return;

        let updatedSubject = { ...subject, [field]: value };

        if (field === "subjectType") {
            if (value === "PF") {
                updatedSubject = {
                    ...updatedSubject,
                    businessName: undefined,
                    pIva: undefined,
                };
            } else if (value === "PG") {
                updatedSubject = {
                    ...updatedSubject,
                    name: undefined,
                    lastname: undefined,
                    fiscalCode: undefined,
                };
            }
        }

        const validationResult = validateSubject(updatedSubject);

        setContactValidation(validationResult.thereIsContact);

        onChange(updatedSubject, validationResult.isValid);
    };

    return (
        <SubjectDetailsFormWrapper>
            <FormRow>
                <SegmentedSubjectType
                    value={(subject?.subjectType as SubjectType) || "PF"}
                    onChange={(type) => handleOnChange(type, "subjectType")}
                    readonly={readonly}
                    required={true}
                />

                <RowSpacer />

                {subject?.subjectType === "PF" && (
                    <>
                        <InputTextStyled
                            label={t("edit-subject-tab-data-name")}
                            placeholder={t(
                                "edit-subject-tab-data-name-placeholder"
                            )}
                            onChange={(name) => handleOnChange(name, "name")}
                            value={subject?.name}
                            disabled={readonly}
                            required={true}
                        />

                        <RowSpacer />

                        <InputTextStyled
                            label={t("edit-subject-tab-data-lastname")}
                            placeholder={t(
                                "edit-subject-tab-data-lastname-placeholder"
                            )}
                            onChange={(lastname) =>
                                handleOnChange(lastname, "lastname")
                            }
                            value={subject?.lastname}
                            disabled={readonly}
                            required={true}
                        />
                        <RowSpacer />

                        <div style={{ flex: 1 }}></div>
                    </>
                )}

                {subject?.subjectType === "PG" && (
                    <>
                        <InputTextStyled
                            label={`${t(
                                "edit-claim-tab-counterpart-owner-businessname-label"
                            )}`}
                            required={true}
                            placeholder={t(
                                "edit-claim-tab-counterpart-owner-businessname-placeholder"
                            )}
                            onChange={(businessname) =>
                                handleOnChange(businessname, "businessName")
                            }
                            value={subject?.businessName}
                            disabled={readonly}
                        />

                        <RowSpacer />

                        <div style={{ flex: 1 }}></div>

                        <RowSpacer />

                        <div style={{ flex: 1 }}></div>
                    </>
                )}
            </FormRow>

            <FormRow>
                <InputTextStyled
                    styleInput={contactValidation ? {} : highlightInput}
                    label={`${t("subject-contact-type-E")}`}
                    required={!contactValidation}
                    placeholder={t("subject-contact-type-E")}
                    onChange={(email) => handleOnChange(email, "email")}
                    value={subject?.email}
                    validateStatus={emailValidation.validateStatus}
                    help={emailValidation.messageKey}
                    disabled={readonly}
                />

                <RowSpacer />

                <InputTextStyled
                    styleInput={contactValidation ? {} : highlightInput}
                    label={`${t("subject-contact-type-P")}`}
                    required={!contactValidation}
                    placeholder={t("subject-contact-type-P")}
                    onChange={(pec) => handleOnChange(pec, "pec")}
                    value={subject?.pec}
                    validateStatus={pecValidation.validateStatus}
                    help={pecValidation.messageKey}
                    disabled={readonly}
                />

                <RowSpacer />

                <InputTextStyled
                    styleInput={contactValidation ? {} : highlightInput}
                    label={`${t("subject-contact-type-T")}`}
                    required={!contactValidation}
                    placeholder={t("subject-contact-type-T")}
                    onChange={(phone) => handleOnChange(phone, "phone")}
                    value={subject?.phone}
                    validateStatus={phoneValidation.validateStatus}
                    help={phoneValidation.messageKey}
                    disabled={readonly}
                />

                <RowSpacer />

                <div style={{ flex: 1 }}>
                    <div className="contacts-message">
                        {!contactValidation &&
                            t("appraisal-subject-invalid-contact-message")}
                    </div>
                </div>
            </FormRow>

            <FormRow>
                <AddressAutocomplete
                    labelPlaceholder={t("subject-address-label")}
                    required={true}
                    placeholder={t("subject-address-place-placeholder")}
                    address={subject?.address!}
                    onChange={(addr) => {
                        handleOnChange({...addr, id: undefined}, "address")
                    }}
                    readOnly={readonly}
                />
            </FormRow>
        </SubjectDetailsFormWrapper>
    );
};

export default SubjectDetailsForm;
