import styled from "styled-components";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { useTranslation } from "react-i18next";
import { DatePickerStyled, SelectStyled } from "../../../style/Input";
import { ButtonConfirm } from "../../Layout/Buttons";
import { useEffect, useState } from "react";
import { useAuthorization } from "../../../hooks/useAuthorization";
import {
  ProvincesAndZones,
  useLazyGetAllCoverageProvincesAndZonesQuery,
} from "../../../redux/apiSpecifications/apiFesf";
import { SelectPair } from "../../../types/common.types";
import { FormRow } from "../../../style/form";
import { RowSpacer } from "../../../style/containers";
import moment from "moment";
import { DateFormat } from "../../../config/const";

const SupplierNetworkFilterWrapper = styled(WidgetWrapper)`
  width: 100%;
  flex-direction: column;
  align-items: stretch;

  .widget-title {
    padding: 0.5em 2em;
    display: flex;
    color: #333;
    background-color: #ededed;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .body {
    padding: 2em;

    flex-direction: column;
    align-items: center;
    gap: 1em;
  }
`;

export type SupplierNetworkFilterType = {
  date: string;
  districtCode?: string;
};

interface ISupplierNetworkFilterProps {
  filter: SupplierNetworkFilterType;
  onChange: (value: any, field: string) => void;
  onConfirm: () => void;
}

const SupplierNetworkFilter = ({
  filter,
  onChange,
  onConfirm,
}: ISupplierNetworkFilterProps) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<SelectPair[]>([
    {
      value: "",
      label: t("supplier-network-dashboard-district-options-all"),
    } as SelectPair,
  ]);

  const { getAuthorization } = useAuthorization();
  const [loadZones] = useLazyGetAllCoverageProvincesAndZonesQuery();

  useEffect(() => {
    loadProvinces();
  }, []);

  const loadProvinces = async () => {
    const response = await loadZones({
      authorization: await getAuthorization(),
    });

    if (response.isSuccess && response.data) {
      const correctData = response.data as ProvincesAndZones;
      const provincesOptions = correctData.provinces?.map(
        (province) =>
          ({
            value: province.districtCode,
            label: province.districtName,
          } as SelectPair)
      );

      let sortedProvincesOptions = provincesOptions?.sort((p1, p2) =>
        p1.label > p2.label ? 1 : p1.label < p2.label ? -1 : 0
      );

      sortedProvincesOptions?.unshift({
        value: "",
        label: t("supplier-network-dashboard-district-options-all"),
      });

      setOptions(sortedProvincesOptions as SelectPair[]);
    }
  };

  const handleFilterSearchLabel = (input: string, option: any) => {
    const text = input.toLowerCase();
    return option.label.toLowerCase().includes(text) || false;
  };

  return (
    <SupplierNetworkFilterWrapper>
      <div className="widget-title">
        {t("supplier-network-dashboard-calendar-title")}
      </div>

      <div className="body">
        <FormRow style={{ alignItems: "flex-end" }}>
          <DatePickerStyled
            value={filter.date}
            onChange={(date) => {
              onChange(
                date === undefined ? moment().format(DateFormat) : date,
                "date"
              );
            }}
            placeholder={"gg/mm/aaaa"}
            label={"Data"}
            disabledDate={(current) => {
              return moment().add(-1, "days") >= current;
            }}
          />

          <RowSpacer />

          <SelectStyled
            label={t("edit-expertizer-management-coverage-province-province")}
            classNameInput="input-province"
            value={filter.districtCode}
            options={options}
            onChange={(districtCode) =>
              onChange(districtCode ?? "", "districtCode")
            }
            showSearch
            allowClear={filter.districtCode !== ""}
            filterOption={handleFilterSearchLabel}
          />
        </FormRow>

        {/* <pre>{JSON.stringify(filter, null, 2)}</pre> */}

        <ButtonConfirm onClick={onConfirm}>
          {t("supplier-network-dashboard-calendar-button")!}
        </ButtonConfirm>
      </div>
    </SupplierNetworkFilterWrapper>
  );
};

export default SupplierNetworkFilter;
