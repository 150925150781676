import { Modal } from "antd";
import styled from "styled-components";
import { ButtonCancel, ButtonConfirm } from "../../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import WaitingForNetworkDelay from "../../../../Layout/WaitingForNetworkDelay";
import UserSubjectForm from "./UserSubjectForm";
import useUserSubjectForm from "./useUserSubjectForm";
import { UserItem } from "../../UserItem";
import { ButtonsWrapper } from "../../style/ButtonsWrapper";
import { FullUser } from "../../../../../redux/apiSpecifications/apiFesf";
import { Contact } from "../../../../../redux/apiSpecifications/apiCrud";

const ModalContent = styled.div`
  padding: 2em;

  .ant-form-item-explain {
    color: red !important;
  }
`;

interface IUserSubjectModalProps {
  user: UserItem;
  onCancel: () => void;
  onConfirm: (fullUser: FullUser) => void;
}

const UserSubjectModal = (props: IUserSubjectModalProps) => {
  const { user, onCancel, onConfirm } = props;

  const { data, canSubmit, userUICommunications, handleDataChange } =
    useUserSubjectForm(user);

  const { t } = useTranslation();

  const handleOnSave = () => {
    const fullUser: FullUser = {
      idSubject: data.idSubject,
      subjectType: "PF",
      name: data.name,
      lastname: data.lastName,
      fiscalCode: data.fiscalCode,
      contacts: [],
    };

    if (data.phone ?? "" !== "") {
      fullUser.contacts!.push({
        type: "T",
        value: data.phone,
      } as Contact);
    }

    if (data.email ?? "" !== "") {
      fullUser.contacts!.push({
        type: "E",
        value: data.email,
      } as Contact);
    }

    if (data.pec ?? "" !== "") {
      fullUser.contacts!.push({
        type: "P",
        value: data.pec,
      } as Contact);
    }

    onConfirm(fullUser);
  };

  const handleOnCancel = () => {
    onCancel();
  };

  return (
    <Modal
      open={true}
      footer={null}
      closable={false}
      onCancel={handleOnCancel}
      width="800px"
    >
      <ModalContent>
        {userUICommunications.action !== "idle" ? (
          <WaitingForNetworkDelay
            action={userUICommunications.action}
            status={userUICommunications.status}
            result={userUICommunications.result}
            errorDetails={userUICommunications.errorDetails}
            onGoBack={() => {}}
            onDismiss={() => {}}
          />
        ) : (
          <>
            <UserSubjectForm model={data} onChange={handleDataChange} />

            <ButtonsWrapper>
              <ButtonCancel onClick={handleOnCancel}>
                {t("button-cancel")!}
              </ButtonCancel>

              <ButtonConfirm onClick={handleOnSave} disabled={!canSubmit}>
                {t("button-confirm")!}
              </ButtonConfirm>
            </ButtonsWrapper>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default UserSubjectModal;
