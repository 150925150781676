import React from "react";
import {
  ConcludePracticeStatuses,
  UpdateQuestionnaireDataType,
} from "./ConcludePractice";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ButtonConfirm } from "../../../../../Layout/Buttons";
import { fileToBase64 } from "../../../../../../utils/fileUtils";
import {
  DocumentFiles,
  PracticeAttachment,
  PracticeDocument,
  UploadPracticeDocumentApiResponse,
  useUploadPracticeDocumentMutation,
} from "../../../../../../redux/apiSpecifications/apiCrud";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useAuthorization } from "../../../../../../hooks/useAuthorization";
import { IconLoading } from "../../../../../../config/icons";

// Styled components
const SolveMissingAgreementWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
`;

const LoaderWrapper = styled.div`
  width: 100px;
  height: 100px;
`;

/**
 * @desccription
 * @interface ISolveMissingAgreementProps
 * @onUpdatePractice {(newValues: any) => void} - callback called to update some practice's values
 */

interface ISolveMissingAgreementProps {
  idPractice?: number;
  onUpdateQuestionnaire: (val: UpdateQuestionnaireDataType) => void;
}

/**
 * ConcludePractice component
 *
 * @component
 * @example
 * <ConcludePractice />
 */
const SolveMissingAgreement: React.FC<ISolveMissingAgreementProps> = ({
  idPractice,
  onUpdateQuestionnaire,
}) => {
  // Common variables
  const { t } = useTranslation();
  const { getAuthorization } = useAuthorization();

  // Local state
  const refAgreementFile = React.useRef<HTMLInputElement>(null);
  const [loadingAgreement, setLoadingAgreement] =
    React.useState<boolean>(false);

  // Fetch & update
  const [saveDocument] = useUploadPracticeDocumentMutation();

  // Handles

  const selectAgreementFile = () => refAgreementFile.current?.click();

  const handleLoadAgreement = async (e: any) => {
    if (!e) return;

    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    setLoadingAgreement(true);

    const fileContent = await fileToBase64(selectedFile);

    const attachment = {
      id: 0,
      name: selectedFile.name,
      originalName: selectedFile.name,
      base64File: fileContent,
    } as PracticeAttachment;

    const practiceDocument: DocumentFiles = {
      documentCode: "",
      documentTypeCode: "ACC",
      attachmentsFiles: [attachment],
    };

    onUpdateQuestionnaire({
      questionnaire: {
        agreement: "S",
      },
      documents: [practiceDocument],
      status: "questionnare",
    });
  };

  const handleWaitingForAgreement = () => {
    onUpdateQuestionnaire({
      questionnaire: { agreement: "SN" },
      status: "questionnare",
    });
  };

  const handleVocalAgreement = () => {
    onUpdateQuestionnaire({
      questionnaire: { agreement: "N", isVocalAgreement: true },
      status: "questionnare",
    });
  };

  const handleNoAgreement = () => {
    onUpdateQuestionnaire({
      questionnaire: { agreement: "N" },
      status: "load-adz",
    });
  };

  return (
    <SolveMissingAgreementWrapper>
      {loadingAgreement && <LoaderWrapper>{IconLoading}</LoaderWrapper>}
      {!loadingAgreement && (
        <>
          <ButtonConfirm onClick={selectAgreementFile}>
            {
              t(
                "appraisals-conclude-practice-button-solve-missing-agreement-load"
              )!
            }
          </ButtonConfirm>
          <input
            ref={refAgreementFile}
            type="file"
            accept="application/pdf"
            style={{ display: "none" }}
            onChange={(e) => handleLoadAgreement(e)}
          />

          <ButtonConfirm onClick={handleWaitingForAgreement}>
            {
              t(
                "appraisals-conclude-practice-button-solve-missing-agreement-waiting"
              )!
            }
          </ButtonConfirm>
          <ButtonConfirm onClick={handleVocalAgreement}>
            {
              t(
                "appraisals-conclude-practice-button-solve-missing-agreement-vocal"
              )!
            }
          </ButtonConfirm>
          <ButtonConfirm onClick={handleNoAgreement}>
            {
              t(
                "appraisals-conclude-practice-button-solve-missing-agreement-no"
              )!
            }
          </ButtonConfirm>
        </>
      )}
    </SolveMissingAgreementWrapper>
  );
};

export default SolveMissingAgreement;
