import styled from "styled-components";
// import { WidgetWrapper } from "../../../../../../style/DashbordWidgetWrappers";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_RowSelectionState,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  AppraiserMapData,
  PracticeMapData,
} from "../../../../../redux/apiSpecifications/apiCrud";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import { Popconfirm } from "antd";

const AppraisersListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
`;

const CellWrapper = styled.div`
  padding: 1em 0;

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    button {
      font-size: 0.8em;
    }
  }
`;

export type AssignmentMapAppraisersListDataItem = {
  id: number;
  nominative: string;
  managed: number;
  dailyManaged: number;
  leftMonthly: number;
  monthlyManageable: number;
  score: number;
};

interface IAssignmentMapSelectableAppraisersListProps {
  appraisers: AppraiserMapData[] | undefined;
  practice: PracticeMapData;
  onAssignAppraiser: (idAppraiser: number, idPractice: number) => void;
}

const AssignmentMapSelectableAppraisersList = ({
  appraisers,
  practice,
  onAssignAppraiser,
}: IAssignmentMapSelectableAppraisersListProps) => {
  const { t } = useTranslation();

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [popconfirmSave, setPopconfirmSave] = useState<boolean>(false);
  const [popconfirmCancel, setPopconfirmCancel] = useState<boolean>(false);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  useEffect(() => {
    initSelection();
  }, [appraisers]);

  const initSelection = () => {
    const result: MRT_RowSelectionState = {};

    if (practice.assignedAppraiserId !== undefined) {
      appraisers?.forEach((appraiser) => {
        if (practice.assignedAppraiserId === appraiser.id) {
          result[appraiser.id!.toString()] = true;
        }
      });
    }

    setRowSelection(result);
  };

  const handleFormateNominative = (
    appraiserName: string | undefined
  ): string => {
    let nominative: string = "";

    if (appraiserName != undefined) {
      let arrStr = appraiserName.split(" ");
      const arr = arrStr?.map((str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      });
      nominative = arr.join(" ");
    }

    return nominative;
  };

  const data = useMemo(
    () =>
      appraisers?.map(
        (appraiser) =>
          ({
            id: appraiser.id,
            nominative: handleFormateNominative(appraiser.name),
            managed: appraiser.totalManagedPractices,
            dailyManaged: appraiser.totalDailyCapacity ?? 0,
            leftMonthly: appraiser.monthlyResidual ?? 0,
            monthlyManageable: appraiser.totalMonthlyCapacity ?? 0,
            score: appraiser.totalScore ?? 0,
          } as AssignmentMapAppraisersListDataItem)
      ) || [],
    [appraisers]
  );

  const resultsColumns = useMemo<
    MRT_ColumnDef<AssignmentMapAppraisersListDataItem>[]
  >(
    () => [
      {
        accessorKey: "nominative",
        header: t("assignment-map-appriasers-list-col-nominative-label"),
        size: 100,
        Cell: ({ row }) => <CellWrapper>{row.original.nominative}</CellWrapper>,
      },
      {
        accessorKey: "managed",
        header: t("assignment-map-appriasers-list-col-managed-label"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>
            <div style={{ textAlign: "center" }}>{row.original.managed}</div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "dailyManaged",
        header: t("assignment-map-appriasers-list-col-daily-managed-label"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>
            <div style={{ textAlign: "center" }}>
              {row.original.dailyManaged}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "leftMonthly",
        header: t("assignment-map-appriasers-list-col-left-monthly-label"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>
            <div style={{ textAlign: "center" }}>
              {row.original.leftMonthly}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "monthlyManageable",
        header: t(
          "supplier-network-dashboard-appriasers-list-col-monthly-manageably-label"
        ),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>
            <div style={{ textAlign: "center" }}>
              {row.original.monthlyManageable}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "score",
        header: t("assignment-map-appriasers-list-col-score"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>
            <div style={{ textAlign: "center" }}>{row.original.score}</div>
          </CellWrapper>
        ),
      },
    ],
    [appraisers]
  );

  const disableAssignButton = useMemo(() => {
    if (Object.keys(rowSelection).length === 0) {
      return true;
    }

    if (
      practice.assignedAppraiserId !== undefined &&
      practice.assignedAppraiserId !== null
    ) {
      if (rowSelection[practice.assignedAppraiserId!.toString()] === true) {
        return true;
      }
    }

    return false;
  }, [rowSelection]);

  const disableCancelSelectionButton = useMemo(() => {
    if (Object.keys(rowSelection).length === 0) {
      return true;
    }

    if (
      practice.assignedAppraiserId !== undefined &&
      practice.assignedAppraiserId !== null
    ) {
      if (rowSelection[practice.assignedAppraiserId!.toString()] === true) {
        return true;
      }
    }

    return false;
  }, [rowSelection]);

  const handleCancelSelection = () => {
    initSelection();
  };

  const handleAssignAppraiser = () => {
    let idAppraiser: number;

    appraisers?.forEach((appraiser) => {
      if (rowSelection[appraiser.id!.toString()] === true) {
        idAppraiser = appraiser.id!;
      }
    });

    onAssignAppraiser(idAppraiser!, practice.id!);
  };

  return (
    <AppraisersListWrapper>
      {/* <pre>{JSON.stringify(rowSelection, null, 2)}</pre> */}
      <MaterialReactTable
        columns={resultsColumns}
        data={data}
        enableDensityToggle={false}
        enableColumnOrdering
        onPaginationChange={setPagination}
        enableRowSelection={true}
        enableMultiRowSelection={false}
        getRowId={(row: AssignmentMapAppraisersListDataItem) =>
          row.id.toString()
        }
        onRowSelectionChange={setRowSelection}
        state={{ rowSelection, pagination }}
        muiTableBodyCellProps={{
          sx: {
            textAlign: "left",
          },
        }}
      />

      <div className="button-wrapper">
        <Popconfirm
          placement="bottom"
          icon={null}
          title={t("assignment-map-appriasers-list-button-cancel-selection")}
          onConfirm={handleCancelSelection}
          onCancel={() => setPopconfirmCancel(false)}
          okText={t("switch-yes")!}
          cancelText={t("switch-no")!}
        >
          <ButtonConfirm
            onClick={() => {}}
            disabled={disableCancelSelectionButton}
          >
            {t("button-cancel-selection")!}
          </ButtonConfirm>
        </Popconfirm>

        <Popconfirm
          placement="bottomRight"
          icon={null}
          title={t("assignment-map-appriasers-list-button-save-confirm")}
          onConfirm={handleAssignAppraiser}
          onCancel={() => setPopconfirmSave(false)}
          okText={t("switch-yes")!}
          cancelText={t("switch-no")!}
        >
          <ButtonConfirm onClick={() => {}} disabled={disableAssignButton}>
            {t("button-assign")!}
          </ButtonConfirm>
        </Popconfirm>
      </div>
    </AppraisersListWrapper>
  );
};

export default AssignmentMapSelectableAppraisersList;
