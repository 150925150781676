import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { widgets } from "../../../config/testIds";
import { DashboardExperiseInitialDialogSelectionType } from "../../../config/const";
import { Modal, notification } from "antd";
import { MouseEvent, useMemo } from "react";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { ButtonConfirm } from "../../Layout/Buttons";
import dekra from "../../../style/dekra";
import { FilterPriorityValues } from "../../MainTabs/Dashboards/Dashboard-E2";

const testIds = widgets.expertisesStatuses.initialDialog;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4em 0 6em 0;
  .header {
    .welcome {
      text-align: center;
      font-size: 1.8em;
      margin-bottom: 2em;
      color: #555;
      letter-spacing: 2px;
    }
    .close-button {
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    width: 30em;
    gap: 1em;
  }
`;

const ButtonStatus = styled(ButtonConfirm)<{ zeroValue?: boolean }>`
  background-color: #e24d28 !important;

  ${({ zeroValue }) =>
    zeroValue &&
    `
		background-color: #fff !important;
		color: #333 !important;
		border: 1px solid #ddd !important;
	`}

  span {
    display: flex;
    .text {
      flex: 1;
      text-align: left;
      padding-left: 1em;
    }

    .value {
      text-align: right;
      padding-right: 1em;
      font-weight: bold;
      font-size: 1.2em;
    }
  }
`;

interface IExpertizerInitialDialogProps {
  values: FilterPriorityValues;
  notifications: number;
  onSelect: (
    selection: number | DashboardExperiseInitialDialogSelectionType
  ) => void;
}

const ExpertizerInitialDialog = (props: IExpertizerInitialDialogProps) => {
  const {
    values: { urgent, important, expiring, expired, standard },
    notifications,
    onSelect,
  } = props;

  const { t } = useTranslation();
  const username = useSelector(
    (state: RootState) => state.user.userDetails?.firstname
  );

  const handleCancel = () => {
    if (
      urgent === 0 &&
      important === 0 &&
      expiring === 0 &&
      expired === 0 &&
      standard === 0 &&
      notifications === 0
    )
      onSelect(0);
    else onSelect(-1);
  };

  const quantities = [urgent, important, expiring, expired, standard];

  const buttons = useMemo(
    () =>
      [1, 2, 3, 4, 5].map((priority, index) => ({
        value: priority,
        text: t(`appraisal-priority-${priority}`),
        quantity: quantities[index],
      })),

    [props.values]
  );

  return (
    <Modal open={true} onCancel={handleCancel} footer={null} width="75%">
      <ContentWrapper data-testid={testIds.container}>
        <div className="header">
          <div className="welcome">
            {t("expertizer-dashboard-welcome-1")} {username}!
            <br />
            {t("expertizer-dashboard-welcome-2")}
          </div>
          <div className="close-button"></div>
        </div>
        <div className="buttons">
          {buttons.map(({ value, text, quantity }, index) => (
            <ButtonStatus
              className="btn-statuses"
              onClick={() => onSelect(value)}
              size="large"
              key={index}
              zeroValue={!(quantity > 0)}
              disabled={quantity < 1}
            >
              <>
                <div className="text">{text}</div>

                <div className="value">{quantity || 0}</div>
              </>
            </ButtonStatus>
          ))}

          <ButtonStatus
            className="btn-statuses"
            onClick={() => onSelect("notifications")}
            size="large"
            zeroValue={!(notifications > 0)}
            disabled={notifications < 1}
          >
            <>
              <div className="text">
                {t("expertizer-dashboard-button-notifications")}
              </div>

              <div className="value">{notifications || 0}</div>
            </>
          </ButtonStatus>
        </div>
      </ContentWrapper>
    </Modal>
  );
};

export default ExpertizerInitialDialog;
