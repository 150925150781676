import { useTranslation } from "react-i18next";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import { useMemo } from "react";

type PECSentDescriptionDetails = {
    pec_result: string;
    pec_type: string;
    receiver_role: string;
    receiver_email: string;
};

interface IPECSentDescriptionProps {
    modifyDetailsJson: Modifydetail[];
}

const PECSentDescription = ({
    modifyDetailsJson,
}: IPECSentDescriptionProps) => {
    const { t } = useTranslation();

    const pecSentDescriptionDetails: PECSentDescriptionDetails =
        useMemo(() => {
            const pec_type = modifyDetailsJson.find(
                (_) => _.key === "pec_type"
            );

            const receiver_role = modifyDetailsJson.find(
                (_) => _.key === "receiver_role"
            );

            return {
                pec_result:
                    modifyDetailsJson.find((_) => _.key === "pec_result")
                        ?.value ?? "",
                pec_type: pec_type
                    ? t(`communication_type-${pec_type.value}`)
                    : "",
                receiver_role: receiver_role
                    ? t(`communication_receiver_role-${receiver_role.value}`)
                    : "",
                receiver_email:
                    modifyDetailsJson.find((_) => _.key === "receiver_email")
                        ?.value ?? "",
            } as PECSentDescriptionDetails;
        }, [modifyDetailsJson]);

    return (
        <>
            {t(`historical-actions-pec-sent-${pecSentDescriptionDetails.pec_result}-description`, {
                pec_type: pecSentDescriptionDetails.pec_type,
                receiver_role: pecSentDescriptionDetails.receiver_role,
                receiver_email: pecSentDescriptionDetails.receiver_email,
            })}
        </>
    );
};

export default PECSentDescription;