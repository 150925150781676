import { DonutDataItemType } from "../../../Charts/DonutChart";

export class AppraisalsRequestsChartHelper {
    static getCenterValue = (items: DonutDataItemType[]): string => {
        const sum = items.reduce((acc, cur) => {
            return acc + cur.value;
        }, 0);

        return String(sum);
    };

    static getFill = (category: string): string => {
        let fill: string = "";

        switch (category) {
            case "AD":
                fill = "#FFD740";
                break;
            case "ATI":
                fill = "#26A69A";
                break;
            case "CIL":
                fill = "#FF7043";
                break;
            case "API":
                fill = "#D81B60";
                break;
            case "APN":
                fill = "#448AFF";
                break;
            case "RAC":
                fill = "#7FC782";
                break;
            case "RA":
                fill = "#FFA726";
                break;
            case "RCTVP":
                fill = "#4CAF50";
                break;
            case "RCTVN":
                fill = "#42A5F5";
                break;
            case "RPAPN":
                fill = "#A37666";
                break;
            case "S":
                fill = "#FF5252 ";
                break;
            case "X":
                fill = "#B0BEC5";
                break;
        }

        return fill;
    };
}
