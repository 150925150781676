import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DocProcessingAlert } from "../../../../../../redux/apiSpecifications/apiCrud";
import { Tooltip } from "antd";
import { SegmentedStyled, SelectStyled } from "../../../../../../style/Input";
import { SegmentedValue } from "antd/lib/segmented";
import dekra from "../../../../../../style/dekra";

// Styled components
const IsNegativeSelectionWrapper = styled.div<{
  isNegative: boolean | undefined;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2em;
  margin-bottom: 3em;

  .ant-select-selector {
    width: 20em !important;
  }

  .ant-segmented-item-selected {
    background-color: ${(props) =>
      props.isNegative === undefined ? "orange" : dekra.primaryColor};
    color: #fff;
  }
`;

/**
 * @desccription
 * @interface ILoadAdzProps
 * @property {boolean | undefined} value - selected value
 * @property {string | undefined} isNegativeReason - negative reason
 * @property {(value: boolean | undefined) => void} onChange - on change selected value
 * @property {(value: string) => void} onChangeReason - on change negative reason
 */

interface IIsNegativeSelectionProps {
  value: boolean | undefined;
  isNegativeReason: string | undefined;
  onChange: (value: boolean | undefined) => void;
  onChangeReason: (value: string) => void;
}

/**
 * IsNegativeSelection component
 *
 * @component
 * @example
 * <IsNegativeSelection  />
 */
const IsNegativeSelection: React.FC<IIsNegativeSelectionProps> = ({
  value,
  isNegativeReason,
  onChange,
  onChangeReason,
}) => {
  // COMMON VARIABLES
  const { t } = useTranslation();

  // LOCAL STATE
  const options = useMemo(() => [t("switch-yes"), t("switch-no"), "---"], []);

  const isNegativeReasonOptions = useMemo(
    () =>
      [
        "",
        "VEN",
        "ROT",
        "RIP",
        "VSS",
        "VNR",
        "VSD",
        "RSC",
        "NRC",
        "NE",
        "DND",
        "DGP",
      ].map((o) => ({
        value: o,
        label: t(
          `appraisals-conclude-practice-questionnaire-is-negative-reason-${o}`
        ),
      })),
    []
  );

  const handleChange = (value: SegmentedValue) => {
    const bVal =
      value === options[0] ? true : value === options[1] ? false : undefined;
    onChange(bVal);
  };

  const mappedValue = useMemo(() => {
    if (value === true) return options[0];
    if (value === false) return options[1];
    if (value === undefined) return options[2];
  }, [value]);

  return (
    <IsNegativeSelectionWrapper isNegative={value}>
      <label className="label">
        <Tooltip
          title={t(
            "appraisals-conclude-practice-questionnaire-question-1-tooltip"
          )}
        >
          {t("appraisals-conclude-practice-questionnaire-question-1-label")}
        </Tooltip>
      </label>

      <SegmentedStyled
        value={mappedValue}
        options={options}
        onChange={handleChange}
      />

      {value && (
        <SelectStyled
          options={isNegativeReasonOptions}
          onChange={onChangeReason}
          value={isNegativeReason}
        />
      )}
    </IsNegativeSelectionWrapper>
  );
};

export default IsNegativeSelection;
