import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MaterialReactTable from "material-react-table";
import { MRT_ColumnDef } from "material-react-table";
import { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { WidgetWrapper } from "../../../../style/DashbordWidgetWrappers";
import {
  PracticeE2List,
  PracticesProcessingStatus,
  ReminderPractice,
  useLazyGetPracticesStatusQuery,
} from "../../../../redux/apiSpecifications/apiCrud";
import { useAuthorization } from "../../../../hooks/useAuthorization";
import { RootState } from "../../../../redux/store";
import { addTab } from "../../../../redux/features/mainTabsSlice";
import { useInterval } from "../../../../hooks/useInterval";
import { IconLocked } from "../../../../config/icons";

import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { ButtonConfirm } from "../../../Layout/Buttons";

const PracticeListWrapper = styled(WidgetWrapper)`
  position: relative;
  align-items: flex-start;
  max-width: 100%;
  overflow: auto;
  padding: 2em;
  margin-top: 2em;

  .MuiPaper-root {
    box-shadow: none;
    width: 100%;
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiTableCell-root {
    padding: 0;
  }

  .confirm-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
  }

  .MuiTableRow-head {
    .MuiInputBase-root {
      input {
        margin-left: 5px;
      }
    }
  }

  .button-wrapper {
    position: absolute;
    top: 1em;
    right: 3em;
    z-index: 1000;

    button {
      font-size: 0.9em;
    }
  }
`;

const CellWrapper = styled.div`
  cursor: pointer;
  padding: 0.5em 1em;

  &.blocked-practice-row {
    color: #888;
  }
`;

const BlockedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.6em;
  font-size: 1.6em;
  color: #c44f4f;
`;

export type PracticeListResultItem = {
  id: number;
  priority: string;
  clientName: string;
  sxNumber: string;
  sxType: string;
  plate: string;
  model: string;
  cap: string;
  cityAndProvince: string;
  expertDeadline: string;
  status: string;
  statusCode: string;
  isBlocked: boolean;
  blockedBy: string;
  practiceNumber: string;
  isViewed: boolean;
  rejectionDate: string;
  actions: string;
};

// truncate text to max n chars eventualli adding "..."
const truncText = (text: string | undefined, n: number) =>
  !text ? "" : text.length > n ? text.substring(0, n) + "..." : text;

interface IPracticeListProps {
  items: ReminderPractice[] | undefined;
}

const PracticeList = (props: IPracticeListProps) => {
  const dispatch = useDispatch();
  const { items } = props;
  const { t } = useTranslation();

  const { getAuthorization } = useAuthorization();

  const { activeRole } = useSelector((state: RootState) => state.user);
  const [retrivePracticesStatus] = useLazyGetPracticesStatusQuery();

  const [updatedItems, setUpdatedItems] = useState<
    ReminderPractice[] | undefined
  >(items as ReminderPractice[]);

  const [practicesToMonitoringStatus, setPracticesToMonitoringStatus] =
    useState<number[]>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setUpdatedItems(items as ReminderPractice[]);
  }, [items]);

  useEffect(() => {
    const pageItems = items?.slice(
      pagination.pageIndex * pagination.pageSize,
      (pagination.pageIndex + 1) * pagination.pageSize
    );

    const updatedPracticesToMonitor = (
      pageItems?.filter((item) => item.id !== undefined) || []
    ).map((item) => item.id as number);

    setPracticesToMonitoringStatus(updatedPracticesToMonitor);
  }, [items, pagination.pageIndex, pagination.pageSize]);

  const data = useMemo(
    () =>
      (updatedItems as ReminderPractice[])?.map((item) => {
        const status = t(`appraisal-statuses-${item.status}`) || "";

        return {
          id: item.id,
          priority: item.priority, // priorità
          priorityReason: item.priorityReason, // // motivazione priorità
          appraiserName: item.appraiserName || "", // perito/studio
          clientName: item.clientName || "", // cliente
          practiceNumber: item.practiceNumber || "", //  id incarico dekra
          claimNumber: item.claimNumber || "", // numero sinistro
          plate: item.plate || "", // targa
          receiptDate: item.receiptDate || "", // data ricezione incarico
          assigmentAppraiserDate: item.assigmentAppraiserDate || "", // data assegnazione perito
          appointmentDate: item.appointmentDate || "", // data appuntamento
          status: t(`appraisal-statuses-${item.status}`) || "", // stato
          delayFromExpiring: item.delayFromExpiring || "", // ritardo (giorni passati dalla scadenza)
          appraiserExpringDate: item.appraiserExpringDate || "", // data scadenza perito

          isHoldRequest: item.isHoldRequest || false, // flag data a trattenere
          appraisalType:
            t(
              `appraisals-details-tab-details-appraisals-expertise-type-${item.appraisalType}`
            ) || "", // tipologia incarico
          claimType:
            t(
              `reminders-supplier-network-practice-list-claim-type-${item.claimType}`
            ) || "", // tipo sinistro
        } as ReminderPractice;
      }) || [],
    [updatedItems]
  );

  const updatePracticesStatus = async () => {
    if (practicesToMonitoringStatus.length < 1) return;

    const response = await retrivePracticesStatus({
      authorization: await getAuthorization(),
      practices: practicesToMonitoringStatus,
      activeRole: activeRole!,
    });
    const statuses = response.data as PracticesProcessingStatus;

    if (response.isSuccess && statuses) {
      const _updatedItems = (
        (items as ReminderPractice[])?.filter((i) => i.id !== undefined) || []
      ).map((item) => {
        const status = statuses.find((s) => s.practiceId === item.id);

        if (status === undefined) return item;

        return {
          ...item,
          status: status.practiceStatusCode,
          blockedBy: status.blockedBy || "",
          isBlocked: status.blockedBy ? status.blockedBy.length > 0 : false,
          isViewed: status.isViewed,
        };
      });

      setUpdatedItems(_updatedItems);
    }
  };

  const {
    restart: restartRefrehInterval,
    reset: resetRefrehInterval,
    clear: clearRefreshInterval,
    percentage,
    remainingTime,
  } = useInterval({
    seconds: 10,
    callback: updatePracticesStatus,
  });

  useEffect(() => {
    return () => clearRefreshInterval();
  }, []);

  const handleSelectRow = (row: ReminderPractice) => {
    dispatch(
      addTab({
        key: 0,
        label: row.practiceNumber!,
        type: "appraisal-details",
        externalData: {
          idPractice: row.id,
        },
      })
    );
  };

  const exportToExcel = () => {
    const jsonToExport = updatedItems?.map((item) => ({
      [t("reminders-supplier-network-practice-list-priority")]: t(
        `expertizer-list-appraisal-priority-${item.priority}`
      ),
      [t("reminders-supplier-network-practice-list-priority-reason")]: t(
        `select-practice-urgency-reason-${item.priorityReason || ""}`
      ),
      [t("reminders-supplier-network-practice-list-appraiser-name")]:
        item.appraiserName || "", // perito/studio
      [t("reminders-supplier-network-practice-list-client-name")]:
        item.clientName || "", // cliente
      [t("reminders-supplier-network-practice-list-practice-number")]:
        item.practiceNumber || "", //  id incarico dekra
      [t("reminders-supplier-network-practice-list-claim-number")]:
        item.claimNumber || "", //  claim number
      [t("reminders-supplier-network-practice-list-plate")]: item.plate || "", // targa
      [t("reminders-supplier-network-practice-list-receipt-date")]:
        item.receiptDate || "", // data ricezione incarico
      [t("reminders-supplier-network-practice-list-assigment-appraiser-date")]:
        item.assigmentAppraiserDate || "", // data assegnazione perito
      [t("reminders-supplier-network-practice-list-appointment-date")]:
        item.appointmentDate || "", // data appuntamento
      [t("reminders-supplier-network-practice-list-status")]: t(
        `appraisal-statuses-${item.status}`
      ), // stato

      [t("reminders-supplier-network-practice-list-delay-expiring-date")]:
        item.delayFromExpiring || "", // ritardo (giorni passati dalla scadenza)
      [t("reminders-supplier-network-practice-list-appraiser-expiring-date")]:
        item.appraiserExpringDate || "", // data scadenza perito

      [t("reminders-supplier-network-practice-list-appraisal-type")]:
        t(
          `appraisals-details-tab-details-appraisals-expertise-type-${
            item.appraisalType || ""
          }`
        ) || "", // tipologia incarico
      [t("reminders-supplier-network-practice-list-claim-type")]: t(
        `reminders-supplier-network-practice-list-claim-type-${
          item.claimType || ""
        }`
      ), // tipo sinistro
    }));

    const worksheet = XLSX.utils.json_to_sheet(
      jsonToExport as ReminderPractice[]
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Solleciti");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "Solleciti" + ".xlsx");
  };

  const resultsColumns = useMemo<MRT_ColumnDef<ReminderPractice>[]>(
    () => [
      {
        accessorKey: "priority",
        header: t("reminders-supplier-network-practice-list-priority"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {t(`expertizer-list-appraisal-priority-${row.original.priority}`)}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "priorityReason",
        header: t("reminders-supplier-network-practice-list-priority-reason"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {t(
              `select-practice-urgency-reason-${
                row.original.priorityReason || ""
              }`
            )}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "appraiserName",
        header: t("reminders-supplier-network-practice-list-appraiser-name"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            <div title={row.original.appraiserName}>
              {truncText(row.original.appraiserName, 15)}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "clientName",
        header: t("reminders-supplier-network-practice-list-client-name"),
        size: 250,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.clientName}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "practiceNumber",
        header: t("reminders-supplier-network-practice-list-practice-number"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            <div title={row.original.practiceNumber}>
              {row.original.practiceNumber}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "claimNumber",
        header: t("reminders-supplier-network-practice-list-claim-number"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            <div title={row.original.claimNumber}>
              {row.original.claimNumber}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "plate",
        header: t("reminders-supplier-network-practice-list-plate"),
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.plate}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "receiptDate",
        header: t("reminders-supplier-network-practice-list-receipt-date"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.receiptDate}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "assigmentAppraiserDate",
        header: t(
          "reminders-supplier-network-practice-list-assigment-appraiser-date"
        ),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.assigmentAppraiserDate}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "appointmentDate",
        header: t("reminders-supplier-network-practice-list-appointment-date"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.appointmentDate}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "status",
        header: t("reminders-supplier-network-practice-list-status"),
        size: 150,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.status}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "delayFromExpiring",
        header: t(
          "reminders-supplier-network-practice-list-delay-expiring-date"
        ),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.delayFromExpiring}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "appraiserExpringDate",
        header: t(
          "reminders-supplier-network-practice-list-appraiser-expiring-date"
        ),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.appraiserExpringDate}
          </CellWrapper>
        ),
      },

      {
        accessorKey: "appraisalType",
        header: t("reminders-supplier-network-practice-list-appraisal-type"),
        size: 250,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.appraisalType}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "claimType",
        header: t("reminders-supplier-network-practice-list-claim-type"),
        size: 200,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.claimType}
          </CellWrapper>
        ),
      },
    ],
    [updatedItems]
  );

  const renderTable = () => (
    <MaterialReactTable
      columns={resultsColumns}
      data={data}
      enableDensityToggle={false}
      enableColumnOrdering
      muiTableBodyCellProps={{
        sx: {
          textAlign: "left",
        },
      }}
      onPaginationChange={setPagination}
      state={{ pagination }}
    />
  );

  return (
    <PracticeListWrapper>
      <div className="button-wrapper">
        <ButtonConfirm onClick={exportToExcel} size="small">
          {t("reminders-supplier-network-practice-list-get-excel")!}
        </ButtonConfirm>
      </div>
      {renderTable()}
    </PracticeListWrapper>
  );
};

export default PracticeList;
