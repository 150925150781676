import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
    DashboardWrapper,
    LoadingInTabWrapper,
    RefreshInTabWrapper,
} from "../../../style/DashbordWidgetWrappers";
import { IconLoading } from "../../../config/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Communication,
    DashboardF1Summary,
    GetRequestListApiArg,
    Request,
    useLazyGetF1PracticesSummaryByTenantQuery,
    useLazyGetRequestListQuery,
    useLazyGetUserCommunicationsListQuery,
} from "../../../redux/apiSpecifications/apiCrud";
import { RootState } from "../../../redux/store";
import { useInterval } from "../../../hooks/useInterval";
import { setInfoAlwaysVisibleTop } from "../../../redux/features/userSlice";
import { mainTabs as testIds } from "../../../config/testIds";
import TimerPie, { TimerUpdateWrapper } from "../../Layout/TimerPie";
import CustomerCommunications, {
    CustomerCommunicationSelectionType,
} from "../../Widgets/Customer/CustomerCommunications";
import CustomerList from "../../Widgets/Customer/CustomerList";
import { AggregateSummaryDataE2, AggregateSummaryDataF1 } from "./common-utils";
import CommunicationList from "../../Widgets/Communications/CommunicationsList";
import { Button } from "antd";
import dekra from "../../../style/dekra";
import { useAuthorization } from "../../../hooks/useAuthorization";
import AppraisalsRequestsChartCL from "../../Widgets/Appraisals/AppraisalsRequestsCharts/AppraisalsRequestsChartCL";
import { AppraisalsRequestsChartSelectionType } from "../../Widgets/Appraisals/AppraisalsRequestsCharts/AppraisalsRequestsChartSelectionType";
import { Tenant } from "../../../redux/apiSpecifications/apiFesf";
import PracticeRequestsList from "../../Widgets/PracticeRequests/PracticeRequestsList";

const DashboardF1Wrapper = styled(DashboardWrapper)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1em;

    .buttonStyle {
        background: ${dekra.primaryColor};
        border-radius: 10px;
        color: #fff;
        text-transform: uppercase;
        padding: 0px 2rem;
        text-align: right;
        border: none;
    }
`;

const DashboardRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1em;
`;

interface IDashBoard_F1Props {
    tabKey?: number;
}

const DashBoard_F1 = (props: IDashBoard_F1Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //useGetF1PracticesSummaryByTenantQuery
    const [retrieveSummaryData] = useLazyGetF1PracticesSummaryByTenantQuery();
    //useFakeUpdateF1PracticesSummaryByTenantMutation
    const [retriveCommunications] = useLazyGetUserCommunicationsListQuery();
    const [getRequestList] = useLazyGetRequestListQuery();

    const { getAuthorization } = useAuthorization();

    const { selectedTenants, activeRole } = useSelector(
        (state: RootState) => state.user
    );
    const { activeKey } = useSelector((state: RootState) => state.mainTabs);
    const autoRefreshTabData = useSelector(
        (state: RootState) => state.user.configParams?.autoRefreshTabData || 600
    ); // 10 minutes

    const {
        restart: restartRefrehInterval,
        reset: resetRefrehInterval,
        clear: clearRefreshInterval,
        percentage,
        remainingTime,
    } = useInterval({
        seconds: autoRefreshTabData,
        callback: () => {
            setLoadingStatus("refreshing");
            loadSummary();
        },
    });

    const [loadingStatus, setLoadingStatus] = useState<
        "idle" | "loading" | "refreshing"
    >("idle");

    const [allData, setAllData] = useState<DashboardF1Summary>();
    const [filteredByStatusData, setFilteredByStatusData] =
        useState<DashboardF1Summary>();

    const [filteringType, setFilteringType] = useState<
        "status" | "communications"
    >("status");

    const [communicationsData, setCommunicationsData] = useState<
        Communication[]
    >([]);

    const [requestsData, setRequestsData] = useState<Request[]>([]);

    const [communicationsFilterSelection, setCommunicationsFilterSelection] =
        useState<CustomerCommunicationSelectionType>({
            senderType: "",
            categoryCode: "",
            status: "",
        });

    const [requestsFilterSelection, setRequestsFilterSelection] =
        useState<AppraisalsRequestsChartSelectionType>({
            senderType: "",
            categoryCode: "",
        });

    useEffect(() => {
        if (activeKey === props.tabKey) {
            setLoadingStatus("refreshing");
            (async () => {
                await loadSummary();
                restartRefrehInterval();
            })();
        } else clearRefreshInterval();
    }, [activeKey]);

    const loadSummary = async () => {
        if (!selectedTenants) return;
        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        const tenantsSummaryData: DashboardF1Summary[] = [];

        for (let tenant of selectedTenants) {
            const idTenant = tenant.id!;

            const response = await retrieveSummaryData({
                idTenant,
                authorization: await getAuthorization(),
            });
            const { data, error, isSuccess, isError } = response;

            if (isSuccess && data) {
                tenantsSummaryData.push(data as DashboardF1Summary);
            } else if (isError) {
                dispatch(
                    setInfoAlwaysVisibleTop({
                        type: "error",
                        message: t("http-error-loading-dashboard"),
                    })
                );
                setLoadingStatus("idle");
                return;
            } else {
                console.log("unknown error");
            }
        }

        const aggregateSummaryDataF1 =
            AggregateSummaryDataF1(tenantsSummaryData);
        setAllData(aggregateSummaryDataF1 as DashboardF1Summary);
        setLoadingStatus("idle");
    };

    const loadCommunications = async (
        selection: CustomerCommunicationSelectionType
    ) => {
        if (!selectedTenants) return;
        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );
        const response = await retriveCommunications({
            authorization: await getAuthorization(),
            senderType: selection.senderType || "",
            categoryCode: selection.categoryCode || "",
            statusCode: selection.status || "",
            tenants: selectedTenants.map((t) => t.id!),
        });
        const { data, error, isSuccess, isError } = response;

        if (isSuccess && data) {
            setCommunicationsData(data as Communication[]);
        } else if (isError) {
            dispatch(
                setInfoAlwaysVisibleTop({
                    type: "error",
                    message: t("http-error-loading-dashboard"),
                })
            );
            setLoadingStatus("idle");
            return;
        } else {
            console.log("unknown error");
        }

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("idle");
    };

    const loadRequests = async (
        selection: AppraisalsRequestsChartSelectionType
    ) => {
        if (!selectedTenants) return;

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("refreshing");

        const params: GetRequestListApiArg = {
            authorization: await getAuthorization(),
            activeRole: activeRole!,
            tenants: selectedTenants.map((t: Tenant) => t.id!),
            lastMessageSenderType: selection.lastMessageSenderType ?? "",
            lastMessageReceiverType: selection.lastMessageReceiverType ?? "",
            categoryCode: selection.categoryCode ?? "",
            statusCode: selection.statusCode,
        };

        const response = await getRequestList(params);

        const { data, error, isSuccess, isError } = response;

        if (isSuccess && data) {
            setRequestsData(data as Request[]);
        } else if (isError) {
            dispatch(
                setInfoAlwaysVisibleTop({
                    type: "error",
                    message: t("http-error-loading-dashboard"),
                })
            );
            setLoadingStatus("idle");
            return;
        } else {
            console.log("unknown error");
        }

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("idle");
    };

    useEffect(() => {
        setLoadingStatus("loading");
        loadSummary();
    }, [selectedTenants]);

    const handleFilterCommunications = (
        selection: CustomerCommunicationSelectionType
    ) => {
        setFilteringType("communications");
        setCommunicationsFilterSelection(selection);

        setLoadingStatus("refreshing");
        loadCommunications(selection);
    };

    const handleFilterRequests = (
        selection: AppraisalsRequestsChartSelectionType
    ) => {
        setFilteringType("communications");
        setRequestsFilterSelection(selection);

        setLoadingStatus("refreshing");
        loadRequests(selection);
    };

    const resetStatus = () => {
        setFilteringType("status");
    };

    return (
        <div data-testid={testIds.dashboards.F1}>
            {loadingStatus === "loading" && (
                <LoadingInTabWrapper>
                    <div className="box" data-testid={testIds.F1.loader}>
                        {IconLoading}
                    </div>
                </LoadingInTabWrapper>
            )}
            {(loadingStatus === "refreshing" || loadingStatus === "idle") &&
                allData && (
                    <>
                        <DashboardF1Wrapper>
                            {/* <DashboardRow>
                                <AppraisalsRequestsChartCL
                                    isActive={
                                        filteringType === "communications"
                                    }
                                    userRequestsSummary={
                                        allData?.userRequestsSummary!
                                    }
                                    gridArea={"comunications"}
                                    onSelect={handleFilterRequests}
                                    isOpen={false}
                                    selection={requestsFilterSelection}
                                />
                            </DashboardRow> */}

                            {filteringType === "communications" && (
                                <DashboardRow>
                                    <Button
                                        className="buttonStyle"
                                        onClick={() => resetStatus()}
                                    >
                                        {"Torna alla lista pratiche"}
                                    </Button>
                                </DashboardRow>
                            )}

                            <DashboardRow>
                                {filteringType === "status" && (
                                    <CustomerList
                                        items={allData?.practices}
                                        isActive={activeKey === props.tabKey}
                                    />
                                )}

                                {filteringType === "communications" && (
                                    <PracticeRequestsList
                                        items={requestsData}
                                        gridArea={"practicesList"}
                                    />
                                    // <CommunicationList
                                    //     items={communicationsData}
                                    // />
                                )}
                            </DashboardRow>
                        </DashboardF1Wrapper>
                    </>
                )}
        </div>
    );
};

export default DashBoard_F1;
