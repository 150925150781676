import { useEffect, useState } from "react";
import styled from "styled-components";
import { ContentWrapper, GenericWrapper } from "../../Commons/Wrappers";
import AgendaBase, { DayContent } from "../../../../Inputs/AgendaBase";
import moment, { Moment } from "moment";
import dekra from "../../../../../style/dekra";
import {
  AppraiserAgenda as AgendaAppraiser,
  Appointment,
  DailyAgenda,
  District,
  useLazyGetMyAgendaQuery,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import { useTranslation } from "react-i18next";
import ProvinceList, { SelectedDayType } from "./ProvinceList";
import AppraiserAgendaAppointments from "./AppraiserAgendaAppointments";

import { AppraiserStatusValues as DefaultInsertLeaveValues } from "./AppraiserAgendaAppraiserStatus";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import AppraiserAgendaAppraiserStatusLeaveNF from "./AppraiserAgendaAppraiserStatusNF";
import {
  setAppraiserAgendaDays,
  setAppraiserAgendaSelectDay,
  setAppraiserAgendaSelectProvince,
  setAppraiserError,
  setAppraiserStatus,
} from "../../../../../redux/features/appraisersSlice";

type RangeValue = [string | null, string | null];

// Styled components

const AppraiserAgendaNFContainer = styled(ContentWrapper)`
  overflow: auto;
  max-height: calc(100vh - 16em);
  padding-right: 2em;
`;

const AppraiserAgendaNFWrapper = styled(GenericWrapper)`
  display: grid;
  padding: 2em 3em 3em 3em;
  height: 48em;

  grid-template-columns: auto 20em 1fr;
  grid-template-rows: 3em 30% 1fr;
  grid-template-areas:
    "calendar legend legend"
    "calendar provinces appointments"
    "calendar set-leave set-leave";

  grid-gap: 1em 5em;

  .calendar-wrapper {
    grid-area: calendar;
  }

  .legend-wrapper {
    grid-area: legend;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .provinces-wrapper {
    grid-area: provinces;
    height: 100%;
  }

  .appointments-wrapper {
    grid-area: appointments;
    height: 100%;
  }

  .set-leave-wrapper {
    grid-area: set-leave;
    height: 100%;
    width: 100%;
  }

  .appointment,
  .leave,
  .saint-patron,
  .illness,
  .suspended,
  .blocked,
  .closed {
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 1px;
    width: 4em;
    height: 1.5em;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 2em;
    color: #fff;
    background-color: ${dekra.primaryColor};
    padding: 0.1em 1em;
  }

  .illness {
    color: #fff;
    background-color: #d91b1b;
  }

  .leave,
  .saint-patron {
    color: #fff;
    background-color: #ef7c00;
  }

  .suspended {
    color: #fff;
    background-color: #ffcc00;
  }

  .blocked {
    color: #fff;
    background-color: #afabab;
  }

  .closed {
    color: #fff;
    background-color: #3b3838;
  }

  .day-content {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
  }

  .legend {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 1em;

    .col {
      flex: 1;
      display: flex;
      flex-direction: column;

      .legend-item {
        display: flex;
        align-items: center;
        gap: 0.25em;
      }

      .square-appointment,
      .square-leave,
      .square-illnes,
      .square-suspension,
      .square-blocked,
      .square-closed {
        width: 3em;
        height: 1em;
        border-radius: 5px;
        margin-right: 1em;
      }

      .square-appointment {
        background-color: #008143;
      }

      .square-leave {
        background-color: #ef7c00;
      }

      .square-illnes {
        background-color: #d91b1b;
      }

      .square-suspension {
        background-color: #ffcc00;
      }

      .square-blocked {
        background-color: #afabab;
      }

      .square-closed {
        background-color: #3b3838;
      }
    }
  }
`;

const truncate = (str: string, n: number) =>
  str.length > n ? str.slice(0, n - 1) + "..." : str;

type SelectedProvince = {
  code: string;
  name: string;
  type: string;
};

let today = moment();
today = moment([today.year(), today.month(), today.date()]);
/**
 * @description
 * @param {number | undefined} idAppraiser  - id of the appraiser
 */
interface IAppraiserAgendaNFProps {
  idAppraiser: number | undefined;
  defaultDate?: string;
}

const AppraiserAgendaNF = ({
  idAppraiser,
  defaultDate,
}: IAppraiserAgendaNFProps) => {
  const { getAuthorization } = useAuthorization();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const appraiserData = useSelector((state: RootState) =>
    state.appraisers.appraisers.find((a) => a.id === idAppraiser)
  );

  const { myAgenda } = appraiserData || {};

  const {
    days = [],
    selectedDay,
    selectionType,
    selectedAppointments = [],
    districts: allDistricts = [],
    defaultStatusValues,
    forceToUpdate,
  } = myAgenda || {};

  // Local state

  const [daysContent, setDaysContent] = useState<DayContent[]>([]);

  // API
  const [loadAgenda] = useLazyGetMyAgendaQuery();

  // Utils
  const getDistrinctName = (districtCode: string | undefined) => {
    if (!districtCode) return "";
    const district = allDistricts.find((d) => d.districtCode === districtCode);
    return truncate(district?.districtName || "", 10);
  };

  // Use Effects
  useEffect(() => {
    if (!idAppraiser || idAppraiser === 0) return;
    (async () => {
      await loadMonthData(today.format("DD/MM/YYYY"));
    })();
  }, [idAppraiser, forceToUpdate]);

  const loadMonthData = async (month: string) => {
    dispatch(setAppraiserStatus({ id: idAppraiser!, status: "loading" }));

    const { data, isError, isSuccess } = await loadAgenda({
      authorization: await getAuthorization(),
      appraiserid: idAppraiser!,
      month: month,
    });

    if (isError || !isSuccess) {
      dispatch(
        setAppraiserError({
          id: idAppraiser!,
          message: "si è verificato un errore nel caricamento dell'agenda",
        })
      );
      return;
    }

    const { districts = [], days = [] } = data as AgendaAppraiser;

    dispatch(
      setAppraiserAgendaDays({
        id: idAppraiser!,
        days,
        dayToSelect: month,
        districts,
      })
    );
  };

  useEffect(() => {
    if (!days) return;

    const newDaysContent = days.map((day) => {
      const {
        date = "",
        onIllnessDistricts = [],
        onBlockedDistricts = [],
        onSuspendedDistricts = [],
        onClosedDistricts = [],
        onLeaveDistricts = [],
        onPatronSaintDistricts = [],
        appointments = [],
      } = day;

      const hasLeaves = onLeaveDistricts.length > 0;

      const hasPatronSaint = onPatronSaintDistricts.length > 0;

      const hasIllness = onIllnessDistricts.length > 0;

      const hasBlocked = onBlockedDistricts.length > 0;

      const hasSuspended = onSuspendedDistricts.length > 0;

      const hasClosed = onClosedDistricts.length > 0;

      const hasAppointments = appointments.length > 0;

      return {
        day: moment(day.date, "DD/MM/YYYY"),
        content: (
          <>
            {hasSuspended && (
              <div
                className="suspended"
                onClick={() => handleSelectDayStatus("suspended", date)}
              ></div>
            )}
            {hasLeaves && (
              <div
                className="leave"
                onClick={() => handleSelectDayStatus("leave", date)}
              ></div>
            )}
            {hasPatronSaint && (
              <div
                className="saint-patron"
                onClick={() => handleSelectDayStatus("saint", date)}
              ></div>
            )}
            {hasIllness && (
              <div
                className="illness"
                onClick={() => handleSelectDayStatus("illness", date)}
              ></div>
            )}

            {hasBlocked && (
              <div
                className="blocked"
                onClick={() => handleSelectDayStatus("blocked", date)}
              ></div>
            )}
            {hasClosed && (
              <div
                className="closed"
                onClick={() => handleSelectDayStatus("closed", date)}
              ></div>
            )}

            {hasAppointments && (
              <div
                className="appointment"
                onClick={() => handleSelectDayStatus("appointments", date)}
              ></div>
            )}
          </>
        ),
      } as DayContent;
    });

    setDaysContent(newDaysContent);
  }, [days]);

  const handleChangeMonth = async (date: Moment) => {
    await loadMonthData(date.format("DD/MM/YYYY"));

    dispatch(
      setAppraiserAgendaSelectDay({
        id: idAppraiser!,
        date: date.format("DD/MM/YYYY"),
        type: undefined,
      })
    );
  };

  const handleDayClick = (day: Moment) => {};

  const findDateRange = (
    day: DailyAgenda,
    type: SelectedDayType,
    province: string
  ) => {
    let districts: District[] = [];

    if (type === "illness") {
      districts = day?.onIllnessDistricts || [];
    } else if (type === "leave") {
      districts = day?.onLeaveDistricts || [];
    } else if (type === "suspended") {
      districts = day?.onSuspendedDistricts || [];
    } else if (type === "blocked") {
      districts = day?.onBlockedDistricts || [];
    } else if (type === "closed") {
      districts = day?.onClosedDistricts || [];
    }

    const allProvinces = districts?.map((a) => a.districtCode || "") || [];
    const uniqueProvinces = Array.from(new Set(allProvinces || []));

    // range
    let firstDay = moment(day!.date, "DD/MM/YYYY");
    let lastDay = moment(day!.date, "DD/MM/YYYY");

    // find first day of leave
    const startDay = moment(day?.date, "DD/MM/YYYY");
    const minDay = moment(day?.date, "DD/MM/YYYY").subtract(30, "days");
    const maxDay = moment(day?.date, "DD/MM/YYYY").add(30, "days");

    const hasSameTypeAndProvinces = (d: Moment) => {
      const dDate = days.find((_d) => _d.date === d.format("DD/MM/YYYY"));
      if (!dDate) return false; // the day entry does not exist

      let dayDistricts: District[] = [];
      if (type === "illness") {
        dayDistricts = dDate?.onIllnessDistricts || [];
      } else if (type === "leave") {
        dayDistricts = dDate?.onLeaveDistricts || [];
      } else if (type === "suspended") {
        dayDistricts = dDate?.onSuspendedDistricts || [];
      } else if (type === "blocked") {
        dayDistricts = dDate?.onBlockedDistricts || [];
      } else if (type === "closed") {
        dayDistricts = dDate?.onClosedDistricts || [];
      }
      return (
        dayDistricts?.find((p) => p.districtCode === province) !== undefined
      );
    };

    for (let d = moment(startDay); d.isAfter(minDay); d.subtract(1, "days")) {
      if (hasSameTypeAndProvinces(d)) firstDay = moment(d);
      else break;
    }

    for (let d = moment(startDay); d.isBefore(maxDay); d.add(1, "days")) {
      if (hasSameTypeAndProvinces(d)) lastDay = moment(d);
      else break;
    }

    return [
      firstDay.format("DD/MM/YYYY"),
      lastDay.format("DD/MM/YYYY"),
    ] as RangeValue;
  };

  const handleSelectDayStatus = async (type: SelectedDayType, date: string) => {
    const day = days.find((d) => d.date === date);
    //const range = findDateRange(day!, type);

    if (date.slice(3, 10) !== selectedDay?.date?.slice(3, 10))
      await loadMonthData(date);

    dispatch(
      setAppraiserAgendaSelectDay({
        id: idAppraiser!,
        date,
        type,
        //  range,
      })
    );
  };

  const handleSelectProvince = (
    provinceCode: string,
    type: SelectedDayType
  ) => {
    let newAppointments: Appointment[] = [];

    if (type === "appointments") {
      newAppointments =
        selectedDay?.appointments
          ?.filter((a) => a.appointmentDistrict === provinceCode)
          ?.sort((a, b) =>
            a.appointmentTimeSlot!.localeCompare(b.appointmentTimeSlot!)
          ) || [];
    }
    // else {
    const range = findDateRange(selectedDay!, type, provinceCode);

    const districts =
      type === "suspended"
        ? selectedDay?.onSuspendedDistricts
        : type === "blocked"
        ? selectedDay?.onBlockedDistricts
        : [];

    let statusCode =
      districts?.find((sd) => sd.districtCode === provinceCode)?.reasonCode ||
      "";

    dispatch(
      setAppraiserAgendaSelectProvince({
        id: idAppraiser!,
        range,
        province: {
          code: provinceCode,
          name: getDistrinctName(provinceCode),
          type,
        } as SelectedProvince,
        appointments: newAppointments,
        defaultProvinces: [provinceCode],
        status: type,
        statusCode,
      })
    );
  };

  return (
    <AppraiserAgendaNFContainer>
      <AppraiserAgendaNFWrapper>
        <div className="calendar-wrapper">
          <AgendaBase
            currentDate={moment(selectedDay?.date, "DD/MM/YYYY") || today}
            daysContent={daysContent}
            dayMinHeight={"6em"}
            isDayClickable={false}
            onDayClick={handleDayClick}
            onDateChange={handleChangeMonth}
          />
        </div>
        <div className="legend-wrapper">
          <div className="legend">
            <div className="col">
              <div className="legend-item">
                <div className="square-appointment"></div>
                <div className="item-text">
                  {t("agenda-legend-appointments")}
                </div>
              </div>
              <div className="legend-item">
                <div className="square-leave"></div>
                <div className="item-text">{t("agenda-legend-leave")}</div>
              </div>
              <div className="legend-item">
                <div className="square-illnes"></div>
                <div className="item-text">{t("agenda-legend-illness")}</div>
              </div>
            </div>
            <div className="col">
              <div className="legend-item">
                <div className="square-suspension"></div>
                <div className="item-text">{t("agenda-legend-suspension")}</div>
              </div>
              <div className="legend-item">
                <div className="square-blocked"></div>
                <div className="item-text">{t("agenda-legend-blocked")}</div>
              </div>
              <div className="legend-item">
                <div className="square-closed"></div>
                <div className="item-text">{t("agenda-legend-closed")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="provinces-wrapper">
          <ProvinceList
            type={selectionType}
            agenda={selectedDay}
            date={selectedDay?.date}
            allDistricts={allDistricts}
            onSelect={handleSelectProvince}
          />
        </div>
        <div className="appointments-wrapper">
          <AppraiserAgendaAppointments appointments={selectedAppointments} />
        </div>
        <div className="set-leave-wrapper">
          <AppraiserAgendaAppraiserStatusLeaveNF
            idAppraiser={idAppraiser}
            //minDate={today}
            allProvinces={allDistricts}
            //agenda={selectedDay}
            daysContent={daysContent}
            //onSave={handleSaveLeaveSuspension}
          />
        </div>
      </AppraiserAgendaNFWrapper>
    </AppraiserAgendaNFContainer>
  );
};

export default AppraiserAgendaNF;
