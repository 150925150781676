import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { WidgetWrapper } from "../../../../style/DashbordWidgetWrappers";
import WidgetHeader from "../../WidgetHeader";
import DonutChart, { DonutDataItemType } from "../../../Charts/DonutChart";
import { UserRequestsSummary } from "../../../../redux/apiSpecifications/apiCrud";
import { AppraisalsRequestsChartSelectionType } from "./AppraisalsRequestsChartSelectionType";
import { AppraisalsRequestsChartData } from "./AppraisalsRequestsChartData";
import RequestsChartLegend from "./RequestsChartLegend";
import { AppraisalsRequestsChartHelper } from "./AppraisalsRequestsChartHelper";

const AppraisalsRequestsChartAPWrapper = styled(WidgetWrapper)`
  flex-direction: column;
  max-height: 2.5em;
  transition: max-height 1s;
  &.open {
    height: 400px;
    max-height: 400px;
    max-height: 400px;
  }

  div.appraisals-requests-chart-body {
    display: flex;
    height: 28em;

    div.body {
      flex: 1;

      .col {
        display: flex;
      }

      .charts-donut-container {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    div.legend-icon {
      width: 30px;
      text-align: center;
      padding-top: 8px;
    }
  }
`;

export interface IAppraisalsRequestsChartAPProps {
  userRequestsSummary: UserRequestsSummary[];
  onSelect: (selection: AppraisalsRequestsChartSelectionType) => void;
  gridArea?: string;
  isActive: boolean;
  selection: AppraisalsRequestsChartSelectionType;
}

const AppraisalsRequestsChartAP = (props: IAppraisalsRequestsChartAPProps) => {
  const { userRequestsSummary, onSelect, gridArea, isActive, selection } =
    props;

  const { t } = useTranslation();

  const [donatsData, setDonatsData] = useState<AppraisalsRequestsChartData[]>(
    []
  );

  useEffect(() => {
    if (userRequestsSummary) {
      let result: AppraisalsRequestsChartData[] = [];

      userRequestsSummary.forEach((selectedUserRequestSummary) => {
        let donutDataItems: DonutDataItemType[] = [];

        selectedUserRequestSummary.totalsByCategory!.forEach((item) => {
          donutDataItems.push({
            key: item.category!,
            name: t(`appraisals-dashboard-request-category-${item.category}`),
            value: item.total!,
            fill: AppraisalsRequestsChartHelper.getFill(item.category!),
          });
        });

        result.push({
          type: selectedUserRequestSummary.senderType!,
          items: donutDataItems,
          label: t(
            `appraisals-dashboard-ap-request-${selectedUserRequestSummary.senderType}-title`
          ),
        });
      });

      var bo = result.find((_) => _.type === "BO");
      var st = result.find((_) => _.type === "ST");
      var bo_st = result.find((_) => _.type === "BO|ST");

      let donatsData: AppraisalsRequestsChartData[] = [];

      if (bo) {
        donatsData.push({
          ...bo,
          lastMessageSenderType: "BO",
          lastMessageReceiverType: "AP",
        });
      }

      if (st) {
        donatsData.push({
          ...st,
          lastMessageSenderType: "ST",
          lastMessageReceiverType: "AP",
        });
      }

      if (bo_st) {
        donatsData.push({
          ...bo_st,
          lastMessageSenderType: "BO|ST",
          lastMessageReceiverType: "AP",
          statusCode: "C"
        });
      }

      setDonatsData(donatsData);
    }
  }, [userRequestsSummary]);

  const handleFilterReset = () => {
    onSelect({
      senderType: undefined,
      categoryCode: undefined,
    });
  };

  return (
    <AppraisalsRequestsChartAPWrapper className={"open"} gridArea={gridArea}>
      <WidgetHeader
        title={t("appraisals-dashboard-requests-title")}
        isActive={isActive}
        filterReset={handleFilterReset}
      />

      <div className="appraisals-requests-chart-body">
        <div className="body">
          {donatsData.map((donatData, index) => {
            const selected =
              isActive && selection.senderType === donatData.type
                ? selection.categoryCode
                : "";

            return (
              <div className="col" key={index}>
                <DonutChart
                  title={donatData.label}
                  data={donatData.items}
                  onClick={(key) =>
                    onSelect({
                      lastMessageSenderType: donatData.lastMessageSenderType,
                      lastMessageReceiverType:
                        donatData.lastMessageReceiverType,
                      statusCode: donatData.statusCode,
                      senderType: donatData.type,
                      categoryCode: key,
                    })
                  }
                  centerValue={AppraisalsRequestsChartHelper.getCenterValue(
                    donatData.items
                  )}
                  selected={selected}
                  hideLegend={true}
                />
              </div>
            );
          })}

          {Array.from(Array(3 - donatsData.length).keys()).map((_, index) => {
            return <div className="col" key={index}></div>;
          })}
        </div>

        <div className="legend-icon">
          <RequestsChartLegend />
        </div>
      </div>
    </AppraisalsRequestsChartAPWrapper>
  );
};

export default AppraisalsRequestsChartAP;
