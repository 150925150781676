import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { WidgetWrapper } from "../../../../../../style/DashbordWidgetWrappers";
import CreateClaimModal from "./CreateClaimModal";
import CreateClaimProvider from "./CreateClaimContext";
import CreateClaimQuickForm from "./CreateClaimQuickForm";
import { CreateClaimData } from "./CreateClaimReducer";

const CreateClaimWrapper = styled(WidgetWrapper)`
  width: 100%;
  height: 100%;
  flex-direction: column;

  .widget-title {
    padding: 0.5em 2em;
    display: flex;
    color: #333;
    background-color: #ededed;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .body {
    padding: 2em;

    flex-direction: column;
    // align-items: center;
    gap: 1em;
  }
`;

interface ICreateClaimProps {
  gridArea?: string;
  onCreateClaim: (createClaimData: CreateClaimData, complete: boolean) => void;
}

const CreateClaim = ({ onCreateClaim }: ICreateClaimProps) => {
  const { t } = useTranslation();

  return (
    <CreateClaimProvider>
      <CreateClaimWrapper>
        <div className="widget-title">{t("create-claim-form-title")}</div>

        <div className="body">
          <CreateClaimQuickForm />
        </div>
      </CreateClaimWrapper>

      <CreateClaimModal onCreateClaim={onCreateClaim} />
    </CreateClaimProvider>
  );
};

export default CreateClaim;
