import styled from "styled-components";
import { ButtonCancel, ButtonConfirm } from "../../../../../../Layout/Buttons";
import PolicyGuaranteesList, {
  GuaranteesListItem,
} from "../../../../../../Widgets/Policies/PolicyGuaranteesList";
import {
  useCreateClaimContext,
  useCreateClaimDispatch,
} from "../CreateClaimContext";
import { Alert } from "antd";
import { Guarantee } from "../../../../../../../redux/apiSpecifications/apiClaims";
import { useTranslation } from "react-i18next";

const GuaranteesListStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-end;
`;

interface IGuaranteesListStepProps {
  onNextStep: () => void;
  onPreviousStep: () => void;
}

const GuaranteesListStep = ({
  onNextStep,
  onPreviousStep,
}: IGuaranteesListStepProps) => {
  const { t } = useTranslation();

  const { createClaimData } = useCreateClaimContext();

  const dispatch = useCreateClaimDispatch();

  const handleOnCreateNewGuarantee = () => {
    dispatch!({
      type: "CHANGE_CLAIM_DATA",
      payload: {
        value: {
          excessAmount: 0,
          overdraftAmount: 0
        } as Guarantee,
        field: "selectedGuarantee",
      },
    });

    onNextStep();
  };

  const handleOnSelectGuarantee = (guarante: GuaranteesListItem) => {
    dispatch!({
      type: "CHANGE_CLAIM_DATA",
      payload: { value: guarante as Guarantee, field: "selectedGuarantee" },
    });

    onNextStep();
  };

  const guaranteesList = createClaimData?.selectedPolicy?.guarantees!;

  return (
    <GuaranteesListStepWrapper>
      {guaranteesList.length > 0 && (
        <Alert
          message={t("create-claim-wizard-select-guarantee-message")}
          type="info"
        />
      )}

      {guaranteesList.length === 0 && (
        <Alert
          message={t("create-claim-wizard-guarantee-not-found-message")}
          type="info"
        />
      )}

      <ButtonsWrapper>
        <ButtonConfirm onClick={handleOnCreateNewGuarantee}>
          Crea nuova garanzia
        </ButtonConfirm>
      </ButtonsWrapper>

      <PolicyGuaranteesList
        data={createClaimData?.selectedPolicy?.guarantees!}
        onSelectItem={handleOnSelectGuarantee}
      />

      <ButtonsWrapper>
        <ButtonCancel onClick={onPreviousStep}>
          {t("BUTTONS.previous")!}
        </ButtonCancel>
      </ButtonsWrapper>

      {/* <pre>{JSON.stringify(createClaimData?.selectedGuarantee, null, 2)}</pre> */}
    </GuaranteesListStepWrapper>
  );
};

export default GuaranteesListStep;
