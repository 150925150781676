import { useEffect, useState } from "react";
import { mainTabs as testIds } from "../../../config/testIds";
import {
    DashboardWrapper,
    LoadingInTabWrapper,
    RefreshInTabWrapper,
} from "../../../style/DashbordWidgetWrappers";
import { IconLoading } from "../../../config/icons";
import styled from "styled-components";
import ReportAppraisalsStatutes from "../../Widgets/Appraisals/Reports/ReportAppraisalsStatutes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
    AccountingReportList,
    AccountingSummary,
    ReportMetadata,
    useCreateAccountingReportMutation,
    useLazyGetG1PracticesSummaryQuery,
} from "../../../redux/apiSpecifications/apiFesf";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useTranslation } from "react-i18next";
import { setInfoAlwaysVisibleTop } from "../../../redux/features/userSlice";
import TimerPie, { TimerUpdateWrapper } from "../../Layout/TimerPie";
import { useInterval } from "../../../hooks/useInterval";
import { AggregateSummaryDataG1 } from "./common-utils";
import CreateReport from "../../Widgets/Appraisals/Reports/CreateReport/CreateReport";
import PracticeList from "../../Widgets/Appraisals/Reports/PracticeList";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";

const DashboardG1Wrapper = styled(DashboardWrapper)`
    display: grid;
    grid-template-areas:
        "statuses statuses refresh"
        "createReport createReport createReport"
        "practiceList practiceList practiceList"
        "spacer spacer spacer";
    grid-template-columns: 2fr 3fr 50px;
    grid-template-rows: 3em min-content auto auto;
    column-gap: 1em;
    row-gap: 1em;
`;

type FiltersValues = {
    selectedReportId?: number;
};

const defaultFilters: FiltersValues = {
    selectedReportId: undefined,
};

interface IDashboardG1Props {
    tabKey?: number;
}

const Dashboard_G1 = (props: IDashboardG1Props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [api, contextHolder] = notification.useNotification();

    // const { getAuthorization } = useAuthorization();

    const { activeKey } = useSelector((state: RootState) => state.mainTabs);

    const autoRefreshTabData = useSelector(
        (state: RootState) => state.user.configParams?.autoRefreshTabData || 600
    ); // 10 minutes

    const {
        restart: restartRefrehInterval,
        reset: resetRefrehInterval,
        clear: clearRefreshInterval,
        percentage,
        remainingTime,
    } = useInterval({
        seconds: autoRefreshTabData,
        callback: () => {
            setLoadingStatus("refreshing");
            loadData(true);
        },
    });

    const [loadingStatus, setLoadingStatus] = useState<
        "idle" | "loading" | "refreshing" | "load-in-progress"
    >("idle");
    const [allData, setAllData] = useState<AccountingSummary>();

    const [filters, setFilters] = useState(defaultFilters);

    const [getG1PracticesSummary] = useLazyGetG1PracticesSummaryQuery();
    const [createAccountingReport] = useCreateAccountingReportMutation();

    const { authorization, selectedTenants, activeRole } = useSelector(
        (state: RootState) => state.user
    );

    useEffect(() => {
        setLoadingStatus("loading");

        loadData();
    }, []);

    useEffect(() => {
        if (activeKey === props.tabKey) {
            setLoadingStatus("refreshing");

            (async () => {
                await loadData(true);

                restartRefrehInterval();
            })();
        } else clearRefreshInterval();
    }, [activeKey]);

    useEffect(() => {
        if (loadingStatus !== "idle" || !selectedTenants) return;

        setLoadingStatus("refreshing");

        loadData(true);
    }, [selectedTenants, filters]);

    useEffect(() => {
        if (allData?.reportList) {
            if (filters.selectedReportId) {
                const selectedReport = allData.reportList.find(
                    (_) => _.id === filters.selectedReportId
                );

                if (selectedReport === undefined) {
                    const updatedFilters = {
                        ...filters,
                        selectedReportId: undefined,
                    };

                    setFilters(updatedFilters);
                }
            } else if (allData?.reportList.length > 0) {
                const updatedFilters = {
                    ...filters,
                    selectedReportId: allData?.reportList[0].id,
                };

                setFilters(updatedFilters);
            }
        }
    }, [allData?.reportList]);

    const loadData = async (keepFilter?: boolean) => {
        if (!selectedTenants) return;

        const tenantsSummaryData: AccountingSummary[] = [];

        for (let tenant of selectedTenants) {
            const idTenant = tenant.id!;

            const response = await getG1PracticesSummary({
                idTenant,
                idReport: filters.selectedReportId,
                authorization: authorization, //await getAuthorization(),
            });

            const { data, error, isSuccess, isError } = response;

            if (isSuccess && data) {
                tenantsSummaryData.push(data as AccountingSummary);
            } else if (isError) {
                dispatch(
                    setInfoAlwaysVisibleTop({
                        type: "error",
                        message: t("http-error-loading-dashboard"),
                    })
                );

                setLoadingStatus("idle");

                return;
            } else {
                console.log("unknown error");
            }
        }

        const aggregatedSummaryData =
            AggregateSummaryDataG1(tenantsSummaryData);

        setAllData(aggregatedSummaryData);

        setLoadingStatus("idle");
    };

    const handleResetFilter = () => {
        setFilters({ ...defaultFilters });
    };

    const handleSelectReport = (reportId: number) => {
        const updatedFilters = {
            ...filters,
            selectedReportId: reportId,
        };

        setFilters(updatedFilters);
    };

    const notifySuccess = (text: string) => {
        api.success({
            message: "",
            description: text,
            duration: 5,
            placement: "bottomRight" as NotificationPlacement,
        });
    };

    const handleOnConfirmCreateReport = (
        reportMetadata: ReportMetadata
    ): Promise<string> =>
        new Promise(async (resolve, reject) => {
            // salvo qui il report

            setLoadingStatus("refreshing");

            const response = await createAccountingReport({
                authorization: authorization,
                reportMetadata: reportMetadata,
            });

            const correctResponse = response as {
                data: AccountingReportList;
            };

            const errorResponse = response as {
                error: FetchBaseQueryError;
            };

            if (correctResponse.data) {
                await loadData(true);

                notifySuccess("Richiesta nuovo report inserita con successo!");

                resolve("Success!");
            } else if (errorResponse) {
                reject(errorResponse.error);
            }

            setLoadingStatus("idle");
        });

    return (
        <div data-testid={testIds.dashboards.G1}>
            {contextHolder}
            {loadingStatus === "loading" && (
                <LoadingInTabWrapper>
                    <div className="box" data-testid={testIds.G1.loader}>
                        {IconLoading}
                    </div>
                </LoadingInTabWrapper>
            )}

            {(loadingStatus === "refreshing" ||
                loadingStatus === "load-in-progress" ||
                loadingStatus === "idle") && (
                <>
                    <DashboardG1Wrapper>
                        <TimerUpdateWrapper>
                            <TimerPie
                                percentage={percentage as number}
                                tooltip={`${t(
                                    "timer-dashboard-refresh-tooltip-1"
                                )!}  ${remainingTime}`}
                                innerText={remainingTime as string}
                                bgColor="#32b677"
                                gridArea="refresh"
                                onClick={() => {
                                    setLoadingStatus("refreshing");
                                    loadData(true);
                                    resetRefrehInterval();
                                }}
                            />
                        </TimerUpdateWrapper>

                        <ReportAppraisalsStatutes
                            managedTotal={allData?.managedTotal}
                            toBeAccountedTotal={allData?.toBeAccountedTotal}
                            reportList={allData?.reportList}
                            selectedReportId={filters.selectedReportId}
                            onSelectReport={handleSelectReport}
                            onResetFilter={handleResetFilter}
                            gridArea="statuses"
                        />

                        <CreateReport
                            gridArea="createReport"
                            onConfirmCreateReport={handleOnConfirmCreateReport}
                        />

                        {allData?.practiceList &&
                            allData?.practiceList.length > 0 && (
                                <PracticeList
                                    practices={allData?.practiceList!}
                                    gridArea="practiceList"
                                />
                            )}
                    </DashboardG1Wrapper>

                    {(loadingStatus === "refreshing" ||
                        loadingStatus === "load-in-progress") && (
                        <RefreshInTabWrapper>
                            <div
                                className="box"
                                data-testid={testIds.G1.loader}
                            >
                                {IconLoading}
                            </div>
                        </RefreshInTabWrapper>
                    )}
                </>
            )}
        </div>
    );
};

export default Dashboard_G1;
