import { SubjectType } from "../config/const";
import {
  validateClaimData,
  validateClaimResponsabilityData,
  validateCounterpartData,
  validateDamagedParts,
} from "../validation/claimValidation";
import { validateSubjectData } from "../validation/subjectValidation";
import {
  updateValidations as updateClaimValidations,
  updateClaimData,
  updateResponsabilityData,
  forceValidation as activeClaimForceValidation,
  addDamagedPart,
  removeDamagedPart,
  setDamagedPart,
  updateValidations,
  IActiveClaimState,
  updateCounterpartData,
} from "./features/activeClaimSlice";
import {
  forceValidation as activeSubjectForceValidation,
  ISubjectState,
  SubjectValidations,
  updateSubject,
  updateValidations as updateSubjectValidations,
} from "./features/activeSubject";

export const dataValidationMiddleware =
  (store: any) => (next: any) => (action: any) => {
    // UPDATE SUBJECT
    if (updateSubject.match(action)) {
      const { subject, additionalValidations } = store.getState()
        .activeSubject as ISubjectState;
      let validateSubjectDataResult = {
        isSubjectValid: false,
        additionalValidations,
      } as SubjectValidations;

      validateSubjectDataResult = validateSubjectData(
        action.payload,
        subject?.subjectType as SubjectType,
        additionalValidations
      );

      store.dispatch(updateSubjectValidations(validateSubjectDataResult));
    }
    // FORCE ACTIVE SUBJECT VALIDATION
    else if (activeSubjectForceValidation.match(action)) {
      const { subject, additionalValidations } = store.getState()
        .activeSubject as ISubjectState;

      let validateSubjectDataResult = {
        isSubjectValid: false,
        additionalValidations,
      } as SubjectValidations;

      validateSubjectDataResult = validateSubjectData(
        subject,
        subject?.subjectType as SubjectType,
        additionalValidations
      );

      store.dispatch(updateSubjectValidations(validateSubjectDataResult));
    }
    // UPDATE CLAIM DATA
    else if (updateClaimData.match(action)) {
      const { additionalValidations: previousValidations, policyData } =
        store.getState().activeClaim as IActiveClaimState;
      const policyEffectDate = policyData?.effectDate;
      const policyExpirationDate = policyData?.expirationDate;
      const validateClaimDataResult = validateClaimData(
        action.payload,
        previousValidations,
        policyEffectDate,
        policyExpirationDate
      );
      if (validateClaimDataResult)
        store.dispatch(updateClaimValidations(validateClaimDataResult));
    }
    // UPDATE COUNTERPART DATA
    else if (updateCounterpartData.match(action)) {
      const previousValidations =
        store.getState().activeClaim.additionalValidations;
      const validateCounterpartDataResult = validateCounterpartData(
        action.payload,
        previousValidations
      );
      if (validateCounterpartDataResult)
        store.dispatch(updateClaimValidations(validateCounterpartDataResult));
    }
    // UPDATE CLAIM RESPONSABILITY DATA
    else if (updateResponsabilityData.match(action)) {
      const additionalValidations =
        store.getState().activeClaim.additionalValidations;

      const validateClaimDataResult = validateClaimResponsabilityData(
        action.payload,
        additionalValidations
      );

      if (validateClaimDataResult)
        store.dispatch(updateClaimValidations(validateClaimDataResult));
    }
    // FORCE ACTIVE CLAIM VALIDATION
    else if (activeClaimForceValidation.match(action)) {
      const { claimData, responsability, additionalValidations, policyData } =
        store.getState().activeClaim as IActiveClaimState;

      const policyEffectDate = policyData?.effectDate;
      const policyExpirationDate = policyData?.expirationDate;

      const validateClaimDataResult = validateClaimData(
        claimData!,
        additionalValidations,
        policyEffectDate,
        policyExpirationDate
      );

      const validateResponsabilityResult = validateClaimResponsabilityData(
        responsability!,
        validateClaimDataResult
      );

      if (validateClaimDataResult)
        store.dispatch(updateClaimValidations(validateResponsabilityResult));
    }
    // ADD  DAMAGED PART
    else if (addDamagedPart.match(action)) {
      const { additionalValidations } = store.getState()
        .activeClaim as IActiveClaimState;
      const updatedValidations = {
        ...additionalValidations,
        isDamagedPartsCompleted: false,
      };
      store.dispatch(updateClaimValidations(updatedValidations));
    }
    // REMOVE DAMAGED PART
    else if (removeDamagedPart.match(action)) {
      const { additionalValidations, damagedParts } = store.getState()
        .activeClaim as IActiveClaimState;
      const updatedDamagedParts = damagedParts.filter(
        (part, index) => index !== action.payload
      );

      const updatedValidations = validateDamagedParts(
        updatedDamagedParts,
        additionalValidations
      );
      store.dispatch(updateClaimValidations(updatedValidations));
    }
    // UPDATE DAMAGED PART
    else if (setDamagedPart.match(action)) {
      const { additionalValidations, damagedParts } = store.getState()
        .activeClaim as IActiveClaimState;
      const updatedDamagedParts = damagedParts.map((part, index) =>
        index === action.payload.index ? action.payload.damagedPart : part
      );

      const updatedValidations = validateDamagedParts(
        updatedDamagedParts,
        additionalValidations
      );
      store.dispatch(updateClaimValidations(updatedValidations));
    }

    return next(action);
  };
