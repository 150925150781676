import { useTranslation } from "react-i18next";
import styled from "styled-components";
import dekra from "../../../style/dekra";
import { mainTabs, widgets } from "../../../config/testIds";
import { ExpertizerStatusType } from "../../../config/const";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { LuCalendarClock } from "react-icons/lu";
import { IoTodayOutline } from "react-icons/io5";
import { GiFinishLine } from "react-icons/gi";
import { IconArrowDown, IconRemoveFilter } from "../../../config/icons";
import { FilterExpertizerInProgress } from "./ExpertizerInProgress";
import {
  FilterStatusValues,
  StatusTypes,
} from "../../MainTabs/Dashboards/Dashboard-E2";

const testIds = mainTabs.E2.statuses;

const RowWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
`;

const StatusesWrapper = styled(WidgetWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  .status {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0.25em 1em;

    border-right: 2px solid #bbb;
    color: #333;
    text-transform: uppercase;
    font-size: 0.9em;

    &:hover {
      color: ${dekra.primaryColor};
      cursor: pointer;
    }

    &.active {
      background-color: ${dekra.primaryColor};
      height: 100%;
      svg {
        color: ${dekra.secondaryColors.white};
      }

      .text {
        color: ${dekra.secondaryColors.white};
      }

      .value {
        color: ${dekra.secondaryColors.white};
      }

      &:hover {
        svg {
          color: ${dekra.secondaryColors.white};
        }
        .text {
          color: ${dekra.secondaryColors.white};
        }
        .value {
          color: ${dekra.secondaryColors.white};
        }
      }
    }

    &:last-child {
      border-right: none;
    }

    svg {
      font-size: 1.6em;
      color: #aaa;
    }

    .clickable {
      display: flex;
      align-items: center;

      letter-spacing: 1px;
      line-height: 1em;

      .text {
        margin-left: 0.5em;
        align-self: flex-end;
      }

      .value {
        margin-left: 0.5em;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .reset-filter {
    padding: 0 0.5em;
    font-size: 1.2em;
    color: #555;

    .icon-wrapper {
      cursor: pointer;
      margin-top: 5px;
    }
  }
`;

export interface IExpertizerStatusesProps {
  values: FilterStatusValues;

  onSelect: (status: StatusTypes | undefined) => void;
  selected?: ExpertizerStatusType;
}

const ExpertizerStatuses = (props: IExpertizerStatusesProps) => {
  const { values, onSelect, selected } = props;
  const {
    rejected,
    confirmed,
    withoutAppointment,
    withAppointment,
    withAppointmentToday,
    concluded,
    waitingForAgreement
  } = values;

  const { t } = useTranslation();

  const handleResetFilter = () => onSelect(undefined);

  const statusValues = [
    rejected,
    confirmed,
    withoutAppointment,
    withAppointment,
    withAppointmentToday,
    concluded,
    waitingForAgreement
  ];

  return (
    <RowWrapper>
      <StatusesWrapper data-testid={widgets.expertisesStatuses.container}>
        {["RE", "PA", "AD", "AF", "AFO", "CP", "AC"].map((status, index) => (
          <div
            key={index}
            className={`status ${selected === status ? "active" : ""}`}
            data-testid={testIds.confirmed.container}
            onClick={() => onSelect(status as StatusTypes)}
          >
            <AiOutlineCheckCircle />
            <div className="clickable">
              <div className="text">{t(`appraisal-statuses-s-${status}`)}</div>
              <div className="value">{statusValues[index]}</div>
            </div>
          </div>
        ))}

        <div className="reset-filter">
          <div className="icon-wrapper" onClick={handleResetFilter}>
            <IconRemoveFilter />
          </div>
        </div>
      </StatusesWrapper>
    </RowWrapper>
  );
};

export default ExpertizerStatuses;
