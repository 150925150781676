import ExpertizeScoreProvider from "./ExpertizeScoreContext";
import ExpertizeScoreTabs from "./ExpertizeScoreTabs";

interface IExpertizerScoreProps {
    tabKey?: number;
}

const ExpertizerScore = ({ tabKey }: IExpertizerScoreProps) => {
    return (
        <ExpertizeScoreProvider>
            <ExpertizeScoreTabs></ExpertizeScoreTabs>
        </ExpertizeScoreProvider>
    );
};

export default ExpertizerScore;
