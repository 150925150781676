import { useEffect, useState } from "react";
import SettingsModal from "./Settings/SettingsModal";
import { AssignmentMapFilterType } from "./AssignmentMapFilter/AssignmentMapFilter";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import PrincipalView from "./Views/PrincipalView";
import AppraiserView from "./Views/AppraiserView";
import PracticeView from "./Views/PracticeView";

const initialFilter: AssignmentMapFilterType = {
    practiceStatus: "",
    isUrgent: true,
    appraiserType: "",
    saturationCluster: "",
};

interface IAssignmentMapProps {
    tabKey?: number;
}

const AssignmentMap = ({ tabKey }: IAssignmentMapProps) => {
    const { activeKey } = useSelector((state: RootState) => state.mainTabs);

    const [filter, setFilter] =
        useState<AssignmentMapFilterType>(initialFilter);

    const [selectedPracticeId, setSelectedPracticeId] = useState<
        number | undefined
    >(undefined);

    const [selectedAppraiserId, setSelectedAppraiserId] = useState<
        number | undefined
    >(undefined);

    const [selectedIdZone, setSelectedIdZone] = useState<number | undefined>(
        undefined
    );

    const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);

    // useEffect(() => {
    //     if (activeKey === tabKey) {
    //         if (selectedIdZone !== undefined) {
    //         }

    //         const newFilter = { ...filter };
    //         setFilter(newFilter);
    //         console.info("si funziona");
    //     }
    // }, [activeKey]);

    const handleOnChange = async (value: any, field: string) => {
        const newFilter = {
            ...filter,
            [field]: value,
        };
        setFilter(newFilter);
    };

    const handleResetFilter = async () => {
        setFilter(initialFilter);
    };

    const handleSelectPractice = (practiceId: number) => {
        setSelectedPracticeId(practiceId);
    };

    const handleSelectAppraiser = (appraiserId: number, idZone: number) => {
        setSelectedAppraiserId(appraiserId);
        
        setSelectedIdZone(idZone);
    };

    return (
        <div>
            {!selectedPracticeId && !selectedAppraiserId && (
                <PrincipalView
                    filter={filter}
                    onChangeFilter={handleOnChange}
                    onResetFilter={handleResetFilter}
                    onSelectAppraiser={handleSelectAppraiser}
                    onSelectPractice={handleSelectPractice}
                />
            )}

            {selectedPracticeId && (
                <PracticeView
                    practiceId={selectedPracticeId}
                    onClearSelection={() => setSelectedPracticeId(undefined)}
                />
            )}

            {selectedAppraiserId && (
                <AppraiserView
                    appraiserId={selectedAppraiserId}
                    idZone={selectedIdZone!}
                    onClearSelection={() => setSelectedAppraiserId(undefined)}
                />
            )}

            {showSettingsModal && (
                <SettingsModal onCancel={() => setShowSettingsModal(false)} />
            )}
        </div>
    );
};

export default AssignmentMap;
