import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { AppraiserScoreReport } from "../../../../../redux/apiSpecifications/apiFesf";
import { useTranslation } from "react-i18next";
import { CellWrapper } from "../../../../../style/CellWrapper";
import styled from "styled-components";
import { WidgetWrapper } from "../../../../../style/containers";
import ReportActions from "./ReportActions";
import { dateTimeToNumber, dateToNumber } from "../../../../../utils/date";

export const DownloadReportListWrapper = styled(WidgetWrapper)`
    align-items: flex-start;
    max-width: 100%;
    overflow: auto;
    padding: 2em;

    .MuiPaper-root {
        box-shadow: none;
        width: 100%;
    }

    .MuiToolbar-root > .MuiBox-root {
        justify-content: flex-start;
    }

    .MuiTableCell-root {
        padding: 0;
    }

    .confirm-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
    }

    .MuiDialog-container {
        .MuiPaper-root {
            padding: 0 2em !important;
        }
    }

    .MuiTableRow-head {
        .MuiInputBase-root {
            input {
                margin-left: 5px;
            }
        }
    }

    .MuiTableCell-root {
        padding: 0.5em 1em;
    }
`;

export type ReportDataItem = {
    reportId?: number;
    reportTypeCode?: string;
    reportTypeDescription: string;
    startDate: string;
    startDateNumber?: string;
    endDate: string;
    endDateNumber?: string;
    creationDate: string;
    creationDateNumber?: string;
    actions: string;
    generationStatus: string;
};

interface IReportListProps {
    reports?: AppraiserScoreReport[];
}

const ReportList = ({ reports }: IReportListProps) => {
    const { t } = useTranslation();

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const data = useMemo(
        () =>
            reports?.map(
                (report) =>
                    ({
                        ...report,
                        reportTypeDescription: t(`expert-score-download-report-list-type-description-${report.reportTypeCode}`),
                        startDateNumber: dateToNumber(report.startDate),
                        endDateNumber: dateToNumber(report.endDate),
                        creationDateNumber: dateTimeToNumber(
                            report.creationDate
                        ),
                    } as ReportDataItem)
            ) || [],
        [reports]
    );

    const resultsColumns = useMemo<MRT_ColumnDef<ReportDataItem>[]>(
        () => [
            {
                accessorKey: "reportTypeCode",
                header: t("expert-score-download-report-list-col-type"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper>{row.original.reportTypeDescription}</CellWrapper>
                ),
            },
            {
                accessorKey: "startDate",
                header: t("expert-score-download-report-list-col-start-date"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper>{row.original.startDate}</CellWrapper>
                ),
            },
            {
                accessorKey: "endDate",
                header: t("expert-score-download-report-list-col-end-date"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper>{row.original.endDate}</CellWrapper>
                ),
            },
            {
                accessorKey: "creationDate",
                header: t(
                    "expert-score-download-report-list-col-creation-date"
                ),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper>{row.original.creationDate}</CellWrapper>
                ),
            },
            {
                accessorKey: "actions",
                header: "",
                size: 100,
                enableSorting: false,
                enableColumnDragging: false,
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <ReportActions
                        reportId={row.original.reportId!}
                        generationStatus={row.original.generationStatus}
                    />
                ),
            },
        ],
        [reports]
    );

    return (
        <DownloadReportListWrapper>
            <MaterialReactTable
                columns={resultsColumns}
                data={data}
                enableDensityToggle={false}
                enableColumnOrdering
                muiTableBodyCellProps={{
                    sx: {
                        textAlign: "left",
                    },
                }}
                onPaginationChange={setPagination}
                state={{ pagination }}
                autoResetPageIndex={false}
                enableColumnDragging={false}
                enableColumnActions={false}
            />

            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        </DownloadReportListWrapper>
    );
};

export default ReportList;
