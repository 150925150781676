import styled from "styled-components";
import { mainTabs as testIds } from "../../../../config/testIds";
import {
    DashboardWrapper,
    LoadingInTabWrapper,
    RefreshInTabWrapper,
} from "../../../../style/DashbordWidgetWrappers";
import { useState } from "react";
import { IconLoading } from "../../../../config/icons";
import SupplierNetworkAppraisersList from "../../../Widgets/SupplierNetwork/SupplierNetworkAppraisersList";
import SupplierNetworkCalendar from "../../../Widgets/SupplierNetwork/SupplierNetworkCalendar";
import SupplierNetworkMap from "../../../Widgets/SupplierNetwork/SupplierNetworkMap";
import { AppraisersListItem } from "../../../../redux/apiSpecifications/apiFesf";
import { CommunicationsBySenderTypeAndCategories } from "../../../../redux/apiSpecifications/apiCrud";
import moment from "moment";
import { DateFormat } from "../../../../config/const";
import SupplierNetworkAppraiserStatuslDialog from "../../../Widgets/SupplierNetwork/SupplierNetworkAppraiserStatuslDialog";
import SupplierNetworkFilter, {
    SupplierNetworkFilterType,
} from "../../../Widgets/SupplierNetwork/SupplierNetworkFilter";

const DashboardNFWrapper = styled(DashboardWrapper)`
    display: flex;
    flex-direction: row;
    gap: 1em;

    .MuiTableCell-root {
        padding: 0;
    }

    .appraisers-list-wrapper {
        flex: 3;
    }

    .col-right {
        display: flex;
        flex-direction: column;
        gap: 1em;
        flex: 2;

        .row-top {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            gap: 1em;
        }

        .map-wrapper {
            flex: 1;
        }
    }
`;

interface IDashboardNFProps {
    // config
    loadingStatus?: "loading" | "refreshing" | "idle";
    disableMaps?: boolean;
    showAppraiserStatusModal?: boolean;

    // data
    appraisersList?: AppraisersListItem[];
    communicationSummary?: CommunicationsBySenderTypeAndCategories[];
    selectedAppraiser?: AppraisersListItem;

    appraiserToShowOnMap?: number;

    // actions
    onEditAppraiser: (appraiserId: number, nominative: string) => void;
    onEditAppraiserStatus: (appraiserId: number) => void;
    onLoadDataWithDate: (
        searchDate: string | undefined,
        searchDistrictCode: string | undefined
    ) => void;
    onShowAppraiserStatusModal: (show: boolean) => void;
    onShowAppraiserOnMap: (appraiserId: number) => void;
}

const DashBoard_NF_Presentational = (props: IDashboardNFProps) => {
    const {
        loadingStatus,
        disableMaps,
        appraisersList,
        showAppraiserStatusModal,
        selectedAppraiser,
        appraiserToShowOnMap,
        onEditAppraiser,
        onEditAppraiserStatus,
        onLoadDataWithDate,
        onShowAppraiserStatusModal,
        onShowAppraiserOnMap,
    } = props;

    const [filter, setFilter] = useState<SupplierNetworkFilterType>({
        date: moment().format(DateFormat),
        districtCode: "",
    });
    
    // const [appraiserToShowOnMap, setAppraiserToShowOnMap] = useState<
    //     number | undefined
    // >(undefined);

    // const handleOnSaved = () => {
    //     onShowAppraiserStatusModal(false);
    // };

    const handleOnChange = (value: any, field: string) => {
        const newFilter = {
            ...filter,
            [field]: value,
        };

        setFilter(newFilter);

        // setAppraiserToShowOnMap(undefined);

        onLoadDataWithDate(newFilter.date, newFilter.districtCode);
    };

    return (
        <div data-testid={testIds.dashboards.NF}>
            {loadingStatus === "loading" && (
                <LoadingInTabWrapper>
                    <div className="box" data-testid={testIds.NF.loader}>
                        {IconLoading}
                    </div>
                </LoadingInTabWrapper>
            )}

            {(loadingStatus === "idle" || loadingStatus === "refreshing") && (
                <>
                    <DashboardNFWrapper data-testid={testIds.NF.wrapper}>
                        <div className="appraisers-list-wrapper">
                            <SupplierNetworkAppraisersList
                                items={appraisersList}
                                // districtCode={filter.districtCode}
                                filter={filter}
                                onSelectAppraiser={onEditAppraiser}
                                onEditAppraiserStatus={onEditAppraiserStatus}
                                onShowAppraiserOnMap={onShowAppraiserOnMap}
                                // {(appraiserId) =>
                                //     setAppraiserToShowOnMap(appraiserId)
                                // }
                            />
                        </div>

                        <div className="col-right">
                            <div className="row-top">
                                <SupplierNetworkFilter
                                    filter={filter}
                                    onChange={handleOnChange}
                                    onConfirm={() =>
                                        onLoadDataWithDate(
                                            filter.date,
                                            filter.districtCode
                                        )
                                    }
                                />
                            </div>

                            <div className="map-wrapper">
                                {!disableMaps &&
                                    appraiserToShowOnMap !== undefined && (
                                        <SupplierNetworkMap
                                            appraiserId={appraiserToShowOnMap}
                                            appraiserName={appraisersList?.find(_ =>_.id === appraiserToShowOnMap)?.appraiserName}
                                            date={
                                                filter.date ??
                                                moment().format(DateFormat)
                                            }
                                        />
                                    )}

                                {/* <SupplierNetworkMap appraiserId={appraiserToShowOnMap} /> */}
                            </div>
                        </div>
                    </DashboardNFWrapper>

                    {loadingStatus === "refreshing" && (
                        <RefreshInTabWrapper>
                            <div
                                className="box"
                                data-testid={testIds.D1.loader}
                            >
                                {IconLoading}
                            </div>
                        </RefreshInTabWrapper>
                    )}
                </>
            )}

            {/* {showAppraiserStatusModal && (
                <SupplierNetworkAppraiserStatuslDialog
                    appraiser={selectedAppraiser}
                    date={filter.date}
                    onCancel={handleOnSaved}
                    onSaved={handleOnSaved}
                />
            )} */}
        </div>
    );
};

export default DashBoard_NF_Presentational;
