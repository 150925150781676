import { useTranslation } from "react-i18next";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import { useMemo } from "react";

type EmailDeliveredDescriptionDetails = {
    email_result: string;
    email_type: string;
    receiver_role: string;
    receiver_email: string;
};

interface IEmailDeliveredDescriptionProps {
    modifyDetailsJson: Modifydetail[];
}

const EmailDeliveredDescription = ({
    modifyDetailsJson,
}: IEmailDeliveredDescriptionProps) => {
    const { t } = useTranslation();

    const emailSentDescriptionDetails: EmailDeliveredDescriptionDetails =
        useMemo(() => {
            const email_type = modifyDetailsJson.find(
                (_) => _.key === "email_type"
            );

            const receiver_role = modifyDetailsJson.find(
                (_) => _.key === "receiver_role"
            );

            return {
                email_result:
                    modifyDetailsJson.find((_) => _.key === "email_result")
                        ?.value ?? "",
                email_type: email_type
                    ? t(`communication_type-${email_type.value}`)
                    : "",
                receiver_role: receiver_role
                    ? t(`communication_receiver_role-${receiver_role.value}`)
                    : "",
                receiver_email:
                    modifyDetailsJson.find((_) => _.key === "receiver_email")
                        ?.value ?? "",
            } as EmailDeliveredDescriptionDetails;
        }, [modifyDetailsJson]);

    return (
        <>
            {t(`historical-actions-email-delivered-${emailSentDescriptionDetails.email_result}-description`, {
                email_type: emailSentDescriptionDetails.email_type,
                receiver_role: emailSentDescriptionDetails.receiver_role,
                receiver_email: emailSentDescriptionDetails.receiver_email,
            })}
        </>
    );
};

export default EmailDeliveredDescription;
