import { useTranslation } from "react-i18next";
import { FormRow } from "../../../../../../../style/form";
import SelectUserTenants from "../../../../../../Inputs/SelectUserTenants";
import { RowSpacer } from "../../../../../../../style/containers";
import {
  DatePickerStyled,
  InputTextStyled,
  SwitchStyled,
} from "../../../../../../../style/Input";
import { BasePolicy } from "../../../../../../../redux/apiSpecifications/apiPolicies";
import moment from "moment";

interface IPolicyDataFormProps {
  model: BasePolicy;
  disableForm?: boolean;
  onChange?: (value: any, field: string) => void;
}

const PolicyDataForm = ({
  model,
  disableForm,
  onChange,
}: IPolicyDataFormProps) => {
  const { t } = useTranslation();

  const effectDate = model?.effectDate
    ? moment(model.effectDate, "DD/MM/YYYY")
    : null;

  const expirationDate = model?.expirationDate
    ? moment(model.expirationDate, "DD/MM/YYYY")
    : null;

  const handleOnChange = (value: any, field: string) => {
    if (onChange) onChange(value, field);
  };

  return (
    <FormRow>
      <SelectUserTenants
        label={t("LABELS.company")}
        required={true}
        value={model.tenantId}
        onChange={(value) => handleOnChange(value, "tenantId")}
        disabled={true}
      />

      <RowSpacer />

      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        <InputTextStyled
          label={t("LABELS.number-company")}
          placeholder={t("PLACEHOLDERS.number-company")}
          onChange={(value) => handleOnChange(value, "numberCompany")}
          required={true}
          value={model.numberCompany}
          disabled={disableForm}
        />

        <RowSpacer />

        <SwitchStyled
          label={t("LABELS.administrative-compliance")}
          onChange={(value) =>
            handleOnChange(value, "administrativeCompliance")
          }
          unCheckedChildren={t("switch-no")}
          checkedChildren={t("switch-yes")}
          checked={model.administrativeCompliance}
          disabled={disableForm}
        />
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        <DatePickerStyled
          label={t("LABELS.effect-date")}
          placeholder={"gg/mm/aaaa"}
          required={true}
          value={model?.effectDate}
          onChange={(value) => handleOnChange(value, "effectDate")}
          disabledDate={(current) => {
            if (expirationDate) {
              return current.isAfter(expirationDate, "day");
            }
            return false;
          }}
          disabled={disableForm}
        />

        <RowSpacer />

        <DatePickerStyled
          label={t("LABELS.expiration-date")}
          placeholder={"gg/mm/aaaa"}
          required={true}
          value={model?.expirationDate}
          onChange={(value) => handleOnChange(value, "expirationDate")}
          disabledDate={(current) => {
            if (effectDate) {
              return current.isBefore(effectDate, "day");
            }
            return false;
          }}
          disabled={disableForm}
        />
      </div>
    </FormRow>
  );
};

export default PolicyDataForm;
