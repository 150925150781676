import styled from "styled-components";

import { useEffect, useState } from "react";
import {
  Appraiser,
  PracticeActivityAppraiser,
  useLazyGetAppraisersListQuery,
} from "../../../../../redux/apiSpecifications/apiCrud";
import { RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Button, Popconfirm, Select } from "antd";
import { useTranslation } from "react-i18next";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import AppraiserDetails from "./AppraiserDetails";

import { mainTabs } from "../../../../../config/testIds";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import { addTab } from "../../../../../redux/features/mainTabsSlice";
import { addAppraiser } from "../../../../../redux/features/appraisersSlice";

const testIds = mainTabs.tabs.activities.appraiserSelection;
const testIdsClickables =
  mainTabs.tabs.appraisalDetails.collapsables.details.location.clickableSubject;

const AppraiserSelectionWrapper = styled.div`
  flex: 1;

  .select-appraiser-label {
    text-transform: uppercase;
  }

  .button-assign-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;

    .selected-appraiser {
      display: flex;
      align-items: center;
      flex: 1;
      padding-left: 1em;

      .label {
        text-transform: uppercase;
        margin-right: 1em;
      }
    }

    .button-assign-appraiser-content {
      display: flex;

      .button-assign-appraiser-nominative {
        margin-left: 1em;
        letter-spacing: 1px;
      }
    }
  }

  .error-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message {
    font-size: 1.2em;
  }
`;

const SuggestedAppraisersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 0 1em 1em;

  .appraiser-list-header {
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    margin-bottom: 0.5em;

    .nominative {
      flex: 2;
    }

    .workload,
    .availability,
    .open-button,
    .status {
      flex: 1;
      text-align: center;
    }
  }
`;

const SelectStyled = styled(Select)`
  width: 100%;
  margin-bottom: 2em;
`;

const AppraierListItemWrapper = styled.div<{
  bgColor?: string;
  selected?: boolean;
}>`
  display: flex;
  margin-bottom: 0.25em;
  cursor: pointer;

  ${(props) => props.bgColor && "background-color: " + props.bgColor + ";"}

  &:hover {
    background-color: #f5f5f5;
    color: #333;
  }

  ${(props) => props.selected && "font-weight: bold; background-color: #eee;"}

  .nomivative {
    flex: 2;

    padding-left: 1em;
  }

  .status{
    width:150px;
    text-align: left;
  }

  .workload {
    /* flex: 1;
    text-align: center; */
    width: 50px;
  }

  .availability {
    flex: 1;
    text-align: center;
  }

  .open-button {
    /* flex: 1; */
    width: 122px;
    padding-left: 1em;
    background-color: white;

    span {
      font-size: 0.9em;
    }
  }
`;

const calBgColor = (
  rank: number | undefined,
  index: number,
  numberOfSuggested: number
): string => {
  const startColor = "#90EE90";
  const endColor = "#FFE5B4";

  const percentage = index / (numberOfSuggested - 1);
  const red = Math.round(
    parseInt(startColor.slice(1, 3), 16) * (1 - percentage) +
      parseInt(endColor.slice(1, 3), 16) * percentage
  );
  const green = Math.round(
    parseInt(startColor.slice(3, 5), 16) * (1 - percentage) +
      parseInt(endColor.slice(3, 5), 16) * percentage
  );
  const blue = Math.round(
    parseInt(startColor.slice(5, 7), 16) * (1 - percentage) +
      parseInt(endColor.slice(5, 7), 16) * percentage
  );

  return `rgb(${red}, ${green}, ${blue})`;
};

export type ErrorType = {
  message: string;
  timestamp: number;
};

type SelectedAppraiserType = {
  appraiser: Appraiser;
  nominative: string;
};

type AppraiserListItemType = {
  appraiser: PracticeActivityAppraiser;
  nominative: string;
};

const getNominative = (appraiser: PracticeActivityAppraiser | undefined) => {
  if (!appraiser) return "";

  const nominative =
    appraiser.subjectType === "PF"
      ? `${appraiser.firstName} ${appraiser.lastName}`
      : appraiser.subjectType === "PG"
      ? appraiser.businessName || ""
      : "";

  const intExt = appraiser.isInternal ? " (I)" : " (E)";

  return nominative + " " + intExt;
};

const getWorkload = (appraiser: PracticeActivityAppraiser | undefined) => {
  if (!appraiser) return "";

  return `${appraiser.practiceCountDaily || 0}/${
    appraiser.maxPracticeCountDaily || 0
  }`;
};

interface IAppraisalsDetailsProps {
  appraiser: Appraiser | undefined;
  practiceStatus: string;
  practiceId: number | undefined;
  onAssignAppraiser: (appraiser: PracticeActivityAppraiser) => void;
  onChange: () => void;
  onSuspend: () => void;
  error: ErrorType | undefined;
}

const AppraiserSelection = (props: IAppraisalsDetailsProps) => {
  const {
    appraiser,
    practiceStatus,
    practiceId,
    onAssignAppraiser,
    onChange,
    onSuspend,
  } = props;
  const { t } = useTranslation();

  const { getAuthorization } = useAuthorization();
  const activeRole = useSelector((state: RootState) => state.user.activeRole);

  const [hasAppraiser, setHasAppraiser] = useState((appraiser?.id || 0) > 0);
  const [appraisersList, setAppraisersList] = useState<AppraiserListItemType[]>(
    []
  );
  const [suggestedAppraisers, setSuggestedAppraisers] = useState<
    AppraiserListItemType[]
  >([]);

  const [selectedAppraiser, setSelectedAppraiser] = useState<
    SelectedAppraiserType | undefined
  >();

  const [error, setError] = useState(props.error);

  const [getApprailsalsList] = useLazyGetAppraisersListQuery();

  useEffect(() => {
    if (!!hasAppraiser) return;
    (async () => {
      const response = await getApprailsalsList({
        authorization: await getAuthorization(),
        practiceId,
        activeRole: activeRole!,
      });
      if (response.isSuccess) {
        const appraisersToSort = (
          response.data as PracticeActivityAppraiser[]
        ).map((appraiser) => ({
          appraiser,
          nominative:
            appraiser.subjectType === "PF"
              ? `${appraiser.firstName} ${appraiser.lastName}`
              : appraiser.subjectType === "PG"
              ? appraiser.businessName || ""
              : "",
        }));

        const nominativeSortedAppraisers = appraisersToSort
          .slice()
          .sort((a, b) => (a.nominative > b.nominative ? 1 : -1));

        const rankSortedAppraisers = appraisersToSort
          .slice()
          .sort((a, b) =>
            (a.appraiser.rank || 0) > (b.appraiser.rank || 0) ? 1 : -1
          );

        setAppraisersList(nominativeSortedAppraisers);
        setSuggestedAppraisers(rankSortedAppraisers.slice(0, 10));
      } else {
        // to do
      }
    })();
  }, [hasAppraiser]);

  useEffect(() => {
    setHasAppraiser((appraiser?.id || 0) > 0);
  }, [appraiser]);

  useEffect(() => {
    setError(props.error);
  }, [props.error?.timestamp]);

  const onChangeSelectedAppraiser = (value: any) => {
    const id = parseInt(value);
    const _appraiser = appraisersList.find((a) => a.appraiser.id === id);
    if (!!_appraiser) {
      setSelectedAppraiser(_appraiser);
      setError(undefined);
    }
  };

  const handleFilterSearchAppraiser = (input: string, option: any) => {
    const text = input.toLowerCase();
    const id = parseInt(option.key);
    const _appraiser = appraisersList.find((a) => a.appraiser.id === id);

    return _appraiser?.nominative?.toLowerCase().includes(text) || false;
  };

  const handleSelectAppraiser = () => {
    onAssignAppraiser(selectedAppraiser?.appraiser || ({} as any));
  };

  const renderAppraiserSelection = () => {
    return (
      <div>
        <div
          className="select-appraiser-label"
          data-testid={testIds.selectAppraiserTitle}
        >
          {t(
            "appraisals-details-tab-activities-select-appraiser-default-message"
          )}
        </div>
        <SelectStyled
          placeholder={t(
            "appraisals-details-tab-activities-select-appraiser-default-message"
          )}
          showSearch
          filterOption={handleFilterSearchAppraiser}
          value={
            selectedAppraiser !== undefined
              ? `${getNominative(selectedAppraiser?.appraiser)} ${t(
                  `appraisal-details-tab-activities-appraiser-status-${selectedAppraiser?.appraiser.appraiserStatus}`
                )} ${getWorkload(selectedAppraiser?.appraiser)}`
              : ""
          }
          onChange={onChangeSelectedAppraiser}
        >
          {appraisersList.map((item, i) => (
            <Select.Option key={item.appraiser.id}>
              <AppraiserListItem appraiser={item.appraiser} />
            </Select.Option>
          ))}
        </SelectStyled>

        {/* <pre>{JSON.stringify(selectedAppraiser, null, 2)}</pre>  */}

        <SuggestedAppraisersWrapper>
          <div className="appraiser-list-header">
            <div className="nominative">
              {t(
                "appraisals-details-tab-activities-select-appraiser-preferred-nominative"
              )}
            </div>
            <div className="status">
              {t("appraisals-details-tab-activities-select-appraiser-status")}
            </div>
            <div className="workload">
              {t(
                "appraisals-details-tab-activities-select-appraiser-preferred-workload"
              )}
            </div>
            {/* <div className="availability"></div> */}
            <div className="open-button"></div>
          </div>
          {suggestedAppraisers
            .filter((item) => item.appraiser.rank! < 9999)
            .map((item, i) => (
              <AppraiserListItem
                bgColor={calBgColor(
                  item.appraiser.rank,
                  i,
                  suggestedAppraisers.length
                )}
                key={item.appraiser.id}
                appraiser={item.appraiser}
                onChange={onChangeSelectedAppraiser}
                selected={selectedAppraiser?.appraiser.id === item.appraiser.id}
                dataTestId={testIds.suggestedAppraisers.container}
              />
            ))}
        </SuggestedAppraisersWrapper>

        {!hasAppraiser && !!selectedAppraiser && error === undefined && (
          <div className="button-assign-wrapper">
            <div className="selected-appraiser">
              <div className="label">
                {t(
                  "appraisals-details-tab-activities-select-appraiser-selected-nominative"
                )}
              </div>
              <div data-testid={testIdsClickables.text}>
                {selectedAppraiser.nominative}
              </div>
            </div>
            <Popconfirm
              placement="leftBottom"
              icon={null}
              title={t(
                "appraisals-details-tab-activities-select-appraiser-confirm-assign"
              )}
              onConfirm={handleSelectAppraiser}
              okText={
                <div data-testid={testIds.buttonAssignConfirm}>
                  {t("switch-yes")}
                </div>
              }
              cancelText={t("switch-no")}
            >
              <ButtonConfirm
                onClick={() => {}}
                dataTestId={testIds.buttonAssign}
              >
                <div className="button-assign-appraiser-content">
                  {t(
                    "appraisals-details-tab-activities-select-appraiser-button-assing"
                  )}
                </div>
              </ButtonConfirm>
            </Popconfirm>
          </div>
        )}

        {error && (
          <div className="error-wrapper">
            <div className="message">{error.message}</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <AppraiserSelectionWrapper data-testid={testIds.container}>
      {!hasAppraiser && renderAppraiserSelection()}
      {hasAppraiser && (
        <AppraiserDetails
          appraiser={appraiser!}
          practiceStatus={practiceStatus}
          onChange={onChange}
          onSuspend={onSuspend}
        />
      )}
    </AppraiserSelectionWrapper>
  );
};

interface IAppraiserListItemProps {
  appraiser: PracticeActivityAppraiser;
  bgColor?: string;
  selected?: boolean;
  onChange?: (id: number) => void;
  dataTestId?: string;
}

const AppraiserListItem = (props: IAppraiserListItemProps) => {
  const { appraiser, onChange, bgColor = "transparent", selected } = props;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleOpenAppraiserAgenda = () => {
    //
    dispatch(
      addAppraiser({
        id: appraiser.id!,
        defaultSettings: {},
      })
    );

    dispatch(
      addTab({
        key: 0,
        label: appraiser.firstName || "",
        type: "agenda-appraiser",
        externalData: { idAppraiser: appraiser.id, readOnly: true },
      })
    );
  };

  return (
    <AppraierListItemWrapper
      key={appraiser.id}
      onClick={() => onChange && onChange(appraiser.id || 0)}
      bgColor={bgColor}
      selected={selected}
      data-testid={props.dataTestId}
    >
      <div className="nomivative">{getNominative(appraiser)}</div>
      <div className="status">
        {t(
          `appraisal-details-tab-activities-appraiser-status-${appraiser.appraiserStatus}`
        )}
      </div>
      <div className="workload">{`${appraiser.practiceCountDaily || 0}/${
        appraiser.maxPracticeCountDaily || 0
      }`}</div>
      {/* <div className="availability"></div> */}
      <div className="open-button">
        <ButtonConfirm onClick={handleOpenAppraiserAgenda} size="small">
          {t("button-open-agenda-appraiser")!}
        </ButtonConfirm>
      </div>
    </AppraierListItemWrapper>
  );
};

export default AppraiserSelection;
