import { useEffect, useState } from "react";
import { IconLoading } from "../../../../config/icons";
import {
  LoadingInTabWrapper,
  RefreshInTabWrapper,
} from "../../../../style/DashbordWidgetWrappers";
import { AssignmentMapWrapper } from "../Maps/models";
import PracticeMap from "../Maps/PracticeMap";
import PracticeAppraisersList from "../Lists/PracticeAppraisersList/PracticeAppraisersList";
import {
  AppraisersByPracticeMapData,
  PracticesByAppraiserMapData,
  ReadOnlyPractice,
  useAssignAppraiserMutation,
  useLazyGetAppraisersFromAssignmentMapByPracticeQuery,
} from "../../../../redux/apiSpecifications/apiCrud";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { SerializedError } from "@reduxjs/toolkit";
import { useNotifications } from "../../../../hooks/useNotifications";
import PracticeDetails from "../Details/PracticeDetails/PracticeDetails";
import { useTranslation } from "react-i18next";

interface IPracticeViewProps {
  practiceId: number;
  onClearSelection: () => void;
}

const PracticeView = ({ practiceId, onClearSelection }: IPracticeViewProps) => {
  const { t } = useTranslation();

  const { authorization, activeRole } = useSelector(
    (state: RootState) => state.user
  );

  const [loadingStatus, setLoadingStatus] = useState<
    "idle" | "loading" | "refreshing" | "load-in-progress"
  >("idle");

  const [appraisersByPracticeMapData, setAppraisersByPracticeMapData] =
    useState<AppraisersByPracticeMapData | null>();

  const [practicesByAppraiserMapData, setPracticesByAppraiserMapData] =
    useState<PracticesByAppraiserMapData | null>();

  const { displaySuccess, displayFetchBaseQueryErrorNotification } =
    useNotifications();

  const [getAppraisersFromAssignmentMapByPractice] =
    useLazyGetAppraisersFromAssignmentMapByPracticeQuery();

  const [assignAppraiser] = useAssignAppraiserMutation();

    useEffect(() => {
        loadData(practiceId);
    }, []);

  const loadData = async (practiceId: number) => {
    setLoadingStatus("refreshing");

    const response = await getAppraisersFromAssignmentMapByPractice({
      id: practiceId,
      authorization: authorization,
    });
    const data = response.data as AppraisersByPracticeMapData;

    if (data) {
      setAppraisersByPracticeMapData(data);
    } else {
      const errorResponse = response as {
        error: FetchBaseQueryError | SerializedError;
      };

      displayFetchBaseQueryErrorNotification(errorResponse.error);
    }

    setLoadingStatus("idle");
  };

  const handleAssignAppraiser = async (
    idAppraiser: number,
    idPractice: number
  ) => {
    setLoadingStatus("refreshing");

    const response = await assignAppraiser({
      authorization: authorization,
      activeRole: activeRole!,
      assignmentData: {
        idAppraiser: idAppraiser,
        idPractice: idPractice,
      },
    });

    const updatedPractice = (
      response as {
        data: ReadOnlyPractice;
      }
    ).data as ReadOnlyPractice;

    if (updatedPractice) {
      const response = await getAppraisersFromAssignmentMapByPractice({
        authorization: authorization,
        id: idPractice,
      });

      const data = response.data as AppraisersByPracticeMapData;

      if (data) {
        setAppraisersByPracticeMapData({
          appraisers: data.appraisers,
          practice: {
            ...practicesByAppraiserMapData?.practices,
            assignedAppraiserId: updatedPractice.appraiser?.id,
          },
        } as AppraisersByPracticeMapData);

        await loadData(idPractice);

        displaySuccess(
          t("assignment-map-appraiser-assigned-successfully-message")
        );
      } else {
        const errorResponse = response as {
          error: FetchBaseQueryError | SerializedError;
        };

        displayFetchBaseQueryErrorNotification(errorResponse.error);
      }
    } else {
      const errorResponse = response as {
        error: FetchBaseQueryError | SerializedError;
      };

      displayFetchBaseQueryErrorNotification(errorResponse.error);
    }

    setLoadingStatus("idle");
  };

  return (
    <div>
      {loadingStatus === "loading" && (
        <LoadingInTabWrapper>
          <div className="box">{IconLoading}</div>
        </LoadingInTabWrapper>
      )}

      {(loadingStatus === "refreshing" ||
        loadingStatus === "load-in-progress" ||
        loadingStatus === "idle") && (
        <>
          <AssignmentMapWrapper>
            <div className="col-left">
              {appraisersByPracticeMapData && (
                <PracticeMap
                  practice={appraisersByPracticeMapData?.practice}
                  appraisers={appraisersByPracticeMapData?.appraisers}
                />
              )}
            </div>

            <div className="col-right">
              {appraisersByPracticeMapData && (
                <>
                  <PracticeDetails
                    practice={appraisersByPracticeMapData?.practice!}
                    onClearSelection={onClearSelection}
                  />
                  <PracticeAppraisersList
                    appraisersByPracticeMapData={appraisersByPracticeMapData!}
                    onAssignAppraiser={handleAssignAppraiser}
                  />
                </>
              )}
            </div>
          </AssignmentMapWrapper>

          {(loadingStatus === "refreshing" ||
            loadingStatus === "load-in-progress") && (
            <RefreshInTabWrapper>
              <div className="box">{IconLoading}</div>
            </RefreshInTabWrapper>
          )}
        </>
      )}
    </div>
  );
};
export default PracticeView;
