import { ButtonConfirm } from "../../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import { mainTabs } from "../../../../../config/testIds";
import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { Popconfirm } from "antd";
import { SelectStyled } from "../../../../../style/Input";
import { SelectPair } from "../../../../../types/common.types";

const testIds = mainTabs.tabs.activities.cancelPractice;

const CancelPracticeWrapper = styled.div`
    textarea {
        margin: 2em 0;
    }

    .button-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    .ant-form-item-row {
        flex-direction: column;

        .ant-form-item-label {
            align-self: flex-start;
        }
    }
`;

interface ICancelPracticeProps {
    onCancel: (reason: string, note: string) => void;
}

const CancelPractice = (props: ICancelPracticeProps) => {
    const { t } = useTranslation();

    const [reasonCode, setReasonCode] = useState<string>("");
    const [note, setNote] = useState<string>("");
    const [isValid, setIsValid] = useState<boolean>(false);

    useEffect(() => {
        setIsValid(reasonCode !== "");
    }, [reasonCode]);

    const handleCancelPractice = () => {
        props.onCancel(reasonCode, note);
    };

    const options = useMemo(() => {
        const result: SelectPair[] = [];

        ["DSD", "DRRD", "RC", "EIB"].forEach((code) => {
            result.push({
                value: code,
                label: t(
                    `appraisals-details-tab-activities-collapsable-cancel-practice-reason-${code}`
                ),
            });
        });

        return result;
    }, []);

    return (
        <CancelPracticeWrapper data-testid={testIds.container}>
            <SelectStyled
                label={t(
                    "appraisals-details-tab-activities-collapsable-cancel-practice-reason-label"
                )}
                onChange={setReasonCode}
                options={options}
            />

            <TextArea
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder={
                    t(
                        "appraisals-details-tab-activities-collapsable-cancel-practice-note-label"
                    )!
                }
                data-testid={testIds.note}
            />

            <div className="button-wrapper">
                <Popconfirm
                    placement="leftBottom"
                    icon={null}
                    title={t(
                        "appraisals-details-tab-activities-collapsable-cancel-practice-confirm"
                    )}
                    onConfirm={handleCancelPractice}
                    okText={
                        <div data-testid={testIds.confirmButton}>
                            {t("switch-yes")}
                        </div>
                    }
                    cancelText={t("switch-no")}
                >
                    <ButtonConfirm
                        disabled={!isValid}
                        onClick={() => {}}
                        dataTestId={testIds.cancelPracticeButton}
                    >
                        {t("button-confirm")!}
                    </ButtonConfirm>
                </Popconfirm>
            </div>
        </CancelPracticeWrapper>
    );
};

export default CancelPractice;
