import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { mainTabs as testIds } from "../../../config/testIds";
import {
    DashboardWrapper,
    LoadingInTabWrapper,
    RefreshInTabWrapper,
} from "../../../style/DashbordWidgetWrappers";
import { RootState } from "../../../redux/store";
import { useEffect, useState } from "react";
import { IconLoading } from "../../../config/icons";
import {
    Communication,
    DashboardSummary,
    GetRequestListApiArg,
    GetUserCommunicationsListApiArg,
    PracticeList,
    Request,
    RequestsList,
    useLazyGetPracticesSummaryQuery,
    useLazyGetRequestListQuery,
    useLazyGetUserCommunicationsListQuery,
} from "../../../redux/apiSpecifications/apiCrud";
import { setInfoAlwaysVisibleTop } from "../../../redux/features/userSlice";
import { useTranslation } from "react-i18next";
import AppraisalsStatuses from "../../Widgets/Appraisals/AppraisalsStatuses";
import PracticesToManage, {
    PracticesToManageType,
} from "../../Widgets/Appraisals/AppraisalsToManage";
import AppraisalsCommunications, {
    AppraisalsCommunicationSelectionType,
} from "../../Widgets/Appraisals/AppraisalsCommunications";
import AppraisalsList from "../../Widgets/Appraisals/AppraisalsList";
import { AppraisalsStatusesType } from "../../../config/const";
import {
    AggregateSummaryDataD1,
    filterStatusByDiscardedD1,
} from "./common-utils";
import CommunicationList from "../../Widgets/Communications/CommunicationsList";
import { useInterval } from "../../../hooks/useInterval";
import TimerPie, { TimerUpdateWrapper } from "../../Layout/TimerPie";
import { Tenant } from "../../../redux/apiSpecifications/apiFesf";
import { useAuthorization } from "../../../hooks/useAuthorization";
import AppraisalsRequestsChartBO from "../../Widgets/Appraisals/AppraisalsRequestsCharts/AppraisalsRequestsChartBO";
import { AppraisalsRequestsChartSelectionType } from "../../Widgets/Appraisals/AppraisalsRequestsCharts/AppraisalsRequestsChartSelectionType";
import PracticeRequestsList from "../../Widgets/PracticeRequests/PracticeRequestsList";
import RegisteredMailErrorList from "../../Widgets/RegisteredMail/RegisteredMailErrorList";

const DashboardD1Wrapper = styled(DashboardWrapper)`
    display: grid;
    grid-template-areas:
        "statuses statuses refresh"
        "toManage comunications registeredMailError"
        "practicesList practicesList practicesList"
        "spacer spacer spacer";
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-rows: 3em auto auto auto;
    column-gap: 1em;
    row-gap: 1em;
`;

const BottomSpacer = styled.div`
    grid-area: spacer;
`;

type FiltersValues = {
    status?: AppraisalsStatusesType;
    toManageTotal?: string;
    toManageDrill?: string;
};

const defaultFilters: FiltersValues = {
    status: "discarded",
    toManageTotal: "urgent",
    toManageDrill: undefined,
};

interface IDashboardD1Props {
    tabKey?: number;
}

const DashBoard_D1 = (props: IDashboardD1Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [retrieveSummaryData] = useLazyGetPracticesSummaryQuery();

    const { selectedTenants, activeRole } = useSelector(
        (state: RootState) => state.user
    );

    const { getAuthorization } = useAuthorization();

    const { activeKey } = useSelector((state: RootState) => state.mainTabs);
    const autoRefreshTabData = useSelector(
        (state: RootState) => state.user.configParams?.autoRefreshTabData || 600
    ); // 10 minutes

    const {
        restart: restartRefrehInterval,
        reset: resetRefrehInterval,
        clear: clearRefreshInterval,
        percentage,
        remainingTime,
    } = useInterval({
        seconds: autoRefreshTabData,
        callback: () => {
            setLoadingStatus("refreshing");
            loadData(true);
        },
    });

    const [allData, setAllData] = useState<DashboardSummary>();
    const [filters, setFilters] = useState<FiltersValues>(defaultFilters);

    const [filteredByManagedData, setFilteredByManagedData] =
        useState<DashboardSummary>();

    const [filteredByDiscarded, setFilteredByDiscarded] =
        useState<DashboardSummary>();

    const [communicationsData, setCommunicationsData] = useState<
        Communication[]
    >([]);

    const [requestsData, setRequestsData] = useState<Request[]>([]);

    const [retriveCommunications] = useLazyGetUserCommunicationsListQuery();
    const [getRequestList] = useLazyGetRequestListQuery();

    const [filteringType, setFilteringType] = useState<
        "toManage" | "communications"
    >("toManage");
    const [communicationsFilterSelection, setCommunicationsFilterSelection] =
        useState<AppraisalsCommunicationSelectionType>({
            senderType: "",
            categoryCode: "",
            status: "",
        });

    const [requestsFilterSelection, setRequestsFilterSelection] =
        useState<AppraisalsRequestsChartSelectionType>({
            senderType: "",
            categoryCode: "",
        });

    const [loadingStatus, setLoadingStatus] = useState<
        "idle" | "loading" | "refreshing"
    >("idle");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (activeKey === props.tabKey) {
            setLoadingStatus("refreshing");
            (async () => {
                await loadData(true);
                restartRefrehInterval();
            })();
        } else clearRefreshInterval();
    }, [activeKey]);

    const loadData = async (keepFilter?: boolean) => {
        const tenantsSummaryData: DashboardSummary[] = [];

        if (!selectedTenants) return;

        for (let tenant of selectedTenants) {
            const idTenant = tenant.id!;

            const response = await retrieveSummaryData({
                idTenant,
                authorization: await getAuthorization(),
            });
            const { data, error, isSuccess, isError } = response;

            if (isSuccess && data) {
                tenantsSummaryData.push(data as DashboardSummary);
            } else if (isError) {
                dispatch(
                    setInfoAlwaysVisibleTop({
                        type: "error",
                        message: t("http-error-loading-dashboard"),
                    })
                );
                setIsLoading(false);
                return;
            } else {
                console.log("unknown error");
            }
        }

        const aggregatedSummaryData =
            AggregateSummaryDataD1(tenantsSummaryData);

        setAllData(aggregatedSummaryData);

        if (!keepFilter) setFilters(defaultFilters);

        const discarded = filterStatusByDiscardedD1(aggregatedSummaryData);
        setFilteredByDiscarded(discarded);
        handleFilterToManage(
            filters.toManageTotal!,
            filters.toManageDrill,
            discarded.practices
        );
        setIsLoading(false);
        setLoadingStatus("idle");
    };

    useEffect(() => {
        if (!selectedTenants) return;

        setIsLoading(true);
        setLoadingStatus("loading");
        loadData();
    }, [selectedTenants]);

    const loadCommunications = async (
        selection: AppraisalsCommunicationSelectionType
    ) => {
        if (!selectedTenants) return;

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("refreshing");

        const params: any = {
            authorization: await getAuthorization(),
            tenants: selectedTenants.map((t: Tenant) => t.id!),
        };

        if (selection.senderType) params.senderType = selection.senderType;
        if (selection.categoryCode)
            params.categoryCode = selection.categoryCode;
        if (selection.status) params.statusCode = selection.status;

        const response = await retriveCommunications(
            params as GetUserCommunicationsListApiArg
        );
        const { data, error, isSuccess, isError } = response;

        if (isSuccess && data) {
            setCommunicationsData(data as Communication[]);
        } else if (isError) {
            dispatch(
                setInfoAlwaysVisibleTop({
                    type: "error",
                    message: t("http-error-loading-dashboard"),
                })
            );
            setLoadingStatus("idle");
            return;
        } else {
            console.log("unknown error");
        }

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("idle");
    };

    const loadRequests = async (
        selection: AppraisalsRequestsChartSelectionType
    ) => {
        if (!selectedTenants) return;

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("refreshing");

        const params: GetRequestListApiArg = {
            authorization: await getAuthorization(),
            activeRole: activeRole!,
            tenants: selectedTenants.map((t: Tenant) => t.id!),
            lastMessageSenderType: selection.lastMessageSenderType ?? "",
            lastMessageReceiverType: selection.lastMessageReceiverType ?? "",
            categoryCode: selection.categoryCode ?? "",
            statusCode: selection.statusCode,
        };

        const response = await getRequestList(params);

        const { data, error, isSuccess, isError } = response;

        if (isSuccess && data) {
            setRequestsData(data as Request[]);
        } else if (isError) {
            dispatch(
                setInfoAlwaysVisibleTop({
                    type: "error",
                    message: t("http-error-loading-dashboard"),
                })
            );
            setLoadingStatus("idle");
            return;
        } else {
            console.log("unknown error");
        }

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("idle");
    };

    const handleFilterToManage = (
        totalType: string | undefined,
        drillType: string | undefined,
        practicesToUse: PracticeList = []
    ) => {
        const updatedFilters = {
            ...filters,
            toManageTotal: totalType,
            toManageDrill: drillType,
            status: "discarded" as AppraisalsStatusesType,
        };

        setFilteringType("toManage");
        setFilters(updatedFilters);

        const practices = (
            filteredByDiscarded?.practices || practicesToUse
        ).filter((practice) => {
            let toInclude = true;

            switch (totalType) {
                case "urgent":
                    toInclude = (toInclude && practice.priority === 1) || false;
                    break;
                case "due":
                    toInclude = (toInclude && practice.priority === 2) || false;
                    break;
                case "new":
                    toInclude = (toInclude && practice.priority === 3) || false;
                    break;
                case "suspended":
                    toInclude = (toInclude && practice.priority === 4) || false;
                    break;
            }

            if (drillType) {
                switch (drillType) {
                    case "rca":
                        toInclude =
                            (toInclude &&
                                practice.claimsBranchGroup === "RCA") ||
                            false;
                        break;
                    case "cvt":
                        toInclude =
                            (toInclude &&
                                practice.claimsBranchGroup === "CVT") ||
                            false;
                        break;
                }
            }

            return toInclude;
        });

        const practicesToManage = {
            total: practices.length,
            urgent: {
                rca: practices.filter(
                    (practice) =>
                        practice.priority === 1 &&
                        practice.claimsBranchGroup === "RCA"
                ).length,
                cvt: practices.filter(
                    (practice) =>
                        practice.priority === 1 &&
                        practice.claimsBranchGroup === "CVT"
                ).length,
            },
            due: {
                rca: practices.filter(
                    (practice) =>
                        practice.priority === 2 &&
                        practice.claimsBranchGroup === "RCA"
                ).length,
                cvt: practices.filter(
                    (practice) =>
                        practice.priority === 2 &&
                        practice.claimsBranchGroup === "CVT"
                ).length,
            },
            new: {
                rca: practices.filter(
                    (practice) =>
                        practice.priority === 3 &&
                        practice.claimsBranchGroup === "RCA"
                ).length,
                cvt: practices.filter(
                    (practice) =>
                        practice.priority === 3 &&
                        practice.claimsBranchGroup === "CVT"
                ).length,
            },
            suspended: {
                rca: practices.filter(
                    (practice) =>
                        practice.priority === 4 &&
                        practice.claimsBranchGroup === "RCA"
                ).length,
                cvt: practices.filter(
                    (practice) =>
                        practice.priority === 4 &&
                        practice.claimsBranchGroup === "CVT"
                ).length,
            },
        };

        const totalDailyPractices = allData?.totalDailyPractices;
        const totalAssignedPractices = allData?.totalAssignedPractices;
        const totalDiscardedPractices = allData?.totalDiscardedPractices;
        const communicationSummary = allData?.communicationSummary;

        const result: DashboardSummary = {
            totalDailyPractices,
            totalAssignedPractices,
            totalDiscardedPractices,
            practicesToManage,
            communicationSummary,
            practices,
        };

        setFilteredByManagedData(result);
    };

    const handleFilterCommunications = (
        selection: AppraisalsCommunicationSelectionType
    ) => {
        console.info(selection);
        setFilteringType("communications");
        setCommunicationsFilterSelection(selection);

        loadCommunications(selection);
    };

    const handleFilterRequests = (
        selection: AppraisalsRequestsChartSelectionType
    ) => {
        console.info(selection);
        setFilteringType("communications");

        setRequestsFilterSelection(selection);

        loadRequests(selection);
    };

    const handleFilterStatus = (status: AppraisalsStatusesType) => {
        setFilters({ ...filters, status });
    };

    const practicesToShowInList =
        filters.status == "daily"
            ? allData?.practices
            : filteredByManagedData?.practices;

    return (
        <div data-testid={testIds.dashboards.D1}>
            {isLoading && (
                <LoadingInTabWrapper>
                    <div className="box" data-testid={testIds.D1.loader}>
                        {IconLoading}
                    </div>
                </LoadingInTabWrapper>
            )}

            {!isLoading && allData && filteredByManagedData && (
                <>
                    <DashboardD1Wrapper>
                        <TimerUpdateWrapper>
                            <TimerPie
                                percentage={percentage as number}
                                tooltip={`${t(
                                    "timer-dashboard-refresh-tooltip-1"
                                )!}  ${remainingTime}`}
                                innerText={remainingTime as string}
                                bgColor="#32b677"
                                gridArea="refresh"
                                onClick={() => {
                                    setLoadingStatus("refreshing");
                                    loadData(true);
                                    resetRefrehInterval();
                                }}
                            />
                        </TimerUpdateWrapper>
                        <AppraisalsStatuses
                            daily={allData.totalDailyPractices}
                            assigned={allData.totalAssignedPractices}
                            discarded={allData.totalDiscardedPractices}
                            onSelect={handleFilterStatus}
                            selected={filters.status}
                            gridArea="statuses"
                        />
                        <PracticesToManage
                            practices={
                                filteredByDiscarded?.practicesToManage as PracticesToManageType
                            }
                            onSelect={handleFilterToManage}
                            statusSelected={filters.status}
                            totalSelected={filters.toManageTotal}
                            gridArea="toManage"
                            isOpen={true}
                            isActive={filteringType === "toManage"}
                            selectedLevel1={filters.toManageTotal}
                            selectedLevel2={filters.toManageDrill}
                        />

                        <AppraisalsRequestsChartBO
                            isActive={filteringType === "communications"}
                            userRequestsSummary={allData?.userRequestsSummary!}
                            gridArea={"comunications"}
                            onSelect={handleFilterRequests}
                            isOpen={true}
                            selection={requestsFilterSelection}
                        />

                        <RegisteredMailErrorList
                            registeredLetterErrors={
                                allData?.registeredLetterErrors!
                            }
                            isOpen={true}
                            gridArea={"registeredMailError"}
                        />

                        {/* <AppraisalsCommunications
                          communications={allData.communicationSummary}
                          gridArea="comunications"
                          onSelect={handleFilterCommunications}
                          selection={communicationsFilterSelection}
                          isOpen={true}
                        /> */}

                        {filteringType === "toManage" && (
                            <AppraisalsList
                                items={practicesToShowInList}
                                gridArea="practicesList"
                                isActive={activeKey === props.tabKey}
                                scenario="dashboard_bo"
                                onRefresh={() => {}}
                            />
                        )}

                        {filteringType === "communications" && (
                            <PracticeRequestsList
                                items={requestsData}
                                gridArea={"practicesList"}
                            />
                            // <CommunicationList
                            //     items={requestsData}
                            //     gridArea={"practicesList"}
                            // />
                        )}

                        <BottomSpacer />

                        {/*  testing purpose only */}
                        <div
                            data-testid={testIds.D1.testFilterByManagedButton}
                            style={{ display: "none" }}
                            onClick={() =>
                                handleFilterToManage("urgent", "rca")
                            }
                        ></div>
                    </DashboardD1Wrapper>
                    {loadingStatus === "refreshing" && (
                        <RefreshInTabWrapper>
                            <div
                                className="box"
                                data-testid={testIds.D1.loader}
                            >
                                {IconLoading}
                            </div>
                        </RefreshInTabWrapper>
                    )}
                </>
            )}
        </div>
    );
};

export default DashBoard_D1;
