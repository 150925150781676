import { useEffect, useState } from "react";
import { isNotEmpty } from "../../../../../../../validation/common";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../redux/store";
import {
    RejectionReason,
    useLazyGetRejectionReasonListQuery,
} from "../../../../../../../redux/apiSpecifications/apiCrud";
import { UserUICommunication } from "../../../../../../../types/common.types";
import { updateObject } from "../../../../../../../utils/updateObject";

export type RejectPracticeFormModel = {
    rejectionReasonCode: string;
    rejectionReasonNote?: string;
};

type RejectPracticeFormState = {
    data: RejectPracticeFormModel;
    canSubmit: boolean;
};

const useRejectPracticeForm = () => {
    const checkIfCanSubmit = (data: RejectPracticeFormModel) => {
        const { rejectionReasonCode } = data;

        return isNotEmpty(rejectionReasonCode);
    };

    const initState = () => {
        const data: RejectPracticeFormModel = {
            rejectionReasonCode: "",
        };

        return {
            data,
            canSubmit: checkIfCanSubmit(data),
        } as RejectPracticeFormState;
    };

    const [state, setState] = useState<RejectPracticeFormState>(initState());

    const { t } = useTranslation();

    const { authorization, activeRole } = useSelector(
        (rootState: RootState) => rootState.user
    );

    const [getRejectionReasonList, result] =
        useLazyGetRejectionReasonListQuery();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const response = await getRejectionReasonList({
            authorization: authorization,
        });
    };

    const handleDataChange = (value: any, field: string) => {
        const { data } = state;

        const updatedData = updateObject(data, value, field);

        setState({
            ...state,
            data: { ...updatedData },
            canSubmit: checkIfCanSubmit(updatedData),
        });
    };

    const { status, data: allRejectionReasons } = result;

    return {
        data: state.data,
        canSubmit: state.canSubmit,
        status,
        allRejectionReasons: allRejectionReasons as RejectionReason[],
        handleDataChange
    };
};

export default useRejectPracticeForm;
