import { notification } from "antd";
import {
    DashboardWrapper,
    LoadingInTabWrapper,
    RefreshInTabWrapper,
    WidgetWrapper,
} from "../../../../style/DashbordWidgetWrappers";
import styled from "styled-components";
import CreateBusinessReport from "./components/CreateBusinessReport";
import {
    AppraisalQrReportRange,
    QRAppraisalReports,
    useCreateAppraisalQrReportMutation,
    useLazyGetAppraisalQrReportsQuery,
} from "../../../../redux/apiSpecifications/apiFesf";
import { useEffect, useState } from "react";
import { NotificationPlacement } from "antd/lib/notification";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import BusinessReportList from "./components/BusinessReportList";
import { IconLoading } from "../../../../config/icons";
import { mainTabs as testIds } from "../../../../config/testIds";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useInterval } from "../../../../hooks/useInterval";

const DashboardH1Wrapper = styled(DashboardWrapper)`
    display: grid;
    grid-template-areas:
        "createReport"
        "practiceList"
        "spacer";
    // grid-template-columns: 2fr 3fr 50px;
    grid-template-rows: min-content auto;
    column-gap: 1em;
    row-gap: 1em;
`;

interface IDashboardH1Props {
    tabKey?: number;
}

const Dashboard_H1 = ({ tabKey }: IDashboardH1Props) => {
    const [api, contextHolder] = notification.useNotification();

    const { activeKey } = useSelector((state: RootState) => state.mainTabs);

    const { authorization } = useSelector((state: RootState) => state.user);

    const [loadingStatus, setLoadingStatus] = useState<
        "idle" | "loading" | "refreshing" | "load-in-progress"
    >("idle");

    const [businessReports, setBusinessReports] = useState<QRAppraisalReports>(
        []
    );

    const [getAppraisalQrReports] = useLazyGetAppraisalQrReportsQuery();

  const [createAppraisalQrReport] = useCreateAppraisalQrReportMutation();

  useEffect(() => {
    setLoadingStatus("loading");

    loadData();
  }, []);

    useEffect(() => {
        if (activeKey === tabKey) {
            refresh();
        }
    }, [activeKey]);

    const {
        restart: restartRefrehInterval,
        reset: resetRefrehInterval,
        clear: clearRefreshInterval,
        percentage,
        remainingTime,
    } = useInterval({
        seconds: 10,

        callback: () => loadData(),
    });

    const refresh = async () => {
        setLoadingStatus("refreshing");

        await loadData();

        setLoadingStatus("idle");
    };

    const loadData = async () => {
        const response = await getAppraisalQrReports({
            authorization: authorization,
        });

        const data = response.data as QRAppraisalReports;

        setBusinessReports(data);
    };

    const handleOnConfirmCreateBusinessReport = (
        appraisalQrReportRange: AppraisalQrReportRange
    ): Promise<string> =>
        new Promise(async (resolve, reject) => {
            // salvo qui il report

            setLoadingStatus("refreshing");

      const response = await createAppraisalQrReport({
        authorization: authorization,
        appraisalQrReportRange: appraisalQrReportRange,
      });

      const correctResponse = response as {
        data: QRAppraisalReports;
      };

      const errorResponse = response as {
        error: FetchBaseQueryError;
      };

      if (correctResponse.data) {
        setBusinessReports(correctResponse.data);

        notifySuccess("Richiesta nuovo report inserita con successo!");

        resolve("Success!");
      } else if (errorResponse) {
        reject(errorResponse.error);
      }

            setLoadingStatus("idle");
        });

    const notifySuccess = (text: string) => {
        api.success({
            message: "",
            description: text,
            duration: 5,
            placement: "bottomRight" as NotificationPlacement,
        });
    };

    return (
        <div data-testid={testIds.dashboards.H1}>
            {contextHolder}

            {loadingStatus === "loading" && (
                <LoadingInTabWrapper>
                    <div className="box" data-testid={testIds.H1.loader}>
                        {IconLoading}
                    </div>
                </LoadingInTabWrapper>
            )}

            {(loadingStatus === "refreshing" ||
                loadingStatus === "load-in-progress" ||
                loadingStatus === "idle") && (
                <>
                    <DashboardH1Wrapper>
                        <CreateBusinessReport
                            gridArea="createReport"
                            onConfirmCreateBusinessReport={
                                handleOnConfirmCreateBusinessReport
                            }
                        />

                        {businessReports.length > 0 && (
                            <BusinessReportList
                                businessReports={businessReports}
                            />
                        )}
                    </DashboardH1Wrapper>

                    {(loadingStatus === "refreshing" ||
                        loadingStatus === "load-in-progress") && (
                        <RefreshInTabWrapper>
                            <div
                                className="box"
                                data-testid={testIds.H1.loader}
                            >
                                {IconLoading}
                            </div>
                        </RefreshInTabWrapper>
                    )}
                </>
            )}
        </div>
    );
};

export default Dashboard_H1;
