import { useEffect, useMemo, useState } from "react";
import { IconLoading } from "../../../config/icons";
import { LoadingFullScreenWrapper } from "../../../style/DashbordWidgetWrappers";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../../hooks/useAuthorization";
import {
    AppraisalExpirationDatesConfig,
    TenantAppraisalExpirationDatesConfig,
    useCreateTenantAppraisalExpirationDatesConfigMutation,
    useLazyGetAppraisalExpirationDatesConfigQuery,
} from "../../../redux/apiSpecifications/apiFesf";
import styled from "styled-components";
import { FormRow } from "../../../style/form";
import { SelectStyled } from "../../../style/Input";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { SelectPair } from "../../../types/common.types";
import AppraisalExpirationDatesForm from "./AppraisalExpirationDatesForm";
import { updateObject } from "../../../utils/updateObject";
import { Popconfirm } from "antd";
import { ButtonConfirm } from "../../Layout/Buttons";
import { notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";

const AppraisalExpirationDatesWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 1em;

    .wrapper-content {
        // display: flex;
        // flex-wrap: wrap;
        // align-items: flex-start;
        // justify-content: center;
        padding: 2em;
        border-radius: 0.5em;
        overflow: hidden;
        box-shadow: 0 0 5px #aaa;
        background-color: #fff;
        gap: 2em 10em;

        .form {
            margin-top: 1em;
        }
    }

    .buttons-wrapper {
        width: 100%;
        margin-top: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em;
    }
`;

const defaultTenantAppraisalExpirationDatesConfig: TenantAppraisalExpirationDatesConfig =
    {
        // expirationdateclientappraisalcvt: 0,
        // expirationdateappraiserappraisalcvt: 0,
        // expirationdateclientappraisalrca1: 0,
        // expirationdateappraiserappraisalrca1: 0,
        // expirationdateclientappraisalrca2: 0,
        // expirationdateappraiserappraisalrca2: 0,
        // expirationdateclientappraisalrca0: 0,
        // expirationdateappraiserappraisalrca0: 0,
        // expirationdateclientfeedbackrca1: 0,
        // expirationdateappraiserfeedbackrca1: 0,
        // expirationdateclientfeedbackrca2: 0,
        // expirationdateappraiserfeedbackrca2: 0,
        // expirationdateclientlocationstatus: 0,
        // expirationdateappraiserlocationstatus: 0,
        // expirationdateclientcomplaintrecovery: 0,
        // expirationdateappraisercomplaintrecovery: 0,
        // expirationdateclientcongruityrequest: 0,
        // expirationdateappraisercongruityrequest: 0,
        // expirationdateclientcinematic: 0,
        // expirationdateappraisercinematic: 0,
        // expirationdateclientcomparative: 0,
        // expirationdateappraisercomparative: 0,
        // expirationdateclientctp: 0,
        // expirationdateappraiserctp: 0,
        // expirationdateivassrca0: 0,
        // expirationdateivassrca1: 0,
        // expirationdateivassrca2: 0,
    };

const AppraisalExpirationDates = () => {
    const { t } = useTranslation();

    const [api, contextHolder] = notification.useNotification();

    const { getAuthorization } = useAuthorization();

    const { selectedTenants } = useSelector((state: RootState) => state.user);

    const [status, setStatus] = useState<
        "idle" | "loading" | "saving" | "error"
    >("idle");

    const [selectedTenantId, setSelectedTenantId] = useState<
        Number | undefined
    >();

    const [selectedTenantConfig, setSelectedTenantConfig] = useState<
        TenantAppraisalExpirationDatesConfig | undefined
    >();

    const [appraisalExpirationDatesConfig, setAppraisalExpirationDatesConfig] =
        useState<AppraisalExpirationDatesConfig>([]);

    const [getAppraisalExpirationDatesConfig] =
        useLazyGetAppraisalExpirationDatesConfigQuery();
    const [createTenantAppraisalExpirationDatesConfig] =
        useCreateTenantAppraisalExpirationDatesConfigMutation();

    useEffect(() => {
        loadData();

        if (selectedTenants?.length ?? 0) {
            setSelectedTenantId(selectedTenants![0].id);
        }
    }, []);

    useEffect(() => {
        if (selectedTenantId && appraisalExpirationDatesConfig.length > 0) {
            const findResult = appraisalExpirationDatesConfig.find(
                (_) => _.idcompany === selectedTenantId
            );

            if (findResult) {
                setSelectedTenantConfig(findResult);
            } else {
                setSelectedTenantConfig({
                    ...defaultTenantAppraisalExpirationDatesConfig,
                    idcompany: selectedTenantId,
                } as TenantAppraisalExpirationDatesConfig);
            }
        }
    }, [selectedTenantId, appraisalExpirationDatesConfig]);

    const loadData = async () => {
        setStatus("loading");

        const { data, isError, isSuccess } =
            await getAppraisalExpirationDatesConfig({
                authorization: await getAuthorization(),
            });

        if (isSuccess) {
            setAppraisalExpirationDatesConfig(
                data as AppraisalExpirationDatesConfig
            );
        } else if (isError) {
            setStatus("error");

            // to do
            return;
        }

        setStatus("idle");
    };

    const tenatsOptions = useMemo(() => {
        const tenatsOptionsArray: SelectPair[] = [];

        if (selectedTenants) {
            selectedTenants.forEach((tenant) => {
                tenatsOptionsArray.push({
                    value: tenant.id,
                    label: tenant.companyName!,
                });
            });
        }

        return tenatsOptionsArray;
    }, [selectedTenants]);

    const handleOnChangeTenant = (value: Number) => {
        setSelectedTenantId(value);
    };

    const handleDataChange = (value: string, field: string) => {
        let valueToUpdate: string | null = "";

        if (value !== "") {
            if (/^\d+$/.test(value)) {
                if (value.length === 2 && value.startsWith("0")) {
                    valueToUpdate = value.substring(1);
                } else {
                    valueToUpdate = value;
                }
            } else {
                let propertyName = field as keyof typeof selectedTenantConfig;

                valueToUpdate = selectedTenantConfig![propertyName]
                    ? selectedTenantConfig![propertyName]
                    : "";
            }
        }

        let updatedData = updateObject(
            selectedTenantConfig,
            valueToUpdate ? Number(valueToUpdate) : null,
            field
        );

        setSelectedTenantConfig(updatedData);
    };

    const handleSaveStatus = async () => {
        setStatus("saving");

        const response = (await createTenantAppraisalExpirationDatesConfig({
            authorization: await getAuthorization(),
            tenantAppraisalExpirationDatesConfig: selectedTenantConfig!,
        })) as any;

        if (response.data) {
            setAppraisalExpirationDatesConfig(
                response.data as AppraisalExpirationDatesConfig
            );

            api.success({
                message: "",
                description: t(
                    "appraisal-expiration-dates-save-successfully-message"
                ),
                duration: 5,
                placement: "bottomRight" as NotificationPlacement,
            });
        }

        setStatus("idle");
    };

    return (
        <>
            {contextHolder}
            {["loading", "saving"].includes(status) && (
                <LoadingFullScreenWrapper>
                    <div className="box">{IconLoading}</div>
                </LoadingFullScreenWrapper>
            )}

            <AppraisalExpirationDatesWrapper>
                <div className="wrapper-content">
                    <div>
                        <FormRow>
                            <SelectStyled
                                label={`${t(
                                    "create-report-form-client-label"
                                )}`}
                                tooltip={t("create-report-form-client-tooltip")}
                                onChange={(tenantId) =>
                                    handleOnChangeTenant(tenantId)
                                }
                                value={selectedTenantId}
                                options={tenatsOptions}
                            />
                        </FormRow>
                    </div>

                    {selectedTenantConfig && (
                        <div className="form">
                            <AppraisalExpirationDatesForm
                                data={selectedTenantConfig}
                                onChange={handleDataChange}
                            />
                        </div>
                    )}

                    <div className="buttons-wrapper">
                        <Popconfirm
                            placement="top"
                            icon={null}
                            title={
                                t("configuration-panel-button-save-confirm")!
                            }
                            onConfirm={handleSaveStatus}
                            okText={t("switch-yes")!}
                            cancelText={t("switch-no")!}
                        >
                            <ButtonConfirm onClick={() => {}}>
                                {t("button-save")!}
                            </ButtonConfirm>
                        </Popconfirm>
                    </div>

                    {/* <pre>{JSON.stringify(selectedTenantConfig, null, 2)}</pre> */}
                </div>
            </AppraisalExpirationDatesWrapper>
        </>
    );
};

export default AppraisalExpirationDates;
