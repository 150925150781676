import { useMemo } from "react";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import { useTranslation } from "react-i18next";

interface IPracticeRejectedDescriptionProps {
    modifyDetailsJson: Modifydetail[];
}

const PracticeRejectedDescription = ({
    modifyDetailsJson,
}: IPracticeRejectedDescriptionProps) => {
    const { t } = useTranslation();

    const rejectionReasonDescription: string = useMemo(() => {
        const value = modifyDetailsJson.find(
            (_) => _.key === "rejection_reason_code"
        );

        return value?.value ? t(`historical-actions-rejection-reason-${value?.value}-description`) : "";
    }, [modifyDetailsJson]);

    const rejectionReasonNote: string = useMemo(() => {
        const value = modifyDetailsJson.find(
            (_) => _.key === "rejection_reason_note"
        );

        return value?.value ?? "";
    }, [modifyDetailsJson]);

   
    if (rejectionReasonNote) {
        return (
            <>
                {t("historical-actions-practice-rejected-with-note-description", {
                    rejection_reason_description: rejectionReasonDescription,
                    rejection_reason_note: rejectionReasonNote
                })}
            </>
        );
    } else {
        return (
            <>
                {t("historical-actions-practice-rejected-description", {
                    rejection_reason_description: rejectionReasonDescription,
                })}
            </>
        );
    }
};

export default PracticeRejectedDescription;
