import { useTranslation } from "react-i18next";
import { InputTextProps, InputTextStyled } from "../../style/Input";
import { ValidateStatusTypes } from "../../validation/subjectValidation";

import { InputFiscalCode as testId } from "../../config/testIds";
import { AdditionalValidationField } from "../../types/common.types";

interface InputFiscalCodeProps extends InputTextProps {
  validation: AdditionalValidationField;
  value: string | undefined;
}

/**
 * Render an input field for fiscal code.
 */

const InputFiscalCode = (props: InputFiscalCodeProps) => {
  const { t } = useTranslation();

  const handleOnChange = (val: string) => {
    props.onChange!(val);
  };

  const validateStatus = props.validation.validateStatus as ValidateStatusTypes;
  const help = t(props.validation.messageKey || "") || "";

  return (
    <InputTextStyled
      label={`${t("edit-subject-tab-data-fiscal-code")}`}
      tooltip={`${t("edit-subject-tab-data-fiscal-code-tooltip")}`}
      placeholder={`${t("edit-subject-tab-data-fiscal-code-placeholder")}`}
      onChange={handleOnChange}
      value={props.value}
      validateStatus={validateStatus}
      help={help}
      required={false}
      dataTestId={props.dataTestId || testId.fiscalCode}
      disabled={props.readOnly}
    />
  );
};

export default InputFiscalCode;
