import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface NotificationState {
    // open?: boolean;
    type: "success" | "error" | "warning" | "info" | undefined;
    message?: string;
}

export const notificationInitialState: NotificationState = {
    type: undefined,
    message: "",
};

export const NotificationsSlice = createSlice({
    name: "notifications",
    initialState: notificationInitialState,
    reducers: {
        addNotification: (state, action: PayloadAction<NotificationState>) => ({
            ...notificationInitialState,
            ...action.payload,
            open: true
        }),
    }
})

export const NotificationsActions = NotificationsSlice.actions;
export const NotificationsReducer = NotificationsSlice.reducer;