import { useSelector } from "react-redux";
import { AppraiserScoreTenantConfig } from "../../../../redux/apiSpecifications/apiFesf";
import { RootState } from "../../../../redux/store";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useMemo } from "react";
import ExpertizerScoreInputText from "../ExpertizerScoreInputText";

interface IAppraiserScoreTenantConfigFormProps {
    appraiserScoreTenantConfig: AppraiserScoreTenantConfig;
    onChange: (field: string, value: string) => void;
}

const AppraiserScoreTenantConfigForm = ({
    appraiserScoreTenantConfig,
    onChange,
}: IAppraiserScoreTenantConfigFormProps) => {
    const { userDetails } = useSelector((state: RootState) => state.user);

    const { t } = useTranslation();

    const companyName = useMemo(() => {
        if (appraiserScoreTenantConfig.tenantId === undefined) return "";

        const selectedTenant = userDetails?.tenants?.find(
            (_) => _.id === appraiserScoreTenantConfig.tenantId
        );
        return selectedTenant?.companyName ?? "";
    }, [appraiserScoreTenantConfig.tenantId]);

    const values = useMemo(() => {
        return {
            singleSignatureCount: {
                Threshold:
                    appraiserScoreTenantConfig?.singleSignatureCountThreshold ||
                    0,
            },
            doubleSignatureCount: {
                Threshold:
                    appraiserScoreTenantConfig?.doubleSignatureCountThreshold ||
                    0,
            },
            noCardCvtCount: {
                Threshold:
                    appraiserScoreTenantConfig?.noCardCvtCountThreshold || 0,
            },
        };
    }, [appraiserScoreTenantConfig]);

    return (
        <div className="tenant-config-box">
            <div className="title">{companyName}</div>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>{t("expert-score-configure-threshold-label")}</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {[
                        "singleSignatureCount",
                        "doubleSignatureCount",
                        "noCardCvtCount",
                    ].map((appraiserConfigType) => (
                        <tr key={appraiserConfigType}>
                            <td>
                                <div className="label">
                                    {t(
                                        `expert-score-configure-threshold-weight-field-${appraiserConfigType}-label`
                                    )}
                                </div>
                            </td>

                            <td className="value">
                                <ExpertizerScoreInputText
                                    value={
                                        values[
                                            appraiserConfigType as keyof typeof values
                                        ]?.Threshold
                                    }
                                    onChange={(value) =>
                                        onChange(
                                            `${appraiserConfigType}Threshold`,
                                            value
                                        )
                                    }
                                />
                            </td>

                            <td className="value"></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AppraiserScoreTenantConfigForm;
