import { Layout } from "antd";
import { header as testId } from "../../config/testIds";

import styled from "styled-components";
import SingedIn from "../Authentication/SignedIn";
import DrawerMenu from "./DrawerMenu";
import ClickableSearch from "../Search/ClickableSearch";
import SelectClient from "../Appraisals/SelectClient";
import { getMsalConfig } from "../../config/azureConfig";
import dekra from "../../style/dekra";

import logo from "../../images/Logo_CLAIENT.png";


const HeaderStyled = styled(Layout.Header)`
  background-color: #fff;
  position: relative;
  z-index: 2;
  padding: 0;
  height: 80px;
  border-top: 3px solid ${getMsalConfig().color};
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background-color: ${dekra.primaryColor};
  padding: 0 3em 0 0;
`;

const LogoWrapper = styled.div`
  margin-left: 1em;
  img {
    height: 26px;
  }
`;

const Header = () => {
  return (
    <HeaderStyled data-testid={testId.container}>
      <Content>
        <DrawerMenu />
        <div>
          <LogoWrapper>
            <img src={logo} />
          </LogoWrapper>
          <SelectClient />
        </div>
        <div style={{ flex: 1 }}></div>
        <ClickableSearch />
        <SingedIn />
      </Content>
    </HeaderStyled>
  );
};

export default Header;
