export const removeMimeType = (base64: string) => {
  const base64WithoutMimeType = base64.split(",")[1];
  return base64WithoutMimeType;
};

export const addMimeType = (base64: string, filename: string) => {
  const fileExtention = filename.split(".").pop();
  const mimeType =
    fileExtention === "pdf"
      ? "application/pdf"
      : fileExtention === "ADZ"
      ? `"application/ADZ"`
      : `image/${fileExtention}`;
  const base64WithMimeType = `data:${mimeType};base64,${base64}`;
  return base64WithMimeType;
};
