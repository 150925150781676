import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../redux/store";
import { Tabs } from "antd";
import { Tab } from "rc-tabs/lib/interface";
import { useEffect, useMemo, useState } from "react";
import { buildTabContent } from "./tabContentBuilder";
import dekra from "../../style/dekra";
import {
  MainTabType,
  removeTab,
  setActiveTab,
} from "../../redux/features/mainTabsSlice";
import { IconClose } from "../../config/icons";
import { mainTabs as testIds } from "../../config/testIds";
import { MainTabsTypes } from "../../config/const";
import { removeAppraiser } from "../../redux/features/appraisersSlice";

const MainTabsWrapper = styled.div`
  margin-top: 5px;
  width: 100%;
  min-height: calc(100vh - 115px);

  .ant-tabs-nav-operations {
    display: none !important;
  }

  display: flex;

  .ant-tabs {
    width: 100%;
  }
  .ant-tabs-nav-wrap {
    background-color: #fff;
    box-shadow: 0 0 5px #aaa;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-ink-bar {
    background: ${dekra.primaryColor};
    display: none;
  }

  .ant-tabs-nav {
    margin-bottom: 0;

    &:before {
      border: none;
    }
  }

  .ant-tabs-nav-list {
    background-color: #eee;
    color: #333;

    .ant-tabs-tab:first-child {
      border-left: none;
    }
  }

  .ant-tabs-tab {
    padding: 0.75em 2em;
    letter-spacing: 1px;
    border-left: 1px solid #ccc;
    text-transform: uppercase;
    font-size: 0.9em;

    .ant-tabs-tab-btn {
      &:hover {
        color: ${dekra.primaryColor};
      }
    }
  }

  .ant-tabs-tab-active {
    background: ${dekra.primaryColor};
    position: relative;

    .ant-tabs-tab-btn {
      color: white;

      &:hover {
        color: white;
      }
    }
  }
`;

const TabLabelWrapper = styled.div`
  display: flex;
  align-items: center;

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -0.75em -1.25em 0 1em;
    font-size: 1.4em;

    &:hover {
      color: red;
    }
  }
`;

const TimerPercentage = styled.div<{ percentage: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: ${(props) => props.percentage}%;
  background: #6ac89b;
`;

interface ITabHeaderItemProps {
  itemKey: number;
  label: string;
  type: MainTabsTypes;
  onRemove?: (key: number) => void;
}

const TabHeaderItem = (props: ITabHeaderItemProps) => {
  const { itemKey: key, label, type, onRemove } = props;

  const handleRemove = () => onRemove && onRemove(key);

  return (
    <TabLabelWrapper>
      <div className="text">{label}</div>
      {!type.startsWith("dashboard") && (
        <div className="icon" onClick={handleRemove}>
          <IconClose />
        </div>
      )}
    </TabLabelWrapper>
  );
};

const MainTabs = () => {
  const dispatch = useDispatch();
  const { tabs, activeKey } = useSelector((state: RootState) => state.mainTabs);

  const handleRemoveTab = (key: number) => {
    const tabToRemove = tabs.find((tab) => tab.key === key);
    if (tabToRemove?.type === "appraiser-details")
      dispatch(removeAppraiser(tabToRemove.externalData.idAppraiser));

    dispatch(removeTab(key));
  };

  const handleOnChangeTab = (key: string) => {
    dispatch(setActiveTab(parseInt(key)));
  };

  const items = useMemo(
    () =>
      tabs.map(
        (item) =>
          ({
            key: item.key.toString(),
            label: (
              <TabHeaderItem
                key={item.key}
                itemKey={item.key}
                label={item.label}
                type={item.type}
                onRemove={handleRemoveTab}
              />
            ),
            children: buildTabContent(item, false),
          } as Tab)
      ),
    [tabs]
  );

  return (
    <MainTabsWrapper data-testid={testIds.container}>
      <Tabs
        items={items}
        activeKey={activeKey.toString()}
        onChange={handleOnChangeTab}
      />
    </MainTabsWrapper>
  );
};

export default MainTabs;
