import { t } from "i18next";
import { InputTextStyled } from "../../../../style/Input";

const SettingsForm = () => {
  return (
    <>
      <td>{t("assigment-map-additional-parameters-modal-label")}</td>
      <InputTextStyled
        type="text"
        label= {t("assigment-map-additional-parameters-modal-kilometer-radius-label")}
        value={"150"}
        onChange={() => {}}
      />
    </>
  );
};
export default SettingsForm;
