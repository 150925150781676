import styled from "styled-components";
import dekra from "./dekra";

export const Col = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
`;

interface RowProps {
	justify?: string | undefined;
}

export const Row = styled.div<RowProps>`
	display: flex;
	flex: 1;
	flex-direction: row;
	width: 100%;
	align-items: flex-start;
	justify-content: ${(props) => props.justify || "flex-start"};
`;

export const RowSpacer = styled.div`
	display: block;
	height: 100%;
	width: 2em;
`;

export const RowEmptySpace = styled.div`
	flex: 1;
`;

export const CenteredRow = styled.div`
	align-items: center;
	justify-content: center;
	display: flex;
`;

export const Hr = styled.div`
	height: 1px;
	border-top: 1px solid #eee;
	width: 100%;
	margin: 1em 0;
`;

export const Hidden = styled.div`
	width: 0px;
	height: 0px;
	overflow: hidden;
`;

export const PageContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 1400px;
	background: #fff;
	border-radius: 1em;
	box-shadow: 0 0 3px #aaa;
	overflow: hidden;
`;

export const ModalContentWrapper = styled(Col)`
	background-color: #fafafa;
	position: relative;

	.ant-tabs-nav {
		padding-top: 5em;
	}

	.ant-tabs-content-holder {
		background-color: #eee;
		border-top-left-radius: 1em;
		box-shadow: inset 0 0 5px #aaa;
		padding: 3em 4em;
	}

	.ant-tabs-content {
		min-height: 39em;
	}

	.ant-tabs-tab-btn {
		text-transform: uppercase;
	}

	.ant-tabs-tab.ant-tabs-tab-active {
		background-color: ${dekra.primaryColor};

		.ant-tabs-tab-btn {
			color: #fff;
		}
	}

	.ant-tabs-ink-bar {
		background: ${dekra.primaryColor};
	}

	.ant-tabs-tabpane {
		padding-left: 0 !important;
	}
`;

export const ModalTitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1.4em;
	letter-spacing: 1px;
	width: 100%;
	padding: 2em;
	text-transform: uppercase;
	color: #333;

	svg {
		font-size: 1.6em;
		margin-left: 0.5em;
		margin-top: -0.1em;
	}
`;

export const ModalActionButtons = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	flex: 1;

	button {
		margin-left: 1em;
	}
`;

/// WIDGETS WRAPPER TO REMOVE - START
export const WidgetsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 1em;
`;

export const WidgetWrapper = styled.div<{ size?: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	padding: 1em 3em;
	border-radius: 1em;
	box-shadow: rgb(170, 170, 170) 0px 0px 3px;

	${(props) => props.size === "small" && "flex: 1; min-width: 300px;"}
	${(props) => props.size === "medium" && "flex: 2; min-width: 500px; "}
	${(props) => (!props.size || props.size === "full") && "width: 100%;"}
`;

/// WIDGETS WRAPPER TO REMOVE - END
