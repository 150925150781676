import { useTranslation } from "react-i18next";
import { PracticeRequests } from "../../../../../redux/apiSpecifications/apiCrud";
import { useMemo } from "react";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import styled from "styled-components";
import { dateTimeToNumber } from "../../../../../utils/date";

export type RequestResultItem = {
    id: number;
    isUrgent: boolean;
    status: string; // Nuovo, Letto, Bozza, Inviato
    category: string; // Sollecito, Richiesta Aggiornamenti, Richiesta Accordo Conservativo, Altro
    from: string; // Profilo del mittente
    to: string; // Profilo del destinatario
    practiceId: number;
    dateTime: string; // Data e ora di invio
    dateTimeNumber: string;
    text: string; // Testo del messaggio
};

const CellWrapper = styled.div`
    cursor: pointer;
    padding: 1em 0;
`;

// truncate text to max n chars eventualli adding "..."
const truncText = (text: string | undefined, n: number) =>
    !text ? "" : text.length > n ? text.substring(0, n) + "..." : text;

interface IRequestsListProps {
    items: PracticeRequests | undefined;
    onSelectItem: (item: RequestResultItem) => void;
}

const RequestsList = ({ items, onSelectItem }: IRequestsListProps) => {
    const { t } = useTranslation();

    const data = useMemo(
        () =>
            items
                ?.map(
                    (item) =>
                        ({
                            id: item.id,
                            isUrgent: item.isUrgent ?? false,
                            status: t(
                                `practice-request-status-description-${item.status}`
                            )!,
                            category: t(
                                `practice-request-category-description-${item.categoryCode}`
                            )!,
                            from:
                                item.messages!.length > 0
                                    ? t(
                                          `practice-request-user-profile-description-${item.messages![0]
                                              .senderProfile!}`
                                      )!
                                    : t(
                                          `practice-request-user-profile-description-${item.senderProfile!}`
                                      )!,
                            to:
                                item.messages!.length > 0
                                    ? t(
                                          `practice-request-user-profile-description-${item.messages![0]
                                              .receiverProfile!}`
                                      )!
                                    : t(
                                          `practice-request-user-profile-description-${item.recipientProfile!}`
                                      )!,
                            practiceId: item.practiceId!,
                            dateTime:
                                item.messages!.length > 0
                                    ? item.messages![0].messageDate!
                                    : item.sendingDate,
                            dateTimeNumber:
                                item.messages!.length > 0
                                    ? dateTimeToNumber(
                                          item.messages![0].messageDate!
                                      )
                                    : dateTimeToNumber(item.sendingDate),
                            text:
                                item.messages!.length > 0
                                    ? item.messages![0].messageText!
                                    : "",
                        } as RequestResultItem)
                )
                .sort((a, b) =>
                    a.dateTimeNumber > b.dateTimeNumber ? -1 : 1
                ) || [],
        [items]
    );

    const resultsColumns = useMemo<MRT_ColumnDef<RequestResultItem>[]>(
        () => [
            {
                accessorKey: "isUrgent",
                header: t(`practice-requests-list-is-urgent-colum-header`),
                maxSize: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        {row.original.isUrgent ? t(`switch-yes`) : ""}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "status",
                header: t(`practice-requests-list-status-colum-header`),
                maxSize: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        {row.original.status}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "category",
                header: t(`practice-requests-list-category-colum-header`),
                size: 400,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        <div title={row.original.category}>
                            {row.original.category}
                        </div>
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "from",
                header: t("practice-requests-list-from-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        <div title={row.original.from}>{row.original.from}</div>
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "to",
                header: t("practice-requests-list-to-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        <div title={row.original.to}>{row.original.to}</div>
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "dateTimeNumber",
                header: t("practice-requests-list-date-time-column-header"),
                size: 180,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        {row.original.dateTime}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "text",
                header: t("practice-requests-list-text-column-header"),
                maxSize: 1000,
                size: 350,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        {truncText(row.original.text, 30)}
                    </CellWrapper>
                ),
            },
        ],
        []
    );

    const handleSelectRow = (row: RequestResultItem) => {
        onSelectItem(row);
    };

    return (
        <>
            <MaterialReactTable
                columns={resultsColumns}
                data={data}
                enableDensityToggle={false}
                muiTableBodyCellProps={{
                    sx: {
                        textAlign: "left",
                    },
                }}
            />

            {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        </>
    );
};

export default RequestsList;
