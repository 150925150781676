import styled from "styled-components";
import { WidgetWrapper } from "../../../../../style/DashbordWidgetWrappers";
import { t } from "i18next";
import { IconRemoveFilter } from "../../../../../config/icons";
import { SelectedAppraiser } from "../../Maps/models";
import {
  AppraiserMapData,
  CoverageDistrictMapData,
} from "../../../../../redux/apiSpecifications/apiCrud";

const AppraiserDetailsWrapper = styled(WidgetWrapper)`
  flex-direction: column;
  display: flex;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0 5px #aaa;
  overflow: hidden;

  .header {
    color: #333;
    background-color: #ededed;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    display: flex;

    .title {
      flex: 1 1 0%;
      padding: 0.5em 2em;
    }

    .actions {
      .icon-wrapper {
        font-size: 1.4em;
        margin-top: 6px;
        margin-right: 1em;
        cursor: pointer;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 100%;
    padding: 1em 2em;

    .icon {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .info {
      flex: 1 1 auto;
      flex-direction: column;
      display: flex;
      gap: 1em;

      .info-row {
        display: flex;
        flex-direction: row;
        gap: 1em;
      }

      .info-row > div {
        width: 100%;
      }
    }
  }
`;

interface IAppraiserDetailsProps {
  appraiser: AppraiserMapData;
  onClearSelection: () => void;
}

const AppraiserDetails = ({
  appraiser,
  onClearSelection,
}: IAppraiserDetailsProps) => {
  return (
    <AppraiserDetailsWrapper>
      <div className="header">
        <div className="title">
          {t("assignment-map-appraiser-details-title")}
        </div>

        <div className="actions">
          <div className="icon-wrapper" onClick={onClearSelection}>
            <IconRemoveFilter />
          </div>
        </div>
      </div>

      <div className="body">
        {appraiser.coverageDistricts?.[0].isPosition === true ? (
          <div className="icon">
            <img
              src={`/images/markers/new/appraisers/${
                appraiser.isInternal !== true ? "external" : "internal"
              }-${appraiser.coverageDistricts?.map(
                (c: CoverageDistrictMapData) => c.saturationClusterCode
              )}-live-big.png`}
            />
          </div>
        ) : appraiser.isInternal !== true ? (
          <div className="icon">
            <img
              src={`/images/markers/new/appraisers/external-${appraiser.coverageDistricts?.map(
                (c: CoverageDistrictMapData) => c.saturationClusterCode
              )}-big.png`}
            />
          </div>
        ) : (
          <div className="icon">
            <img
              src={`/images/markers/new/appraisers/internal-${appraiser.coverageDistricts?.map(
                (c: CoverageDistrictMapData) => c.saturationClusterCode
              )}-big.png`}
            />
          </div>
        )}

        <div className="info">
          <div>
            <div className="info-row">
              <div>{appraiser.name}</div>
              <div>{`${t("assignment-map-appraiser-details-score")} ${
                appraiser.totalScore ?? ""
              } `}</div>
            </div>

            <div className="info-row">{appraiser.address}</div>
          </div>

          <div>
            <div className="info-row">
              <div>{`${t("assignment-map-appraiser-details-total-managed")} ${
                appraiser.totalManagedPractices ?? ""
              }`}</div>
              <div>{`${t("assignment-map-appraiser-details-montly-residual")} ${
                appraiser.monthlyResidual ?? ""
              }`}</div>
            </div>

            <div className="info-row">
              <div>{`${t("assignment-map-appraiser-details-daily-capacity")} ${
                appraiser.totalDailyCapacity ?? ""
              }`}</div>
              <div>{`${t("assignment-map-appraiser-details-montly-capacity")} ${
                appraiser.totalMonthlyCapacity ?? ""
              }`}</div>
            </div>
          </div>
        </div>
      </div>

      {/* <pre>{JSON.stringify(appraiser, null, 2)}</pre> */}
    </AppraiserDetailsWrapper>
  );
};

export default AppraiserDetails;
