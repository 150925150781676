import styled from "styled-components";
import { FormRow } from "../../../../../style/form";
import InputIBAN from "../../../../Inputs/InputIBAN";
import { AppraiserUser } from "../../../../../redux/apiSpecifications/apiFesf";
import { useEffect, useState } from "react";
import { InputFieldValidation } from "../../../../../validation/subjectValidation";
import IBAN from "iban";

const AppraiserPaymentMethodWrapper = styled.div`
    margin-top: 2em;
    padding-top: 2em;
    border-top: 1px solid #eee;
    width: 90%;
    max-width: 80em;
`;

const defaultValidationResult: InputFieldValidation = {
    validateStatus: "success",
    messageKey: "",
};

interface IAppraiserPaymentMethodProps {
    appraiser: AppraiserUser | undefined;
    onChange: (appraiser: AppraiserUser) => void;
}

const AppraiserPaymentMethod = ({
    appraiser,
    onChange,
}: IAppraiserPaymentMethodProps) => {
    const [ibanValidation, setIbanValidation] = useState<InputFieldValidation>(
        defaultValidationResult
    );

    useEffect(() => {
        if (appraiser && appraiser.iban && !IBAN.isValid(appraiser.iban)) {
            setIbanValidation({
                ...ibanValidation,
                validateStatus: "error",
                messageKey: "iban-data-validation-invalid",
            });
        } else {
            setIbanValidation(defaultValidationResult);
        }
    }, [appraiser?.iban]);

    const handleOnChange = (value: string, field: string) => {
        const newAppraiser = { ...appraiser, [field]: value.trim() };

        onChange(newAppraiser);
    };

    return (
        <AppraiserPaymentMethodWrapper>
            <FormRow>
                <InputIBAN
                    required={false}
                    showTooltip={false}
                    value={appraiser?.iban || ""}
                    onChange={(iban) => handleOnChange(iban.value, "iban")}
                    validation={{
                        state: ibanValidation.validateStatus === "success",
                        validateStatus: ibanValidation.validateStatus,
                        messageKey: ibanValidation.messageKey,
                    }}
                    index={0}
                />
            </FormRow>
        </AppraiserPaymentMethodWrapper>
    );
};

export default AppraiserPaymentMethod;
