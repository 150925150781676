import styled from "styled-components";
import { RowSpacer } from "../../../../../../style/containers";
import { FormRow } from "../../../../../../style/form";
import {
  DatePickerStyled,
  InputTextStyled,
  TimePickerStyled,
} from "../../../../../../style/Input";
import AddressAutocomplete from "../../../../../AzureMapsAddress/AddressAutocomplete";
import SelectUserTenants from "../../../../../Inputs/SelectUserTenants";
import { ButtonConfirm } from "../../../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import {
  useCreateClaimContext,
  useCreateClaimDispatch,
} from "./CreateClaimContext";
import { useMemo } from "react";

const CreateClaimQuickFormWrapper = styled.div`
  .buttons-wrapper {
    width: 100%;
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CreateClaimQuickForm = () => {
  const { t } = useTranslation();

  const { createClaimData } = useCreateClaimContext();

  const dispatch = useCreateClaimDispatch();

  const enabledCreateButton = useMemo(() => {
    return (
      createClaimData &&
      createClaimData.address &&
      createClaimData.companyId &&
      createClaimData.date &&
      createClaimData.time &&
      createClaimData.plateNumber
    );
  }, [createClaimData]);

  const handleOnChange = (value: any, field: string) => {
    dispatch!({
      type: "CHANGE_CLAIM_DATA",
      payload: { value: value, field: field },
    });
  };

  const handleOpenWizard = () => {
    dispatch!({
      type: "SET_OPEN_WIZARD",
      payload: true,
    });
  };

  return (
    <CreateClaimQuickFormWrapper>
      <FormRow style={{ alignItems: "flex-end" }}>
        <SelectUserTenants
          label={t("LABELS.company")}
          placeholder={t("PLACEHOLDERS.select-company")}
          required={true}
          value={createClaimData?.companyId}
          onChange={(value) => {
            handleOnChange(value, "companyId");
          }}
          showSearch={true}
          allowClear={1 > 0}
        />
      </FormRow>

      <FormRow style={{ alignItems: "flex-end" }}>
        <AddressAutocomplete
          labelPlaceholder={t("LABELS.place-happening")}
          placeholder={t("PLACEHOLDERS.place-happening")}
          required={true}
          address={createClaimData?.address}
          onChange={(value) => {
            handleOnChange(value, "address");
          }}
          options={{ civicMandatory: false }}
        />
      </FormRow>

      <FormRow style={{ alignItems: "flex-end" }}>
        <DatePickerStyled
          label={t("LABELS.date-happening")}
          placeholder={"gg/mm/aaaa"}
          required={true}
          value={createClaimData?.date}
          onChange={(value) => {
            handleOnChange(value, "date");
          }}
        />

        <RowSpacer />

        <TimePickerStyled
          label={t("LABELS.time-happening")}
          placeholder={"hh:mm"}
          required={true}
          value={createClaimData?.time}
          onChange={(value) => {
            handleOnChange(value, "time");
          }}
        />

        <RowSpacer />

        <InputTextStyled
          label={t("LABELS.plate-number")}
          placeholder={t("PLACEHOLDERS.plate-number")}
          onChange={(value) => {
            handleOnChange(value, "plateNumber");
          }}
          required={true}
          value={createClaimData?.plateNumber}
        />
      </FormRow>

      <div className="buttons-wrapper">
        <ButtonConfirm
          onClick={handleOpenWizard}
          disabled={!enabledCreateButton}
        >
          {t("BUTTONS.create")!}
        </ButtonConfirm>
      </div>

      {/* <pre>{JSON.stringify(loadingStatus, null, 2)}</pre>
      <pre>{JSON.stringify(createClaimData, null, 2)}</pre> */}
    </CreateClaimQuickFormWrapper>
  );
};

export default CreateClaimQuickForm;
