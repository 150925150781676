import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MaterialReactTable, {
  MRT_RowSelectionState,
  MRT_TableInstance,
} from "material-react-table";
import { MRT_ColumnDef } from "material-react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { widgets } from "../../../config/testIds";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { useInterval } from "../../../hooks/useInterval";
import { IconLoading, IconLocked } from "../../../config/icons";
import {
  PracticeF1List,
  PracticesProcessingStatus,
  useLazyGetPracticesStatusQuery,
} from "../../../redux/apiSpecifications/apiCrud";
import { addTab } from "../../../redux/features/mainTabsSlice";
import { useAuthorization } from "../../../hooks/useAuthorization";

const testId = widgets.customerList;

const CustomerListWrapper = styled(WidgetWrapper)`
  align-items: flex-start;
  max-width: 100%;
  overflow: auto;
  padding: 2em;

  .MuiPaper-root {
    box-shadow: none;
    width: 100%;
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiTableCell-root {
    padding: 0;
  }

  .confirm-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
  }
`;

const CustomerListWithoutWrapper = styled.div<{ gridArea?: string }>`
  grid-area: ${(props) => props.gridArea};

  .MuiPaper-root {
    box-shadow: none;
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiTableCell-root {
    padding: 0;
  }
`;

const CellWrapper = styled.div`
  cursor: pointer;
  padding: 1em 0;

  &.blocked-practice-row {
    color: #888;
  }
`;

const BlockedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.6em;
  font-size: 1.6em;
  color: #c44f4f;
`;

export type CustomerResultItem = {
  id: number;
  priority: string;
  clientName: string;
  sxNumber: string;
  sxType: string;
  plate: string;
  model: string;
  cap: string;
  cityAndProvince: string;
  expertDeadline: string;
  status: string;
  statusCode: string;
  isBlocked: boolean;
  blockedBy: string;
  practiceNumberCompany: string;
  actions: string;
  appraiserName: string;
};

export type CustomerListItemType = any & {
  isBlocked: boolean;
  blockedBy: string;
};

// truncate text to max n chars eventualli adding "..."
const truncText = (text: string | undefined, n: number) =>
  !text ? "" : text.length > n ? text.substring(0, n) + "..." : text;

interface ICustomerListProps {
  items: PracticeF1List | undefined;
  removeWrapper?: boolean;
  gridArea?: string;
  isActive?: boolean;
}

const CustomerList = (props: ICustomerListProps) => {
  const dispatch = useDispatch();
  const { items, removeWrapper, gridArea, isActive } = props;
  const { t } = useTranslation();

  const { activeRole } = useSelector((state: RootState) => state.user);

  const { getAuthorization } = useAuthorization();
  const [retrivePracticesStatus] = useLazyGetPracticesStatusQuery();

  const [showAppointmentDialog, setShowAppointmentDialog] =
    useState<boolean>(false);

  const [updatedItems, setUpdatedItems] = useState<
    CustomerListItemType[] | undefined
  >(items as CustomerListItemType[]);

  const [practicesToMonitoringStatus, setPracticesToMonitoringStatus] =
    useState<number[]>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    setUpdatedItems(items as CustomerListItemType[]);
  }, [items]);

  useEffect(() => {
    const pageItems = items?.slice(
      pagination.pageIndex * pagination.pageSize,
      (pagination.pageIndex + 1) * pagination.pageSize
    );

    const updatedPracticesToMonitor = (
      pageItems?.filter((item) => item.id !== undefined) || []
    ).map((item) => item.id as number);

    setPracticesToMonitoringStatus(updatedPracticesToMonitor);
  }, [items, pagination.pageIndex, pagination.pageSize]);

  const getClaimTypeValue = (code: string | undefined): string => {
    const iCode = parseInt(code || "");
    let prefix = "";

    if (iCode >= 2 && iCode <= 8)
      prefix = t("appraisals-dashboard-to-manage-cvt") + " - ";
    return (
      prefix + t(`appraisals-details-tab-details-claim-type-${code}`) || ""
    );
  };

  const data = useMemo(
    () =>
      (items as CustomerListItemType[])?.map((item) => {
        const status = t(`appraisal-statuses-${item.status}`) || "";

        return {
          id: item.id,
          priority: t(
            `expertizer-dashboard-results-practices-priority-${item.priority}`
          )!,
          practiceNumberCompany: item.practiceNumberCompany || "",
          clientName: item.clientName || "",
          sxNumber: item.sxNumber || "",
          sxType: getClaimTypeValue(item.sxTypology),
          plate: item.plate || "",
          model: item.model || "",
          cap: item.appraisalLocationAddress?.postalCode,
          cityAndProvince: item.appraisalLocationAddress
            ? item.appraisalLocationAddress?.city +
              `(${item.appraisalLocationAddress?.province})`
            : "",
          expertDeadline: item.expertDeadline || "",
          status,
          statusCode: item.status,
          expertizerName: item.expertizerName,
          isBlocked: item.isBlocked,
          blockedBy: item.blockedBy,
          appraiserName: item.appraiserName,
          actions: "",
        } as CustomerResultItem;
      }) || [],
    [items]
  );

  const updatePracticesStatus = async () => {
    if (practicesToMonitoringStatus.length < 1) return;

    const response = await retrivePracticesStatus({
      authorization: await getAuthorization(),
      practices: practicesToMonitoringStatus,
      activeRole: activeRole!,
    });
    const statuses = response.data as PracticesProcessingStatus;

    if (response.isSuccess && statuses) {
      const _updatedItems = (
        (items as CustomerListItemType[])?.filter((i) => i.id !== undefined) ||
        []
      ).map((item) => {
        const status = statuses.find((s) => s.practiceId === item.id);

        if (status === undefined) return item;

        return {
          ...item,
          status: status.practiceStatusCode,
          blockedBy: status.blockedBy || "",
          isBlocked: status.blockedBy ? status.blockedBy.length > 0 : false,
        };
      });

      setUpdatedItems(_updatedItems);
    }
  };

  const {
    restart: restartRefrehInterval,
    reset: resetRefrehInterval,
    clear: clearRefreshInterval,
    percentage,
    remainingTime,
  } = useInterval({
    seconds: 10,
    callback: updatePracticesStatus,
  });

  useEffect(() => {
    return () => clearRefreshInterval();
  }, []);

  useEffect(() => {
    if (isActive) restartRefrehInterval();
    else clearRefreshInterval();
  }, [isActive]);

  const handleSelectRow = (row: CustomerResultItem) => {
    dispatch(
      addTab({
        key: 0,
        label: row.practiceNumberCompany,
        type: "customer-details",
        externalData: {
          idPractice: row.id,
        },
      })
    );
  };

  const resultsColumns = useMemo<MRT_ColumnDef<CustomerResultItem>[]>(
    () => [
      {
        accessorKey: "priority",
        header: t("expertizer-dashboard-results-col-priority"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.priority}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "practiceNumberCompany",
        header: t(
          "customer-dashboard-communications-list-header-id-assignment"
        ),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.practiceNumberCompany}
          </CellWrapper>
        ),
      },
      // {
      //   accessorKey: "clientName",
      //   header: t("expertizer-dashboard-results-col-client-name"),
      //   size: 50,
      //   Cell: ({ row }) => (
      //     <CellWrapper
      //       className={row.original.isBlocked ? "blocked-practice-row" : ""}
      //       onClick={() => handleSelectRow(row.original)}
      //     >
      //       <div title={row.original.clientName}>
      //         {truncText(row.original.clientName, 5)}
      //       </div>
      //     </CellWrapper>
      //   ),
      // },
      {
        accessorKey: "sxNumber",
        header: t("expertizer-dashboard-results-col-sx-number"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            <div title={row.original.sxNumber}>
              {truncText(row.original.sxNumber, 5)}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "sxType",
        header: t("expertizer-dashboard-results-col-sx-type"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.sxType}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "plate",
        header: t("expertizer-dashboard-results-col-plate"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.plate}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "model",
        header: t("expertizer-dashboard-results-col-model"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.model}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "cap",
        header: t("expertizer-dashboard-results-col-cap"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.cap}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "cityAndProvince",
        header: t("expertizer-dashboard-results-col-city-and-province"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.cityAndProvince}
          </CellWrapper>
        ),
      },
      // {
      //   accessorKey: "expertDeadline",
      //   header: t(
      //     "expertizer-dashboard-results-col-expertizer-expiration-date"
      //   ),
      //   size: 100,
      //   Cell: ({ row }) => (
      //     <CellWrapper
      //       className={row.original.isBlocked ? "blocked-practice-row" : ""}
      //       onClick={() => handleSelectRow(row.original)}
      //     >
      //       {row.original.expertDeadline}
      //     </CellWrapper>
      //   ),
      // },
      // {
      //   accessorKey: "status",
      //   header: t("expertizer-dashboard-results-col-status"),
      //   Cell: ({ row }) => (
      //     <CellWrapper
      //       className={row.original.isBlocked ? "blocked-practice-row" : ""}
      //       onClick={() => handleSelectRow(row.original)}
      //     >
      //       {row.original.status}
      //     </CellWrapper>
      //   ),
      // },
      {
        accessorKey: "appraiserName",
        header: t("appraisals-dashboard-results-appraisals-study"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.appraiserName ? "blocked-practice-row" : ""}
          >
            {row.original.appraiserName}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "isBlocked",
        header: t("appraisals-dashboard-results-appraisals-is-blocked"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
          >
            {row.original.isBlocked ? (
              <BlockedWrapper title={row.original.blockedBy}>
                <IconLocked />{" "}
              </BlockedWrapper>
            ) : (
              <></>
            )}
          </CellWrapper>
        ),
      },
    ],
    [updatedItems]
  );

  const renderTable = () => (
    <MaterialReactTable
      columns={resultsColumns}
      data={data}
      enableDensityToggle={false}
      enableColumnOrdering
      muiTableBodyCellProps={{
        sx: {
          textAlign: "left",
        },
      }}
      onPaginationChange={setPagination}
      state={{ pagination }}
    />
  );

  return (
    <>
      {removeWrapper ? (
        <CustomerListWithoutWrapper
          data-testid={testId.container}
          gridArea={gridArea}
        >
          {renderTable()}
        </CustomerListWithoutWrapper>
      ) : (
        <CustomerListWrapper data-testid={testId.container} gridArea={gridArea}>
          {renderTable()}
        </CustomerListWrapper>
      )}
    </>
  );
};

export default CustomerList;
