import React, { useMemo } from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Modifydetail,
  PracticeHistory,
  ReadOnlyPractice,
  useLazyGetPracticeHistoryQuery,
} from "../../../../../redux/apiSpecifications/apiCrud";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import {
  LoadingInTabWrapper,
  RefreshInTabWrapper,
  WidgetWrapper,
} from "../../../../../style/DashbordWidgetWrappers";
import { mainTabs } from "../../../../../config/testIds";
import { IconLoading } from "../../../../../config/icons";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import ModifyDescription from "./ModifyDescription/ModifyDescription";

const testIds = mainTabs.tabs.historicalActions;

const ContentWrapper = styled.div`
  position: relative;

  .positionLoading {
    position: absolute;
    top: 30%;
    left: 30%;
    zindex: 999;
  }
`;

const ListActions = styled(WidgetWrapper)`
  align-items: flex-start;
  max-width: 100%;
  overflow: auto;
  padding: 2em;

  .MuiPaper-root {
    box-shadow: none;
    width: 100%;
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiTableCell-root {
    padding: 0;
  }
`;

const CellWrapper = styled.div`
  cursor: pointer;
  padding: 1em 0;
`;

interface IAppraisalDetailsHistoricalActionsProps {
  practice: ReadOnlyPractice | undefined;
  defaultActiveKey?: string[];
  isActive?: boolean;
  activeKey?: string;
}

type PracticeHistoryTableRow = {
  modifyType?: string;
  modifyDescription?: string;
  modifyUser?: string;
  modifyTimestamp: string;
  modifyTimestampNumber: string;
  modifyDetailsJson?: Modifydetail[];
};

const timestampToNumber = (timestamp?: string) => {
  let splitted = timestamp?.split(" ");

  return (
    splitted![0].split("/").reverse().join("") +
    splitted![1].padStart(8, "0").replaceAll(":", "")
  );
};

const AppraisalDetailsHistoricalActions = (
  props: IAppraisalDetailsHistoricalActionsProps
) => {
  const { t } = useTranslation();

  const { practice, isActive, activeKey } = props;
  const defalultHistoricalActions: PracticeHistory = {};

  const [historical, setHistorical] = useState<PracticeHistory[]>([]);
  const [retrievePracticeHistory] = useLazyGetPracticeHistoryQuery();

  const { getAuthorization } = useAuthorization();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    loadData();
  }, [practice?.appraiser, isActive]);

  useEffect(() => {
    if (activeKey === "historical") {
      loadData();
    }
  }, [activeKey]);

  const loadData = async () => {
    setIsLoading(true);
    const { data, error, isSuccess } = await retrievePracticeHistory({
      authorization: await getAuthorization(),
      idPractice: practice?.id as number,
    });

    if (isSuccess && data) {
      setHistorical(data as PracticeHistory[]);
    } else {
      console.log(error);
    }
    setIsLoading(false);
  };

  const dataHistorical = useMemo(
    () =>
      historical?.map(
        (item: PracticeHistory) =>
          ({
            //modifyType: item.modifyType,
            modifyTimestamp: item.modifyTimestamp,
            modifyTimestampNumber: timestampToNumber(item.modifyTimestamp),
            modifyUser: item.modifyUser,
            modifyDescription: item.modifyDescription,
            modifyType: item.modifyType,
            modifyDetailsJson: item.modifyDetailsJson,
          } as PracticeHistoryTableRow)
      ) || [],
    [historical]
  );

  const resultsColumns = useMemo<MRT_ColumnDef<PracticeHistoryTableRow>[]>(
    () => [
      {
        accessorKey: "modifyTimestampNumber",
        header: t("appraisal-historical-tab-date"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => {}}>
            {row.original.modifyTimestamp}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "modifyUser",
        header: t("appraisal-historical-tab-user"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => {}}>
            {row.original.modifyUser}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "modifyDescription",
        header: t("appraisal-historical-tab-description"),
        size: 300,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => {}}>
            <ModifyDescription item={row.original} />
          </CellWrapper>
        ),
      },
    ],
    [historical]
  );

  return (
    <>
      {isLoading && (
        <RefreshInTabWrapper
          className="positionLoading"
          data-testid={testIds.loadingContainer}
        >
          <div className="box">{IconLoading}</div>
        </RefreshInTabWrapper>
      )}

      <ContentWrapper data-testid={testIds.container}>
        <ListActions data-testid={testIds.table}>
          <MaterialReactTable
            columns={resultsColumns}
            data={dataHistorical}
            enableDensityToggle={false}
            enableColumnOrdering
            muiTableBodyCellProps={{
              sx: {
                textAlign: "left",
              },
            }}
            onPaginationChange={setPagination}
            state={{ pagination }}
          />
        </ListActions>
      </ContentWrapper>
    </>
  );
};

export default AppraisalDetailsHistoricalActions;
