import { useState } from "react";
import { isNotEmpty } from "../../../../../../validation/common";
import { NewRequestFormModel } from "./NewRequestFormModel";
import { updateObject } from "../../../../../../utils/updateObject";
import { RequestHelper } from "../RequestHelper";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { useTranslation } from "react-i18next";

const useNewRequestForm = (requestCategoryCode: string) => {
    const { t } = useTranslation();

    const checkIfCanSubmit = (data: NewRequestFormModel) => {
        const { to, priority, categoryCode, object, text } = data;

        return (
            isNotEmpty(to) && isNotEmpty(priority) && isNotEmpty(categoryCode) && isNotEmpty(object)  && isNotEmpty(text) 
        );
    };

    const { activeRole } = useSelector(
        (rootState: RootState) => rootState.user
    );

    const [toList, setToList] = useState<number[]>(
        RequestHelper.GetRequestReceivers(activeRole!, requestCategoryCode)
    );

    const initState = (requestCategoryCode: string) => {
        let data: NewRequestFormModel = {
            to: toList.length === 1 ? toList[0].toString() : "",
            priority: "NU",
            categoryCode: requestCategoryCode,
            object: t(
                `practice-request-category-description-${requestCategoryCode}`
            ),
        };

        return {
            data,
            canSubmit: checkIfCanSubmit(data),
        };
    };

    const [state, setState] = useState(initState(requestCategoryCode));

    const handleDataChange = (value: any, field: string) => {
        const { data } = state;

        const updatedData = updateObject(data, value, field);

        setState({
            ...state,
            data: { ...updatedData },
            canSubmit: checkIfCanSubmit(updatedData),
        });
    };

    return {
        data: state.data,
        toList,
        canSubmit: state.canSubmit,
        handleDataChange,
    };
};

export default useNewRequestForm;
