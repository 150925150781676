import { Drawer } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import dekra from "../../style/dekra";
import { useEffect, useMemo, useState } from "react";
import { BiMenu } from "react-icons/bi";
import ConfigModal from "../ConfigModal";
import { drawerMenu as testIds } from "../../config/testIds";
import SelectActiveRole from "../Inputs/SelectActiveRole";
import azureConfigValues from "../../config/azureConfigValues";
import SelectLanguage from "../Inputs/SelectLanguage";
import { isLocalhost, isSandbox } from "../../utils/devHelper";
import { addTab } from "../../redux/features/mainTabsSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  CheckNewUsersApiResponse,
  CountNewUsers,
  ToggleUserActivationApiResponse,
  useLazyCheckNewUsersQuery,
} from "../../redux/apiSpecifications/apiFesf";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { addAppraiser } from "../../redux/features/appraisersSlice";

const {
  local: localhostConfig,
  sandbox: sandboxConfing,
  dev: devConfig,
  test: testConfig,
} = azureConfigValues;

const DrawerWrapper = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }

  .menu-items {
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-bottom: 2em;

    .menu-item {
      cursor: pointer;
      text-transform: uppercase;
      font-size: 1.2em;
      font-weight: bold;
      padding: 0.5em 0;
      color: ${dekra.primaryColor};
    }
  }

  .ant-row {
    flex-direction: column;

    .ant-form-item-label {
      text-align: left;
    }
  }
`;

const ButtonMenu = styled.div`
  color: white;
  background-color: ${dekra.primaryColor};
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 1em;

  cursor: pointer;
  line-height: 0;

  .icon {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 60%;
    svg {
      font-size: 2.6em;
    }
  }

  .txt {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 5px;
    height: 40%;
    text-transform: uppercase;
    font-size: 0.7em;
    line-height: 0;
  }
`;

const EnvironmentsWrapper = styled.div`
  self-justify: flex-end;
  .environment {
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }

  .localhost,
  .sandbox,
  .dev,
  .test {
    width: 1em;
    height: 1em;
    margin-right: 1em;
  }

  .localhost {
    background-color: ${localhostConfig.color};
  }

  .sandbox {
    background-color: ${sandboxConfing.color};
  }

  .dev {
    background-color: ${devConfig.color};
  }

  .test {
    background-color: ${testConfig.color};
  }
`;

const UserManagementLinkWrapper = styled.div`
  font-size: 1.2em;
  cursor: pointer;
  color: ${dekra.primaryColor};
  margin-top: 8px;
  display: flex;
  align-items: center;

  svg {
    font-size: 1.4em;
  }
`;

const DrawerMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { activeRole = "", authorization } = useSelector(
    (state: RootState) => state.user
  );

  const [open, setOpen] = useState(false);

  const [showNewUsers, setShowNewUsers] = useState(false);

  const handleToggleDrawer = () => setOpen(!open);

  const [retrieveCheckNewUsers] = useLazyCheckNewUsersQuery();

  const loadCheckNewUsers = async () => {
    const response = await retrieveCheckNewUsers({
      authorization: authorization,
      activeRole: activeRole,
    });

    const correctResponse = response as {
      data: CountNewUsers;
    };

    const errorResponse = response as {
      error: FetchBaseQueryError;
    };

    if (errorResponse && errorResponse.error) {
      setShowNewUsers(false);
    } else {
      setShowNewUsers(Number(correctResponse.data) > 0);
    }
  };

  useEffect(() => {
    if (["D2", "E1", "NF"].includes(activeRole)) {
      loadCheckNewUsers();
    }
  }, [activeRole]);

  const handleOpenRates = () => {
    dispatch(
      addTab({
        key: 0,
        label: t("rates-clients-tab-label"),
        type: "rates-clients",
        externalData: undefined,
      })
    );

    handleToggleDrawer();
  };

  const handleOpenFees = () => {
    dispatch(
      addTab({
        key: 0,
        label: t("fees-clients-tab-label"),
        type: "fees-clients",
        externalData: undefined,
      })
    );

    handleToggleDrawer();
  };

  const handleOpenMyAgenda = () => {
    dispatch(
      addAppraiser({
        id: -1,
        defaultSettings: {
          tab: "",
          agendaDate: "",
          agendaStatus: "",
          agendaReason: "",
          agendaProvince: "",
        },
      })
    );

    dispatch(
      addTab({
        key: 0,
        label: t("agenda-tab-my-agenda"),
        type: "my-agenda",
        externalData: undefined,
      })
    );

    handleToggleDrawer();
  };

  const handleClickUserManagement = () => {
    dispatch(
      addTab({
        key: 0,
        label: t("user-management-tab-label"),
        type: "user-management",
        externalData: {
          idPractice: 33,
        },
      })
    );

    handleToggleDrawer();
  };

  const handleOpenAgendaAppraisers = () => {
    dispatch(
      addTab({
        key: 0,
        label: t("agenda-tab-agenda-appraisers"),
        type: "agenda-appraisers",
        externalData: undefined,
      })
    );

    handleToggleDrawer();
  };

  const handleOpenConfigurationPanel = () => {
    dispatch(
      addTab({
        key: 0,
        label: t("tab-configuration-panel"),
        type: "configuration-panel",
        externalData: undefined,
      })
    );

    handleToggleDrawer();
  };

  const handleOpenManageReport = () => {
    dispatch(
      addTab({
        key: 0,
        label: t("manage-report-tab-label"),
        type: "manage-report",
        externalData: undefined,
      })
    );

    handleToggleDrawer();
  };

  const handleOpenRemindersPanel = () => {
    dispatch(
      addTab({
        key: 0,
        label: t("reminders-panel-label"),
        type: "reminders-panel",
        externalData: undefined,
      })
    );

    handleToggleDrawer();
  };

  const handleAppraisalExpirationDatesConfiguration = () => {
    dispatch(
      addTab({
        key: 0,
        label: t("tab-appraisal-expiration-dates-configuration"),
        type: "appraisal-expiration-dates-configuration",
        externalData: undefined,
      })
    );

    handleToggleDrawer();
  };

  const handleAssignmentMap = () => {
    dispatch(
      addTab({
        key: 0,
        label: t("assignment-map-tab-label"),
        type: "assignment-map",
        externalData: undefined,
      })
    );

    handleToggleDrawer();
  };

  const handleCoverageLevelConfiguration = () => {
    dispatch(
      addTab({
        key: 0,
        label: t("tab-coverage-level-configuration"),
        type: "coverage-level-configuration",
        externalData: undefined,
      })
    );

    handleToggleDrawer();
  };

  const handleExpertScore = () => {
    dispatch(
      addTab({
        key: 0,
        label: t("expert-score-tab-label"),
        type: "expert-score",
        externalData: undefined,
      })
    );

    handleToggleDrawer();
  };

  const showRatesClients = useMemo(() => {
    return ["D2", "E1", "NF", "G2"].includes(activeRole);
  }, [activeRole]);

  const showUserManagement = useMemo(() => {
    return ["D2", "E1", "NF", "C1", "A1"].includes(activeRole);
  }, [activeRole]);

  const showMyAgenda = useMemo(() => {
    return ["E2", "E3"].includes(activeRole);
  }, [activeRole]);

  const showAgendaAppraisers = useMemo(() => {
    return ["D1", "D2", "E1", "NF"].includes(activeRole);
  }, [activeRole]);

  const showConfigurationPanel = useMemo(() => {
    return ["NF"].includes(activeRole);
  }, [activeRole]);

  const showFeesClients = useMemo(() => {
    return ["G1"].includes(activeRole);
  }, [activeRole]);

  const showReportManagement = useMemo(() => {
    return ["G1", "G2"].includes(activeRole);
  }, [activeRole]);

  const showRemindersPanel = useMemo(() => {
    return ["NF", "E1"].includes(activeRole);
  }, [activeRole]);

  const showAppraisalExpirationDatesConfiguration = useMemo(() => {
    return ["D2", "NF"].includes(activeRole);
  }, [activeRole]);

  const showAssignmentMap = useMemo(() => {
    return ["D1", "D2", "E1", "E4"].includes(activeRole);
  }, [activeRole]);

  const showCoverageLevelConfiguration = useMemo(() => {
    return ["D2", "E1"].includes(activeRole);
  }, [activeRole]);

  const showExpertScore = useMemo(() => {
    return ["NF", "E1", "E4"].includes(activeRole);
  }, [activeRole]);

  const showPowerBIExternalLink = useMemo(() => {
    return ["D2", "H1"].includes(activeRole);
  }, [activeRole]);

  const powerBIExternalLink = t("power-bi-external-link");

  return (
    <>
      <ButtonMenu onClick={handleToggleDrawer} data-testid={testIds.buttonOpen}>
        <div className="icon">
          <BiMenu />
        </div>

        <div className="txt">{t("main-menu-label")}</div>
      </ButtonMenu>

      <DrawerWrapper
        placement="left"
        key="left"
        closable={true}
        open={open}
        onClose={handleToggleDrawer}
      >
        <div data-testid={testIds.buttonOpen} className="menu-items">
          {(isLocalhost() || isSandbox()) && <ConfigModal />}

          {showRatesClients && (
            <div className="menu-item" onClick={handleOpenRates}>
              {t("menu-item-rates")}
            </div>
          )}

          {showFeesClients && (
            <div className="menu-item" onClick={handleOpenFees}>
              {t("menu-item-fees")}
            </div>
          )}

          {showReportManagement && (
            <div className="menu-item" onClick={handleOpenManageReport}>
              {t("menu-item-manage-report")}
            </div>
          )}

          {showUserManagement && (
            <div className="menu-item" onClick={handleClickUserManagement}>
              {t("menu-item-user-management")}{" "}
              {showNewUsers && <span>(*)</span>}
            </div>
          )}

          {showMyAgenda && (
            <div className="menu-item" onClick={handleOpenMyAgenda}>
              {t("agenda-menu-my-agenda")}
            </div>
          )}

          {showAgendaAppraisers && (
            <div className="menu-item" onClick={handleOpenAgendaAppraisers}>
              {t("agenda-menu-agenda-appraisers")}
            </div>
          )}

          {showConfigurationPanel && (
            <div className="menu-item" onClick={handleOpenConfigurationPanel}>
              {t("tab-configuration-panel")}
            </div>
          )}

          {showAppraisalExpirationDatesConfiguration && (
            <div
              className="menu-item"
              onClick={handleAppraisalExpirationDatesConfiguration}
            >
              {t("menu-item-appraisal-expiration-dates-configuration")}
            </div>
          )}

          {showRemindersPanel && (
            <div className="menu-item" onClick={handleOpenRemindersPanel}>
              {t("menu-item-configuration-reminders-panel")}
            </div>
          )}

          {showAssignmentMap && (
            <div className="menu-item" onClick={handleAssignmentMap}>
              {t("menu-item-assignment-map")}
            </div>
          )}

          {showCoverageLevelConfiguration && (
            <div
              className="menu-item"
              onClick={handleCoverageLevelConfiguration}
            >
              {t("menu-item-coverage-level-configuration")}
            </div>
          )}

          {showExpertScore && (
            <div className="menu-item" onClick={handleExpertScore}>
              {t("menu-item-expert-score")}
            </div>
          )}

          {showPowerBIExternalLink && (
            <div className="menu-item" onClick={()=> window.open(powerBIExternalLink, "_blank")}>
              {t("menu-item-power-bi-external-link")}
            </div>
          )}
        </div>

        <div style={{ flex: 1 }}></div>

        <SelectActiveRole onChange={() => setOpen(false)} />

        <br />

        <SelectLanguage />

        <br />

        <EnvironmentsWrapper>
          <div className="environment">
            <div className="localhost"></div> localhost
          </div>
          <div className="environment">
            <div className="sandbox"></div> sandbox
          </div>
          <div className="environment">
            <div className="dev"></div> dev
          </div>
          <div className="environment">
            <div className="test"></div> test
          </div>
        </EnvironmentsWrapper>
      </DrawerWrapper>
    </>
  );
};

export default DrawerMenu;
