import React from "react";
import styled from "styled-components";
import { WidgetWrapper } from "../../../../../style/DashbordWidgetWrappers";
import { SearchedClaimsList } from "../../../../../redux/apiSpecifications/apiClaims";
import ClaimsList from "../../../../Widgets/Claims/ClaimsList";

const TableWrapper = styled(WidgetWrapper)`
  max-width: 100%;
  padding: 2em;

  .MuiPaper-root {
    box-shadow: none;
    width: 100%;
  }

  // .confirm-loader {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   width: 2em;
  //   height: 2em;
  // }

  // .MuiDialog-container {
  //   .MuiPaper-root {
  //     padding: 0 2em !important;
  //   }
  // }

  .MuiTableRow-head {
    .MuiInputBase-root {
      input {
        margin-left: 5px;
      }
    }
  }
`;

interface ClaimsTableProps {
  data: SearchedClaimsList;
  gridArea?: string;
  onPaginationChange: (pageIndex: number, pageSize: number) => void;
}

const ClaimsTable: React.FC<ClaimsTableProps> = ({
  data,
  gridArea,
  onPaginationChange,
}) => {
  return (
    <TableWrapper gridArea={gridArea}>
      <ClaimsList data={data} onPaginationChange={onPaginationChange} />
    </TableWrapper>
  );
};

export default ClaimsTable;
