import styled from "styled-components";
import { mainTabs } from "../../../../../config/testIds";
import {
  ReadOnlyPractice,
  RequestMessage,
  SecondaryPracticeRequest,
  useCloseRequestMutation,
  useConfirmRequestClosureCheckMutation,
  useCreatePracticeRequestMutation,
  useForwardRequestMutation,
  useLazyGetAllPracticeRequestsQuery,
  useLazyGetPracticeRequestQuery,
  useReplyToRequestMessageMutation,
} from "../../../../../redux/apiSpecifications/apiCrud";
import { ContentWrapper } from "../../Commons/Wrappers";
import {
  RefreshInTabWrapper,
  WidgetWrapper,
} from "../../../../../style/DashbordWidgetWrappers";
import { useTranslation } from "react-i18next";

import ButtonNewRequest from "./ButtonNewRequest";
import { useEffect, useMemo, useState } from "react";
import NewRequestModal from "./NewRequestModal/NewRequestModal";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import RequestsList, { RequestResultItem } from "./RequestsList";
import { IconLoading } from "../../../../../config/icons";
import ManageRequestModal from "./ManagePracticeRequestModal/ManagePracticeRequestModal";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

const ListActions = styled(WidgetWrapper)`
  display: block;
  align-items: flex-start;
  max-width: 100%;
  overflow: auto;
  padding: 2em;

  .MuiPaper-root {
    box-shadow: none;
    width: 100%;
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiTableCell-root {
    padding: 0;
  }
`;

const RequestsListWrapper = styled.div`
  margin-top: 16px;
`;

interface IAppraisalDetailsTabRequestsProps {
  practice: ReadOnlyPractice;
  // defaultActiveKey?: string[];
  isActive?: boolean;
  // onUpdatePractice: (practice: ReadOnlyPractice) => void;
  // disableMap?: boolean;
  // isTest?: boolean;
}

const testIds = mainTabs.tabs.activities;

const AppraisalDetailsTabRequests = ({
  practice,
  isActive,
}: IAppraisalDetailsTabRequestsProps) => {
  const { t } = useTranslation();

  const [newRequestKey, setNewRequestKey] = useState<string | null>(null);

  const [selectedRequest, setSelectedRequest] =
    useState<SecondaryPracticeRequest | null>(null);

  const [getAllPracticeRequests, resultGetAllPracticeRequests] =
    useLazyGetAllPracticeRequestsQuery();

  const [getPracticeRequest, resultGetPracticeRequest] =
    useLazyGetPracticeRequestQuery();

  const [createPracticeRequest] = useCreatePracticeRequestMutation();
  const [closeRequest] = useCloseRequestMutation();
  const [replyToRequestMessage] = useReplyToRequestMessageMutation();
  const [forwardRequest] = useForwardRequestMutation();
  const [confirmRequestClosureCheck] = useConfirmRequestClosureCheckMutation();

  const { getAuthorization } = useAuthorization();

  const { authorization, activeRole } = useSelector(
    (state: RootState) => state.user
  );

  useEffect(() => {
    if (practice?.id) loadData();
  }, [practice?.appraiser, isActive]);

  const loadData = async () => {
    const { data, error, isSuccess } = await getAllPracticeRequests({
      activeRole: activeRole!,
      authorization: await getAuthorization(),
      id: practice?.id as number,
    });

    if (isSuccess && data) {
    } else {
      console.log(error);
    }
  };

  const handleOnMenuClick = (key: string) => {
    setNewRequestKey(key);
  };

  const handleOnSendNewRequest = async (
    secondaryPracticeRequest: SecondaryPracticeRequest
  ) => {
    setNewRequestKey(null);

    const response = await createPracticeRequest({
      authorization: authorization,
      activeRole: activeRole!,
      id: practice?.id!,
      secondaryPracticeRequest: secondaryPracticeRequest,
    });

    const errorResponse = response as {
      error: FetchBaseQueryError | SerializedError;
    };

    if (errorResponse.error) {
      console.error(errorResponse.error);
    } else {
      await loadData();
    }
  };

  const handleOnCanceldNewRequest = () => {
    setNewRequestKey(null);
  };

  const handleManageRequest = async (request: RequestResultItem) => {
    const { data, error, isSuccess } = await getPracticeRequest({
      activeRole: activeRole!,
      authorization: await getAuthorization(),
      id: request?.id as number,
    });

    if (isSuccess && data) {
      setSelectedRequest(data);
    } else {
      console.log(error);
    }
  };

  const showRefresh = useMemo(() => {
    return (
      ["pending"].includes(resultGetAllPracticeRequests.status) ||
      ["pending"].includes(resultGetPracticeRequest.status)
    );
  }, [resultGetAllPracticeRequests, resultGetPracticeRequest]);

  const showRequestsList = useMemo(() => {
    return ["fulfilled"].includes(resultGetAllPracticeRequests.status);
  }, [resultGetAllPracticeRequests]);

  const handleOnCancelManageRequest = () => setSelectedRequest(null);

  const handleOnConfirmCloseRequest = async (
    requestMessage?: RequestMessage
  ) => {
    setSelectedRequest(null);

    const response = await closeRequest({
      authorization: authorization,
      activeRole: activeRole!,
      id: selectedRequest?.id!,
      requestMessage: requestMessage ?? ({} as RequestMessage),
    });

    const errorResponse = response as {
      error: FetchBaseQueryError | SerializedError;
    };

    if (errorResponse.error) {
      console.error(errorResponse.error);
    } else {
      await loadData();
    }
  };

  const handleOnConfirmForwardMessage = async (
    requestMessage: RequestMessage
  ) => {
    setSelectedRequest(null);

    const response = await forwardRequest({
      authorization: authorization,
      activeRole: activeRole!,
      id: selectedRequest?.id!,
      requestMessage: requestMessage,
    });

    const errorResponse = response as {
      error: FetchBaseQueryError | SerializedError;
    };

    if (errorResponse.error) {
      console.error(errorResponse.error);
    } else {
      await loadData();
    }
  };

  const handleOnConfirmReplyMessage = async (
    requestMessage: RequestMessage
  ) => {
    setSelectedRequest(null);

    const response = await replyToRequestMessage({
      authorization: authorization,
      activeRole: activeRole!,
      id: selectedRequest?.id!,
      requestMessage: requestMessage,
    });

    const errorResponse = response as {
      error: FetchBaseQueryError | SerializedError;
    };

    if (errorResponse.error) {
      console.error(errorResponse.error);
    } else {
      await loadData();
    }
  };

  const handleOnConfirmHaveReadMessage = async () => {
    setSelectedRequest(null);

    const response = await confirmRequestClosureCheck({
      authorization: authorization,
      activeRole: activeRole!,
      id: selectedRequest?.id!,
    });

    const errorResponse = response as {
      error: FetchBaseQueryError | SerializedError;
    };

    if (errorResponse.error) {
      console.error(errorResponse.error);
    } else {
      await loadData();
    }
  };

  return (
    <>
      {showRefresh && (
        <RefreshInTabWrapper>
          <div className="box">{IconLoading}</div>
        </RefreshInTabWrapper>
      )}

      <ContentWrapper data-testid={testIds.container}>
        <ListActions>
          <div>
            <ButtonNewRequest
              practice={practice}
              onMenuClick={handleOnMenuClick}
            />
          </div>
          {showRequestsList && (
            <RequestsListWrapper>
              <RequestsList
                items={resultGetAllPracticeRequests.data}
                onSelectItem={handleManageRequest}
              />
            </RequestsListWrapper>
          )}
        </ListActions>
      </ContentWrapper>

      {newRequestKey && (
        <NewRequestModal
          category={newRequestKey}
          onSend={handleOnSendNewRequest}
          onCancel={handleOnCanceldNewRequest}
        />
      )}

      {selectedRequest && (
        <ManageRequestModal
          practiceRequest={selectedRequest}
          onCancel={handleOnCancelManageRequest}
          onConfirmCloseRequest={handleOnConfirmCloseRequest}
          // onConfirmSendMessageAndCloseRequest={}
          onConfirmForwardMessage={handleOnConfirmForwardMessage}
          onConfirmReplyMessage={handleOnConfirmReplyMessage}
          onConfirmHaveReadMessage={handleOnConfirmHaveReadMessage}
        />
      )}
    </>
  );
};

export default AppraisalDetailsTabRequests;
