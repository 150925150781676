import { useEffect, useState } from "react";
import { IconLoading } from "../../../../config/icons";
import {
  LoadingInTabWrapper,
  RefreshInTabWrapper,
} from "../../../../style/DashbordWidgetWrappers";
import { AssignmentMapWrapper } from "../Maps/models";
import AppraiserMap from "../Maps/AppraiserMap";
import AppraiserPracticeList from "../Lists/AppraiserPracticeList/AppraiserPracticeList";
import AppraiserDetails from "../Details/AppraiserDetails/AppraiserDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  AppraisersByPracticeMapData,
  BulkAssignmentData,
  PracticesByAppraiserMapData,
  useAssignPracticesMutation,
  useLazyGetPracticesFromAssignmentMapByAppraiserQuery,
} from "../../../../redux/apiSpecifications/apiCrud";
import { Tenant } from "../../../../redux/apiSpecifications/apiFesf";
import { useNotifications } from "../../../../hooks/useNotifications";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

interface IAppraiserView {
  appraiserId: number;
  idZone: number;
  onClearSelection: () => void;
}

const AppraiserView = ({
  appraiserId,
  idZone,
  onClearSelection,
}: IAppraiserView) => {
  const { t } = useTranslation();

  const [loadingStatus, setLoadingStatus] = useState<
    "idle" | "loading" | "refreshing" | "load-in-progress"
  >("idle");

  const { authorization, activeRole, selectedTenants } = useSelector(
    (state: RootState) => state.user
  );

  const { displaySuccess, displayFetchBaseQueryErrorNotification } =
    useNotifications();

  const [getPracticesFromAssignmentMapByAppraiser] =
    useLazyGetPracticesFromAssignmentMapByAppraiserQuery();

  const [practicesByAppraiserMapData, setPracticesByAppraiserMapData] =
    useState<PracticesByAppraiserMapData | null>();

  const [assignPractices] = useAssignPracticesMutation();

  useEffect(() => {
    loadData(appraiserId, idZone);
  }, []);

  const loadData = async (appraiserId: number, idZone: number) => {
    setLoadingStatus("refreshing");

    const response = await getPracticesFromAssignmentMapByAppraiser({
      authorization: authorization,
      id: appraiserId,
      idZone: idZone,
      tenants: selectedTenants!.map((t: Tenant) => t.id!),
    });

    const data = response.data as PracticesByAppraiserMapData;

    if (data) {
      setPracticesByAppraiserMapData(data);
    } else {
      const errorResponse = response as {
        error: FetchBaseQueryError | SerializedError;
      };

      displayFetchBaseQueryErrorNotification(errorResponse.error);
    }

    setLoadingStatus("idle");
  };

  const handleAssignPractices = async (
    bulkAssignmentData: BulkAssignmentData
  ) => {
    setLoadingStatus("refreshing");

    const response = await assignPractices({
      authorization: authorization,
      bulkAssignmentData,
    });

    const errorResponse = response as {
      error: FetchBaseQueryError | SerializedError;
    };

    if (errorResponse.error) {
      displayFetchBaseQueryErrorNotification(errorResponse.error);
    } else {
      const selectedAppraiser = practicesByAppraiserMapData?.appraiser!;

      const response = await getPracticesFromAssignmentMapByAppraiser({
        authorization: authorization,
        id: selectedAppraiser.id!,
        idZone: selectedAppraiser.coverageDistricts?.[0]?.idzone,
        tenants: selectedTenants!.map((t: Tenant) => t.id!),
      });

      const data = response.data as PracticesByAppraiserMapData;

      if (data) {
        setPracticesByAppraiserMapData({
          ...practicesByAppraiserMapData,
          practices: data.practices,
        } as PracticesByAppraiserMapData);

        await loadData(appraiserId, idZone);

        displaySuccess(
          t("assignment-map-practices-assigned-successfully-message")
        );
      } else {
        const errorResponse = response as {
          error: FetchBaseQueryError | SerializedError;
        };

        displayFetchBaseQueryErrorNotification(errorResponse.error);
      }
    }

    setLoadingStatus("idle");
  };

  return (
    <div>
      {loadingStatus === "loading" && (
        <LoadingInTabWrapper>
          <div className="box">{IconLoading}</div>
        </LoadingInTabWrapper>
      )}
      {(loadingStatus === "refreshing" ||
        loadingStatus === "load-in-progress" ||
        loadingStatus === "idle") && (
        <>
          <AssignmentMapWrapper>
            <div className="col-left">
              {practicesByAppraiserMapData && (
                <AppraiserMap
                  appraiser={practicesByAppraiserMapData?.appraiser}
                  practices={practicesByAppraiserMapData?.practices}
                  onEditSettings={() => {}}
                />
              )}
            </div>
            <div className="col-right">
              {practicesByAppraiserMapData && (
                <>
                  <AppraiserDetails
                    appraiser={practicesByAppraiserMapData?.appraiser!}
                    onClearSelection={onClearSelection}
                  />

                  <AppraiserPracticeList
                    practicesByAppraiserMapData={practicesByAppraiserMapData!}
                    onAssignPractices={handleAssignPractices}
                  />
                </>
              )}
            </div>
          </AssignmentMapWrapper>

          {(loadingStatus === "refreshing" ||
            loadingStatus === "load-in-progress") && (
            <RefreshInTabWrapper>
              <div className="box">{IconLoading}</div>
            </RefreshInTabWrapper>
          )}
        </>
      )}
    </div>
  );
};
export default AppraiserView;
