import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MaterialReactTable from "material-react-table";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { addTab } from "../../../redux/features/mainTabsSlice";
import { widgets } from "../../../config/testIds";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { Request } from "../../../redux/apiSpecifications/apiCrud";
const testId = widgets.communicationsList;

const CommunicationListWrapper = styled(WidgetWrapper)`
  align-items: flex-start;
  max-width: 100%;
  overflow: auto;
  padding: 2em;
  flex: 1;

  .MuiPaper-root {
    box-shadow: none;
    width: 100%;

    th:last-child,
    td:last-child {
      width: 50px;
    }
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiTableCell-root {
    padding: 0;
  }
`;

const CommunicationListWithoutWrapper = styled.div<{ gridArea?: string }>`
  grid-area: ${(props) => props.gridArea};

  .MuiPaper-root {
    box-shadow: none;
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiTableCell-root {
    padding: 0;
  }
`;

const CellWrapper = styled.div`
  cursor: pointer;
  padding: 1em 0;
`;

const ExpandedRowWrapper = styled.div`
  padding: 1em;
  background-color: #f5f5f5;
`;

export type PracticeRequestResultItem = {
  id: number;
  idPractice: number;
  isUrgent: boolean;
  status: string; // Nuovo, Letto, Bozza, Inviato
  category: string; // Sollecito, Richiesta Aggiornamenti, Richiesta Accordo Conservativo, Altro
  from: string; // Nome e Cognome del mittente
  practiceNumber: string; // Numero pratica
  dateTime: string; // Data e ora di invio
  text: string; // Testo del messaggio
};

// truncate text to max n chars eventualli adding "..."
const truncText = (text: string | undefined, n: number) =>
  !text ? "" : text.length > n ? text.substring(0, n) + "..." : text;

interface IPracticeRequestsListProps {
  items: Request[] | undefined;
  removeWrapper?: boolean;
  gridArea?: string;
}

const PracticeRequestsList = (props: IPracticeRequestsListProps) => {
  const dispatch = useDispatch();
  const { items, removeWrapper, gridArea } = props;
  const { t } = useTranslation();

  const data = useMemo(
    () =>
      items?.map(
        (item) =>
          ({
            id: item.id,
            idPractice: item.idPractice,
            isUrgent: item.priorityCode !== "False",
            status: t(
              `practice-request-status-description-${item.statusCode}`
            )!,
            category: t(
              `practice-request-category-description-${item.categoryCode}`
            )!,
            from: t(
              `practice-request-user-profile-description-${item.senderType!}`
            )!,
            practiceNumber: item.practiceNumber || "",
            dateTime: item.messageDate || "",
            text: item.messageText || "",
          } as PracticeRequestResultItem)
      ) || [],
    [items]
  );

  const handleSelectRow = (row: PracticeRequestResultItem) => {
    dispatch(
      addTab({
        key: 0,
        label: row.practiceNumber,
        type: "appraisal-details",
        externalData: {
          idPractice: row.idPractice,
          defaultTab: "notifications",
        },
      })
    );
  };

  const resultsColumns = useMemo<MRT_ColumnDef<PracticeRequestResultItem>[]>(
    () => [
      {
        accessorKey: "isUrgent",
        header: t(`practice-requests-list-is-urgent-colum-header`),
        maxSize: 100,
        minSize: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            {row.original.isUrgent ? t(`switch-yes`) : ""}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "status",
        header: t(`appraisals-dashboard-communications-list-header-status`),
        maxSize: 100,
        minSize: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            {row.original.status}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "category",
        header: t("appraisals-dashboard-communications-list-header-category"),
        maxSize: 400,
        minSize: 400,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            <div title={row.original.practiceNumber}>
              {row.original.category}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "from",
        header: t("appraisals-dashboard-communications-list-header-from"),
        maxSize: 100,
        minSize: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            <div title={row.original.from}>{row.original.from}</div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "practiceNumber",
        header: t(
          "appraisals-dashboard-communications-list-header-practice-number"
        ),
        maxSize: 150,
        minSize: 150,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            {row.original.practiceNumber}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "dateTime",
        header: t("appraisals-dashboard-communications-list-header-date-time"),
        maxSize: 180,
        minSize: 180,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            {row.original.dateTime}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "text",
        header: t("appraisals-dashboard-communications-list-header-text"),
        maxSize: 1000,
        minSize: 350,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            {truncText(row.original.text, 30)}
          </CellWrapper>
        ),
      },
    ],
    []
  );

  const renderTable = () => (
    <MaterialReactTable
      columns={resultsColumns}
      data={data}
      enableDensityToggle={false}
      muiTableBodyCellProps={{
        sx: {
          textAlign: "left",
        },
      }}
      positionExpandColumn="last"
      renderDetailPanel={({ row }) => (
        <ExpandedRowWrapper>{row.original.text}</ExpandedRowWrapper>
      )}
    />
  );

  return removeWrapper ? (
    <CommunicationListWithoutWrapper
      data-testid={testId.container}
      gridArea={gridArea}
    >
      {renderTable()}
    </CommunicationListWithoutWrapper>
  ) : (
    <CommunicationListWrapper
      data-testid={testId.container}
      gridArea={gridArea}
    >
      {renderTable()}
    </CommunicationListWrapper>
  );
};

export default PracticeRequestsList;
