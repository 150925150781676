import { LocalStorageKeys } from "../config/const";
import { ActiveClaimStateType } from "../types/claim.types";
import {
	addAttachment,
	forceSerialize as forceSerializeSubject,
	removeAttachment,
	updateSubject as updateActiveSubject,
	clearLocalStorage as clearLocalStorageSubject,
} from "./features/activeSubject";

import {
	clearLocalStorage as clearLocalStorageClaim,
	clear,
	setDataEntryStatus,
	setPolicyData,
	updateCardWizardData,
	updateClaimData,
	updateCounterpartData,
	updateResponsabilityData,
	setDamagedPart,
	removeDamagedPart,
	addDamagedPart,
	setAdditionalInfo,
	deleteAdditionalInfo,
	forceSerialize as forceSerializeClaim,
} from "./features/activeClaimSlice";
import { RootState } from "./store";
import { DamagedPart } from "./apiSpecifications/apiCrud";
import { setConfigParams } from "./features/userSlice";

export const localstorageMiddleware = (store: any) => (next: any) => (action: any) => {
	const getState = (): RootState => store.getState();
	try {
		// CLEAR SUBJECT LOCAL STORAGE
		if (clear.match(action) || clearLocalStorageSubject.match(action)) {
			localStorage.removeItem(LocalStorageKeys.activeSubject.subject);
			localStorage.removeItem(LocalStorageKeys.activeSubject.attachments);
		} // SET POLICY DATA
		else if (setPolicyData.match(action)) {
			localStorage.setItem(LocalStorageKeys.activeClaim.policyData, JSON.stringify(action.payload));
		} // UPDATE STEPPER DATA
		else if (updateCardWizardData.match(action)) {
			localStorage.setItem(LocalStorageKeys.activeClaim.stepperData, JSON.stringify(action.payload));
		} // UPDATE CLAIM DATA
		else if (updateClaimData.match(action)) {
			localStorage.setItem(LocalStorageKeys.activeClaim.claimData, JSON.stringify(action.payload));
		} // UPDATE COUNTER PART DATA
		else if (updateCounterpartData.match(action)) {
			localStorage.setItem(LocalStorageKeys.activeClaim.counterpartData, JSON.stringify(action.payload));
		} // SET RESPONSABIITY DATA
		else if (updateResponsabilityData.match(action)) {
			localStorage.setItem(LocalStorageKeys.activeClaim.responsability, JSON.stringify(action.payload));
		} // SET DAMAGED PART
		else if (setDamagedPart.match(action)) {
			const damagedPartsTxt = localStorage.getItem(LocalStorageKeys.activeClaim.damagedParts);
			let damagedParts: DamagedPart[] = [];
			if (damagedPartsTxt?.length && damagedPartsTxt.length > 0) {
				damagedParts = JSON.parse(damagedPartsTxt) as DamagedPart[];
			}

			if (damagedParts?.length === 0 && action.payload.index === 0) {
				damagedParts = [action.payload.damagedPart];
			} else if (action.payload.index >= damagedParts?.length) {
				damagedParts = [...damagedParts, action.payload.damagedPart];
			} else {
				damagedParts[action.payload.index] = action.payload.damagedPart;
			}
			localStorage.setItem(LocalStorageKeys.activeClaim.damagedParts, JSON.stringify(damagedParts));
		} // REMOVE DAMAGED PART
		else if (removeDamagedPart.match(action)) {
			const damagedPartsTxt = localStorage.getItem(LocalStorageKeys.activeClaim.damagedParts);
			let damagedParts: DamagedPart[] = [];
			if (damagedPartsTxt?.length && damagedPartsTxt.length > 0) {
				damagedParts = JSON.parse(damagedPartsTxt) as DamagedPart[];
			}
			const udpatedDamagedParts = damagedParts.filter((dp, i) => i !== action.payload);

			localStorage.setItem(LocalStorageKeys.activeClaim.damagedParts, JSON.stringify(udpatedDamagedParts));
		} // ADD DAMAGED PART
		else if (addDamagedPart.match(action)) {
			const damagedPartsTxt = localStorage.getItem(LocalStorageKeys.activeClaim.damagedParts);
			let damagedParts: DamagedPart[] = [];
			if (damagedPartsTxt?.length && damagedPartsTxt.length > 0) {
				damagedParts = JSON.parse(damagedPartsTxt) as DamagedPart[];
			}

			localStorage.setItem(LocalStorageKeys.activeClaim.damagedParts, JSON.stringify([...damagedParts, {}]));
		} // SET ADDITIONAL INFO
		else if (setAdditionalInfo.match(action)) {
			// const additionalInfoTxt = localStorage.getItem(LocalStorageKeys.activeClaim.additionalInfo);
			// let additionalInfo: AdditionalInfoDataType[] = [];
			// if (additionalInfoTxt?.length && additionalInfoTxt.length > 0) {
			// 	additionalInfo = JSON.parse(additionalInfoTxt);
			// }
			// if (action.payload.index === -1) {
			// 	additionalInfo.push(action.payload.additionalInfo);
			// } else {
			// 	additionalInfo[action.payload.index] = action.payload.additionalInfo;
			// }
			// localStorage.setItem(LocalStorageKeys.activeClaim.additionalInfo, JSON.stringify(additionalInfo));
		} // REMOVE ADDITIONAL INFO
		else if (deleteAdditionalInfo.match(action)) {
			// const additionalInfoTxt = localStorage.getItem(LocalStorageKeys.activeClaim.additionalInfo);
			// let additionalInfo: AdditionalInfoDataType[] = [];
			// if (additionalInfoTxt?.length && additionalInfoTxt.length > 0) {
			// 	additionalInfo = JSON.parse(additionalInfoTxt);
			// 	additionalInfo = additionalInfo.filter((ai, i) => i !== action.payload);
			// }
			// localStorage.setItem(LocalStorageKeys.activeClaim.additionalInfo, JSON.stringify(additionalInfo));
		} // UPDATE ACTIVE CLAIM
		else if (updateActiveSubject.match(action)) {
			localStorage.setItem(LocalStorageKeys.activeSubject.subject, JSON.stringify(action.payload));
		} // FORCE SERIALIZE ACTIVE SUBJECT
		else if (forceSerializeSubject.match(action)) {
			localStorage.setItem(LocalStorageKeys.activeSubject.subject, JSON.stringify(getState().activeSubject.subject));
			localStorage.setItem(LocalStorageKeys.activeSubject.attachments, JSON.stringify(getState().activeSubject.attachments));
		} // ADD DOCUMENT'S ATTACHMENT
		else if (addAttachment.match(action)) {
			const { docIndex, attachment } = action.payload;
			const newAttachmets = getState().activeSubject.attachments.map((docAttachments, i) =>
				i === docIndex ? [...docAttachments, attachment] : docAttachments
			);

			localStorage.setItem(LocalStorageKeys.activeSubject.attachments, JSON.stringify(newAttachmets));
		} // REMOVE DOCUMENT'S ATTACHMENT
		else if (removeAttachment.match(action)) {
			const { docIndex, attachmentIndex } = action.payload;
			const newAttachmets = getState().activeSubject.attachments.map((docAttachments, i) =>
				i !== docIndex ? docAttachments : docAttachments.filter((_, ai) => ai !== attachmentIndex)
			);
			localStorage.setItem(LocalStorageKeys.activeSubject.attachments, JSON.stringify(newAttachmets));
		} // FORCE SERIALIZE ACTIVE CLAIM
		else if (forceSerializeClaim.match(action)) {
			const { claimData = {}, counterpartData = {}, responsability = {} } = getState().activeClaim;

			localStorage.setItem(LocalStorageKeys.activeClaim.claimData, JSON.stringify(claimData));

			localStorage.setItem(LocalStorageKeys.activeClaim.counterpartData, JSON.stringify(counterpartData));

			localStorage.setItem(LocalStorageKeys.activeClaim.responsability, JSON.stringify(responsability));
		}
		// CLEAR CLAIM LOCAL STORAGE
		else if (clear.match(action) || clearLocalStorageClaim.match(action)) {
			localStorage.removeItem(LocalStorageKeys.activeClaim.claimData);
			localStorage.removeItem(LocalStorageKeys.activeClaim.stepperData);
			localStorage.removeItem(LocalStorageKeys.activeClaim.counterpartData);
			localStorage.removeItem(LocalStorageKeys.activeClaim.responsability);
			localStorage.removeItem(LocalStorageKeys.activeClaim.policyData);
			localStorage.removeItem(LocalStorageKeys.activeClaim.damagedParts);
			localStorage.removeItem(LocalStorageKeys.activeClaim.additionalInfo);
		}
		// SET CONFIG PARAMS
		else if (setConfigParams.match(action)) {
			localStorage.setItem(LocalStorageKeys.configParams, JSON.stringify(action.payload));
		}
	} catch (err) {
		console.log(err);
	}
	return next(action);
};
