import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import adzValidIcon from "../../../../../../images/adz.png";
import adzInvalidIcon from "../../../../../../images/adz-invalid.png";
import adzEmptyIcon from "../../../../../../images/adz-empty.png";
import { Tooltip } from "antd";
import { IoMdEye } from "react-icons/io";
import { FaFileDownload } from "react-icons/fa";
import dekra from "../../../../../../style/dekra";
import { PracticeAttachment } from "../../../../../../redux/apiSpecifications/apiCrud";
import { addMimeType } from "../../../../../../utils/base64";

// Styled components

const ADZPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  img {
    max-width: 100px;
    max-height: 60px;
  }

  .icon-label {
    margin-top: 1em;
  }
`;

const IconWrapper = styled.div`
  color: ${dekra.primaryColor};
  cursor: pointer;
  font-size: 1.6em;
`;

const PreviewWrapper = styled.div`
  width: 100px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const FileOverlay = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  opacity: 0;

  &:hover {
    z-index: 4;
    background-color: #fff;
    box-shadow: 0 0 5px #aaa;
    border-radius: 5px;
    opacity: 1;
  }
`;

export type ADZPreviewStatuses = "empty" | "valid" | "invalid";

/**
 * @desccription
 * @interface IAdzPreviewProps
 * @property {ADZPreviewStatuses} isValid - status of the ADZ file
 * @property {PracticeAttachment} adzDocument - ADZ document file
 */

interface IAdzPreviewProps {
  status?: ADZPreviewStatuses;
  adzDocument: PracticeAttachment | undefined;
}

/**
 * AdzPreview component
 *
 * @component
 * @example
 * <ADZPreview status={"empty"} />
 */
const ADZPreview: React.FC<IAdzPreviewProps> = ({ status, adzDocument }) => {
  // COMMON VARIABLES
  const { t } = useTranslation();

  // Local state
  const refDownload = React.useRef<HTMLAnchorElement>(null);

  const { label, icon } = useMemo(() => {
    switch (status) {
      case "empty":
        return {
          label: t("appraisals-conclude-practice-adz-empty"),
          icon: adzEmptyIcon,
        };
        break;
      case "valid":
        return {
          label: t("appraisals-conclude-practice-adz-valid"),
          icon: adzValidIcon,
        };
        break;
      case "invalid":
        return {
          label: t("appraisals-conclude-practice-adz-invalid"),
          icon: adzInvalidIcon,
        };
    }

    return {};
  }, [status]);

  const handleDownload = async () => {
    if (adzDocument?.base64File) {
      refDownload?.current?.click();
    }
  };

  const hrefContent = useMemo(() => {
    if (!adzDocument) return;

    return addMimeType(
      adzDocument!.base64File || "",
      adzDocument?.originalName || ""
    );
  }, [adzDocument]);

  return (
    <ADZPreviewWrapper>
      <PreviewWrapper>
        {status === "valid" && (
          <FileOverlay>
            <IconWrapper onClick={handleDownload}>
              <Tooltip
                title={t(
                  "appraisal-details-tab-documents-icon-download-tooltip"
                )}
              >
                <FaFileDownload />
              </Tooltip>
            </IconWrapper>
          </FileOverlay>
        )}
        <div className="icon-and-label">
          <img src={icon} />
        </div>
      </PreviewWrapper>

      <div className="icon-label">{label}</div>

      <a
        style={{ display: "none" }}
        ref={refDownload}
        download={adzDocument?.originalName}
        href={hrefContent}
      >
        download
      </a>
    </ADZPreviewWrapper>
  );
};

export default ADZPreview;
