import { useTranslation } from "react-i18next";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import { useMemo } from "react";

type RegisteredLetterReceiptDescriptionDetails = {
    registered_letter_result: string;
    registered_letter_receipt_type: string;
    registered_letter_failure_reason: string;
};

interface IRegisteredLetterReceiptDescriptionProps {
    modifyDetailsJson: Modifydetail[];
}

const RegisteredLetterReceiptDescription = ({
    modifyDetailsJson,
}: IRegisteredLetterReceiptDescriptionProps) => {
    const { t } = useTranslation();

    const registeredLetterReceiptDescriptionDetails: RegisteredLetterReceiptDescriptionDetails =
        useMemo(() => {
            return {
                registered_letter_result:
                    modifyDetailsJson.find(
                        (_) => _.key === "registered_letter_result"
                    )?.value ?? "",
                registered_letter_receipt_type:
                    modifyDetailsJson.find(
                        (_) => _.key === "registered_letter_receipt_type"
                    )?.value ?? "",
                registered_letter_failure_reason:
                    modifyDetailsJson.find(
                        (_) => _.key === "registered_letter_failure_reason"
                    )?.value ?? "",
            } as RegisteredLetterReceiptDescriptionDetails;
        }, [modifyDetailsJson]);

    return (
        <>
            {t(
                `historical-actions-registered-letter-receipt-${registeredLetterReceiptDescriptionDetails.registered_letter_result}-${registeredLetterReceiptDescriptionDetails.registered_letter_receipt_type}-description`,
                {
                    registered_letter_failure_reason: registeredLetterReceiptDescriptionDetails.registered_letter_failure_reason
                }
            )}
        </>
    );
};

export default RegisteredLetterReceiptDescription;
