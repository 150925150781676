import { baseSplitApiDocs as api } from "./config/baseApiDocs";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadAttachment: build.mutation<
      UploadAttachmentApiResponse,
      UploadAttachmentApiArg
    >({
      query: (queryArg) => ({
        url: `/attachments`,
        method: "POST",
        body: queryArg.documentFiles,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    downloadAttachmentById: build.query<
      DownloadAttachmentByIdApiResponse,
      DownloadAttachmentByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/attachments/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
        params: { fileName: queryArg.fileName },
      }),
    }),
    deleteAttachment: build.mutation<
      DeleteAttachmentApiResponse,
      DeleteAttachmentApiArg
    >({
      query: (queryArg) => ({
        url: `/attachments/${queryArg.id}`,
        method: "DELETE",
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    downloadAttachmentZipById: build.query<
      DownloadAttachmentZipByIdApiResponse,
      DownloadAttachmentZipByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/attachments/${queryArg.id}/zip`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAttachmentFileThumbnail: build.query<
      GetAttachmentFileThumbnailApiResponse,
      GetAttachmentFileThumbnailApiArg
    >({
      query: (queryArg) => ({
        url: `/attachments/${queryArg.id}/thumbnail`,
        params: {
          activeRole: queryArg.activeRole,
          fileName: queryArg.fileName,
          width: queryArg.width,
          height: queryArg.height,
        },
      }),
    }),
    downloadAttachmentByDocumentCode: build.query<
      DownloadAttachmentByDocumentCodeApiResponse,
      DownloadAttachmentByDocumentCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/all-attachments/${queryArg.documentCode}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    downloadPracticeAttachments: build.query<
      DownloadPracticeAttachmentsApiResponse,
      DownloadPracticeAttachmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/attachments/all-practice-attachments/${queryArg.id}`,
        params: {
          activeRole: queryArg.activeRole,
          documentCategory: queryArg.documentCategory,
        },
      }),
    }),
    getZipFileList: build.query<
      GetZipFileListApiResponse,
      GetZipFileListApiArg
    >({
      query: (queryArg) => ({
        url: `/attachments/${queryArg.id}/zip-file-list`,
        params: {
          activeRole: queryArg.activeRole,
          fileContentType: queryArg.fileContentType,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiDocs };
export type UploadAttachmentApiResponse =
  /** status 201 The new item is created */ {
    id?: number;
    message?: string;
  };
export type UploadAttachmentApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Alphanumeric Id of each document resource (Unique identifier for all TPA modules) */
  documentCode: string;
  /** Upload a file related to a document */
  documentFiles: DocumentFiles;
};
export type DownloadAttachmentByIdApiResponse =
  /** status 200 a base64File string containing the downloaded file */
    | AttachmentFile
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type DownloadAttachmentByIdApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** the name of the file */
  fileName?: string;
};
export type DeleteAttachmentApiResponse =
  /** status 200 The item is deleted */ {
    id?: number;
    message?: string;
  };
export type DeleteAttachmentApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type DownloadAttachmentZipByIdApiResponse =
  /** status 200 a base64File string containing the downloaded file */
    | AttachmentFile
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type DownloadAttachmentZipByIdApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type GetAttachmentFileThumbnailApiResponse =
  /** status 200 a base64File string containing the thumbnail */
    | FileThumbnail
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetAttachmentFileThumbnailApiArg = {
  /** Numeric Id of the resource */
  id: number;
  /** the active role selected by the user */
  activeRole: string;
  /** the name of the file */
  fileName?: string;
  /** width of the image */
  width?: number;
  /** height of the image */
  height?: number;
};
export type DownloadAttachmentByDocumentCodeApiResponse =
  /** status 200 a base64File string containing the downloaded file */
    | AttachmentFile
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type DownloadAttachmentByDocumentCodeApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Alphanumeric Id of each document resource (Unique identifier for all TPA modules) */
  documentCode: string;
};
export type DownloadPracticeAttachmentsApiResponse =
  /** status 200 a base64File string containing the downloaded file */
    | AttachmentFile
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type DownloadPracticeAttachmentsApiArg = {
  /** Numeric Id of the resource */
  id: number;
  /** the active role selected by the user */
  activeRole: string;
  /** the category of the document */
  documentCategory?: string;
};
export type GetZipFileListApiResponse =
  /** status 200 a list of the files contained in the zip file */
    | ZipFileItem[]
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type GetZipFileListApiArg = {
  /** Numeric Id of the resource */
  id: number;
  /** the active role selected by the user */
  activeRole: string;
  /** indicates what type of files should be searched for */
  fileContentType?: string;
};
export type Schema = {
  errorCode?: string;
  errorMessage?: string;
};
export type AttachmentFile = {
  id?: number;
  name?: string;
  originalName?: string;
  documentBasePath?: string;
  base64File?: string;
};
export type DocumentFiles = {
  documentCode?: string;
  documentBasePath?: string;
  attachmentsFiles?: AttachmentFile[];
};
export type FileThumbnail = {
  id?: number;
  fileName?: string;
  base64File?: string;
  width?: number;
  height?: number;
};
export type ZipFileItem = {
  filePath?: string;
  fileName?: string;
};
export const {
  useUploadAttachmentMutation,
  useDownloadAttachmentByIdQuery,
  useLazyDownloadAttachmentByIdQuery,
  useDeleteAttachmentMutation,
  useDownloadAttachmentZipByIdQuery,
  useLazyDownloadAttachmentZipByIdQuery,
  useGetAttachmentFileThumbnailQuery,
  useLazyGetAttachmentFileThumbnailQuery,
  useDownloadAttachmentByDocumentCodeQuery,
  useLazyDownloadAttachmentByDocumentCodeQuery,
  useDownloadPracticeAttachmentsQuery,
  useLazyDownloadPracticeAttachmentsQuery,
  useGetZipFileListQuery,
  useLazyGetZipFileListQuery,
} = injectedRtkApi;
