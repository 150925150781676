import React, { useEffect, useState } from "react";

import { Input, Modal } from "antd";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Address } from "../../redux/apiSpecifications/apiCrud";
import { Urls } from "../../config/urlBuilder";
import dekra from "../../style/dekra";
import { IconCancel, IconLoading } from "../../config/icons";
import { ButtonCancel, ButtonConfirm } from "../Layout/Buttons";
import { InputTextStyled } from "../../style/Input";
import { checkPostalCode, isNotEmpty } from "../../validation/common";
import { Address as testIds } from "../../config/testIds";
import AddressFields, { AddressFieldsOptions } from "../Inputs/AddressFields";
import { getAzureMapAddressConfig } from "../../config/azureConfig";

const AddressWrapper = styled.div`
  flex: 1;

  .resume-text {
    cursor: pointer;

    .select {
      color: ${dekra.primaryColor};
      font-weight: bold;
    }
  }

  input {
    cursor: pointer;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4em 4em 2em 4em;
  z-index: 1;
  position: relative;

  .button-wrapper {
    width: 10em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em 2em 0 2em;
    z-index: 101;

    .loader {
      width: 6em;
      height: 6em;
    }
  }

  .selected-address {
    margin-top: 3em;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;
    margin-top: 2em;
  }
`;

export const AutocompleteWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 3em;
  z-index: 100;
`;

export const SearchBox = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .clear-query {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #aaa;
    font-size: 1.4em;
  }
`;

export const Results = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  position: absolute;
  width: 100%;
  top: 2.2em;
  left: 0;
  border: 2px solid #ddd;
`;

export const ResultItem = styled.div`
  display: grid;
  grid-template-columns: auto 15% 15%;
  cursor: pointer;
  padding: 0.5em 1em;

  &:hover {
    background-color: antiquewhite;
  }
`;

export const checkAddress = (
  address: Address,
  options?: AddressFieldsOptions
): boolean => {
  const { street, civic, city, postalCode, province, country } = address;
  
  return (
    isNotEmpty(street) &&
    ((options?.civicMandatory === true) ? isNotEmpty(civic) : true) &&
    // isNotEmpty(civic) &&
    isNotEmpty(city) &&
    checkPostalCode(postalCode?.toString() || "") &&
    isNotEmpty(province) &&
    isNotEmpty(country)
  );
};

const fullSelectedAddress = (address: Address) => {
  const street = address.street ? `${address.street} ` : "";
  const civic = address.civic ? `${address.civic} ` : "";
  const city = address.city ? `${address.city} ` : "";
  const postalCode = address.postalCode ? `${address.postalCode} ` : "";
  const province = address.province ? `${address.province} ` : "";
  const country = address.country ? `${address.country} ` : "";

  return `${street}${civic}${city}${postalCode}${province}${country}`;
};

interface IAddressAutocompleteProps {
  readOnly?: boolean;

  labelPlaceholder?: string | undefined | null;
  labelSearchBox?: string | undefined | null;
  tooltipPlaceholder?: string | undefined | null;
  tooltipSearchBox?: string | undefined | null;
  placeholder?: string | undefined | null;
  placeholderSearchbox?: string | undefined | null;

  address: Address | undefined;
  onChange: (address: Address) => void;
  countries?: string;
  dataTestId?: string | undefined;
  required?: boolean;
  options?: AddressFieldsOptions;
}

const AddressAutocomplete = (props: IAddressAutocompleteProps) => {
  const { options = { civicMandatory: true } } = props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [query, setQuery] = useState<string | undefined>();

  const [results, setResults] = useState<Address[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<Address | undefined>(
    props.address
  );

  const [isValid, setIsValid] = useState(false);
  const [execSearch, setExecSearch] = useState(0);

  const [timeoutSearch, setTimeoutSearch] =
    useState<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    setSelectedAddress(props.address);
    setIsValid(checkAddress(props.address || {}, props.options));
  }, [props.address, props.options]);

  const handleOpenModal = () => {
    if (!props.readOnly) {
      setIsModalOpen(true);
      setQuery(undefined);
      setResults([]);
    }
  };

  const handleClearQuery = () => {
    handleQueryChange("");
    setResults([]);
  };

  const handleSelectAddress = (address: Address) => {
    const newAddress = { ...address, id: selectedAddress?.id };
    console.info(newAddress);
    setSelectedAddress(newAddress);
    setIsValid(checkAddress(newAddress, options));
    setResults([]);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setIsSearching(false);
    setSelectedAddress(props.address);
  };

  const handleModalConfirm = () => {
    props.onChange(selectedAddress!);
    setIsModalOpen(false);
  };

  const handleQueryChange = (q: string) => {
    setQuery(q);
    if (q.length > 5) {
      if (timeoutSearch) clearTimeout(timeoutSearch);
      const newInterval = setTimeout(() => setExecSearch(Date.now()), 500);
      setTimeoutSearch(newInterval);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [execSearch]);

  const handleKeyup = (e: React.KeyboardEvent) => {
    if (e.code === "Enter") handleSearch();
  };

  const handleSearch = async () => {
    if (!query) return;

    setIsSearching(true);

    const url = Urls.azureAddress(query);

    try {
      const responseIT = await fetch(url + "&countrySet=IT");
      const dataIT = await responseIT.json();
      const addressesIT: any[] = dataIT.results;

      const azureMapAddressConfig = getAzureMapAddressConfig();

      if (addressesIT.length < azureMapAddressConfig.defaultLimit) {
        const response = await fetch(url);
        const data = await response.json();
        const addresses = data.results;

        addresses.forEach((address: any) => {
          const resultFind = addressesIT.find((_) => _.id === address.id);

          if (!resultFind) {
            addressesIT.push(address);
          }
        });
      }

      if (addressesIT.length > 0) {
        const newResults = addressesIT.map(
          (r: any) =>
            ({
              country: r.address.country,
              province: r.address.countrySecondarySubdivision,
              fullAddress: r.address.freeformAddress,
              city: r.address.municipality,
              street: r.address.streetName,
              civic: r.address.streetNumber,
              postalCode: r.address.postalCode,
              latitude: r.position.lat,
              longitude: r.position.lon,
            } as Address)
        );

        setResults(newResults);
      }
    } catch (e) {
      console.log(e);
    }

    setIsSearching(false);
  };

  const handleOnChangeAddress = (address: Address, isValid: boolean) => {
    setSelectedAddress(address);
    setIsValid(isValid);
  };

  const label = props.labelPlaceholder + (props.required ? " *" : "");

  return (
    <AddressWrapper data-testid={testIds.container}>
      <div className="resume-text" onClick={handleOpenModal}>
        {selectedAddress && (
          <InputTextStyled
            label={label}
            placeholder={props.placeholder}
            readOnly={true}
            value={fullSelectedAddress(selectedAddress)}
            dataTestId={testIds.fullAddress}
            disabled={props.readOnly}
          />
        )}

        {!selectedAddress && (
          <span data-testid={testIds.fullAddress} className="select">
            {t("address-automplete-select-label")}
          </span>
        )}
      </div>

      <Modal
        open={isModalOpen}
        footer={null}
        onCancel={handleModalCancel}
        width={700}
      >
        <ModalContent>
          {isSearching && (
            <div className="loader-wrapper">
              <div className="loader"> {IconLoading}</div>
            </div>
          )}
          
          <AutocompleteWrapper>
            <SearchBox>
              <Input
                placeholder={t("address-automplete-input-placeholder")!}
                onChange={(e) => handleQueryChange(e.currentTarget.value)}
                readOnly={isSearching}
                value={query}
                onKeyUp={handleKeyup}
                addonAfter={
                  <div className="clear-query" onClick={handleClearQuery}>
                    <IconCancel />
                  </div>
                }
                data-testid={testIds.autocomplete}
              />
            </SearchBox>

            {!isSearching && results && results.length > 0 && (
              <Results data-testid={testIds.results.container}>
                {results.map((r, i) => (
                  <ResultItem key={i} onClick={() => handleSelectAddress(r)}>
                    {fullSelectedAddress(r)}
                  </ResultItem>
                ))}
              </Results>
            )}
          </AutocompleteWrapper>
          
          <AddressFields
            onChange={handleOnChangeAddress}
            address={selectedAddress}
            options={props.options}
          />

          <div className="actions">
            <ButtonCancel
              onClick={handleModalCancel}
              dataTestId={testIds.buttonCancel}
            >
              {t("button-cancel")!}
            </ButtonCancel>
            <ButtonConfirm
              disabled={!isValid}
              onClick={handleModalConfirm}
              dataTestId={testIds.buttonConfirm}
            >
              {t("button-confirm")!}
            </ButtonConfirm>
          </div>
        </ModalContent>
      </Modal>
    </AddressWrapper>
  );
};

export default AddressAutocomplete;
