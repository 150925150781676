import { useTranslation } from "react-i18next";
import { FormRow } from "../../../../../style/form";
import { SelectStyled } from "../../../../../style/Input";
import { UserConfigCollaboratorFormModel } from "./useUserCollaboratorConfigForm";
import { SelectPair } from "../../../../../types/common.types";

interface IUserCollaboratorConfigFormProps {
  model: UserConfigCollaboratorFormModel;
  studioOptions: SelectPair[];
  onChange: (value: any, field: string) => void;
}

const UserCollaboratorConfigForm = (
  props: IUserCollaboratorConfigFormProps
) => {
  const { model, studioOptions, onChange } = props;

  const { t } = useTranslation();

  return (
    <FormRow>
      <SelectStyled
        label={t("user-collaborator-config-form-studio-label")}
        tooltip={t("user-collaborator-config-form-studio-tooltip")}
        onChange={(value) => onChange(value, 'studioId')}
        value={model.studioId?.toString()}
        options={studioOptions}
      />
    </FormRow>
  );
};
export default UserCollaboratorConfigForm;
