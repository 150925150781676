import { useEffect, useState } from "react";
import { UserItem } from "../../UserItem";
import { UserUICommunication } from "../../../../../types/common.types";
import {
    BaseAppraiserItem,
    useLazyGetCollaboratorConfigListQuery,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useTranslation } from "react-i18next";
import { logUnhandledException } from "../../../../../utils/console";
import { updateObject } from "../../../../../utils/updateObject";

export type UserConfigCollaboratorFormModel = {
    studioId?: number;
};

type UserCollaboratorConfigFormState = {
    data: UserConfigCollaboratorFormModel;
    canSubmit: boolean;
};

const useUserCollaboratorConfigForm = (user: UserItem) => {
    const { t } = useTranslation();

    const { authorization, activeRole } = useSelector(
        (rootState: RootState) => rootState.user
    );

    const checkIfCanSubmit = (data: UserConfigCollaboratorFormModel) => {
        const { studioId } = data;

        return studioId! > 0;
    };

    const initState = (user: UserItem) => {
        const data: UserConfigCollaboratorFormModel = {
            studioId: user.appraisalStudioId,
        };

        return {
            data,
            canSubmit: checkIfCanSubmit(data),
        } as UserCollaboratorConfigFormState;
    };

    const [state, setState] = useState<UserCollaboratorConfigFormState>(
        initState(user)
    );

    const [userUICommunications, setUserUICommunications] =
        useState<UserUICommunication>({
            action: "idle",
        } as UserUICommunication);

    const [studiosList, setStudiosList] = useState<BaseAppraiserItem[]>([]);

    const [getCollaboratorConfigList] = useLazyGetCollaboratorConfigListQuery();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            setUserUICommunications({
                action: "load",
                status: "in-progress",
            } as UserUICommunication);

            const response = await getCollaboratorConfigList({
                authorization: authorization,
            });

            if (response.isSuccess && response.data) {
                const responseData = response.data as BaseAppraiserItem[];

                setStudiosList(responseData);

                setUserUICommunications({
                    action: "idle",
                } as UserUICommunication);
            } else {
                const status = "";

                setUserUICommunications({
                    action: "load",
                    status: "done",
                    result: "error",
                    errorDetails: t(`http-error-${status}`),
                } as UserUICommunication);
            }
        } catch (err) {
            const status = "";

            setUserUICommunications({
                action: "load",
                status: "done",
                result: "error",
                errorDetails: t(`http-error-${status}`),
            } as UserUICommunication);

            logUnhandledException(err); // if this point is reached then it's not an error, it's a bug
        }
    };

    const handleDataChange = (value: any, field: string) => {
        const { data } = state;

        const updatedData = updateObject(data, value, field);

        setState({
            ...state,
            data: { ...updatedData },
            canSubmit: checkIfCanSubmit(updatedData),
        });
    };

    return {
        data: state.data,
        canSubmit: state.canSubmit,
        userUICommunications,
        studiosList,
        handleDataChange,
    };
};

export default useUserCollaboratorConfigForm;
