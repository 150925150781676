import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  AreaCoverage,
  Coverage,
  DistrictCoverage,
} from "../../../../../redux/apiSpecifications/apiFesf";
import AppraiserDetailsManagementCoverageDistrict from "./AppraiserDetailsManagementCoverageDistrict";
import {
  CoverageZoneSelect,
  IDistrictZoneOptions,
} from "./AppraiserDetailsManagement";
import { ButtonConfirm } from "../../../../Layout/Buttons";

import { mainTabs } from "../../../../../config/testIds";
import { InputNumberStyled } from "../../../../../style/Input";
import { Input } from "antd";
import { GiConvergenceTarget } from "react-icons/gi";

const testIds = mainTabs.tabs.supplierNetwork.appraiserDetails.management;

const AppraiserDetailsManagementCoverageDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3em;
  gap: 2em;

  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding-top: 2em;
    border-top: 1px solid #eee;

    .row {
      padding-top: 1em;
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: center;

      .perc-number-label {
        font-size: 0.9em;
        text-transform: uppercase;
      }

      .perc-number {
        width: 7em;
        margin-left: 2em;
      }
    }
  }

  .title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }

  .button-wrapper {
    margin-top: 0 !important;
    flex: 1;
    display: flex;
    justify-content: flex-end !important;

    button {
      font-size: 0.9em;
      width: 12em;
    }
  }
`;

interface IAppraiserDetailsManagementCoverageDataProps {
  coverage: Coverage | undefined;
  zones: CoverageZoneSelect | undefined;
  maxDistrict: number | undefined;
  onChange: (value: any, field: string) => void;
  onAddNew: (districtCode: string) => void;
  onDeleteDistrinct: (index: number) => void;
}

const AppraiserDetailsManagementCoverageData = (
  props: IAppraiserDetailsManagementCoverageDataProps
) => {
  const { t } = useTranslation();
  const {
    coverage,
    zones,
    maxDistrict,
    onChange,
    onAddNew,
    onDeleteDistrinct,
  } = props;

  const handleOnChange = (value: any, field: string, index: number) => {
    if (field === "district") {
      onChange({ value: { districtCode: value, index } }, "district");
    } else if (
      field === "newAreaCoverage-city" ||
      field === "newAreaCoverage-cap"
    ) {
      onChange(value, field);
    } else if (field === "districtMaxPracticeCountDaily") {
      const updatedDistrictCoverages = coverage?.districtCoverages?.map(
        (district, i) => {
          if (i !== index) return district;

          const numberOfAreas = district.areaCoverages?.length;
          const quotePerArea = Math.floor(value / numberOfAreas!);
          const firstAreaQuote = value - (numberOfAreas! - 1) * quotePerArea;

          const updatedAreasCoverage: AreaCoverage[] =
            district.areaCoverages?.map((ac, i) => ({
              ...ac,
              areaMaxPracticeCountDaily:
                i === 0 ? firstAreaQuote : quotePerArea,
            }))!;

          return {
            ...district,
            districtMaxPracticeCountDaily: value,
            areaCoverages: updatedAreasCoverage,
          };
        }
      );

      const updatedCoverage = {
        ...coverage,
        districtCoverages: updatedDistrictCoverages,
      };
      onChange(updatedCoverage, "coverage");
    } else if (field === "districtMaxPracticeCountMonthly") {
      const updatedDistrictCoverages = coverage?.districtCoverages?.map(
        (district, i) => {
          if (i !== index) return district;

          const numberOfAreas = district.areaCoverages?.length;
          const quotePerArea = Math.floor(value / numberOfAreas!);
          const firstAreaQuote = value - (numberOfAreas! - 1) * quotePerArea;

          const updatedAreasCoverage: AreaCoverage[] =
            district.areaCoverages?.map((ac, i) => ({
              ...ac,
              areaMaxPracticeCountMonthly:
                i === 0 ? firstAreaQuote : quotePerArea,
            }))!;

          return {
            ...district,
            districtMaxPracticeCountMonthly: value,
            areaCoverages: updatedAreasCoverage,
          };
        }
      );

      const updatedCoverage = {
        ...coverage,
        districtCoverages: updatedDistrictCoverages,
      };
      onChange(updatedCoverage, "coverage");
    } else if (
      field === "areaMaxPracticeCountDaily" ||
      field === "areaMaxPracticeCountMonthly"
    ) {
    } else if (field === "areaMaxPracticeCountMonthly") {
    } else if (field === "hasAreaCoverageParams" || field === "areaCoverages") {
      if (
        field === "hasAreaCoverageParams" &&
        value &&
        coverage?.districtCoverages
      ) {
        const district = coverage?.districtCoverages![index];
        onChange(district.districtCode, "setDefaultAreas");
        return;
      }

      const updatedDistrictCoverages = coverage?.districtCoverages?.map(
        (district, i) => {
          if (i !== index) return district;
          if (field === "hasAreaCoverageParams")
            return { ...district, [field]: value, areaCoverages: [] };
          return {
            ...district,
            [field]: value,
          };
        }
      );

      const updatedCoverage = {
        ...coverage,
        districtCoverages: updatedDistrictCoverages,
      };
      onChange(updatedCoverage, "coverage");
    } else if (field === "appraiserStatusCode") {
      onChange(value, field);
    } else if (field === "areaCoverages") {
    } else {
      const updatedCoverage = { ...coverage, [field]: value };
      onChange(updatedCoverage, "coverage");
    }
  };

  const handleOnChangeStatus = (coverage: DistrictCoverage, status: string) => {
    handleOnChange(
      { value: status, districtCode: coverage.districtCode },
      "appraiserStatusCode",
      -1
    );
  };

  const canAddNewDistrict =
    (coverage?.districtCoverages?.length || 0) < (maxDistrict || 0);

  return (
    <AppraiserDetailsManagementCoverageDataWrapper
      data-testid={testIds.mainData.container}
    >
      <div className="header">
        <div className="title">
          {t("edit-expertizer-management-coverage-title")}
        </div>

        <div className="row">
          {/* <div className="perc-number-label">
            {t("edit-expertizer-management-coverage-percentage")}
          </div>
          <Input
            className="perc-number"
            addonAfter="%"
            type="number"
            onChange={(e) => onChange(e.target.value, "coverageLeaveLossPerc")}
            value={coverage?.coverageLeaveLossPerc}
          /> */}
          <div className="button-wrapper">
            <ButtonConfirm
              size="small"
              onClick={onAddNew}
              dataTestId={testIds.coverageData.buttonAddProvince}
              disabled={!canAddNewDistrict}
            >
              {t("edit-expertizer-management-add-province-button")!}
            </ButtonConfirm>
          </div>
        </div>
      </div>
      {!!zones &&
        coverage?.districtCoverages?.map((district, index) => {
          const districts = zones?.districts.find(
            (d) => Object.keys(d)[0] === district.districtCode!
          )!;

          const zipCodes = zones?.zipCodes.find(
            (d) => Object.keys(d)[0] === district.districtCode!
          )!;

          const zIndex = district.districtCode as keyof IDistrictZoneOptions;
          const areaOptionsByCity =
            !!districts && !!zIndex ? districts[zIndex] : [];

          const areaOptionsByZipCode =
            !!districts && !!zIndex ? zipCodes[zIndex] : [];

          return (
            <AppraiserDetailsManagementCoverageDistrict
              key={district.districtCode}
              provinceOptions={zones?.provinces || []}
              areaOptionsByCity={areaOptionsByCity}
              areaOptionsByZipCode={areaOptionsByZipCode}
              onChange={(val, field) => handleOnChange(val, field, index)}
              coverage={district}
              onDeleteDistrinct={() => onDeleteDistrinct(index)}
              onChangeStatus={handleOnChangeStatus}
            />
          );
        })}
    </AppraiserDetailsManagementCoverageDataWrapper>
  );
};

export default AppraiserDetailsManagementCoverageData;
