/**
 * This interface make an object extensible, otherwise in Typescrit will be impossible add new properties
 */
interface ExtensibleObject {
	[key: string]: any;
}

/**
 * Update an object passing a value and the route to the property to update.
 *
 * @template ObjectType
 * @params {ObjectType} originalObject - The original object to modify
 * @params {any} value - The value to assign
 * @params {string} - the route to the property, each tree's level is dot separated
 * @returns {ObjectType} - The updated object
 */
export function updateObject<ObjectType>(originalObject: ObjectType, value: any, fieldRoute: string): ObjectType {
	const updatedObject: ExtensibleObject = JSON.parse(JSON.stringify(originalObject));
	const steps = fieldRoute.split(".") as string[];

	steps.reduce((prev: ExtensibleObject, prop, index) => {
		// if is the leaf
		if (index === steps.length - 1) {
			prev[prop] = value;
		} else {
			// if is an intermidiate object
			const property = prev[prop];

			if (!property) (prev as ExtensibleObject)[prop] = {} as ExtensibleObject; // it may be undefined
			return prev[prop];
		}
	}, updatedObject);

	return updatedObject as ObjectType;
}
