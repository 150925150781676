import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  AppraiserAvailabilityInfo,
  AppraisersDistrictAvailabilityInfo,
  useLazyGetDistrictAvailabilityInfoQuery,
} from "../../../../redux/apiSpecifications/apiFesf";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../../../hooks/useAuthorization";
import { IconLoading } from "../../../../config/icons";
import { LoadingFullScreenWrapper } from "../../../../style/DashbordWidgetWrappers";
import { useDispatch } from "react-redux";
import { addTab } from "../../../../redux/features/mainTabsSlice";

// Styled components

const AppraisersListWrapper = styled.div`
  width: 100%;

  .header {
    padding: 0.25em;
    border-radius: 5px;
    background-color: #eee;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9em;
  }

  .appraisers {
    margin: 1em 2.5% 0 2.5%;
    width: 95%;

    tr {
      border-bottom: 1px solid #e0e0e0;
      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: #f5f5f5;
        cursor: pointer;
      }

      td {
        padding: 0.25em 1em;
      }
    }
  }

  .appraiser {
  }
`;

type status = "idle" | "loading" | "error";

interface IAppraiserListProps {
  date: string | undefined;
  districtCode: string | undefined;
  districtName: string | undefined;
  availabilityType: string | undefined;
}

const AppraisersList = ({
  date,
  districtCode,
  districtName,
  availabilityType,
}: IAppraiserListProps) => {
  const { t } = useTranslation();
  const { getAuthorization } = useAuthorization();
  const dispatch = useDispatch();

  const [status, setStatus] = useState<status>("idle");
  const [appraisers, setAppraisers] = useState<AppraiserAvailabilityInfo[]>([]);

  const [loadAppraisers] = useLazyGetDistrictAvailabilityInfoQuery();

  useEffect(() => {
    loadAppraisersData();
  }, [date, districtCode, availabilityType]);

  const loadAppraisersData = async () => {
    if (!date || !districtCode || !availabilityType) {
      setAppraisers([]);
      return;
    }

    setStatus("loading");

    const response = await loadAppraisers({
      authorization: await getAuthorization(),
      searchDate: date,
      district: districtCode,
      availabilityType,
    });

    const _appraisers = (response.data as AppraisersDistrictAvailabilityInfo)
      .districtAvaliabilityInfo;
    if (_appraisers !== undefined) {
      setAppraisers(_appraisers);
      setStatus("idle");
    } else {
      setStatus("error");
    }
  };

  let headerText = t("agenda-appraisers-list");

  if (districtName && availabilityType)
    headerText += ` / ${districtName}  / ${t("agenda-filter-availability")} ${t(
      `agenda-filter-availability-${availabilityType}`
    )}`;

  const handleSelectedAppraiser = (a: AppraiserAvailabilityInfo) => {
    dispatch(
      addTab({
        key: 0,
        label: a.appraiserBusinessName || "",
        type: "appraiser-details",
        externalData: {
          idAppraiser: a.appraiserId,
          defaultTab: "agenda",
          defaultAgendaDate: date,
          defaultAgendaStatus: a.status,
          defaultAgendaReason: undefined,
          defaultAgendaProvince: districtCode,
        },
      })
    );
  };

  return (
    <>
      {status === "loading" && (
        <LoadingFullScreenWrapper>
          <div className="box">{IconLoading}</div>
        </LoadingFullScreenWrapper>
      )}
      <AppraisersListWrapper>
        <div className="header">{headerText}</div>
        <table className="appraisers">
          <tbody>
            {appraisers?.map((a, i) => (
              <tr
                key={i}
                className="appraiser"
                onClick={() => handleSelectedAppraiser(a)}
              >
                <td className="name">{a.appraiserBusinessName}</td>
                <td className="type">
                  {t(`agenda-appraiser-type-${a.appraiserRoleType}`)}
                </td>
                <td className="contact">{a.telephone}</td>
                <td className="suspension-type">
                  {t(`agenda-appraiser-status-${a.status}`)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </AppraisersListWrapper>
    </>
  );
};

export default AppraisersList;
