import styled from "styled-components";
import { IconDelete, IconEdit } from "../../config/icons";

const IconButtonDefaultStyled = styled.div`
	font-size: 1.4em;
	color: #888;
	cursor: pointer;
	border-radius: 10em;

	width: 1.6em;
	height: 1.6em;

	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		color: #fff;
		background-color: #40a9ff;
	}
`;

const IconButtonDangerStyled = styled.div`
	font-size: 1.4em;
	color: red;
	cursor: pointer;
	border-radius: 10em;

	width: 1.6em;
	height: 1.6em;

	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		color: #fff;
		background-color: red;
	}
`;

interface IconButtonProps {
	onClick: () => void;
}

export const EditIconButton = (props: IconButtonProps) => (
	<IconButtonDefaultStyled onClick={props.onClick} data-testid="icon-button-edit">
		<IconEdit dataTestid="icon-button-edit-svg" />
	</IconButtonDefaultStyled>
);

export const DeleteIconButton = (props: IconButtonProps) => (
	<IconButtonDangerStyled onClick={props.onClick} data-testid="icon-button-delete">
		<IconDelete dataTestid="icon-button-delete-svg" />
	</IconButtonDangerStyled>
);
