import React from "react";
import styled from "styled-components";
import AppraiserAgenda from "../AppraiserDetails/Agenda/AppraiserAgenda";
import { GenericWrapper } from "../Commons/Wrappers";

//Styled components
const AgendaAppraiserWrapper = styled(GenericWrapper)`
  background-color: transparent;
`;

interface IAgendaAppraiserProps {
  idAppraiser?: number;
  readOnly?: boolean;
}

const AgendaAppraiser = ({ idAppraiser, readOnly }: IAgendaAppraiserProps) => {
  return (
    <AgendaAppraiserWrapper>
      <AppraiserAgenda idAppraiser={idAppraiser || -1} readOnly={readOnly} />
    </AgendaAppraiserWrapper>
  );
};

export default AgendaAppraiser;
