import { useSelector } from "react-redux";
import {
    RequestMessage,
    SecondaryPracticeRequest,
} from "../../../../../../redux/apiSpecifications/apiCrud";
import Message from "./Message";
import { RootState } from "../../../../../../redux/store";
import { ButtonConfirm } from "../../../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useMemo } from "react";
import { Button, Popconfirm } from "antd";
import { ProfileHelper } from "../ProfileHelper";

const FirsMessageWrapper = styled.div`
    margin-bottom: 1em;
    .button-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 1em;
        gap: 1em;
    }
`;

interface IManagePracticeRequestShowModeProps {
    practiceRequest: SecondaryPracticeRequest;
    onClose: () => void;
    onCloseWithMessage: () => void;
    onForward: () => void;
    onReply: () => void;
    onHaveReadMessage: () => void;
}

const ManagePracticeRequestShowMode = ({
    practiceRequest,
    onClose,
    onCloseWithMessage,
    onForward,
    onReply,
    onHaveReadMessage
}: IManagePracticeRequestShowModeProps) => {
    const { t } = useTranslation();

    const activeRole = useSelector((state: RootState) => state.user.activeRole);

    let first: RequestMessage | null = null;
    let messages: RequestMessage[] = [];

    // if (practiceRequest.status === "C") {
    //     [...messages] = practiceRequest.messages!;
    // } else {
    //     [first, ...messages] = practiceRequest.messages!;
    // }

    [first, ...messages] = practiceRequest.messages!;

    const userProfile = useMemo(() => {
        return ProfileHelper.GetProfileFromRole(activeRole!);
    }, [activeRole]);

    const canClose = useMemo(() => {
        if (
            first &&
            practiceRequest.status !== "C" &&
            practiceRequest.senderProfile === userProfile &&
            !(practiceRequest.isSecondary ?? false)
        ) {
            return true;
        }

        return false;
    }, []);

    const canCloseWithMessage = useMemo(() => {
        if (
            first &&
            practiceRequest.status !== "C" &&
            practiceRequest.senderProfile === userProfile &&
            !(practiceRequest.isSecondary ?? false) &&
            (practiceRequest.senderProfile === 4 ||
                practiceRequest.senderProfile === 8) && // BO oppure ST
            practiceRequest.recipientProfile === 5 // Perito
        ) {
            return true;
        }

        return false;
    }, []);

    const canForward = useMemo(() => {
        if (
            first &&
            practiceRequest.status !== "C" &&
            (practiceRequest.senderProfile === userProfile ||
                practiceRequest.recipientProfile === userProfile) &&
            first.senderProfile !== userProfile &&
            userProfile === 4
        ) {
            if (
                [
                    "AD",
                    "ATI",
                    "CIL",
                    "API",
                    "APN",
                    "RAC",
                    "RA",
                    "S",
                    "X",
                ].includes(practiceRequest.categoryCode!) &&
                practiceRequest.isSecondary === false &&
                practiceRequest.senderProfile !== 4
            ) {
                return true;
            } else if (
                [
                    "AD",
                    "ATI",
                    "CIL",
                    "API",
                    "APN",
                    "RAC",
                    "RA",
                    "S",
                    "X",
                ].includes(practiceRequest.categoryCode!) &&
                practiceRequest.isSecondary === true &&
                practiceRequest.senderProfile === 4
            ) {
                return true;
            }
        }

        return false;
    }, []);

    const canReply = useMemo(() => {
        if (
            first &&
            practiceRequest.status !== "C" &&
            (practiceRequest.senderProfile === userProfile ||
                practiceRequest.recipientProfile === userProfile) &&
            first.senderProfile !== userProfile
        ) {
            return true;
        }

        return false;
    }, []);

    const canHaveReadMessage = useMemo(() => {
        if (
            first &&
            practiceRequest.status === "C" &&
            (practiceRequest.isToCheck ?? false) &&
            !(practiceRequest.isSecondary ?? false) &&
            (practiceRequest.senderProfile === 4 ||
                practiceRequest.senderProfile === 8) && // BO oppure ST
            practiceRequest.recipientProfile === 5 && // Perito
            userProfile == 5
        ) {
            return true;
        }

        return false;
    }, []);

    return (
        <>
            {first && (
                <FirsMessageWrapper>
                    <Message message={first} />
                    <div className="button-wrapper">
                        <Popconfirm
                            placement="leftBottom"
                            icon={null}
                            title={t(
                                "appraisals-details-tab-requests-confirm-close-request"
                            )}
                            onConfirm={onClose}
                            okText={t("switch-yes")}
                            cancelText={t("switch-no")}
                        >
                            {canClose && !canCloseWithMessage && (
                                <ButtonConfirm onClick={() => {}}>
                                    {t("button-close-practice-request")!}
                                </ButtonConfirm>
                            )}
                        </Popconfirm>

                        {canCloseWithMessage && (
                            <ButtonConfirm onClick={onCloseWithMessage}>
                                {t("button-close-practice-request")!}
                            </ButtonConfirm>
                        )}

                        {canForward && (
                            <ButtonConfirm onClick={onForward}>
                                {t("button-forward-practice-request-message")!}
                            </ButtonConfirm>
                        )}

                        {canReply && (
                            <ButtonConfirm onClick={onReply}>
                                {t("button-reply")!}
                            </ButtonConfirm>
                        )}

                        {canHaveReadMessage && (
                            <ButtonConfirm onClick={onHaveReadMessage}>
                                {t("button-have-read-message")!}
                            </ButtonConfirm>
                        )}
                    </div>
                </FirsMessageWrapper>
            )}
            {messages.map((message) => (
                <Message key={message.id} message={message} />
            ))}

            {/* <pre>{JSON.stringify(practiceRequest, null, 2)}</pre> */}
            {/* <pre>{JSON.stringify(userProfile, null, 2)}</pre> */}
        </>
    );
};

export default ManagePracticeRequestShowMode;
