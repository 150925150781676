import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import { AccountingReportList } from "../../../redux/apiSpecifications/apiFesf";
import WidgetHeader from "../../Widgets/WidgetHeader";
import { useTranslation } from "react-i18next";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { CellWrapper } from "../../../style/CellWrapper";
import { ReportItem } from "./ReportItem";
import { dateToNumber } from "../../../utils/date";
import ReportActions from "./ReportActions";

const SimulatedReportsListWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    align-items: stretch;
    max-height: 2.5em;
    transition: max-height 1s;
    &.open {
        max-height: 100em;
    }

    div.body {
        padding: 1em 2em;

        .MuiPaper-root {
            box-shadow: none;
            width: 95%;
        }

        .MuiInputBase-root {
            &:before {
                border-bottom: 1px solid white;
            }
        }

        .MuiToolbar-root > .MuiBox-root {
            justify-content: flex-start;
        }

        .MuiInputBase-input {
            color: #555;
        }

        .MuiTableCell-root {
            padding: 0.5em 1em;
        }
    }
`;

interface ISimulatedReportsListProps {
    reports: AccountingReportList;
    // onEditUserContact: (userItem: UserItem) => void;
    // onEditUserConfig: (userItem: UserItem) => void;
}

const SimulatedReportsList = ({ reports }: ISimulatedReportsListProps) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(reports!.length > 0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });

    useEffect(() => {
        setIsOpen(reports!.length > 0);
    }, [reports]);

    const data = useMemo(
        () =>
            reports?.map((report) => {
                return {
                    ...report,
                    startDateNumber: dateToNumber(report.startDate),
                    endDateNumber: dateToNumber(report.endDate),
                    creationDateNumber: dateToNumber(report.creationDate),
                } as ReportItem;
            }) || [],
        [reports]
    );

    const columns = useMemo<MRT_ColumnDef<ReportItem>[]>(
        () => [
            {
                accessorKey: "name",
                header: t("report-list-name-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper>{row.original.name}</CellWrapper>
                ),
            },
            {
                accessorKey: "startDateNumber",
                header: t("report-list-start-date-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => {}}>
                        {row.original.startDate}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "endDateNumber",
                header: t("report-list-end-date-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => {}}>
                        {row.original.endDate}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "creationDateNumber",
                header: t("report-list-creation-date-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => {}}>
                        {row.original.creationDate}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "actions",
                header: t("report-list-actions-column-header"),
                size: 100,
                enableSorting: false,
                enableColumnDragging: false,
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <ReportActions
                        reportId={row.original.id!}
                        generationStatus={row.original.generationStatus!}
                        reportStatus={row.original.reportStatus!}
                        canReview={false}
                        canClose={false}
                    />
                ),
            },
        ],
        [reports]
    );

    const handleOnOpen = () => setIsOpen(true);
    const handleOnClose = () => setIsOpen(false);

    return (
        <SimulatedReportsListWrapper className={`${isOpen ? "open" : ""}`}>
            <WidgetHeader
                title={t("manage-report-simulated-report-title")}
                isActive={false}
                onOpen={handleOnOpen}
                onClose={handleOnClose}
                isOpen={isOpen}
            />

            <div className="body">
                {data.length > 0 && (
                    <MaterialReactTable
                        muiTablePaperProps={{ style: { width: "100%" } }}
                        columns={columns}
                        data={data}
                        enableDensityToggle={false}
                        enableColumnOrdering
                        muiTableBodyCellProps={{
                            sx: {
                                textAlign: "left",
                            },
                        }}
                        onPaginationChange={setPagination}
                        state={{ pagination }}
                        autoResetPageIndex={false}
                    />
                )}
            </div>
        </SimulatedReportsListWrapper>
    );
};

export default SimulatedReportsList;
