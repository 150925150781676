import { useState } from "react";
import { IconLoading } from "../../../../config/icons";
import {
    DashboardWrapper,
    LoadingInTabWrapper,
    RefreshInTabWrapper,
} from "../../../../style/DashbordWidgetWrappers";
import styled from "styled-components";

const DashboardC1Wrapper = styled(DashboardWrapper)`
    display: grid;
    grid-template-areas:
        "claimsInEvidence createClaim createSubject"
        "claimsList claimsList claimsList"
        "spacer spacer spacer";
    grid-template-columns: 3fr 2fr 1fr;
    grid-template-rows: auto auto auto;
    column-gap: 1em;
    row-gap: 1em;
`;

interface IDashboardC1Props {
    tabKey?: number;
}

const DashBoard_C1 = (props: IDashboardC1Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingStatus, setLoadingStatus] = useState<
        "idle" | "loading" | "refreshing"
    >("idle");

    return (
        <div>
            {isLoading && (
                <LoadingInTabWrapper>
                    <div className="box">{IconLoading}</div>
                </LoadingInTabWrapper>
            )}

            {!isLoading && (
                <>
                    <DashboardC1Wrapper></DashboardC1Wrapper>

                    {loadingStatus === "refreshing" && (
                        <RefreshInTabWrapper>
                            <div className="box">{IconLoading}</div>
                        </RefreshInTabWrapper>
                    )}
                </>
            )}
        </div>
    );
};

export default DashBoard_C1;
