import { useSelector } from "react-redux";
import {
  SearchedPoliciesList,
  SearchValidPoliciesParams,
  useSearchValidPoliciesMutation,
} from "../redux/apiSpecifications/apiPolicies";
import { RootState } from "../redux/store";
import { useState } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

type PagedSearchValidPoliciesParams = {
  pageNum: number;
  pageLen: number;
  searchValidPoliciesParams: SearchValidPoliciesParams;
};

type SearchValidPoliciesState = {
  policiesList?: SearchedPoliciesList;
  pagedSearchValidPoliciesParams: PagedSearchValidPoliciesParams;
};

const defaultState: SearchValidPoliciesState = {
  pagedSearchValidPoliciesParams: {
    pageLen: 5,
    pageNum: 1,
    searchValidPoliciesParams: {},
  },
};

const useSearchValidPolicies = () => {
  const { activeRole } = useSelector((state: RootState) => state.user);

  const [state, setState] = useState<SearchValidPoliciesState>(defaultState);

  const [searchValidPolicies] = useSearchValidPoliciesMutation();

  const searchValidPoliciesPromise = (
    pagedSearchValidPoliciesParams: PagedSearchValidPoliciesParams
  ): Promise<SearchedPoliciesList> =>
    new Promise(async (resolve, reject) => {
      const response = await searchValidPolicies({
        ...pagedSearchValidPoliciesParams,
        activeRole: activeRole!,
      });

      const searchResponse = response as {
        data: SearchedPoliciesList;
      };

      if (searchResponse.data) {
        setState({
          ...state,
          pagedSearchValidPoliciesParams: {
            ...state.pagedSearchValidPoliciesParams,
          },
          policiesList: searchResponse.data,
        });

        resolve(searchResponse.data);
      } else {
        const errorResponse = response as {
          error: FetchBaseQueryError;
        };

        reject(errorResponse.error);
      }
    });

  const changePaginationPromise = (
    pageIndex: number,
    pageSize: number
  ): Promise<SearchedPoliciesList> =>
    new Promise(async (resolve, reject) => {
      const response = await searchValidPolicies({
        ...state.pagedSearchValidPoliciesParams,
        activeRole: activeRole!,
        pageNum: pageIndex,
        pageLen: pageSize,
      });

      const searchResponse = response as {
        data: SearchedPoliciesList;
      };

      if (searchResponse.data) {
        setState({
          ...state,
          pagedSearchValidPoliciesParams: {
            ...state.pagedSearchValidPoliciesParams,
            pageNum: pageIndex,
            pageLen: pageSize,
          },
          policiesList: searchResponse.data,
        });

        resolve(searchResponse.data);
      } else {
        const errorResponse = response as {
          error: FetchBaseQueryError;
        };

        reject(errorResponse.error);
      }
    });

  return {
    pagedSearchValidPoliciesParams: state?.pagedSearchValidPoliciesParams,
    policiesList: state?.policiesList,
    searchValidPoliciesPromise,
    changePaginationPromise,
  };
};

export default useSearchValidPolicies;
