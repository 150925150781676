import { Drawer, Modal, Space } from "antd";
import styled from "styled-components";
import { IconLoading } from "../../../../../../config/icons";
import { ButtonConfirm } from "../../../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import {
  useCreateClaimContext,
  useCreateClaimDispatch,
} from "./CreateClaimContext";
import Wizard from "./Wizard/Wizard";
import { CreateClaimData } from "./CreateClaimReducer";

const ModalContent = styled.div`
  // padding: 2em;
  // position: relative;
  display: flex;
  flex-direction: column;
  gap: 2em;

  .ant-form-item-explain {
    color: red !important;
  }

  .loader-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em 2em 0 2em;
    z-index: 101;

    .loader {
      width: 6em;
      height: 6em;
    }
  }
`;

const RefreshingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  display: flex;
  justify-content: center;

  .box {
    width: 10em;
    display: flex;
    justify-content: center;
  }
`;

interface ICreateClaimModalProps {
  onCreateClaim: (createClaimData: CreateClaimData, complete: boolean) => void;
}

const CreateClaimModal = ({ onCreateClaim }: ICreateClaimModalProps) => {
  const { t } = useTranslation();

  const { createClaimData, loadingStatus, openWizard } =
    useCreateClaimContext();

  const dispatch = useCreateClaimDispatch();

  const handleOnCancel = () => {
    dispatch!({
      type: "SET_OPEN_WIZARD",
      payload: false,
    });
  };

  const handleOnCreateClaim = (complete: boolean) => {
    dispatch!({
      type: "SET_OPEN_WIZARD",
      payload: false,
    });

    onCreateClaim(createClaimData!, complete);
  };

  return (
    <Drawer
      closable={true}
      open={openWizard}
      width="100VW"
      onClose={handleOnCancel}
      destroyOnClose={true}
      title={t("create-claim-modal-title")}
      // extra={
      //   <Space>
      //     <ButtonConfirm onClick={handleOnSave} disabled={true}>
      //       {t("button-confirm")!}
      //     </ButtonConfirm>
      //   </Space>
      // }
    >
      <ModalContent>
        {openWizard === true && (
          <>
            <Wizard onCreateClaim={handleOnCreateClaim} />

            {/* <pre>{JSON.stringify(createClaimData, null, 2)}</pre> */}

            {loadingStatus === "refreshing" && (
              <RefreshingWrapper>
                <div className="box">{IconLoading}</div>
              </RefreshingWrapper>
            )}
          </>
        )}
      </ModalContent>
    </Drawer>
  );
};

export default CreateClaimModal;
