import { useTranslation } from "react-i18next";
import { QRAppraisalReports } from "../../../../../redux/apiSpecifications/apiFesf";
import { useMemo, useState } from "react";
import { BusinessReportItem } from "./BusinessReportItem";
import { dateTimeToNumber, dateToNumber } from "../../../../../utils/date";
import { CellWrapper } from "../../../../../style/CellWrapper";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import styled from "styled-components";
import BusinessReportActions from "./BusinessReportActions";

const BusinessReportsListWrapper = styled.div`
    display: flex;
    align-items: center;
    overflow: auto;
    margin-top: 1em;
    background-color: #fff;
    border-radius: 1em;
    box-shadow: 0 0 5px #aaa;
    padding: 1em 2em;

    .MuiPaper-root {
        box-shadow: none;
        width: 95%;
    }

    .MuiInputBase-root {
        &:before {
            border-bottom: 1px solid white;
        }
    }

    .MuiToolbar-root > .MuiBox-root {
        justify-content: flex-start;
    }

    .MuiInputBase-input {
        color: #555;
    }

    .MuiTableCell-root {
        padding: 0.5em 1em;
    }
`;

interface IBusinessReportListProps {
    businessReports: QRAppraisalReports;
}

const BusinessReportList = ({ businessReports }: IBusinessReportListProps) => {
    const { t } = useTranslation();

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const data = useMemo(
        () =>
            businessReports?.map((report) => {
                return {
                    ...report,
                    startDateNumber: dateToNumber(report.startDate),
                    endDateNumber: dateToNumber(report.endDate),
                    creationDateNumber: dateTimeToNumber(report.creationDate),
                } as BusinessReportItem;
            }) || [],
        [businessReports]
    );

    const columns = useMemo<MRT_ColumnDef<BusinessReportItem>[]>(
        () => [
            {
                accessorKey: "name",
                header: t("business-report-list-name-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper>{row.original.name}</CellWrapper>
                ),
            },
            {
                accessorKey: "rangeType",
                header: t("business-report-list-range-type-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => {}}>
                        {t(
                            `business-report-range-type-description-${row.original.rangeType}`
                        )}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "startDateNumber",
                header: t("business-report-list-start-date-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => {}}>
                        {row.original.startDate}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "endDateNumber",
                header: t("business-report-list-end-date-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => {}}>
                        {row.original.endDate}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "creationDateNumber",
                header: t("business-report-list-creation-date-column-header"),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => {}}>
                        {row.original.creationDate}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "generationStatus",
                header: t(
                    "business-report-list-generation-status-column-header"
                ),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => {}}>
                        {t(
                            `report-generation-status-code-${row.original.generationStatus}`
                        )}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "actions",
                header: t("report-list-actions-column-header"),
                size: 100,
                enableSorting: false,
                enableColumnDragging: false,
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <BusinessReportActions
                        businessReportId={row.original.id!}
                        businessReportDocumentCode={row.original.documentCode!}
                        generationStatus={row.original.generationStatus!}
                    />
                ),
            },
        ],
        [businessReports]
    );

    return (
        <BusinessReportsListWrapper>
            {data.length > 0 && (
                <MaterialReactTable
                    muiTablePaperProps={{ style: { width: "100%" } }}
                    columns={columns}
                    data={data}
                    enableDensityToggle={false}
                    enableColumnOrdering
                    muiTableBodyCellProps={{
                        sx: {
                            textAlign: "left",
                        },
                    }}
                    onPaginationChange={setPagination}
                    state={{ pagination }}
                    autoResetPageIndex={false}
                />
            )}
        </BusinessReportsListWrapper>
    );
};

export default BusinessReportList;
