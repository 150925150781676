import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useEffect, useState } from "react";
import {
    AppraisersListItem,
    SupplierNetworkAdminPanel,
    useLazyGetSupplierNetworkAdminPanelQuery,
} from "../../../../redux/apiSpecifications/apiFesf";
import DashBoard_NF_Presentational from "./Dashboard-NF-Presentational";
import moment from "moment";
import { addTab } from "../../../../redux/features/mainTabsSlice";
import { DateFormat } from "../../../../config/const";
import { useAuthorization } from "../../../../hooks/useAuthorization";

interface IDashboardNFProps {
    tabKey?: number;
    disableMaps?: boolean;
}

const DashBoard_NF = (props: IDashboardNFProps) => {
    const { disableMaps, tabKey } = props;

    const { getAuthorization } = useAuthorization();

    const { selectedTenants } = useSelector((state: RootState) => state.user);

    const dispatch = useDispatch();

    const activeKey = useSelector(
        (state: RootState) => state.mainTabs.activeKey
    );

    const [loadingStatus, setLoadingStatus] = useState<
        "idle" | "loading" | "refreshing"
    >("idle");

    const [dashboardData, setDashboardData] =
        useState<SupplierNetworkAdminPanel | null>();

    const [loadDashboard] = useLazyGetSupplierNetworkAdminPanelQuery();

    const [showAppraiserStatusModal, setShowAppraiserStatusModal] =
        useState(false);

    const [selectedAppraiser, setSelectedAppraiser] = useState<
        AppraisersListItem | undefined
    >();

    const [appraiserToShowOnMap, setAppraiserToShowOnMap] = useState<
        number | undefined
    >(undefined);

    const [lastSearchDate, setLastSearchDate] = useState<string | undefined>();

    useEffect(() => {
        if (activeKey === tabKey) {
            setAppraiserToShowOnMap(undefined);
            
            (async () => {
                await loadData();
            })();
        }
    }, [activeKey]);

    const loadData = async (date?: string, districtCode?: string) => {
        setLoadingStatus("refreshing");

        const searchDate = date ? date : moment().format(DateFormat);
        const response = await loadDashboard({
            authorization: await getAuthorization(),
            searchDate,
            searchDistrictCode: districtCode,
        });
        const data = response.data as SupplierNetworkAdminPanel;
        if (data) {
            setDashboardData(data);
        } else {
            // error to do....
        }

        setLoadingStatus("idle");
    };

    useEffect(() => {
        if (!selectedTenants) return;

        setLoadingStatus("loading");
        loadData();
    }, [selectedTenants]);

    const handleLoadDataWithDate = (
        searchDate: string | undefined,
        searchDistrictCode: string | undefined
    ) => {
        setAppraiserToShowOnMap(undefined);

        loadData(searchDate, searchDistrictCode);

        setLastSearchDate(searchDate);
    };

    const handleEditAppraiser = (id: number, nominative: string) => {
        dispatch(
            addTab({
                key: 0,
                label: nominative, //, .slice(0, nominative.indexOf(" ")),
                type: "appraiser-details",
                externalData: {
                    idAppraiser: id,
                },
            })
        );
    };

    const handleEditAppraiserStatus = (id: number) => {
        const appraiser = dashboardData?.appraisersList?.find(
            (x) => x.id === id
        );

        setSelectedAppraiser(appraiser);
        setShowAppraiserStatusModal(true);
    };

    return (
        <DashBoard_NF_Presentational
            loadingStatus={loadingStatus}
            disableMaps={disableMaps}
            showAppraiserStatusModal={showAppraiserStatusModal}
            appraisersList={dashboardData?.appraisersList}
            communicationSummary={dashboardData?.communicationSummary}
            selectedAppraiser={selectedAppraiser}
            appraiserToShowOnMap={appraiserToShowOnMap}
            onEditAppraiser={handleEditAppraiser}
            onEditAppraiserStatus={handleEditAppraiserStatus}
            onLoadDataWithDate={handleLoadDataWithDate}
            onShowAppraiserStatusModal={setShowAppraiserStatusModal}
            onShowAppraiserOnMap={(appraiserId) => setAppraiserToShowOnMap(appraiserId)}
        />
    );
};

export default DashBoard_NF;
