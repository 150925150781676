import styled from "styled-components";
import { AppraisalQuestionnaire } from "../../../../../redux/apiSpecifications/apiCrud";
import { useTranslation } from "react-i18next";
import {
  DatePickerStyled,
  InputTextStyled,
  SelectStyled,
  SwitchStyled,
} from "../../../../../style/Input";
import { useMemo } from "react";
import TextArea from "antd/lib/input/TextArea";

const QuestionnaireWrapper = styled.div`
  display: flex;
  flex: 1;

  .title {
    letter-spacing: 1px;
    margin: 0 5em 1em 0;
  }

  .resume {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .main-area {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex: 1 1 0%;
    gap: 2em;
  }

  label {
    width: 15em;
  }

  .field {
    display: flex;
    flex-direction: row;
    gap: 3em;

    .label {
      display: flex;
      flex-direction: row;
    }

    .value {
    }

    .second-value {
    }
  }

  .taxable {
    input {
      width: 140px;
    }
  }
`;

interface IQuestionnaireProps {
  questionnaire: AppraisalQuestionnaire;
  isPracticeInvoiced?: boolean
}

const Questionnaire = ({ questionnaire, isPracticeInvoiced }: IQuestionnaireProps) => {
  const { t } = useTranslation();

  return (
    <QuestionnaireWrapper>
      <div className="main-area">
        <div className="resume">
          {/* NEGATIVE */}
          <div className="field">
            <SwitchStyled
              label={t(
                "appraisals-conclude-practice-questionnaire-question-1-label"
              )}
              checkedChildren={t("switch-yes")}
              unCheckedChildren={t("switch-no")}
              onChange={() => {}}
              checked={questionnaire?.isNegative || false}
              disabled={isPracticeInvoiced}
            />

            <div>
              {questionnaire?.negativeReasonCode === ""
                ? ""
                : t(
                    `appraisals-conclude-practice-questionnaire-is-negative-reason-${
                      questionnaire?.negativeReasonCode || ""
                    }`
                  )!}
            </div>
          </div>

          {/* ACCESS PROVE */}
          <div className="field">
            <SwitchStyled
              label={t("appraisals-conclude-practice-has-access-proof-label")}
              checkedChildren={t("switch-yes")}
              unCheckedChildren={t("switch-no")}
              onChange={() => {}}
              checked={questionnaire?.accessProof || false}
            />
          </div>

          {/* AGREEMENT */}
          <SelectStyled
            label={t(
              "appraisals-conclude-practice-questionnaire-question-2-label"
            )}
            options={[
              {
                value: "S",
                label: t("switch-yes"),
              },
              {
                value: "SN",
                label: t("appraisals-conclude-practice-yes-no-file"),
              },
              {
                value: "N",
                label: t("switch-no"),
              },
            ]}
            onChange={() => {}}
            disabled={true}
            value={questionnaire?.agreement}
          />

          {/* VERBAL AGREEMENT */}
          <div className="field">
            <SwitchStyled
              label={t("appraisals-conclude-practice-questionnaire-verbal-agreement-label")}
              checkedChildren={t("switch-yes")}
              unCheckedChildren={t("switch-no")}
              onChange={() => {}}
              checked={questionnaire?.isVerbalAgreement || false}
            />
          </div>

          {/* COMPATIBILITA' */}
          {!questionnaire?.isNegative && (
            <SelectStyled
              label={t(
                "appraisals-conclude-practice-questionnaire-question-3-label"
              )}
              options={[
                {
                  value: "S",
                  label: t("switch-yes"),
                },
                {
                  value: "N",
                  label: t("switch-no"),
                },
                {
                  value: "V",
                  label: t("appraisals-conclude-practice-to-verify"),
                },
              ]}
              onChange={() => {}}
              disabled={true}
              value={questionnaire?.isConsistent}
            />
          )}

          {/* ANTI ECONOMIC' */}
          <SwitchStyled
            label={t(
              "appraisals-conclude-practice-questionnaire-question-4-label"
            )}
            checkedChildren={t("switch-yes")}
            unCheckedChildren={t("switch-no")}
            onChange={() => {}}
            checked={questionnaire?.isUneconomic || false}
          />

          {/* NOTE' */}
          <TextArea
            placeholder={t("appraisals-conclude-practice-note-placeholder")!}
            onChange={() => {}}
            disabled={true}
            value={questionnaire?.notes || ""}
          />

          {/* {IMPONIBILE PERIZIA} */}

          <InputTextStyled
            type="number"
            classNameItem="taxable"
            label={t("appraisals-details-tab-details-taxable-appraisal-label")}
   
            onChange={() => {}}
            value={questionnaire?.totalTaxableAmount}
            disabled={true}
          />

          {/* <div className="empty-field"></div> */}

          {/* {DATA CONCLUSIONE} */}

          <DatePickerStyled
            label={t(
              "appraisals-details-tab-details-appraisal-conlusion-date-label"
            )}
            placeholder= " "
            onChange={() => {}}
            value={questionnaire?.conclusionDate}
            disabled={true}
          />
        </div>
      </div>
    </QuestionnaireWrapper>
  );
};

export default Questionnaire;
