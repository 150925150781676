import { useTranslation } from "react-i18next";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import styled from "styled-components";
import { formatDate, stringDateFormat } from "../../../../../utils/date";

const NoContactNotificationWrapper = styled.div`
    .button-wrapper {
        display: flex;
        justify-content: flex-end;
    }
`;

interface INoContactNotificationProps {
    practiceDate: string | undefined;
    onSend: () => void;
}

const NoContactNotification = (props: INoContactNotificationProps) => {
    const { practiceDate, onSend } = props;

    const { t } = useTranslation();

    const setNotificationDate = () => {
        var notificationDate = new Date(stringDateFormat(practiceDate));

        notificationDate = new Date(notificationDate.setDate(notificationDate.getDate() + 1));
        const formattedDate = formatDate(notificationDate);

        return formattedDate;
    }
    const autoNotificationDate = setNotificationDate();

    return (
        <NoContactNotificationWrapper>
            <div>L'invio automatico, se previsto per questa pratica, partirà il:  {autoNotificationDate}</div>
            <div className="button-wrapper">
                <ButtonConfirm onClick={() => onSend()}>
                    {t("button-manual-sending")!}
                </ButtonConfirm>
            </div>
        </NoContactNotificationWrapper>
    );
};

export default NoContactNotification;
