import { useEffect, useMemo, useState } from "react";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_RowSelectionState,
} from "material-react-table";
import { t } from "i18next";
import styled from "styled-components";
import {
  Address,
  AppraiserMapData,
  BulkAssignmentData,
  PracticeMapData,
} from "../../../../../redux/apiSpecifications/apiCrud";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import { Popconfirm } from "antd";
import { useTranslation } from "react-i18next";

const PracticesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
`;

const CellWrapper = styled.div`
  padding: 1em 0;

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    button {
      font-size: 0.8em;
    }
  }
`;

export type PracticeDataItem = {
  id: number;
  practiceNumber: string;
  practiceStatus: string;
  assignedAppraiser: string;
  receiptDate: string;
  appraisalLocationAddress: string;
};

interface IAssignmentMapSelectablePracticesListProps {
  appraiser: AppraiserMapData;
  practices?: PracticeMapData[] | undefined;
  onAssignPractices: (bulkAssignmentData: BulkAssignmentData) => void;
}

const AssignmentMapSelectablePracticesList = ({
  appraiser,
  practices,
  onAssignPractices,
}: IAssignmentMapSelectablePracticesListProps) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [popconfirmSave, setPopconfirmSave] = useState<boolean>(false);

  useEffect(() => {
    const result: MRT_RowSelectionState = {};

    practices?.forEach((practice) => {
      if (practice.assignedAppraiserId === appraiser.id) {
        result[practice.id!.toString()] = true;
      }
    });

    setRowSelection(result);
  }, [practices]);

  const handleFormatAddress = (
    appraisalLocationAddress: Address | undefined
  ): string => {
    if (!appraisalLocationAddress) {
      return "";
    }

    const { street, civic, city, province } = appraisalLocationAddress;

    const addressParts = [
      street,
      civic && `${civic},`,
      city,
      province && `(${province})`,
    ].filter(Boolean);

    return addressParts.join(" ");
  };

  const data = useMemo(
    () =>
      practices?.map(
        (item) =>
          ({
            id: item.id,
            practiceNumber: item.practiceNumber,
            practiceStatus: item.practiceStatus ?? "",
            assignedAppraiser: item.assignedAppraiser ?? "",
            receiptDate: item.receiptDate ?? "",
            appraisalLocationAddress:
              handleFormatAddress(item.appraisalLocationAddress) ?? "",
          } as PracticeDataItem)
      ) || [],
    [practices]
  );

  const resultsColumns = useMemo<MRT_ColumnDef<PracticeDataItem>[]>(
    () => [
      {
        accessorKey: "practiceNumber",
        header: t("assignment-map-practices-list-col-practice-number"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>{row.original.practiceNumber}</CellWrapper>
        ),
      },
      {
        accessorKey: "practiceStatus",
        header: t("assignment-map-practices-list-col-practice-status"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>
            <div style={{ textAlign: "center" }}>
              {t(
                `assignment-map-practices-list-practice-status-description-${row.original.practiceStatus}`
              )}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "assignedAppraiser",
        header: t("assignment-map-practices-list-col-assigned-appraiser"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>
            <div style={{ textAlign: "center" }}>
              {row.original.assignedAppraiser}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "appraisalLocationAddress",
        header: t(
          "assignment-map-practices-list-col-appraisal-location-address"
        ),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>
            <div style={{ textAlign: "center" }}>
              {row.original.appraisalLocationAddress}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "receiptDate",
        header: t("assignment-map-practices-list-col-receipt-date"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>
            <div style={{ textAlign: "center" }}>
              {row.original.receiptDate}
            </div>
          </CellWrapper>
        ),
      },
    ],
    [practices]
  );

  const handleAssign = async () => {
    const bulkAssignmentData: BulkAssignmentData = {
      appraiserId: appraiser.id,
      assignments: [],
    };

    practices?.forEach((practice) => {
      if (rowSelection[practice.id!.toString()] === true) {
        if (practice.assignedAppraiserId !== appraiser.id) {
          bulkAssignmentData.assignments?.push({
            practiceId: practice.id,
            isToBeAssigned: true,
          });
        }
      } else if (practice.assignedAppraiserId === appraiser.id) {
        bulkAssignmentData.assignments?.push({
          practiceId: practice.id,
          isToBeAssigned: false,
        });
      }
    });

    onAssignPractices(bulkAssignmentData);
  };

  const disableAssignButton = useMemo(() => {
    let result = true;

    practices?.forEach((practice) => {
      if (rowSelection[practice.id!.toString()] === true) {
        if (practice.assignedAppraiserId !== appraiser.id) {
          result = false;
        }
      } else if (practice.assignedAppraiserId === appraiser.id) {
        result = false;
      }
    });

    return result;
  }, [practices, rowSelection]);

  return (
    <PracticesListWrapper>
      {/* <pre>{JSON.stringify(rowSelection, null, 2)}</pre> */}
      <MaterialReactTable
        columns={resultsColumns}
        data={data}
        enableDensityToggle={false}
        enableColumnOrdering
        onPaginationChange={setPagination}
        enableRowSelection={true}
        getRowId={(row: PracticeDataItem) => row.id.toString()}
        onRowSelectionChange={setRowSelection}
        state={{ rowSelection, pagination }}
        muiTableBodyCellProps={{
          sx: {
            textAlign: "left",
          },
        }}
      />

      <div className="button-wrapper">
        <Popconfirm
          placement="bottomRight"
          icon={null}
          title={t("assignment-map-practice-list-button-save-confirm")}
          onConfirm={handleAssign}
          onCancel={() => setPopconfirmSave(false)}
          okText={t("switch-yes")!}
          cancelText={t("switch-no")!}
        >
          <ButtonConfirm onClick={() => {}} disabled={disableAssignButton}>
            {t("button-assign")!}
          </ButtonConfirm>
        </Popconfirm>
      </div>
    </PracticesListWrapper>
  );
};

export default AssignmentMapSelectablePracticesList;
