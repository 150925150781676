import { baseSplitApiClaims as api } from "./config/baseApiClaims";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createClaimDraft: build.mutation<
      CreateClaimDraftApiResponse,
      CreateClaimDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/claims/`,
        method: "POST",
        body: queryArg.createdClaim,
      }),
    }),
    getClaim: build.query<GetClaimApiResponse, GetClaimApiArg>({
      query: (queryArg) => ({ url: `/claims/${queryArg.id}` }),
    }),
    updateClaim: build.mutation<UpdateClaimApiResponse, UpdateClaimApiArg>({
      query: (queryArg) => ({
        url: `/claims/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updatedClaim,
      }),
    }),
    deleteClaimDraft: build.mutation<
      DeleteClaimDraftApiResponse,
      DeleteClaimDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/claims/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getSubjectsVersionByClaim: build.query<
      GetSubjectsVersionByClaimApiResponse,
      GetSubjectsVersionByClaimApiArg
    >({
      query: (queryArg) => ({
        url: `/claims/${queryArg.claimId}/subjects/${queryArg.subjectId}`,
      }),
    }),
    changeClaimPolicy: build.mutation<
      ChangeClaimPolicyApiResponse,
      ChangeClaimPolicyApiArg
    >({
      query: (queryArg) => ({
        url: `/claims/${queryArg.id}/change-policy`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    searchClaims: build.mutation<SearchClaimsApiResponse, SearchClaimsApiArg>({
      query: (queryArg) => ({
        url: `/claims/search`,
        method: "POST",
        body: queryArg.searchClaimsParams,
        params: {
          pageNum: queryArg.pageNum,
          pageLen: queryArg.pageLen,
          scenario: queryArg.scenario,
          activeRole: queryArg.activeRole,
        },
      }),
    }),
    getClaimsCompanies: build.query<
      GetClaimsCompaniesApiResponse,
      GetClaimsCompaniesApiArg
    >({
      query: () => ({ url: `/claims/companies` }),
    }),
    createOrUpdateClaimsCompanyData: build.mutation<
      CreateOrUpdateClaimsCompanyDataApiResponse,
      CreateOrUpdateClaimsCompanyDataApiArg
    >({
      query: (queryArg) => ({
        url: `/claims/companies`,
        method: "POST",
        body: queryArg.claimsCompany,
      }),
    }),
    getClaimsCompanyData: build.query<
      GetClaimsCompanyDataApiResponse,
      GetClaimsCompanyDataApiArg
    >({
      query: (queryArg) => ({ url: `/claims/companies/${queryArg.id}` }),
    }),
    getClaimsSummaryA1: build.query<
      GetClaimsSummaryA1ApiResponse,
      GetClaimsSummaryA1ApiArg
    >({
      query: (queryArg) => ({
        url: `/claims/summary/A1`,
        params: { tenants: queryArg.tenants },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiClaims };
export type CreateClaimDraftApiResponse =
  /** status 200 a JSON representing the current claim */ ReadOnlyClaim;
export type CreateClaimDraftApiArg = {
  createdClaim: CreatedClaim;
};
export type GetClaimApiResponse =
  /** status 200 a JSON representing the current claim */ ReadOnlyClaim;
export type GetClaimApiArg = {
  /** Numeric Id of the resource */
  id: number;
};
export type UpdateClaimApiResponse =
  /** status 200 a JSON representing the current claim */ ReadOnlyClaim;
export type UpdateClaimApiArg = {
  /** Numeric Id of the resource */
  id: number;
  updatedClaim: UpdatedClaim;
};
export type DeleteClaimDraftApiResponse = unknown;
export type DeleteClaimDraftApiArg = {
  /** Numeric Id of the resource */
  id: number;
};
export type GetSubjectsVersionByClaimApiResponse =
  /** status 200 a JSON representing subject'd data */ Subject;
export type GetSubjectsVersionByClaimApiArg = {
  /** resource id */
  claimId: number;
  /** resource id */
  subjectId: number;
};
export type ChangeClaimPolicyApiResponse =
  /** status 200 a JSON representing the current claim */ ReadOnlyPolicy;
export type ChangeClaimPolicyApiArg = {
  /** Numeric Id of the resource */
  id: number;
  body: number;
};
export type SearchClaimsApiResponse =
  /** status 200 a searched claims list */ SearchedClaimsList;
export type SearchClaimsApiArg = {
  /** result page number */
  pageNum: number;
  /** result page item list */
  pageLen: number;
  /** identifie the scenario to get items list from the correct source */
  scenario: string;
  /** the active role selected by the user */
  activeRole: string;
  searchClaimsParams: SearchClaimsParams;
};
export type GetClaimsCompaniesApiResponse =
  /** status 200 a JSON representing claims companies list data */ ClaimsCompanyItem[];
export type GetClaimsCompaniesApiArg = void;
export type CreateOrUpdateClaimsCompanyDataApiResponse =
  /** status 200 a JSON representing a claims company data */ ClaimsCompany;
export type CreateOrUpdateClaimsCompanyDataApiArg = {
  claimsCompany: ClaimsCompany;
};
export type GetClaimsCompanyDataApiResponse =
  /** status 200 a JSON representing a claims company data */ ClaimsCompany;
export type GetClaimsCompanyDataApiArg = {
  /** Numeric Id of the resource */
  id: number;
};
export type GetClaimsSummaryA1ApiResponse =
  /** status 200 a JSON representing a claims company data */ DashboardA1Summary;
export type GetClaimsSummaryA1ApiArg = {
  /** enabled tenants */
  tenants: number[];
};
export type ClaimAddress = {
  city?: string;
  civic?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  postalCode?: string;
  province?: string;
  street?: string;
};
export type Event = ClaimAddress & {
  date?: string;
  time?: string;
};
export type CreatedClaim = Event & {
  companyId?: number;
  policyId?: number;
  claimsBranchCode?: string;
};
export type UpdatedClaim = {
  id?: number;
  reportingDate?: string;
  companyReceiptDate?: string;
  receiptDate?: string;
  customerReferenceNumber?: string;
  uciReferenceNumber?: string;
  consapReferenceNumber?: string;
  branchGroupId?: number;
};
export type Vehicle = {
  id?: number;
  plateNumber?: string;
  chassisNumber?: string;
  type?: string;
  brand?: string;
  model?: string;
};
export type Guarantee = {
  id?: number;
  branch?: string;
  excessAmount?: number;
  overdraftAmount?: number;
};
export type BasePolicy = {
  id?: number;
  numberCompany?: string;
  tenantId?: number;
  effectDate?: string;
  expirationDate?: string;
  administrativeCompliance?: boolean;
  insuredProperty?: Vehicle;
  guarantees?: Guarantee[];
};
export type BirthDateAndPlace = {
  date?: string;
  city?: string;
  province?: string;
  country?: string;
};
export type Address = {
  id?: number;
  type?: string;
  street?: string;
  civic?: string;
  postalCode?: string;
  city?: string;
  province?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  preferred?: boolean;
  isDeleted?: boolean;
  isIncorrect?: boolean;
};
export type Contact = {
  id?: number;
  type?: string;
  value?: string;
  description?: string;
  useCase?: string;
  preferred?: boolean;
  isDeleted?: boolean;
};
export type SubjectDocument = {
  id?: number;
  documentCode?: string;
  type?: string;
  number?: string;
  issuingDate?: string;
  expirationDate?: string;
  issuingInstitution?: string;
};
export type SubjectPayment = {
  id?: number;
  type?: string;
  iban?: string | null;
  bicSwift?: string | null;
  addressSendCheck?: Address | null;
  preferred?: boolean;
};
export type Subject = {
  id?: number;
  subjectType?: string;
  name?: string;
  lastname?: string;
  gender?: string;
  fiscalCode?: string;
  birth?: BirthDateAndPlace | null;
  businessName?: string;
  giuridicalType?: string;
  registeredOffice?: Address | null;
  pIva?: string;
  contacts?: Contact[] | null;
  addresses?: Address[] | null;
  documents?: SubjectDocument[] | null;
  payments?: SubjectPayment[] | null;
};
export type PolicySubject = Subject & {
  id?: number;
  roleCode?: string;
  roleId?: number;
};
export type ReadOnlyPolicy = BasePolicy & {
  number?: string;
  company?: string;
  policySubjects?: PolicySubject[];
};
export type CounterpartyVehicle = Vehicle & {
  id?: number;
  insuranceCompany?: string;
  insuranceCompanyCode?: string;
  hasFleetPolicy?: boolean;
  hasBlackBox?: boolean;
  isResponsibleOrDamaged?: string;
  owner?: Subject;
  driver?: Subject & {
    sameAsOwner?: boolean;
  };
};
export type ReadOnlyClaim = CreatedClaim &
  UpdatedClaim & {
    status?: string;
    creationDate?: string;
    company?: string;
    number?: string;
    branchGroup?: string;
    isCard?: boolean;
    isManaged?: boolean;
    openingDate?: string;
    conclusionDate?: string;
    compensationAdjuster?: string;
    isReopened?: string;
    reportingDate?: string;
    companyReceiptDate?: string;
    receiptDate?: string;
    customerReferenceNumber?: string;
    uciReferenceNumber?: string;
    consapReferenceNumber?: string;
    policy?: ReadOnlyPolicy;
    counterparties?: CounterpartyVehicle[];
  };
export type ClaimsListItem = {
  id?: number;
  companyId?: number;
  claimNumber?: string;
  custoReferenceNumber?: string;
  claimType?: string;
  claimStatusCode?: string;
  claimWorkflow?: string;
  signatureTypeCode?: string;
  plateNumberList?: string[];
  policyNumber?: string;
  subjectName?: string;
  subjectTaxId?: string;
  lossAdjuster?: string;
  openingDate?: string;
};
export type SearchedClaimsList = {
  totalCount?: number;
  currentPageNum?: number;
  totalPageNum?: number;
  pageLen?: number;
  items?: ClaimsListItem[];
};
export type SearchClaimsParams = {
  companyList?: number[];
  claimNumberList?: string[];
  claimType?: string;
  claimStatus?: string;
  claimWorkflow?: string;
  subjectName?: string;
  subjectTaxId?: string;
  plateNumberList?: string[];
  policyNumber?: string;
  isReopened?: boolean;
};
export type ClaimsCompanyItem = {
  id?: number;
  businessName?: string;
};
export type ClaimsCompany = {
  registryInfo?: Subject;
  department?: string;
  coordinationCenterId?: number;
  group?: string;
  isBillingEnabled?: boolean;
  sapCode?: string;
  aniaCode?: string;
  uciCode?: string;
  notes?: string;
  lossProvisionDefaults?: {
    injuriesAmount?: number;
    vehiclesAmount?: number;
    propertiesAmount?: number;
  };
};
export type TotalByCategory = {
  category?: string;
  total?: number;
};
export type DashboardA1Summary = {
  inDraft?: TotalByCategory[];
  inProgress?: TotalByCategory[];
  inProgressReopened?: TotalByCategory[];
};
export const {
  useCreateClaimDraftMutation,
  useGetClaimQuery,
  useLazyGetClaimQuery,
  useUpdateClaimMutation,
  useDeleteClaimDraftMutation,
  useGetSubjectsVersionByClaimQuery,
  useLazyGetSubjectsVersionByClaimQuery,
  useChangeClaimPolicyMutation,
  useSearchClaimsMutation,
  useGetClaimsCompaniesQuery,
  useLazyGetClaimsCompaniesQuery,
  useCreateOrUpdateClaimsCompanyDataMutation,
  useGetClaimsCompanyDataQuery,
  useLazyGetClaimsCompanyDataQuery,
  useGetClaimsSummaryA1Query,
  useLazyGetClaimsSummaryA1Query,
} = injectedRtkApi;
