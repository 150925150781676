import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Pie, PieChart, Legend } from "recharts";
import DonutChart, { DonutDataItemType } from "../../Charts/DonutChart";
import { useEffect, useState } from "react";
import { widgets as testIds } from "../../../config/testIds";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import WidgetHeader from "../WidgetHeader";

const PracticesToManageWrapper = styled(WidgetWrapper)`
  flex-direction: column;
  align-items: stretch;

  max-height: 2.5em;
  transition: max-height 1s;

  &.open {
    max-height: 100em;
  }
`;

export type PracticesToManageType = {
  total: number | undefined;
  urgent:
    | {
        rca: number | undefined;
        cvt: number | undefined;
      }
    | undefined;
  due:
    | {
        rca: number | undefined;
        cvt: number | undefined;
      }
    | undefined;
  new:
    | {
        rca: number | undefined;
        cvt: number | undefined;
      }
    | undefined;
  suspended:
    | {
        rca: number | undefined;
        cvt: number | undefined;
      }
    | undefined;
};

const summRcaCvt = (type: any | undefined) =>
  (type?.rca || 0) + (type?.cvt || 0);

export interface IPracticesToManageProps {
  practices: PracticesToManageType | undefined;
  totalSelected: string | undefined;
  statusSelected: string | undefined;
  onSelect: (
    totalType: string | undefined,
    drillType: string | undefined
  ) => void;
  gridArea?: string;
  isActive?: boolean;
  isOpen?: boolean;
  selectedLevel1?: string;
  selectedLevel2?: string;
}

const PracticesToManage = (props: IPracticesToManageProps) => {
  const { t } = useTranslation();
  const {
    practices,
    onSelect,
    statusSelected = "daily",
    totalSelected,
    gridArea,
    isActive = false,
    selectedLevel1,
    selectedLevel2,
  } = props;
  const { urgent, due, new: newPractices, suspended } = practices || {};

  const [dataDrill, setDataDrill] = useState<DonutDataItemType[]>([]);
  const [dataTotal, setDataTotal] = useState<DonutDataItemType[]>([]);

  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const hasSomePractice = (type: any) => type?.rca || type?.cvt;

  // set default order "urgent" >> "due" >> "new"
  const defaultTotalSelected = totalSelected
    ? totalSelected
    : hasSomePractice(practices?.urgent)
    ? "urgent"
    : hasSomePractice(practices?.due)
    ? "due"
    : hasSomePractice(practices?.new)
    ? "new"
    : hasSomePractice(practices?.suspended)
    ? "suspended"
    : "";

  useEffect(() => {
    setDataTotal([
      {
        key: "urgent",
        name: t("appraisals-dashboard-to-manage-urgent"),
        value: summRcaCvt(urgent),
        fill: "#E24D28",
      },
      {
        key: "due",
        name: t("appraisals-dashboard-to-manage-due"),
        value: summRcaCvt(due),
        fill: "#FFCC00",
      },
      {
        key: "new",
        name: t("appraisals-dashboard-to-manage-new"),
        value: summRcaCvt(newPractices),
        fill: "#8FBC8F",
      },
      {
        key: "suspended",
        name: t("appraisals-dashboard-to-manage-suspended"),
        value: summRcaCvt(suspended),
        fill: "#8B8D8F",
      },
    ]);

    let rcaDrill = 0;
    let cvtDrill = 0;

    switch (defaultTotalSelected) {
      case "urgent":
        rcaDrill = urgent?.rca || 0;
        cvtDrill = urgent?.cvt || 0;
        setDataDrill([
          {
            key: "rca",
            name: t("appraisals-dashboard-to-manage-rca"),
            value: rcaDrill,
            fill: "#D7431D",
          },
          {
            key: "cvt",
            name: t("appraisals-dashboard-to-manage-cvt"),
            value: cvtDrill,
            fill: "#EC716F",
          },
        ]);
        break;
      case "due":
        rcaDrill = due?.rca || 0;
        cvtDrill = due?.cvt || 0;
        setDataDrill([
          {
            key: "rca",
            name: t("appraisals-dashboard-to-manage-rca"),
            value: rcaDrill,
            fill: "#F5C400",
          },
          {
            key: "cvt",
            name: t("appraisals-dashboard-to-manage-cvt"),
            value: cvtDrill,
            fill: "#FFDE5C",
          },
        ]);
        break;
      case "new":
        rcaDrill = newPractices?.rca || 0;
        cvtDrill = newPractices?.cvt || 0;
        setDataDrill([
          {
            key: "rca",
            name: t("appraisals-dashboard-to-manage-rca"),
            value: rcaDrill,
            fill: "#67A267",
          },
          {
            key: "cvt",
            name: t("appraisals-dashboard-to-manage-cvt"),
            value: cvtDrill,
            fill: "#A6C9A6",
          },
        ]);
        break;
      case "suspended":
        rcaDrill = suspended?.rca || 0;
        cvtDrill = suspended?.cvt || 0;
        setDataDrill([
          {
            key: "rca",
            name: t("appraisals-dashboard-to-manage-rca"),
            value: rcaDrill,
            fill: "#828587",
          },
          {
            key: "cvt",
            name: t("appraisals-dashboard-to-manage-cvt"),
            value: cvtDrill,
            fill: "#A1A3A5",
          },
        ]);
        break;
      default:
        rcaDrill = urgent?.rca || 0;
        cvtDrill = urgent?.cvt || 0;
        setDataDrill([
          {
            key: "rca",
            name: t("appraisals-dashboard-to-manage-rca"),
            value: rcaDrill,
            fill: "#D7431D",
          },
          {
            key: "cvt",
            name: t("appraisals-dashboard-to-manage-cvt"),
            value: cvtDrill,
            fill: "#EC716F",
          },
        ]);
        break;
    }
  }, [practices, defaultTotalSelected]);

  const handleOnClickTotal = (key: string | undefined) => {
    onSelect(key, undefined);
  };

  const handleOnClickDrill = (key: string | undefined) => {
    onSelect(defaultTotalSelected, key);
  };

  const handleFilterReset = () => onSelect("urgent", undefined);
  const handleOnOpen = () => setIsOpen(true);
  const handleOnClose = () => setIsOpen(false);

  const _total = dataTotal.reduce((acc, cur) => acc + cur.value, 0).toString();
  const _totalDril = dataDrill
    .reduce((acc, cur) => acc + cur.value, 0)
    .toString();

  return (
    <PracticesToManageWrapper
      className={`${isActive || isOpen ? "open" : ""}`}
      data-testid={testIds.appraisalsToManage.container}
      gridArea={gridArea}
    >
      <WidgetHeader
        title={t("appraisals-dashboard-to-manage-title")}
        isActive={isActive}
        filterReset={handleFilterReset}
        onOpen={handleOnOpen}
        onClose={handleOnClose}
        isOpen={isOpen}
      />

      <div className="body">
        <div className="col">
          <DonutChart
            title={t("appraisals-dashboard-to-manage-discarded")}
            data={dataTotal}
            onClick={handleOnClickTotal}
            centerValue={_total}
            selected={isActive ? selectedLevel1 : ""}
          />
        </div>
        <div className="col">
          <DonutChart
            title={t(`appraisals-dashboard-to-manage-${defaultTotalSelected}`)}
            data={dataDrill}
            onClick={handleOnClickDrill}
            centerValue={_totalDril}
            selected={isActive ? selectedLevel2?.toLowerCase() : ""}
            unselectOnClick={true}
          />
        </div>
      </div>
    </PracticesToManageWrapper>
  );
};

export default PracticesToManage;
