import { useTranslation } from "react-i18next";
import { AppraiserUser } from "../../../../../redux/apiSpecifications/apiFesf";
import { ContentWrapper, GenericWrapper } from "../../Commons/Wrappers";
import AppraiserDetailsSubjectAdditionalInfo from "./AppraiserDetailsSubjectAdditionalInfo";
import AppraiserDetailsSubjectPersonalData from "./AppraiserDetailsSubjectPersonalData";
import styled from "styled-components";
import { useEffect } from "react";
import CodiceFiscale from "codice-fiscale-js";
import { useDispatch } from "react-redux";
import { mainTabs } from "../../../../../config/testIds";
import { setValidation } from "../../../../../redux/features/appraisersSlice";
import AppraiserPaymentMethod from "./AppraiserPaymentMethod";

const testIds = mainTabs.tabs.supplierNetwork.appraiserDetails.subject;

const AppraisalDetailsSubjectContainer = styled(ContentWrapper)`
    overflow: auto;
    max-height: calc(100vh - 16em);
    padding-right: 2em;
`;

const AppraiserDetailsSubjectWrapper = styled(GenericWrapper)`
    display: flex;
    flex-direction: column;

    .button-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2em;
    }
`;

const isEmptyOrNull = (str: string | undefined) =>
    !str || str.replaceAll(" ", "").length == 0;

interface IAppraiserDetailsSubjectProps {
    appraiser: AppraiserUser | undefined;
    onChange: (appraiser: AppraiserUser) => void;
}

const AppraiserDetailsSubject = (props: IAppraiserDetailsSubjectProps) => {
    const { t } = useTranslation();
    const { appraiser, onChange } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        if (!appraiser) return;
        validateAppraiser(appraiser);
    }, [appraiser]);

    const handleOnChange = (appraiser: AppraiserUser) => {
        validateAppraiser(appraiser);
        onChange(appraiser);
    };

    const validateAppraiser = (appraiser: AppraiserUser) => {
        let isValid = true;
        if (appraiser.subjectType === "PF") {
            if (isEmptyOrNull(appraiser.name)) isValid = false;
            if (isEmptyOrNull(appraiser.lastname)) isValid = false;
            else {
                try {
                    new CodiceFiscale(appraiser.fiscalCode!);
                } catch (e) {
                    isValid = false;
                }
            }
        } else if (appraiser.subjectType === "PG") {
            if (isEmptyOrNull(appraiser.businessName)) isValid = false;
            if (isEmptyOrNull(appraiser.giuridicalType)) isValid = false;
        }

        dispatch(
            setValidation({
                id: appraiser.id!,
                validationErrors: {
                    subject: isValid ? [] : ["invalida data"],
                },
            })
        );
    };

    return (
        <AppraisalDetailsSubjectContainer>
            <AppraiserDetailsSubjectWrapper data-testid={testIds.container}>
                <AppraiserDetailsSubjectPersonalData
                    appraiser={appraiser}
                    onChange={handleOnChange}
                />

                <AppraiserPaymentMethod
                    appraiser={appraiser}
                    onChange={handleOnChange}
                />

                <AppraiserDetailsSubjectAdditionalInfo
                    appraiser={appraiser}
                    onChange={(a) => handleOnChange(a)}
                />
            </AppraiserDetailsSubjectWrapper>
        </AppraisalDetailsSubjectContainer>
    );
};

export default AppraiserDetailsSubject;
