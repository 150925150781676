import { useTranslation } from "react-i18next";
import { InputTextStyled } from "../../../style/Input";
import { TenantAppraisalExpirationDatesConfig } from "../../../redux/apiSpecifications/apiFesf";
import styled from "styled-components";

const AppraisalExpirationDatesFormWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 1em;
    gap: 2em 10em;
`;

const SectionWrapper = styled.div`
    margin-top: 1em;

    .inner-wrapper {
        .header {
            text-align: center;
            font-size: 1em;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-bottom: 2em;
        }

        th.sign-type {
            width: 150px;
        }

        th.input {
            width: 100px;
        }
    }
`;

const AppraisalAndClientWrapper = styled(SectionWrapper)`
    // width: 600px;
`;

const IVASSWrapper = styled(SectionWrapper)`
    // width: 300px;
`;

interface IAppraisalExpirationDatesFormProps {
    data: TenantAppraisalExpirationDatesConfig;
    onChange: (value: string, field: string) => void;
}

const AppraisalExpirationDatesForm = ({
    data,
    onChange,
}: IAppraisalExpirationDatesFormProps) => {
    const { t } = useTranslation();

    // const handleOnChange = (value: string, field: string) => {
    //     let str = field as keyof typeof data;

    //     if (value === "") onChange("", field);
    //     else if (/^\d+$/.test(value)) {
    //         if (value.length === 2 && value.startsWith("0")) {
    //             onChange(value.substring(1), field);
    //         } else {
    //             onChange(value, field);
    //         }
    //     } else {
    //         onChange(data[str] ? data[str]?.toString() : "", field);
    //     }
    // };

    return (
        <AppraisalExpirationDatesFormWrapper>
            <AppraisalAndClientWrapper>
                <div className="inner-wrapper">
                    <div className="header">
                        {t(
                            "appraisal-expiration-dates-appraisal-and-client-header"
                        )}
                    </div>

                    <div className="content">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        {t(
                                            "appraisal-expiration-dates-table-header-assignment-type"
                                        )}
                                    </th>
                                    <th className="claim-type">
                                        {t(
                                            "appraisal-expiration-dates-table-header-claim-type"
                                        )}
                                    </th>
                                    <th className="sign-type">
                                        {t(
                                            "appraisal-expiration-dates-table-header-sign-type"
                                        )}
                                    </th>
                                    <th className="input">
                                        {t(
                                            "appraisal-expiration-dates-table-header-expiration-date-client"
                                        )}
                                    </th>
                                    <th className="input">
                                        {t(
                                            "appraisal-expiration-dates-table-header-expiration-date-appraiser"
                                        )}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-assignment-type-expertize"
                                        )}
                                    </td>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-claim-type-CVT"
                                        )}
                                    </td>
                                    <td align="center">-</td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateclientappraisalcvt
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateclientappraisalcvt"
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateappraiserappraisalcvt
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateappraiserappraisalcvt"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-assignment-type-expertize"
                                        )}
                                    </td>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-claim-type-RCA"
                                        )}
                                    </td>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-sign-type-mono"
                                        )}
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateclientappraisalrca1
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateclientappraisalrca1"
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateappraiserappraisalrca1
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateappraiserappraisalrca1"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-assignment-type-expertize"
                                        )}
                                    </td>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-claim-type-RCA"
                                        )}
                                    </td>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-sign-type-double"
                                        )}
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateclientappraisalrca2
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateclientappraisalrca2"
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateappraiserappraisalrca2
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateappraiserappraisalrca2"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-assignment-type-expertize"
                                        )}
                                    </td>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-claim-type-RCA"
                                        )}
                                    </td>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-sign-type-no-card"
                                        )}
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateclientappraisalrca0
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateclientappraisalrca0"
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateappraiserappraisalrca0
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateappraiserappraisalrca0"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-assignment-type-feedback"
                                        )}
                                    </td>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-claim-type-RCA"
                                        )}
                                    </td>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-sign-type-mono"
                                        )}
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateclientfeedbackrca1
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateclientfeedbackrca1"
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateappraiserfeedbackrca1
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateappraiserfeedbackrca1"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-assignment-type-feedback"
                                        )}
                                    </td>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-claim-type-RCA"
                                        )}
                                    </td>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-sign-type-double"
                                        )}
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateclientfeedbackrca2
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateclientfeedbackrca2"
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateappraiserfeedbackrca2
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateappraiserfeedbackrca2"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-assignment-type-location-status"
                                        )}
                                    </td>
                                    <td align="center">-</td>
                                    <td align="center">-</td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateclientlocationstatus
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateclientlocationstatus"
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateappraiserlocationstatus
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateappraiserlocationstatus"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-assignment-type-complaint-recovery"
                                        )}
                                    </td>
                                    <td align="center">-</td>
                                    <td align="center">-</td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateclientcomplaintrecovery
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateclientcomplaintrecovery"
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateappraisercomplaintrecovery
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateappraisercomplaintrecovery"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-assignment-type-congruity-request"
                                        )}
                                    </td>
                                    <td align="center">-</td>
                                    <td align="center">-</td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateclientcongruityrequest
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateclientcongruityrequest"
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateappraisercongruityrequest
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateappraisercongruityrequest"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-assignment-type-cinematic"
                                        )}
                                    </td>
                                    <td align="center">-</td>
                                    <td align="center">-</td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateclientcinematic
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateclientcinematic"
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateappraisercinematic
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateappraisercinematic"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-assignment-type-comparative"
                                        )}
                                    </td>
                                    <td align="center">-</td>
                                    <td align="center">-</td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateclientcomparative
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateclientcomparative"
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateappraisercomparative
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateappraisercomparative"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-assignment-type-CPT"
                                        )}
                                    </td>
                                    <td align="center">-</td>
                                    <td align="center">-</td>
                                    <td>
                                        <InputTextStyled
                                            value={data.expirationdateclientctp}
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateclientctp"
                                                );
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <InputTextStyled
                                            value={
                                                data.expirationdateappraiserctp
                                            }
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateappraiserctp"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </AppraisalAndClientWrapper>

            <IVASSWrapper>
                <div className="inner-wrapper">
                    <div className="header">
                        {t("appraisal-expiration-dates-ivass-header")}
                    </div>

                    <div className="content">
                        <table>
                            <thead>
                                <tr>
                                    <th className="sign-type">
                                        {t(
                                            "appraisal-expiration-dates-table-header-sign-type"
                                        )}
                                    </th>
                                    <th className="input">
                                        {t(
                                            "appraisal-expiration-dates-table-header-expiration-date-IVASS"
                                        )}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="sign-type" align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-sign-type-mono"
                                        )}
                                    </td>

                                    <td>
                                        <InputTextStyled
                                            value={data.expirationdateivassrca1}
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateivassrca1"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="sign-type" align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-sign-type-double"
                                        )}
                                    </td>

                                    <td>
                                        <InputTextStyled
                                            value={data.expirationdateivassrca2}
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateivassrca2"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="sign-type" align="center">
                                        {t(
                                            "appraisal-expiration-dates-table-cell-sign-type-no-card"
                                        )}
                                    </td>

                                    <td className="input">
                                        <InputTextStyled
                                            value={data.expirationdateivassrca0}
                                            onChange={(value) => {
                                                onChange(
                                                    value,
                                                    "expirationdateivassrca0"
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </IVASSWrapper>
        </AppraisalExpirationDatesFormWrapper>
    );
};

export default AppraisalExpirationDatesForm;
