import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TbUrgent } from "react-icons/tb";
import {
  MdHourglassEmpty,
  MdOutlineNotificationImportant,
} from "react-icons/md";
import { RxExclamationTriangle } from "react-icons/rx";
import { RiThumbUpLine } from "react-icons/ri";
import { WidgetWrapper } from "../../../../style/DashbordWidgetWrappers";
import dekra from "../../../../style/dekra";
import {
  FilterPriorityValues,
  FilterStatusValues,
  StatusTypes,
} from "../../Dashboards/Dashboard-E2";
import { IconRemoveFilter } from "../../../../config/icons";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

const RowWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
`;

const AppraiserFilterWrapper = styled(WidgetWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  .appraiser {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0.25em 1em;

    border-right: 2px solid #bbb;
    color: #333;
    text-transform: uppercase;
    font-size: 0.9em;

    &:hover {
      color: ${dekra.primaryColor};
      cursor: pointer;

      &.empty {
        cursor: default;
      }
    }

    &.active {
      background-color: ${dekra.primaryColor};
      height: 100%;
      svg {
        color: ${dekra.secondaryColors.white};
      }

      .text {
        color: ${dekra.secondaryColors.white};
      }

      .value {
        color: ${dekra.secondaryColors.white};
      }

      &:hover {
        svg {
          color: ${dekra.secondaryColors.white};
        }
        .text {
          color: ${dekra.secondaryColors.white};
        }
        .value {
          color: ${dekra.secondaryColors.white};
        }
      }
    }

    &:last-child {
      border-right: none;
    }

    svg {
      font-size: 1.6em;
      color: #aaa;
    }

    .clickable {
      display: flex;
      align-items: center;

      letter-spacing: 1px;

      .text {
        margin-left: 0.5em;
        align-self: flex-end;
      }

      .value {
        margin-left: 0.5em;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .reset-filter {
    padding: 0 0.5em;
    font-size: 1.2em;
    color: #555;

    .icon-wrapper {
      cursor: pointer;
      margin-top: 5px;
    }
  }
`;

export type AppraiserFilterValues = {
  internal: number;
  external: number;
};

export type AppraiserType = "I" | "E" | undefined;

export interface IAppraiserProps {
  values: AppraiserFilterValues;
  onSelect: (appraiser: AppraiserType) => void;
  selected?: AppraiserType;
}

const AppriaserFilter = (props: IAppraiserProps) => {
  const { values, onSelect, selected } = props;

  const { t } = useTranslation();

  const handleResetFilter = () => onSelect(undefined);

  return (
    <RowWrapper>
      <AppraiserFilterWrapper>
        <div
          className={`appraiser ${selected === "I" ? "active" : ""}`}
          onClick={() => onSelect("I")}
        >
          <AiOutlineCheckCircle />
          <div className="clickable">
            <div className="text">{t(`appraisal-internal`)}</div>
            <div className="value">{values.internal}</div>
          </div>
        </div>

        <div
          className={`appraiser ${selected === "E" ? "active" : ""}`}
          onClick={() => onSelect("E")}
        >
          <AiOutlineCheckCircle />
          <div className="clickable">
            <div className="text">{t(`appraisal-external`)}</div>
            <div className="value">{values.external}</div>
          </div>
        </div>

        <div className="reset-filter">
          <div className="icon-wrapper" onClick={handleResetFilter}>
            <IconRemoveFilter />
          </div>
        </div>
      </AppraiserFilterWrapper>
    </RowWrapper>
  );
};

export default AppriaserFilter;
