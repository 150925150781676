import { useTranslation } from "react-i18next";
import { SelectStyled } from "../../style/Input";
import countriesJson from "../../config/countries.json";
import { SelectPair } from "../../types/common.types";

type countryJsonType = {
	name: string;
	code: string;
};

const countries = countriesJson.map((country: countryJsonType) => ({ value: country.name, label: country.name } as SelectPair));

interface SelectCountryProps {
	required?: boolean | undefined;
	onChange: (code: string) => void;
	value: string | undefined;
	dataTestId?: string | undefined;
}

const SelectCountry = (props: SelectCountryProps) => {
	const { t } = useTranslation();

	return (
		<SelectStyled
			label={`${t("select-country-label")} ${props.required ? "*" : ""}`}
			tooltip={t("select-country-tooltip")}
			onChange={props.onChange}
			value={props.value}
			options={countries}
			showSearch={true}
			dataTestId={props.dataTestId}
		/>
	);
};

export default SelectCountry;
