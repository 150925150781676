import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useExpertizeScoreContext } from "../ExpertizeScoreContext";
import ExpertizerScoreInputText from "../ExpertizerScoreInputText";
import { Tooltip } from "antd";
import { IoMdHelpCircleOutline } from "react-icons/io";
import AppraiserScoreTenantConfigForm from "./AppraiserScoreTenantConfigForm";
import { updateObject } from "../../../../utils/updateObject";

const ThresoldsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; /* Allinea le tabelle in alto */
    width: 100%;

    table {
        width: 450px;
    }
`;
const ThresoldsWrapperLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    .table-wrapper:last-child {
        margin-right: 0;
    }

    .label {
        padding-right: 0.5em;
    }

    .value {
        width: 6em;
        height: 2em;
        border-radius: 5px;
        margin-right: 1em;
    }

    div.tenant-configs-box {
        display: flex;
        flex-direction: column;
        gap: 2em;

        div.tenant-config-box {
            div.title {
                text-align: center;
                background-color: rgb(245, 245, 245);
            }
        }
    }
`;

const ThresoldsWrapperRight = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    .table-wrapper:last-child {
        margin-right: 0;
    }

    .label {
        padding-right: 0.5em;
    }

    .value {
        width: 6em;
        height: 2em;
        border-radius: 5px;
        margin-right: 1em;
    }
`;

const AppraiserScoreConfigForm = () => {
    const { t } = useTranslation();

    const { selectedClusterConfig, onChangeSelectedClusterConfig } =
        useExpertizeScoreContext();

    const values = useMemo(() => {
        return {
            confirmationReactivity: {
                Threshold:
                    selectedClusterConfig?.confirmationReactivityThreshold || 0,
                Weight:
                    selectedClusterConfig?.confirmationReactivityWeight || 0,
            },
            visionReactivity: {
                Threshold:
                    selectedClusterConfig?.visionReactivityThreshold || 0,
                Weight: selectedClusterConfig?.visionReactivityWeight || 0,
            },
            appraisalTime: {
                Threshold:
                    selectedClusterConfig?.appraisalTimeThreshold || 0,
                Weight: selectedClusterConfig?.appraisalTimeWeight || 0,
            },
            meanAppraisalAmount: {
                Threshold:
                    // selectedClusterConfig?.meanAppraisAlaverageAmountThreshold ||
                    0,
                Weight:
                    selectedClusterConfig?.meanAppraisalAmountWeight ||
                    0,
            },
            //seconda tabella
            accessesCount: {
                Threshold: selectedClusterConfig?.accessesCountThreshold || 0,
                Weight: selectedClusterConfig?.accessesCountWeight || 0,
            },
            noAccessesCount: {
                Threshold: selectedClusterConfig?.noAccessesCountThreshold || 0,
                Weight: selectedClusterConfig?.noAccessesCountWeight || 0,
            },
            agreementsCount: {
                Threshold: selectedClusterConfig?.agreementsCountThreshold || 0,
                Weight: selectedClusterConfig?.agreementsCountWeight || 0,
            },
            rejectedCount: {
                Threshold: selectedClusterConfig?.rejectedCountThreshold || 0,
                Weight: selectedClusterConfig?.rejectedCountWeight || 0,
            },
            accountingVerifyCount: {
                Threshold:
                    selectedClusterConfig?.accountingVerifyCountThreshold || 0,
                Weight: selectedClusterConfig?.accountingVerifyCountWeight || 0,
            },
        };
    }, [selectedClusterConfig]);

    const handleOnChangeScoreTenantConfig = (
        tenantId: number,
        field: string,
        value: string
    ) => {
        const newAppraiserScoreTenantConfigs =
            selectedClusterConfig?.appraiserScoreTenantConfigs?.map(
                (scoreTenant) => {
                    if (scoreTenant.tenantId !== tenantId) return scoreTenant;

                    let updatedData = updateObject(scoreTenant, value, field);

                    return {
                        ...updatedData,
                    };
                }
            );

        onChangeSelectedClusterConfig(
            "appraiserScoreTenantConfigs",
            newAppraiserScoreTenantConfigs
        );
    };

    return (
        <>
            <ThresoldsWrapper>
                <ThresoldsWrapperLeft>
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        {t(
                                            "expert-score-configure-kpi-quantities-label"
                                        )}
                                    </th>

                                    <th>
                                        {t(
                                            "expert-score-configure-threshold-quantities-label"
                                        )}
                                    </th>

                                    <th>
                                        {t(
                                            "expert-score-configure-weight-label"
                                        )}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {[
                                    "confirmationReactivity",
                                    "visionReactivity",
                                    "appraisalTime",
                                    "meanAppraisalAmount",
                                ].map((clusterConfigType) => (
                                    <tr key={clusterConfigType}>
                                        <td>
                                            <div className="label-with-tooltip">
                                                <div className="label">
                                                    <Tooltip title={t("?")}>
                                                        <IoMdHelpCircleOutline />
                                                    </Tooltip>
                                                    {t(
                                                        `expert-score-configure-kpi-quantities-${clusterConfigType}-label`
                                                    )}
                                                </div>
                                            </div>
                                        </td>

                                        {clusterConfigType !==
                                        "meanAppraisalAmount" ? (
                                            <td className="value">
                                                <ExpertizerScoreInputText
                                                    value={
                                                        values[
                                                            clusterConfigType as keyof typeof values
                                                        ]?.Threshold
                                                    }
                                                    onChange={(value) =>
                                                        onChangeSelectedClusterConfig(
                                                            `${clusterConfigType}Threshold`,
                                                            value
                                                        )
                                                    }
                                                />
                                            </td>
                                        ) : (
                                            <td></td>
                                        )}

                                        <td className="value">
                                            <ExpertizerScoreInputText
                                                value={
                                                    values[
                                                        clusterConfigType as keyof typeof values
                                                    ]?.Weight
                                                }
                                                onChange={(value) =>
                                                    onChangeSelectedClusterConfig(
                                                        `${clusterConfigType}Weight`,
                                                        value
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="tenant-configs-box">
                        {selectedClusterConfig?.appraiserScoreTenantConfigs?.map(
                            (appraiserScoreTenantConfig) => (
                                <AppraiserScoreTenantConfigForm
                                    key={appraiserScoreTenantConfig.tenantId}
                                    appraiserScoreTenantConfig={
                                        appraiserScoreTenantConfig
                                    }
                                    onChange={(field, value) =>
                                        handleOnChangeScoreTenantConfig(
                                            appraiserScoreTenantConfig.tenantId!,
                                            field,
                                            value
                                        )
                                    }
                                />
                            )
                        )}
                    </div>
                </ThresoldsWrapperLeft>

                <ThresoldsWrapperRight>
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        {t(
                                            "expert-score-configure-kpi-qualitative-label"
                                        )}
                                    </th>
                                    <th>
                                        {t(
                                            "expert-score-configure-threshold-qualitative-label"
                                        )}
                                    </th>
                                    <th>
                                        {t(
                                            "expert-score-configure-weight-label"
                                        )}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {[
                                    "accessesCount",
                                    "noAccessesCount",
                                    "agreementsCount",
                                    "rejectedCount",
                                    "accountingVerifyCount",
                                ].map((clusterConfigType) => (
                                    <tr key={clusterConfigType}>
                                        <td>
                                            <div className="label">
                                                <Tooltip title={t("?")}>
                                                    <IoMdHelpCircleOutline />
                                                </Tooltip>
                                                {t(
                                                    `expert-score-configure-kpi-qualitative-${clusterConfigType}-label`
                                                )}
                                            </div>
                                        </td>

                                        <td className="value">
                                            <ExpertizerScoreInputText
                                                value={
                                                    values[
                                                        clusterConfigType as keyof typeof values
                                                    ]?.Threshold
                                                }
                                                onChange={(value) =>
                                                    onChangeSelectedClusterConfig(
                                                        `${clusterConfigType}Threshold`,
                                                        value
                                                    )
                                                }
                                            />
                                        </td>

                                        <td className="value">
                                            <ExpertizerScoreInputText
                                                value={
                                                    values[
                                                        clusterConfigType as keyof typeof values
                                                    ]?.Weight
                                                }
                                                onChange={(value) =>
                                                    onChangeSelectedClusterConfig(
                                                        `${clusterConfigType}Weight`,
                                                        value
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </ThresoldsWrapperRight>
            </ThresoldsWrapper>
        </>
    );
};

export default AppraiserScoreConfigForm;
