import { useTranslation } from "react-i18next";
import {
  useCreateClaimContext,
  useCreateClaimDispatch,
} from "../CreateClaimContext";
import styled from "styled-components";
import { ButtonCancel, ButtonConfirm } from "../../../../../../Layout/Buttons";
import { FormRow } from "../../../../../../../style/form";
import {
  InputTextStyled,
  SelectStyled,
} from "../../../../../../../style/Input";
import { SelectPair } from "../../../../../../../types/common.types";
import { useMemo } from "react";
import { RowSpacer } from "../../../../../../../style/containers";
import { Guarantee } from "../../../../../../../redux/apiSpecifications/apiPolicies";
import GuaranteeDataForm from "./GuaranteeDataForm";

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-end;
`;

const validateForm = (model: Guarantee) => {
  return model.branch !== undefined && model.branch !== "";
};

interface IGuaranteeInfoStepProps {
  onNextStep: () => void;
  onPreviousStep: () => void;
}

const GuaranteeInfoStep = ({
  onNextStep,
  onPreviousStep,
}: IGuaranteeInfoStepProps) => {
  const { t } = useTranslation();

  const { createClaimData, allBranches } = useCreateClaimContext();

  const dispatch = useCreateClaimDispatch();

  const onChange = (value: any, field: string) => {
    dispatch!({
      type: "CHANGE_CLAIM_DATA",
      payload: {
        value: value,
        field: `selectedGuarantee.${field}`,
      },
    });
  };

  const handleOnChange = (value: any, field: string) => {
    if (["excessAmount", "overdraftAmount"].includes(field)) {
      if (value === "") onChange("0", field);
      else if (/^\d+(\,\d{0,})?$/.test(value)) {
        if (
          value.length === 2 &&
          value.startsWith("0") &&
          !value.endsWith(",")
        ) {
          onChange(value.substring(1), field);
        } else {
          onChange(value, field);
        }
      }
    } else {
      onChange(value, field);
    }
  };

  const model = createClaimData?.selectedGuarantee!;

  const disableForm = model.id !== undefined;

  const enableNextButton = disableForm || validateForm(model);

  const branchesOptions = useMemo(() => {
    const result: SelectPair[] = [];

    const allBranchCodes = allBranches.map((_) => _.branchCode);

    const guarranteesBranches =
      createClaimData?.selectedPolicy?.guarantees?.map((_) => _.branch);

    for (let branchCode of allBranchCodes.filter((branchCode) => {
      return !guarranteesBranches!.includes(branchCode);
    })) {
      result.push({
        value: branchCode,
        label: t(`policy-branch-${branchCode}`),
      });
    }

    if (model.id !== undefined) {
      result.push({
        value: model.branch,
        label: t(`policy-branch-${model.branch}`),
      });
    }

    return result.sort((p1, p2) =>
      p1.label > p2.label ? 1 : p1.label < p2.label ? -1 : 0
    );
  }, [model.id, allBranches]);

  return (
    <>
      <GuaranteeDataForm
        model={model}
        disableForm={disableForm}
        branchesOptions={branchesOptions}
        onChange={handleOnChange}
      />
      {/* <FormRow>
        {model.id ? (
          <InputTextStyled
            label={t("LABELS.branch")}
            placeholder={t("PLACEHOLDERS.branch")}
            required={true}
            value={t(`policy-branch-${model.branch}`)}
            onChange={(value) => handleOnChange(value, "branch")}
            disabled={disableForm}
          />
        ) : (
          <SelectStyled
            label={t("LABELS.branch")}
            required={true}
            value={model.branch}
            options={branchesOptions}
            onChange={(value) => handleOnChange(value, "branch")}
          />
        )}

        <RowSpacer />

        <InputTextStyled
          label={t("LABELS.excess-amount")}
          placeholder={t("PLACEHOLDERS.excess-amount")}
          required={true}
          value={model.excessAmount}
          onChange={(value) => handleOnChange(value, "excessAmount")}
          disabled={disableForm}
        />

        <RowSpacer />

        <InputTextStyled
          label={t("LABELS.overdraft-amount")}
          placeholder={t("PLACEHOLDERS.overdraft-amount")}
          required={true}
          value={model.overdraftAmount}
          onChange={(value) => handleOnChange(value, "overdraftAmount")}
          disabled={disableForm}
        />
      </FormRow> */}

      <ButtonsWrapper>
        <ButtonCancel onClick={onPreviousStep}>Indietro</ButtonCancel>

        <ButtonConfirm onClick={onNextStep} disabled={!enableNextButton}>
          Avanti
        </ButtonConfirm>
      </ButtonsWrapper>

      {/* <pre>{JSON.stringify(createClaimData?.selectedGuarantee, null, 2)}</pre> */}
    </>
  );
};

export default GuaranteeInfoStep;
