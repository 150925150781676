import { useTranslation } from "react-i18next";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import { useMemo } from "react";

type AppointmentModifiedDescriptionDetails = {
    appointment_date_old: string;
    appointment_time_slot_old: string;
    appointment_date_new: string;
    appointment_time_slot_new: string;
};

interface IAppointmentModifiedDescriptionProps {
    modifyDetailsJson: Modifydetail[];
}

const AppointmentModifiedDescription = ({
    modifyDetailsJson,
}: IAppointmentModifiedDescriptionProps) => {
    const { t } = useTranslation();

    const appointmentModifiedDescriptionDetails: AppointmentModifiedDescriptionDetails =
        useMemo(() => {
            return {
                appointment_date_old:
                    modifyDetailsJson.find(
                        (_) => _.key === "appointment_date-old"
                    )?.value ?? "",
                appointment_time_slot_old:
                    modifyDetailsJson.find(
                        (_) => _.key === "appointment_time_slot-old"
                    )?.value ?? "",
                appointment_date_new:
                    modifyDetailsJson.find(
                        (_) => _.key === "appointment_date-new"
                    )?.value ?? "",
                appointment_time_slot_new:
                    modifyDetailsJson.find(
                        (_) => _.key === "appointment_time_slot-new"
                    )?.value ?? "",
            } as AppointmentModifiedDescriptionDetails;
        }, [modifyDetailsJson]);

    return (
        <>
            {t("historical-actions-appointment-modified-description", {
                appointment_date:
                    appointmentModifiedDescriptionDetails.appointment_date_new,
                appointment_time_slot_description: t(
                    `appointment_time_slot_${appointmentModifiedDescriptionDetails.appointment_time_slot_new}_description`
                ),
            })}
        </>
    );
};

export default AppointmentModifiedDescription;
