import { useState } from "react";
import { checkPostalCode, isNotEmpty } from "../../validation/common";
import { InputFieldValidation } from "../../validation/subjectValidation";
import { Address as testIds } from "../../config/testIds";
import { Address } from "../../redux/apiSpecifications/apiCrud";
import { useTranslation } from "react-i18next";
import { FormRow } from "../../style/form";
import { InputTextStyled } from "../../style/Input";
import { RowSpacer } from "../../style/containers";
import SelectCountry from "./SelectCountry";
import SelectProvince from "./SelectProvince";

const checkAddress = (address: Address, options?: AddressFieldsOptions): boolean => {
    const { street, civic, city, postalCode, province, country } = address;

    return (
        isNotEmpty(street) &&
        ((options?.civicMandatory === true) ? isNotEmpty(civic) : true) &&
        // isNotEmpty(civic) &&
        isNotEmpty(city) &&
        checkPostalCode(postalCode?.toString() || "") &&
        isNotEmpty(province) &&
        isNotEmpty(country)
    );
};

export interface AddressFieldsOptions {
    civicMandatory: boolean;
}

interface IAddressFieldsProps {
    address: Address | undefined;
    options?: AddressFieldsOptions;
    onChange: (address: Address, isValid: boolean) => void;
}

const AddressFields = (props: IAddressFieldsProps) => {
    const { address, options = { civicMandatory: true }, onChange } = props;

    const { t } = useTranslation();

    const [postalCodeValidation, setPostalCodeValidation] =
        useState<InputFieldValidation>({
            validateStatus: "success",
            messageKey: "",
        });

    const handleOnChange = (value: string, field: string) => {
        const newAddress = { ...address, [field]: value };

        const validateResult = checkAddress(newAddress, options);

        onChange(newAddress, validateResult);

        if (field === "postalCode") {
            if (
                /^\d+$/.test(value) &&
                value.length === 5 &&
                parseInt(value) > 0
            )
                setPostalCodeValidation({
                    validateStatus: "success",
                    messageKey: "",
                });
            else {
                setPostalCodeValidation({
                    validateStatus: "error",
                    messageKey: "address-postalcode-invalid",
                });
            }
        }
    };

    return (
        <div className="selected-address">
            <FormRow>
                <InputTextStyled
                    label={`${t("address-street-label")} *`}
                    tooltip={t("address-street-tooltip")}
                    placeholder={t("address-street-placeholder")}
                    onChange={(street: string) =>
                        handleOnChange(street, "street")
                    }
                    value={address?.street}
                    dataTestId={testIds.street}
                />
            </FormRow>
            
            <FormRow>
                <InputTextStyled
                    label={`${t("address-civic-label")} ${
                        options.civicMandatory ? "*" : ""
                    }`}
                    tooltip={t("address-civic-tooltip")}
                    placeholder={t("address-civic-placeholder")}
                    onChange={(civic: string) => handleOnChange(civic, "civic")}
                    value={address?.civic}
                    dataTestId={testIds.civic}
                />

                <RowSpacer />

                <InputTextStyled
                    label={`${t("address-postalcode-label")} *`}
                    tooltip={t("address-postalcode-tooltip")}
                    placeholder={t("address-postalcode-placeholder")}
                    onChange={(postalCode: string) =>
                        handleOnChange(postalCode, "postalCode")
                    }
                    value={address?.postalCode}
                    validateStatus={postalCodeValidation.validateStatus}
                    help={t(postalCodeValidation.messageKey)}
                    dataTestId={testIds.postalCode}
                />
            </FormRow>
            
            <FormRow>
                <InputTextStyled
                    label={`${t("address-city-label")} *`}
                    tooltip={t("address-city-tooltip")}
                    placeholder={t("address-city-placeholder")}
                    onChange={(city: string) => handleOnChange(city, "city")}
                    value={address?.city}
                    dataTestId={testIds.city}
                />
            </FormRow>
            
            <FormRow>
                <SelectProvince
                    onChange={(province: string) =>
                        handleOnChange(province, "province")
                    }
                    value={address?.province}
                    dataTestId={testIds.province}
                />
                <RowSpacer />
                <SelectCountry
                    onChange={(country: string) =>
                        handleOnChange(country, "country")
                    }
                    value={address?.country}
                    dataTestId={testIds.country}
                />
            </FormRow>
        </div>
    );
};

export default AddressFields;
