import CodiceFiscale from "codice-fiscale-js";
import { logUnhandledException } from "../utils/console";
import { SubjectAdditionalValidations, SubjectValidations } from "../redux/features/activeSubject";
import { DateFormat, GenderTypes, SubjectType } from "../config/const";
import { throwDataException } from "../utils/exceptions";
import moment from "moment";
import IBAN from "iban";
import { Subject } from "../redux/apiSpecifications/apiCrud";
import { checkBicSwift, checkEmailFormat, checkPostalCode, checkTelephone, isNotEmpty } from "./common";
import { ValidationStatuses } from "../types/common.types";

export const validateVatNumber = (vatNumber: string | undefined) => {
	if (!vatNumber || vatNumber === "")
		return {
			validateStatus: "success" as ValidationStatuses,
			messageKey: "vat-number-data-empty",
		};

	if (/^[0-9]{11}$/.test(vatNumber as string)) {
		return {
			validateStatus: "success" as ValidationStatuses,
			messageKey: "vat-number-data-validation",
		};
	} else
		return {
			validateStatus: "error" as ValidationStatuses,
			messageKey: "vat-number-invalid-format",
		};
};

export const validateSubjectData = (
	subject: Subject | undefined,
	subjectType: SubjectType,
	additionalValidations: SubjectAdditionalValidations
): SubjectValidations => {
	if (!subject) return { isSubjectValid: false, additionalValidations };

	let isSubjectValid = false;
	const updatedAdditionalValidations = JSON.parse(JSON.stringify(additionalValidations)) as SubjectAdditionalValidations;

	// Data Tab Subject
	if (subjectType === "PF") {
		const { name, lastname, gender, birth, fiscalCode } = subject;
		const fiscalCodeValidation = validateFiscalCode(
			fiscalCode,
			name,
			lastname,
			gender as GenderTypes,
			birth?.date,
			birth?.city,
			birth?.province,
			birth?.country
		);

		updatedAdditionalValidations.fiscalCode = {
			state: fiscalCodeValidation.validateStatus === "success",
			validateStatus: fiscalCodeValidation.validateStatus,
			messageKey: fiscalCodeValidation.messageKey,
		};

		isSubjectValid = isNotEmpty(name) && isNotEmpty(lastname) && fiscalCodeValidation.validateStatus === "success";
	} else if (subjectType === "PG") {
		const { businessName: businessName, pIva } = subject;
		const vatNumberValidation = validateVatNumber(pIva);

		updatedAdditionalValidations.pIva = {
			state: vatNumberValidation.validateStatus === "success",
			validateStatus: vatNumberValidation.validateStatus,
			messageKey: vatNumberValidation.messageKey,
		};

		isSubjectValid = isNotEmpty(businessName) && vatNumberValidation.validateStatus === "success";

		if (subject.giuridicalType === "proprietorship") {
			const { name, lastname, gender, birth, fiscalCode } = subject;
			const fiscalCodeValidation = validateFiscalCode(
				fiscalCode,
				name,
				lastname,
				gender as GenderTypes,
				birth?.date,
				birth?.city,
				birth?.province,
				birth?.country
			);

			updatedAdditionalValidations.propritorshipFiscalCode = {
				state: fiscalCodeValidation.validateStatus === "success",
				validateStatus: fiscalCodeValidation.validateStatus,
				messageKey: fiscalCodeValidation.messageKey,
			};

			isSubjectValid =
				isSubjectValid &&
				isNotEmpty(name) &&
				isNotEmpty(lastname) &&
				isNotEmpty(name) &&
				//isNotEmpty(fiscalCode) &&
				fiscalCodeValidation.validateStatus === "success";
		}
	}

	// Data Tab Contacts
	subject.contacts!.forEach((contact, index) => {
		const { type, value, useCase } = contact;

		let isContactValid = isNotEmpty(value) && isNotEmpty(type);

		if (isContactValid) {
			switch (type) {
				case "E":
					if (checkEmailFormat(value!)) {
						updatedAdditionalValidations.contacts[index] = {
							state: true,
							validateStatus: "success",
							messageKey: "",
						};
					} else {
						updatedAdditionalValidations.contacts[index] = {
							state: false,
							validateStatus: "error",
							messageKey: "subject-contact-invalid-email",
						};
						isContactValid = false;
					}
					break;
				case "P":
					if (checkEmailFormat(value!)) {
						updatedAdditionalValidations.contacts[index] = {
							state: true,
							validateStatus: "success",
							messageKey: "",
						};
					} else {
						updatedAdditionalValidations.contacts[index] = {
							state: false,
							validateStatus: "error",
							messageKey: "subject-contact-invalid-pec",
						};
						isContactValid = false;
					}
					break;
				case "T":
					if (checkTelephone(value!)) {
						updatedAdditionalValidations.contacts[index] = {
							state: true,
							validateStatus: "success",
							messageKey: "",
						};
					} else {
						updatedAdditionalValidations.contacts[index] = {
							state: false,
							validateStatus: "error",
							messageKey: "subject-contact-invalid-phone",
						};
						isContactValid = false;
					}
					break;
				case "C":
					if (checkTelephone(value!)) {
						updatedAdditionalValidations.contacts[index] = {
							state: true,
							validateStatus: "success",
							messageKey: "",
						};
					} else {
						updatedAdditionalValidations.contacts[index] = {
							state: false,
							validateStatus: "error",
							messageKey: "subject-contact-invalid-mobile",
						};
						isContactValid = false;
					}
					break;
				default:
					return false;
			}
		}

		isSubjectValid &&= isContactValid && isNotEmpty(useCase);
	});

	// Data tab Addresses
	subject.addresses!.forEach((address, index) => {
		const { street, civic, city, postalCode, province, country, type } = address;

		isSubjectValid =
			isSubjectValid &&
			isNotEmpty(type) &&
			isNotEmpty(street) &&
			isNotEmpty(civic) &&
			isNotEmpty(city) &&
			checkPostalCode(postalCode?.toString() || "") &&
			isNotEmpty(province) &&
			isNotEmpty(country);
	});

	// Data tab Payments
	subject.payments!.forEach((payment, index) => {
		const { type, iban, bicSwift, addressSendCheck } = payment;

		let isPaymentValid = type !== undefined;
		isSubjectValid &&= isPaymentValid;

		if (type === "B") {
			// IBAN
			if (iban === "" || !iban) {
				updatedAdditionalValidations.paymentsIban[index] = {
					state: true,
					validateStatus: "success",
					messageKey: "iban-data-validation-empty",
				};
				isPaymentValid = false;
			} else if (IBAN.isValid(payment.iban!)) {
				updatedAdditionalValidations.paymentsIban[index] = {
					state: true,
					validateStatus: "success",
					messageKey: "iban-data-validation-success",
				};
			} else {
				updatedAdditionalValidations.paymentsIban[index] = {
					state: false,
					validateStatus: "error",
					messageKey: "iban-data-validation-invalid",
				};
				isPaymentValid = false;
			}

			// BIC/SWIFT
			if (bicSwift === "" || !bicSwift) {
				updatedAdditionalValidations.paymentsBic[index] = {
					state: true,
					validateStatus: "success",
					messageKey: "bic-swift-data-validation-empty",
				};
				isPaymentValid = false;
			} else if (checkBicSwift(payment.bicSwift!)) {
				updatedAdditionalValidations.paymentsBic[index] = {
					state: true,
					validateStatus: "success",
					messageKey: "bic-swift-data-validation-success",
				};
			} else {
				updatedAdditionalValidations.paymentsBic[index] = {
					state: false,
					validateStatus: "error",
					messageKey: "bic-swift-data-validation-invalid",
				};
				isPaymentValid = false;
			}

			isSubjectValid &&= isPaymentValid;
		} else if (type === "A") {
			const { street, civic, city, postalCode, province, country } = addressSendCheck || {};
			const validAddress = isNotEmpty(street) && isNotEmpty(civic) && isNotEmpty(city) && !!postalCode && isNotEmpty(province) && isNotEmpty(country);

			isSubjectValid &&= validAddress;
		}
	});

	// Data tab Documents
	subject.documents!.forEach((document, index) => {
		const { type, number, issuingDate, expirationDate, issuingInstitution } = document;
		const isDocumentValid =
			isNotEmpty(type) && isNotEmpty(number) && isNotEmpty(issuingDate) && isNotEmpty(expirationDate) && isNotEmpty(issuingInstitution);

		isSubjectValid &&= isDocumentValid;
	});

	return { isSubjectValid, additionalValidations: updatedAdditionalValidations };
};

export type ValidateStatusTypes = "" | "warning" | "error" | "success" | "validating" | undefined;

export type InputFieldValidation = {
	validateStatus: ValidateStatusTypes;
	messageKey: string;
};

export type GenderValidValues = "M" | "F" | undefined;

const pattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;

export function formalValidationFiscalCode(fiscalCode: string | undefined): InputFieldValidation {
	if (!fiscalCode) {
		return {
			validateStatus: "success",
			messageKey: "fiscal-code-data-empty",
		};
	} else if (fiscalCode.search(pattern) === -1) {
		return {
			validateStatus: "error",
			messageKey: "fiscal-code-invalid-format",
		};
	}

	return {
		validateStatus: "success",
		messageKey: "fiscal-code-data-validation",
	};
}

export function validateFiscalCode(
	fiscalCode: string | undefined,
	name: string | undefined,
	surname: string | undefined,
	gender: GenderTypes,
	strDateOfBirth: string | undefined,
	city: string | undefined,
	province: string | undefined,
	country: string | undefined
): InputFieldValidation {
	const formalValidationResult = formalValidationFiscalCode(fiscalCode);

	if (formalValidationResult.validateStatus === "error") return formalValidationResult;

	if (fiscalCode && name && surname && gender && strDateOfBirth && city && province && country) {
		const dateOfBirth = moment(strDateOfBirth, DateFormat);
		const day = dateOfBirth.date();
		const month = dateOfBirth.month() + 1;
		const year = dateOfBirth.year();

		const birthplace = country?.toLowerCase() === "italy" ? city : country;
		const birthplaceProvincia = country?.toLowerCase() === "italy" ? province : "EE";

		try {
			const computedFiscalCode = new CodiceFiscale({
				name,
				surname,
				gender,
				day,
				month,
				year,
				birthplace,
				birthplaceProvincia,
			});

			if (fiscalCode.toUpperCase() === computedFiscalCode.toString()) {
				return {
					validateStatus: "success",
					messageKey: "fiscal-code-data-validation",
				};
			}
		} catch (err) {
			logUnhandledException(err);
		}

		return {
			validateStatus: "error",
			messageKey: "fiscal-code-incosistent-data",
		};
	}

	return {
		validateStatus: "success",
		messageKey: "fiscal-code-data-empty",
	};
}
