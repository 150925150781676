import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { widgets } from "../../../config/testIds";

import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { useEffect, useMemo, useState } from "react";
import MapBase, { Marker, MarkerType } from "../../Maps/MapBase";
import WidgetHeader from "../WidgetHeader";
import {
    GeographicalLocation,
    PracticeE2List,
    useSaveAppraiserCurrentLocationMutation,
} from "../../../redux/apiSpecifications/apiCrud";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { TFunction } from "i18next";
import { Popover } from "antd";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import moment from "moment";

const testIds = widgets.mapExpertizers;

const MapWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    flex: 1;

    max-height: 2.5em;
    transition: max-height 1s;

    &.open {
        height: 400px;
        max-height: 400px;
        min-height: 400px;
    }
`;

const LegendWrapper = styled.div`
    margin-right: 1em;
    display: flex;
    align-items: center;
`;

const LegendContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1em;

  .item {
    display: flex;cd
    align-items: center;
    gap: 0.5em;
  }

  .round-RE {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #ef7c00;
  }

  .round-PA {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #f5b066;
  }

  .round-AD {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #ffcc00;
  }

  .round-AF {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #666;
  }

  .round-AFO {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #64b32c;
  }

  .round-CP {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #002e55;
  }
`;

interface IExpertizerMapProps {
    isActive?: boolean;
    disable?: boolean;
    practices?: PracticeE2List[];
    testMarker?: (markers: Array<Marker>) => void;
}

export const mappingTypeAppraisalTypeMarker = (
    status: string | undefined,
    appointmentDate: string | undefined
): MarkerType => {
    const today = moment().format("DD/MM/YYYY");
    const sameDay = appointmentDate === today;

    const type =
        status === "RE"
            ? "practiceRejected"
            : status === "PA"
            ? "practiceConfirmed"
            : status === "AD"
            ? "practiceToDoAppointment"
            : status === "AF" && !sameDay
            ? "practiceWithAppointment"
            : status === "AF" && sameDay
            ? "practiceAppointmentToday"
            : status === "CP" && !sameDay
            ? "practiceToConclude"
            : status === "CP" && sameDay
            ? "practiceAppointmentToday"
            : "practiceOtherStatus";

    return type;
};

const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};

const createMarkers = (
    practices: PracticeE2List[] | undefined,
    t: TFunction<"translation", undefined>
) => {
    const practicesWithLocation =
        practices?.filter((p) => p.appraisalLocationAddress) || [];

    const practicesWithType = practicesWithLocation.map((p) => {
        const practice = {
            lat: p.appraisalLocationAddress?.latitude || 0,
            long: p.appraisalLocationAddress?.longitude || 0,
            type:
                mappingTypeAppraisalTypeMarker(p.status, p.appointmentDate) ||
                undefined,
            data: {
                id: p?.id || 0,
                expertizeType:
                    p.practicetypecode !== undefined
                        ? t(
                              `appraisals-details-tab-details-appraisals-expertise-type-${p.practicetypecode}`
                          )
                        : "N/D",
                numberExpertize: p.sxNumber || "",
                practiceNumber: p.practiceNumber || "",
                plate: p.plate || "",
            },
        };

        return practice;
    });

    const practicesWithValidType =
        practicesWithType.filter((x) => x.type != undefined) || [];

    return practicesWithValidType;
};

const ExpertizerMap = (props: IExpertizerMapProps) => {
    const { disable, isActive = false, practices, testMarker } = props;
    const { t } = useTranslation();

    const [markers, setMarkers] = useState<Marker[]>([]);
    const [center, setCenter] = useState<[number, number]>([
        12.4963655, 41.9027835,
    ]);

    const [saveAppraiserCurrentLocation] =
        useSaveAppraiserCurrentLocationMutation();

    const authorization = useSelector(
        (state: RootState) => state.user.authorization
    );

    useEffect(() => {
        const success = (pos: any) => {
            let markers: Array<Marker> = createMarkers(practices, t);

            const crd = pos.coords;

            //add current position (appraiser)

            if (crd.latitude && crd.longitude) {
                const geographicalLocation = {
                    latitude: crd.latitude,
                    longitude: crd.longitude,
                };

                try {
                    (async () => {
                        await saveAppraiserCurrentLocation({
                            authorization,
                            geographicalLocation,
                        });
                    })();
                } catch (error) {
                    console.error(
                        "Errore durante il salvataggio della posizione corrente:",
                        error
                    );
                }
            }

            if (crd.latitude && crd.longitude) {
                markers.unshift({
                    lat: crd.latitude,
                    long: crd.longitude,
                    type: "expertizer",
                    data: {
                        id: 0,
                        expertizeType: "",
                        numberExpertize: "",
                        plate: "",
                        practiceNumber: "",
                    },
                });
                setCenter([crd.longitude, crd.latitude]);
            }

            setMarkers(markers);

            if (testMarker) testMarker(markers);
        };

        navigator.geolocation.getCurrentPosition(success, console.log, options);
    }, [practices]);

    const colorLegend = useMemo(() => {
        return (
            <LegendWrapper>
                <Popover
                    placement="leftTop"
                    content={
                        <LegendContentWrapper>
                            {["RE", "PA", "AD", "AF", "AFO", "CP"].map((i) => (
                                <div className="item" key={i}>
                                    <div className={`round-${i}`}></div>
                                    {t(`appraisal-statuses-s-${i}`)}
                                </div>
                            ))}
                        </LegendContentWrapper>
                    }
                >
                    <AiOutlineQuestionCircle size={20} />
                </Popover>
            </LegendWrapper>
        );
    }, []);

    return (
        <MapWrapper data-testid={testIds.container} className={"open"}>
            <WidgetHeader
                title={t("expertizer-dashboard-map-title")}
                isActive={isActive}
                content={colorLegend}
            />
            <div className="body" style={{ height: "100%" }}>
                {!disable && (
                    <MapBase
                        wrapperId="map-expertizer"
                        markers={markers}
                        center={center}
                    />
                )}
            </div>
        </MapWrapper>
    );
};

export default ExpertizerMap;
