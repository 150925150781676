import { useTranslation } from "react-i18next";
import { Guarantee } from "../../../redux/apiSpecifications/apiClaims";
import { useMemo } from "react";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { CellWrapper } from "../../../style/CellWrapper";
import { TableWrapper } from "../../../style/TableWrapper";

interface IPolicyGuaranteesListProps {
  data: Guarantee[];
  onSelectItem?: (item: GuaranteesListItem) => void;
}

const PolicyGuaranteesList = ({
  data,
  onSelectItem,
}: IPolicyGuaranteesListProps) => {
  const { t } = useTranslation();

  const items = useMemo(
    () =>
      data.map((item) => {
        return {
          ...item,
          branchDescription: t(
            `policy-branch-${item.branch}`
          )!,
        } as GuaranteesListItem;
      }) || [],
    [data]
  );

  const columns = useMemo<MRT_ColumnDef<GuaranteesListItem>[]>(() => {
    return [
      {
        accessorKey: "branchDescription",
        header: "branchDescription",
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>{row.original.branchDescription}</CellWrapper>
        ),
      },
      {
        accessorKey: "excessAmount",
        header: "excessAmount",
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>{row.original.excessAmount}</CellWrapper>
        ),
      },
      {
        accessorKey: "overdraftAmount",
        header: "overdraftAmount",
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>{row.original.overdraftAmount}</CellWrapper>
        ),
      },
    ];
  }, [data]);

  const handleSelectItem = (item: GuaranteesListItem) => {
    if (onSelectItem) onSelectItem(item);
  };

  return (
    <TableWrapper>
      <MaterialReactTable
        columns={columns}
        data={items}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableHiding={false}
        enableFilters={false}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableSorting={false}
        enableTopToolbar={false}
        // muiTableHeadCellProps={{
        //   sx: {
        //     padding: "1em 0",
        //   },
        // }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            handleSelectItem(row.original);
          },
          sx: {
            cursor: onSelectItem !== undefined ? "pointer" : "default",
          },
        })}
        // muiTableBodyCellProps={{
        //   sx: {
        //     textAlign: "left",
        //     padding: "1em 0",
        //   },
        // }}
        // state={{
        //   pagination,
        // }}
        // manualPagination
        // rowCount={data.totalCount!}
        // onPaginationChange={setPagination}
      />

      {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
    </TableWrapper>
  );
};

export default PolicyGuaranteesList;

export type GuaranteesListItem = Guarantee & { branchDescription?: string };
