import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { truncText } from "../../../utils/text";
import {
  ClaimsListItem,
  SearchedClaimsList,
} from "../../../redux/apiSpecifications/apiClaims";
import { CellWrapper } from "../../../style/CellWrapper";
import { TableWrapper } from "../../../style/TableWrapper";

interface IClaimsListProps {
  data: SearchedClaimsList;
  onSelectItem?: (item: ClaimsListItem) => void;
  onPaginationChange: (pageIndex: number, pageSize: number) => void;
}

const ClaimsList = ({
  data,
  onSelectItem,
  onPaginationChange,
}: IClaimsListProps) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: data.currentPageNum! - 1,
    pageSize: data.pageLen!,
  });

  useEffect(() => {
    if (
      data.currentPageNum! - 1 !== pagination.pageIndex ||
      data.pageLen! !== pagination.pageSize
    ) {
      onPaginationChange(pagination.pageIndex + 1, pagination.pageSize);
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  const items = useMemo(
    () =>
      data.items?.map((item) => {
        return {
          ...item,
        } as ClaimsListItem;
      }) || [],
    [data.items]
  );

  const columns = useMemo<MRT_ColumnDef<ClaimsListItem>[]>(() => {
    return [
      {
        accessorKey: "claimNumber",
        header: t("claims-list-claim-number-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper title={row.original.claimNumber}>
            {truncText(row.original.claimNumber, 20)}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "subjectName",
        header: t("claims-list-subject-name-column-header"),
        size: 150,
        Cell: ({ row }) => (
          <CellWrapper title={row.original.subjectName}>
            {truncText(row.original.subjectName, 25)}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "claimType",
        header: t("claims-list-claim-type-column-header"),
        size: 100,
        Cell: ({ row }) => <CellWrapper>{row.original.claimType}</CellWrapper>,
      },
      {
        accessorKey: "claimStatus",
        header: t("claims-list-claim-status-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>
            {t(
              `dashboard-a1-list-claim-status-code-${row.original.claimStatusCode}`
            )}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "policyNumber",
        header: t("claims-list-policy-number-column-header"),
        size: 150,
        Cell: ({ row }) => (
          <CellWrapper title={row.original.policyNumber}>
            {truncText(row.original.policyNumber, 20)}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "plateNumberList",
        header: t("claims-list-plate-number-list-column-header"),
        size: 150,
        Cell: ({ row }) => (
          <CellWrapper>{row.original.plateNumberList!.join(", ")}</CellWrapper>
        ),
      },
      {
        accessorKey: "openingDate",
        header: t("claims-list-opening-date-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper>{row.original.openingDate}</CellWrapper>
        ),
      },
    ];
  }, [t]);

  const handleSelectItem = (item: ClaimsListItem) => {
    if (onSelectItem) onSelectItem(item);
  };

  return (
    <TableWrapper>
      <MaterialReactTable
        columns={columns}
        data={items}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableHiding={false}
        enableFilters={false}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableSorting={false}
        enableTopToolbar={false}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            handleSelectItem(row.original);
          },
          sx: {
            cursor: onSelectItem !== undefined ? "pointer" : "default",
          },
        })}
        state={{
          pagination,
        }}
        manualPagination
        rowCount={data.totalCount!}
        onPaginationChange={setPagination}
      />
    </TableWrapper>
  );
};

export default ClaimsList;
