import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FormRow, ModalButtons } from "../../style/form";
import { InputTextStyled } from "../../style/Input";
import { RowSpacer } from "../../style/containers";
import { updateObject } from "../../utils/updateObject";
import { ButtonCancel, ButtonConfirm } from "../Layout/Buttons";
import SelectCountry from "./SelectCountry";
import SelectProvince from "./SelectProvince";

const ModalContent = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 3em 2em 8em 2em;
`;

const BirthPlaceContainer = styled.div`
	display: flex;
	flex: 1;
`;

const FormattedBirthPlace = styled.div`
	display: flex;
	flex: 1;
	align-items: flex-end;
	position: relative;
	cursor: pointer;

	input {
		cursor: pointer;
	}
`;

export type BirthPlaceFormattedValueType = {
	country: string | undefined;
	province: string | undefined;
	city: string | undefined;
};

interface BirthPlaceProps {
	birthPlace?: BirthPlaceFormattedValueType | undefined;
	onChange: (birthPlace: BirthPlaceFormattedValueType) => void;
}

const BirthPlace = (props: BirthPlaceProps) => {
	const { t } = useTranslation();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [birthPlace, setBirthPlace] = useState<BirthPlaceFormattedValueType>(props.birthPlace || ({} as BirthPlaceFormattedValueType));
	const [confirmButtonEnabled, setConfirmButtonEnabled] = useState(birthPlace.city && birthPlace.province && birthPlace.country);

	useEffect(() => {
		if (props.birthPlace) setBirthPlace(props.birthPlace);
	}, [props.birthPlace]);

	useEffect(() => {
		setConfirmButtonEnabled(birthPlace.city && birthPlace.province && birthPlace.country);
	}, [birthPlace]);

	const handleOnChange = (value: any, field: string) => {
		const updatedBirthPlace = updateObject(birthPlace, value, field);
		setBirthPlace(updatedBirthPlace);
	};

	const handleConfirm = () => {
		props.onChange(birthPlace);
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setBirthPlace(props.birthPlace || ({} as BirthPlaceFormattedValueType));
		setIsModalOpen(false);
	};

	const fullAddress =
		birthPlace.city && birthPlace.province && birthPlace.country ? `${birthPlace.city} - ${birthPlace.province} - ${birthPlace.country}` : null;

	return (
		<BirthPlaceContainer data-testid="birthplace-container">
			<FormattedBirthPlace onClick={() => setIsModalOpen(true)}>
				<InputTextStyled
					label={`${t("input-birth-place-label")}`}
					tooltip={t("input-birth-place-tooltip")}
					placeholder={t("input-birth-place-placeholder")}
					onChange={() => {}}
					value={fullAddress}
					readOnly={true}
					dataTestId="birthplace-fullplace"
				/>
			</FormattedBirthPlace>
			<Modal open={isModalOpen} footer={null} onCancel={handleCancel} width="800px">
				<ModalContent>
					<FormRow>
						<InputTextStyled
							label={`${t("address-city-label")} *`}
							tooltip={t("address-city-tooltip")}
							placeholder={t("address-city-placeholder")}
							onChange={(city) => handleOnChange(city, "city")}
							value={birthPlace.city}
							dataTestId="birthplace-city"
						/>
						<RowSpacer />
						<SelectProvince
							onChange={(province) => handleOnChange(province, "province")}
							value={birthPlace.province}
						/>
						
						<RowSpacer />
						<SelectCountry
							required
							onChange={(code) => handleOnChange(code, "country")}
							value={birthPlace.country}
							dataTestId="birthplace-country"
						/>
					</FormRow>
					<ModalButtons justify="flex-end">
						<ButtonConfirm onClick={handleConfirm} disabled={!confirmButtonEnabled} dataTestId="birthplace-button-confirm">
							{t("button-confirm")!}
						</ButtonConfirm>
						<RowSpacer />
						<ButtonCancel onClick={handleCancel} dataTestId="birthplace-button-cancel">
							{t("button-cancel")!}
						</ButtonCancel>
					</ModalButtons>
				</ModalContent>
			</Modal>
		</BirthPlaceContainer>
	);
};

export default BirthPlace;
