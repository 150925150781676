import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { WidgetWrapper } from "../../../../style/DashbordWidgetWrappers";
import WidgetHeader from "../../WidgetHeader";
import DonutChart, { DonutDataItemType } from "../../../Charts/DonutChart";
import { UserRequestsSummary } from "../../../../redux/apiSpecifications/apiCrud";
import { AppraisalsRequestsChartSelectionType } from "./AppraisalsRequestsChartSelectionType";
import { AppraisalsRequestsChartData } from "./AppraisalsRequestsChartData";
import { AppraisalsRequestsChartHelper } from "./AppraisalsRequestsChartHelper";
import RequestsChartLegend from "./RequestsChartLegend";

const AppraisalsRequestsChartWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    max-height: 2.5em;
    transition: max-height 1s;
    &.open {
        max-height: 100em;
    }

    div.appraisals-requests-chart-body {
        display: flex;

        div.body {
            flex: 1;
        }

        div.legend-icon {
            width: 30px;
            text-align: center;
            padding-top: 8px;
        }
    }
`;

export interface IAppraisalsRequestsChartSTProps {
    userRequestsSummary: UserRequestsSummary[];
    onSelect: (selection: AppraisalsRequestsChartSelectionType) => void;
    gridArea?: string;
    isActive: boolean;
    isOpen: boolean;
    selection: AppraisalsRequestsChartSelectionType;
}

const AppraisalsRequestsChartST = (props: IAppraisalsRequestsChartSTProps) => {
    const { userRequestsSummary, onSelect, gridArea, isActive, selection } =
        props;

    const { t } = useTranslation();

    const [donatsData, setDonatsData] = useState<AppraisalsRequestsChartData[]>(
        []
    );
    const [isOpen, setIsOpen] = useState(props.isOpen);

    useEffect(() => {
        if (!isActive) {
            // setShowSelections(false);
            setIsOpen(isActive || props.isOpen);
        } else setIsOpen(isActive);
    }, [isActive]);

    useEffect(() => {
        if (userRequestsSummary) {
            let result: AppraisalsRequestsChartData[] = [];

            userRequestsSummary.forEach((selectedUserRequestSummary) => {
                let donutDataItems: DonutDataItemType[] = [];

                selectedUserRequestSummary.totalsByCategory!.forEach((item) => {
                    donutDataItems.push({
                        key: item.category!,
                        name: t(
                            `appraisals-dashboard-request-category-${item.category}`
                        ),
                        value: item.total!,
                        fill: AppraisalsRequestsChartHelper.getFill(
                            item.category!
                        ),
                    });
                });

                result.push({
                    type: selectedUserRequestSummary.senderType!,
                    items: donutDataItems,
                    label: t(
                        `appraisals-dashboard-st-request-${selectedUserRequestSummary.senderType}-title`
                    ),
                });
            });

            var st = result.find((_) => _.type === "ST");
            var cl = result.find((_) => _.type === "CL");
            var ap = result.find((_) => _.type === "AP");
            var bo = result.find((_) => _.type === "BO");

            let donatsData: AppraisalsRequestsChartData[] = [];

            if (st) {
                donatsData.push({
                    ...st,
                    lastMessageSenderType: "ST",
                    statusCode: "N",
                });
            }

            if (cl) {
                donatsData.push({
                    ...cl,
                    lastMessageSenderType: "CL",
                    lastMessageReceiverType: "ST",
                });
            }

            if (ap) {
                donatsData.push({
                    ...ap,
                    lastMessageSenderType: "AP",
                    lastMessageReceiverType: "ST",
                });
            }

            if (bo) {
                donatsData.push({
                    ...bo,
                    lastMessageSenderType: "BO",
                    lastMessageReceiverType: "ST",
                });
            }

            setDonatsData(donatsData);
        }
    }, [userRequestsSummary]);

    const handleFilterReset = () => {
        onSelect({
            senderType: undefined,
            categoryCode: undefined,
        });
    };

    const handleOnOpen = () => setIsOpen(true);
    const handleOnClose = () => setIsOpen(false);

    return (
        <AppraisalsRequestsChartWrapper
            className={`${isActive || isOpen ? "open" : ""}`}
            gridArea={gridArea}
        >
            <WidgetHeader
                title={t("appraisals-dashboard-requests-title")}
                isActive={isActive}
                filterReset={handleFilterReset}
                onOpen={handleOnOpen}
                onClose={handleOnClose}
                isOpen={isOpen}
            />
            <div className="appraisals-requests-chart-body">
                <div className="body">
                    {donatsData.map((donatData, index) => {
                        const selected =
                            isActive && selection.senderType === donatData.type
                                ? selection.categoryCode
                                : "";

                        return (
                            <div className="col" key={index}>
                                <DonutChart
                                    title={donatData.label}
                                    data={donatData.items}
                                    onClick={(key) =>
                                        onSelect({
                                            lastMessageSenderType:
                                                donatData.lastMessageSenderType,
                                            lastMessageReceiverType:
                                                donatData.lastMessageReceiverType,
                                            statusCode: donatData.statusCode,
                                            senderType: donatData.type,
                                            categoryCode: key,
                                        })
                                    }
                                    centerValue={AppraisalsRequestsChartHelper.getCenterValue(
                                        donatData.items
                                    )}
                                    selected={selected}
                                    hideLegend={true}
                                />
                            </div>
                        );
                    })}

                    {Array.from(Array(4 - donatsData.length).keys()).map(
                        (_, index) => {
                            return <div className="col" key={index}></div>;
                        }
                    )}
                </div>

                <div className="legend-icon">
                    <RequestsChartLegend />
                </div>
            </div>
        </AppraisalsRequestsChartWrapper>
    );
};

export default AppraisalsRequestsChartST;
