import { useTranslation } from "react-i18next";
import { GenderTypes } from "../../config/const";
import { SelectStyled } from "../../style/Input";
import { selectGender as testId } from "../../config/testIds";

interface ISelectGenderProps {
	classNameItem?: string;
	styleItem?: string;
	onChange: (val: GenderTypes) => void;
	value: GenderTypes;
}

const SelectGender = (props: ISelectGenderProps) => {
	const { t } = useTranslation();

	return (
		<SelectStyled
			classNameItem={props.classNameItem}
			styleItem={props.styleItem}
			label={t("select-gender")}
			tooltip={t("select-gender-tooltip")}
			onChange={(gender) => props.onChange(gender === "n.d." ? undefined : gender)}
			value={props.value}
			options={[
				{
					value: "",
					label: t("select-gender-n.d."),
				},
				{
					value: "M",
					label: t("select-gender-M"),
				},
				{
					value: "F",
					label: t("select-gender-F"),
				},
			]}
			dataTestId={testId.gender}
		/>
	);
};

export default SelectGender;
