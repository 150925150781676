import { useMemo } from "react";
import styled from "styled-components";
import { DistrictAvailability } from "../../../../redux/apiSpecifications/apiFesf";
import { useTranslation } from "react-i18next";
import moment from "moment";

// Styled components

const ProvinceListWrapper = styled.div`
  width: 100%;

  .header {
    padding: 0.25em;
    border-radius: 5px;
    background-color: #eee;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9em;
  }

  .provinces {
    margin-top: 1em;
    overflow: auto;
    max-height: 95%;
  }

  .province {
    padding-left: 2em;
    display: flex;
    align-items: center;

    .square {
      width: 0.75em;
      height: 0.75em;
      border-radius: 3px;
      background-color: #d91b1b;
      display: inline-block;
      margin-right: 0.5em;
      margin-bottom: 0.2em;
    }
  }

  .province {
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }

    .square {
      width: 0.75em;
      height: 0.75em;
      border-radius: 3px;
      background-color: #d91b1b;
      display: inline-block;
      margin-right: 0.5em;
      margin-bottom: 0.2em;
    }

    .square-C1 {
      background-color: #002e55;
    }

    .square-C2 {
      background-color: #ef7c00;
    }

    .square-C3 {
      background-color: #ffcc00;
    }

    .square-C4 {
      background-color: #64b32c;
    }

    .square-p {
      background-color: #ddd;
    }
  }
`;

interface IProvinceListProps {
  districts: DistrictAvailability[] | undefined;
  date: string | undefined;
  onSelect: (provinceCode: string, provinceName: string, date: string) => void;
}

const ProvinceList = ({ districts, date, onSelect }: IProvinceListProps) => {
  const { t } = useTranslation();

  const squareClass = useMemo(() => {
    if (!districts) return "";
    if (districts.length < 1) return "";

    const isPastDate = moment().isAfter(moment(date, "DD/MM/YYYY"), "day");

    return isPastDate
      ? "square square-p"
      : `square square-${districts[0].availabilityType}`;
  }, [date, districts]);

  return (
    <ProvinceListWrapper>
      <div className="header">{t("agenda-provinces-list")}</div>
      <div className="provinces">
        {districts?.map((d, index) => (
          <div
            className="province"
            key={index}
            onClick={() => onSelect(d.districtCode!, d.districtName!, date!)}
          >
            <div className={squareClass}></div>
            {d.districtName}
          </div>
        ))}
      </div>
    </ProvinceListWrapper>
  );
};

export default ProvinceList;
