import styled from "styled-components";
import { MainTabType } from "../../../redux/features/mainTabsSlice";
import { MainTabContentWrapper } from "../../../style/DashbordWidgetWrappers";
import SearchFormClaims from "../../Search/SearchFormClaims";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ClaimsList from "../Claims/ClaimsList";
import {
  SearchClaimsParams,
  SearchedClaimsList,
  useSearchClaimsMutation,
} from "../../../redux/apiSpecifications/apiClaims";
import { IconLoading } from "../../../config/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const SearchResultsWrapper = styled(MainTabContentWrapper)`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 2em 1% 1em 1%;
  width: 98%;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0 5px #aaa;
  padding: 1em 2em;

  .title-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2em;
    padding-top: 2em;
    border-top: 1px solid #ddd;

    .title {
      font-size: 1.4em;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .search-result-message-wrapper {
    margin-top: 2em;
    padding: 0 2em;
  }

  .table-wrapper {
    padding: 0 2em;
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1em;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  .loading-content {
    height: 10em;
    width: 10em;
  }
`;

interface ISearchResultClaimsProps {
  tabItem: MainTabType;
}

const SearchResultClaims = (props: ISearchResultClaimsProps) => {
  const { t } = useTranslation();

  const { tabItem } = props;
  const { externalData } = tabItem;
  //   const { totalCount, currentPageNum, totalPageNum, pageLen, items } =
  //     externalData;

  const { activeRole } = useSelector((state: RootState) => state.user);

  const refResults = useRef<null | HTMLDivElement>(null);

  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<string | undefined>();

  const [claimsList, setClaimsList] = useState<
    SearchedClaimsList | undefined
  >();

  const [searchClaimsParams, setSearchClaimsParams] =
    useState<SearchClaimsParams>({} as SearchClaimsParams);

  const [searchClaims] = useSearchClaimsMutation();

  useEffect(() => {
    if (externalData) {
      setClaimsList(externalData);

      if (refResults.current)
        setTimeout(() => {
          refResults!.current!.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 1000);
    }
  }, [externalData]);

  const handleSearchAppraisals = async (searchParams: SearchClaimsParams) => {
    setLoading(true);
    setLoadingError(undefined);

    setSearchClaimsParams(searchParams);

    try {
      const response = await searchClaims({
        pageNum: 1,
        pageLen: 10,
        scenario: "search",
        activeRole: activeRole!,
        searchClaimsParams: searchParams,
      });

      const searchResponse = response as {
        data: SearchedClaimsList;
      };

      setClaimsList(searchResponse.data);

      setTimeout(() => {
        refResults!.current!.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 1000);
    } catch (e) {
      console.error(e);

      setLoadingError(t("waiting-for-network-delay-result-error")!);
    }

    setLoading(false);
  };

  const handlePaginationChange = async (
    pageIndex: number,
    pageSize: number
  ) => {
    setLoading(true);
    setLoadingError(undefined);

    try {
      const response = await searchClaims({
        pageNum: pageIndex,
        pageLen: pageSize,
        scenario: "search",
        activeRole: activeRole!,
        searchClaimsParams: searchClaimsParams!,
      });

      const searchResponse = response as {
        data: SearchedClaimsList;
      };

      setClaimsList(searchResponse.data);

      setTimeout(() => {
        refResults!.current!.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 1000);
    } catch (e) {
      console.error(e);

      setLoadingError(t("waiting-for-network-delay-result-error")!);
    }

    setLoading(false);
  };

  return (
    <SearchResultsWrapper>
      <SearchFormClaims onSearch={handleSearchAppraisals} />

      <div className="title-wrapper" ref={refResults}>
        <div className="title">
          {t("search-results-appraisals-table-label")}
        </div>
      </div>

      {claimsList && (
        <div className="table-wrapper">
          <ClaimsList
            data={claimsList}
            onPaginationChange={handlePaginationChange}
          />
          {/* <pre>{JSON.stringify(loading, null, 2)}</pre> */}
        </div>
      )}

      {loading && (
        <LoadingWrapper>
          <div className="loading-content">{IconLoading}</div>
        </LoadingWrapper>
      )}
    </SearchResultsWrapper>
  );
};

export default SearchResultClaims;
