import { useTranslation } from "react-i18next";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import { useMemo } from "react";

type AppraisalLocationModifiedDescriptionDetails = {
  appraisal_subject_old: string;
  appraisal_subject_new: string;
};

interface IAppraisalLocationModifiedDescriptionProps {
  modifyDetailsJson: Modifydetail[];
}
const AppraisalLocationModifiedDescription = ({
  modifyDetailsJson,
}: IAppraisalLocationModifiedDescriptionProps) => {
  const { t } = useTranslation();

  const appraisalLocationModifiedDescriptionDetails: AppraisalLocationModifiedDescriptionDetails =
    useMemo(() => {
      return {
        appraisal_subject_old:
          modifyDetailsJson.find((_) => _.key === "appraisal_subject_old")
            ?.value ?? "",
        appraisal_subject_new:
          modifyDetailsJson.find((_) => _.key === "appraisal_subject_new")
            ?.value ?? "",
      } as AppraisalLocationModifiedDescriptionDetails;
    }, [modifyDetailsJson]);

  return (
    <>
      {t("historical-actions-appointment-modified-description-details", {
        appraisal_subject_new:
          appraisalLocationModifiedDescriptionDetails.appraisal_subject_new,
          appraisal_subject_old:
          appraisalLocationModifiedDescriptionDetails.appraisal_subject_old,
      })}
    </>
  );
};

export default AppraisalLocationModifiedDescription;
