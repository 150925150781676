import { useTranslation } from "react-i18next";
import { WidgetWrapper } from "../../../../style/containers";
import styled from "styled-components";
import { useMemo, useState } from "react";
import { useExpertizeScoreContext } from "../ExpertizeScoreContext";
import ConfigureClusterInput from "./ConfigureClusterInput";
import { ConfigureClusterActionType } from "../types";
import { Alert, Popconfirm } from "antd";
import { ButtonConfirm } from "../../../Layout/Buttons";
import ConfigureClusterNameModal from "./ConfigureClusterNameModal/ConfigureClusterNameModal";
import cluster from "cluster";

export const ConfigureClusterWrapper = styled(WidgetWrapper)`
  flex-direction: column;
  align-items: unset;
  padding: 2em;
  gap: 2em;

  .buttons-wrapper {
    width: 100%;
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }
`;

const ConfigureCluster = () => {
  const { t } = useTranslation();

  const {
    appraiserScoreClusterList,
    provincesOptions,
    isFormChanged,
    onAddDistrictToCluster,
    onRemoveDistrictToCluster,
    onSubmitConfigureClusterForm,
    onAddAllDistrictsToCluster,
    onRemoveAllDistrictsFromCluster,
    onUpdateClusterName,
  } = useExpertizeScoreContext();

  const [selectedClusterIdForEditName, setSelectedClusterIdForEditName] =
    useState<number>();

  const availableProvincesOptions = useMemo(() => {
    const assignedProvinces: number[] = [];

    appraiserScoreClusterList?.forEach((cluster) => {
      assignedProvinces.push(...cluster.districts!);
    });

    return provincesOptions?.filter((province) => {
      return !assignedProvinces.includes(Number(province.value));
    });
  }, [appraiserScoreClusterList]);

  const handleOnAction = (action: ConfigureClusterActionType, payload: any) => {
    if (action === "add-district") {
      onAddDistrictToCluster(
        Number(payload.clusterId),
        Number(payload.districtId)
      );
    } else if (action === "remove-district") {
      onRemoveDistrictToCluster(
        Number(payload.clusterId),
        Number(payload.districtId)
      );
    } else if (action === "edit-cluster-name") {
      setSelectedClusterIdForEditName(Number(payload.clusterId));
    } else if (action === "add-all") {
      onAddAllDistrictsToCluster(Number(payload.clusterId));
    } else if (action === "clear-all") {
      onRemoveAllDistrictsFromCluster(Number(payload.clusterId));
    }
  };

  const handleOnSubmitConfigureClusterForm = () => {
    onSubmitConfigureClusterForm();
  };

  const handleOnConfirmNewClusterName = (newClusterName: string) => {
    onUpdateClusterName(selectedClusterIdForEditName!, newClusterName);
    setSelectedClusterIdForEditName(undefined);
  };

  return (
    <ConfigureClusterWrapper>
      <Alert
        message={
          availableProvincesOptions!.length > 0
            ? availableProvincesOptions
                ?.map((province) => province.label)
                .join(", ")
            : t("expert-score-configure-cluster-all-provinces-assigned")
        }
        type="info"
      />

      {appraiserScoreClusterList?.map((cluster) => (
        <ConfigureClusterInput
          key={cluster.id}
          cluster={cluster}
          availableProvincesOptions={availableProvincesOptions}
          onAction={handleOnAction}
        />
      ))}

      <div className="buttons-wrapper">
        <Popconfirm
          placement="top"
          icon={null}
          title={t("configuration-panel-button-save-confirm")!}
          onConfirm={handleOnSubmitConfigureClusterForm}
          okText={t("switch-yes")!}
          cancelText={t("switch-no")!}
        >
          <ButtonConfirm
            disabled={!isFormChanged || availableProvincesOptions?.length! > 0}
            onClick={() => {}}
          >
            {t("button-save")!}
          </ButtonConfirm>
        </Popconfirm>
      </div>

      <ConfigureClusterNameModal
        clusterId={selectedClusterIdForEditName}
        onCancel={() => setSelectedClusterIdForEditName(undefined)}
        onConfirm={handleOnConfirmNewClusterName}
      />
    </ConfigureClusterWrapper>
  );
};

export default ConfigureCluster;
