import styled from "styled-components";
import { ButtonConfirm } from "../../../Layout/Buttons";
import { Collapse } from "antd";
import { BlockedWrapper } from "../../../Widgets/Appraisals/AppraisalsList";
import { IconLocked } from "../../../../config/icons";
import { useTranslation } from "react-i18next";

export const PanelStyled = styled(Collapse.Panel)`
  margin-bottom: 10em;
  margin-bottom: 10em;
`;

const PanelHeaderWithButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .text {
    display: flex;
    align-items: center;
  }
  .text {
    display: flex;
    align-items: center;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1em;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1em;
  }
`;

interface IPanelHeaderWithButtonsProps {
  text: string | undefined | null;
  button1Text?: string | undefined;
  button1OnClick?: () => Promise<boolean | void>;
  button1Disabled?: boolean;
  dataTestId?: string;
  showBlockedIcon?: boolean;
}

const PanelHeaderWithButtons = (props: IPanelHeaderWithButtonsProps) => {
  const { t } = useTranslation();
  const { text, button1Text, button1OnClick, button1Disabled, showBlockedIcon } = props;

  const _title = t("appraisal-blocked");

  return (
    <PanelHeaderWithButtonsWrapper>
      <div className="text">{text}</div>
      <div className="buttons">
        {button1OnClick && button1Text && (
          <ButtonConfirm disabled={button1Disabled} onClick={button1OnClick}>
            {button1Text || ""}
          </ButtonConfirm>
        )}
        {showBlockedIcon && (
          <BlockedWrapper title={_title}>
            <IconLocked />{" "}
          </BlockedWrapper>
        )}
      </div>
    </PanelHeaderWithButtonsWrapper>
  );
};

export default PanelHeaderWithButtons;
