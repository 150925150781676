import { Tooltip } from "antd";
import styled from "styled-components";
import dekra from "../../style/dekra";
import { timerPie } from "../../config/testIds";

export const TimerUpdateWrapper = styled.div<{ width?: string, height?: string }>`
	background-color: #fff;
	box-shadow: 0 0 5px 0 #aaa;
	border-radius: 1em;
	display: flex;
	align-items: center;
	justify-content: center;
	${(props) => (props.width ? `width: ${props.width};` : "")}
	${(props) => (props.height ? `height: ${props.height};` : "")}
`;

const TimePieWrapper = styled.div<{
	size: number;
	color: string;
	percentage: number;
	innerTextSize: number;
	innerTextColor: string;
	bgColor: string;
	gridArea: string;
}>`
	grid-area: ${(props) => props.gridArea};
	width: ${(props) => props.size}px;
	background-color: ${(props) => props.bgColor};
	border-radius: 50%;
	aspect-ratio: 1;
	display: inline-grid;
	place-content: center;
	position: relative;
	cursor: pointer;

	&:before {
		content: "";
		position: absolute;
		border-radius: 50%;
		inset: 0;
		background: conic-gradient(${(props) => props.color} calc(${(props) => props.percentage} * 1%), #0000 0);
	}

	.inner-text {
		font-size: ${(props) => props.innerTextSize}px;
		color: ${(props) => props.innerTextColor};
		z-index: 100;
	}
`;

interface ITimePieProps {
	size?: number;
	percentage: number;
	color?: string;
	tooltip?: string;
	tooltipPlacement?: "top" | "bottom" | "left" | "right";
	innerText?: string;
	innerTextSize?: number;
	innerTextColor?: string;
	bgColor?: string;
	gridArea?: string;
	onClick?: () => void;
}

const TimerPie = (props: ITimePieProps) => {
	const {
		size = 30,
		percentage,
		color,
		tooltip,
		tooltipPlacement = "left",
		innerText,
		innerTextSize = 9,
		innerTextColor = "#fff",
		bgColor = "transparent",
		gridArea = "",
	} = props;

	const renderTimePieWrapper = () => (
		<TimePieWrapper
			size={size}
			color={color || dekra.primaryColor}
			percentage={percentage}
			innerTextSize={innerTextSize}
			innerTextColor={innerTextColor}
			bgColor={bgColor}
			gridArea={gridArea}
			onClick={props.onClick}
			data-testid={timerPie.container}
		>
			<div className="inner-text">{innerText}</div>
		</TimePieWrapper>
	);

	const renderWithTooltip = () => (
		<Tooltip title={tooltip} placement={tooltipPlacement}>
			{renderTimePieWrapper()}
		</Tooltip>
	);

	return tooltip ? renderWithTooltip() : renderTimePieWrapper();
};

export default TimerPie;
