import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SelectedAppraiser, SelectedPractice } from "./models";
import {
  AppraiserMapData,
  CoverageDistrictMapData,
  PracticeMapData,
} from "../../../../redux/apiSpecifications/apiCrud";
import { useEffect, useRef, useState } from "react";
import { getAzureMapAddressConfig } from "../../../../config/azureConfig";
import i18next from "i18next";

const MapContainerWrapper = styled.div`
  flex-direction: column;

  display: flex;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0 5px #aaa;
  overflow: hidden;

  .header {
    color: #333;
    background-color: #ededed;

    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;

    display: flex;

    .title {
      flex: 1 1 0%;
      padding: 0.5em 2em;
    }

    .actions {
      .icon-wrapper {
        font-size: 1.4em;
        margin-top: 6px;
        margin-right: 1em;
        cursor: pointer;
      }
    }
  }

  .body {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .col {
      flex: 1;
    }

    .map {
      width: 100%;
    }
  }

  .popup-close {
    color: white;
  }
`;

const getLanguageCode = (language: string) =>
  language === "it" ? "it-IT" : language === "en" ? "en-US" : "en-US";

interface IAppraiserMapProps {
  appraiser?: AppraiserMapData;
  practices?: PracticeMapData[] | undefined;
  onEditSettings: () => void;
}

type MarkerData<T> = {
  type: "Appraiser" | "Practice";
  icon: string;
  info: T;
};

type Marker = {
  longitude: number;
  latitude: number;
  data: MarkerData<SelectedAppraiser | SelectedPractice>;
  onClick?: (m: Marker) => void;
};

const buildHtmlContent = (
  selectedAppraiserProvince: string,
  selectedAppraiserId: number,
  data: MarkerData<SelectedAppraiser | SelectedPractice>
) => {
  if (data.type === "Appraiser") {
    const appraiser = data.info as SelectedAppraiser;

    let imageSrc;
    if (appraiser.isPosition) {
      imageSrc = appraiser.isInternal
        ? `/images/markers/new/appraisers/internal-${appraiser.saturationClusterCode}-live-big.png`
        : `/images/markers/new/appraisers/external-${appraiser.saturationClusterCode}-live-big.png`;
    } else {
      imageSrc = appraiser.isInternal
        ? `/images/markers/new/appraisers/internal-${appraiser.saturationClusterCode}-big.png`
        : `/images/markers/new/appraisers/external-${appraiser.saturationClusterCode}-big.png`;
    }

    return `<img src="${imageSrc}"/>`;
  } else {
    const practice = data.info as SelectedPractice;
    let imageSrc;

    if (selectedAppraiserProvince === practice.province) {
        if (practice.assignedAppraiserId === selectedAppraiserId) {
            imageSrc = `/images/markers/new/practices/assigned-${practice.status}-big.png`;
        } else {
            imageSrc = `/images/markers/new/practices/not-assigned-${practice.status}-big.png`;
        }
    } else {
        imageSrc = `/images/markers/new/practices/out-${practice.status}-big.png`;
    }
    console.info(selectedAppraiserProvince, practice.province, imageSrc)

    return `<img src="${imageSrc}"/>`;
}
};

const AppraiserMap = ({ appraiser, practices }: IAppraiserMapProps) => {
  const wrapperId: string = "myMap";

  const { t } = useTranslation();

  const [map, setMap] = useState<any>();
  const [atlas, setAtlas] = useState<any>();

  useEffect(() => {
    const azureMapAddressConfig = getAzureMapAddressConfig();

    import("azure-maps-control").then((_atlas) => {
      const mapInstance = new _atlas.Map(wrapperId, {
        language: getLanguageCode(i18next.language),
        authOptions: {
          authType: _atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: azureMapAddressConfig.subscriptionKey,
        },
        showLogo: false,
        showFeedbackLink: false,
        style: "grayscale_light",
      });

      setMap(mapInstance);
      setAtlas(_atlas);
    });
  }, []);

  useEffect(() => {
    if (!map || !atlas || !appraiser || !practices) return;
    map.popups.clear();
    map.markers.clear();

    const markers: Marker[] = [];

    if (appraiser) {
      const appraiserMarker: Marker = {
        longitude: appraiser.coverageDistricts?.[0].longitude!,
        latitude: appraiser.coverageDistricts?.[0].latitude!,
        data: {
          type: "Appraiser",
          icon: `Appraiser_${appraiser.coverageDistricts?.map(
            (c: CoverageDistrictMapData) => c.saturationClusterCode
          )}`,
          info: {
            id: appraiser.id,
            name: appraiser.name,
            idZone: appraiser.coverageDistricts?.[0].idzone,
            longitude: appraiser.coverageDistricts?.[0].longitude,
            latitude: appraiser.coverageDistricts?.[0].latitude,
            saturationClusterCode:
              appraiser.coverageDistricts?.[0].saturationClusterCode,
            address: appraiser.address,
            totalManaged: appraiser.totalManagedPractices,
            dailyCapacity: appraiser.totalDailyCapacity,
            montlyResidual: appraiser.monthlyResidual,
            monthlyCapacity: appraiser.totalMonthlyCapacity,
            score: appraiser.totalScore,
            isInternal: appraiser.isInternal,
            districtCode: appraiser.coverageDistricts?.[0].districtCode,
            isPosition: appraiser.coverageDistricts?.[0].isPosition,
          } as SelectedAppraiser,
        },
      };

      markers.push(appraiserMarker);
    }

    practices.forEach((practice) => {
      const practiceMarker: Marker = {
        longitude: practice?.appraisalLocationAddress?.longitude!,
        latitude: practice?.appraisalLocationAddress?.latitude!,
        data: {
          type: "Practice",
          icon: `Practice_Gray_${practice.practiceStatus}`,
          info: {
            id: practice.id,
            number: practice.practiceNumber,
            status: practice.practiceStatus,
            longitude: practice?.appraisalLocationAddress?.longitude,
            latitude: practice.appraisalLocationAddress?.latitude,
            assignedAppraiser: practice.assignedAppraiser,
            assignedAppraiserId: practice.assignedAppraiserId,
            province: practice.appraisalLocationAddress?.province,
            receiptDate: practice.receiptDate,
            practiceType: practice.practiceTypeCode,
          } as SelectedPractice,
        },
      };

      markers.push(practiceMarker);
    });

    map.events.add("ready", () => {
      const popup = new atlas.Popup({
        position: [0, 0],
        pixelOffset: [0, -18],
      });

      markers.forEach((marker) => {
        const htmlMarker = new atlas.HtmlMarker({
          htmlContent: buildHtmlContent(
            appraiser.coverageDistricts![0].districtCode!,
            appraiser.id!,
            marker.data
          ),
          position: [marker.longitude, marker.latitude],
        });

        map.markers.add(htmlMarker);

        map.events.add("mousemove", htmlMarker, () => {
          const html: string[] = ['<div style="padding:10px;">'];

          if (marker.data.type === "Practice") {
            const practice = marker.data.info as SelectedPractice;

            html.push(practice.number);
          } else if (marker.data.type === "Appraiser") {
            const appraiser = marker.data.info as SelectedAppraiser;

            html.push(appraiser.name);
          }

          html.push("</div>");

          popup.setOptions({
            position: [marker.longitude, marker.latitude],
            content: html.join(""),
          });

          popup.open(map);
        });

        map.events.add("mouseout", htmlMarker, () => {
          popup.close();
        });
      });

      const bounds = atlas.data.BoundingBox.fromPositions(
        markers.map((m) => [m.longitude, m.latitude])
      );

      map.setCamera({
        bounds,
        padding: 50,
      });
    });
  }, [map, appraiser, practices]);

  return (
    <MapContainerWrapper>
      <div className="header">
        <div className="title">{t("assignment-map-label")}</div>
      </div>

      <div className="body">
        <div
          id={wrapperId}
          className="map"
          style={{
            aspectRatio: "1 / 1",
            visibility: map === undefined ? "hidden" : "visible",
          }}
        ></div>
      </div>
    </MapContainerWrapper>
  );
};

export default AppraiserMap;
