import styled from "styled-components";
import { mainTabs as testIds } from "../../../config/testIds";
import { DashboardWrapper, WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { useTranslation } from "react-i18next";

const MessageWrapper = styled.div`
	font-size: 2em;
	padding: 4em;
	color: #aaa;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
`;

const DashBoard_NoRole = () => {
	const { t } = useTranslation();

	return (
		<DashboardWrapper data-testid={testIds.dashboards.NoRole}>
			<WidgetWrapper>
				<MessageWrapper data-testid={testIds.NoRole.messageWrapper}>{t("homepage-available-no-roles-message")}</MessageWrapper>
			</WidgetWrapper>
		</DashboardWrapper>
	);
};

export default DashBoard_NoRole;
