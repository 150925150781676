import { useTranslation } from "react-i18next";
import { SignatureTypes } from "../../types/claim.types";
import { SelectStyled } from "../../style/Input";
import { selectSignatureType as testId } from "../../config/testIds";

interface ISelectSignatureTypeProps {
  classNameItem?: string;
  styleItem?: string;
  onChange: (val: SignatureTypes) => void;
  value: SignatureTypes | undefined;
  disabled?: boolean | undefined;
  dataTestId?: string;
}

const SelectSignatureType = (props: ISelectSignatureTypeProps) => {
  const { t } = useTranslation();

  return (
    <SelectStyled
      classNameItem={props.classNameItem}
      styleItem={props.styleItem}
      label={t("select-signature-type-label")}
      tooltip={t("select-signature-type-tooltip")}
      onChange={props.onChange}
      value={props.value}
      disabled={props.disabled}
      options={[
        {
          value: "",
          label: t("select-signature-type-"),
        },
        {
          value: "0",
          label: t("select-signature-type-0"),
        },
        {
          value: "1",
          label: t("select-signature-type-1"),
        },
        {
          value: "2",
          label: t("select-signature-type-2"),
        },
      ]}
      dataTestId={props.dataTestId || testId.container}
    />
  );
};

export default SelectSignatureType;
