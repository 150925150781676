import { useState } from "react";
import {
  FormItemStyled,
  InputTextProps,
  InputTextStyled,
} from "../../style/Input";
import { Tag } from "antd";
import styled from "styled-components";

const TagsWrapper = styled.div`
  .tag {
    margin-top: 0.5em;
    font-size: 1.2em;
  }
`;

interface InputWithBadgeProps extends InputTextProps {
  updateFilterTag: (val: string[]) => void;
  dataTestId?: string;
}

export const InputWithBadge = (props: InputWithBadgeProps) => {
  const { updateFilterTag, dataTestId } = props;

  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState<string[]>([]);

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      let inputValid = true;

      if (props.mimLength !== undefined) {
        if(inputValue.length < props.mimLength) inputValid = false;
      }

      if (props.mimLength !== undefined) {
        if(inputValue.length > props.maxLength!) inputValid = false;
      }
      if(inputValid) {
      setTags([...tags, inputValue]);
      props.updateFilterTag([...tags, inputValue]);
    } else return

    }

    setInputValue("");
  };

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    updateFilterTag(newTags);
  };

  return (
    <FormItemStyled
      className={props.classNameItem}
      name={props.name}
      style={props.styleItem}
      label={props.label}
      tooltip={props.tooltip}
      validateStatus={props.validateStatus}
      help={props.help}
      labelAlign={props.labelAlign}
    >
      <InputTextStyled
        value={inputValue}
        onChange={setInputValue}
        onPressEnter={handleInputConfirm}
        dataTestId={dataTestId}
        maxLength={props.maxLength}
      />
      <TagsWrapper>
        {tags.map((tag) => (
          <span key={tag}>
            <Tag
              closable
              onClose={(e) => {
                e.preventDefault();
                handleClose(tag);
              }}
              className="tag"
            >
              {tag}
            </Tag>
          </span>
        ))}
      </TagsWrapper>
    </FormItemStyled>
  );
};
