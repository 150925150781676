import { NewRequestFormModel } from "./NewRequestModal/NewRequestFormModel";
import { ReadOnlyPractice } from "../../../../../redux/apiSpecifications/apiCrud";

type RequestConfiguration = {
    category: string;
    enabledRoles: RequestSenderConfiguration[];
};

type RequestSenderConfiguration = {
    roles: string[];
    availableRecipients: number[];
};

const RequestConfigurations: RequestConfiguration[] = [
    {
        category: "AD", // "Autorizza Documentale"
        enabledRoles: [
            {
                roles: ["E2", "E3"], //Perito
                availableRecipients: [4], // Back office
            },
        ],
    },
    {
        category: "ATI", // "Autorizzazione a trattenere l'incarico"
        enabledRoles: [
            {
                roles: ["E2", "E3"], //Perito
                availableRecipients: [4], // Back office
            },
        ],
    },
    {
        category: "CIL", // "Comunicazione intervento Legale in corso di gestione"
        enabledRoles: [
            {
                roles: ["F1"], //Cliente
                availableRecipients: [4], // Back office
            },
        ],
    },
    {
        category: "API", // "Reincarico Perizia Integrativa"
        enabledRoles: [
            {
                roles: ["E2", "E3"], //Perito
                availableRecipients: [4], // Back office
            },
            {
                roles: ["D1", "D2"], //Back Office
                availableRecipients: [6], // Cliente
            },
        ],
    },
    {
        category: "APN", // "Reincarico Perizia Negativa"
        enabledRoles: [
            {
                roles: ["E2", "E3"], //Perito
                availableRecipients: [4], // Back office
            },
            {
                roles: ["D1", "D2"], //Back Office
                availableRecipients: [6], // Cliente
            },
        ],
    },
    {
        category: "RAC", // "Richiesta Accordo Conservativo"
        enabledRoles: [
            {
                roles: ["D1", "D2"], //Back Office
                availableRecipients: [5], // Perito
            },
            {
                roles: ["E1", "E4"], //Sala tecnica
                availableRecipients: [5], // Perito
            },
        ],
    },
    {
        category: "RA", // "Richiesta Aggiornamenti"
        enabledRoles: [
            {
                roles: ["F1"], //Cliente
                availableRecipients: [4], // Back office
            },
            {
                roles: ["D1", "D2"], //Back Office
                availableRecipients: [5], // Perito
            },
            {
                roles: ["E1", "E4"], //Sala tecnica
                availableRecipients: [5], // Perito
            },
        ],
    },
    {
        category: "RCTVP", // "Richiesta Chiarimenti Tecnici Su Veicoli già Periziati"
        enabledRoles: [
            {
                roles: ["E2", "E3"], //Perito
                availableRecipients: [8], // Sala tecnica
            },
        ],
    },
    {
        category: "RCTVN", // "Richiesta Chiarimenti Tecnici Su Veicoli Non Ancora Periziati"
        enabledRoles: [
            {
                roles: ["E2", "E3"], //Perito
                availableRecipients: [8], // Sala tecnica
            },
        ],
    },
    {
        category: "RPAPN", // "Richiesta Prova accesso Perizia Negativa"
        enabledRoles: [
            {
                roles: ["E1", "E4"], //Sala tecnica
                availableRecipients: [5], // Perito
            },
        ],
    },
    {
        category: "S", // "Sollecito"
        enabledRoles: [
            {
                roles: ["F1"], //Cliente
                availableRecipients: [4], // Back office
            },
            {
                roles: ["D1", "D2"], //Back Office
                availableRecipients: [5], // Perito
            },
            {
                roles: ["E1", "E4"], //Sala tecnica
                availableRecipients: [5], // Perito
            },
        ],
    },
    {
        category: "X", // "Altro"
        enabledRoles: [
            {
                roles: ["F1"], //Cliente
                availableRecipients: [4, 8], // Back office, Sala tecnica
            },
            {
                roles: ["E2", "E3"], //Perito
                availableRecipients: [4, 8], // Back office, Sala tecnica
            },
            {
                roles: ["D1", "D2"], //Back Office
                availableRecipients: [8, 6 , 5], // Sala tecnica, Cliente, Perito
            },
            {
                roles: ["E1", "E4"], //Sala tecnica
                availableRecipients: [4, 6 , 5], // Back office, Cliente, Perito
            },
        ],
    },
];

export class RequestHelper {
    static GetCategoriesForNewButton(activeRole: string): string[] {
        const result: string[] = [];

        RequestConfigurations.forEach((requestConfiguration) => {
            requestConfiguration.enabledRoles.forEach((enableRole) => {
                if (enableRole.roles.includes(activeRole)) {
                    result.push(requestConfiguration.category);
                }
            });
        });

        return result;
    }

    static GetRequestReceivers(activeRole: string, category: string): number[] {
        let result: number[] = [];

        const requestConfiguration = RequestConfigurations.find(
            (requestConfiguration) => requestConfiguration.category == category
        );

        if (requestConfiguration !== null) {
            requestConfiguration!.enabledRoles.forEach((enableRole) => {
                if (enableRole.roles.includes(activeRole)) {
                    result = [...result, ...enableRole.availableRecipients];
                }
            });
        }

        return result;
    }

    static BuildNewRequestFormModel(
        activeRole: string,
        category: string,
        practice: ReadOnlyPractice
    ): NewRequestFormModel {
        let newRequestFormModel = {
            category: category,
        } as NewRequestFormModel;

        return newRequestFormModel;
    }
}
