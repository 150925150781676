import { useTranslation } from "react-i18next";
import { AppraisalStatusTypes } from "../../config/const";
import { SelectStyled } from "../../style/Input";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useMemo } from "react";

interface ISelectPracticeStatusProps {
  classNameItem?: string;
  styleItem?: any;
  onChange: (val: AppraisalStatusTypes) => void;
  value: AppraisalStatusTypes | undefined;
  dataTestId?: string;
  disabled?: boolean;
  labelAlign?: "left" | "right";
}

const SelectPracticeStatus = (props: ISelectPracticeStatusProps) => {
  const { t } = useTranslation();
  const language = useSelector((state: RootState) => state.user.language);

  const options = useMemo(() => {
    return [
      "",
      "DA",
      "SC",
      "ST",
      "PA",
      "AD",
      "FZ",
      "RT",
      "AF",
      "CP",
      "ZC",
      "RC",
      "CQ",
      "IP",
      "IV",
      "PP",
      "AC",
      "CO",
      "SUS",
      "CAN",
      "SUB",
      "RE",
      "CI",
      "CR",
      "CF",
    ]
      .map((code) => ({
        value: code,
        label: t(`appraisal-statuses-${code}`),
      }))
      .sort((a, b) => (a!.label < b!.label ? -1 : 1));
  }, [language]);

  return (
    <SelectStyled
      disabled={props.disabled}
      classNameItem={props.classNameItem}
      styleItem={props.styleItem}
      label={t("appraisals-details-tab-details-appraisals-status-label")}
      onChange={(val) => props.onChange(val as AppraisalStatusTypes)}
      value={t(`appraisal-statuses-${props.value}`)}
      labelAlign={props.labelAlign}
      options={options}
      dataTestId={props.dataTestId}
    />
  );
};

export default SelectPracticeStatus;
