import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { dateTimeToNumber } from "../../../../../../utils/date";
import moment from "moment";
import { ButtonConfirm } from "../../../../../Layout/Buttons";
import { fileToBase64 } from "../../../../../../utils/fileUtils";
import { PracticeAttachment } from "../../../../../../redux/apiSpecifications/apiCrud";
import { DocumentFiles } from "../../../../../../redux/apiSpecifications/apiCrud";
import { IconLoading } from "../../../../../../config/icons";
import { UpdateQuestionnaireDataType } from "./ConcludePractice";

const WaitingForAgreementWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1em;

  .title {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    padding: 0.5em 0;
  }

  .info {
    background-color: #e6f4ff;
  }

  .message-wrapper {
    background-color: orange;
    color: #fff;
  }

  .loader {
    width: 100px;
    height: 100px;
    margin: auto;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
`;

interface IWaitingForAgreementProps {
  practiceStatusDate?: string;
  onUpdateQuestionnaire: (val: UpdateQuestionnaireDataType) => void;
}

const WaitingForAgreement = ({
  practiceStatusDate,
  onUpdateQuestionnaire,
}: IWaitingForAgreementProps) => {
  const { t } = useTranslation();

  const [loadingAgreement, setLoadingAgreement] =
    React.useState<boolean>(false);

  const refAgreementFile = React.useRef<HTMLInputElement>(null);

  const timeRemaining = useMemo(() => {
    if (practiceStatusDate) {
      const practiceStatusDateNumber = dateTimeToNumber(practiceStatusDate);

      const practiceStatusDateMoment = moment(
        practiceStatusDateNumber.toString(),
        "YYYYMMDDHHmmss"
      );

      const nowMoment = moment();

      const duration = moment.duration(
        practiceStatusDateMoment.add(48, "hours").diff(nowMoment)
      );

      const hours = duration.days() * 24 + duration.hours();

      const minutes = duration.minutes();

      const result: number[] = [];

      if (minutes > 0) {
        return `${hours}:${minutes.toString().padStart(2, "0")}`;
      } else {
        return `00:00`;
      }
    }

    return "";
  }, [practiceStatusDate]);

  const selectAgreementFile = () => refAgreementFile.current?.click();

  const handleLoadAgreement = async (e: any) => {
    if (!e) return;

    const selectedFile = e.target.files[0];

    if (!selectedFile) return;

    setLoadingAgreement(true);

    const fileContent = await fileToBase64(selectedFile);

    const attachment = {
      id: 0,
      name: selectedFile.name,
      originalName: selectedFile.name,
      base64File: fileContent,
    } as PracticeAttachment;

    const practiceDocument: DocumentFiles = {
      documentCode: "",
      documentTypeCode: "ACC",
      attachmentsFiles: [attachment],
    };

    onUpdateQuestionnaire({
      questionnaire: {
        agreement: "S",
      },
      documents: [practiceDocument],
      status: "questionnare",
    });
  };

  return (
    <WaitingForAgreementWrapper>
      {loadingAgreement && <div className="loader">{IconLoading}</div>}
      {!loadingAgreement && (
        <>
          <div className="info">
            <div className="title">
              {t("appraisals-conclude-practice-waiting-for-agreement-title")}
            </div>

            <ul>
              <li>
                {t(
                  "appraisals-conclude-practice-waiting-for-agreement-message",
                  {
                    timeRemaining: timeRemaining,
                  }
                )}
              </li>
            </ul>
          </div>

          <div className="buttons">
            <ButtonConfirm onClick={selectAgreementFile}>
              {
                t(
                  "appraisals-conclude-practice-button-solve-missing-agreement-load"
                )!
              }
            </ButtonConfirm>

            <input
              ref={refAgreementFile}
              type="file"
              accept="application/pdf"
              style={{ display: "none" }}
              onChange={(e) => handleLoadAgreement(e)}
            />
          </div>
        </>
      )}
    </WaitingForAgreementWrapper>
  );
};

export default WaitingForAgreement;
