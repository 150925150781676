import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IconLoading } from "../../../../../config/icons";
import { Tag } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import { useLazyDownloadAttachmentByDocumentCodeQuery } from "../../../../../redux/apiSpecifications/apiDocs";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { DocumentFiles } from "../../../../../redux/apiSpecifications/apiCrud";
import React from "react";

const ActionsWrapper = styled.div`
    display: flex;
    gap: 1em;

    .confirm-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
    }

    button {
        font-size: 0.9em;
    }
`;

interface IBusinessReportActionsProps {
    businessReportId: number;
    businessReportDocumentCode: string;
    generationStatus: string;
}

const BusinessReportActions = ({
    businessReportId,
    businessReportDocumentCode,
    generationStatus,
}: IBusinessReportActionsProps) => {
    const { t } = useTranslation();

    const refDownload = React.useRef<HTMLAnchorElement>(null);

    const { authorization } = useSelector((state: RootState) => state.user);

    const [downloadAttachmentByDocumentCode] =
        useLazyDownloadAttachmentByDocumentCodeQuery();

    const handleOnDownloadReport = async (
        businessReportDocumentCode: string
    ) => {
        const response = await downloadAttachmentByDocumentCode({
            authorization: authorization,
            documentCode: businessReportDocumentCode,
        });

        const { data, error, isSuccess, isError } = response;

        if (isSuccess && data) {
            const documentFiles = data as DocumentFiles;

            if (
                documentFiles &&
                documentFiles.attachmentsFiles &&
                documentFiles.attachmentsFiles.length > 0
            ) {
                const attachmentFile = documentFiles.attachmentsFiles[0];

                const link = document.createElement("a");

                link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${attachmentFile?.base64File!}`;
                link.target = "_blank";
                link.download = attachmentFile?.originalName!; // `${attachmentFile?.originalName}.xlsx`;
                link.click();

                refDownload?.current?.click();
            }
        } else {
            console.info(error);
        }
    };

    return (
        <ActionsWrapper>
            {["Q", "W"].includes(generationStatus) && (
                <div className="confirm-loader">{IconLoading}</div>
            )}

            {generationStatus === "E" && (
                <Tag icon={<CloseCircleOutlined />} color="error">
                    {t("report-generation-status-code-E")}
                </Tag>
            )}

            {generationStatus === "C" && (
                <ButtonConfirm
                    onClick={() =>
                        handleOnDownloadReport(businessReportDocumentCode)
                    }
                >
                    {t("button-download-report")!}
                </ButtonConfirm>
            )}
        </ActionsWrapper>
    );
};

export default BusinessReportActions;
