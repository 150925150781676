import styled from "styled-components";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import dekra from "../../../style/dekra";
import { mainTabs, widgets } from "../../../config/testIds";
import { useTranslation } from "react-i18next";

const testIdsD2 = mainTabs.D2.statuses;

const RowWrapper = styled.div<{ gridArea?: string }>`
    grid-area: ${(props) => (props.gridArea ? props.gridArea : "")};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;
`;

const StatusesWrapper = styled(WidgetWrapper)`
    align-items: center;
    justify-content: space-between;
    font-size: 1.1em;

    .appraiser-type {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 0.5em 1em;
        line-height: 1em;

        border-right: 2px solid #bbb;

        // &.active {
        //     background-color: #fff;
        //     svg {
        //         color: ${dekra.primaryColor};
        //     }

        //     .title {
        //         color: ${dekra.primaryColor};
        //     }

        //     .value {
        //         color: ${dekra.primaryColor};
        //     }
        // }

        &.active-type {
            background-color: ${dekra.primaryColor};
            height: 100%;
            cursor: pointer;
            svg {
                color: ${dekra.secondaryColors.white};
            }
            .text {
                color: ${dekra.secondaryColors.white};
            }
            .value {
                color: ${dekra.secondaryColors.white};
            }

            &:hover {
                svg {
                    color: ${dekra.secondaryColors.black};
                }
                .text {
                    color: ${dekra.secondaryColors.black};
                }
                .value {
                    color: ${dekra.secondaryColors.black};
                }
            }
        }

        &.inactive-type {
            cursor: pointer;
            &:hover {
                svg {
                    color: ${dekra.primaryColor};
                }
                .text {
                    color: ${dekra.primaryColor};
                }
                .value {
                    color: ${dekra.primaryColor};
                }
            }
        }

        &:last-child {
            border-right: none;
        }

        svg {
            font-size: 1.4em;
            color: #aaa;
        }

        .appraiser-type-details {
            display: flex;
            align-items: center;
            color: #333;
            font-size: 1em;
            text-transform: uppercase;
            letter-spacing: 1px;

            .text {
                margin-left: 0.5em;
                align-self: flex-end;
                text-wrap: nowrap;
            }

            .value {
                margin-left: 0.5em;
                font-weight: bold;
            }
        }
    }
`;

export interface IAppraisersTypeFilterProps {
    external?: number;
    internal?: number;
    all?: number;
    selected?: AppraisersTypes;
    gridArea?: string;
    onSelect: (status: AppraisersTypes) => void;
}

export type AppraisersTypes = "internal" | "external" | "all";

const AppraisersTypeFilter = ({
    external,
    internal,
    all,
    selected = "external",
    gridArea,
    onSelect,
}: IAppraisersTypeFilterProps) => {
    const { t } = useTranslation();

    return (
        <RowWrapper gridArea={gridArea}>
            <StatusesWrapper data-testid={widgets.appraisalsStatuses.extention}>
                <div
                    className={`appraiser-type ${
                        selected === "external"
                            ? "active-type"
                            : "inactive-type"
                    }`}
                    onClick={() => onSelect("external")}
                >
                    <div className="appraiser-type-details">
                        <div className="text">
                            {t("appraisers-dashboard-type-external")}
                        </div>

                        <div
                            className="value"
                            data-testid={testIdsD2.inVerification.value}
                        >
                            {external}
                        </div>
                    </div>
                </div>

                <div
                    className={`appraiser-type ${
                        selected === "internal"
                            ? "active-type"
                            : "inactive-type"
                    }`}
                    onClick={() => onSelect("internal")}
                >
                    <div className="appraiser-type-details">
                        <div className="text">
                            {t("appraisers-dashboard-type-internal")}
                        </div>

                        <div
                            className="value"
                            data-testid={testIdsD2.cancelled.value}
                        >
                            {internal}
                        </div>
                    </div>
                </div>

                <div
                    className={`appraiser-type ${
                        selected === "all" ? "active-type" : "inactive-type"
                    }`}
                    onClick={() => onSelect("all")}
                >
                    <div className="appraiser-type-details">
                        <div className="text">
                            {t("appraisers-dashboard-type-all")}
                        </div>

                        <div
                            className="value"
                            data-testid={testIdsD2.cancelled.value}
                        >
                            {all}
                        </div>
                    </div>
                </div>
            </StatusesWrapper>
        </RowWrapper>
    );
};

export default AppraisersTypeFilter;
