import React from "react";
import styled from "styled-components";
import DonutChart, { DonutDataItemType } from "../../../../Charts/DonutChart";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  width: 100%;
`;

const DonutsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1em;
  padding: 1em 1.5em;
`;

const DonutContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 260px;
  position: relative;

  h4 {
    text-align: center;
    margin-bottom: 0.25em;
    color: #333;
    font-size: 1.1em;
  }

  .recharts-pie {
    outline: none;
  }

  .total-number {
    display: flex;
    font-size: 20px;
    font-weight: bold;
    color: #000;
  }
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  background-color: #fff;
  padding: 0.5em 1em 0.25em 1em;
  box-shadow: 0 0 5px #555;
  border-radius: 1em;

  .value {
    font-weight: bold;
    font-size: 1.2em;
    margin-right: 0.5em;
  }

  .name {
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 2px;
  }
`;

const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding: 0.5em 0;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const LegendDot = styled.span<{ color: string }>`
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`;

export interface DonutsData {
  key: string;
  title: string;
  items: DonutDataItemType[];
  centerValue: string;
  selectedItemKey?: string;
}

export interface DonutsOverviewOptions {
  uniqueLegend?: boolean;
}

interface DonutsOverviewProps {
  donuts: DonutsData[];
  options?: DonutsOverviewOptions;
  onClick: (donutKey: string, itemKey: string) => void;
}

const DonutsOverview: React.FC<DonutsOverviewProps> = ({
  donuts,
  options,
  onClick,
}) => {

  const { t } = useTranslation();

  const uniqueLegend = options?.uniqueLegend === true;

  const uniqueCategories = Array.from(
    new Set(donuts.flatMap((donut) => donut.items.map((item) => item.name)))
  ).map((category, index) => ({
    category,
    color: `hsl(${(index * 360) / donuts.length}, 70%, 50%)`,
  }));

  return (
    <Wrapper>
      <DonutsContainer>
        {donuts.map((donut) => (
          <DonutContainer key={donut.key}>
            <DonutChart
              title={donut.title}
              data={donut.items}
              onClick={(itemKey) => {
                onClick(donut.key, itemKey!);
              }}
              centerValue={donut.centerValue}
              selected={donut.selectedItemKey}
              hideLegend={uniqueLegend}
            />
          </DonutContainer>
        ))}
      </DonutsContainer>

      {uniqueLegend && (
        <LegendContainer>
        {uniqueCategories.map((item) => (
          <LegendItem key={item.category}>
            <LegendDot
              color={
                donuts
                  .find((donut) =>
                    donut.items.some(
                      (donutItem) => donutItem.name === item.category
                    )
                  )
                  ?.items.find((donutItem) => donutItem.name === item.category)
                  ?.fill || item.color
              }
            />{" "}
            <span className="legend-label">{t(`dashboard-a1-list-claim-status-code-${item.category}`)}</span>
          </LegendItem>
        ))}
      </LegendContainer>
      )}
      
    </Wrapper>
  );
};

export default DonutsOverview;
