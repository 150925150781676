import { Alert, Modal, Space } from "antd";
import styled from "styled-components";
import { ButtonsWrapper } from "../../../UserManagement/style/ButtonsWrapper";
import { ButtonCancel, ButtonConfirm } from "../../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import { ModalTitleWrapper } from "../../../../../style/containers";
import { InputTextStyled } from "../../../../../style/Input";
import { useEffect, useMemo, useState } from "react";
import { useExpertizeScoreContext } from "../../ExpertizeScoreContext";

const ModalContent = styled.div`
  padding: 2em;

  .ant-form-item-explain {
    color: red !important;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
`;

const ConfigureClusterNameModalTitleWrapper = styled(ModalTitleWrapper)`
  padding: 0 0 1em 0;
  svg {
    margin-left: 0;
  }
  span {
    margin-left: 1em;
    color: #333;
    letter-spacing: 1px;
  }
`;

interface IConfigureClusterNameModalProps {
  clusterId?: number;
  onConfirm: (newClusterName: string) => void;
  onCancel: () => void;
}

const ConfigureClusterNameModal = ({
  clusterId,
  onConfirm,
  onCancel,
}: IConfigureClusterNameModalProps) => {
  const { t } = useTranslation();

  const [newClusterName, setNewClusterName] = useState<string>("");

  const { appraiserScoreClusterList } = useExpertizeScoreContext();

  useEffect(() => {
    if (clusterId) {
      const selectedCluster = appraiserScoreClusterList?.find(
        (_) => _.id === clusterId
      );

      if (selectedCluster) {
        setNewClusterName(selectedCluster.clusterName!);
      }
    }
  }, [clusterId, appraiserScoreClusterList]);

  const isNameAlreadyUsed = useMemo(
    () =>
      appraiserScoreClusterList?.find(
        (_) =>
          _.id !== clusterId! &&
          _.clusterName?.toUpperCase() === newClusterName?.trim().toUpperCase()
      ) !== undefined,
    [newClusterName, appraiserScoreClusterList, clusterId]
  );

  const isNameValid = useMemo(() => {
    return newClusterName!.trim().length > 0 && !isNameAlreadyUsed;
  }, [newClusterName, isNameAlreadyUsed]);

  const handleInputChange = (newName: string) => {
    setNewClusterName(newName);
  };

  const handleConfirm = () => {
    onConfirm(newClusterName!.trim());
  };

  return (
    <Modal open={clusterId !== undefined} footer={false} onCancel={onCancel}>
      <ModalContent>
        <ConfigureClusterNameModalTitleWrapper>
          <span>{t("configure-cluster-name-modal-title")}</span>
        </ConfigureClusterNameModalTitleWrapper>

        <div className="form">
          <InputTextStyled
            value={newClusterName}
            onChange={handleInputChange}
          />

          {isNameAlreadyUsed && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Alert
                message={t("configure-cluster-name-already-used-message")}
                type="info"
              />
            </Space>
          )}
        </div>

        <ButtonsWrapper>
          <ButtonCancel onClick={onCancel}>{t("button-cancel")!}</ButtonCancel>

          <ButtonConfirm
            onClick={handleConfirm}
            disabled={isNameAlreadyUsed || !isNameValid}
          >
            {t("button-confirm")!}
          </ButtonConfirm>
        </ButtonsWrapper>
      </ModalContent>
    </Modal>
  );
};
export default ConfigureClusterNameModal;
