import { useSelector } from "react-redux";
import styled from "styled-components";
import { mainTabs as testIds } from "../../../../config/testIds";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Tabs } from "antd";
import { Tab } from "rc-tabs/lib/interface";
import AppraisalDetailsTabDetails from "./AppraisalDetailsTabDetails/AppraisalDetailsTabDetails";
import {
    AppraisalSubject,
    ReadOnlyPractice,
    WriteOnlyPractice,
    useLazyGetPracticeDetailQuery,
    usePracticeBlockMutation,
    usePracticeUnblockMutation,
    useUpdatePracticeDetailMutation,
} from "../../../../redux/apiSpecifications/apiCrud";
import { UserUICommunication } from "../../../../types/common.types";
import { RootState } from "../../../../redux/store";
import {
    LoadingInTabWrapper,
    MainTabContentWrapper,
} from "../../../../style/DashbordWidgetWrappers";
import { IconLoading } from "../../../../config/icons";
import AppraisalDetailsTabActivities from "./AppraisalDetailsActivities/AppraisalDetailsTabActivities";
import AppraisalDetailsHistoricalActions from "./AppraisalDetailsHistoricalActions/AppraisalDetailsHistoricalActions";
import ResumeInfo from "../Commons/ResumeInfo";
import { EmptyContentWrapper, TabsWrapper } from "../Commons/Wrappers";
import { useAuthorization } from "../../../../hooks/useAuthorization";
import AppraisalDetailsDocumentation from "./AppraisalDetailsDocumentation/AppraisalDetailsDocumentation";
import AppraisalDetailsTabRequests from "./AppraisalDetailsRequests/AppraisalDetailsTabRequests";
import { error } from "console";

const ModalContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2em;
    padding: 2em 0;

    .text {
        font-size: 1.4em;
    }

    .icon {
        width: 100px;
    }
`;

type OriginalLocationSubjectsItem = {
    name: string | undefined;
    lastname: string | undefined;
    businessName: string | undefined;
    subjectType: string | undefined;
    fiscalCode: string | undefined;
    pIva: string | undefined;
};

type OriginalLocationSubjectsData = {
    owner: OriginalLocationSubjectsItem;
    communicationRecipient: OriginalLocationSubjectsItem;
    appraisalLocation: OriginalLocationSubjectsItem;
};

export interface IAppraisalDetailsProps {
    idPractice: number;
    defaultTab?: string;
    defaultTabPanel?: string;
    showLoadADZ?: boolean;
    tabKey: number;
}

const AppraisalDetails = (props: IAppraisalDetailsProps) => {
    const { t } = useTranslation();

    const { idPractice, tabKey, showLoadADZ, defaultTab, defaultTabPanel } = props;
    const { activeKey } = useSelector((state: RootState) => state.mainTabs);
    const [practiceDetails, setPracticeDetails] = useState<ReadOnlyPractice>();
    const [canSave, setCanSave] = useState(false);
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const [practiceModified, setPracticeModified] = useState(false);

    useState<UserUICommunication>({ action: "idle" } as UserUICommunication);
    const [retrievePractice] = useLazyGetPracticeDetailQuery();

    const [updatePractice] = useUpdatePracticeDetailMutation();

    const [blockPractice] = usePracticeBlockMutation();
    const [unblockPractice] = usePracticeUnblockMutation();
    const [alreadyBlockedPractice, setAlreadyBlockedPractice] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [isBlockingPractice, setIsBlockingPractice] = useState(false);
    const [blockedPracticeError, setBlockedPracticeError] = useState<
        string | undefined
    >();
    const [isSavingDetails, setIsSavingDetails] = useState(false);
    const [savingDetailsError, setSavingDetailsError] = useState<
        string | undefined
    >();

    const { activeRole } = useSelector((state: RootState) => state.user);

    const { getAuthorization } = useAuthorization();

    const [loadingStatus, setLoadingStatus] = useState<
        "idle" | "loading" | "refreshing"
    >("idle");

    const [keyActive, setKeyActive] = useState(
        showLoadADZ ? "activities" : "details"
    );

    const [items, setItems] = useState<Tab[]>([]);

    const [originalLocationSubjectsData, setOriginalLocationSubjectsData] =
        useState<OriginalLocationSubjectsData>();

    useEffect(() => {
        return () => {
            if (!!idPractice && alreadyBlockedPractice) {
                (async () => {
                    await unblockPractice({
                        authorization: await getAuthorization(),
                        id: idPractice,
                    });
                })();
            }
        };
    }, [idPractice, alreadyBlockedPractice]);

    useEffect(() => {
        (async () => {
            setLoadingStatus("loading");
            const { data, error, isSuccess, status } = await retrievePractice({
                authorization: await getAuthorization(),
                id: idPractice,
                activeRole: activeRole!,
            });
            if (isSuccess && data) {
                const practiceDetails = data as ReadOnlyPractice;

                setPracticeDetails(practiceDetails);
                setOriginalLocationSubjectsData({
                    owner: {
                        name: practiceDetails?.owner?.name,
                        lastname: practiceDetails?.owner?.lastname,
                        businessName: practiceDetails?.owner?.businessName,
                        subjectType: practiceDetails?.owner?.subjectType,
                        fiscalCode: practiceDetails?.owner?.fiscalCode,
                        pIva: practiceDetails?.owner?.pIva,
                    },
                    communicationRecipient: {
                        name: practiceDetails?.communicationRecipient?.name,
                        lastname:
                            practiceDetails?.communicationRecipient?.lastname,
                        businessName:
                            practiceDetails?.communicationRecipient
                                ?.businessName,
                        subjectType:
                            practiceDetails?.communicationRecipient
                                ?.subjectType,
                        fiscalCode:
                            practiceDetails?.communicationRecipient?.fiscalCode,
                        pIva: practiceDetails?.communicationRecipient?.pIva,
                    },
                    appraisalLocation: {
                        name: practiceDetails?.appraisalLocation?.name,
                        lastname: practiceDetails?.appraisalLocation?.lastname,
                        businessName:
                            practiceDetails?.appraisalLocation?.businessName,
                        subjectType:
                            practiceDetails?.appraisalLocation?.subjectType,
                        fiscalCode:
                            practiceDetails?.appraisalLocation?.fiscalCode,
                        pIva: practiceDetails?.appraisalLocation?.pIva,
                    },
                });
            } else {
                console.log(error);
            }
            setLoadingStatus("idle");
        })();
    }, [idPractice]);

    const handleOnChangeDetails = async (value: any, field: string) => {
        const updatedPracticeDetails = { ...practiceDetails, [field]: value };
        setCanSave(true);
        setPracticeModified(true);
        setPracticeDetails(updatedPracticeDetails);

        if (alreadyBlockedPractice) {
            return;
        }

        setIsBlockingPractice(true);

        if (practiceDetails?.isBlocked || !practiceDetails?.id) return;

        setShowModal(true);
        setIsSavingDetails(false);

        const result: any = await blockPractice({
            authorization: await getAuthorization(),
            id: practiceDetails.id,
        });

        if (result.data?.id === practiceDetails?.id) {
            setAlreadyBlockedPractice(true);
            setBlockedPracticeError(undefined);
            setShowModal(false);
        } else {
            setBlockedPracticeError(
                t("appraisal-details-blocking-practice-error")!
            );
        }

        setIsBlockingPractice(false);
    };

    const updateLocationSubjectId = useCallback(
        (
            subject: AppraisalSubject | undefined | null,
            originalSubject: OriginalLocationSubjectsItem
        ): AppraisalSubject | undefined => {
            let setIdToZero = false;
            if (subject?.subjectType === "PF") {
                if ((subject?.fiscalCode?.length || 0) > 0) {
                    if (subject?.fiscalCode !== originalSubject.fiscalCode)
                        setIdToZero = true;
                } else {
                    const nameChanged = subject?.name !== originalSubject?.name;
                    const lastnameChanged =
                        subject?.lastname !== originalSubject?.lastname;
                    const fiscalCodeChanged =
                        subject?.fiscalCode !== originalSubject?.fiscalCode;

                    if (nameChanged || lastnameChanged) setIdToZero = true;
                }
            } else if (subject?.subjectType === "PG") {
                if ((subject?.pIva?.length || 0) > 0) {
                    if (subject?.pIva !== originalSubject.pIva)
                        setIdToZero = true;
                } else {
                    const businessNameChanged =
                        subject?.businessName !== originalSubject?.businessName;
                    const pIvaChanged = subject?.pIva !== originalSubject?.pIva;
                    if (pIvaChanged || businessNameChanged) setIdToZero = true;
                }
            }

            if (!setIdToZero) return subject || undefined;

            return {
                ...subject,
                id: 0,
                address: {
                    ...subject?.address,
                    id: 0,
                },
            };
        },
        []
    );

    const handleOnSaveDetails = async () => {
        if (!practiceDetails || !practiceDetails.id) return true;

        setShowModal(true);
        setIsSavingDetails(true);

        // check if location & subject are changed
        const writeOnlyPractice = {
            ...practiceDetails,
            owner: practiceDetails?.owner,
            // updateLocationSubjectId(
            //   practiceDetails?.owner,
            //   originalLocationSubjectsData?.owner!
            // ),
            communicationRecipient: practiceDetails?.communicationRecipient,
            // updateLocationSubjectId(
            //   practiceDetails?.communicationRecipient,
            //   originalLocationSubjectsData?.communicationRecipient!
            // ),
            appraisalLocation: practiceDetails?.appraisalLocation,
            // updateLocationSubjectId(
            //   practiceDetails?.appraisalLocation,
            //   originalLocationSubjectsData?.appraisalLocation!
            // ),
            relatedPractices: undefined,
            appraisalLocationHist: undefined,
            appraiser: undefined,
        } as WriteOnlyPractice;

        const result: any = await updatePractice({
            authorization: await getAuthorization(),
            activeRole: activeRole!,
            id: practiceDetails.id,
            writeOnlyPractice,
        });

        const practiceResult = result.data as ReadOnlyPractice;

    if (practiceResult.id === practiceDetails.id) {
      setPracticeDetails(practiceResult);
      setShowModal(false);
      setIsSavingDetails(true);
      setCanSave(false);
      setPracticeModified(false);
      return true;
    } else {
      setSavingDetailsError(t("appraisal-details-save-practice-error")!);
      setIsSavingDetails(false);
      setCanSave(true);
      setPracticeModified(true);
      return false;
    }
  };

    const handleUpdatePractice = (practice: ReadOnlyPractice) => {
        setPracticeDetails(practice);
    };

    const onChange = (key: string) => {
        setKeyActive(key);
    };

    const handleDisableEnableSaveButton = (val: boolean) => {
        setCanSave(!val);
    };

    const allItems = [
        {
            key: "details",
            label: t("appraisals-details-tab-details-label"),
            children: (
                <AppraisalDetailsTabDetails
                    practice={practiceDetails}
                    onChange={(value: any, field: string) =>
                        handleOnChangeDetails(value, field)
                    }
                    onSave={() => handleOnSaveDetails()}
                    isActive={activeKey === props.tabKey}
                    disableButton={(val) => handleDisableEnableSaveButton(val)}
                    onValidateFields={(errors) => setFormErrors([...errors])}
                    defaultActiveKey={defaultTabPanel ? [defaultTabPanel] : undefined}
                />
            ),
        },
        {
            key: "activities",
            label: t("appraisals-details-tab-activities-label"),
            children: (
                <AppraisalDetailsTabActivities
                    practice={practiceDetails}
                    isActive={activeKey === props.tabKey}
                    onUpdatePractice={handleUpdatePractice}
                    defaultActiveKey={
                        showLoadADZ ? ["conclude-practice"] : undefined
                    }
                    onRefreshPractice={setPracticeDetails}
                />
            ),
        } as Tab,
        {
            key: "documentation",
            label: t("appraisals-details-tab-documentation-label"),
            children: (
                <AppraisalDetailsDocumentation
                    idPractice={practiceDetails?.id}
                    isPracticeInvoiced={practiceDetails?.status === "CF"}
                />
            ),
        } as Tab,
        {
            key: "historical",
            label: t("appraisals-details-tab-historical-label"),
            children: (
                <AppraisalDetailsHistoricalActions
                    practice={practiceDetails}
                    isActive={activeKey === props.tabKey}
                />
            ),
        } as Tab,
        {
            key: "notifications",
            label: t("appraisals-details-tab-notifications-label"),
            children: (
                <AppraisalDetailsTabRequests
                    practice={practiceDetails!}
                    isActive={activeKey === props.tabKey}
                />
            ),
        } as Tab,
    ];

    const { practiceTypeCode, policy, owner, claim } = practiceDetails || {};

    const _expertiseType = t(
        `appraisals-details-tab-details-appraisals-expertise-type-${practiceTypeCode}`
    );

    const _owner =
        owner?.subjectType === "PF"
            ? `${owner?.name} ${owner?.lastname}`
            : owner?.businessName;
    const _occurrenceDate = claim?.occurrenceDate;
    const practiceNumber = claim?.number;

    const insuredProperties = practiceDetails?.policy?.insuredProperties || [];
    const { plateNumber } = insuredProperties[0] || {};

    const dateReceived = practiceDetails?.practiceDate;
    const appraiserName =
        practiceDetails?.appraiser?.subject?.subjectType === "PF"
            ? `${practiceDetails?.appraiser?.subject?.name} ${practiceDetails?.appraiser?.subject?.lastname}`
            : practiceDetails?.appraiser?.subject?.businessName;

    const handleOnSave = async () => {
        const result = await handleOnSaveDetails();
        if (result) {
            setCanSave(false);
            setPracticeModified(false);

            unblockPractice({
                authorization: await getAuthorization(),
                id: idPractice,
            });

            setAlreadyBlockedPractice(false);
            setIsBlockingPractice(false);
            setShowModal(false);
        }
    };

    useEffect(() => {
        let _items: Tab[] = [];

        if (["F1"].includes(activeRole || "")) {
            _items = [
                allItems.find((i) => i.key === "details")!,
                allItems.find((i) => i.key === "documentation")!,
                // allItems.find((i) => i.key === "historical")!,
                allItems.find((i) => i.key === "notifications")!,
            ];
        } else if (["G1", "G2"].includes(activeRole || "")) {
            _items = [
                allItems.find((i) => i.key === "details")!,
                allItems.find((i) => i.key === "documentation")!,
                allItems.find((i) => i.key === "historical")!,
            ];
        } else if (["E2", "E3", "D1", "D2", "E4", "F1", "F2"]) {
            _items = [...allItems];
        }

        setItems(_items);
    }, [activeRole, practiceDetails, alreadyBlockedPractice]);

    return (
        <>
            {loadingStatus === "loading" && (
                <LoadingInTabWrapper>
                    <div className="box" data-testid={""}>
                        {IconLoading}
                    </div>
                </LoadingInTabWrapper>
            )}

            {loadingStatus === "idle" && (
                <MainTabContentWrapper
                    data-testid={testIds.tabs.appraisalDetails.container}
                >
                    <>
                        <ResumeInfo
                            items={[
                                {
                                    label: t(
                                        "appraisals-details-avi-expertise-type"
                                    ),
                                    value: _expertiseType,
                                },
                                {
                                    label: t(
                                        "appraisals-details-avi-claim-number"
                                    ),
                                    value: practiceNumber || "...",
                                },
                                {
                                    label: t("appraisals-details-avi-plate"),
                                    value: plateNumber || "...",
                                },
                                {
                                    label: t("appraisals-details-avi-owner"),
                                    value: _owner || "",
                                },
                                {
                                    label: t(
                                        "appraisals-details-tab-details-appraisals-occurrence-date-label"
                                    ),
                                    value: _occurrenceDate || "",
                                },
                                {
                                    label: t("appraisals-details-recived-date"),
                                    value: dateReceived || "",
                                },
                                {
                                    label: t(
                                        "appraisals-details-appraiser-name"
                                    ),
                                    value: appraiserName || "",
                                },
                            ]}
                            button1Text={t("button-save")!}
                            button1OnClick={() => handleOnSave()}
                            button1Disabled={
                                formErrors.length > 0 || !practiceModified
                            }
                            formErrors={formErrors}
                            data-testid={
                                testIds.tabs.appraisalDetails.resumeInfo
                            }
                        />

                        {/* <pre>{JSON.stringify(formErrors, null, 2)}</pre> */}

                        <TabsWrapper data-testid={testIds.container}>
                            <Tabs
                                items={items}
                                tabPosition="left"
                                onChange={onChange}
                                //activeKey={keyActive}
                                defaultActiveKey={defaultTab || keyActive}
                            />
                        </TabsWrapper>
                    </>

                    {showModal && (
                        <Modal
                            open={showModal}
                            footer={null}
                            onCancel={() => setShowModal(false)}
                        >
                            <ModalContent>
                                {isBlockingPractice && (
                                    <>
                                        <div className="text">
                                            {t(
                                                "appraisal-details-blocking-practice-in-progress"
                                            )}
                                        </div>
                                        <div className="icon">
                                            {IconLoading}
                                        </div>
                                    </>
                                )}
                                {blockedPracticeError && (
                                    <div className="text">
                                        {t(
                                            "appraisal-details-blocking-practice-error"
                                        )}
                                    </div>
                                )}
                                {isSavingDetails && (
                                    <>
                                        <div className="text">
                                            {t(
                                                "appraisal-details-save-practice-in-progress"
                                            )}
                                        </div>
                                        <div className="icon">
                                            {IconLoading}
                                        </div>
                                    </>
                                )}

                                {savingDetailsError && (
                                    <div className="text">
                                        {t(
                                            "appraisal-details-save-practice-error"
                                        )}
                                    </div>
                                )}
                            </ModalContent>
                        </Modal>
                    )}
                </MainTabContentWrapper>
            )}
        </>
    );
};

export default AppraisalDetails;
