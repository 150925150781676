import React, { useEffect, useRef, useState } from "react";
import { ButtonCancel, ButtonConfirm } from "../../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import {
  Calendar,
  Col,
  Input,
  Popconfirm,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";
import { mainTabs } from "../../../../../config/testIds";
import styled from "styled-components";
import dekra from "../../../../../style/dekra";
import moment, { Moment } from "moment";
import { SelectStyled } from "../../../../../style/Input";
import { PracticeAppointment } from "../../../../../redux/apiSpecifications/apiCrud";
import { DateFormat } from "../../../../../config/const";
import { set } from "yaml/dist/schema/yaml-1.1/set";

const testIds = mainTabs.tabs.activities.scheduleAppointment;

const AppointmentRow = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  .already-scheduled-title {
    margin-bottom: 2em;
    .label {
      text-transform: uppercase;
      font-size: 1.2em;
    }

    .date {
      padding-left: 1em;
    }
  }
`;

const ScheduleAppointmentWrapper = styled.div<{ hasAppointment: boolean }>`
  display: grid;
  grid-template-areas:
    "calendar dayInteval"
    "calendar note"
    "actions  actions";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 3em auto 5em;

  .day-interval {
    grid-area: dayInteval;
    padding: 33px 0 2em 3em;

    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .note-input {
    flex: 1;
    margin-top: 1em;
  }

  .note {
    grid-area: note;
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    margin-top: 3em;
    margin-left: 3em;
  }

  .actions {
    grid-area: actions;
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .ant-picker-body {
    padding: 0 !important;
  }

  .ant-picker-content {
    th {
      padding: 0.25em;
      background-color: ${(props) =>
        props.hasAppointment ? "#ddd" : dekra.primaryColor};

      color: ${(props) => (props.hasAppointment ? "#333" : "#fff")};
      font-size: 0.8em;
    }
  }
`;

const CalendarWrapper = styled.div<{ isSelected: boolean }>`
  grid-area: calendar;

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      ${(props) =>
        props.isSelected
          ? ""
          : "background-color: transparent  !important; color: #333 !important;"}
    }
  }
`;

const CalendarHeader = styled.div<{ hasAppointment: boolean }>`
  background-color: ${(props) =>
    props.hasAppointment ? "#ddd" : dekra.primaryColor};

  color: ${(props) => (props.hasAppointment ? "#333" : "#fff")};

  padding: 0.5em 0.5em 0.25em 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 1.2em;
  letter-spacing: 1px;

  .ant-select-selector {
    background-color: ${(props) =>
      props.hasAppointment ? "#ddd" : dekra.primaryColor} !important;
    color: ${(props) => (props.hasAppointment ? "#333" : "#fff")};

    border: none !important;
  }

  .ant-select-arrow {
    display: none !important;
  }

  .years {
    font-size: 1em;
    text-align: right;
    margin-top: -5px;
  }

  .ant-select-selection-item {
    padding: 0 !important;
  }
`;

const AppointmentWrapper = styled.div``;

const defaultDate = moment().format(DateFormat);
const defaultAppointment: PracticeAppointment = {
  appointmentDate: defaultDate,
  appointmentTimeSlot: "1",
  notes: "",
  idPractice: 0,
};

interface IScheduleAppointmentProps {
  appointment?: PracticeAppointment;
  idPractice?: number;
  onSchedule: (appointment: PracticeAppointment) => Promise<void>;
  onModify?: (appointment: PracticeAppointment) => Promise<void>;
  onCancel?: () => void;
}

const isAppointmentDateMonth = (appointmentDate: string, calendarDate: Moment) => {
  const date = moment(appointmentDate, DateFormat);

  return date.year() === calendarDate.year() && date.month() === calendarDate.month();
};
const ScheduleAppointment = (props: IScheduleAppointmentProps) => {
  const { t } = useTranslation();

  const [appointment, setAppointment] = useState({
    ...(props.appointment || defaultAppointment),
    idPractice: props.idPractice,
  });

  const [calendarShowingDate, setCalendarShowingDate] = useState<
    Moment | undefined
  >(moment(appointment?.appointmentDate, DateFormat));

  const [isCalendarSelected, setIsCalendarSelected] = useState(
    !!appointment?.appointmentDate
  );

  const isPanelChanging = useRef(false);

  useEffect(() => {
    setAppointment({
      ...(props.appointment || defaultAppointment),
      idPractice: props.idPractice,
    });
  }, [props.appointment]);

  // onChangeValue must change only the appointmentDate day
  const onChangeValue = (val: any, field: string) => {
    if (field !== "appointmentDate") {
      const updatedAppointment = { ...appointment, [field]: val };
      setAppointment(updatedAppointment);
      return;
    }

    if (isPanelChanging.current) {
      isPanelChanging.current = false;
      return;
    }

    const updatedAppointment = { ...appointment, [field]: val };

    setAppointment(updatedAppointment);
    setIsCalendarSelected(true);
    setCalendarShowingDate(moment(val, DateFormat));
  };

  // handlePanelChange must change only the month or the year
  const handlePanelChange = (val: Moment) => {
    isPanelChanging.current = true;
    setCalendarShowingDate(val);
    setIsCalendarSelected(false);
    setIsCalendarSelected(isAppointmentDateMonth(appointment.appointmentDate!, val));
  };

  const clearAppointment = () => {
    setAppointment({
      appointmentDate: defaultDate,
      appointmentTimeSlot: "1",
      notes: "",
      idPractice: props.idPractice,
    });
  };

  const handleOnSchedule = () => {
    if (appointment) {
      props.onSchedule(appointment);
      clearAppointment();
    }
  };

  const handleOnModify = () => {
    if (props.onModify && appointment) {
      props.onModify(appointment);
      clearAppointment();
    }
  };

  const handleOnCancel = () => {
    if (props.onCancel) props.onCancel();
    clearAppointment();
  };

  const calendarHeaderRender = (params: any) => {
    const { value, onChange } = params || {};

    const month = value.month();
    const year = value.year();
    const currentYear = new Date().getFullYear();

    const monthOptions = Array.from(new Array(12).keys()).map((iMonth) => (
      <Select.Option key={iMonth} value={iMonth} className="month-item">
        {t(`month-${iMonth + 1}`)}
      </Select.Option>
    ));

    const yearOptions = (
      <>
        <Select.Option
          key={currentYear}
          value={currentYear}
          className="year-item"
        >
          {currentYear}
        </Select.Option>
        <Select.Option
          key={currentYear + 1}
          value={currentYear + 1}
          className="year-item"
        >
          {currentYear + 1}
        </Select.Option>
      </>
    );

    return (
      <CalendarHeader hasAppointment={hasAppointment}>
        <Select
          size="small"
          dropdownMatchSelectWidth={false}
          value={month}
          onChange={(newMonth) => {
            const now = value.clone().month(newMonth);
            onChange(now);
          }}
        >
          {monthOptions}
        </Select>
        <Select
          size="small"
          className="years"
          dropdownMatchSelectWidth={false}
          value={year}
          onChange={(newYear) => {
            const now = value.clone().year(newYear);
            onChange(now);
          }}
        >
          {yearOptions}
        </Select>
      </CalendarHeader>
    );
  };

  const hasAppointment =
    props.appointment !== undefined && props.appointment !== null;

  return (
    <AppointmentWrapper data-testid={testIds.container}>
      <AppointmentRow>
        <div className="appointment">
          {!hasAppointment && (
            <div className="already-scheduled-title">
              <div className="label">
                {t(
                  "appraisals-details-tab-activities-collapsable-schedule-appointment-label"
                )}
              </div>
            </div>
          )}
          {hasAppointment && (
            <div className="already-scheduled-title">
              <div className="label">
                {t(
                  "appraisals-details-tab-activities-collapsable-appointment-already-scheduled-label"
                )}
                :
              </div>
              <div className="date">
                {appointment.appointmentDate}
                {" - "}
                {appointment.appointmentTimeSlot === "1"
                  ? t(
                      "appraisals-details-tab-activities-collapsable-schedule-appoinment-morning-label"
                    )
                  : t(
                      "appraisals-details-tab-activities-collapsable-schedule-appoinment-afternoon-label"
                    )}
              </div>
            </div>
          )}
        </div>
      </AppointmentRow>

      <ScheduleAppointmentWrapper hasAppointment={hasAppointment}>
        <CalendarWrapper isSelected={isCalendarSelected}>
          <Calendar
            value={calendarShowingDate}
            fullscreen={false}
            onSelect={(val) =>
              onChangeValue(val.format(DateFormat), "appointmentDate")
            }
            onPanelChange={(val) => handlePanelChange(val)}
            headerRender={calendarHeaderRender}
          />
        </CalendarWrapper>

        <div className="day-interval">
          <SelectStyled
            value={appointment?.appointmentTimeSlot}
            onChange={(val) => onChangeValue(val, "appointmentTimeSlot")}
            options={[
              {
                value: "1",
                label: t(
                  "appraisals-details-tab-activities-collapsable-schedule-appoinment-morning-label"
                ),
              },
              {
                value: "2",
                label: t(
                  "appraisals-details-tab-activities-collapsable-schedule-appoinment-afternoon-label"
                ),
              },
            ]}
          />
        </div>

        <div className="note">
          <Input.TextArea
            maxLength={1000}
            value={appointment?.notes}
            className="note-input"
            onChange={(e) => onChangeValue(e.target.value, "notes")}
            placeholder={
              t(
                "appraisals-details-tab-activities-collapsable-confirm-out-of-zone-note-placeholder"
              )!
            }
          />
        </div>

        <div className="actions">
          {props.onCancel && (
            <ButtonCancel onClick={handleOnCancel}>
              {t("button-cancel")!}
            </ButtonCancel>
          )}

          {props.appointment && (
            <ButtonConfirm
              onClick={handleOnModify}
              disabled={
                !appointment?.appointmentDate ||
                !appointment?.appointmentTimeSlot
              }
              dataTestId={testIds.buttonModify}
            >
              {
                t(
                  "appraisals-details-tab-activities-collapsable-button-modify-appointment"
                )!
              }
            </ButtonConfirm>
          )}
          {!props.appointment && (
            <ButtonConfirm
              onClick={handleOnSchedule}
              disabled={
                !appointment?.appointmentDate ||
                !appointment?.appointmentTimeSlot
              }
              dataTestId={testIds.buttonSchedule}
            >
              {
                t(
                  "appraisals-details-tab-activities-collapsable-button-schedule-appointment"
                )!
              }
            </ButtonConfirm>
          )}
        </div>
        {/*  testing purpose only */}
        <div
          data-testid={testIds.testFunctionSetDate}
          style={{ display: "none" }}
          onClick={() =>
            onChangeValue(
              moment().add(1, "day").format(DateFormat),
              "appointmentDate"
            )
          }
        ></div>
        {/*  testing purpose only */}
        <div
          data-testid={testIds.testFunctionSchedule}
          style={{ display: "none" }}
          onClick={() =>
            props.onSchedule({
              appointmentDate: moment().add(1, "day").format(DateFormat),
              appointmentTimeSlot: "2",
              notes: "",
            })
          }
        ></div>
      </ScheduleAppointmentWrapper>
    </AppointmentWrapper>
  );
};

export default ScheduleAppointment;
