import { baseSplitApiFesf as api } from "./config/baseApiFesf";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMyUserData: build.query<GetMyUserDataApiResponse, GetMyUserDataApiArg>({
      query: (queryArg) => ({
        url: `/me`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    checkNewUsers: build.query<CheckNewUsersApiResponse, CheckNewUsersApiArg>({
      query: (queryArg) => ({
        url: `/users/new`,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    getUserConfigLists: build.query<
      GetUserConfigListsApiResponse,
      GetUserConfigListsApiArg
    >({
      query: (queryArg) => ({
        url: `/user-config-lists`,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    getAllUsers: build.query<GetAllUsersApiResponse, GetAllUsersApiArg>({
      query: (queryArg) => ({
        url: `/users`,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    toggleUserActivation: build.mutation<
      ToggleUserActivationApiResponse,
      ToggleUserActivationApiArg
    >({
      query: (queryArg) => ({
        url: `/users/toggle-activation/${queryArg.id}`,
        method: "PUT",
        body: queryArg.forcedActivationStatus,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    resetUserConfiguration: build.mutation<
      ResetUserConfigurationApiResponse,
      ResetUserConfigurationApiArg
    >({
      query: (queryArg) => ({
        url: `/users/configuration/${queryArg.id}`,
        method: "DELETE",
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    updateUserConfiguration: build.mutation<
      UpdateUserConfigurationApiResponse,
      UpdateUserConfigurationApiArg
    >({
      query: (queryArg) => ({
        url: `/users/configuration/${queryArg.id}`,
        method: "PUT",
        body: queryArg.userConfig,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}`,
        method: "PUT",
        body: queryArg.fullUser,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    assignCollaboratorToAppraisalStudio: build.mutation<
      AssignCollaboratorToAppraisalStudioApiResponse,
      AssignCollaboratorToAppraisalStudioApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}/collaborator`,
        method: "POST",
        headers: { Authorization: queryArg.authorization },
        params: { studioId: queryArg.studioId },
      }),
    }),
    getCollaboratorConfigList: build.query<
      GetCollaboratorConfigListApiResponse,
      GetCollaboratorConfigListApiArg
    >({
      query: (queryArg) => ({
        url: `/users/collaborator-config-list/`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAllTenants: build.query<GetAllTenantsApiResponse, GetAllTenantsApiArg>({
      query: (queryArg) => ({
        url: `/tenants`,
        headers: { Authorization: queryArg.authorization },
        params: { searchDate: queryArg.searchDate },
      }),
    }),
    getAllTenantRateConfigs: build.query<
      GetAllTenantRateConfigsApiResponse,
      GetAllTenantRateConfigsApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/tenants/rates`,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    getAllTenantFeeConfigs: build.query<
      GetAllTenantFeeConfigsApiResponse,
      GetAllTenantFeeConfigsApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/tenants/fees`,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    createTenantRateConfiguration: build.mutation<
      CreateTenantRateConfigurationApiResponse,
      CreateTenantRateConfigurationApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/tenants/rates/${queryArg.id}`,
        method: "POST",
        body: queryArg.tenantRatesByType,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    createTenantFeeConfiguration: build.mutation<
      CreateTenantFeeConfigurationApiResponse,
      CreateTenantFeeConfigurationApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/tenants/fees/${queryArg.id}`,
        method: "POST",
        body: queryArg.tenantFeesByType,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    getAllProfiles: build.query<
      GetAllProfilesApiResponse,
      GetAllProfilesApiArg
    >({
      query: (queryArg) => ({
        url: `/profiles`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getSupplierNetworkAdminPanel: build.query<
      GetSupplierNetworkAdminPanelApiResponse,
      GetSupplierNetworkAdminPanelApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/supplier-network`,
        headers: { Authorization: queryArg.authorization },
        params: {
          searchDate: queryArg.searchDate,
          searchDistrictCode: queryArg.searchDistrictCode,
        },
      }),
    }),
    createOrUpdateAppraisalStudioCollaborator: build.mutation<
      CreateOrUpdateAppraisalStudioCollaboratorApiResponse,
      CreateOrUpdateAppraisalStudioCollaboratorApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/supplier-network/${queryArg.id}/collaborators`,
        method: "POST",
        body: queryArg.supplierNetworkCollaboratorList,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAppraisalStudioCollaborators: build.query<
      GetAppraisalStudioCollaboratorsApiResponse,
      GetAppraisalStudioCollaboratorsApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/supplier-network/${queryArg.id}/collaborators`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    addAppraiser: build.mutation<AddAppraiserApiResponse, AddAppraiserApiArg>({
      query: (queryArg) => ({
        url: `/superuser/appraisers/`,
        method: "POST",
        body: queryArg.appraiserUser,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAppraiser: build.query<GetAppraiserApiResponse, GetAppraiserApiArg>({
      query: (queryArg) => ({
        url: `/superuser/appraisers/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAppraiserManagement: build.query<
      GetAppraiserManagementApiResponse,
      GetAppraiserManagementApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/appraiser-management/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
        params: { searchDate: queryArg.searchDate },
      }),
    }),
    getAllCoverageProvincesAndZones: build.query<
      GetAllCoverageProvincesAndZonesApiResponse,
      GetAllCoverageProvincesAndZonesApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/appraiser-management/provinces-zones/`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    setCoverageAreas: build.mutation<
      SetCoverageAreasApiResponse,
      SetCoverageAreasApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/appraiser-management/coverage/${queryArg.id}`,
        method: "POST",
        body: queryArg.coverage,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAllAppraiserStatuses: build.query<
      GetAllAppraiserStatusesApiResponse,
      GetAllAppraiserStatusesApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/appraiser-management/status/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
        params: { searchDate: queryArg.searchDate },
      }),
    }),
    setAppraiserStatus: build.mutation<
      SetAppraiserStatusApiResponse,
      SetAppraiserStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/appraiser-management/status/${queryArg.id}`,
        method: "POST",
        body: queryArg.districtLeave,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    saveAppraiserTenantsPermissions: build.mutation<
      SaveAppraiserTenantsPermissionsApiResponse,
      SaveAppraiserTenantsPermissionsApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/appraiser-management/tenants-permissions/${queryArg.id}`,
        method: "POST",
        body: queryArg.appraiserTenantsPermissions,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAppraiserRates: build.query<
      GetAppraiserRatesApiResponse,
      GetAppraiserRatesApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/appraiser-rates/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
        params: { searchDate: queryArg.searchDate },
      }),
    }),
    updateAppraiserRates: build.mutation<
      UpdateAppraiserRatesApiResponse,
      UpdateAppraiserRatesApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/appraiser-rates/${queryArg.id}`,
        method: "PUT",
        body: queryArg.appraiserRates,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getTenantRatesHistory: build.query<
      GetTenantRatesHistoryApiResponse,
      GetTenantRatesHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/tenants/rates/history/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
        params: {
          activeRole: queryArg.activeRole,
          validityDate: queryArg.validityDate,
        },
      }),
    }),
    getTenantFeesHistory: build.query<
      GetTenantFeesHistoryApiResponse,
      GetTenantFeesHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/tenants/fees/history/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    getAppraisersAgenda: build.query<
      GetAppraisersAgendaApiResponse,
      GetAppraisersAgendaApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/appraisers-agenda`,
        headers: { Authorization: queryArg.authorization },
        params: { month: queryArg.month },
      }),
    }),
    getDistrictAvailabilityInfo: build.query<
      GetDistrictAvailabilityInfoApiResponse,
      GetDistrictAvailabilityInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/appraisers-agenda/district-availability-info`,
        headers: { Authorization: queryArg.authorization },
        params: {
          searchDate: queryArg.searchDate,
          district: queryArg.district,
          availabilityType: queryArg.availabilityType,
        },
      }),
    }),
    getMyAgenda: build.query<GetMyAgendaApiResponse, GetMyAgendaApiArg>({
      query: (queryArg) => ({
        url: `/appraiser/my-agenda`,
        headers: { Authorization: queryArg.authorization },
        params: { month: queryArg.month, appraiserid: queryArg.appraiserid },
      }),
    }),
    setAppraiserStatusOnDistricts: build.mutation<
      SetAppraiserStatusOnDistrictsApiResponse,
      SetAppraiserStatusOnDistrictsApiArg
    >({
      query: (queryArg) => ({
        url: `/appraiser/my-agenda`,
        method: "POST",
        body: queryArg.districtAppraiserStatuses,
        headers: { Authorization: queryArg.authorization },
        params: { appraiserid: queryArg.appraiserid },
      }),
    }),
    getSupplierNetworkConfig: build.query<
      GetSupplierNetworkConfigApiResponse,
      GetSupplierNetworkConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/configuration`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    createSupplierNetworkConfig: build.mutation<
      CreateSupplierNetworkConfigApiResponse,
      CreateSupplierNetworkConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/superuser/configuration`,
        method: "POST",
        body: queryArg.supplierNetworkConfigPanel,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getG1PracticesSummary: build.query<
      GetG1PracticesSummaryApiResponse,
      GetG1PracticesSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/accounting/summary/G1/${queryArg.idTenant}`,
        headers: { Authorization: queryArg.authorization },
        params: { idReport: queryArg.idReport },
      }),
    }),
    excludePracticeFromReport: build.query<
      ExcludePracticeFromReportApiResponse,
      ExcludePracticeFromReportApiArg
    >({
      query: (queryArg) => ({
        url: `/accounting/G1/exclude-practice/${queryArg.practiceNumber}`,
        headers: { Authorization: queryArg.authorization },
        params: {
          tenant: queryArg.tenant,
          startDate: queryArg.startDate,
          endDate: queryArg.endDate,
        },
      }),
    }),
    getAccountingReportSet: build.query<
      GetAccountingReportSetApiResponse,
      GetAccountingReportSetApiArg
    >({
      query: (queryArg) => ({
        url: `/accounting/G1/report-set/${queryArg.idTenant}`,
        headers: { Authorization: queryArg.authorization },
        params: { startDate: queryArg.startDate, endDate: queryArg.endDate },
      }),
    }),
    getAccountingReportList: build.query<
      GetAccountingReportListApiResponse,
      GetAccountingReportListApiArg
    >({
      query: (queryArg) => ({
        url: `/accounting/reports`,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    createAccountingReport: build.mutation<
      CreateAccountingReportApiResponse,
      CreateAccountingReportApiArg
    >({
      query: (queryArg) => ({
        url: `/accounting/reports`,
        method: "POST",
        body: queryArg.reportMetadata,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAccountingReport: build.query<
      GetAccountingReportApiResponse,
      GetAccountingReportApiArg
    >({
      query: (queryArg) => ({
        url: `/accounting/reports/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
        params: { activeRole: queryArg.activeRole },
      }),
    }),
    closeAccountingReport: build.mutation<
      CloseAccountingReportApiResponse,
      CloseAccountingReportApiArg
    >({
      query: (queryArg) => ({
        url: `/accounting/reports/${queryArg.id}`,
        method: "PUT",
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getG2PracticesSummary: build.query<
      GetG2PracticesSummaryApiResponse,
      GetG2PracticesSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/accounting/summary/G2/${queryArg.idTenant}`,
        headers: { Authorization: queryArg.authorization },
        params: { idReport: queryArg.idReport },
      }),
    }),
    downloadCustomerDocument: build.query<
      DownloadCustomerDocumentApiResponse,
      DownloadCustomerDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/customer/documents/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    createTenantAppraisalExpirationDatesConfig: build.mutation<
      CreateTenantAppraisalExpirationDatesConfigApiResponse,
      CreateTenantAppraisalExpirationDatesConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/appraisal-expirationdates-config/`,
        method: "POST",
        body: queryArg.tenantAppraisalExpirationDatesConfig,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAppraisalExpirationDatesConfig: build.query<
      GetAppraisalExpirationDatesConfigApiResponse,
      GetAppraisalExpirationDatesConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/appraisal-expirationdates-config/`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAppraisalQrReports: build.query<
      GetAppraisalQrReportsApiResponse,
      GetAppraisalQrReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/quality-review-analyst/appraisals-report`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    createAppraisalQrReport: build.mutation<
      CreateAppraisalQrReportApiResponse,
      CreateAppraisalQrReportApiArg
    >({
      query: (queryArg) => ({
        url: `/quality-review-analyst/appraisals-report`,
        method: "POST",
        body: queryArg.appraisalQrReportRange,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    createOrUpdateClusters: build.mutation<
      CreateOrUpdateClustersApiResponse,
      CreateOrUpdateClustersApiArg
    >({
      query: (queryArg) => ({
        url: `/appraiser-score/clusters`,
        method: "POST",
        body: queryArg.appraiserScoreClusters,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    createOrUpdateClusterConfig: build.mutation<
      CreateOrUpdateClusterConfigApiResponse,
      CreateOrUpdateClusterConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/appraiser-score/clusters/${queryArg.id}/config`,
        method: "POST",
        body: queryArg.appraiserScoreClusterConfig,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAppraiserScoreDashboard: build.query<
      GetAppraiserScoreDashboardApiResponse,
      GetAppraiserScoreDashboardApiArg
    >({
      query: (queryArg) => ({
        url: `/appraiser-score/dashboard`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    getAppraiserScoreReport: build.query<
      GetAppraiserScoreReportApiResponse,
      GetAppraiserScoreReportApiArg
    >({
      query: (queryArg) => ({
        url: `/appraiser-score/reports/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiFesf };
export type GetMyUserDataApiResponse =
  /** status 200 A JSON representing the subject data */
    | User
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetMyUserDataApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
};
export type CheckNewUsersApiResponse =
  /** status 200 A JSON representing the subject data */
    | CountNewUsers
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type CheckNewUsersApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** user's current Role */
  activeRole: string;
};
export type GetUserConfigListsApiResponse =
  /** status 200 A JSON representing the subject data */
    | UserConfigLists
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetUserConfigListsApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** user's current Role */
  activeRole: string;
};
export type GetAllUsersApiResponse =
  /** status 200 A JSON representing the subject data */
    | Users
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetAllUsersApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** user's current Role */
  activeRole: string;
};
export type ToggleUserActivationApiResponse =
  /** status 200 A JSON representing the subject data */
    | User
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type ToggleUserActivationApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** user's current Role */
  activeRole: string;
  /** user's data and permissions to saved */
  forcedActivationStatus: ForcedActivationStatus;
};
export type ResetUserConfigurationApiResponse =
  /** status 201 The request was correct but the item does not exists */
    | ItemNotFound
    | /** status 204 The item is deleted */ ItemDeleted;
export type ResetUserConfigurationApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** user's current Role */
  activeRole: string;
};
export type UpdateUserConfigurationApiResponse =
  /** status 200 A JSON representing the subject data */
    | User
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type UpdateUserConfigurationApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** user's current Role */
  activeRole: string;
  /** user's data and permissions to saved */
  userConfig: UserConfig;
};
export type GetUserApiResponse =
  /** status 200 A JSON representing the subject data */
    | UserExtended
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetUserApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** user's current Role */
  activeRole: string;
};
export type UpdateUserApiResponse =
  /** status 200 A JSON representing the subject data */
    | User
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type UpdateUserApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** user's data and permissions to saved */
  fullUser: FullUser;
};
export type AssignCollaboratorToAppraisalStudioApiResponse =
  /** status 200 A JSON representing the subject data */
    | User
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type AssignCollaboratorToAppraisalStudioApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** Numeric Id of the resource */
  studioId: number;
};
export type GetCollaboratorConfigListApiResponse =
  /** status 200 A JSON representing the subject data */
    | BaseAppraiserItem[]
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetCollaboratorConfigListApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
};
export type GetAllTenantsApiResponse =
  /** status 200 a JSON containing the tenants' list data */
    | Tenants
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetAllTenantsApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Who Knows */
  searchDate?: string;
};
export type GetAllTenantRateConfigsApiResponse =
  /** status 200 a JSON containing all tenants current rate configuration */ AllTenantsCurrentRateConfig;
export type GetAllTenantRateConfigsApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** user's current Role */
  activeRole: string;
};
export type GetAllTenantFeeConfigsApiResponse =
  /** status 200 a JSON containing all tenants current rate configuration */ AllTenantsCurrentFeeConfig;
export type GetAllTenantFeeConfigsApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** user's current Role */
  activeRole: string;
};
export type CreateTenantRateConfigurationApiResponse =
  /** status 200 a JSON containing the tenants' list data */
    | TenantRatesByType
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type CreateTenantRateConfigurationApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** user's current Role */
  activeRole: string;
  tenantRatesByType: TenantRatesByType;
};
export type CreateTenantFeeConfigurationApiResponse =
  /** status 200 a JSON containing the tenants' list data */
    | TenantFeesByType
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type CreateTenantFeeConfigurationApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** user's current Role */
  activeRole: string;
  tenantFeesByType: TenantFeesByType;
};
export type GetAllProfilesApiResponse =
  /** status 200 a JSON containing the profiles' list data */
    | Profiles
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetAllProfilesApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
};
export type GetSupplierNetworkAdminPanelApiResponse =
  /** status 200 a JSON containing the profiles' list data */
    | SupplierNetworkAdminPanel
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetSupplierNetworkAdminPanelApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Who Knows */
  searchDate?: string;
  /** Who Knows */
  searchDistrictCode?: string;
};
export type CreateOrUpdateAppraisalStudioCollaboratorApiResponse =
  /** status 200 a JSON containing the profiles' list data */
    | SupplierNetworkCollaborator[]
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type CreateOrUpdateAppraisalStudioCollaboratorApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  supplierNetworkCollaboratorList: SupplierNetworkCollaboratorList;
};
export type GetAppraisalStudioCollaboratorsApiResponse =
  /** status 200 a JSON containing the profiles' list data */
    | SupplierNetworkCollaborator[]
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetAppraisalStudioCollaboratorsApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type AddAppraiserApiResponse =
  /** status 200 a JSON containing the profiles' list data */
    | AppraiserUser
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type AddAppraiserApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  appraiserUser: AppraiserUser;
};
export type GetAppraiserApiResponse =
  /** status 200 a JSON containing the profiles' list data */
    | AppraiserUser
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetAppraiserApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type GetAppraiserManagementApiResponse =
  /** status 200 a JSON representing appraiser management data */
    | AppraiserManagementData
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetAppraiserManagementApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** Who Knows */
  searchDate?: string;
};
export type GetAllCoverageProvincesAndZonesApiResponse =
  /** status 200 a JSON representing all coverage zones */
    | ProvincesAndZones
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetAllCoverageProvincesAndZonesApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
};
export type SetCoverageAreasApiResponse =
  /** status 200 A JSON containing coverage areas main info */
    | Coverage
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type SetCoverageAreasApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  coverage: Coverage;
};
export type GetAllAppraiserStatusesApiResponse =
  /** status 200 a JSON array containing all statuses of the appraiser for a given date */
    | AppraiserCoveragesByDistrict
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetAllAppraiserStatusesApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** Who Knows */
  searchDate?: string;
};
export type SetAppraiserStatusApiResponse =
  /** status 200 a JSON array containing all statuses of the appraiser for a given date */
    | AppraiserCoveragesByDistrict
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type SetAppraiserStatusApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  districtLeave: DistrictLeave;
};
export type SaveAppraiserTenantsPermissionsApiResponse =
  /** status 200 a JSON array containing the latest permission settings for all assigned tenants */
    | AppraiserTenantsPermissions
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type SaveAppraiserTenantsPermissionsApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  appraiserTenantsPermissions: AppraiserTenantsPermissions;
};
export type GetAppraiserRatesApiResponse =
  /** status 200 a JSON array representing appraiser fees info */
    | AppraiserRates
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetAppraiserRatesApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** Who Knows */
  searchDate?: string;
};
export type UpdateAppraiserRatesApiResponse =
  /** status 200 a JSON array representing appraiser fees info */
    | AppraiserRates
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type UpdateAppraiserRatesApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  appraiserRates: AppraiserRates;
};
export type GetTenantRatesHistoryApiResponse =
  /** status 200 a JSON array representing rates changes history */ TenantRatesByTypeHist;
export type GetTenantRatesHistoryApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** user's current Role */
  activeRole: string;
  /** Reference date for validity */
  validityDate?: string;
};
export type GetTenantFeesHistoryApiResponse =
  /** status 200 a JSON array representing rates changes history */ TenantFeesByTypeHist;
export type GetTenantFeesHistoryApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** user's current Role */
  activeRole: string;
};
export type GetAppraisersAgendaApiResponse =
  /** status 200 a JSON representing mothly appraisers availability agenda */
    | AppraisersMonthlyAgenda
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetAppraisersAgendaApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** month query filter */
  month: string;
};
export type GetDistrictAvailabilityInfoApiResponse =
  /** status 200 a JSON array representing appraisers  availability info filtered by district, date and availability class */
    | AppraisersDistrictAvailabilityInfo
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetDistrictAvailabilityInfoApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Who Knows */
  searchDate?: string;
  /** district query filter */
  district: string;
  /** district query filter */
  availabilityType: string;
};
export type GetMyAgendaApiResponse =
  /** status 200 a JSON array representing */
    | AppraiserAgenda
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetMyAgendaApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** month query filter */
  month: string;
  /** Numeric appraiser id */
  appraiserid: number;
};
export type SetAppraiserStatusOnDistrictsApiResponse =
  /** status 200 a JSON array containing all statuses of the appraiser for a given date */
    | AppraiserStatusesByDistrict
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type SetAppraiserStatusOnDistrictsApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric appraiser id */
  appraiserid: number;
  districtAppraiserStatuses: DistrictAppraiserStatuses;
};
export type GetSupplierNetworkConfigApiResponse =
  /** status 200 a JSON array representing the config params list */
    | SupplierNetworkConfigPanel
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetSupplierNetworkConfigApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
};
export type CreateSupplierNetworkConfigApiResponse =
  /** status 200 a JSON array representing the config params list */
    | SupplierNetworkConfigPanel
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type CreateSupplierNetworkConfigApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** a JSON array representing the config params list */
  supplierNetworkConfigPanel: SupplierNetworkConfigPanel;
};
export type GetG1PracticesSummaryApiResponse =
  /** status 200 a JSON representing accounting admministrator's dashboard data */
    | AccountingSummary
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetG1PracticesSummaryApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
  /** Numeric Id of the resource */
  idReport?: number;
};
export type ExcludePracticeFromReportApiResponse =
  /** status 200 practice id and company number */ ExcludedPractice;
export type ExcludePracticeFromReportApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** practice number code */
  practiceNumber: string;
  /** Numeric Id of the resource */
  tenant?: number;
  /** report start date */
  startDate: string;
  /** report end date */
  endDate: string;
};
export type GetAccountingReportSetApiResponse =
  /** status 200 a JSON representing report data */
    | AccountingReportSet
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetAccountingReportSetApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
  /** report start date */
  startDate: string;
  /** report end date */
  endDate: string;
};
export type GetAccountingReportListApiResponse =
  /** status 200 report's list */
    | AccountingReportList
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetAccountingReportListApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** user's current Role */
  activeRole: string;
};
export type CreateAccountingReportApiResponse = /** status 200 report's list */
  | AccountingReportList
  | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type CreateAccountingReportApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** request body */
  reportMetadata: ReportMetadata;
};
export type GetAccountingReportApiResponse =
  /** status 200 a JSON representing report data */
    | AttachmentFile
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetAccountingReportApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** user's current Role */
  activeRole: string;
};
export type CloseAccountingReportApiResponse = /** status 200 report's list */
  | AccountingReportList
  | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type CloseAccountingReportApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type GetG2PracticesSummaryApiResponse =
  /** status 200 a JSON representing accounting admministrator's dashboard data */
    | AccountingSummary
    | /** status 204 The request was correct but the item does not exists */ ItemNotFound;
export type GetG2PracticesSummaryApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  idTenant: number;
  /** Numeric Id of the resource */
  idReport?: number;
};
export type DownloadCustomerDocumentApiResponse =
  /** status 200 a JSON representing the document attached */ AttachmentFile;
export type DownloadCustomerDocumentApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type CreateTenantAppraisalExpirationDatesConfigApiResponse =
  /** status 200 a JSON representing the latest configuration saved for all tenant */ AppraisalExpirationDatesConfig;
export type CreateTenantAppraisalExpirationDatesConfigApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** a JSON representing an appraisal expiration dates config for a single tenant */
  tenantAppraisalExpirationDatesConfig: TenantAppraisalExpirationDatesConfig;
};
export type GetAppraisalExpirationDatesConfigApiResponse =
  /** status 200 a JSON representing the latest configuration saved for all tenant */ AppraisalExpirationDatesConfig;
export type GetAppraisalExpirationDatesConfigApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
};
export type GetAppraisalQrReportsApiResponse =
  /** status 200 a JSON containing quality review report metadata list */ QRAppraisalReports;
export type GetAppraisalQrReportsApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
};
export type CreateAppraisalQrReportApiResponse =
  /** status 200 a JSON containing quality review report metadata list */ QRAppraisalReports;
export type CreateAppraisalQrReportApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** JSON representing user's filters selection */
  appraisalQrReportRange: AppraisalQrReportRange;
};
export type CreateOrUpdateClustersApiResponse =
  /** status 200 a JSON containing quality review report metadata list */ AppraiserScoreDashboard;
export type CreateOrUpdateClustersApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** JSON representing clusters and their properties */
  appraiserScoreClusters: AppraiserScoreClusters;
};
export type CreateOrUpdateClusterConfigApiResponse =
  /** status 200 a JSON containing quality review report metadata list */ AppraiserScoreDashboard;
export type CreateOrUpdateClusterConfigApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** JSON representing cluster's config */
  appraiserScoreClusterConfig: AppraiserScoreClusterConfig;
};
export type GetAppraiserScoreDashboardApiResponse =
  /** status 200 a JSON containing quality review report metadata list */ AppraiserScoreDashboard;
export type GetAppraiserScoreDashboardApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
};
export type GetAppraiserScoreReportApiResponse =
  /** status 200 a JSON representing report data */ AttachmentFile;
export type GetAppraiserScoreReportApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type BaseRole = {
  id?: number;
  roleCode?: string;
  roleDescription?: string;
};
export type Roles = BaseRole[];
export type BaseTenant = {
  id?: number;
  companyName?: string;
  companyCode?: string;
};
export type TenantRateByType = {
  practiceTypeCode?: string;
  practiceTypeBaseRateAmount?: number;
  practiceTypePenaltyPerc?: number;
};
export type Tenant = BaseTenant & {
  appraisalRates?: TenantRateByType[];
};
export type Tenants = Tenant[] | null;
export type User = {
  id?: number;
  userName?: string;
  firstname?: string;
  surname?: string;
  jobTitle?: string;
  email?: string;
  loginCode?: string;
  mobilePhone?: string;
  officeLocation?: string;
  preferredLanguage?: string;
  adUserId?: string;
  businessPhone?: string;
  idSubject?: number;
  isActive?: boolean;
  isCollaborator?: boolean;
  appraisalStudioId?: number;
  appraisalStudio?: string;
  roles?: Roles;
  tenants?: Tenants;
};
export type ItemNotFound = {
  message?: string;
};
export type ResponseError = {
  errorCode?: string;
  errorMessage?: string;
};
export type CountNewUsers = number;
export type RoleType = {
  roleTypeCode?: string;
  roleTypeDescription?: string;
  isMultiTenant?: boolean;
  isMultiRole?: boolean;
  roles?: BaseRole[];
};
export type UserConfigLists = {
  roleTypes?: RoleType[];
  enabledTenants?: BaseTenant[];
};
export type Users = User[] | null;
export type ForcedActivationStatus = boolean;
export type ItemResponse = {
  id?: number;
  message?: string;
};
export type ItemDeleted = ItemResponse;
export type UserConfig = {
  enabledTenants?: BaseTenant[];
  roles?: BaseRole[];
};
export type UserExtended = User & {
  isDeleted?: boolean;
  dateInsert?: string;
  dateModify?: string;
};
export type Contact = {
  id?: number;
  type?: string;
  value?: string;
  description?: string;
  useCase?: string;
  preferred?: boolean;
  isDeleted?: boolean;
};
export type SubjectBase = {
  subjectType?: string;
  name?: string;
  lastname?: string;
  fiscalCode?: string;
  contacts?: Contact[] | null;
};
export type FullUser = UserExtended & SubjectBase;
export type BaseAppraiserItem = {
  id?: number;
  appraiserName?: string;
};
export type TenantRatesByType = {
  tenantId?: number;
  tenantName?: string;
  tenantRateConfig?: TenantRateByType[];
};
export type AllTenantsCurrentRateConfig = TenantRatesByType[];
export type TenantFeeByType = {
  feeType?: string;
  feeAmount?: number;
};
export type TenantFeesByType = {
  tenantId?: number;
  tenantName?: string;
  tenantFeeConfig?: TenantFeeByType[];
};
export type AllTenantsCurrentFeeConfig = TenantFeesByType[];
export type Profile = {
  id?: number;
  profileCode?: string;
};
export type Profiles = Profile[] | null;
export type AppraisersListItem = BaseAppraiserItem & {
  isInternal?: boolean;
  appraiserContact?: string;
  practiceCountDaily?: number;
  maxPracticeCountDaily?: number;
  practiceCountLeftMonthly?: number;
  maxPracticeCountMonthly?: number;
  province?: string;
  latitude?: number;
  longitude?: number;
  totalCoverage?: boolean;
  status?: string;
};
export type TotalByCategory = {
  category?: string;
  total?: number;
};
export type UserRequestsSummary = {
  senderType?: string;
  recipientType?: string;
  totalsByCategory?: TotalByCategory[];
};
export type TotalByStatus = {
  id?: number;
  statusCode?: string;
  totalByStatusValue?: number;
};
export type CommunicationCategory = {
  id?: number;
  categoryCode?: string;
  totalByCategory?: number;
  totalsByCategoryAndStatus?: TotalByStatus[];
};
export type CommunicationsBySenderTypeAndCategories = {
  senderType?: string;
  communicationsByCategory?: CommunicationCategory[];
};
export type SupplierNetworkAdminPanel = {
  appraisersList?: AppraisersListItem[];
  userRequestsSummary?: UserRequestsSummary[];
  communicationSummary?: CommunicationsBySenderTypeAndCategories[];
};
export type SupplierNetworkCollaborator = {
  id?: number;
  denomination?: string;
  dekraAccountNumber?: string;
  membershipNumber?: string;
};
export type SupplierNetworkCollaboratorList = {
  collaborators?: SupplierNetworkCollaborator[];
};
export type BirthDateAndPlace = {
  date?: string;
  city?: string;
  province?: string;
  country?: string;
};
export type Address = {
  id?: number;
  type?: string;
  street?: string;
  civic?: string;
  postalCode?: string;
  city?: string;
  province?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  preferred?: boolean;
  isDeleted?: boolean;
};
export type SubjectDocument = {
  id?: number;
  documentCode?: string;
  type?: string;
  number?: string;
  issuingDate?: string;
  expirationDate?: string;
  issuingInstitution?: string;
};
export type SubjectPayment = {
  id?: number;
  type?: string;
  iban?: string | null;
  bicSwift?: string | null;
  addressSendCheck?: Address | null;
  preferred?: boolean;
};
export type Subject = {
  id?: number;
  subjectType?: string;
  name?: string;
  lastname?: string;
  gender?: string;
  fiscalCode?: string;
  birth?: BirthDateAndPlace | null;
  businessName?: string;
  giuridicalType?: string;
  registeredOffice?: Address | null;
  pIva?: string;
  contacts?: Contact[] | null;
  addresses?: Address[] | null;
  documents?: SubjectDocument[] | null;
  payments?: SubjectPayment[] | null;
};
export type ManagementTool = {
  id?: number;
  description?: string;
};
export type TrustCompany = {
  id?: number;
  description?: string;
};
export type Appraiser = {
  id?: number;
  idTenant?: number;
  appraiserStatus?: string;
  practiceCountDaily?: number;
  maxPracticeCountDaily?: number;
  practiceCountMonthly?: number;
  maxPracticeCountMonthly?: number;
  automaticClosing?: boolean;
  appraisalType?: string;
  isInternal?: boolean;
  registrationCode?: string;
  sapCode?: string;
  iban?: string | null;
  managementTools?: ManagementTool[];
  trustCompanies?: TrustCompany[];
};
export type AppraiserUser = Subject &
  Appraiser & {
    idAppraiser?: number;
    idUser?: number;
    availableManagementTools?: ManagementTool[];
    availableTrustCompanies?: TrustCompany[];
  };
export type AppraiserTenantsPermissionsItem = {
  tenantId?: number;
  tenantDescription?: string;
  appraiserTenantPermissionCode?: string;
  dateModify?: string;
};
export type AppraiserTenantsPermissions = {
  tenantsPermissions?: AppraiserTenantsPermissionsItem[];
};
export type DistrictBaseCoverage = {
  districtCode?: string;
  districtName?: string;
  districtLatitude?: number;
  districtLongitude?: number;
  validityStartDate?: string;
  validityEndDate?: string;
  isTest?: boolean;
};
export type AreaCoverage = {
  id?: number;
  city?: string;
  zipCode?: string;
  areaMaxPracticeCountDaily?: number;
  areaMaxPracticeCountMonthly?: number;
};
export type AreaCoverages = AreaCoverage[];
export type DistrictCoverage = DistrictBaseCoverage & {
  districtMaxPracticeCountDaily?: number;
  districtMaxPracticeCountMonthly?: number;
  hasAreaCoverageParams?: boolean;
  isTest?: boolean;
  areaCoverages?: AreaCoverages;
};
export type Coverage = {
  coverageLeaveLossPerc?: number;
  coverageTypeCodes?: string[];
  districtCoverages?: DistrictCoverage[];
};
export type AppraiserManagementData = {
  coverageLeaveLossPerc?: number;
  appraiserRoleType?: string;
  appraiserBusinessName?: string;
  customersSettings?: AppraiserTenantsPermissions | null;
  coverage?: Coverage;
};
export type Zone = {
  id?: number;
  provinceCode?: string;
  provinceName?: string;
  locationName?: string;
  zipCode?: string;
};
export type Province = {
  id?: number;
  districtCode?: string;
  districtName?: string;
  districtLatitude?: number;
  districtLongitude?: number;
};
export type ProvincesAndZones = {
  zones?: Zone[];
  provinces?: Province[];
};
export type DistrictPractices = {
  managedPractices?: number;
  manageblePractices?: number;
  remaningPractices?: number;
};
export type AppraiserDistrictConfig = {
  districtBaseCoverage?: DistrictBaseCoverage;
  districtStatus?: string;
  districtPractices?: DistrictPractices;
};
export type AppraiserCoveragesByDistrict = {
  appraiserDistrictConfigs?: AppraiserDistrictConfig[];
};
export type DistrictLeave = {
  districtCode?: string;
  startDate?: string;
  endDate?: string;
  leaveType?: string;
  leaveReason?: string;
  leaveNote?: string;
  action?: string;
};
export type RateByType = TenantRateByType & {
  practiceTypeCashPerc?: number;
};
export type AppraiserRate = {
  idAppraiser?: number;
  tenantId?: number;
  tenantName?: string;
  ratesByType?: RateByType[];
};
export type AppraiserRates = {
  appraiserRates?: AppraiserRate[];
};
export type TenantRatesByTypeHistItem = {
  id?: number;
  rateTypeCode?: string;
  rateTypeBaseRateAmount?: number;
  rateTypePenaltyPerc?: number;
  validityStartDate?: string;
  validityEndDate?: string;
  modifyUser?: string;
  insertDateTime?: string;
  modifyDateTime?: string;
};
export type TenantRatesByTypeHist = TenantRatesByTypeHistItem[];
export type TenantFeesByTypeHistItem = TenantFeesByType & {
  modifyUsername?: string;
  validityStartDate?: string;
  validityEndDate?: string;
};
export type TenantFeesByTypeHist = TenantFeesByTypeHistItem[];
export type DistrictAvailability = {
  districtCode?: string;
  districtName?: string;
  latitude?: number;
  longitude?: number;
  availabilityType?: string;
  availabilityPercentage?: number;
};
export type AppraisersDailyAgenda = {
  date?: string;
  districts?: DistrictAvailability[];
};
export type AppraisersMonthlyAgenda = {
  month?: string;
  days?: AppraisersDailyAgenda[];
};
export type AppraiserAvailabilityInfo = {
  appraiserId?: number;
  appraiserBusinessName?: string;
  appraiserRoleType?: string;
  appraiserPreferredContact?: string;
  leaveType?: string;
  telephone?: string;
  status?: string;
};
export type AppraisersDistrictAvailabilityInfo = {
  districtAvaliabilityInfo?: AppraiserAvailabilityInfo[];
};
export type District = {
  districtName?: string;
  districtCode?: string;
};
export type DistrictReason = District & {
  reasonCode?: string;
};
export type Appointment = {
  practiceId?: number;
  practiceNumber?: string;
  appointmentDistrict?: string;
  appointmentTimeSlot?: string;
  appointmentAddress?: string;
};
export type DailyAgenda = {
  date?: string;
  onSuspendedDistricts?: DistrictReason[];
  onBlockedDistricts?: DistrictReason[];
  onClosedDistricts?: District[];
  onIllnessDistricts?: District[];
  onLeaveDistricts?: District[];
  onPatronSaintDistricts?: District[];
  appointments?: Appointment[];
};
export type AppraiserAgenda = {
  daysBeforeLeaveRequest?: number;
  districts?: District[];
  days?: DailyAgenda[];
};
export type AppraiserStatusesByDistrict = {
  statusesByDistrict?: DistrictBaseCoverage[];
};
export type DistrictAppraiserStatus = {
  districtCode?: string;
  validityStartDate?: string;
  validityEndDate?: string;
  suspensionReason?: string;
  appraiserStatusCode?: string;
  description?: string;
};
export type DistrictAppraiserStatuses = {
  originalDistrictAppraiserStatuses?: DistrictAppraiserStatus[];
  newDistrictAppraiserStatuses?: DistrictAppraiserStatus[];
};
export type AvailabilityRange = {
  availabilityRangeType?: string;
  rangeFrom?: number;
  rangeTo?: number;
};
export type SupplierNetworkConfigPanel = {
  availabilityRangeConfig?: AvailabilityRange[];
  leaveforewarning?: number;
  leavethreshold?: number;
  smallstudiesthreshold?: number;
  assignmentdecreasestartdays?: number;
  assignmentstopstartdays?: number;
  assignmentpercentagedecrease?: number;
};
export type AccountingReport = {
  id?: number;
  tenant?: string;
  name?: string;
  startDate?: string;
  endDate?: string;
  isSimulated?: boolean;
  reportStatus?: string;
  generationStatus?: string;
  creationDate?: string;
};
export type G1SummaryPractice = {
  id?: number;
  reportName?: string;
  brokeredCompany?: string;
  practiceNumber?: string;
  practiceNumberCompany?: string;
  claimNumber?: string;
  plateNumber?: string;
  originalFeeTypeCode?: string;
  feeTypeCode?: string;
  appraisalTypeCode?: string;
  practiceDate?: string;
  conclusionDate?: string;
  practiceRateAmount?: number;
  practiceFeeAmount?: number;
  isAccountingVerify?: boolean;
  inAuditingDate?: string;
};
export type AccountingSummary = {
  managedTotal?: number;
  toBeAccountedTotal?: number;
  reportList?: AccountingReport[];
  practiceList?: G1SummaryPractice[];
};
export type ExcludedPractice = {
  id?: number;
  practiceNumber?: string;
};
export type InvoicedPractice = {
  id?: number;
  reportName?: string;
  practiceNumber?: string;
  originalFee?: number;
  latestFee?: number;
  delta?: number;
};
export type AccountingReportSet = {
  totalPractices?: number;
  totalDelta?: number;
  invoicedPractices?: InvoicedPractice[];
};
export type AccountingReportList = AccountingReport[];
export type ReportMetadata = {
  idTenant?: number;
  name?: string;
  startDate?: string;
  endDate?: string;
  isSimulated?: boolean;
  exclusions?: number[];
};
export type AttachmentFile = {
  id?: number;
  name?: string;
  originalName?: string;
  documentBasePath?: string;
  base64File?: string;
};
export type TenantAppraisalExpirationDatesConfig = {
  id?: number;
  idcompany?: number;
  expirationdateclientdocumental?: number;
  expirationdateappraiserdocumental?: number;
  expirationdateclienttransformeddocumental?: number;
  expirationdateappraisertransformeddocumental?: number;
  expirationdateappraisertransformeddocumentalcheck?: number;
  expirationdateclientappraisalcvt?: number;
  expirationdateappraiserappraisalcvt?: number;
  expirationdateclientappraisalrca1?: number;
  expirationdateappraiserappraisalrca1?: number;
  expirationdateclientappraisalrca2?: number;
  expirationdateappraiserappraisalrca2?: number;
  expirationdateclientappraisalrca0?: number;
  expirationdateappraiserappraisalrca0?: number;
  expirationdateclientfeedbackrca1?: number;
  expirationdateappraiserfeedbackrca1?: number;
  expirationdateclientfeedbackrca2?: number;
  expirationdateappraiserfeedbackrca2?: number;
  expirationdateclientlocationstatus?: number;
  expirationdateappraiserlocationstatus?: number;
  expirationdateclientcomplaintrecovery?: number;
  expirationdateappraisercomplaintrecovery?: number;
  expirationdateclientcongruityrequest?: number;
  expirationdateappraisercongruityrequest?: number;
  expirationdateclientctp?: number;
  expirationdateappraiserctp?: number;
  expirationdateclientcinematic?: number;
  expirationdateappraisercinematic?: number;
  expirationdateclientcomparative?: number;
  expirationdateappraisercomparative?: number;
  expirationdateclientre?: number;
  expirationdateappraiserre?: number;
  expirationdateivassrca1?: number;
  expirationdateivassrca2?: number;
  expirationdateivassrca0?: number;
};
export type AppraisalExpirationDatesConfig =
  TenantAppraisalExpirationDatesConfig[];
export type QRAppraisalReport = {
  id?: number;
  name?: string;
  rangeType?: string;
  startDate?: string;
  endDate?: string;
  generationStatus?: string;
  documentCode?: string;
  creationDate?: string;
};
export type QRAppraisalReports = QRAppraisalReport[];
export type AppraisalQrReportRange = {
  rangeType?: string;
  startDate?: string;
  endDate?: string;
};
export type AppraiserScoreCluster = {
  id?: number;
  clusterName?: string;
  districts?: number[];
};
export type AppraiserScoreTenantConfig = {
  id?: number;
  tenantId?: number;
  clusterId?: number;
  singleSignatureCountThreshold?: number;
  doubleSignatureCountThreshold?: number;
  noCardCvtCountThreshold?: number;
};
export type AppraiserScoreClusterConfig = {
  id?: number;
  clusterId?: number;
  appraisalTimeThreshold?: number;
  appraisalTimeWeight?: number;
  confirmationReactivityThreshold?: number;
  confirmationReactivityWeight?: number;
  visionReactivityThreshold?: number;
  visionReactivityWeight?: number;
  meanAppraisalAmountWeight?: number;
  accessesCountThreshold?: number;
  accessesCountWeight?: number;
  noAccessesCountThreshold?: number;
  noAccessesCountWeight?: number;
  agreementsCountThreshold?: number;
  agreementsCountWeight?: number;
  rejectedCountThreshold?: number;
  rejectedCountWeight?: number;
  accountingVerifyCountThreshold?: number;
  accountingVerifyCountWeight?: number;
  appraiserScoreTenantConfigs?: AppraiserScoreTenantConfig[];
};
export type AppraiserScoreReport = {
  reportId?: number;
  reportTypeCode?: string;
  startDate?: string;
  endDate?: string;
  creationDate?: string;
  generationStatus?: string;
};
export type AppraiserScoreDashboard = {
  appraiserScoreClusterList?: AppraiserScoreCluster[];
  appraiserScoreClusterConfigList?: AppraiserScoreClusterConfig[];
  appraiserScoreReportList?: AppraiserScoreReport[];
};
export type AppraiserScoreClusters = {
  clusters?: AppraiserScoreCluster[];
};
export const {
  useGetMyUserDataQuery,
  useLazyGetMyUserDataQuery,
  useCheckNewUsersQuery,
  useLazyCheckNewUsersQuery,
  useGetUserConfigListsQuery,
  useLazyGetUserConfigListsQuery,
  useGetAllUsersQuery,
  useLazyGetAllUsersQuery,
  useToggleUserActivationMutation,
  useResetUserConfigurationMutation,
  useUpdateUserConfigurationMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useAssignCollaboratorToAppraisalStudioMutation,
  useGetCollaboratorConfigListQuery,
  useLazyGetCollaboratorConfigListQuery,
  useGetAllTenantsQuery,
  useLazyGetAllTenantsQuery,
  useGetAllTenantRateConfigsQuery,
  useLazyGetAllTenantRateConfigsQuery,
  useGetAllTenantFeeConfigsQuery,
  useLazyGetAllTenantFeeConfigsQuery,
  useCreateTenantRateConfigurationMutation,
  useCreateTenantFeeConfigurationMutation,
  useGetAllProfilesQuery,
  useLazyGetAllProfilesQuery,
  useGetSupplierNetworkAdminPanelQuery,
  useLazyGetSupplierNetworkAdminPanelQuery,
  useCreateOrUpdateAppraisalStudioCollaboratorMutation,
  useGetAppraisalStudioCollaboratorsQuery,
  useLazyGetAppraisalStudioCollaboratorsQuery,
  useAddAppraiserMutation,
  useGetAppraiserQuery,
  useLazyGetAppraiserQuery,
  useGetAppraiserManagementQuery,
  useLazyGetAppraiserManagementQuery,
  useGetAllCoverageProvincesAndZonesQuery,
  useLazyGetAllCoverageProvincesAndZonesQuery,
  useSetCoverageAreasMutation,
  useGetAllAppraiserStatusesQuery,
  useLazyGetAllAppraiserStatusesQuery,
  useSetAppraiserStatusMutation,
  useSaveAppraiserTenantsPermissionsMutation,
  useGetAppraiserRatesQuery,
  useLazyGetAppraiserRatesQuery,
  useUpdateAppraiserRatesMutation,
  useGetTenantRatesHistoryQuery,
  useLazyGetTenantRatesHistoryQuery,
  useGetTenantFeesHistoryQuery,
  useLazyGetTenantFeesHistoryQuery,
  useGetAppraisersAgendaQuery,
  useLazyGetAppraisersAgendaQuery,
  useGetDistrictAvailabilityInfoQuery,
  useLazyGetDistrictAvailabilityInfoQuery,
  useGetMyAgendaQuery,
  useLazyGetMyAgendaQuery,
  useSetAppraiserStatusOnDistrictsMutation,
  useGetSupplierNetworkConfigQuery,
  useLazyGetSupplierNetworkConfigQuery,
  useCreateSupplierNetworkConfigMutation,
  useGetG1PracticesSummaryQuery,
  useLazyGetG1PracticesSummaryQuery,
  useExcludePracticeFromReportQuery,
  useLazyExcludePracticeFromReportQuery,
  useGetAccountingReportSetQuery,
  useLazyGetAccountingReportSetQuery,
  useGetAccountingReportListQuery,
  useLazyGetAccountingReportListQuery,
  useCreateAccountingReportMutation,
  useGetAccountingReportQuery,
  useLazyGetAccountingReportQuery,
  useCloseAccountingReportMutation,
  useGetG2PracticesSummaryQuery,
  useLazyGetG2PracticesSummaryQuery,
  useDownloadCustomerDocumentQuery,
  useLazyDownloadCustomerDocumentQuery,
  useCreateTenantAppraisalExpirationDatesConfigMutation,
  useGetAppraisalExpirationDatesConfigQuery,
  useLazyGetAppraisalExpirationDatesConfigQuery,
  useGetAppraisalQrReportsQuery,
  useLazyGetAppraisalQrReportsQuery,
  useCreateAppraisalQrReportMutation,
  useCreateOrUpdateClustersMutation,
  useCreateOrUpdateClusterConfigMutation,
  useGetAppraiserScoreDashboardQuery,
  useLazyGetAppraiserScoreDashboardQuery,
  useGetAppraiserScoreReportQuery,
  useLazyGetAppraiserScoreReportQuery,
} = injectedRtkApi;
