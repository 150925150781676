import { useTranslation } from "react-i18next";
import { InputTextStyled, SelectStyled } from "../../../../../../style/Input";
import { RowSpacer } from "../../../../../../style/containers";
import { FormRow } from "../../../../../../style/form";
import { useMemo } from "react";
import { Input } from "antd";
import { NewRequestFormModel } from "./NewRequestFormModel";
import Attachments from "../../../../../Inputs/Attachments";
import { SelectPair } from "../../../../../../types/common.types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";

const NewRequestFormWrapper = styled.div`
    div.message-text {
        height: 200px;
        min-height: 50%;
        margin: 5px auto;
        text-align: left;
        position: relative;
        display: flex;
        flex-direction: column;

        textarea.message-text {
            flex-grow: 1;
            width: 100%;
            box-sizing: border-box;
            margin: 5px auto;
            padding: 5px;
            text-align: left;
            position: relative;
        }
    }
`;

interface INewRequestFormProps {
    model: NewRequestFormModel;
    toList: number[];
    onChange: (value: any, field: string) => void;
}

const NewRequestForm = ({ model, toList, onChange }: INewRequestFormProps) => {
    const { t } = useTranslation();

    const { activeRole } = useSelector((state: RootState) => state.user);

    const toOptions = useMemo(() => {
        const result: SelectPair[] = [];

        if (toList.length === 1)
            result.push({
                value: toList[0].toString(),
                label: t(
                    `practice-request-user-profile-description-${toList[0]}`
                ),
            });
        else {
            result.push({
                value: "",
                label: "",
            });

            toList!.forEach((to) => {
                result.push({
                    value: to.toString(),
                    label: t(`practice-request-user-profile-description-${to}`),
                });
            });
        }

        return result;
    }, [toList]);

    const priorityOptions = useMemo(() => {
        let result: SelectPair[] = [
            {
                value: "NU",
                label: t("practice-request-priority-description-NU"),
            },
        ];

        if (!["E2", "E3"].includes(activeRole!)) {
            result.push({
                value: "U",
                label: t("practice-request-priority-description-U"),
            });
        }

        return result;
    }, []);

    const handleAddDocument = () => {
        // dispatch(addDocument());
        // dispatch(forceValidation());
        // dispatch(forceSerialize());
    };

    return (
        <NewRequestFormWrapper>
            <FormRow>
                <SelectStyled
                    label={t("new-request-form-to-label")}
                    onChange={(to) => onChange(to, "to")}
                    value={model.to}
                    options={toOptions}
                />
                <RowSpacer />
                <SelectStyled
                    label={t("new-request-form-priority-label")}
                    onChange={(priority) => onChange(priority, "priority")}
                    value={model.priority}
                    options={priorityOptions}
                />
            </FormRow>

            <FormRow>
                <InputTextStyled
                    label={`${t("new-request-form-object-label")} *`}
                    // placeholder={t("edit-subject-tab-data-name-placeholder")}
                    onChange={(object) => onChange(object, "object")}
                    value={model.object}
                />
            </FormRow>

            <FormRow className="message-text">
                <Input.TextArea
                    // maxLength={1000}
                    onChange={(e) => onChange(e.target.value, "text")}
                    value={model.text ?? ""}
                    className="message-text"
                    // onChange={(e) => onChangeValue(e.target.value, "notes")}
                    placeholder={
                        t(
                            "appraisals-details-tab-requests-new-request-text-placeholder"
                        )!
                    }
                />
            </FormRow>

            {/* <pre>{JSON.stringify(activeRole, null, 2)}</pre> */}

            {/* <FormRow>
                <Attachments
                    // attachments={additionalInfo.documentAttachments?.map(
                    //     (a) => ({
                    //         id: a.id,
                    //         filename: a.name || "",
                    //         base64: "",
                    //         documentCode: "",
                    //     })
                    // )}
                    docIndex={0}
                    documentCode={""}
                    onAddAttachment={() => {}}
                    onRemoveAttachment={() => {}}
                />
            </FormRow> */}
        </NewRequestFormWrapper>
    );
};

export default NewRequestForm;
