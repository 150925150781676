import { useTranslation } from "react-i18next";
import {
  AppraiserRate,
  useLazyGetAppraiserRatesQuery,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { ContentWrapper, GenericWrapper } from "../../Commons/Wrappers";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useEffect, useState } from "react";
import { ChangeValueType, RatesHeader } from "../../RatesClients/RatesClients";
import { InputTextStyled } from "../../../../../style/Input";
import TenantRates from "./TenantRates";
import { Pagination } from "antd";
import styled from "styled-components";
import { DateFormat } from "../../../../../config/const";
import moment from "moment";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import {
  AppraiserRateData,
  setAppraiserError,
  setAppraiserRatesData,
  setAppraiserStatus,
  setValidation,
} from "../../../../../redux/features/appraisersSlice";

const RatesClientsContainer = styled(ContentWrapper)`
  overflow: auto;
  max-height: calc(100vh - 16em);
  padding-right: 2em;
`;

const RatesClientsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const RatesClientsContent = styled(GenericWrapper)`
  display: flex;
  width: auto;
  flex-direction: column;
  margin: 1em;
  align-items: flex-start;
  overflow-x: auto;
  min-width: 600px;

  .tenants-wrapper {
    margin: 2em 0;
    display: flex;
    flex-direction: column;
    gap: 3em;
  }

  .header-wrapper {
    display: flex;
    flex-direction: row;

    .filter-wrapper {
      input {
        width: 15em;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10em;
    align-self: center;
    flex: 1;

    .pagination-wrapper {
      flex: 1;
      display: flex;
      margin-bottom: 2em;
    }
  }
`;

/**
 * @description Props pf AppraiserDetailsRates
 * @idAppraiser id of the appraiser
 */
interface IAppraiserDetailsRatesProps {
  idAppraiser: number;
  forceReload?: number;
}

/**
 * @description Tab content of the rates of the appraiser
 */
const AppraiserDetailsRates = ({
  idAppraiser,
  forceReload,
}: IAppraiserDetailsRatesProps) => {
  // Common variables
  const { t } = useTranslation();

  const { getAuthorization } = useAuthorization();
  const dispatch = useDispatch();

  const appraiserData = useSelector((state: RootState) =>
    state.appraisers.appraisers.find((a) => a.id === idAppraiser)
  );

  const { ratesData: appraiserRates } = appraiserData || {};

  //Local state
  const [ratesCode, setRatesCode] = useState<string[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [filteredAppraiserRates, setFilteredAppraiserRates] =
    useState<AppraiserRateData[]>();
  const [page, setPage] = useState<number>(1);

  const [getAppraiserRates] = useLazyGetAppraiserRatesQuery();

  // Use Effects
  useEffect(() => {
    if (!idAppraiser) return;
    (async () => {
      dispatch(
        setAppraiserStatus({
          id: appraiserData?.id!,
          status: "loading",
        })
      );

      const result = await getAppraiserRates({
        authorization: await getAuthorization(),
        id: idAppraiser,
        searchDate: moment().format(DateFormat),
      });

      if (result.data) {
        const newAppraiserRates: AppraiserRateData[] = (
          result.data as AppraiserRate[]
        ).map((appraiserData) => ({
          appraiserData,
          modified: false,
        }));

        const _data = result.data as AppraiserRate[];
        if (!_data) return;

        const newRatesCode: string[] = Array.from(
          new Set(
            _data
              .map((item) =>
                item.ratesByType?.map((rate) => rate.practiceTypeCode)
              )
              .flat()
              .filter((code) => code !== undefined) as string[]
          )
        );

        setRatesCode(newRatesCode);
        dispatch(
          setAppraiserRatesData({
            id: appraiserData?.id!,
            ratesData: newAppraiserRates,
            isUpdate: false,
          })
        );
      } else {
        dispatch(
          setAppraiserError({
            id: idAppraiser || 0,
            message: "Errore caricamento tariffe",
          })
        );
      }
    })();
  }, [idAppraiser, forceReload]);

  useEffect(() => {
    if (!appraiserRates) return;
    const lcFilter = filter.toLowerCase();
    const ftr = appraiserRates.filter((ar) =>
      filter === ""
        ? true
        : ar.appraiserData.tenantName?.toLowerCase().includes(lcFilter)
    );

    setFilteredAppraiserRates(ftr);
  }, [filter, appraiserRates]);

  useEffect(() => {
    validateData();
  }, [appraiserRates]);

  const handleOnChange = (
    tenantId: number | undefined,
    rateCode: string,
    value: string | number | undefined | null,
    type: ChangeValueType
  ) => {
    if (!tenantId) return;

    const updatedAppraiserRates = appraiserRates?.map((ar) => {
      if (ar.appraiserData.tenantId !== tenantId) return ar;

      return {
        modified: true,
        appraiserData: {
          tenantId: ar.appraiserData.tenantId,
          tenantName: ar.appraiserData.tenantName,
          ratesByType: ar.appraiserData.ratesByType?.map((rate) => {
            if (rate.practiceTypeCode !== rateCode) return rate;

            if (type === "rate-base") {
              let newPercPenalty =
                value === null ? null : rate.practiceTypePenaltyPerc;
              let newPercCash =
                value === null ? null : rate.practiceTypeCashPerc;

              if (rate.practiceTypePenaltyPerc === null && value !== null) {
                newPercPenalty = 0;
                newPercCash = 0;
              }

              return {
                ...rate,
                practiceTypeBaseRateAmount: value,
                practiceTypePenaltyPerc: newPercPenalty,
                practiceTypeCashPerc: newPercCash,
              };
            }

            if (type === "penalty-perc")
              return {
                ...rate,
                practiceTypePenaltyPerc: value,
              };

            if (type === "cash-perc")
              return {
                ...rate,
                practiceTypeCashPerc: value,
              };
          }),
        },
      } as AppraiserRateData;
    });

    dispatch(
      setAppraiserRatesData({
        id: appraiserData?.id!,
        ratesData: updatedAppraiserRates || [],
        isUpdate: true,
      })
    );
  };

  const validateData = () => {
    let isValid = true;

    appraiserRates?.forEach((appraiserRates) => {
      const ratesWithValue = appraiserRates.appraiserData?.ratesByType?.filter(
        (rate) =>
          rate.practiceTypeBaseRateAmount !== null &&
          rate.practiceTypeBaseRateAmount !== 0
      );
      if ((ratesWithValue?.length || 0) < 1) isValid = false; // at least one rate with value
    });

    dispatch(
      setValidation({
        id: appraiserData?.id!,
        validationErrors: {
          rates: isValid ? [] : ["tariffe invalide"],
        },
      })
    );
  };

  const pageSize = 5;

  return (
    <RatesClientsWrapper data-test-id="RatesClientsWrapper">
      <RatesClientsContainer data-test-id="RatesClientsContainer">
        <RatesClientsContent data-test-id="RatesClientsContent">
          <div className="header-wrapper">
            <div className="filter-wrapper">
              <InputTextStyled
                placeholder={t("rates-clients-filter-placeholder")}
                value={filter}
                onChange={(txt) => setFilter(txt)}
              />
            </div>
            <RatesHeader ratesCode={ratesCode} />
          </div>
          <div className="tenants-wrapper">
            {filteredAppraiserRates
              ?.slice((page - 1) * pageSize, page * pageSize)
              .map((tenantRates, i) => (
                <TenantRates
                  key={i}
                  appraiserData={tenantRates.appraiserData}
                  onChange={handleOnChange}
                />
              ))}
          </div>
          <div className="footer">
            <div className="pagination-wrapper">
              <Pagination
                current={page}
                defaultPageSize={pageSize}
                onChange={setPage}
                total={filteredAppraiserRates?.length}
              />
            </div>
          </div>
        </RatesClientsContent>
      </RatesClientsContainer>
    </RatesClientsWrapper>
  );
};

export default AppraiserDetailsRates;
