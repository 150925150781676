import React, { useEffect, useMemo } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { getLoginRequest } from "../../config/azureConfig";
import { AuthenticationResult } from "@azure/msal-browser";
import { Tooltip } from "antd";
import { FaSignOutAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { IconUser } from "../../config/icons";
import dekra from "../../style/dekra";
import { EndSessionPopupRequest } from "@azure/msal-browser";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  setAuthorization,
  setUserDetails,
  setUserError,
} from "../../redux/features/userSlice";
import { authentication as testIds } from "../../config/testIds";
import {
  Roles,
  User as UserDetails,
  useLazyGetMyUserDataQuery,
} from "../../redux/apiSpecifications/apiFesf";
import { Urls } from "../../config/urlBuilder";
import { setDashboards } from "../../redux/features/mainTabsSlice";

const AuthenticationWrapper = styled.div`
  display: flex;
  line-height: 1.2em;
  align-items: center;
`;

const LogoutStyled = styled.div`
  cursor: pointer;
  color: #fff;
  font-size: 1.4em;
`;

const UserWrapper = styled.div`
  margin-right: 1em;
`;

const NoUserWrapper = styled.div`
  margin-right: 1em;
  svg {
    color: #fff;
    border: 2px solid #ddd;
  }
`;

const UserThumbnail = styled(IconUser)`
  border: 2px solid #fff;
  color: #fff;
  border-radius: 5px;
  font-size: 2em;
  padding: 0.15em;
  cursor: pointer;
`;

const getFirstRoleAsArray = (roles: Roles | undefined): Roles =>
  roles !== undefined ? roles.slice(0, 1) : [];

const SingedIn = () => {
  const dispatch = useDispatch();

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [retrieveMe] = useLazyGetMyUserDataQuery();

  const { t } = useTranslation();
  const { firstname, surname } =
    useSelector((state: RootState) => state.user.userDetails) || {};

  const userNominative =
    surname && firstname ? `${firstname} ${surname}` : undefined;

  useEffect(() => {
    if (!isAuthenticated) return;

    (async () => {
      try {
        const response = await instance.acquireTokenSilent({
          ...getLoginRequest(),
          account: accounts[0],
        });
        const jwt = "Bearer " + response.accessToken;
        dispatch(setAuthorization(jwt));

        retrieveMe({ authorization: jwt })
          .then((response) => {
            if (response.status === "fulfilled") {
              const userDetails = response.data as UserDetails;
              dispatch(setUserDetails(userDetails));
              dispatch(setDashboards(getFirstRoleAsArray(userDetails.roles)));
            } else {
              dispatch(setUserError(t("roles-loading-error")));
            }
          })
          .catch((error) => {
            dispatch(setUserError(t("roles-loading-error")));
          });
      } catch (err) {
        dispatch(setUserError(t("roles-loading-error")));
        console.log(err);
      }
    })();
  }, [isAuthenticated]);

  return (
    <AuthenticationWrapper data-testid={testIds.signedIn}>
      {userNominative && (
        <>
          <Tooltip title={userNominative}>
            <UserWrapper>
              <UserThumbnail />
            </UserWrapper>
          </Tooltip>
        </>
      )}
      {!userNominative && (
        <NoUserWrapper>
          <UserThumbnail />
        </NoUserWrapper>
      )}

      <LogoutStyled
        data-testid={testIds.buttonSignout}
        onClick={() => {
          instance.logoutPopup({
            postLogoutRedirect: "/",
            mainWindowRedirectUri: "/",
          } as EndSessionPopupRequest);
        }}
      >
        <Tooltip title={t("authentication-sign-out")}>
          <FaSignOutAlt />
        </Tooltip>
      </LogoutStyled>
    </AuthenticationWrapper>
  );
};

export default SingedIn;
