import { useEffect, useMemo, useRef, useState } from "react";

import styled from "styled-components";
import { MainTabType } from "../../../redux/features/mainTabsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MainTabContentWrapper } from "../../../style/DashbordWidgetWrappers";
import { clickableSearch as testIds } from "../../../config/testIds";
import SearchFormAppraisals from "../../Search/SearchFormAppraisals";
import { IconLoading } from "../../../config/icons";
import { RootState } from "../../../redux/store";
import AppraisalsList from "../Appraisals/AppraisalsList";
import ExpertizerList from "../Expertizer/ExpertizerList";
import {
    PracticeE2List,
    PracticeList,
    SearchPracticesParams,
    SearchResponseAppraiser,
    SearchResponseBo,
    useSearchPracticesAppraiserMutation,
    useSearchPracticesBoMutation,
} from "../../../redux/apiSpecifications/apiCrud";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Alert } from "antd";

const SearchResultsWrapper = styled(MainTabContentWrapper)`
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 2em 1% 1em 1%;
    width: 98%;
    background-color: #fff;
    border-radius: 1em;
    box-shadow: 0 0 5px #aaa;
    padding: 1em 2em;

    .title-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 2em;
        padding-top: 2em;
        border-top: 1px solid #ddd;

        .title {
            font-size: 1.4em;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }

    .search-result-message-wrapper {
        margin-top: 2em;
        padding: 0 2em;
    }

    .table-wrapper {
        padding: 0 2em;
    }
`;

const LoadingWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 1em;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-content {
        height: 10em;
        width: 10em;
    }
`;

interface ISearchResultsAppraisalsProps {
    tabItem: MainTabType;
}

const SearchResultsAppraisals = (props: ISearchResultsAppraisalsProps) => {
    const { tabItem } = props;
    const { externalData } = tabItem;
    const { items, totalResultCount } = externalData;
    const { t } = useTranslation();

    const { getAuthorization } = useAuthorization();

    const { activeRole } = useSelector((state: RootState) => state.user);
    const isUserBo = ["D1", "D2"].includes(activeRole || "");
    const isTechnicalRoom = ["E1", "E4"].includes(activeRole || "");
    const isUserAppraiser = ["E2", "E3"].includes(activeRole || "");
    const isSupplierNetwork = ["NF"].includes(activeRole || "");
    const isAdministrative = ["G1", "G2"].includes(activeRole || "");
    const isCustomer = ["F1"].includes(activeRole || "");

    const refResults = useRef<null | HTMLDivElement>(null);
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState<string | undefined>();

    const [searchPracticesBo] = useSearchPracticesBoMutation();
    const [searchPracticesAppraiser] = useSearchPracticesAppraiserMutation();

    const [filteredByUserAppraiser, setFilteredByUserAppraiser] =
        useState<PracticeE2List[]>();
    const [filteredByUserBO, setFilteredByUserBO] = useState<PracticeList>();

    const [totalSearchResultCount, setTotalSearchResultCount] =
        useState<number>(0);

    useEffect(() => {
        if (items) {
            if (
                isUserBo ||
                isTechnicalRoom ||
                isSupplierNetwork ||
                isAdministrative ||
                isCustomer
            ) {
                setFilteredByUserBO(items);
            } else if (isUserAppraiser) {
                setFilteredByUserAppraiser(items);
            }
            if (refResults.current)
                setTimeout(() => {
                    refResults!.current!.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                    });
                }, 1000);
        }
    }, [items]);

    useEffect(() => {
        setTotalSearchResultCount(totalResultCount);
    }, [totalResultCount]);

    // const searchResultMessage = useMemo(() => {
    //     if (items.length < totalResultCount)
    //         return t("search-results-appraisals-result-message");
    //     else return undefined;
    // }, [items, totalResultCount]);

    const handleSearchAppraisals = async (
        searchParams: SearchPracticesParams
    ) => {
        setLoading(true);
        setLoadingError(undefined);

        setFilteredByUserAppraiser([]);
        setFilteredByUserBO([]);

        try {
            if (
                isUserBo ||
                isTechnicalRoom ||
                isSupplierNetwork ||
                isAdministrative ||
                isCustomer
            ) {
                const response = await searchPracticesBo({
                    authorization: await getAuthorization(),
                    searchPracticesParams: searchParams,
                    activeRole: activeRole!,
                });

                const searchResponse = (
                    response as {
                        data: SearchResponseBo;
                    }
                ).data as SearchResponseBo;

                // const listPractice = (
                //     response as {
                //         data: PracticeList;
                //     }
                // ).data as PracticeList;

                setFilteredByUserBO(searchResponse.results);
                setTotalSearchResultCount(searchResponse.totalResultCount!);
            } else if (isUserAppraiser) {
                const response = await searchPracticesAppraiser({
                    authorization: await getAuthorization(),
                    searchPracticesParams: searchParams,
                    activeRole: activeRole!,
                });

                const listPractice = (
                    response as {
                        data: SearchResponseAppraiser;
                    }
                ).data as SearchResponseAppraiser;

                // const listPractice = (
                //     response as {
                //         data: PracticeE2List[];
                //     }
                // ).data as PracticeE2List[];

                setFilteredByUserAppraiser(listPractice.results);
                setTotalSearchResultCount(listPractice.totalResultCount!);
            }

            setTimeout(() => {
                refResults!.current!.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }, 1000);
        } catch (e) {
            console.error(e);
            setLoadingError(t("waiting-for-network-delay-result-error")!);
        }

        setLoading(false);
    };

    return (
        <SearchResultsWrapper
            data-testid={testIds.results.appraisals.container}
        >
            <SearchFormAppraisals onSearch={handleSearchAppraisals} />

            <div className="title-wrapper" ref={refResults}>
                <div className="title">
                    {t("search-results-appraisals-table-label")}
                </div>
            </div>

            {(isUserBo ||
                isTechnicalRoom ||
                isSupplierNetwork ||
                isAdministrative ||
                isCustomer) && (
                <>
                    {totalSearchResultCount >
                        (filteredByUserBO?.length ?? 0) && (
                        <div className="search-result-message-wrapper">
                            <Alert
                                message={t(
                                    "search-results-appraisals-result-message",
                                    {
                                        totalResultCount:
                                            totalSearchResultCount,
                                        realResultCount:
                                            filteredByUserBO?.length,
                                    }
                                )}
                                type="info"
                            />
                        </div>
                    )}

                    <div className="table-wrapper">
                        <AppraisalsList
                            items={filteredByUserBO}
                            removeWrapper={true}
                            scenario="search_result"
                            onRefresh={() => {}}
                        />
                        {/* <pre>{JSON.stringify(filteredByUserBO, null, 2)}</pre> */}
                    </div>
                </>
            )}

            {isUserAppraiser && (
                <>
                    {totalSearchResultCount >
                        (filteredByUserAppraiser?.length ?? 0) && (
                        <div className="search-result-message-wrapper">
                            <Alert
                                message={t(
                                    "search-results-appraisals-result-message",
                                    {
                                        totalResultCount:
                                            totalSearchResultCount,
                                        realResultCount:
                                            filteredByUserAppraiser?.length,
                                    }
                                )}
                                type="info"
                            />
                        </div>
                    )}

                    <div className="table-wrapper">
                        <ExpertizerList
                            items={filteredByUserAppraiser}
                            removeWrapper={true}
                            onRefresh={() => {}}
                        />
                    </div>
                </>
            )}

            {loading && (
                <LoadingWrapper>
                    <div className="loading-content">{IconLoading}</div>
                </LoadingWrapper>
            )}
        </SearchResultsWrapper>
    );
};

export default SearchResultsAppraisals;
