export const getAppraiserStatusSelectsOptions = (t: any) => {
  const defaultSelectOptions = [
    {
      value: "",
      label: "",
    },
    {
      value: "add-leave",
      label: t("agenda-send-leave-add"),
    },
    {
      value: "add-saint",
      label: t("agenda-send-leave-add-saint"),
    },
    {
      value: "add-illness",
      label: t("agenda-send-illness-add"),
    },
  ];

  const leaveOptions = [
    {
      value: "modify-leave",
      label: t("agenda-send-leave-modify"),
    },
    {
      value: "remove-leave",
      label: t("agenda-send-leave-remove"),
    },
  ];

  const saintOptions = [
    {
      value: "modify-saint",
      label: t("agenda-send-saint-modify"),
    },
    {
      value: "remove-saint",
      label: t("agenda-send-saint-remove"),
    },
  ];

  const illnessOptions = [
    {
      value: "modify-illness",
      label: t("agenda-send-illness-modify"),
    },
    {
      value: "remove-leave",
      label: t("agenda-send-illness-remove"),
    },
  ];

  return {
    defaultSelectOptions,
    leaveOptions,
    saintOptions,
    illnessOptions,
  };
};

export const getAppraiserStatusSelectsOptionsNF = (t: any) => {
  const suspensionOptions = [
    {
      value: "modify-suspension",
      label: t("agenda-send-suspension-modify"),
    },
    {
      value: "remove-suspension",
      label: t("agenda-send-suspension-remove"),
    },
  ];

  const blockedOptions = [
    {
      value: "modify-blocked",
      label: t("agenda-send-blocked-modify"),
    },
    {
      value: "remove-blocked",
      label: t("agenda-send-blocked-remove"),
    },
  ];

  const closedOptions = [
    {
      value: "modify-closed",
      label: t("agenda-send-closed-modify"),
    },
    {
      value: "remove-closed",
      label: t("agenda-send-closed-remove"),
    },
  ];

  const { defaultSelectOptions, leaveOptions, saintOptions, illnessOptions } =
    getAppraiserStatusSelectsOptions(t);

  defaultSelectOptions.push({
    value: "add-suspension",
    label: t("agenda-send-suspension-add"),
  });

  defaultSelectOptions.push({
    value: "add-blocked",
    label: t("agenda-send-blocked-add"),
  });

  defaultSelectOptions.push({
    value: "add-closed",
    label: t("agenda-send-closed-add"),
  });

  return {
    defaultSelectOptions,
    leaveOptions,
    saintOptions,
    illnessOptions,
    suspensionOptions,
    blockedOptions,
    closedOptions,
  };
};
