import moment from "moment";
import { DateFormat, TimeFormat } from "../config/const";
import { Address } from "../redux/apiSpecifications/apiCrud";
import { AdditionalValidationField } from "../types/common.types";

export const isNotEmpty = (str: string | undefined | null): boolean => (str?.length || 0) > 0;

export const checkEmailFormat = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/g.test(email);

export const checkTelephone = (_num: string) => {
	const num = _num.replace(" ", "");
	return /^(\d|\+)[ \d-]+$/g.test(num!) && num.length >= 9 && num.length <= 15;
};
export const checkPostalCode = (postalCode: string): boolean => /^[0-9]{5}$/g.test(postalCode);

export const checkBicSwift = (bicSwift: string): boolean => bicSwift.length >= 8 && bicSwift.length <= 11; // /^[A-Z]{4}[-]{0,1}[A-Z]{2}[-]{0,1}[A-Z0-9]{2}[-]{0,1}[0-9]{3}$/.test(bicSwift);

export const checkDate = (strDate: string | undefined): AdditionalValidationField =>
	moment(strDate, DateFormat).isValid() //
		? { state: true } //
		: { state: false, validateStatus: "error", messageKey: "invalid-date" }; //

export const checkDateBetween = (strDate: string | undefined, strDateBegin: string | undefined, strDateEnd: string | undefined) => {
	const date = moment(strDate, DateFormat);
	const dateBegin = moment(strDateBegin, DateFormat);
	const dateEnd = moment(strDateEnd, DateFormat);

	if (!date.isValid()) return { state: false, validateStatus: "error", messageKey: "invalid-date" };
	if (dateBegin.isValid() && dateBegin.isAfter(date)) return { state: false, validateStatus: "error", messageKey: "out-of-coverage-date" };
	if (dateEnd.isValid() && dateEnd.isBefore(date)) return { state: false, validateStatus: "error", messageKey: "out-of-coverage-date" };

	return { state: true };
};

export const checkTime = (strTime: string | undefined): AdditionalValidationField =>
	moment(strTime, TimeFormat).isValid() //
		? { state: true } //
		: { state: false, validateStatus: "error", messageKey: "invalid-time" }; //

export const checkPlace = (place: Address | undefined): AdditionalValidationField => {
	return !!place && !!place.country && !!place.city && !!place.street
		? { state: true }
		: { state: false, validateStatus: "error", messageKey: "invalid-place" };
};

export const checkPlate = (plate: string | undefined) => (plate?.length || 0) === 7;
