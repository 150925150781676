import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useEffect, useState } from "react";
import {
  FileThumbnail,
  useLazyDownloadAttachmentByIdQuery,
  useLazyGetAttachmentFileThumbnailQuery,
  useLazyGetZipFileListQuery,
  ZipFileItem,
} from "../../redux/apiSpecifications/apiDocs";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { addMimeType, removeMimeType } from "../../utils/base64";
// import { FileThumbnailWithImage } from "./ViewADZImage";
import styled from "styled-components";
import { PracticeAttachment } from "../../redux/apiSpecifications/apiCrud";
import { FaFileDownload } from "react-icons/fa";
import { IconLoading, IconNext, IconPrevious } from "../../config/icons";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { downloadFile } from "../../utils/fileUtils";

export const LoadingInTabWrapper = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;

  .box {
    width: 20em;
  }
`;

const PreviewADZWrapper = styled.div`
  display: flex;
  height: calc(100vh - 103px);

  .full-image {
    width: 100%;
    padding-right: 1rem;

    .buttons {
      display: flex;
      justify-content: center;
      gap: 1rem;

      div {
        cursor: pointer;
      }
    }

    .image {
      height: calc(
        100vh - 125px
      ); // altezza del wrapper - altezza del div buttons

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin: 0 auto;
      }
    }
  }

  .thumbnails {
    justify-items: end;
    overflow-y: auto;
    overflow-x: hidden;

    flex-grow: 0; /* do not grow   - initial value: 0 */
    flex-shrink: 0; /* do not shrink - initial value: 1 */
  }

  .thumbnails-grid {
    display: grid;
    grid-gap: 1rem;

    img {
      border: solid 5px white;
      cursor: pointer;
    }

    img.selected {
      border: solid 5px rgb(0, 129, 67);
    }

    div {
      padding: 10px;
    }

    div.selected {
      background-color: rgb(237, 237, 237);
    }
  }

  @media (min-width: 600px) {
    .thumbnails {
      flex-basis: 180px; /* width/height  - initial value: auto */
    }

    .thumbnails-grid {
      grid-template-columns: repeat(1, 160px);
    }
  }

  @media (min-width: 1200px) {
    .thumbnails {
      flex-basis: 355px; /* width/height  - initial value: auto */
    }

    .thumbnails-grid {
      grid-template-columns: repeat(2, 160px);
    }
  }

  @media (min-width: 1600px) {
    .thumbnails {
      flex-basis: 530px; /* width/height  - initial value: auto */
    }

    .thumbnails-grid {
      grid-template-columns: repeat(3, 160px);
    }
  }
`;

type FileThumbnailWithImage = FileThumbnail & {
  base64FileImage?: string;
};

interface IPreviewADZProps {
  id: number;
}

const PreviewADZ = ({ id }: IPreviewADZProps) => {
  const { t } = useTranslation();

  const { authorization, activeRole } = useSelector(
    (state: RootState) => state.user
  );

  const [loadingStatus, setLoadingStatus] = useState<
    "idle" | "loading" | "refreshing" | undefined
  >("loading");
  const [thumbnails, setThumbnails] = useState<FileThumbnailWithImage[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState<
    number | undefined
  >(undefined);

  const [getZipFileList] = useLazyGetZipFileListQuery();
  const [getAttachmentFileThumbnail] = useLazyGetAttachmentFileThumbnailQuery();
  const [downloadAttachmentById] = useLazyDownloadAttachmentByIdQuery();

  useEffect(() => {
    loadDataPromise(id).then(async (data) => {
      const promiseArray = data.map((_) =>
        loadtAttachmentFileThumbnail(id, _.fileName!)
      );

      const resolvedPromises = await Promise.all(promiseArray);

      const nullFree = resolvedPromises.filter(
        (_) => _ !== null
      ) as FileThumbnail[];

      if (nullFree.length > 0) {
        const response = await downloadAttachmentById({
          authorization,
          id: id,
          fileName: nullFree[0].fileName,
        });

        const data = response.data as PracticeAttachment;

        if (data) {
          setThumbnails(
            nullFree.map((t, index) => {
              if (index === 0) {
                return {
                  ...t,
                  base64FileImage: addMimeType(
                    data.base64File!,
                    data.originalName!
                  ),
                };
              } else {
                return t;
              }
            })
          );

          setSelectedImageIndex(0);
        } else {
          setThumbnails(nullFree);
        }
      }

      setLoadingStatus("idle");
    });
  }, []);

  const loadtAttachmentFileThumbnail = async (id: number, fileName: string) => {
    const response = await getAttachmentFileThumbnail({
      activeRole: activeRole!,
      id: id,
      fileName: fileName,
      width: 150,
    });

    const data = response.data as FileThumbnailWithImage;

    if (data) {
      return {
        ...data,
        base64File: addMimeType(data.base64File!, data.fileName!),
      };
    } else {
      const errorResponse = response as {
        error: FetchBaseQueryError;
      };

      console.error(errorResponse.error);

      return null;
    }
  };

  const loadDataPromise = (attachmentId: number): Promise<ZipFileItem[]> =>
    new Promise(async (resolve, reject) => {
      const response = await getZipFileList({
        activeRole: activeRole!,
        id: attachmentId,
        fileContentType: "images",
      });

      const data = response.data as ZipFileItem[];

      if (data) {
        resolve(data);
      } else {
        const errorResponse = response as {
          error: FetchBaseQueryError;
        };

        reject(errorResponse.error);
      }
    });

  const handleOnSelectImage = (index: number) => {
    const i =
      index > thumbnails.length - 1
        ? 0
        : index < 0
        ? thumbnails.length - 1
        : index;

    if (thumbnails[i].base64FileImage === undefined) {
      (async () => {
        const response = await downloadAttachmentById({
          authorization,
          id: id,
          fileName: thumbnails[i].fileName,
        });

        const data = response.data as PracticeAttachment;

        if (data) {
          setThumbnails((oldState) =>
            oldState.map((t, index) => {
              if (index === i) {
                return {
                  ...t,
                  // base64FileImage: data.base64File!,
                  base64FileImage: addMimeType(
                    data.base64File!,
                    data.originalName!
                  ),
                };
              } else {
                return t;
              }
            })
          );
        }
      })();
    }

    setSelectedImageIndex(i);
  };

  const handleOnImageLoaded = (base64: string) => {
    setThumbnails((oldState) =>
      oldState.map((t, index) => {
        if (index === selectedImageIndex) {
          return { ...t, base64FileImage: base64 };
        } else {
          return t;
        }
      })
    );
  };

  const handleOnDownloadFile = () => {
    const selectedFile = thumbnails[selectedImageIndex!];

    downloadFile(
      removeMimeType(selectedFile.base64FileImage!),
      selectedFile.fileName!
    );
  };

  return (
    <PreviewADZWrapper>
      {loadingStatus === "loading" && (
        <LoadingInTabWrapper>
          <div className="box">{IconLoading}</div>
        </LoadingInTabWrapper>
      )}

      {["idle", "refreshing", "saving"].includes(loadingStatus as string) && (
        <>
          <div className="full-image">
            <div className="buttons">
              <Tooltip title={t("TOOLTIPS.previous-adz-image")}>
                <div
                  onClick={() => handleOnSelectImage(selectedImageIndex! - 1)}
                >
                  <IconPrevious />
                </div>
              </Tooltip>

              <Tooltip title={t("TOOLTIPS.download-adz-image")}>
                <div onClick={handleOnDownloadFile}>
                  <FaFileDownload />
                </div>
              </Tooltip>

              <Tooltip title={t("TOOLTIPS.next-adz-image")}>
                <div
                  onClick={() => handleOnSelectImage(selectedImageIndex! + 1)}
                >
                  <IconNext />
                </div>
              </Tooltip>
            </div>

            <div className="image">
              {thumbnails.map((_, index) => (
                <img
                  key={_.fileName}
                  src={_.base64FileImage || _.base64File}
                  style={{
                    display:
                      index !== (selectedImageIndex ?? -1) ? "none" : "block",
                    // width: _.width! > _.height! ? "100%" : "auto",
                    // height: _.height! > _.width! ? "100%" : "auto",
                  }}
                />
              ))}
            </div>
          </div>

          <div className="thumbnails">
            <div className="thumbnails-grid">
              {thumbnails.map((t, index) => (
                <img
                  className={
                    index !== (selectedImageIndex ?? -1) ? "" : "selected"
                  }
                  key={index}
                  src={t.base64File}
                  onClick={() => handleOnSelectImage(index)}
                />
                // <pre key={index}>{JSON.stringify(t, null, 2)}</pre>
              ))}
            </div>
          </div>
        </>
      )}
    </PreviewADZWrapper>
  );
};

export default PreviewADZ;
