import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { widgets } from "../../../config/testIds";

import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { Modal } from "antd";
import dekra from "../../../style/dekra";
import { ButtonConfirm } from "../../Layout/Buttons";

const testIds = widgets.mapExpertizers;

const ExperiizerPriorityInfoWrapper = styled(WidgetWrapper)`
  display: flex;
  width: 100%;
  height: 100%;

  .icon-info-wrapper {
    font-size: 1.6em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    color: ${dekra.primaryColor};
  }
`;

const ModalContent = styled.div`
  padding: 3em;

  .info-table {
    width: 100%;

    thead {
      td {
        padding: 0.5em 1em;
        text-transform: uppercase;
        vertical-align: top;
        text-align: left;
      }
      background-color: #eee;
    }

    tbody {
      td {
        padding: 0.75em 1em;
        text-align: center;
        border-bottom: 1px solid #eee;

        &:nth-child(1) {
          text-align: left;
        }
      }
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2em;
  }
`;

interface IExpertierPriorityInfoProps {}

const ExperiizerPriorityInfo = ({}: IExpertierPriorityInfoProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ExperiizerPriorityInfoWrapper
      data-testid={testIds.container}
      className={"open"}
    >
      {!isOpen && (
        <div className="icon-info-wrapper" onClick={() => setIsOpen(true)}>
          <FaInfoCircle />
        </div>
      )}
      {isOpen && (
        <Modal
          open={isOpen}
          width="1100px"
          footer={null}
          closable={false}
          onCancel={() => setIsOpen(false)}
        >
          <ModalContent>
            <table className="info-table">
              <thead>
                <tr>
                  <td>{t("dashboard-e2-info-assignment-type")}</td>
                  <td>{t("dashboard-e2-info-claim-type")}</td>
                  <td>{t("dashboard-e2-info-sign-type")}</td>
                  <td>
                    {t("dashboard-e2-info-expiring-date-client-1")}
                    <br />
                    {t("dashboard-e2-info-expiring-date-client-2")}
                  </td>
                  <td>
                    {t("dashboard-e2-info-expiring-date-appraiser-1")}
                    <br />
                    {t("dashboard-e2-info-expiring-date-appraiser-2")}
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t("dashboard-e2-info-assignment-type-expertize")}</td>
                  <td>{t("dashboard-e2-info-claim-type-CVT")}</td>
                  <td> - </td>
                  <td>30</td>
                  <td>20</td>
                </tr>
                <tr>
                  <td>{t("dashboard-e2-info-assignment-type-expertize")}</td>
                  <td>{t("dashboard-e2-info-claim-type-RCA")}</td>
                  <td>{t("dashboard-e2-info-sign-type-mono")}</td>
                  <td>15</td>
                  <td>13</td>
                </tr>
                <tr>
                  <td>{t("dashboard-e2-info-assignment-type-expertize")}</td>
                  <td>{t("dashboard-e2-info-claim-type-RCA")}</td>
                  <td>{t("dashboard-e2-info-sign-type-double")}</td>
                  <td>15</td>
                  <td>13</td>
                </tr>
                <tr>
                  <td>{t("dashboard-e2-info-assignment-type-expertize")}</td>
                  <td>{t("dashboard-e2-info-claim-type-RCA")}</td>
                  <td>{t("dashboard-e2-info-sign-type-no-card")}</td>
                  <td>15</td>
                  <td>13</td>
                </tr>
                <tr>
                  <td>{t("dashboard-e2-info-assignment-type-feedback")}</td>
                  <td>{t("dashboard-e2-info-claim-type-RCA")}</td>
                  <td>{t("dashboard-e2-info-sign-type-mono")}</td>
                  <td>15</td>
                  <td>13</td>
                </tr>
                <tr>
                  <td>{t("dashboard-e2-info-assignment-type-feedback")}</td>
                  <td>{t("dashboard-e2-info-claim-type-RCA")}</td>
                  <td>{t("dashboard-e2-info-sign-type-double")}</td>
                  <td>15</td>
                  <td>13</td>
                </tr>
                <tr>
                  <td>{t("dashboard-e2-info-assignment-type-place")}</td>
                  <td></td>
                  <td></td>
                  <td>10</td>
                  <td>7</td>
                </tr>
                <tr>
                  <td>
                    {t("dashboard-e2-info-assignment-type-congruity-request")}
                  </td>
                  <td></td>
                  <td></td>
                  <td>7</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>
                    {t(
                      "dashboard-e2-info-assignment-type-congruity-kinematics"
                    )}
                  </td>
                  <td></td>
                  <td></td>
                  <td>15</td>
                  <td>13</td>
                </tr>
                <tr>
                  <td>
                    {t(
                      "dashboard-e2-info-assignment-type-congruity-comparativa"
                    )}
                  </td>
                  <td></td>
                  <td></td>
                  <td>10</td>
                  <td>7</td>
                </tr>
                <tr>
                  <td>
                    {t(
                      "dashboard-e2-info-assignment-type-congruity-elmentary-branches"
                    )}
                  </td>
                  <td></td>
                  <td></td>
                  <td>15</td>
                  <td>13</td>
                </tr>
                <tr>
                  <td>{t("dashboard-e2-info-assignment-type-CPT")}</td>
                  <td></td>
                  <td></td>
                  <td>-20 ({t("dashboard-e2-info-from-hearing-date")})</td>
                  <td>-25 ({t("dashboard-e2-info-from-hearing-date")})</td>
                </tr>
              </tbody>
            </table>
            <div className="button-wrapper">
              <ButtonConfirm onClick={() => setIsOpen(false)}>
                {t("button-close")!}
              </ButtonConfirm>
            </div>
          </ModalContent>
        </Modal>
      )}
    </ExperiizerPriorityInfoWrapper>
  );
};

export default ExperiizerPriorityInfo;
