import styled from "styled-components";
import INIAttachmentPractice from "../../../../Inputs/INIAttachmentPractice";
import { useState } from "react";
import {
    PracticeAttachment,
    PracticeDocument,
    useLazyGetIniFileQuery,
} from "../../../../../redux/apiSpecifications/apiCrud";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import { addMimeType } from "../../../../../utils/base64";

const AttachmentsWrapper = styled.div`
    width: "auto";
    display: flex;
    flex-direction: column;
    align-items: "center";
    justify-content: center;
    width: 12em;
    text-align: center;
    font-size: 0.9em;
`;

const AttachmentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    .ant-upload-list-item-actions {
        a {
            display: none;
        }
    }
    .type-name {
        margin-top: 1em;
        text-transform: uppercase;
        font-size: 0.9em;
    }
`;

const FilesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

interface IAppraisalDetailsINIFileProps {
    idPractice: number;
}

const AppraisalDetailsINIFile = ({
    idPractice,
}: IAppraisalDetailsINIFileProps) => {
    const [attachment, setAttachment] = useState<
        PracticeAttachment | undefined
    >(undefined);

    const { getAuthorization } = useAuthorization();

    const [getIniFile, result] = useLazyGetIniFileQuery();

    const handleOnDownloadFile = async () => {
        setAttachment(undefined);

        const authorization = await getAuthorization();

        const response = await getIniFile({
            authorization,
            id: idPractice,
        });

        if (response.isSuccess && response.data) {
            const practiceDocument = response.data as PracticeDocument;

            const practiceAttachement =
                practiceDocument.attachments![0] as PracticeAttachment;

            setAttachment({
                ...practiceAttachement,
                base64File: addMimeType(
                    practiceAttachement.base64File!,
                    practiceAttachement.originalName!
                ),
            });
        }
    };

    return (
        <AttachmentsWrapper>
            <AttachmentsContainer>
                <FilesContainer>
                    <INIAttachmentPractice
                        onDownload={handleOnDownloadFile}
                        isLoading={result.status === "pending"}
                        attachment={attachment}
                    ></INIAttachmentPractice>
                </FilesContainer>
            </AttachmentsContainer>
        </AttachmentsWrapper>
    );
};

export default AppraisalDetailsINIFile;
