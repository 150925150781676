import styled from "styled-components";
import { WidgetWrapper } from "../../../../../../style/DashbordWidgetWrappers";
import { useTranslation } from "react-i18next";
import { FormRow } from "../../../../../../style/form";
import { InputTextStyled } from "../../../../../../style/Input";
import { ButtonConfirm } from "../../../../../Layout/Buttons";

const CreateSubjectWrapper = styled(WidgetWrapper)`
  width: 100%;
  height: 100%;
  flex-direction: column;

  .widget-title {
    padding: 0.5em 2em;
    display: flex;
    color: #333;
    background-color: #ededed;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .body {
    padding: 2em;

    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  .buttons-wrapper {
    width: 100%;
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
interface ICreateSubjectProps {
  gridArea: string;
}

const CreateSubject = (props: ICreateSubjectProps) => {
  const { t } = useTranslation();

  return (
    <CreateSubjectWrapper>
      <div className="widget-title">{t("create-subject-form-title")}</div>
      <div className="body">
        <FormRow style={{ alignItems: "flex-end" }}>
          <InputTextStyled
            label={t("create-subject-form-subject-nominative")}
            placeholder={"..."}
            readOnly={true}
            value={""}
            disabled={false}
          />
        </FormRow>
        <FormRow style={{ alignItems: "flex-end" }}>
          <InputTextStyled
            label={t("create-subject-form-subject-fiscal-code")}
            placeholder={"..."}
            readOnly={true}
            value={""}
            disabled={false}
          />
        </FormRow>
        <div className="buttons-wrapper">
          <ButtonConfirm onClick={() => {}}>
            {t("create-subject-form-add-button")!}
          </ButtonConfirm>
        </div>
      </div>
    </CreateSubjectWrapper>
  );
};
export default CreateSubject;
