// import { useSelector } from "react-redux";
// import { RootState } from "../redux/store";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import {
  BasePolicy,
  Branch,
  Guarantee,
  ReadOnlyPolicy,
  useAddPolicyGuaranteeMutation,
  useCreatePolicyMutation,
  useLazyGetBranchesListQuery,
  useLazyGetReadOnlyPolicyQuery,
} from "../redux/apiSpecifications/apiPolicies";

export const usePolicy = () => {
  // const { activeRole } = useSelector((state: RootState) => state.user);

  const [getReadOnlyPolicy] = useLazyGetReadOnlyPolicyQuery();
  const [getBranchesList] = useLazyGetBranchesListQuery();
  const [addPolicyGuarantee] = useAddPolicyGuaranteeMutation();
  const [createPolicy] = useCreatePolicyMutation();

  const getPolicyPromise = (id: number): Promise<ReadOnlyPolicy | undefined> =>
    new Promise(async (resolve, reject) => {
      const response = await getReadOnlyPolicy({
        id: id,
      });

      const getResponse = response as {
        data: ReadOnlyPolicy;
      };

      if (getResponse.data) {
        resolve(getResponse.data);
      } else {
        const errorResponse = response as {
          error: FetchBaseQueryError;
        };

        reject(errorResponse.error);
      }
    });

  const getBranchesListPromise = (): Promise<Branch[]> =>
    new Promise(async (resolve, reject) => {
      const response = await getBranchesList();

      const getResponse = response as {
        data: Branch[];
      };

      if (getResponse.data) {
        resolve(getResponse.data);
      } else {
        const errorResponse = response as {
          error: FetchBaseQueryError;
        };

        reject(errorResponse.error);
      }
    });

  const addPolicyGuaranteePromise = (
    policyId: number,
    guarantee: Guarantee
  ): Promise<Guarantee[]> =>
    new Promise(async (resolve, reject) => {
      const response = await addPolicyGuarantee({
        id: policyId,
        guarantee: guarantee,
      });

      const postResponse = response as {
        data: Guarantee[];
      };

      if (postResponse.data) {
        resolve(postResponse.data);
      } else {
        const errorResponse = response as {
          error: FetchBaseQueryError;
        };

        reject(errorResponse.error);
      }
    });

  const createPolicyPromise = (
    basePolicy: BasePolicy
  ): Promise<ReadOnlyPolicy> =>
    new Promise(async (resolve, reject) => {
      const response = await createPolicy({
        basePolicy,
      });

      const postResponse = response as {
        data: ReadOnlyPolicy;
      };

      if (postResponse.data) {
        resolve(postResponse.data);
      } else {
        const errorResponse = response as {
          error: FetchBaseQueryError;
        };

        reject(errorResponse.error);
      }
    });

  return {
    getPolicyPromise,
    getBranchesListPromise,
    addPolicyGuaranteePromise,
    createPolicyPromise,
  };
};
