import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { widgets } from "../../../config/testIds";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { DonutDataItemType } from "../../Charts/DonutChart";
import { useEffect, useState } from "react";
import { PracticesInProgressSummary } from "../../../redux/apiSpecifications/apiCrud";
import DonutChart from "../../Charts/DonutChart";
import { Button } from "antd";
import WidgetHeader from "../WidgetHeader";

const testIds = widgets.inProgress;

const AppraisalsInProgressWrapper = styled(WidgetWrapper)`
	flex-direction: column;
	align-items: stretch;

	max-height: 2.5em;
	transition: max-height 1s;

	&.open {
		max-height: 100em;
	}

	.button-wrapper {
		display: flex;
		justify-content: center;
		margin-bottom: 2em;
	}

	.vertical-separator {
		border-left: 2px dashed #ddd;
		margin: 1em;
	}
`;

const statusInProgressDefault: FilterPracticesInProgress = {
	level1: "documental",
	level2: undefined,
	level3: undefined,
};

export type InternaExternalType = undefined | "internal" | "external";

export type FilterPracticesInProgress = {
	level1: undefined | "documental" | "inspective";
	level2: InternaExternalType;
	level3: undefined | "yes" | "no";
};
export interface IAppraisalsInProgressProps {
	gridArea?: string;
	summary?: PracticesInProgressSummary;
	onApplyFilter: (filter: FilterPracticesInProgress) => void;
	isActive?: boolean;
	isOpen?: boolean;
	resetFilter?: number;
}

const AppraisalsInProgress = (props: IAppraisalsInProgressProps) => {
	const { gridArea, summary, onApplyFilter, isActive = false, resetFilter } = props;
	const { t } = useTranslation();

	const [filterPracticesInProgress, setFilterPracticesInProgress] = useState<FilterPracticesInProgress>(statusInProgressDefault);
	const [intExt, setIntExt] = useState<InternaExternalType>(undefined);

	const [dataTotal, setDataTotal] = useState<DonutDataItemType[]>([]);
	const [dataIntExt, setDataIntExt] = useState<DonutDataItemType[]>([]);
	const [dataAppointment, setDataAppointment] = useState<DonutDataItemType[]>([]);

	const [showSelections, setShowSelections] = useState(false);
	const [isOpen, setIsOpen] = useState(props.isOpen);

	useEffect(() => {
		setIsOpen(props.isOpen);
	}, [props.isOpen]);

	useEffect(() => {
		setFilterPracticesInProgress(statusInProgressDefault);
		setShowSelections(false);
	}, [resetFilter]);

	useEffect(() => {
		if (!isActive) setShowSelections(false);
	}, [isActive]);

	useEffect(() => {
		handleFilterSelect(intExt as InternaExternalType, "level2");
	}, [intExt]);

	useEffect(() => {
		setDataTotal([
			{
				key: "documental",
				name: t("appraisals-dashboard-to-manage-documental"),
				value: summary?.byIsDocumentalFlag?.documental?.total || 0,
				fill: "#F48824",
			},
			{
				key: "inspective",
				name: t("appraisals-dashboard-to-manage-inspective"),
				value: summary?.byIsDocumentalFlag?.inspective?.total || 0,
				fill: "#336699",
			},
		]);

		let internal = 0;
		let external = 0;

		if (filterPracticesInProgress?.level1 === "documental") {
			internal = summary?.byIsDocumentalFlag?.documental?.assignedToInternal || 0;
			external = summary?.byIsDocumentalFlag?.documental?.assignedToExternal || 0;
			setDataIntExt([
				{
					key: "internal",
					name: t("appraisals-dashboard-to-manage-internal"),
					value: internal,
					fill: "#E9770C",
				},
				{
					key: "external",
					name: t("appraisals-dashboard-to-manage-external"),
					value: external,
					fill: "#F6A051",
				},
			]);
		} else if (filterPracticesInProgress?.level1 === "inspective") {
			internal = summary?.byIsDocumentalFlag?.inspective?.assignedToInternal || 0;
			external = summary?.byIsDocumentalFlag?.inspective?.assignedToExternal || 0;
			setDataIntExt([
				{
					key: "internal",
					name: t("appraisals-dashboard-to-manage-internal"),
					value: internal,
					fill: "#325F86",
				},
				{
					key: "external",
					name: t("appraisals-dashboard-to-manage-external"),
					value: external,
					fill: "#427EB3",
				},
			]);
		}

		setDataAppointment([
			{
				key: "yes",
				name: t("appraisals-dashboard-with-appointment-yes"),
				value: summary?.byHasAppointmentFlag?.yes || 0,
				fill: "#11AFD0",
			},
			{
				key: "no",
				name: t("appraisals-dashboard-with-appointment-no"),
				value: summary?.byHasAppointmentFlag?.no || 0,
				fill: "#E8E000",
			},
		]);
	}, [summary, filterPracticesInProgress.level1]);

	const handleFilterSelect = (value: string | undefined, field: string) => {
        console.info(value, field)
        const updatedFitlers = { ...filterPracticesInProgress, [field]: value } as FilterPracticesInProgress;
 
        if (field === "level1") {
            updatedFitlers.level2 = undefined;
            setIntExt(undefined);
 
            updatedFitlers.level3 = undefined;
        }
 
        if (field === "level2" && value !== undefined) {
            updatedFitlers.level3 = undefined;
        }
 
 
        if (field === "level3") {
            updatedFitlers.level1 = undefined;
 
            updatedFitlers.level2 = undefined;
            setIntExt(undefined);
        }
 
        setFilterPracticesInProgress(updatedFitlers);
        setShowSelections(true);
    };

	const assignedTotal = ((summary?.byIsDocumentalFlag?.documental?.total || 0) + (summary?.byIsDocumentalFlag?.inspective?.total || 0)).toString();
    const assignedFilter = (): string => {
		let total = 0;
		dataIntExt.forEach((x) => {
			total += x.value;
		}); 

		return String(total);
	}

	const intExtTitle =
		filterPracticesInProgress?.level1 === "documental" ? t("appraisals-dashboard-to-manage-documental") : t("appraisals-dashboard-to-manage-inspective");

	const handleFilterReset = () =>
		setFilterPracticesInProgress({
			level1: undefined,
			level2: undefined,
			level3: undefined,
		});

	const handleOnOpen = () => setIsOpen(true);
	const handleOnClose = () => setIsOpen(false);

	return (
		<AppraisalsInProgressWrapper className={`${isActive || isOpen ? "open" : ""}`} data-testid={testIds.container} gridArea={gridArea}>
			<WidgetHeader
				title={t("appraisals-dashboard-practices-in-progress-title")}
				isActive={isActive}
				filterReset={handleFilterReset}
				onButton1={() => onApplyFilter(filterPracticesInProgress)}
				button1Text={t("appraisals-dashboard-in-progress-button-apply-label")!}
				button1DataTestId={testIds.buttonApply}
				onOpen={handleOnOpen}
				onClose={handleOnClose}
				isOpen={isOpen}
			/>

			<div className="body">
				<div className="col">
					<DonutChart
						title={t(`appraisals-dashboard-statuses-in-progress`)}
						data={dataTotal}
						onClick={(key) => handleFilterSelect(key, "level1")}
						centerValue={assignedTotal}
						selected={showSelections ? filterPracticesInProgress.level1 : ""}
					/>
				</div>
				<div className="col">
					<DonutChart
						title={intExtTitle}
						data={dataIntExt}
						onClick={(key) => setIntExt(key as "internal" | "external")}
						centerValue={assignedFilter()}
						selected={showSelections ? filterPracticesInProgress.level2 : ""}
					/>
				</div>
				<div className="vertical-separator"></div>
				<div className="col">
					<DonutChart
						title={t(`appraisals-dashboard-appointment`)}
						data={dataAppointment}
						onClick={(key) => handleFilterSelect(key, "level3")}
						centerValue={assignedTotal}
						selected={showSelections ? filterPracticesInProgress.level3?.toLowerCase() : ""}
						unselectOnClick={true}
					/>
				</div>
			</div>
		</AppraisalsInProgressWrapper>
	);
};

export default AppraisalsInProgress;
