import { BulkAssignmentData, PracticesByAppraiserMapData } from "../../../../../redux/apiSpecifications/apiCrud";
import { useTranslation } from "react-i18next";
import { WidgetWrapper } from "../../../../../style/DashbordWidgetWrappers";
import styled from "styled-components";
import AssignmentMapSelectablePracticesList from "../components/AssignmentMapSelectablePracticesList";

const ListWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    .body {
        flex-direction: column;
        padding: 1em 2em;
        display: flex;
    }
`;

interface IAppraiserPracticeListProps {
    practicesByAppraiserMapData: PracticesByAppraiserMapData;
    onAssignPractices: (bulkAssignmentData: BulkAssignmentData) => void;
}

const AppraiserPracticeList = ({
    practicesByAppraiserMapData,
    onAssignPractices,
}: IAppraiserPracticeListProps) => {
    const { t } = useTranslation();

    return (
        <ListWrapper>
            <div className="title">
                {t("assignment-map-practices-list-title")}
            </div>

            <div className="body">
                <AssignmentMapSelectablePracticesList
                    appraiser={practicesByAppraiserMapData.appraiser!}
                    practices={practicesByAppraiserMapData.practices}
                    onAssignPractices={onAssignPractices}
                />
            </div>
        </ListWrapper>
    );
};

export default AppraiserPracticeList;
