import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MaterialReactTable from "material-react-table";
import { MRT_ColumnDef } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import {
  PracticeAppointment,
  PracticeE2List,
  PracticesProcessingStatus,
  ReadOnlyPractice,
  useAcceptPracticeAssignmentMutation,
  useCreatePracticeAppointmentMutation,
  useLazyGetPracticeDetailQuery,
  useLazyGetPracticesStatusQuery,
  useSendRejectedFeedbackMutation,
} from "../../../redux/apiSpecifications/apiCrud";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { addTab } from "../../../redux/features/mainTabsSlice";
import { widgets } from "../../../config/testIds";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { useInterval } from "../../../hooks/useInterval";
import { IconLoading, IconLocked } from "../../../config/icons";
import { ButtonConfirm } from "../../Layout/Buttons";
import { Modal, Popconfirm } from "antd";
import ScheduleAppointment from "../../MainTabs/MainTabContent/AppraisalsDetails/AppraisalDetailsActivities/ScheduleAppointment";
import { ExpertizerStatusType } from "../../../config/const";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { StatusTypes } from "../../MainTabs/Dashboards/Dashboard-E2";
const testId = widgets.expertizerList;

const ExpertizerListWrapper = styled(WidgetWrapper)`
  align-items: flex-start;
  max-width: 100%;
  overflow: auto;
  padding: 2em;

  .MuiPaper-root {
    box-shadow: none;
    width: 100%;
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiTableCell-root {
    padding: 0;
  }

  .confirm-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
  }

  .MuiTableRow-head {
    .MuiInputBase-root {
      input {
        margin-left: 5px;
      }
    }
  }
`;

const ExpertizerListWithoutWrapper = styled.div<{ gridArea?: string }>`
  grid-area: ${(props) => props.gridArea};

  .MuiPaper-root {
    box-shadow: none;
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiTableCell-root {
    padding: 0;
  }

  .MuiTableRow-head {
    .MuiInputBase-root {
      input {
        margin-left: 5px;
      }
    }
  }
`;

const CellWrapper = styled.div`
  cursor: pointer;
  padding: 1em 0;

  &.blocked-practice-row {
    color: #888;
  }
`;

const BlockedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.6em;
  font-size: 1.6em;
  color: #c44f4f;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  button {
    font-size: 0.9em;
  }
`;

const ModalContent = styled.div`
  padding: 4em;

  .day-interval {
    padding: 0 0 0 3em !important;
  }

  .note {
    padding-top: 1em !important;
  }
`;

const ModalContentError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
  padding: 2em 0;

  .text {
    padding: 10px;
  }

  .icon {
    width: 100px;
  }
`;

export type ExpertizerResultItem = {
  id: number;
  priority: string;
  clientName: string;
  sxNumber: string;
  sxType: string;
  plate: string;
  model: string;
  cap: string;
  cityAndProvince: string;
  expertDeadline: string;
  status: string;
  statusCode: string;
  isBlocked: boolean;
  blockedBy: string;
  practiceNumber: string;
  isViewed: boolean;
  rejectionDate: string;
  actions: string;
  practiceType: string;
};

export type ExpertizerListItemType = PracticeE2List & {
  isBlocked: boolean;
  blockedBy: string;
};

// truncate text to max n chars eventualli adding "..."
const truncText = (text: string | undefined, n: number) =>
  !text ? "" : text.length > n ? text.substring(0, n) + "..." : text;

interface IExpertisesListProps {
  items: PracticeE2List[] | undefined;
  removeWrapper?: boolean;
  gridArea?: string;
  isActive?: boolean;
  onRefresh: (status: StatusTypes) => void;
}

const ExpertizerList = (props: IExpertisesListProps) => {
  const dispatch = useDispatch();
  const { items, removeWrapper, gridArea, isActive, onRefresh } = props;
  const { t } = useTranslation();

  const { getAuthorization } = useAuthorization();

  const { activeRole } = useSelector((state: RootState) => state.user);
  const [retrivePracticesStatus] = useLazyGetPracticesStatusQuery();
  const [confirmPractice] = useAcceptPracticeAssignmentMutation();
  const [scheduleAppointment] = useCreatePracticeAppointmentMutation();
  const [retrievePractice] = useLazyGetPracticeDetailQuery();
  const [sendRejectedFeedback] = useSendRejectedFeedbackMutation();

  const [acceptingPracticeId, setAcceptingPracticeId] = useState<number>(0);
  const [schedulingAppointmentPracticeId, setSchedulingAppointmentPracticeId] =
    useState<number>(0);
  const [rejectingFeedbackPracticeId, setRejectingFeedbackPracticeId] =
    useState<number>(0);

  const [showAppointmentDialog, setShowAppointmentDialog] =
    useState<boolean>(false);

  const [updatedItems, setUpdatedItems] = useState<
    ExpertizerListItemType[] | undefined
  >(items as ExpertizerListItemType[]);

  const [practicesToMonitoringStatus, setPracticesToMonitoringStatus] =
    useState<number[]>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setUpdatedItems(items as ExpertizerListItemType[]);
  }, [items]);

  useEffect(() => {
    const pageItems = items?.slice(
      pagination.pageIndex * pagination.pageSize,
      (pagination.pageIndex + 1) * pagination.pageSize
    );

    const updatedPracticesToMonitor = (
      pageItems?.filter((item) => item.id !== undefined) || []
    ).map((item) => item.id as number);

    setPracticesToMonitoringStatus(updatedPracticesToMonitor);
  }, [items, pagination.pageIndex, pagination.pageSize]);

  const getClaimTypeValue = (code: string | undefined): string => {
    const iCode = parseInt(code || "");
    let prefix = "";

    if (iCode >= 2 && iCode <= 8)
      prefix = t("appraisals-dashboard-to-manage-cvt") + " - ";
    return (
      prefix + t(`appraisals-details-tab-details-claim-type-${code}`) || ""
    );
  };

  const data = useMemo(
    () =>
      (updatedItems as ExpertizerListItemType[])?.map((item) => {
        const status = t(`appraisal-statuses-${item.status}`) || "";

        return {
          id: item.id,
          priority: t(
            `expertizer-list-appraisal-priority-${item.priorityAppraiser}`
          )!,
          practiceNumber: item.practiceNumber || "",
          clientName: item.clientName || "",
          sxNumber: item.sxNumber || "",
          sxType: getClaimTypeValue(item.sxTypology),
          plate: item.plate || "",
          model: item.model || "",
          cap: item.appraisalLocationAddress?.postalCode,
          cityAndProvince: item.appraisalLocationAddress
            ? item.appraisalLocationAddress?.city +
              `(${item.appraisalLocationAddress?.province})`
            : "",
          expertDeadline: item.expertDeadline || "",
          status,
          statusCode: item.status,
          isBlocked: item.isBlocked,
          blockedBy: item.blockedBy,
          isViewed: item.isViewed ?? false,
          rejectionDate: item.rejectionDate || "",
          actions: "",
          practiceType: item.practicetypecode || "",
        } as ExpertizerResultItem;
      }) || [],
    [updatedItems]
  );

  const updatePracticesStatus = async () => {
    if (practicesToMonitoringStatus.length < 1) return;

    const response = await retrivePracticesStatus({
      authorization: await getAuthorization(),
      practices: practicesToMonitoringStatus,
      activeRole: activeRole!,
    });
    const statuses = response.data as PracticesProcessingStatus;

    if (response.isSuccess && statuses) {
      const _updatedItems = (
        (items as ExpertizerListItemType[])?.filter(
          (i) => i.id !== undefined
        ) || []
      ).map((item) => {
        const status = statuses.find((s) => s.practiceId === item.id);

        if (status === undefined) return item;

        return {
          ...item,
          status: status.practiceStatusCode,
          blockedBy: status.blockedBy || "",
          isBlocked: status.blockedBy ? status.blockedBy.length > 0 : false,
          isViewed: status.isViewed,
        };
      });

      setUpdatedItems(_updatedItems);
    }
  };

  const {
    restart: restartRefrehInterval,
    reset: resetRefrehInterval,
    clear: clearRefreshInterval,
    percentage,
    remainingTime,
  } = useInterval({
    seconds: 10,
    callback: updatePracticesStatus,
  });

  useEffect(() => {
    return () => clearRefreshInterval();
  }, []);

  useEffect(() => {
    if (isActive) restartRefrehInterval();
    else clearRefreshInterval();
  }, [isActive]);

  const handleShowScheduleAppointmentDialog = (id: number) => {
    setSchedulingAppointmentPracticeId(id);
    setShowAppointmentDialog(true);
  };

  const handleCancelScheduleAppointment = () => {
    setShowAppointmentDialog(false);
    setSchedulingAppointmentPracticeId(0);
  };

  const handleScheduleAppointment = async (
    practiceAppointment: PracticeAppointment
  ) => {
    setShowAppointmentDialog(false);

    // I don't have idAppraiser, I get it from practiceDetails
    const { data, error, isSuccess, status } = await retrievePractice({
      authorization: await getAuthorization(),
      id: schedulingAppointmentPracticeId,
      activeRole: activeRole!,
    });
    if (isSuccess && data) {
      const practiceDetails = data as ReadOnlyPractice;

      practiceAppointment.idAppraiser = practiceDetails?.appraiser?.id;
      practiceAppointment.idPractice = practiceDetails.id;

      const response = await scheduleAppointment({
        authorization: await getAuthorization(),
        activeRole: activeRole!,
        practiceAppointment,
      });

      const errorResponse = response as {
        error: {
          status: number;
          data: string;
        };
      };
      if (errorResponse && errorResponse.error) {
        setShowModal(true);
      }

      const updatedPractice = (
        response as {
          data: ReadOnlyPractice;
        }
      ).data as ReadOnlyPractice;

      if (updatedPractice && updatedItems) {
        const _updatedItems = updatedItems.map((practice) => {
          return practice.id === schedulingAppointmentPracticeId
            ? ({
                ...practice,
                status: updatedPractice.status,
                statusCode: updatedPractice.status,
              } as ExpertizerListItemType)
            : practice;
        });
        setUpdatedItems(_updatedItems);
        onRefresh("AF" as StatusTypes);
      }
    } else {
      console.log(error);
    }

    setSchedulingAppointmentPracticeId(0);
  };

  const handleSelectRow = (row: ExpertizerResultItem) => {
    dispatch(
      addTab({
        key: 0,
        label: row.practiceNumber,
        type: "appraisal-details",
        externalData: {
          idPractice: row.id,
        },
      })
    );
  };

  const handleLoadADZ = (row: ExpertizerResultItem) => {
    dispatch(
      addTab({
        key: 0,
        label: row.practiceNumber,
        type: "appraisal-details",
        externalData: {
          idPractice: row.id,
          showLoadADZ: true,
        },
      })
    );
  };

  const resultsColumns = useMemo<MRT_ColumnDef<ExpertizerResultItem>[]>(
    () => [
      {
        accessorKey: "priority",
        header: t("expertizer-dashboard-results-col-priority"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.priority}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "practiceNumber",
        header: t(
          "appraisals-dashboard-communications-list-header-id-assignment"
        ),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.practiceNumber}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "clientName",
        header: t("expertizer-dashboard-results-col-client-name"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            <div title={row.original.clientName}>
              {truncText(row.original.clientName, 5)}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "sxNumber",
        header: t("expertizer-dashboard-results-col-sx-number"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            <div title={row.original.sxNumber}>
              {truncText(row.original.sxNumber, 14)}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "sxType",
        header: t("expertizer-dashboard-results-col-sx-type"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.sxType}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "plate",
        header: t("expertizer-dashboard-results-col-plate"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.plate}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "model",
        header: t("expertizer-dashboard-results-col-model"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.model}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "cap",
        header: t("expertizer-dashboard-results-col-cap"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.cap}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "cityAndProvince",
        header: t("expertizer-dashboard-results-col-city-and-province"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.cityAndProvince}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "expertDeadline",
        header: t(
          "expertizer-dashboard-results-col-expertizer-expiration-date"
        ),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.expertDeadline}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "status",
        header: t("expertizer-dashboard-results-col-status"),
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.status}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "isBlocked",
        header: t("appraisals-dashboard-results-appraisals-is-blocked"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
          >
            {row.original.isBlocked ? (
              <BlockedWrapper title={row.original.blockedBy}>
                <IconLocked />{" "}
              </BlockedWrapper>
            ) : (
              <></>
            )}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "practiceType",
        header: t("expertizer-dashboard-results-col-practice-type"),
        Cell: ({ row }) => (
          <CellWrapper
            className={row.original.isBlocked ? "blocked-practice-row" : ""}
            onClick={() => handleSelectRow(row.original)}
          >
            {t(`expertizer-dashboard-results-col-practice-type-code-${row.original.practiceType}`)}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "actions",
        header: "",
        size: 100,
        enableSorting: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        Cell: ({ row }) =>
          row.original.isBlocked ? (
            <></>
          ) : (
            <ActionsWrapper>
              <RowActions
                item={row.original}
                onConfirm={handleConfirmPractice}
                onScheduleAppointment={handleShowScheduleAppointmentDialog}
                onLoadADZ={() => handleLoadADZ(row.original)}
                onRejectedFeedback={handleRejectFeedback}
                acceptingPracticeId={acceptingPracticeId}
                schedulingAppointmentPracticeId={
                  schedulingAppointmentPracticeId
                }
                rejectingFeedbackPracticeId={rejectingFeedbackPracticeId}
              />
            </ActionsWrapper>
          ),
      },
     
    ],
    [updatedItems, acceptingPracticeId, schedulingAppointmentPracticeId]
  );

  const handleConfirmPractice = async (id: number) => {
    setAcceptingPracticeId(id);

    const response = await confirmPractice({
      authorization: await getAuthorization(),
      idPractice: id || -1,
    });

    const updatedPractice = (
      response as {
        data: ReadOnlyPractice;
      }
    ).data as ReadOnlyPractice;

    if (updatedPractice && updatedItems) {
      const _updatedItems = updatedItems.map((practice) => {
        return practice.id === id
          ? ({
              ...practice,
              status: updatedPractice.status,
              statusCode: updatedPractice.status,
            } as ExpertizerListItemType)
          : practice;
      });

      setUpdatedItems(_updatedItems);
      onRefresh("CR" as StatusTypes);
    } else {
      // set error
    }
    setAcceptingPracticeId(0);
  };

  const handleRejectFeedback = async (id: number) => {
    setRejectingFeedbackPracticeId(id);

    const response = await sendRejectedFeedback({
      authorization: await getAuthorization(),
      activeRole: activeRole!,
      id: id,
    });

    const updatedPractice = (
      response as {
        data: ReadOnlyPractice;
      }
    ).data as ReadOnlyPractice;

    if (updatedItems) {
      const _updatedItems = updatedItems.map((practice) => {
        return practice.id === id
          ? ({
              ...practice,
              isViewed: updatedPractice.isViewed,
            } as ExpertizerListItemType)
          : practice;
      });

      setUpdatedItems(_updatedItems);

      onRefresh("RE" as StatusTypes);
    }

    setRejectingFeedbackPracticeId(0);
  };

  const renderTable = () => (
    <MaterialReactTable
      columns={resultsColumns}
      data={data}
      enableDensityToggle={false}
      enableColumnOrdering
      muiTableBodyCellProps={{
        sx: {
          textAlign: "left",
        },
      }}
      onPaginationChange={setPagination}
      state={{ pagination }}
    />
  );

  return (
    <>
      {removeWrapper ? (
        <ExpertizerListWithoutWrapper
          data-testid={testId.container}
          gridArea={gridArea}
        >
          {renderTable()}
        </ExpertizerListWithoutWrapper>
      ) : (
        <ExpertizerListWrapper
          data-testid={testId.container}
          gridArea={gridArea}
        >
          {renderTable()}
        </ExpertizerListWrapper>
      )}
      <Modal
        open={showAppointmentDialog}
        footer={null}
        onCancel={handleCancelScheduleAppointment}
        width="70%"
      >
        <ModalContent>
          <ScheduleAppointment
            onSchedule={handleScheduleAppointment}
            onCancel={handleCancelScheduleAppointment}
          />
        </ModalContent>
      </Modal>

      {showModal && (
        <Modal
          open={showModal}
          footer={null}
          onCancel={() => setShowModal(false)}
        >
          <ModalContentError>
            <div className="text">
              {t(
                "appraisals-details-tab-activities-collapsable-error-appoinment-popup"
              )}
            </div>
          </ModalContentError>
        </Modal>
      )}
    </>
  );
};

interface IRowActionsProps {
  item: ExpertizerResultItem;
  onConfirm: (params: any) => void;
  onScheduleAppointment: (params: any) => void;
  onLoadADZ: (params: any) => void;
  onRejectedFeedback: (params: any) => void;
  acceptingPracticeId: number;
  schedulingAppointmentPracticeId: number;
  rejectingFeedbackPracticeId: number;
}

const RowActions = (props: IRowActionsProps) => {
  const {
    item,
    onConfirm,
    onScheduleAppointment,
    onLoadADZ,
    onRejectedFeedback,
    acceptingPracticeId,
    schedulingAppointmentPracticeId,
    rejectingFeedbackPracticeId,
  } = props;
  const { t } = useTranslation();

  const status = item.statusCode;

  if (status === "PA") {
    if (acceptingPracticeId === item.id)
      return <div className="confirm-loader">{IconLoading}</div>;

    return (
      <Popconfirm
        placement="leftBottom"
        icon={null}
        title={t(
          "appraisals-details-tab-activities-collapsable-confirm-practice-label"
        )}
        onConfirm={() => onConfirm(item.id)}
        okText={
          <div data-testid={testId.buttonConfirmPractice}>
            {t("switch-yes")}
          </div>
        }
        cancelText={t("switch-no")}
      >
        <ButtonConfirm
          disabled={acceptingPracticeId > 0}
          size="small"
          onClick={() => {}}
          dataTestId={testId.buttonConfirmPracticeConfirmation}
        >
          {t("appraisals-list-button-confirm")!}
        </ButtonConfirm>
      </Popconfirm>
    );
  } else if (status === "AD") {
    if (schedulingAppointmentPracticeId === item.id)
      return <div className="confirm-loader">{IconLoading}</div>;

    return (
      <ButtonConfirm
        disabled={schedulingAppointmentPracticeId > 0}
        size="small"
        onClick={() => onScheduleAppointment(item.id)}
        dataTestId={testId.buttonSetAppointment}
      >
        {
          t(
            "appraisals-details-tab-activities-collapsable-button-schedule-appointment"
          )!
        }
      </ButtonConfirm>
    );
  } else if (status === "CP") {
    return (
      <ButtonConfirm
        disabled={schedulingAppointmentPracticeId > 0}
        size="small"
        onClick={() => onLoadADZ(item.id)}
      >
        {t("expertizer-dashboard-results-action-load-adz")!}
      </ButtonConfirm>
    );
  } else if (status === "RE" && !item.isViewed) {
    if (rejectingFeedbackPracticeId === item.id)
      return <div className="confirm-loader">{IconLoading}</div>;

    return (
      <ButtonConfirm
        disabled={rejectingFeedbackPracticeId > 0}
        size="small"
        onClick={() => onRejectedFeedback(item.id)}
      >
        {t("expertizer-dashboard-results-action-having-read")!}
      </ButtonConfirm>
    );
  }

  return <></>;
};

export default ExpertizerList;
