import { createContext, Dispatch, useContext, useReducer } from "react";
import {
  CreateClaimActions,
  CreateClaimData,
  CreateClaimReducer,
  initialCreateClaimState,
  LoadingStatusType,
} from "./CreateClaimReducer";
import { Branch } from "../../../../../../redux/apiSpecifications/apiPolicies";

export interface CreateClaimContextData {
  createClaimData?: CreateClaimData;
  openWizard: boolean;
  loadingStatus: LoadingStatusType;
  allBranches: Branch[];
  // onCreateClaim: (createClaimData: CreateClaimData, complete: boolean) => void;
}

const CreateClaimContext = createContext<CreateClaimContextData | undefined>(
  undefined
);

const CreateClaimDispatchContext = createContext<
  Dispatch<CreateClaimActions> | undefined
>(undefined);

interface ICreateClaimProviderProps {
  // onCreateClaim: (createClaimData: CreateClaimData, complete: boolean) => void;
  children: React.ReactNode;
}

const CreateClaimProvider: React.FC<ICreateClaimProviderProps> = ({
  // onCreateClaim,
  children,
}) => {
  const [state, dispatch] = useReducer(
    CreateClaimReducer,
    initialCreateClaimState
  );

  return (
    <CreateClaimContext.Provider
      value={{
        createClaimData: state.createClaimData,
        openWizard: state.openWizard,
        loadingStatus: state.loadingStatus,
        allBranches: state.allBranches,
        // onCreateClaim: onCreateClaim
        // selectionPolicyStep: state.selectionPolicyStep
      }}
    >
      <CreateClaimDispatchContext.Provider value={dispatch}>
        {children}
      </CreateClaimDispatchContext.Provider>
    </CreateClaimContext.Provider>
  );
};

export const useCreateClaimContext = (): CreateClaimContextData => {
  const context = useContext(CreateClaimContext);

  if (!context) {
    throw new Error("Errore");
  }

  return context;
};

export function useCreateClaimDispatch() {
  const context = useContext(CreateClaimDispatchContext);

  if (!context) {
    throw new Error("Errore");
  }

  return context;
}

export default CreateClaimProvider;
