import { useCallback, useEffect, useState } from "react";
import { AppraisalSubject } from "../../../../../../redux/apiSpecifications/apiCrud";
import { FormRow } from "../../../../../../style/form";
import { InputTextStyled, SwitchStyled } from "../../../../../../style/Input";
import { RowSpacer } from "../../../../../../style/containers";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SegmentedSubjectType from "../../../../../Inputs/SegmentedSubjectType";
import { SubjectType } from "../../../../../../config/const";
import InputVatNumber from "../../../../../Inputs/InputVatNumber";
import InputFiscalCode from "../../../../../Inputs/InputFiscalCode";
import {
  InputFieldValidation,
  formalValidationFiscalCode,
  validateVatNumber,
} from "../../../../../../validation/subjectValidation";
import {
  checkEmailFormat,
  checkTelephone,
} from "../../../../../../validation/common";
import { mainTabs } from "../../../../../../config/testIds";
import AddressAutocomplete from "../../../../../AzureMapsAddress/AddressAutocomplete";
import { Alert, Collapse } from "antd";
import { PanelStyled } from "../../../Commons/PanelHeaderWithButtons";

const testIds =
  mainTabs.tabs.appraisalDetails.collapsables.details.location.subjectModal;

const AppraisalSubjectDetailsWrapper = styled.div`
  .title {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;

    .same-as-owener {
      font-size: 0.9em;
    }

    .spacer {
      flex: 1;
    }
  }

  .main {
    padding-left: 1em;

    .contacts-message {
      padding-top: 2em;
      color: red;
    }
  }

  .ant-collapse-item {
    border: 1px solid #eee;
  }

  .ant-collapse-content-box {
    background-color: #fafafa;
  }

  .ant-collapse-header {
    padding: 0.25em 1em 0em !important;
  }
`;

const isNotEmptyOrNull = (str: string | undefined | null) =>
  !!str && str.length > 0;

const defaultValidationResult: InputFieldValidation = {
  validateStatus: "success",
  messageKey: "",
};

const defaultSubject = (subject: AppraisalSubject | undefined) => {
  if (!subject || !subject.subjectType)
    return { ...subject, subjectType: "PF" };
  return subject;
};

const highlightInput = {
  backgroundColor: "#ffffae",
};

interface IAppraisalSubjectDetailsProps {
  subject: AppraisalSubject | undefined;
  type: "owner" | "recipient" | "contact";
  onChange: (subject: AppraisalSubject, isValid: boolean) => void;
  isSameAsOwner?: boolean;
  onSetSameAsOwner?: (isSame: boolean) => void;
  practiceBlocked?: boolean;
  readonly?: boolean;
  outOfCoverageNote?: string;
  isIncorrectAddress?: boolean;
  roleActive: string;
  isPracticeInvoced?: boolean;
}

const AppraisalSubjectDetails = (props: IAppraisalSubjectDetailsProps) => {
  const {
    type,
    onChange,
    subject,
    isSameAsOwner,
    practiceBlocked,
    onSetSameAsOwner,
    readonly = false,
    roleActive,
    outOfCoverageNote,
    isIncorrectAddress,
    isPracticeInvoced,
  } = props;
  const { t } = useTranslation();

  const [emailValidation, setEmailValidation] = useState<InputFieldValidation>(
    defaultValidationResult
  );
  const [pecValidation, setPecValidation] = useState<InputFieldValidation>(
    defaultValidationResult
  );
  const [phoneValidation, setPhoneValidation] = useState<InputFieldValidation>(
    defaultValidationResult
  );
  const [fiscalCodeValidation, setFiscalCodeValidation] =
    useState<InputFieldValidation>(defaultValidationResult);
  const [pIvaValidation, setPIvaValidation] = useState<InputFieldValidation>(
    defaultValidationResult
  );

  const validateSubject = useCallback(
    (subject: AppraisalSubject | undefined) => {
      const { email, pec, phone } = subject || {};

      const neEmail = isNotEmptyOrNull(email);
      const nePec = isNotEmptyOrNull(pec);
      const nePhone = isNotEmptyOrNull(phone);

      const _emailValidation: InputFieldValidation =
        !neEmail || checkEmailFormat(email || "")
          ? defaultValidationResult
          : {
              validateStatus: "error",
              messageKey: t("subject-contact-invalid-email"),
            };

      const _pecValidation: InputFieldValidation =
        !nePec || checkEmailFormat(pec || "")
          ? defaultValidationResult
          : {
              validateStatus: "error",
              messageKey: t("subject-contact-invalid-pec"),
            };
      const _phoneValidation: InputFieldValidation =
        !nePhone || checkTelephone(phone || "")
          ? defaultValidationResult
          : {
              validateStatus: "error",
              messageKey: t("subject-contact-invalid-phone"),
            };

      let subjectBasicDatavalidated = false;
      let _validationFiscalCode: InputFieldValidation = {
        validateStatus: "",
        messageKey: "",
      };
      // if subject type is PF check fiscal code, if PG check pIva
      if (subject?.subjectType === "PF") {
        subjectBasicDatavalidated =
          isNotEmptyOrNull(subject?.name) &&
          isNotEmptyOrNull(subject?.lastname);

        _validationFiscalCode = formalValidationFiscalCode(
          subject?.fiscalCode || ""
        );

        subjectBasicDatavalidated =
          subjectBasicDatavalidated &&
          _validationFiscalCode.validateStatus === "success";

        setFiscalCodeValidation(
          formalValidationFiscalCode(subject?.fiscalCode || "")
        );
      } else if (subject?.subjectType === "PG") {
        subjectBasicDatavalidated = isNotEmptyOrNull(subject?.businessName);

        const _validationVatNumber = validateVatNumber(subject?.pIva || "");
        subjectBasicDatavalidated =
          subjectBasicDatavalidated &&
          _validationVatNumber.validateStatus === "success";
        setPIvaValidation(_validationVatNumber);
      }

      // if an email is present, check its format
      if (neEmail) {
        setEmailValidation(_emailValidation);
      } else {
        setEmailValidation(defaultValidationResult);
      }

      // if a pec is present, check its format
      if (nePec) {
        setPecValidation(_pecValidation);
      } else {
        setPecValidation(defaultValidationResult);
      }

      // if a phone is present, check its format
      if (nePhone) {
        setPhoneValidation(_phoneValidation);
      } else {
        setPhoneValidation(defaultValidationResult);
      }

      const thereIsContact =
        (neEmail && _emailValidation.validateStatus === "success") ||
        (nePec && _pecValidation.validateStatus === "success") ||
        (nePhone && _phoneValidation.validateStatus === "success");

      const isAllContactsValidOrEmpty =
        _emailValidation.validateStatus === "success" &&
        _pecValidation.validateStatus === "success" &&
        _phoneValidation.validateStatus === "success";

      return {
        thereIsContact,
        isValid:
          subjectBasicDatavalidated &&
          thereIsContact &&
          isAllContactsValidOrEmpty,
      };
    },
    []
  );

  const [contactValidation, setContactValidation] = useState<boolean>(false);

  useEffect(() => {
    const validationResult = validateSubject(subject);
    setContactValidation(validationResult.thereIsContact);
  }, [props.subject]);

  const handleOnChange = (value: any, field: string) => {
    if (field === "phone" && !/^[0-9\s]*$/.test(value)) return;

    let updatedSubject = { ...subject, [field]: value };

    if (field === "subjectType") {
      if (value === "PF") {
        updatedSubject = {
          ...updatedSubject,
          businessName: undefined,
          pIva: undefined,
        };
      } else if (value === "PG") {
        updatedSubject = {
          ...updatedSubject,
          name: undefined,
          lastname: undefined,
          fiscalCode: undefined,
        };
      }
    }

    const validationResult = validateSubject(updatedSubject);

    setContactValidation(validationResult.thereIsContact);
    onChange(updatedSubject, validationResult.isValid);
  };

  const isDisabled =
    (type === "owner" && !["D1", "D2", "E1"].includes(roleActive)) ||
    (type === "recipient" && !["D1", "D2", "E1", "F1"].includes(roleActive)) ||
    (type === "contact" &&
      !["D1", "D2", "E1", "E2", "E3"].includes(roleActive));

  const isPecDisabled =
    (type === "owner" && !["D1", "D2", "E1", "F1"].includes(roleActive)) ||
    (type === "recipient" && !["D1", "D2", "E1", "F1"].includes(roleActive)) ||
    (type === "contact" &&
      !["D1", "D2", "E1", "E2", "E3"].includes(roleActive));

  return (
    <AppraisalSubjectDetailsWrapper data-testid={""}>
      <Collapse defaultActiveKey={"open"}>
        <PanelStyled
          key={isSameAsOwner ? "close" : "open"}
          header={
            <div className="title">
              <div>
                {t(`appraisals-details-tab-details-owner-vehicle-${type}`)}
              </div>
              <div className="spacer"></div>
              {isSameAsOwner && (
                <div className="same-as-owener">
                  {t(
                    "appraisals-details-tab-details-owner-vehicle-same-as-owner"
                  )}
                </div>
              )}
            </div>
          }
        >
          <div className="main">
            <FormRow>
              <SegmentedSubjectType
                value={(subject?.subjectType as SubjectType) || "PF"}
                onChange={(type) => handleOnChange(type, "subjectType")}
                dataTestId={testIds.subjectType}
                readonly={isDisabled || isPracticeInvoced}
                required={true}
              />
              <RowSpacer />
              {subject?.subjectType === "PF" && (
                <>
                  <InputTextStyled
                    label={t("edit-subject-tab-data-name")}
                    placeholder={t("edit-subject-tab-data-name-placeholder")}
                    onChange={(name) => handleOnChange(name, "name")}
                    value={subject?.name}
                    disabled={practiceBlocked || readonly || isDisabled || isPracticeInvoced}
                    dataTestId={testIds.name}
                    required={true}
                  />
                  <RowSpacer />
                  <InputTextStyled
                    label={t("edit-subject-tab-data-lastname")}
                    placeholder={t(
                      "edit-subject-tab-data-lastname-placeholder"
                    )}
                    onChange={(lastname) =>
                      handleOnChange(lastname, "lastname")
                    }
                    value={subject?.lastname}
                    disabled={practiceBlocked || readonly || isDisabled || isPracticeInvoced}
                    required={true}
                    dataTestId={testIds.lastName}
                  />
                  <RowSpacer />
                  {/* <InputFiscalCode
                                        onChange={(fiscalCode) =>
                                            handleOnChange(
                                                fiscalCode.toUpperCase(),
                                                "fiscalCode"
                                            )
                                        }
                                        value={subject?.fiscalCode}
                                        validation={{
                                            state:
                                                fiscalCodeValidation.validateStatus ===
                                                "success",
                                            messageKey:
                                                fiscalCodeValidation.messageKey,
                                        }}
                                        readOnly={practiceBlocked || readonly}
                                        dataTestId={testIds.fiscalCode}
                                    /> */}
                  <div style={{ flex: 1 }}></div>
                </>
              )}

              {subject?.subjectType === "PG" && (
                <>
                  <InputTextStyled
                    label={`${t(
                      "edit-claim-tab-counterpart-owner-businessname-label"
                    )}`}
                    required={true}
                    placeholder={t(
                      "edit-claim-tab-counterpart-owner-businessname-placeholder"
                    )}
                    onChange={(businessname) =>
                      handleOnChange(businessname, "businessName")
                    }
                    value={subject?.businessName}
                    disabled={practiceBlocked || readonly || isPracticeInvoced}
                    dataTestId={testIds.businessName}
                  />
                  <RowSpacer />
                  {/* <InputVatNumber
                                        onChange={(pIva) =>
                                            handleOnChange(pIva, "pIva")
                                        }
                                        value={subject?.pIva}
                                        validation={{
                                            state:
                                                pIvaValidation.validateStatus ===
                                                "success",
                                            messageKey:
                                                pIvaValidation.messageKey,
                                        }}
                                        readOnly={practiceBlocked || readonly}
                                        dataTestId={testIds.pIva}
                                        required={true}
                                    /> */}
                  <div style={{ flex: 1 }}></div>
                  <RowSpacer />
                  <div style={{ flex: 1 }}></div>
                </>
              )}
            </FormRow>

            <FormRow>
              <InputTextStyled
                styleInput={contactValidation ? {} : highlightInput}
                label={`${t("subject-contact-type-E")}`}
                required={!contactValidation}
                placeholder={t("subject-contact-type-E")}
                onChange={(email) => handleOnChange(email, "email")}
                value={subject?.email}
                validateStatus={emailValidation.validateStatus}
                help={emailValidation.messageKey}
                disabled={practiceBlocked || readonly || isDisabled || isPracticeInvoced}
                dataTestId={testIds.email}
              />
              <RowSpacer />
              <InputTextStyled
                styleInput={contactValidation ? {} : highlightInput}
                label={`${t("subject-contact-type-P")}`}
                required={!contactValidation}
                placeholder={t("subject-contact-type-P")}
                onChange={(pec) => handleOnChange(pec, "pec")}
                value={subject?.pec}
                validateStatus={pecValidation.validateStatus}
                help={pecValidation.messageKey}
                disabled={practiceBlocked || readonly || isPecDisabled || isPracticeInvoced}
                dataTestId={testIds.pec}
              />

              <RowSpacer />
              <InputTextStyled
                styleInput={contactValidation ? {} : highlightInput}
                label={`${t("subject-contact-type-T")}`}
                required={!contactValidation}
                placeholder={t("subject-contact-type-T")}
                onChange={(phone) => handleOnChange(phone, "phone")}
                value={subject?.phone}
                validateStatus={phoneValidation.validateStatus}
                help={phoneValidation.messageKey}
                disabled={practiceBlocked || readonly || isDisabled || isPracticeInvoced}
                dataTestId={testIds.phone}
              />
              <RowSpacer />
              <div style={{ flex: 1 }}>
                <div className="contacts-message">
                  {!contactValidation &&
                    t("appraisal-subject-invalid-contact-message")}
                </div>
              </div>
            </FormRow>

            <FormRow>
              <AddressAutocomplete
                labelPlaceholder={t("subject-address-label")}
                required={true}
                placeholder={t("subject-address-place-placeholder")}
                address={subject?.address!}
                onChange={(addr) => handleOnChange(addr, "address")}
                readOnly={practiceBlocked || readonly || isDisabled || isPracticeInvoced}
                dataTestId={testIds.address}
                options={{civicMandatory: false}}
              />
            </FormRow>

            {isIncorrectAddress && (
              <FormRow>
                <div style={{ flex: 1 }}>
                  <Alert
                    message={t(
                      "appraisals-details-tab-details-incorrect-address"
                    )}
                    type="warning"
                  />
                </div>
              </FormRow>
            )}

            {outOfCoverageNote && (
              <FormRow>
                <div style={{ flex: 1 }}>
                  <Alert
                    message={t(
                      "appraisals-details-tab-details-appraisals-out-of-coverage-note",
                      {
                        notes: outOfCoverageNote,
                      }
                    )}
                    type="info"
                  />
                </div>
              </FormRow>
            )}
          </div>
        </PanelStyled>
      </Collapse>
    </AppraisalSubjectDetailsWrapper>
  );
};

export default AppraisalSubjectDetails;
