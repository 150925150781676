import { useTranslation } from "react-i18next";
import { GenderTypes } from "../../config/const";
import { SelectStyled } from "../../style/Input";
import { useEffect, useState } from "react";
import { RSAKeyPairOptions } from "crypto";
import { SelectPair } from "../../types/common.types";
import {
  ProvincesAndZones,
  useLazyGetAllCoverageProvincesAndZonesQuery,
} from "../../redux/apiSpecifications/apiFesf";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { useAuthorization } from "../../hooks/useAuthorization";

interface ISelectProvinceProps {
  classNameItem?: string;
  styleItem?: string;
  onChange: (province: string) => void;
  value: string | undefined;
  dataTestId?: string;
}

const SelectProvince = (props: ISelectProvinceProps) => {
  const { t } = useTranslation();

  const loadingOption = [
    {
      value: "",
      label: "select-province-loading",
    },
  ];
  const [options, setOptions] = useState<SelectPair[]>(loadingOption);
  const { getAuthorization } = useAuthorization();
  const [loadZones] = useLazyGetAllCoverageProvincesAndZonesQuery();

  useEffect(() => {
    loadProvinces();
  }, []);

  const loadProvinces = async () => {
    const response = await loadZones({
      authorization: await getAuthorization(),
    });
    if (response.isSuccess && response.data) {
      const correctData = response.data as ProvincesAndZones;
      const provincesOptions = correctData.provinces?.map(
        (province) =>
          ({
            value: province.districtCode,
            label: province.districtName,
          } as SelectPair)
      );

      let sortedProvincesOptions = provincesOptions?.sort((p1, p2) =>
        p1.label > p2.label ? 1 : p1.label < p2.label ? -1 : 0
      );

      setOptions(sortedProvincesOptions as SelectPair[]);
    }
  };

  const handleFilterSearchLabel = (input: string, option: any) => {
    const text = input.toLowerCase();
    return option.label.toLowerCase().includes(text) || false;
  };

  return (
    <SelectStyled
      classNameItem={props.classNameItem}
      styleItem={props.styleItem}
      label={t("select-province")}
      tooltip={t("select-province-tooltip")}
      onChange={(province) => props.onChange(province)}
      value={props.value}
      options={options}
      showSearch
      filterOption={handleFilterSearchLabel}
      dataTestId={props.dataTestId}
    />
  );
};

export default SelectProvince;
