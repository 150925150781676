import { Tooltip } from "antd";
import { IconHelp, IconInfo } from "../config/icons";
import { FaInfoCircle } from "react-icons/fa";
import styled from "styled-components";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  color: #bbb;
`;

interface ITooltipStyledProps {
  content: any;
}

const TooltipStyled: React.FC<ITooltipStyledProps> = ({ content }) => {
  return (
    <Tooltip title={content}>
      <IconWrapper>
        <FaInfoCircle />
      </IconWrapper>
    </Tooltip>
  );
};

export default TooltipStyled;
