import { useTranslation } from "react-i18next";
import { AppraisalTypeTypes } from "../../config/const";
import { SelectStyled } from "../../style/Input";
import { selectPracticeType as testId } from "../../config/testIds";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useMemo } from "react";

interface ISelectPracticeTypeProps {
  classNameItem?: string;
  styleItem?: string;
  onChange: (val: AppraisalTypeTypes) => void;
  value: AppraisalTypeTypes | undefined;
  disabled?: boolean | undefined;
  allowBlankItem?: boolean;
  dataTestId?: string;
}

const SelectPracticeType = (props: ISelectPracticeTypeProps) => {
  const { t } = useTranslation();

  const language = useSelector((state: RootState) => state.user.language);

  const options = useMemo(() => {
    const codes = Array.from(Array(9).keys()).map((_) => (_ + 1).toString());

    const result = codes.map((code) => ({
      value: code,
      label: t(
        `appraisals-details-tab-details-appraisals-expertise-type-${code}`
      ),
    }));

    if (props.allowBlankItem ?? false) {
      result.unshift({ value: "", label: "" });
    }

    return result;
  }, [language]);


  return (
    <SelectStyled
      classNameItem={props.classNameItem}
      styleItem={props.styleItem}
      label={t(
        "appraisals-details-tab-details-appraisals-expertise-type-label"
      )}
      onChange={(val) => props.onChange(val as AppraisalTypeTypes)}
      value={props.value}
      disabled={props.disabled}
      options={options}
      dataTestId={props.dataTestId || testId.container}
    />
  );
};

export default SelectPracticeType;
