// DD/MM/YYYY
export const formatDate = (date: Date) => {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    return `${day < 10 ? "0" : ""}${day}/${
        month < 10 ? "0" : ""
    }${month}/${year}`;
};

// Conevert date dd/MM/yyyy in yyyyMMdd
export const dateToNumber = (date?: string) => {
    return date?.split("/").reverse().join("");
};

// Conevert date dd/MM/yyyy hh:mm:ss in yyyyMMddhhmmss
export const dateTimeToNumber = (dateTime?: string) => {
    const dateTimeSplitted = dateTime!.trim().split(" ");

    if (dateTimeSplitted.length === 2) {
        const date = dateTimeSplitted[0];
        const time = dateTimeSplitted[1];

        return `${date.split("/").reverse().join("")}${time.replaceAll(
            ":",
            ""
        )}`;
    }

    return "";
};

//From dd/MM/yyyy to MM/dd/yyyy
export const stringDateFormat = (date?: string) => {
    const dateArray = date?.split("/");
    const day = dateArray ? dateArray[0].toLocaleString() : "";
    const month = dateArray ? dateArray[1].toLocaleString() : "";
    const year = dateArray ? dateArray[2].toLocaleString() : "";
    return `${month}/${day}/${year}`;
}
