import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { GenericWrapper } from "../Commons/Wrappers";
import AgendaBase, { DayContent } from "../../../Inputs/AgendaBase";
import moment, { Moment } from "moment";
import { useAuthorization } from "../../../../hooks/useAuthorization";
import { useTranslation } from "react-i18next";
import {
  AppraisersDailyAgenda,
  AppraisersMonthlyAgenda,
  useLazyGetAppraisersAgendaQuery,
} from "../../../../redux/apiSpecifications/apiFesf";
import { IconLoading } from "../../../../config/icons";
import { Input, Tooltip } from "antd";
import ProvinceList from "./ProvinceList";
import { RiFilterOffLine } from "react-icons/ri";
import dekra from "../../../../style/dekra";
import AppraisersList from "./AppraisersList";
import { LoadingFullScreenWrapper } from "../../../../style/DashbordWidgetWrappers";

//Styled components
const AgendaAppraisersWrapper = styled(GenericWrapper)`
  background-color: #fff;
  height: 52em;
  margin: 1em;
  display: grid;
  grid-gap: 1em 3em;
  grid-template-columns: auto 15em 2fr;
  grid-template-rows: 2.5em auto;
  grid-template-areas:
    "calendar search filter"
    "calendar provinces appraisers-list";

  .calendar-wrapper {
    grid-area: calendar;
  }

  .search-wrapper {
    grid-area: search;
  }

  .filter-wrapper {
    grid-area: filter;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1em;

    .label {
      text-transform: uppercase;
      font-size: 0.9em;
      letter-spacing: 1px;
    }
  }

  .provinces-wrapper {
    height: 100%;
    grid-area: provinces;
    display: flex;
    overflow: hidden;
    padding-bottom: 0.5em;
  }

  .appraisers-list-wrapper {
    grid-area: appraisers-list;
    height: 100%;
  }

  .appraiser-status-wrapper {
    grid-area: appraiser-status;
    height: 100%;
  }

  .day-content {
    .types-list {
      margin-left: 1em;
      display: flex;
      flex-direction: column;
      gap: 0.25em;
    }

    .availability-C1,
    .availability-C2,
    .availability-C3,
    .availability-C4,
    .availability-p {
      width: 3em;
      height: 1.25em;
      border-radius: 5px;
      cursor: pointer;
    }

    .availability-C1 {
      background-color: #002e55;
    }

    .availability-C2 {
      background-color: #ef7c00;
    }

    .availability-C3 {
      background-color: #ffcc00;
    }

    .availability-C4 {
      background-color: #64b32c;
    }

    .availability-p {
      background-color: #ddd;
    }
  }

  .clear-filters {
    cursor: pointer;
    font-size: 1.5em;
    top: 0.15em;
    position: relative;
  }
`;

const ToggleButtonWrapper = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.2em 0 0em 0;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? dekra.primaryColor : "#ddd")};
  color: ${(props) => (props.active ? "#fff" : "#333")};
`;

type status = "idle" | "loading" | "saving" | "error";

type SelectedDistrict = {
  date: string | undefined;
  districtCode: string | undefined;
  districtName: string | undefined;
  availabilityType: string | undefined;
};

type FiltersType = {
  term: string;
  C1: boolean;
  C2: boolean;
  C3: boolean;
  C4: boolean;
};

interface IAgendaAppraisersProps {}

const AgendaAppraisers = ({}: IAgendaAppraisersProps) => {
  // Common variables
  const { getAuthorization } = useAuthorization();
  const { t } = useTranslation();

  // Local state
  const [status, setStatus] = useState<status>("idle");
  const [currentDate, setCurrentDate] = useState<Moment>(moment());
  const [currentMonthYear, setCurrentMonthYear] = useState(
    moment([currentDate.year(), currentDate.month()])
  );
  const [currentAvailabilityType, setCurrentAvailabilityType] = useState<
    string | undefined
  >();
  const [days, setDays] = useState<AppraisersDailyAgenda[]>([]);
  const [daysContent, setDaysContent] = useState<DayContent[]>([]);
  const [refreshAgenda, setRefreshAgenda] = useState<number>(0);
  const [filters, setFilters] = useState<FiltersType>({
    term: "",
    C1: false,
    C2: false,
    C3: false,
    C4: false,
  });
  const [selectedDistrict, setSelectedDistrict] = useState<
    SelectedDistrict | undefined
  >();

  const [selectedAppraiser, setSelectedAppraiser] = useState<
    { id: number; name: string } | undefined
  >();

  // API
  const [loadAgenda] = useLazyGetAppraisersAgendaQuery();

  // Use Effects
  useEffect(() => {
    loadAgendaData();
  }, [refreshAgenda, currentMonthYear]);

  useEffect(() => {
    setCurrentMonthYear(moment([currentDate.year(), currentDate.month()]));
  }, [currentDate]);

  const loadAgendaData = async () => {
    setStatus("loading");

    const { data, isError, isSuccess } = await loadAgenda({
      authorization: await getAuthorization(),
      month: currentDate.format("MM/YYYY"),
    });

    if (isError || !isSuccess) {
      setStatus("error");
      return;
    }

    const { month, days = [] } = data as AppraisersMonthlyAgenda;
    setDays(days);

    setStatus("idle");
  };

  useEffect(() => {
    const today = moment();

    const _daysContent = days.map((d) => {
      const day = moment(d.date!, "DD/MM/YYYY");
      let filteredTypes =
        d.districts?.filter((d) =>
          d.districtName?.toLowerCase().includes(filters.term.toLowerCase())
        ) || [];

      if (filters.C1 || filters.C2 || filters.C3 || filters.C4) {
        filteredTypes = filteredTypes.filter((d) => {
          if (filters.C1 && d.availabilityType === "C1") return true;
          else if (filters.C2 && d.availabilityType === "C2") return true;
          else if (filters.C3 && d.availabilityType === "C3") return true;
          else if (filters.C4 && d.availabilityType === "C4") return true;

          return false;
        });
      }

      const uniqueTypes = new Set(
        filteredTypes.map((ds) => ds.availabilityType)
      );
      const types = Array.from(uniqueTypes);
      const pastType = day.isBefore(today, "day") ? "p" : undefined;

      const content = (
        <div className="types-list">
          {types.sort().map((type, i) => (
            <Tooltip
              key={i}
              placement="top"
              title={t(`day-content-availability-${type}`)}
            >
              <div
                key={i}
                className={`availability-${pastType || type}`}
                onClick={() => handleSelectDayType(type!, d.date!)}
              ></div>
            </Tooltip>
          ))}
        </div>
      );

      return {
        day,
        content,
      } as DayContent;
    });

    setDaysContent(_daysContent);
  }, [days, filters]);

  // Handlers
  const handleDayClick = (day: Moment) => {};

  const handleSelectDayType = (type: string, date: string) => {
    setCurrentDate(moment(date, "DD/MM/YYYY"));
    setCurrentAvailabilityType(type);
    setSelectedDistrict({
      date: currentDate.format("DD/MM/YYYY"),
      districtCode: undefined,
      districtName: undefined,
      availabilityType: currentAvailabilityType!,
    });
    setSelectedAppraiser(undefined);
  };

  const handleSelectProvince = (
    provinceCode: string,
    provinceName: string,
    date: string
  ) => {
    setSelectedDistrict({
      date: currentDate.format("DD/MM/YYYY"),
      districtCode: provinceCode,
      districtName: provinceName,
      availabilityType: currentAvailabilityType!,
    });
    setSelectedAppraiser(undefined);
  };

  const handleChangeFilters = (value: any, type: string) => {
    const newFilters = { ...filters, [type]: value };
    setFilters(newFilters);
  };

  const handleClearFilters = () => {
    setFilters({
      term: "",
      C1: false,
      C2: false,
      C3: false,
      C4: false,
    });

    setCurrentAvailabilityType(undefined);
    setSelectedDistrict(undefined);
    setSelectedAppraiser(undefined);
  };

  const currentDistrict = useMemo(() => {
    let filteredDistrict =
      days
        .find((d) => d.date === currentDate.format("DD/MM/YYYY"))
        ?.districts?.filter(
          (d) => d.availabilityType === currentAvailabilityType
        )
        .filter((d) =>
          d.districtName?.toLowerCase().includes(filters.term.toLowerCase())
        ) || [];

    if (filters.C1 || filters.C2 || filters.C3 || filters.C4) {
      filteredDistrict = filteredDistrict.filter((d) => {
        if (filters.C1 && d.availabilityType === "C1") return true;
        else if (filters.C2 && d.availabilityType === "C2") return true;
        else if (filters.C3 && d.availabilityType === "C3") return true;
        else if (filters.C4 && d.availabilityType === "C4") return true;

        return false;
      });
    }

    return filteredDistrict;
  }, [days, currentDate, currentAvailabilityType, filters]);

  return (
    <>
      {status === "loading" && (
        <LoadingFullScreenWrapper>
          <div className="box">{IconLoading}</div>
        </LoadingFullScreenWrapper>
      )}

      <AgendaAppraisersWrapper>
        <div className="calendar-wrapper">
          <AgendaBase
            currentDate={currentDate}
            daysContent={daysContent}
            dayMinHeight={"7em"}
            isDayClickable={false}
            onDayClick={handleDayClick}
            onDateChange={setCurrentDate}
          />
        </div>

        <div className="search-wrapper">
          <Input
            placeholder={t("agenda-search-placeholder")!}
            value={filters.term}
            onChange={(e) => handleChangeFilters(e.target.value, "term")}
          />
        </div>
        <div className="filter-wrapper">
          <div className="label">{t("agenda-filter-availability")}</div>
          <ToggleButtonWrapper
            active={filters.C1}
            onClick={() => handleChangeFilters(!filters.C1, "C1")}
          >
            {t("agenda-filter-availability-C1")!}
          </ToggleButtonWrapper>
          <ToggleButtonWrapper
            active={filters.C2}
            onClick={() => handleChangeFilters(!filters.C2, "C2")}
          >
            {t("agenda-filter-availability-C2")!}
          </ToggleButtonWrapper>
          <ToggleButtonWrapper
            active={filters.C3}
            onClick={() => handleChangeFilters(!filters.C3, "C3")}
          >
            {t("agenda-filter-availability-C3")!}
          </ToggleButtonWrapper>
          <ToggleButtonWrapper
            active={filters.C4}
            onClick={() => handleChangeFilters(!filters.C4, "C4")}
          >
            {t("agenda-filter-availability-C4")!}
          </ToggleButtonWrapper>

          <div className="clear-filters" onClick={handleClearFilters}>
            <RiFilterOffLine />
          </div>
        </div>

        <div className="provinces-wrapper">
          <ProvinceList
            districts={currentDistrict || []}
            date={currentDate.format("DD/MM/YYYY")}
            onSelect={handleSelectProvince}
          />
        </div>

        <div className="appraisers-list-wrapper">
          <AppraisersList
            date={selectedDistrict?.date}
            districtCode={selectedDistrict?.districtCode}
            districtName={selectedDistrict?.districtName}
            availabilityType={selectedDistrict?.availabilityType}
          />
        </div>
      </AgendaAppraisersWrapper>
    </>
  );
};

export default AgendaAppraisers;
