import { useTranslation } from "react-i18next";
import { SelectStyled } from "../../style/Input";
import { SubjectPropertyValue } from "../../types/subject-data.types";
import { SubjectType } from "../../config/const";
import { addressType as testId } from "../../config/testIds";
import { SubjectAddressDataType } from "../../types/uses-data.types";
import { SelectPair } from "../../types/common.types";

interface SubjectAddressTypeProps {
  classNameItem?: string;
  styleItem?: string;
  onChange: (val: SubjectPropertyValue) => void;
  type: SubjectAddressDataType | undefined;
  index: number;
  required?: boolean | undefined;
  subjectType: SubjectType;
  dataTestId?: string | undefined;
}

const SubjectAddressType = (props: SubjectAddressTypeProps) => {
  const { t } = useTranslation();
  const index = props.index;

  const options = [
    {
      value: "",
      label: t("subject-address-type-empty"),
    },
    {
      value: "L",
      label: t("subject-address-type-L"),
    },
    {
      value: "O",
      label: t("subject-address-type-O"),
    },
    {
      value: "R",
      label: t("subject-address-type-R"),
    },
    {
      value: "S",
      label: t("subject-address-type-S"),
    },
  ] as SelectPair[];

  const getTypeAddress = (type: string | undefined): string => {
    if (type == undefined) return "subject-address-type-empty";

    let typeAddress: string = "subject-address-type-";
    return type !== null ? typeAddress + type : "subject-address-type-empty";
  };

  return (
    <SelectStyled
      classNameItem={props.classNameItem}
      styleItem={props.styleItem}
      label={`${t("subject-address-type-label")} ${props.required ? "*" : ""}`}
      tooltip={t("subject-address-type-tooltip")}
      onChange={(type) => props.onChange({ value: type, index })}
      value={t(getTypeAddress(props.type))}
      options={options}
      dataTestId={props.dataTestId || testId.type}
    />
  );
};

export default SubjectAddressType;
