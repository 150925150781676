import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonConfirm } from "../../../../../Layout/Buttons";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";
import { Row } from "../../../../../../style/containers";
import AttachmentsPractice from "../../../../../Inputs/AttachmentsPractice";
import {
    AppraisalQuestionnaire,
    AttachmentFile,
    PracticeAttachment,
    PracticeDocument,
    PracticeDocumentsByCategory,
    UploadPracticeDocumentApiResponse,
    useDeleteDocumentAttachmentMutation,
    useFinalisePracticeMutation,
    useGenerateCongruityReportMutation,
    useLazyGetAllPracticeDocumentsQuery,
    useUploadPracticeDocumentMutation,
} from "../../../../../../redux/apiSpecifications/apiCrud";

import pdfIcon from "../../../../../../images/pdf-icon.png";
import { useAuthorization } from "../../../../../../hooks/useAuthorization";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { RefreshInTabWrapper } from "../../../../../../style/DashbordWidgetWrappers";
import { IconLoading } from "../../../../../../config/icons";
import { removeMimeType } from "../../../../../../utils/base64";
import { PostConcludePracticeStatuses } from "./PostConcludedPractice";
import { useNotifications } from "../../../../../../hooks/useNotifications";

// Styled components

const ContruityReportWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding-top: 2em;
`;

const DocumentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const DocumentsWrapper = styled.div`
    display: flex;
    gap: 2em;
    align-items: flex-end;

    .document-name {
    }
`;

const TextNoteWrapper = styled.div`
    flex: 1;
    margin-left: 5em;
`;

const PdfPlaceholder = styled.div<{ alignment: string }>`
    width: 100px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: ${(props) => props.alignment};

    img {
        height: 60px;
        width: 45px;
        opacity: 0.4;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1em;
`;

/**
 * @description
 * @interface ICongruityReportProps
 * @property {number} idPractice
 * @property {(appraisalQuestionnaire: AppraisalQuestionnaire) => void} concludePractice - Function to conclude the practice
 * @property {ConcludePracticeStatuses} status - Status of the practice
 */

type StatusType =
    | "idle"
    | "loading"
    | "saving"
    | "deleting"
    | "load-attachment-error"
    | "generate-report-error"
    | "finalizing-practice-error"
    | "practice-finalized";

interface ICongruityReportProps {
    idPractice: number | undefined;
    concludePractice: (appraisalQuestionnaire: AppraisalQuestionnaire) => void;
    status: PostConcludePracticeStatuses;
    isRevisionPracticeTab?: boolean;
}

/**
 * @component
 * @name CongruityReport
 * @description
 * @param {ICongruityReportProps} props
 * @returns {React.FC<ICongruityReportProps>}
 */

const CongruityReport: React.FC<ICongruityReportProps> = ({
    idPractice,
    concludePractice,
    status: externalStatus,
    isRevisionPracticeTab,
}) => {
    // COMMON VARIABLES
    const { t } = useTranslation();
    const { getAuthorization } = useAuthorization();
    const { displayNotification, displayFetchBaseQueryErrorNotification } =
        useNotifications();

    // Local state
    const [status, setStatus] = useState<
        StatusType | PostConcludePracticeStatuses
    >("idle");
    const [textNote, setTextNote] = useState<string>("");
    const [congruityAttachment, setCongruityAttachment] = useState<
        PracticeDocument | undefined
    >(undefined);
    const [congruityReport, setCongruityReport] = useState<
        PracticeDocument | undefined
    >(undefined);

    // API
    const [uploadDocument] = useUploadPracticeDocumentMutation();
    const [deleteDocument] = useDeleteDocumentAttachmentMutation();
    const [generateCongruityReport] = useGenerateCongruityReportMutation();
    const [getDocuments] = useLazyGetAllPracticeDocumentsQuery();
    const [finalizePractice] = useFinalisePracticeMutation();

    useEffect(() => {
        setStatus(externalStatus);
    }, [externalStatus]);

    useEffect(() => {
        if (idPractice || 0 > 0) loadDocuments();
    }, [idPractice]);

    // HANDLERS
    const handleGenerateReport = async () => {
        if (!idPractice) return;

        setStatus("saving");

        // remove previous congruity report
        setCongruityReport(undefined);

        const response = await generateCongruityReport({
            authorization: await getAuthorization(),
            id: idPractice,
            congruityReport: {
                filename: t("appraisal-details-congruity-report-filename")!,
                textNote: textNote,
            },
            version: isRevisionPracticeTab ? 2 : 1,
        });

        const json = ((response as any)?.data as any)?.value;
        const errorResponse = response as {
            error: FetchBaseQueryError | SerializedError;
        };

        if (json) {
            await loadDocuments();
            // const { data, isSuccess, isError } = await getDocuments({
            //   authorization: await getAuthorization(),
            //   id: idPractice,
            // });

            // if (isSuccess) {
            //   const congruityReport = (data as PracticeDocumentsByCategory).find(
            //     (d) => d.documentTypeCode === "RC"
            //   );

            //   setCongruityReport(congruityReport);
            // } else if (isError) {
            //   setStatus("generate-report-error");
            // }
        } else if (errorResponse.error) {
            setStatus("generate-report-error");

            displayFetchBaseQueryErrorNotification(errorResponse.error);
        }

        setStatus("idle");
    };

    const loadDocuments = async () => {
        if (!idPractice) return;

        const { data, isSuccess, isError } = await getDocuments({
            authorization: await getAuthorization(),
            id: idPractice,
        });

        if (isSuccess) {
            const congruityReport = (data as PracticeDocumentsByCategory).find(
                (d) => d.documentTypeCode === "RC" && d.version === 2
            );

            const congruityAttachment = (
                data as PracticeDocumentsByCategory
            ).find((d) => d.documentTypeCode === "ARC" && d.version === 2);

            setCongruityReport(congruityReport);
            setCongruityAttachment(congruityAttachment);
        } else if (isError) {
            setStatus("load-attachment-error");
        }
    };

    const handleFinalizePractice = async () => {
        concludePractice({});
    };

    const handleAddCongruityAttachment = async (
        practiceAttachment: PracticeAttachment
    ) => {
        if (!idPractice) return;

        setStatus("saving");

        const response = await uploadDocument({
            authorization: await getAuthorization(),
            id: idPractice,
            practiceDocument: {
                id: 0,
                categoryTypeCode: "A",
                name: t(
                    "appraisal-details-congruity-report-attachment-filename"
                ),
                documentTypeCode: "ARC",
                attachments: [
                    {
                        ...practiceAttachment,
                        base64File: removeMimeType(
                            practiceAttachment.base64File || ""
                        ),
                    } as AttachmentFile,
                ],
            } as PracticeDocument,
        });

        const correctResponse = response as { data: PracticeDocument[] };
        const errorResponse = response as {
            error: FetchBaseQueryError | SerializedError;
        };

        if (correctResponse.data) {
            const doc = correctResponse.data.find(
                (d) => d.documentTypeCode === "ARC"
            );
            setCongruityAttachment(doc);
        } else if (errorResponse.error) {
            setStatus("load-attachment-error");
        }

        setStatus("idle");
    };

    const handleRemoveCongruityReport = async () => {
        if (!idPractice) return;

        const attachments = congruityReport?.attachments || [];

        if (attachments[0].id == null) return;
        setStatus("deleting");

        const response = await deleteDocument({
            authorization: await getAuthorization(),
            id: idPractice,
            idAttachment: attachments[0].id,
        });

        const correctResponse = response as {
            data: UploadPracticeDocumentApiResponse;
        };

        const errorResponse = response as {
            error: FetchBaseQueryError | SerializedError;
        };

        if (correctResponse.data) {
            setCongruityReport(undefined);
        } else if (errorResponse) {
            setStatus("load-attachment-error");
        }

        setStatus("idle");
    };

    const handleRemoveCongruityAttachment = async () => {
        if (!idPractice) return;

        const attachments = congruityAttachment?.attachments || [];

        if (attachments[0].id == null) return;
        setStatus("deleting");

        const response = await deleteDocument({
            authorization: await getAuthorization(),
            id: idPractice,
            idAttachment: attachments[0].id,
        });

        const correctResponse = response as {
            data: UploadPracticeDocumentApiResponse;
        };

        const errorResponse = response as {
            error: FetchBaseQueryError | SerializedError;
        };

        if (correctResponse.data) {
            setCongruityAttachment(undefined);
        } else if (errorResponse) {
            setStatus("load-attachment-error");
        }

        setStatus("idle");
    };

    return (
        <>
            {["loading", "saving", "deleting", "finalizing-practice"].includes(
                status
            ) && (
                <RefreshInTabWrapper>
                    <div className="box">{IconLoading}</div>
                </RefreshInTabWrapper>
            )}
            <ContruityReportWrapper>
                <Row>
                    <DocumentsWrapper>
                        <DocumentWrapper>
                            {!congruityReport && (
                                <>
                                    <PdfPlaceholder
                                        alignment={
                                            congruityAttachment
                                                ? "center"
                                                : "flex-end"
                                        }
                                    >
                                        <img src={pdfIcon} />
                                    </PdfPlaceholder>
                                    {t(
                                        "appraisal-details-congruity-report-filename"
                                    )}
                                </>
                            )}
                            {congruityReport && (
                                <div className="document-name">
                                    <AttachmentsPractice
                                        onAddAttachment={() => {}}
                                        onRemoveAttachment={
                                            handleRemoveCongruityReport
                                        }
                                        attachments={
                                            congruityReport?.attachments || []
                                        }
                                        acceptedFormats={undefined}
                                        maxFiles={1}
                                        showFilename={true}
                                    />
                                </div>
                            )}
                        </DocumentWrapper>

                        <DocumentWrapper>
                            <AttachmentsPractice
                                onAddAttachment={handleAddCongruityAttachment}
                                onRemoveAttachment={
                                    handleRemoveCongruityAttachment
                                }
                                attachments={
                                    congruityAttachment?.attachments || []
                                }
                                acceptedFormats={
                                    "application/pdf, application/doc, application/docx, application/txt"
                                }
                                maxFiles={1}
                                showFilename={true}
                            />
                            {!(
                                (congruityAttachment?.attachments?.length ||
                                    0) > 0
                            ) && (
                                <div className="document-name">
                                    {t(
                                        "appraisal-details-congruity-report-attachment-filename"
                                    )}
                                </div>
                            )}
                        </DocumentWrapper>
                    </DocumentsWrapper>
                    <TextNoteWrapper>
                        <TextArea
                            rows={5}
                            onChange={(e) => setTextNote(e.target.value)}
                            value={textNote}
                            placeholder={
                                t(
                                    "appraisal-details-congruity-notes-placeholder"
                                )!
                            }
                        ></TextArea>
                    </TextNoteWrapper>
                </Row>
                <ButtonsWrapper>
                    <ButtonConfirm
                        onClick={handleGenerateReport}
                        disabled={textNote?.length < 5}
                    >
                        {t("appraisal-details-congruity-btn-generate-report")!}
                    </ButtonConfirm>
                    <ButtonConfirm
                        onClick={handleFinalizePractice}
                        disabled={!congruityReport}
                    >
                        {t("post-conclude-practice-button-send-client")!}
                    </ButtonConfirm>
                </ButtonsWrapper>
            </ContruityReportWrapper>
        </>
    );
};

export default CongruityReport;
