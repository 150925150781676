import {
  Claim as ClaimModel,
  DamagedPart,
} from "../redux/apiSpecifications/apiCrud";
import { ClaimAdditionalValidations } from "../redux/features/activeClaimSlice";
import {
  BaremsResultType,
  ClaimCounterpartModel,
  ClaimResponsabilityModel,
  ResponsabilityPercentageType,
} from "../types/claim.types";
import { AdditionalValidationField } from "../types/common.types";

import {
  checkDate,
  checkDateBetween,
  checkPlace,
  checkPlate,
  checkTime,
  isNotEmpty,
} from "./common";

export const validateClaimData = (
  claim: ClaimModel,
  previousValidations: ClaimAdditionalValidations,
  policyEffectDate: string | undefined,
  policyExpirationDate: string | undefined
): ClaimAdditionalValidations => {
  if (!claim) return previousValidations;

  const additionalValidations = {
    ...previousValidations,
    receiptDate: checkDate(claim.receiptDate),
    dateOfReceiptCompany: checkDate(claim.dateOfReceiptCompany),
    dateOfReceiptDekra: checkDate(claim.dateOfReceiptDekra),
    occurrenceDate: { state: true }, // checkDateBetween(claim.occurrenceDate, policyEffectDate, policyExpirationDate),
    occurrenceTime: checkTime(claim.occurrenceTime),
    occurrencePlace: checkPlace(claim.occurrencePlace),
    isClaimDataCompleted: false,
  } as ClaimAdditionalValidations;

  additionalValidations.isClaimDataCompleted =
    additionalValidations.receiptDate.state &&
    additionalValidations.dateOfReceiptCompany.state &&
    additionalValidations.dateOfReceiptDekra.state &&
    additionalValidations.occurrenceDate.state &&
    additionalValidations.occurrenceTime.state &&
    (additionalValidations.occurrencePlace?.state || false);

  return additionalValidations;
};

export const validateCounterpartData = (
  counterpart: ClaimCounterpartModel,
  previousValidations: ClaimAdditionalValidations
) => {
  if (!counterpart) return previousValidations;

  let additionalValidations = { ...previousValidations };

  additionalValidations.counterpartPlate = (
    checkPlate(counterpart.plate)
      ? { state: true }
      : { state: false, messageKey: "edit-claim-tab-counterpart-plate-invalid" }
  ) as AdditionalValidationField;

  if (counterpart.subjectType == "PF") {
    additionalValidations.isCounterpartCompleted =
      isNotEmpty(counterpart.ownerName) &&
      isNotEmpty(counterpart.ownerLastname) &&
      isNotEmpty(counterpart.insuranceCode) &&
      checkPlate(counterpart.plate);
  } else if (counterpart.subjectType == "PG") {
    additionalValidations.isCounterpartCompleted =
      isNotEmpty(counterpart.ownerBusinessName) &&
      isNotEmpty(counterpart.insuranceCode) &&
      checkPlate(counterpart.plate);
  } else {
    additionalValidations.isCounterpartCompleted = false;
  }

  return additionalValidations;
};

const checkBaremResult = (result: string) =>
  ["R", "C", "T"].indexOf(result) >= 0
    ? { state: true }
    : {
        state: false,
        messageKey: "edit-claim-tab-responsability-barems-result-missing",
      };

export const validateClaimResponsabilityData = (
  responsability: ClaimResponsabilityModel,
  previousValidations: ClaimAdditionalValidations
): ClaimAdditionalValidations => {
  if (!responsability) return previousValidations;

  const checkedBaremsResults = checkBaremResult(responsability?.barems?.result);

  const additionalValidations = {
    ...previousValidations,
    baremsResult: checkedBaremsResults,
    isResponsabilityCompleted: false,
  } as ClaimAdditionalValidations;

  additionalValidations.isResponsabilityCompleted = checkedBaremsResults.state;

  return additionalValidations;
};

const isEmptySubject = (p: DamagedPart) =>
  !(p.subject?.subjectType || p.subject?.subjectType == "");

const isEmptyRole = (p: DamagedPart) => !(p.role?.length && p.role?.length > 0);

const isEmptyDamages = (p: DamagedPart) =>
  !(p.damages?.length && p.damages?.length > 0);

const hasVehicleWithoutCollisionPoints = (part: DamagedPart) => {
  const vehicle = part.damages?.find((d) => d.type === "V");
  if (!vehicle) return false;

  return vehicle.damagedVehicle?.collisionPoints?.length === 0;
};

const hasPersonWithoutCollisionWounds = (part: DamagedPart) => {
  const person = part.damages?.find((d) => d.type === "P");
  if (!person) return false;

  return person.damagedPerson?.woundedParts?.length === 0;
};

export const validateDamagedParts = (
  damagedParts: DamagedPart[],
  previousValidations: ClaimAdditionalValidations
) => {
  const additionalValidations = {
    ...previousValidations,
    isDamagedPartsCompleted: true,
  };

  damagedParts.forEach((part) => {
    if (
      isEmptySubject(part) ||
      isEmptyRole(part) ||
      isEmptyDamages(part) ||
      hasVehicleWithoutCollisionPoints(part) ||
      hasPersonWithoutCollisionWounds(part)
    ) {
      additionalValidations.isDamagedPartsCompleted = false;
    }
  });

  return additionalValidations;
};

export const getResponsabilityPercentage = (
  barems: BaremsResultType | undefined,
  forcedReason: string | undefined
): ResponsabilityPercentageType => {
  if (!barems) return "";

  if (barems.result === "R") return "0%";

  if (barems.result === "C") return "50%";

  if (barems.result === "T") {
    if (["1", "2"].indexOf(forcedReason || "0") >= 0) return "50%";
    else return "100%";
  }

  return "";
};

export const getResponsabilityType = (
  barems: BaremsResultType | undefined,
  forcedReason: string | undefined
): string => {
  if (!barems) return "---";

  if (barems.result === "R")
    return "edit-claim-tab-responsability-type-responsability-R";

  if (barems.result === "C")
    return "edit-claim-tab-responsability-type-responsability-C";

  if (barems.result === "T") {
    if (["1", "2"].indexOf(forcedReason || "0") >= 0)
      return "edit-claim-tab-responsability-type-responsability-C";
    else return "edit-claim-tab-responsability-type-responsability-T";
  }

  return "---";
};
