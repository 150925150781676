import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal, Tooltip } from "antd";
import { ButtonCancel, ButtonConfirm } from "../../../Layout/Buttons";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  LoadingInTabWrapper,
  RefreshInTabWrapper,
} from "../../../../style/DashbordWidgetWrappers";
import { IconLoading } from "../../../../config/icons";
import {
  TenantRatesByTypeHist,
  TenantRatesByTypeHistItem,
  useGetTenantRatesHistoryQuery,
  useLazyGetTenantRatesHistoryQuery,
} from "../../../../redux/apiSpecifications/apiFesf";
import { useAuthorization } from "../../../../hooks/useAuthorization";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { CellWrapper } from "../../../../style/CellWrapper";
import { dateTimeToNumber, dateToNumber } from "../../../../utils/date";
import { DatePickerStyled } from "../../../../style/Input";

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  .message {
    font-size: 1.2em;
  }
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 4em 3em 2em 3em;
  gap: 1em;

  .title {
    font-size: 1.4em;
    margin-bottom: 1em;
    text-align: center;
  }

  div.history-wrapper {
    width: 100%;

    .MuiPaper-root {
      box-shadow: none;
      width: 100%;
    }

    .MuiToolbar-root > .MuiBox-root {
      justify-content: flex-start;
    }

    th.MuiTableCell-root {
      padding: 0;
    }

    td.MuiTableCell-root {
      padding: 1em 0;
    }

    .MuiTableRow-head {
      .MuiInputBase-root {
        input {
          margin-left: 5px;
        }
      }
    }
  }

  .buttons {
    margin-top: 2em;
  }
`;

type LoadingStatus = "idle" | "loading" | "refreshing" | undefined;

/**
 * RatesClientModalHistory properties
 * @property {number} tenantId - tenant's id
 * @property {string} tenantName - name of the tenant
 * @property {() => void} onClose - callback to close the modal from the parent
 */
interface IRatesClientModalHistoryProps {
  tenantId: number;
  tenantName: string;
  onClose: () => void;
}

/**
 * @description Show the change in the clients' rates
 * @param {IRatesClientModalHistoryProps} props - component properties
 */
const RatesClientModalHistory = ({
  tenantId,
  tenantName,
  onClose,
}: IRatesClientModalHistoryProps) => {
  //Common variables
  const { t } = useTranslation();
  const activeRole = useSelector((state: RootState) => state.user.activeRole);
  const getAuthorization = useAuthorization().getAuthorization;

  //Local state
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  // const [ratesCode, setRatesCode] = useState<string[]>([]);
  const [tenantRatesHistory, setTenantRatesHistory] = useState<
    TenantRatesByTypeHist | undefined
  >();
  const [validityDate, setValidityDate] = useState<string | undefined>(
    undefined
  );

  // //Fetching data
  const [getTenantRatesHistory] = useLazyGetTenantRatesHistoryQuery();

  // useEffects
  useEffect(() => {
    (async () => {
      // setIsLoading(true);
      setLoadingStatus((_setLoadingStatus) => {
        return _setLoadingStatus === undefined ? "loading" : "refreshing";
      });

      const response = await getTenantRatesHistory({
        authorization: await getAuthorization(),
        id: tenantId,
        activeRole: activeRole!,
        validityDate: validityDate,
      });

      if (response.data) {
        setTenantRatesHistory(response.data);
      }

      // setIsLoading(false);
      setLoadingStatus("idle");
    })();
  }, [tenantId, validityDate]);

  // conditional content
  // const showLoader = isLoading;
  // const showError = !isLoading && error;
  // const showContent = !isLoading && !error && tenantRatesHistory;

  return (
    <Modal open={true} onCancel={onClose} footer={null} width={"auto"}>
      {loadingStatus === "loading" && (
        <LoadingInTabWrapper>
          <div
            className="box"
            data-testid={""}
            style={{ marginBottom: "10em" }}
          >
            {IconLoading}
          </div>
        </LoadingInTabWrapper>
      )}

      {error && (
        <ErrorWrapper>
          <div className="message">
            {t("rate-clients-history-modal-error-message")}
          </div>
        </ErrorWrapper>
      )}

      {(loadingStatus === "refreshing" || loadingStatus === "idle") && (
        <>
          {/* {showContent && ( */}
          <ModalContent
            name={tenantName}
            history={tenantRatesHistory}
            validityDate={validityDate}
            onDateChange={(value) => {
              setValidityDate(value);
            }}
            onClose={onClose}
            data-testid={""}
          />
          {/* )} */}

          {loadingStatus === "refreshing" && (
            <RefreshInTabWrapper>
              <div className="box">{IconLoading}</div>
            </RefreshInTabWrapper>
          )}
        </>
      )}
    </Modal>
  );
};

type TenantRatesHistoryListItem = TenantRatesByTypeHistItem & {
  rateTypeDescription: string;
};

type IModalContentProps = {
  name: string | undefined;
  history: TenantRatesByTypeHistItem[] | undefined;
  validityDate?: string | undefined;
  onDateChange: (value: string | undefined) => void;
  onClose: () => void;
};

const ModalContent = ({
  name,
  history,
  validityDate,
  onDateChange,
  onClose,
}: IModalContentProps) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const items = useMemo(
    () =>
      history?.map((item) => {
        return {
          ...item,
          modifyUser: item.modifyUser ?? "",
          validityEndDate:
            item.validityEndDate === "31/12/9999"
              ? t(
                  "rate-clients-history-list-validity-end-date-currently-valid-text"
                )
              : item.validityEndDate,
              rateTypeDescription: t(
            `rate-name-label-${item.rateTypeCode!}`
          ),
        } as TenantRatesHistoryListItem;
      }) || [],
    [history]
  );

  const columns = useMemo<MRT_ColumnDef<TenantRatesHistoryListItem>[]>(() => {
    return [
      {
        accessorKey: "validityStartDate",
        header: t(
          "rate-clients-history-list-validity-start-date-column-header"
        ),
        size: 100,
        filterFn: "contains",
        sortingFn: "SortingDateFn",
        Cell: ({ row }) => (
          <CellWrapper>{row.original.validityStartDate}</CellWrapper>
        ),
      },
      {
        accessorKey: "validityEndDate",
        header: t("rate-clients-history-list-validity-end-date-column-header"),
        size: 100,
        filterFn: "contains",
        sortingFn: "SortingDateFn",
        Cell: ({ row }) => (
          <CellWrapper>{row.original.validityEndDate}</CellWrapper>
        ),
      },
      {
        accessorKey: "rateTypeDescription",
        header: t("rate-clients-history-list-rate-type-column-header"),
        size: 100,
        filterFn: "contains",
        Cell: ({ row }) => (
          <CellWrapper>{row.original.rateTypeDescription}</CellWrapper>
        ),
      },
      {
        accessorKey: "rateTypeBaseRateAmount",
        header: t(
          "rate-clients-history-list-rate-type-base-rate-amount-column-header"
        ),
        size: 100,
        filterFn: "contains",
        Cell: ({ row }) => (
          <CellWrapper>{row.original.rateTypeBaseRateAmount}€</CellWrapper>
        ),
      },
      {
        accessorKey: "rateTypePenaltyPerc",
        header: t(
          "rate-clients-history-list-rate-type-penalty-perc-column-header"
        ),
        size: 100,
        filterFn: "contains",
        Cell: ({ row }) => (
          <CellWrapper>{row.original.rateTypePenaltyPerc}%</CellWrapper>
        ),
      },
      {
        accessorKey: "modifyUser",
        header: t("rate-clients-history-list-modify-user-column-header"),
        size: 100,
        filterFn: "contains",
        Cell: ({ row }) => <CellWrapper>{row.original.modifyUser}</CellWrapper>,
      },
      {
        accessorKey: "insertDateTime",
        header: t("rate-clients-history-list-insert-date-time-column-header"),
        size: 100,
        filterFn: "contains",
        sortingFn: "SortingDateTimeFn",
        Cell: ({ row }) => (
          <CellWrapper>{row.original.insertDateTime}</CellWrapper>
        ),
      },
    ];
  }, [history]);

  return (
    <ModalContentWrapper>
      <div className="title">
        {t("rate-clients-history-modal-title")} {name}
      </div>

      <div className="date">
        <DatePickerStyled
          label={t("LABELS.validity-date")}
          placeholder={"gg-mm-aaaa"}
          value={validityDate}
          onChange={onDateChange}
        />
      </div>

      <div className="history-wrapper">
        <MaterialReactTable
          columns={columns}
          data={items}
          enableDensityToggle={false}
          enableColumnOrdering
          onPaginationChange={setPagination}
          state={{ pagination }}
          autoResetPageIndex={false}
          sortingFns={{
            //will add a new sorting function to the list of other sorting functions already available
            SortingDateFn: (rowA, rowB, columnId) => {
              const rowAValue: string = rowA.getValue(columnId);
              const rowBValue: string = rowB.getValue(columnId);

              if (rowAValue === rowBValue) return 0;

              if (
                rowAValue ===
                t(
                  "rate-clients-history-list-validity-end-date-currently-valid-text"
                )
              ) {
                return 1;
              } else if (
                rowBValue ===
                t(
                  "rate-clients-history-list-validity-end-date-currently-valid-text"
                )
              ) {
                return -1;
              } else {
                const rowAValueNumber = dateToNumber(rowAValue);
                const rowBValueNumber = dateToNumber(rowBValue);

                return rowAValueNumber! > rowBValueNumber!
                  ? 1
                  : rowAValueNumber! < rowBValueNumber!
                  ? -1
                  : 0;
              }
            },
            SortingDateTimeFn: (rowA, rowB, columnId) => {
              const rowAValueNumber = dateTimeToNumber(rowA.getValue(columnId));
              const rowBValueNumber = dateTimeToNumber(rowB.getValue(columnId));

              return rowAValueNumber! > rowBValueNumber!
                ? 1
                : rowAValueNumber! < rowBValueNumber!
                ? -1
                : 0;
            },
          }}
        />
      </div>

      <div className="buttons">
        <ButtonConfirm onClick={() => onClose()}>
          {t("button-close")!}
        </ButtonConfirm>
      </div>
    </ModalContentWrapper>
  );
};

export default RatesClientModalHistory;
