import React, { ChangeEvent, useMemo } from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Popconfirm } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { mainTabs } from "../../../../../config/testIds";
import {
  PracticeNote,
  useLazyGetPracticeNotesQuery,
} from "../../../../../redux/apiSpecifications/apiCrud";
import dekra from "../../../../../style/dekra";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import {
  LoadingInTabWrapper,
  WidgetWrapper,
} from "../../../../../style/DashbordWidgetWrappers";
import {
  IconLoading,
  IconModified,
  IconPracticesRejected,
} from "../../../../../config/icons";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import { FormRow, FormTextArea } from "../../../../../style/form";
import { useAuthorization } from "../../../../../hooks/useAuthorization";

const testIds = mainTabs.tabs.activities.note;

const ContentWrapper = styled.div`
  position: relative;

  .rowNote {
    padding: 0.5rem;
    justify-content: space-between;

    .textArea {
      width: 50%;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 1em;

      .buttonStyle {
        background: ${dekra.primaryColor};
        border-radius: 10px;
        color: #fff;
        text-transform: uppercase;
        padding: 0px 2rem;
      }
    }
  }

  .actionButtonRow {
    .icon-wrapper {
      width: 35px;
      height: 35px;
      font-size: 1.4em;
      margin: 0.3rem;
      cursor: pointer;
      text-align: center;
      display: inline-block;

      path {
        stroke: ${dekra.primaryColor};
      }
    }

    .iconDelete {
      path {
        color: ${dekra.primaryColor};
      }
    }
  }

  .positionLoading {
    position: absolute;
  }
`;

const ModalContent = styled.div`
  padding: 10px 50px;
  height: auto;

  .textAreaEdit {
    height: 300px;
  }`;

const ListNote = styled(WidgetWrapper)`
  align-items: flex-start;
  max-width: 100%;
  overflow: auto;
  padding: 2em;

  .MuiPaper-root {
    box-shadow: none;
    width: 100%;
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiTableCell-root {
    padding: 0;
  }

  .textNote {
    overflow-wrap: break-word;
    word-break: break-word;
    padding: 10px;
  }
`;

const CellWrapper = styled.div`
  cursor: pointer;
  padding: 1em 0;
`;

interface IAppraisalNoteProps {
  idPractice: number;
  onSave: (val: PracticeNote, notes: PracticeNote[]) => void;
  onUpdate: (val: PracticeNote, notes: PracticeNote[]) => void;
  OnDelete: (val: number) => void;
  historyNote?: PracticeNote[];
  isPracticeInvoiced?: boolean;
}

const AppraisalNote = (props: IAppraisalNoteProps) => {
  const { t } = useTranslation();

  const { idPractice, onSave, onUpdate, OnDelete, historyNote } = props;

  const defaultNote: PracticeNote = {};

  const [notes, setNotes] = useState<PracticeNote[]>(historyNote || []);
  const [retrieveNotesData] = useLazyGetPracticeNotesQuery();
  const [addedNote, setAddedNote] = useState<PracticeNote>(defaultNote);
  const [disableButton, setDisableButton] = useState<string>("");

  const { getAuthorization } = useAuthorization();

  const { userDetails } = useSelector((state: RootState) => state.user) || {};

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState<string | undefined>("");
  const [rowEdit, setRowEdit] = useState<PracticeNote>(defaultNote);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sortDate = (arr: PracticeNote[]): PracticeNote[] => {
    let arrSort: PracticeNote[];
    arrSort = arr?.sort((a, b) => {
      let aa = a?.dateModify?.split("/").reverse().join(),
        bb = b?.dateModify?.split("/").reverse().join();
      return aa! > bb! ? -1 : aa! < bb! ? 1 : 0;
    });

    return arrSort;
  };

  useEffect(() => {
    const sortHistoryNote = sortDate(historyNote || []);
    setNotes(sortHistoryNote || []);
  }, [historyNote]);

  useEffect(() => {
    if (
      (historyNote !== undefined && historyNote!.length !== 0) ||
      idPractice === undefined
    )
      return;

    (async () => {
      setIsLoading(true);
      const { data, error, isSuccess } = await retrieveNotesData({
        authorization: await getAuthorization(),
        idPractice,
      });
      if (isSuccess && data) {
        const arrayForSort = [...(data as PracticeNote[])];
        const sortDataDate = sortDate(arrayForSort);
        setNotes(sortDataDate);
      } else {
        console.log(error);
      }
      setIsLoading(false);
    })();
  }, [idPractice]);

  const handleOnChange = (value: ChangeEvent<HTMLTextAreaElement>) => {
    setDisableButton(value.currentTarget.value);

    const newNote: PracticeNote = {
      textNote: value.currentTarget.value,
      dateModify: getCurrentDate(),
      idUser: userDetails?.id,
      userName: userDetails?.firstname + " " + userDetails?.surname,
      id: 0,
      idPractice: idPractice,
    };

    setAddedNote(newNote);
  };

  const handleOnChangeNota = (e: any) => {
    setModalText(e.target.value);
  };

  const handleOnSave = async () => {
    await onSave(addedNote, notes);
    setAddedNote(defaultNote);
    setDisableButton("");
  };

  const handleOnUpdate = async (row: PracticeNote) => {
    setRowEdit(row);
    notes?.forEach((n: PracticeNote) => {
      if (n?.id === row?.id) {
        setModalText(n.textNote);
      }
    });

    setOpen(true);
  };

  const confirmAddedNote = () => {
    handleOnSave();
  };

  const confirmDeleteNote = (id: number) => {
    OnDelete(id);
  };

  const dataNote = useMemo(
    () =>
      notes?.map(
        (item: PracticeNote) =>
          ({
            id: item.id,
            idUser: item.idUser,
            idPractice: item.idPractice,
            userName: item.userName,
            textNote: item.textNote,
            dateModify: item.dateModify,
          } as PracticeNote)
      ) || [],
    [notes]
  );

  const resultsColumns = useMemo<MRT_ColumnDef<PracticeNote>[]>(
    () => [
      {
        accessorKey: "userName",
        header: t("appraisal-note-tab-collapse-user"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => {}}>{row.original.userName}</CellWrapper>
        ),
      },
      {
        accessorKey: "textNote",
        header: t("appraisal-note-tab-collapse-nota"),
        size: 300,
        Cell: ({ row }) => (
          <CellWrapper className="textNote" onClick={() => {}}>
            {row.original.textNote}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "dateModify",
        header: t("appraisal-note-tab-collapse-data-edit"),
        size: 50,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => {}}>
            {row.original.dateModify}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "action",
        header: t("appraisal-note-tab-collapse-handle-nota"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => {}}>
            {row.original.idUser === userDetails?.id && (
              <div className="actionButtonRow">
                <div
                  className="icon-wrapper"
                  onClick={() => handleOnUpdate(row.original)}
                  data-testid={testIds.collapsables.notes.buttonEdit}
                >
                  <IconModified />
                </div>
                <Popconfirm
                  title={t(
                    "appraisal-note-tab-collapse-description-button-delete"
                  )}
                  onConfirm={() => confirmDeleteNote(row.original.id as number)}
                  okText={t("switch-yes")}
                  cancelText={t("switch-no")}
                >
                  <div
                    className="icon-wrapper iconDelete"
                    data-testid={testIds.collapsables.notes.buttonDelete}
                  >
                    <IconPracticesRejected />
                  </div>
                </Popconfirm>
              </div>
            )}
          </CellWrapper>
        ),
      },
    ],
    [notes]
  );

  const handleOk = () => {
    const updateNota: PracticeNote = {
      id: rowEdit.id,
      idUser: rowEdit.idUser,
      idPractice: rowEdit.idPractice,
      userName: rowEdit.userName,
      textNote: modalText,
      dateModify: getCurrentDate(),
    };

    onUpdate(updateNota, notes);

    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const getCurrentDate = (): string => {
    let currentDate = new Date();
    const yyyy = currentDate.getFullYear();
    let mm: any = currentDate.getMonth() + 1;
    let dd: any = currentDate.getDate();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    const formattedToday = dd + "/" + mm + "/" + yyyy;
    return formattedToday;
  };

  return (
    <ContentWrapper data-testid={testIds.container}>
      <FormRow className="rowNote">
        <FormTextArea
          className="textArea"
          onChange={(value) => handleOnChange(value)}
          placeholder={
            t("appraisal-note-tab-collapse-placeholder-textarea") as string
          }
          value={addedNote?.textNote}
          data-testid={testIds.collapsables.notes.textArea}
          disabled={props.isPracticeInvoiced}
        />
        <div className="buttons">
          <Popconfirm
            title={t("appraisal-note-tab-collapse-description-button-insert")}
            onConfirm={confirmAddedNote}
            okText={t("switch-yes")}
            cancelText={t("switch-no")}
          >
            <ButtonConfirm
              className="buttonStyle"
              disabled={disableButton === "" || props.isPracticeInvoiced}
              onClick={() => {}}
            >
              {t("appraisals-note-tab-collapse-button-added-note") || ""}
            </ButtonConfirm>
          </Popconfirm>
        </div>
      </FormRow>

      <ListNote data-testid={testIds.collapsables.notes.table}>
        <MaterialReactTable
          columns={resultsColumns}
          data={dataNote}
          enableDensityToggle={false}
          enableColumnOrdering
          muiTableBodyCellProps={{
            sx: {
              textAlign: "left",
            },
          }}
          onPaginationChange={setPagination}
          state={{ pagination }}
        />
      </ListNote>

      <Modal
        title={t("appraisal-note-tab-collapse-modal-title")}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ModalContent>
          <FormTextArea
            className="textAreaEdit"
            onChange={(event) => handleOnChangeNota(event)}
            value={modalText}
          />
        </ModalContent>
      </Modal>
      {isLoading && (
        <LoadingInTabWrapper
          className="positionLoading"
          data-testid={testIds.collapsables.notes.loadingContainer}
        >
          <div className="box">{IconLoading}</div>
        </LoadingInTabWrapper>
      )}
    </ContentWrapper>
  );
};

export default AppraisalNote;
