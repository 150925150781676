import azureConfigValues from "./azureConfigValues";

const { local, sandbox, dev, test, prod } = azureConfigValues;

const checkUrl = (env: string[]) =>
  env.filter((eu) => window.location.origin.indexOf(eu) > -1).length > 0;

export const getMsalConfig = () => {
  if (checkUrl(local.urls))
    return {
      auth: {
        clientId: local.clientId,
        authority: local.authority,
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
      },
      color: local.color,
    };

  if (checkUrl(sandbox.urls))
    return {
      auth: {
        clientId: sandbox.clientId,
        authority: sandbox.authority,
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
      },
      color: sandbox.color,
    };

  if (checkUrl(dev.urls))
    return {
      auth: {
        clientId: dev.clientId,
        authority: dev.authority,
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
      },
      color: dev.color,
    };

  if (checkUrl(test.urls))
    return {
      auth: {
        clientId: test.clientId,
        authority: test.authority,
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
      },
      color: test.color,
    };

  if (checkUrl(prod.urls))
    return {
      auth: {
        clientId: prod.clientId,
        authority: prod.authority,
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
      },
      color: "",
    };

  return {} as any;
};

export const getLoginRequest = () => {
  if (checkUrl(local.urls)) return local.scopes;

  if (checkUrl(sandbox.urls)) return sandbox.scopes;

  if (checkUrl(dev.urls)) return dev.scopes;

  if (checkUrl(test.urls)) return test.scopes;

  if (checkUrl(prod.urls)) return prod.scopes;

  return {} as any;
};

export const getAzureMapAddressConfig = () => {
  if (checkUrl(local.urls)) return local.maps;

  if (checkUrl(sandbox.urls)) return sandbox.maps;

  if (checkUrl(dev.urls)) return dev.maps;

  if (checkUrl(test.urls)) return test.maps;

  if (checkUrl(prod.urls)) return prod.maps;

  return {} as any;
};
