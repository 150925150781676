import React, { useMemo } from "react";
import { DashboardA1Summary } from "../../../../../../redux/apiSpecifications/apiClaims";
import { WidgetWrapper } from "../../../../../../style/DashbordWidgetWrappers";
import styled from "styled-components";
import WidgetHeader from "../../../../../Widgets/WidgetHeader";
import { useTranslation } from "react-i18next";
import { DonutDataItemType } from "../../../../../Charts/DonutChart";
import DonutsOverview, { DonutsData } from "../Donuts";

const ClaimsDonutsHighlightedWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    align-items: stretch;
    max-height: 2.5em;
    transition: max-height 1s;

    &.open {
        max-height: 100em;
    }
`;

const getFill = (category: string): string => {
    let fill: string = "";

    switch (category) {
        case "A0":
            fill = "#FFD740";
            break;
        case "B0":
            fill = "#26A69A";
            break;
        case "L0":
            fill = "#FF7043";
            break;
        case "D0":
            fill = "#827043";
            break;
        case "Z0":
            fill = "#137043";
            break;
    }

    return fill;
};

export type ClaimsDonutKey = "inDraft" | "inProgress" | "inProgressReopened";

interface ClaimsDonutsHighlightedProps {
    summary?: DashboardA1Summary;
    selectedDonutItem?: string;
    gridArea?: string;
    onDonutItemClick?: (donutKey: ClaimsDonutKey, itemKey: string) => void;
    onResetFilter?: () => void;
}

const ClaimsDonutsHighlighted: React.FC<ClaimsDonutsHighlightedProps> = ({
    summary,
    selectedDonutItem,
    gridArea,
    onDonutItemClick,
    onResetFilter,
}) => {
    const { t } = useTranslation();

    const donuts = useMemo(() => {
        const result: DonutsData[] = [];

        if (summary) {
            let selectedDonutKey = "";
            let selectedDonutItemKey = "";

            if (selectedDonutItem) {
                const tmp = selectedDonutItem.split("-");
                if (tmp.length === 2) {
                    selectedDonutKey = tmp[0];
                    selectedDonutItemKey = tmp[1];
                }
            }

            result.push({
                key: "inDraft",
                title: "In Bozza",
                items: summary.inDraft!.map((item) => ({
                    key: item.category,
                    name: item.category,
                    value: item.total!,
                    fill: getFill(item.category!),
                })) as DonutDataItemType[],
                selectedItemKey:
                    selectedDonutKey === "inDraft"
                        ? selectedDonutItemKey
                        : undefined,
                centerValue: summary
                    .inDraft!.reduce((acc, cur) => acc + cur.total!, 0)
                    .toString(),
            });

            result.push({
                key: "inProgress",
                title: "In Corso",
                items: summary.inProgress!.map((item) => ({
                    key: item.category,
                    name: item.category,
                    value: item.total!,
                    fill: getFill(item.category!),
                })) as DonutDataItemType[],
                selectedItemKey:
                    selectedDonutKey === "inProgress"
                        ? selectedDonutItemKey
                        : undefined,
                centerValue: summary
                    .inProgress!.reduce((acc, cur) => acc + cur.total!, 0)
                    .toString(),
            });

            result.push({
                key: "inProgressReopened",
                title: "In Corso Riaperte",
                items: summary.inProgressReopened!.map((item) => ({
                    key: item.category,
                    name: item.category,
                    value: item.total!,
                    fill: getFill(item.category!),
                })) as DonutDataItemType[],
                selectedItemKey:
                    selectedDonutKey === "inProgressReopened"
                        ? selectedDonutItemKey
                        : undefined,
                centerValue: summary
                    .inProgressReopened!.reduce(
                        (acc, cur) => acc + cur.total!,
                        0
                    )
                    .toString(),
            });
        }

        return result;
    }, [summary, selectedDonutItem]);

    return (
        <ClaimsDonutsHighlightedWrapper gridArea={gridArea} className="open">
            <WidgetHeader
                title={t("frontend-dashboard-claim-title")}
                isActive={false}
                filterReset={onResetFilter}
            />
            <div className="body">
                <DonutsOverview
                    donuts={donuts}
                    options={{ uniqueLegend: true }}
                    onClick={(donutKey, itemKey) => {
                        if (onDonutItemClick) {
                            onDonutItemClick(
                                donutKey as ClaimsDonutKey,
                                itemKey
                            );
                        }
                    }}
                />
            </div>
        </ClaimsDonutsHighlightedWrapper>
    );
};

export default ClaimsDonutsHighlighted;
