import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { WidgetWrapper } from "../../../../../style/DashbordWidgetWrappers";
import { FormRow } from "../../../../../style/form";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useMemo, useState } from "react";
import { SelectPair } from "../../../../../types/common.types";
import { DatePickerStyled, SelectStyled } from "../../../../../style/Input";
import { RowSpacer } from "../../../../../style/containers";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import {
    AccountingReportSet,
    ReportMetadata,
    useLazyGetAccountingReportSetQuery,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import CreateReportModal from "./CreateReportModal";
import { CreateReportData, CreateReportDataStep2 } from "./CreateReportData";

const CreateReportWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    height: 100%;
    .body {
        padding: 1em 3em;
        display: block;

        .button-wrapper {
            display: flex;
            justify-content: flex-end;
            align-self: flex-end;
        }
    }
`;

const defaultCreateReportData: CreateReportData = {
    // idTenant: 4,
    // startDate: "01/03/2024",
    // endDate: "31/03/2024",
};

export interface ICreateReportProps {
    gridArea?: string;
    onConfirmCreateReport: (reportMetadata: ReportMetadata) => Promise<string>;
}

const CreateReport = ({
    gridArea,
    onConfirmCreateReport,
}: ICreateReportProps) => {
    const { t } = useTranslation();

    const { selectedTenants } = useSelector((state: RootState) => state.user);

    const { getAuthorization } = useAuthorization();

    const [createReportData, setCreateReportData] = useState<
        CreateReportData | undefined
    >(defaultCreateReportData);

    const [createReportDataStep2, setCreateReportDataStep2] =
        useState<CreateReportDataStep2 | null>(null);

    const [getAccountingReportSet] = useLazyGetAccountingReportSetQuery();

    const tenatsOptions = useMemo(() => {
        const tenatsOptionsArray: SelectPair[] = [];

        if (selectedTenants) {
            selectedTenants.forEach((tenant) => {
                tenatsOptionsArray.push({
                    value: tenant.id,
                    label: tenant.companyName!,
                });
            });
        }

        return tenatsOptionsArray;
    }, [selectedTenants]);

    const disableCreateReportButton = useMemo(() => {
        const { idTenant, startDate, endDate } = createReportData!;

        return !(idTenant && startDate && endDate);
    }, [createReportData]);

    const handleOnChange = (value: any, field: string) => {
        setCreateReportData({ ...createReportData, [field]: value });
    };

    const handleOnCreateReport = async () => {
        const { idTenant, startDate, endDate } = createReportData!;

        const response = await getAccountingReportSet({
            authorization: await getAuthorization(),
            idTenant: idTenant!,
            startDate: startDate!,
            endDate: endDate!,
        });

        const { data, error, isSuccess, isError } = response;

        if (isSuccess && data) {
            const { totalDelta, totalPractices, invoicedPractices } =
                data as AccountingReportSet;

            setCreateReportDataStep2({
                ...createReportData,
                tenantName: tenatsOptions.find((_) => _.value === idTenant!)
                    ?.label,
                totalDelta,
                totalPractices,
                invoicedPractices,
            } as CreateReportDataStep2);
        }
    };

    const handleOnCancelCreateReport = () => setCreateReportDataStep2(null);

    const handleOnConfirmCreateReport = (
        createReportRequest: ReportMetadata
    ) => {
        setCreateReportDataStep2(null);

        onConfirmCreateReport(createReportRequest)
            .then((response) => {
                setCreateReportData(defaultCreateReportData);

                // alert(response);
            })
            .catch((error) => alert(error));
    };

    return (
        <CreateReportWrapper gridArea={gridArea}>
            <div className="title">
                {t("accounting-administrator-dashboard-create-report-title")}
            </div>

            <div className="body" style={{ height: "100%" }}>
                <FormRow>
                    <SelectStyled
                        label={`${t("create-report-form-client-label")} *`}
                        tooltip={t("create-report-form-client-tooltip")}
                        onChange={(tenantId) =>
                            handleOnChange(tenantId, "idTenant")
                        }
                        value={createReportData?.idTenant}
                        options={tenatsOptions}
                    />

                    <RowSpacer />

                    <FormRow>
                        <DatePickerStyled
                            label={`${t(
                                "create-report-form-start-date-label"
                            )} *`}
                            tooltip={t("create-report-form-start-date-tooltip")}
                            onChange={(startDate) =>
                                handleOnChange(startDate, "startDate")
                            }
                            value={createReportData?.startDate}
                        />

                        <RowSpacer />

                        <DatePickerStyled
                            label={`${t(
                                "create-report-form-end-date-label"
                            )} *`}
                            tooltip={t("create-report-form-end-date-tooltip")}
                            onChange={(endDate) =>
                                handleOnChange(endDate, "endDate")
                            }
                            value={createReportData?.endDate}
                        />

                        <RowSpacer />

                        <div className="button-wrapper">
                            <ButtonConfirm
                                onClick={handleOnCreateReport}
                                disabled={disableCreateReportButton}
                            >
                                {t("button-create-report")!}
                            </ButtonConfirm>
                        </div>
                    </FormRow>
                </FormRow>
            </div>

            {createReportDataStep2 && (
                <CreateReportModal
                    createReportDataStep2={createReportDataStep2}
                    onCancel={handleOnCancelCreateReport}
                    onConfirm={handleOnConfirmCreateReport}
                />
            )}
        </CreateReportWrapper>
    );
};

export default CreateReport;
