import styled from "styled-components";
import { WidgetWrapper } from "../../../../../style/DashbordWidgetWrappers";
import { useTranslation } from "react-i18next";
import { FormRow } from "../../../../../style/form";
import { DatePickerStyled, SelectStyled } from "../../../../../style/Input";
import { useMemo, useState } from "react";
import { AppraisalQrReportRange } from "../../../../../redux/apiSpecifications/apiFesf";
import { RowSpacer } from "../../../../../style/containers";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import { SelectPair } from "../../../../../types/common.types";
import moment from "moment";

const CreateBusinessReportWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    height: 100%;
    .body {
        padding: 1em 3em;
        display: block;

        .button-wrapper {
            display: flex;
            justify-content: flex-end;
            align-self: flex-end;
        }
    }
`;

const defaultCreateBusinessReportData: AppraisalQrReportRange = {
    rangeType: "RD",
    //   startDate?: string;
    //   endDate?: string;
};

interface ICreateBusinessReportProps {
    gridArea?: string;
    onConfirmCreateBusinessReport: (
        appraisalQrReportRange: AppraisalQrReportRange
    ) => Promise<string>;
}

const CreateBusinessReport = ({
    gridArea,
    onConfirmCreateBusinessReport,
}: ICreateBusinessReportProps) => {
    const { t } = useTranslation();

    const [createBusinessReportData, setCreateBusinessReportData] = useState<
        AppraisalQrReportRange | undefined
    >(defaultCreateBusinessReportData);

    const rangesOptions = useMemo(() => {
        let result: SelectPair[] = [
            {
                value: "RD",
                label: t("business-report-range-type-description-RD"),
            },
        ];

        result.push({
            value: "CD",
            label: t("business-report-range-type-description-CD"),
        });

        return result;
    }, []);

    const disableCreateBusinessReportButton = useMemo(() => {
        const { rangeType, startDate, endDate } = createBusinessReportData!;

        return !(rangeType && startDate && endDate);
    }, [createBusinessReportData]);

    const handleOnChange = (value: any, field: string) => {
        setCreateBusinessReportData({
            ...createBusinessReportData,
            [field]: value,
        });
    };

    const handleOnCreateBusinessReport = async () => {
        onConfirmCreateBusinessReport(createBusinessReportData!)
            .then((response) => {
                setCreateBusinessReportData(defaultCreateBusinessReportData);

                // alert(response);
            })
            .catch((error) => alert(error));
    };

    const startDate = createBusinessReportData?.startDate 
        ? moment(createBusinessReportData.startDate, "DD/MM/YYYY") 
        : null;
    const endDate = createBusinessReportData?.endDate
        ? moment(createBusinessReportData.endDate, "DD/MM/YYYY")
        : null;
    const maxEndDate = startDate ? startDate.clone().add(1, 'year') : null;
    const minStartDate = endDate ? endDate.clone().subtract(1, 'year') : null;


    return (
        <CreateBusinessReportWrapper gridArea={gridArea}>
            <div className="title">
                {t("appraisal-analyst-dashboard-create-business-report-title")}
            </div>

            <div className="body" style={{ height: "100%" }}>
                <FormRow>
                    <SelectStyled
                        label={`${t(
                            "create-business-report-form-range-type-label"
                        )} *`}
                        tooltip={t(
                            "create-business-report-form-range-type-tooltip"
                        )}
                        onChange={(rangeType) =>
                            handleOnChange(rangeType, "rangeType")
                        }
                        value={createBusinessReportData?.rangeType}
                        options={rangesOptions}
                    />

                    <RowSpacer />

                    <FormRow>
                        <DatePickerStyled
                            label={`${t(
                                "create-business-report-form-start-date-label"
                            )} *`}
                            tooltip={t(
                                "create-business-report-form-start-date-tooltip"
                            )}
                            onChange={(startDate) =>
                                handleOnChange(startDate, "startDate")
                            }
                            disabledDate={(current) => {
                                if (endDate) {
                                    const minStartDate = endDate.clone().subtract(1, 'year');
                                    return (
                                        current.isAfter(endDate, 'day') ||
                                        current.isBefore(minStartDate, 'day')
                                    );
                                }
                                return false;
                            }}
                            value={createBusinessReportData?.startDate}
                        />

                        <RowSpacer />

                        <DatePickerStyled
                            label={`${t(
                                "create-business-report-form-end-date-label"
                            )} *`}
                            tooltip={t(
                                "create-business-report-form-end-date-tooltip"
                            )}
                            onChange={(endDate) =>
                                handleOnChange(endDate, "endDate")
                            }
                            disabledDate={(current) => {
                                if (startDate) {
                                    const maxEndDate = startDate.clone().add(1, 'year');
                                    return (
                                        current.isBefore(startDate, 'day') ||
                                        current.isAfter(maxEndDate, 'day')
                                    );
                                }
                                return false;
                            }}
                            value={createBusinessReportData?.endDate}
                        />

                        <RowSpacer />

                        <div className="button-wrapper">
                            <ButtonConfirm
                                onClick={handleOnCreateBusinessReport}
                                disabled={disableCreateBusinessReportButton}
                            >
                                {t("button-create-report")!}
                            </ButtonConfirm>
                        </div>
                    </FormRow>
                </FormRow>
            </div>
        </CreateBusinessReportWrapper>
    );
};

export default CreateBusinessReport;
