import { useTranslation } from "react-i18next";
import {
  CardVehicleTypes,
  GenderTypes,
  VehicleTypes,
} from "../../config/const";
import { InputProps, SelectStyled } from "../../style/Input";
import { selectVehicleType as testId } from "../../config/testIds";
import { VehicoleTypeType } from "../../types/claim.types";
import { SelectPair } from "../../types/common.types";
import { useMemo } from "react";

interface ISelectVehicleTypeProps extends InputProps {
  classNameItem?: string;
  styleItem?: string;
  value: VehicoleTypeType;
  disabled?: boolean;
  label?: string | null;
  tooltip?: string | null;
  dataTestId?: string;
  onChange: (val: VehicoleTypeType) => void;
}

const SelectVehicleType = (props: ISelectVehicleTypeProps) => {
  const { value, dataTestId, onChange, ...rest } = props;

  const { t } = useTranslation();

  const options = useMemo(() => {
    return VehicleTypes
      .map((code) => ({
        value: code,
        label: t(`vehicle-type-${code}`),
      }))
      .sort((a, b) => (a!.label < b!.label ? -1 : 1));
  }, []);

  return (
    <SelectStyled
      // classNameItem={props.classNameItem}
      // styleItem={props.styleItem}
      //label={label || t("select-vehicle-type")}
      // tooltip={props.tooltip}
      onChange={(type) => props.onChange(type)}
      // value={props.value}
      options={options}
      dataTestId={dataTestId || testId.container}
      {...rest}
    />
  );
};

export default SelectVehicleType;
