import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import {
  CreatedClaim,
  ReadOnlyClaim,
  useCreateClaimDraftMutation,
} from "../redux/apiSpecifications/apiClaims";

export const useClaims = () => {
  const [createClaimDraft] = useCreateClaimDraftMutation();

  const createClaimDraftPromise = (
    createdClaim: CreatedClaim
  ): Promise<ReadOnlyClaim> =>
    new Promise(async (resolve, reject) => {
      const response = await createClaimDraft({
        createdClaim,
      });

      const postResponse = response as {
        data: ReadOnlyClaim;
      };

      if (postResponse.data) {
        resolve(postResponse.data);
      } else {
        const errorResponse = response as {
          error: FetchBaseQueryError;
        };

        reject(errorResponse.error);
      }
    });

  return {
    createClaimDraftPromise
  };
};
