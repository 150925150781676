import { useTranslation } from "react-i18next";
import styled from "styled-components";
import dekra from "../../../style/dekra";
import { mainTabs } from "../../../config/testIds";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { IconRemoveFilter } from "../../../config/icons";
import { TbUrgent } from "react-icons/tb";
import {
  MdHourglassEmpty,
  MdOutlineNotificationImportant,
} from "react-icons/md";
import { RxExclamationTriangle } from "react-icons/rx";
import { RiThumbUpLine } from "react-icons/ri";
import { FilterPriorityValues } from "../../MainTabs/Dashboards/Dashboard-E2";

const testIds = mainTabs.E2.statuses;

const RowWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
`;

const PriorityWrapper = styled(WidgetWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  .priority {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0.25em 1em;

    border-right: 2px solid #bbb;
    color: #333;
    text-transform: uppercase;
    font-size: 0.9em;

    &:hover {
      color: ${dekra.primaryColor};
      cursor: pointer;

      &.empty {
        cursor: default;
      }
    }

    &.active {
      background-color: ${dekra.primaryColor};
      height: 100%;
      svg {
        color: ${dekra.secondaryColors.white};
      }

      .text {
        color: ${dekra.secondaryColors.white};
      }

      .value {
        color: ${dekra.secondaryColors.white};
      }

      &:hover {
        svg {
          color: ${dekra.secondaryColors.white};
        }
        .text {
          color: ${dekra.secondaryColors.white};
        }
        .value {
          color: ${dekra.secondaryColors.white};
        }
      }
    }

    &:last-child {
      border-right: none;
    }

    svg {
      font-size: 1.6em;
      color: #aaa;
    }

    .clickable {
      display: flex;
      align-items: center;

      letter-spacing: 1px;

      .text {
        margin-left: 0.5em;
        align-self: flex-end;
      }

      .value {
        margin-left: 0.5em;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .reset-filter {
    padding: 0 0.5em;
    font-size: 1.2em;
    color: #555;

    .icon-wrapper {
      cursor: pointer;
      margin-top: 5px;
    }
  }
`;

const icons = [
  <TbUrgent />,
  <MdOutlineNotificationImportant />,
  <MdHourglassEmpty />,
  <RxExclamationTriangle />,
  <RiThumbUpLine />,
];

export interface IPriorityFilterProps {
  values: FilterPriorityValues;
  onSelect: (priority: number | undefined) => void;
  selected?: number;
}

const PriorityFilter = (props: IPriorityFilterProps) => {
  const { values, onSelect, selected } = props;

  const { t } = useTranslation();

  const handleResetFilter = () => onSelect(undefined);

  const priorityValues = [
    values.urgent,
    values.important,
    values.expired,
    values.expiring,
    values.standard,
  ];

  return (
    <RowWrapper>
      <PriorityWrapper>
        {[1, 2, 4, 3, 5].map((priority, index) => (
          <div
            key={index}
            className={`priority ${selected === priority ? "active" : ""}`}
            onClick={() => onSelect(priority)}
          >
            {icons[index]}
            <div className="clickable">
              <div className="text">{t(`appraisal-priority-${priority}`)}</div>
              <div className="value">{priorityValues[index]}</div>
            </div>
          </div>
        ))}

        {/* <div className="priority empty">
          <div className="text"> &nbsp;</div>
          <div className="value"></div>
        </div> */}

        <div className="reset-filter">
          <div className="icon-wrapper" onClick={handleResetFilter}>
            <IconRemoveFilter />
          </div>
        </div>
      </PriorityWrapper>
    </RowWrapper>
  );
};

export default PriorityFilter;
