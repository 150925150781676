import styled from "styled-components";
import dekra from "./dekra";

export const DashboardWrapper = styled.div`
  background-color: transparent;
  width: 100%;
  min-height: calc(100vh - 200px);
  padding: 1em;
  position: relative;
`;

export const MainTabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 100%;
  min-height: calc(100vh - 200px);
  padding: 1em;
  position: relative;
`;

export const GenericTabWrapper = styled.div`
  display: flex;
  background-color: transparent;
`;

export const LoadingInTabWrapper = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: center;
  padding-top: 20%;

  .box {
    width: 20em;
  }
`;

export const LoadingFullScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  padding-top: 20%;

  .box {
    width: 20em;
  }
`;

export const RefreshInTabWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  padding-top: 20%;

  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;

  .box {
    width: 20em;
  }
`;

export const WidgetWrapper = styled.div<{ gridArea?: string; flex?: number }>`
  grid-area: ${(props) => props.gridArea};
  flex: ${(props) => props.flex};

  display: flex;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0 5px #aaa;
  overflow: hidden;

  .title {
    color: #333;
    background-color: #ededed;
    padding: 0.5em 2em;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;

    &.active {
      background-color: ${dekra.primaryColor};
      color: #fff;
    }
  }

  .body {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .col {
      flex: 1;
    }
  }
`;
