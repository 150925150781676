import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { AttachmentType } from "../../components/Inputs/Attachment";
import { DateFormat, SubjectType } from "../../config/const";
import { AdditionalValidationField } from "../../types/common.types";
import { SubjectDocumentWithAttachments } from "../../types/subject-data.types";
import { Subject as SubjectModel, Address, Contact, SubjectPayment } from "../apiSpecifications/apiCrud";

export type SubjectAdditionalValidations = {
	fiscalCode: AdditionalValidationField;
	propritorshipFiscalCode: AdditionalValidationField;
	pIva: AdditionalValidationField;
	contacts: AdditionalValidationField[];
	addresses: AdditionalValidationField[];
	paymentsIban: AdditionalValidationField[];
	paymentsBic: AdditionalValidationField[];
	documents: AdditionalValidationField[];
};

export type SubjectValidations = {
	isSubjectValid: boolean;
	additionalValidations: SubjectAdditionalValidations;
};

export type AddDocumentAttachmentProps = {
	docIndex: number;
	attachment: AttachmentType;
};

export type RemoveDocumentAttachmentProps = {
	docIndex: number;
	attachmentIndex: number;
};

export interface ISubjectState {
	subject: SubjectModel | undefined;
	isSubjectValid: boolean;
	additionalValidations: SubjectAdditionalValidations;
	attachments: AttachmentType[][];
	readOnly: boolean;
	isNew: boolean;
}

const buildInitialState = () => {
	return {
		readOnly: true,
		isNew: false,
		subject: {
			subjectType: "PF",
			birth: {
				date: moment().format(DateFormat),
			},
			contacts: [] as Contact[],
			addresses: [] as Address[],
			payments: [] as SubjectPayment[],
			documents: [] as SubjectDocumentWithAttachments[],
		},
		isSubjectValid: false,
		additionalValidations: {
			fiscalCode: {
				state: false,
			},
			pIva: {
				state: false,
			},
			propritorshipFiscalCode: {
				state: false,
			},
			contacts: [] as AdditionalValidationField[],
			addresses: [] as AdditionalValidationField[],
			paymentsIban: [] as AdditionalValidationField[],
			paymentsBic: [] as AdditionalValidationField[],
			documents: [] as AdditionalValidationField[],
		},
		attachments: [] as AttachmentType[][],
	} as ISubjectState;
};

export const activeSubjectSlice = createSlice({
	name: "activeSubject",
	initialState: buildInitialState(),
	reducers: {
		clear(state) {
			const { subject, readOnly, isNew, additionalValidations, attachments } = buildInitialState();
			state.readOnly = readOnly;
			state.isNew = isNew;
			state.subject = subject;
			state.additionalValidations = additionalValidations;
			state.attachments = attachments;
		},
		clearLocalStorage(state) {
			// handled in localstoareMiddleware
		},
		editSubject(state, action: PayloadAction<SubjectModel>) {
			state.subject = Object.assign({}, buildInitialState().subject, action.payload);
			state.readOnly = false;
		},
		viewSubject(state, action: PayloadAction<SubjectModel>) {
			state.subject = action.payload;
			state.readOnly = true;
		},
		updateSubject(state, action: PayloadAction<SubjectModel>) {
			state.subject = action.payload;
		},
		updateValidations(state, action: PayloadAction<SubjectValidations>) {
			state.isSubjectValid = action.payload.isSubjectValid;
			state.additionalValidations = action.payload.additionalValidations;
		},
		addContact(state) {
			state.subject!.contacts?.push({ preferred: state.subject!.contacts.length === 0 } as Contact);
			state.additionalValidations.contacts.push({
				state: true,
				validateStatus: "success",
				messageKey: "",
			});
		},
		removeContact(state, action: PayloadAction<number>) {
			const itWasPreferred = state.subject?.contacts![action.payload].preferred;

			state.subject!.contacts = state.subject!.contacts!.filter((_, i) => i !== action.payload);
			state.additionalValidations.contacts = state.additionalValidations.contacts.filter((_, i) => i !== action.payload);

			// if preferred was removed and there is at least another contacts, set it as preferred
			if (itWasPreferred && state.subject!.contacts![0]) state.subject!.contacts![0].preferred = true;
		},
		addAddress(state) {
			state.subject!.addresses?.push({ preferred: state.subject!.addresses.length === 0 } as Address);
			state.additionalValidations.addresses.push({
				state: true,
				validateStatus: "success",
				messageKey: "",
			});
		},
		removeAddress(state, action: PayloadAction<number>) {
			const itWasPreferred = state.subject?.addresses![action.payload].preferred;

			state.subject!.addresses = state.subject!.addresses!.filter((_, i) => i !== action.payload);
			state.additionalValidations.addresses = state.additionalValidations.addresses.filter((_, i) => i !== action.payload);

			// if preferred was removed and there is at least another address, set it as preferred
			if (itWasPreferred && state.subject!.addresses![0]) state.subject!.addresses![0].preferred = true;
		},
		addDocument(state) {
			const today = moment().format(DateFormat);
			state.subject!.documents?.push({
				issuingDate: today,
				expirationDate: today,
			} as SubjectDocumentWithAttachments);
			state.additionalValidations.documents.push({
				state: true,
				validateStatus: "success",
				messageKey: "",
			});

			state.attachments.push([] as AttachmentType[]);
		},
		removeDocument(state, action: PayloadAction<number>) {
			// remove attachments
			state.attachments = state.attachments!.filter((_, i) => i !== action.payload);

			state.subject!.documents = state.subject!.documents!.filter((_, i) => i !== action.payload);
			state.additionalValidations.documents = state.additionalValidations.documents.filter((_, i) => i !== action.payload);
		},
		addPayment(state) {
			state.subject!.payments?.push({ preferred: state.subject!.payments.length === 0 } as SubjectPayment);
			state.additionalValidations.paymentsIban.push({
				state: true,
				validateStatus: "success",
				messageKey: "",
			});
			state.additionalValidations.paymentsBic.push({
				state: true,
				validateStatus: "success",
				messageKey: "",
			});
		},
		removePayment(state, action: PayloadAction<number>) {
			const itWasPreferred = state.subject?.payments![action.payload].preferred;

			state.subject!.payments = state.subject!.payments!.filter((_, i) => i !== action.payload);
			state.additionalValidations.paymentsIban = state.additionalValidations.paymentsIban.filter((_, i) => i !== action.payload);
			state.additionalValidations.paymentsBic = state.additionalValidations.paymentsBic.filter((_, i) => i !== action.payload);

			// if preferred was removed and there is at least another payment method, set it as preferred
			if (itWasPreferred && state.subject!.payments![0]) state.subject!.payments![0].preferred = true;
		},
		addAttachment(state, action: PayloadAction<AddDocumentAttachmentProps>) {
			const { docIndex, attachment } = action.payload;
			if (!state.attachments[docIndex]) state.attachments[docIndex] = [] as AttachmentType[];

			if (!state.attachments[docIndex]) state.attachments[docIndex] = [] as AttachmentType[];

			state.attachments[docIndex].push({ ...attachment, status: "new" });
		},
		removeAttachment(state, action: PayloadAction<RemoveDocumentAttachmentProps>) {
			const { docIndex, attachmentIndex } = action.payload;
			const prevStatus = state.attachments[docIndex][attachmentIndex].status;

			if (prevStatus === "new") {
				state.attachments[docIndex] = state.attachments[docIndex].filter((_, i) => i !== attachmentIndex);
			} else if (prevStatus === "downloaded") {
				state.attachments[docIndex][attachmentIndex].status = "deleted";
			}
		},
		setAttachments(state, action: PayloadAction<AttachmentType[][]>) {
			state.attachments = action.payload;
		},
		forceValidation(state) {},
		forceSerialize(state) {},
	},
});

export const {
	clear,
	clearLocalStorage,
	editSubject,
	viewSubject,
	updateSubject,
	updateValidations,
	addContact,
	removeContact,
	addAddress,
	removeAddress,
	addDocument,
	removeDocument,
	addPayment,
	removePayment,
	addAttachment,
	removeAttachment,
	setAttachments,
	forceValidation,
	forceSerialize,
} = activeSubjectSlice.actions;
export default activeSubjectSlice.reducer;
