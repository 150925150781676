import styled from "styled-components";
import { IconLoading } from "../../../../../config/icons";
import { Tag } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import { useLazyGetAppraiserScoreReportQuery } from "../../../../../redux/apiSpecifications/apiFesf";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { AttachmentFile } from "../../../../../redux/apiSpecifications/apiCrud";

const ActionsWrapper = styled.div`
    display: flex;
    gap: 1em;

    .confirm-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
    }

    button {
        font-size: 0.9em;
    }
`;

interface IReportActionsProps {
    reportId: number;
    generationStatus: string;
}

const ReportActions = ({ reportId, generationStatus }: IReportActionsProps) => {
    const { t } = useTranslation();

    const refDownload = React.useRef<HTMLAnchorElement>(null);

    const { authorization, activeRole } = useSelector(
        (state: RootState) => state.user
    );

    const [getAppraiserScoreReport] = useLazyGetAppraiserScoreReportQuery();

    const handleOnDownloadReport = async (reportId: number) => {
        const response = await getAppraiserScoreReport({
            authorization: authorization,
            id: reportId,
        });

        const { data, error, isSuccess, isError } = response;

        if (isSuccess && data) {
            const attachmentFile = data as AttachmentFile;

            const link = document.createElement("a");

            link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${attachmentFile?.base64File!}`;
            link.target = "_blank";
            link.download = attachmentFile?.originalName!;
            link.click();

            refDownload?.current?.click();
        } else {
            console.info(error);
        }
    };

    return (
        <ActionsWrapper>
            {["Q", "W"].includes(generationStatus) && (
                <div className="confirm-loader">{IconLoading}</div>
            )}

            {generationStatus === "E" && (
                <Tag icon={<CloseCircleOutlined />} color="error">
                    {t("report-generation-status-code-E")}
                </Tag>
            )}

            {generationStatus === "C" && (
                <ButtonConfirm onClick={() => handleOnDownloadReport(reportId)}>
                    {t("button-download-report")!}
                </ButtonConfirm>
            )}
        </ActionsWrapper>
    );
};
export default ReportActions;
