import styled from "styled-components";
import { useTranslation } from "react-i18next";
import WidgetHeader from "../WidgetHeader";
import { useEffect, useState } from "react";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import DonutChart, { DonutDataItemType } from "../../Charts/DonutChart";
import { FiltersValues as FilterValueE1 } from "../../MainTabs/Dashboards/Dashboard-E1";
import { FiltersValues as FilterValueE4 } from "../../MainTabs/Dashboards/Dashboard-E4";
import {
    PracticeDataE4,
    TotalByCategory,
} from "../../../redux/apiSpecifications/apiCrud";

const AppraisalsToBeAssignedChartWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    // align-items: stretch;
    max-height: 2.5em;
    transition: max-height 1s;
    &.open {
        max-height: 100em;
    }
`;

export type ChartData = {
    items: DonutDataItemType[];
    label: string;
    type: string;
};

export interface IAppraisalsToBeAssignedChartProps {
    toBeAssigned: TotalByCategory[];
    onSelect: (totalType: string | undefined, key: string | undefined) => void;
    gridArea?: string;
    isActive?: boolean;
    isOpen?: boolean;
    filtersValues?: FilterValueE1 | FilterValueE4;
}

const AppraisalsToBeAssignedChart = (
    props: IAppraisalsToBeAssignedChartProps
) => {
    const {
        toBeAssigned,
        onSelect,
        gridArea,
        isActive = false,
        filtersValues,
    } = props;

    const { t } = useTranslation();

    const [donatsData, setDonatsData] = useState<ChartData[]>([]);
    const [isOpen, setIsOpen] = useState(props.isOpen);

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    useEffect(() => {
        let donutDataItems: DonutDataItemType[] = [];

        toBeAssigned.forEach((item) => {
            donutDataItems.push({
                key: item.category!,
                name: t(`appraisals-dashboard-to-be-assigned-${item.category}`),
                value: item.total!,
                fill: getFillIsRejected(item.category!),
            });
        });

        let result: ChartData[] = [];

        result = [
            {
                type: "isToBeAssigned",
                items: donutDataItems,
                label: t(`appraisals-dashboard-appraisal-type-donut`),
            },
        ];

        setDonatsData(result);
    }, [toBeAssigned]);

    const getFillIsRejected = (key: string): string => {
        let fill: string = "";

        switch (key) {
            case "1":
                fill = "#073B3A";
                break;
            case "2":
                fill = "#0B6E4F";
                break;
            case "3":
                fill = "#08A045";
                break;
            case "4":
                fill = "#6BBF59";
                break;
            case "5":
                fill = "#21D375";
                break;
            case "6":
                fill = "#094F29";
                break;
            case "7":
                fill = "#0A6921";
                break;
            case "8":
                fill = "#1A8828";
                break;
            case "9":
                fill = "#429B46";
                break;
        }

        return fill;
    };

    const handleFilterReset = () => {
        onSelect("isToBeAssigned", "");
    };

    const handleOnOpen = () => setIsOpen(true);
    const handleOnClose = () => setIsOpen(false);

    const getCenterValue = (items: DonutDataItemType[]): string => {
        const sum = items.reduce((acc, cur) => {
            return acc + cur.value;
        }, 0);

        return String(sum);
    };

    return (
        <AppraisalsToBeAssignedChartWrapper
            className={`${isActive || isOpen ? "open" : ""}`}
            gridArea={gridArea}
        >
            <WidgetHeader
                title={t("appraisals-dashboard-to-be-assigned-title")}
                isActive={isActive}
                filterReset={handleFilterReset}
                onOpen={handleOnOpen}
                onClose={handleOnClose}
                isOpen={isOpen}
            />
            <div className="body">
                {donatsData.map((donatData, index) => {
                    const selected =
                        isActive && filtersValues?.donatType === donatData.type
                            ? filtersValues.donatSelection
                            : "";

                    return (
                        <div className="col" key={index}>
                            <DonutChart
                                title={donatData.label}
                                data={donatData.items}
                                onClick={(key) => onSelect(donatData.type, key)}
                                centerValue={getCenterValue(donatData.items)}
                                selected={selected}
                            />
                        </div>
                    );
                })}
            </div>
        </AppraisalsToBeAssignedChartWrapper>
    );
};

export default AppraisalsToBeAssignedChart;
