import { useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { getLoginRequest } from "../../config/azureConfig";
import styled from "styled-components";
import dekra from "../../images/dekra-open.jpg";
import { InteractionType } from "@azure/msal-browser";

const FullWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  width: 100vw;
  background-color: #eee;
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 3px #aaa;
  padding: 1em 3em;
  border-radius: 5em;
  background-color: #fff;
  margin-top: 10em;

  &:hover {
    box-shadow: 0 0 10px #aaa;
  }
`;

const TPA = styled.div`
  font-size: 2em;
  color: #008143;
  margin: 0 1em;
  font-family: monospace;
  font-weight: 700;
  letter-spacing: 2px;
`;

const SingIn = () => {
  const { instance } = useMsal();
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Popup}>
      <FullWrapper>
        <MessageWrapper onClick={() => instance.loginPopup(getLoginRequest())}>
          <img src={dekra} />
        </MessageWrapper>
      </FullWrapper>
    </MsalAuthenticationTemplate>
  );
};

export default SingIn;
