import { editSubject, forceValidation, setAttachments } from "../redux/features/activeSubject";
import { LocalStorageKeys } from "../config/const";
import { store } from "../redux/store";
import { AttachmentType } from "../components/Inputs/Attachment";
import {
	setAdditionalInfo,
	setDamagedPart,
	setPolicyData,
	updateResponsabilityData,
	setDataEntryStatus,
	updateCardWizardData,
	updateClaimData,
	updateCounterpartData,
} from "../redux/features/activeClaimSlice";
import { Claim as ClaimModel, DamagedPart, PolicyMainData } from "../redux/apiSpecifications/apiCrud";

import {
	ActiveClaimStateType,
	//AdditionalInfoDataType,
	CardWizardDataType,
	ClaimCounterpartModel,
	ClaimDataCounterpartDataType,
	ClaimDataType,
	ClaimResponsabilityModel,
	DamagedPartType,
	ResponsabilityDataType,
} from "../types/claim.types";
import { setConfigParams } from "../redux/features/userSlice";

const getObject = (key: string) => {
	const strObject = localStorage.getItem(key);
	if (strObject?.length && strObject?.length > 0) return JSON.parse(strObject);

	return undefined;
};

const checkActiveSubject = () => {
	// ACTIVE SUBJECT
	const activeSubject = getObject(LocalStorageKeys.activeSubject.subject);
	if (activeSubject) {
		store.dispatch(editSubject(activeSubject));
		store.dispatch(forceValidation());
	}

	const attachments: AttachmentType[][] = getObject(LocalStorageKeys.activeSubject.attachments) || [];
	if (attachments) store.dispatch(setAttachments(attachments));
};

const checkConfigParams = () => {
	const configParams = getObject(LocalStorageKeys.configParams);
	if (configParams) store.dispatch(setConfigParams(configParams));
};

export const removeActiveSubjectFromStorage = () => {
	localStorage.removeItem(LocalStorageKeys.activeSubject.subject);
};

export const removeActiveClaimFromStorage = () => {
	localStorage.removeItem(LocalStorageKeys.activeClaim.policyData);
	localStorage.removeItem(LocalStorageKeys.activeClaim.stepperData);
	localStorage.removeItem(LocalStorageKeys.activeClaim.additionalInfo);
	localStorage.removeItem(LocalStorageKeys.activeClaim.claimData);
	localStorage.removeItem(LocalStorageKeys.activeClaim.counterpartData);
	localStorage.removeItem(LocalStorageKeys.activeClaim.responsability);
	localStorage.removeItem(LocalStorageKeys.activeClaim.damagedParts);
};

export const tryLoadFromStorage = () => {
	checkConfigParams();
	checkActiveSubject();
	checkActiveClaim();
};

const checkActiveClaim = () => {
	// POLICY DATA
	const policyDataTxt = localStorage.getItem(LocalStorageKeys.activeClaim.policyData);
	if (policyDataTxt?.length && policyDataTxt?.length > 0) {
		const policyData = JSON.parse(policyDataTxt) as PolicyMainData;
		store.dispatch(setPolicyData(policyData));
	}

	// STEPPER DATA
	const stepperDataTxt = localStorage.getItem(LocalStorageKeys.activeClaim.stepperData);
	if (stepperDataTxt?.length && stepperDataTxt?.length > 0) {
		const stepperData = JSON.parse(stepperDataTxt) as CardWizardDataType;
		store.dispatch(updateCardWizardData(stepperData));
	}

	// CLAIM DATA
	const claimDataTxt = localStorage.getItem(LocalStorageKeys.activeClaim.claimData);
	if (claimDataTxt?.length && claimDataTxt?.length > 0) {
		const claimData = JSON.parse(claimDataTxt) as ClaimModel;
		store.dispatch(updateClaimData(claimData));
		//checkClaimDataCompleted();
	}

	// COUNTERPART DATA
	const counterpartDataTxt = localStorage.getItem(LocalStorageKeys.activeClaim.counterpartData);
	if (counterpartDataTxt?.length && counterpartDataTxt?.length > 0) {
		const counterpartData = JSON.parse(counterpartDataTxt) as ClaimCounterpartModel;
		store.dispatch(updateCounterpartData(counterpartData));
		//checkCounterpatDataCompleted();
	}

	// RESPONSABILITY DATA
	const responsabilityTxt = localStorage.getItem(LocalStorageKeys.activeClaim.responsability);
	if (responsabilityTxt?.length && responsabilityTxt?.length > 0) {
		const responsability = JSON.parse(responsabilityTxt) as ClaimResponsabilityModel;
		store.dispatch(updateResponsabilityData(responsability));
		//checkResponsabilityDataCompleted();
	}

	// DAMAGED PARTS
	const damagedPartsTxt = localStorage.getItem(LocalStorageKeys.activeClaim.damagedParts);
	if (damagedPartsTxt?.length && damagedPartsTxt?.length > 0) {
		const damagedParts = JSON.parse(damagedPartsTxt) as DamagedPart[];
		damagedParts.forEach((dp, i) => store.dispatch(setDamagedPart({ damagedPart: dp, index: i })));
		//checkDamagedPartsDataCompleted();
	}

	// // ADDITIONAL INFO
	// const additionalInfoTxt = localStorage.getItem(LocalStorageKeys.activeClaim.additionalInfo);
	// if (additionalInfoTxt?.length && additionalInfoTxt?.length > 0) {
	// 	const additionalInfos = JSON.parse(additionalInfoTxt) as AdditionalInfoDataType[];
	// 	additionalInfos.forEach((ai, i) => store.dispatch(setAdditionalInfo({ additionalInfo: ai, index: i })));
	// }
};
