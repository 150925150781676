import { useMemo } from "react";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import { useTranslation } from "react-i18next";

interface IPracticeFeeModifiedDescriptionProps {
    modifyDetailsJson: Modifydetail[];
}

const PracticeFeeModifiedDescription = ({
    modifyDetailsJson,
}: IPracticeFeeModifiedDescriptionProps) => {
    const { t } = useTranslation();

    const fee_amount: string = useMemo(() => {
        const value = modifyDetailsJson.find(
            (_) => _.key === "fee_amount"
        );

        return value?.value ?? "";
    }, [modifyDetailsJson]);

    return (
        <>
            {t("historical-actions-practice-fee-modified-description", {
                fee_amount: fee_amount,
            })}
        </>
    );
};

export default PracticeFeeModifiedDescription;
