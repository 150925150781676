import styled from "styled-components";
import {
  useCreateClaimContext,
  useCreateClaimDispatch,
} from "../CreateClaimContext";
import { FormRow } from "../../../../../../../style/form";
import {
  DatePickerStyled,
  InputTextStyled,
  SwitchStyled,
} from "../../../../../../../style/Input";
import { useTranslation } from "react-i18next";
import { RowSpacer } from "../../../../../../../style/containers";
import SelectUserTenants from "../../../../../../Inputs/SelectUserTenants";
import SelectVehicleType from "../../../../../../Inputs/SelectVehicleType";
import { VehicoleTypeType } from "../../../../../../../types/claim.types";
import { ButtonCancel, ButtonConfirm } from "../../../../../../Layout/Buttons";
import { BasePolicy } from "../../../../../../../redux/apiSpecifications/apiClaims";
import moment from "moment";
import PolicyDataForm from "./PolicyDataForm";
import InsuredPropertyDataForm from "./InsuredPropertyDataForm";

const PolicyInfoStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-end;
`;

const validateForm = (model: BasePolicy) => {
  const insuredProperty = model.insuredProperty;

  const isInsuredPropertyValid =
    insuredProperty &&
    insuredProperty.plateNumber &&
    insuredProperty.chassisNumber &&
    insuredProperty.type &&
    insuredProperty.brand &&
    insuredProperty.model;

  return (
    model.tenantId &&
    model.numberCompany &&
    model.effectDate &&
    model.expirationDate &&
    model.insuredProperty &&
    isInsuredPropertyValid
  );
};

interface IPolicyInfoStepProps {
  onNextStep: () => void;
  onPreviousStep: () => void;
}

const PolicyInfoStep = ({
  onNextStep,
  onPreviousStep,
}: IPolicyInfoStepProps) => {
  const { t } = useTranslation();

  const { createClaimData } = useCreateClaimContext();

  const dispatch = useCreateClaimDispatch();

  const handleOnChange = (value: any, field: string) => {
    dispatch!({
      type: "CHANGE_CLAIM_DATA",
      payload: {
        value: value,
        field: `selectedPolicy.${field}`,
      },
    });
  };

  const model = createClaimData?.selectedPolicy!;

  const effectDate = model?.effectDate
    ? moment(model.effectDate, "DD/MM/YYYY")
    : null;

  const expirationDate = model?.expirationDate
    ? moment(model.expirationDate, "DD/MM/YYYY")
    : null;

  const disableForm = model.id !== undefined;

  const enableNextButton = disableForm || validateForm(model);

  return (
    <PolicyInfoStepWrapper>
      <h3>Dati polizza</h3>

      <PolicyDataForm
        model={model}
        disableForm={disableForm}
        onChange={handleOnChange}
      />

      {/* <FormRow>
        <SelectUserTenants
          label={t("LABELS.company")}
          required={true}
          value={model.tenantId}
          onChange={(value) => handleOnChange(value, "tenantId")}
          disabled={true}
        />

        <RowSpacer />

        <div
          style={{
            flex: 1,
            display: "flex",
          }}
        >
          <InputTextStyled
            label={t("LABELS.number-company")}
            placeholder={t("PLACEHOLDERS.number-company")}
            onChange={(value) => handleOnChange(value, "numberCompany")}
            required={true}
            value={model.numberCompany}
            disabled={disableForm}
          />

          <RowSpacer />

          <SwitchStyled
            label={t("LABELS.administrative-compliance")}
            onChange={(value) =>
              handleOnChange(value, "administrativeCompliance")
            }
            unCheckedChildren={t("switch-no")}
            checkedChildren={t("switch-yes")}
            checked={model.administrativeCompliance}
            disabled={disableForm}
          />
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
          }}
        >
          <DatePickerStyled
            label={t("LABELS.effect-date")}
            placeholder={"gg/mm/aaaa"}
            required={true}
            value={model?.effectDate}
            onChange={(value) => handleOnChange(value, "effectDate")}
            disabledDate={(current) => {
              if (expirationDate) {
                return current.isAfter(expirationDate, "day");
              }
              return false;
            }}
            disabled={disableForm}
          />

          <RowSpacer />

          <DatePickerStyled
            label={t("LABELS.expiration-date")}
            placeholder={"gg/mm/aaaa"}
            required={true}
            value={model?.expirationDate}
            onChange={(value) => handleOnChange(value, "expirationDate")}
            disabledDate={(current) => {
              if (effectDate) {
                return current.isBefore(effectDate, "day");
              }
              return false;
            }}
            disabled={disableForm}
          />
        </div>
      </FormRow> */}

      <h3>Dati veicolo</h3>

      <InsuredPropertyDataForm
        model={model.insuredProperty!}
        disableForm={disableForm}
        onChange={(value, field) => handleOnChange(value, `insuredProperty.${field}`)}
      />

      {/* <FormRow>
        <InputTextStyled
          label={t("LABELS.plate-number")}
          placeholder={t("PLACEHOLDERS.plate-number")}
          onChange={(value) =>
            handleOnChange(value, "insuredProperty.plateNumber")
          }
          required={true}
          value={model.insuredProperty?.plateNumber}
          disabled={true}
        />

        <RowSpacer />

        <InputTextStyled
          label={t("LABELS.chassis-number")}
          placeholder={t("PLACEHOLDERS.chassis-number")}
          onChange={(value) =>
            handleOnChange(value, "insuredProperty.chassisNumber")
          }
          required={true}
          value={model.insuredProperty?.chassisNumber}
          disabled={disableForm}
        />

        <RowSpacer />

        <SelectVehicleType
          label={t("LABELS.vehicle-type")}
          required={true}
          value={model.insuredProperty?.type as VehicoleTypeType}
          onChange={(value) => handleOnChange(value, "insuredProperty.type")}
          disabled={disableForm}
        />

        <RowSpacer />

        <InputTextStyled
          label={t("LABELS.brand")}
          placeholder={t("PLACEHOLDERS.brand")}
          onChange={(value) => handleOnChange(value, "insuredProperty.brand")}
          required={true}
          value={model.insuredProperty?.brand}
          disabled={disableForm}
        />

        <RowSpacer />

        <InputTextStyled
          label={t("LABELS.model")}
          placeholder={t("PLACEHOLDERS.model")}
          onChange={(value) => handleOnChange(value, "insuredProperty.model")}
          required={true}
          value={model.insuredProperty?.model}
          disabled={disableForm}
        />
      </FormRow> */}

      <ButtonsWrapper>
        <ButtonCancel onClick={onPreviousStep}>
          {t("BUTTONS.previous")!}
        </ButtonCancel>

        <ButtonConfirm onClick={onNextStep} disabled={!enableNextButton}>
          {t("BUTTONS.next")!}
        </ButtonConfirm>
      </ButtonsWrapper>

      {/* <pre>{JSON.stringify(createClaimData?.selectedPolicy, null, 2)}</pre> */}
    </PolicyInfoStepWrapper>
  );
};

export default PolicyInfoStep;
