import styled from "styled-components";
import { WidgetWrapper } from "../../../../style/DashbordWidgetWrappers";
import dekra from "../../../../style/dekra";
import { useTranslation } from "react-i18next";
import { IconInVerification, IconRemoveFilter } from "../../../../config/icons";
import { SelectStyled } from "../../../../style/Input";
import { useMemo } from "react";
import { SelectPair } from "../../../../types/common.types";
import { AccountingReport } from "../../../../redux/apiSpecifications/apiFesf";
import { dateToNumber } from "../../../../utils/date";

const RowWrapper = styled.div<{ gridArea?: string }>`
    grid-area: ${(props) => (props.gridArea ? props.gridArea : "")};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;
`;

const StatusesWrapper = styled(WidgetWrapper)`
    align-items: center;
    justify-content: space-between;
    font-size: 1.1em;

    .status {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 0.5em 1em;
        line-height: 1em;

        border-right: 2px solid #bbb;

        &:last-child {
            border-right: none;
        }

        svg {
            font-size: 1.4em;
            color: #aaa;
        }

        .status-appraisals {
            display: flex;
            flex: 1 1 0%;
            align-items: center;
            color: #333;
            font-size: 1em;
            text-transform: uppercase;
            letter-spacing: 1px;

            .text {
                margin-left: 0.5em;
                align-self: flex-end;
            }

            .value {
                margin-left: 0.5em;
                font-weight: bold;
            }
        }
    }

    .reset-filter {
        padding: 0 1em;
        font-size: 1.4em;
        color: #555;

        .icon-wrapper {
            cursor: pointer;
            margin-top: 5px;
        }
    }
`;

export interface IReportAppraisalsStatutesProps {
    managedTotal?: number;
    toBeAccountedTotal?: number;
    reportList?: AccountingReport[];
    selectedReportId?: number;
    onSelectReport: (reportId: number) => void;
    onResetFilter: () => void;
    gridArea?: string;
}

const ReportAppraisalsStatutes = ({
    managedTotal,
    toBeAccountedTotal,
    reportList,
    selectedReportId,
    onSelectReport,
    onResetFilter,
    gridArea,
}: IReportAppraisalsStatutesProps) => {
    const { t } = useTranslation();

    const reportsOptions = useMemo(() => {
        const reportsOptionsArray: SelectPair[] = [];

        const sortedReportList = reportList?.sort((a, b) => {
            const aa = dateToNumber(a?.creationDate);
            const bb = dateToNumber(b?.creationDate);

            return aa! > bb! ? -1 : aa! < bb! ? 1 : 0;
        });

        if (sortedReportList) {
            sortedReportList.forEach((report) => {
                reportsOptionsArray.push({
                    value: report.id,
                    label: report.name!,
                });
            });
        }

        return reportsOptionsArray;
    }, [reportList]);

    return (
        <RowWrapper gridArea={gridArea}>
            <StatusesWrapper flex={1}>
                <div className="status">
                    <div className="status-appraisals">
                        <div className="text">
                            {t("dashboard-g-managed-total-label")}
                        </div>
                        <div className="value">{managedTotal}</div>
                    </div>
                </div>

                <div className="status">
                    <div className="status-appraisals">
                        <div className="text">
                            {t("dashboard-g-to-be-accounted-total-label")}
                        </div>
                        <div className="value">{toBeAccountedTotal}</div>
                    </div>
                </div>
            </StatusesWrapper>

            <StatusesWrapper flex={1}>
                <div className="status">
                    <div className="status-appraisals">
                        <IconInVerification />
                        <SelectStyled
                            onChange={(val) => onSelectReport(val)}
                            value={selectedReportId}
                            options={reportsOptions}
                        />
                    </div>
                </div>
            </StatusesWrapper>

            <StatusesWrapper>
                <div className="reset-filter">
                    <div className="icon-wrapper" onClick={onResetFilter}>
                        <IconRemoveFilter />
                    </div>
                </div>
            </StatusesWrapper>
        </RowWrapper>
    );
};

export default ReportAppraisalsStatutes;
