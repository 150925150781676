import styled from "styled-components";
import { mainTabs } from "../../../config/testIds";
import { useMemo, useState } from "react";

import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { AppraisersListItem } from "../../../redux/apiSpecifications/apiFesf";
import { t } from "i18next";
import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { ButtonConfirm } from "../../Layout/Buttons";
import AppraisersTypeFilter, {
    AppraisersTypes,
} from "../Appraisers/AppraisersTypeFilter";
import { SupplierNetworkFilterType } from "./SupplierNetworkFilter";
import moment from "moment";
import { DateFormat } from "../../../config/const";

const testIds = mainTabs.tabs.supplierNetwork.appriasersList;

const AppraisersListWrapper = styled(WidgetWrapper)`
    flex-direction: column;
    align-items: stretch;

    .widget-title {
        display: flex;
        align-items: center;
        padding: 0.5em 2em;
        display: flex;
        color: #333;
        background-color: #ededed;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: bold;

        .text {
            flex: 1;
        }

        .refresh-icon {
            cursor: pointer;
            font-size: 1.2em;
            height: 18px;
        }
    }

    .body {
        flex-direction: column;
        gap: 0.2em;
        padding: 2em;

        .MuiBox-root {
            justify-content: flex-start;
            left: 5px;
        }

        .MuiPaper-root {
            box-shadow: none;
            width: 100%;
        }
    }

    .MuiTableRow-head {
        .MuiInputBase-root {
            input {
                margin-left: 5px;
            }
        }
    }
`;

const CellWrapper = styled.div`
    cursor: pointer;
    padding: 1em 0;

    .button-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        button {
            font-size: 0.8em;
        }
    }
`;

export type AppriasersListDataItem = {
    id: number;
    status: string;
    txtStatus: string;
    isInternal: boolean;
    txtIntExt: string;
    nominative: string;
    contact: string;
    managed: number;
    dailyManaged: number;
    leftMonthly: number;
    monthlyManageable: number;
    availability: string;
};

interface ISupplierNetworkAppraisersListProps {
    isActive?: boolean;
    items: AppraisersListItem[] | undefined;
    // districtCode?: string;
    filter?: SupplierNetworkFilterType;
    onSelectAppraiser?: (id: number, nominative: string) => void;
    onEditAppraiserStatus?: (id: number) => void;
    onShowAppraiserOnMap?: (appraiserId: number) => void;
}

const SupplierNetworkAppraisersList = (
    props: ISupplierNetworkAppraisersListProps
) => {
    const [selectedType, setSelectedType] =
        useState<AppraisersTypes>("external");

    const {
        isActive = false,
        items,
        // districtCode, 
        filter,
        onSelectAppraiser,
        onEditAppraiserStatus,
        onShowAppraiserOnMap,
    } = props;

    const handleFormateNominative = (
        appraiserName: string | undefined
    ): string => {
        let nominative: string = "";

        if (appraiserName != undefined) {
            let arrStr = appraiserName.split(" ");
            const arr = arrStr?.map((str) => {
                return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            });
            nominative = arr.join(" ");
        }

        return nominative;
    };

    const calculateAppraisersTypeCounts = (items: AppraisersListItem[]) => {
        let externalCount = 0;
        let internalCount = 0;
        items.forEach((item) => {
            if (item.isInternal) {
                internalCount++;
            } else {
                externalCount++;
            }
        });
        const allCount = items.length;
        return {
            external: externalCount,
            internal: internalCount,
            all: allCount,
        };
    };

    const appraisersTypeCounts = useMemo(() => {
        if (items) {
            return calculateAppraisersTypeCounts(items);
        } else {
            return { external: 0, internal: 0, all: 0 };
        }
    }, [items]);

    const data = useMemo(
        () =>
            items
                ?.filter((item) => {
                    if (selectedType === "all") return true;
                    if (selectedType === "internal") return item.isInternal;
                    if (selectedType === "external") return !item.isInternal;
                    return false;
                })
                .map(
                    (item) =>
                        ({
                            id: item.id,
                            isInternal: item.isInternal,
                            txtIntExt: item.isInternal
                                ? t(
                                      "supplier-network-dashboard-appriasers-list-internal-label"
                                  )!
                                : t(
                                      "supplier-network-dashboard-appriasers-list-external-label"
                                  )!,
                            nominative: handleFormateNominative(
                                item.appraiserName
                            ),
                            contact: item.appraiserContact,
                            managed: item.practiceCountDaily,
                            dailyManaged: item.maxPracticeCountDaily ?? 0,
                            leftMonthly: item.practiceCountLeftMonthly ?? 0,
                            monthlyManageable:
                                item.maxPracticeCountMonthly ?? 0,
                            availability: `${item.practiceCountDaily} / ${item.maxPracticeCountDaily}`,
                            status:
                                (filter?.districtCode ?? "") === "" ? "-" : item.status,
                        } as AppriasersListDataItem)
                ) || [],
        [items, selectedType, filter?.districtCode]
    );

    const resultsColumns = useMemo<MRT_ColumnDef<AppriasersListDataItem>[]>(
        () => [
            {
                accessorKey: "txtIntExt",
                header: t(
                    "supplier-network-dashboard-appriasers-list-col-is-internal-label"
                ),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        {row.original.txtIntExt}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "nominative",
                header: t(
                    "supplier-network-dashboard-appriasers-list-col-nominative-label"
                ),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        {row.original.nominative}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "contact",
                header: t(
                    "supplier-network-dashboard-appriasers-list-col-contact-label"
                ),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        {row.original.contact}
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "managed",
                header: t(
                    "supplier-network-dashboard-appriasers-list-col-managed-label"
                ),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        <div style={{ textAlign: "center" }}>
                            {filter?.date !== moment().format(DateFormat) ? "-" : row.original.managed}
                        </div>
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "dailyManaged",
                header: t(
                    "supplier-network-dashboard-appriasers-list-col-daily-managed-label"
                ),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        <div style={{ textAlign: "center" }}>
                            {row.original.dailyManaged}
                        </div>
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "leftMonthly",
                header: t(
                    "supplier-network-dashboard-appriasers-list-col-left-monthly-label"
                ),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        <div style={{ textAlign: "center" }}>
                            {filter?.date !== moment().format(DateFormat) ? "-" : row.original.leftMonthly}
                        </div>
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "monthlyManageable",
                header: t(
                    "supplier-network-dashboard-appriasers-list-col-monthly-manageably-label"
                ),
                size: 100,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        <div style={{ textAlign: "center" }}>
                            {row.original.monthlyManageable}
                        </div>
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "status",
                header: t(
                    "supplier-network-dashboard-appriasers-list-col-status-label"
                ),
                size: 50,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        <div style={{ paddingLeft: "2em" }}>
                            {row.original.status}
                        </div>
                    </CellWrapper>
                ),
            },
            {
                accessorKey: "id",
                header: "",
                enableSorting: false,
                enableColumnDragging: false,
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <CellWrapper onClick={() => handleSelectRow(row.original)}>
                        <div
                            className="button-wrapper"
                            style={{ flexDirection: "column", gap: "1em" }}
                        >
                            <ButtonConfirm
                                size="small"
                                onClick={() =>
                                    handleShowAppraiserOnMap(row.original)
                                }
                            >
                                {
                                    t(
                                        "supplier-network-dashboard-appriasers-list-col-filter-button"
                                    )!
                                }
                            </ButtonConfirm>
                        </div>
                    </CellWrapper>
                ),
            },
        ],
        [items]
    );

    const handleSelectRow = (row: AppriasersListDataItem) => {
        onSelectAppraiser && onSelectAppraiser(row.id, row.nominative);
    };

    const handleAppriserStatus = (row: AppriasersListDataItem) => {
        onEditAppraiserStatus && onEditAppraiserStatus(row.id);
    };

    const handleShowAppraiserOnMap = (row: AppriasersListDataItem) => {
        onShowAppraiserOnMap && onShowAppraiserOnMap(row.id);
    };

    return (
        <AppraisersListWrapper data-testid={testIds.container}>
            <div className="widget-title">
                <div className="text">
                    {t("supplier-network-dashboard-appriasers-list-title")}
                </div>
            </div>

            <div className="body">
                <AppraisersTypeFilter
                    external={appraisersTypeCounts.external}
                    internal={appraisersTypeCounts.internal}
                    all={appraisersTypeCounts.all}
                    selected={selectedType}
                    onSelect={(type: AppraisersTypes) => setSelectedType(type)}
                />

                <MaterialReactTable
                    columns={resultsColumns}
                    data={data}
                    enableDensityToggle={false}
                    enableColumnOrdering
                    muiTableBodyCellProps={{
                        sx: {
                            textAlign: "left",
                        },
                    }}
                />
            </div>
        </AppraisersListWrapper>
    );
};

export default SupplierNetworkAppraisersList;
