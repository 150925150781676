import { useTranslation } from "react-i18next";
import { Contact } from "../../../../../redux/apiSpecifications/apiCrud";
import styled from "styled-components";
import { FormRow } from "../../../../../style/form";
import SubjectContactType from "../../../../Inputs/SelectSubjectContactType";
import { SubjectContactDataType } from "../../../../../types/uses-data.types";
import { IconDelete } from "../../../../../config/icons";
import { RowSpacer } from "../../../../../style/containers";
import {
    InputTextStyled,
    SelectStyled,
    SwitchStyled,
} from "../../../../../style/Input";
import { useEffect, useMemo, useState } from "react";
import { ValidationStatuses } from "../../../../../types/common.types";

const ContactWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    // max-width: 900px;

    .title {
        text-transform: uppercase;
    }

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #f5f5f5;
        padding: 0.25em 1em;
    }

    .data {
        padding: 1em 2em;
    }

    .delete-icon-button {
        font-size: 1.6em;
        color: red;
        svg {
            cursor: pointer;
        }
        margin: 0 0.5em -5px 0;
    }
`;

const defaultValidationResult: ValidationStatuses = "success";

interface IAppraiserDetailsContactProps {
    contact: Contact | undefined;
    index: number;
    availableUseCases: string[] | undefined;
    onDelete: (index: number) => void;
    onChange: (contact: Contact, index: number) => void;
}

const AppraiserDetailsContact = (props: IAppraiserDetailsContactProps) => {
    const { t } = useTranslation();
    const { contact, index, availableUseCases, onDelete, onChange } = props;

    const [validation, setValidation] = useState<ValidationStatuses>(
        defaultValidationResult
    );
    const [help, setHelp] = useState<string | undefined | null>("");

    const handleOnChange = (value: any, field: string) => {
        let updatedContact = { ...contact, [field]: value };

        if (field === "type") {
            updatedContact = { ...updatedContact, value: "" };
        }

        onChange(updatedContact, index);
    };

    useEffect(() => {
        switch (contact?.type) {
            case "T":
            case "C":
            case "W":
                if (/^[\d\s]*$/.test(contact?.value as string)) {
                    setValidation(defaultValidationResult);
                    setHelp("");
                } else {
                    const validationResult: ValidationStatuses = "error";
                    setValidation(validationResult);
                    setHelp("appraiser-details-contact-validation-number");
                }
                break;
            case "E":
            case "P":
                if (
                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$|^$/.test(
                        contact?.value as string
                    )
                ) {
                    setValidation(defaultValidationResult);
                    setHelp("");
                } else {
                    const validationResult: ValidationStatuses = "error";
                    setValidation(validationResult);
                    setHelp("appraiser-details-contact-validation-mail");
                }
                break;
        }
    }, [contact?.value]);

    const useCasesOptions = useMemo(() => {
        if (!availableUseCases) return [];

        return availableUseCases.map((useCase) => ({
            value: useCase,
            label: t(`subject-contact-use-case-${useCase}`),
        }));
    }, [availableUseCases]);

    return (
        <ContactWrapper>
            <div className="header">
                <div className="title">
                    {index + 1}°{" "}
                    {t("edit-expertizer-management-contacts-contact-title")}
                </div>

                <div
                    className="delete-icon-button"
                    onClick={() => onDelete(index)}
                >
                    <IconDelete />
                </div>
            </div>

            <div className="data">
                <FormRow>
                    <SubjectContactType
                        index={index || -1}
                        onChange={(type) => handleOnChange(type.value, "type")}
                        type={contact?.type as SubjectContactDataType}
                    />

                    <RowSpacer />

                    <SelectStyled
                        label={t("subject-contact-use-case-label")}
                        tooltip={t("subject-contact-use-case-tooltip")}
                        onChange={(useCase) =>
                            handleOnChange(useCase, "useCase")
                        }
                        value={contact?.useCase}
                        options={useCasesOptions}
                    />

                    <RowSpacer />

                    <SwitchStyled
                        label={t("switch-preferred-label")}
                        checkedChildren={t("switch-yes")}
                        unCheckedChildren={t("switch-no")}
                        checked={contact?.preferred}
                        onChange={(preferred) =>
                            handleOnChange(preferred, "preferred")
                        }
                    />
                    <InputTextStyled
                        label={t(`subject-contact-type-${contact?.type}`)}
                        value={contact?.value}
                        onChange={(value) =>
                            handleOnChange(value.toString(), "value")
                        }
                        validateStatus={validation}
                        help={t(help as string)}
                    />

                    <RowSpacer />

                    <InputTextStyled
                        styleItem={{ flex: 2 }}
                        label={t("subject-contact-description-label")}
                        value={contact?.description}
                        onChange={(description) =>
                            handleOnChange(description, "description")
                        }
                    />
                </FormRow>
            </div>
        </ContactWrapper>
    );
};

export default AppraiserDetailsContact;
