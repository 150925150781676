import { ButtonConfirm } from "../../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import { mainTabs } from "../../../../../config/testIds";
import styled from "styled-components";
import {
    AppraisalSubject,
    OutOfCoverageAppraisalSubject,
    ReadOnlyPractice,
} from "../../../../../redux/apiSpecifications/apiCrud";
import OutOfCoverage from "./OutOfCoverage";

const testIds = mainTabs.tabs.activities.acceptPractice;

const AcceptPracticeWrapper = styled.div`
    .confirm-practice,
    .confirm-out-of-zone {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 2em;

        .label {
            text-transform: uppercase;
            flex: 1;
        }
    }
`;

interface IAcceptPracticeProps {
    practice: ReadOnlyPractice | undefined;
    onConfirmPractice: () => void;
    onConfirmOutOfArea: (subject: OutOfCoverageAppraisalSubject) => void;
}

const AcceptPractice = (props: IAcceptPracticeProps) => {
    const { practice, onConfirmPractice, onConfirmOutOfArea } = props;
    const { t } = useTranslation();

    const { status } = practice || {};

    return (
        <AcceptPracticeWrapper data-testid={testIds.container}>
            {status === "PA" && (
                <div className="confirm-practice">
                    <div className="label">
                        {t(
                            "appraisals-details-tab-activities-collapsable-confirm-practice-label"
                        )}
                    </div>
                    <ButtonConfirm
                        onClick={onConfirmPractice}
                        dataTestId={testIds.buttonAcceptPractice}
                    >
                        {
                            t(
                                "appraisals-details-tab-activities-collapsable-confirm-practice-button"
                            )!
                        }
                    </ButtonConfirm>
                </div>
            )}
            {status === "AD" && (
                <OutOfCoverage
                    appraisalLocation={practice?.appraisalLocation || undefined}
                    onConfirmOutOfArea={onConfirmOutOfArea}
                />
            )}
        </AcceptPracticeWrapper>
    );
};

export default AcceptPractice;
