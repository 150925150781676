import { useTranslation } from "react-i18next";
import {
  DatePickerStyled,
  InputTextStyled,
  TimePickerStyled,
} from "../../../../../../../style/Input";
import SelectUserTenants from "../../../../../../Inputs/SelectUserTenants";
import styled from "styled-components";
import { useCreateClaimContext } from "../CreateClaimContext";
import { Address } from "../../../../../../../redux/apiSpecifications/apiFesf";

const CreateClaimQuickFormSummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr 1fr 1fr 1fr;
  gap: 1em;

  .ant-col.ant-form-item-label {
    text-align: left;
    width: 100%;
  }
`;

const fullAddress = (address?: Address) => {
  if (address === undefined) return "";

  const street = address.street ? `${address.street} ` : "";
  const civic = address.civic ? `${address.civic} ` : "";
  const city = address.city ? `${address.city} ` : "";
  const postalCode = address.postalCode ? `${address.postalCode} ` : "";
  const province = address.province ? `${address.province} ` : "";
  const country = address.country ? `${address.country} ` : "";

  return `${street}${civic}${city}${postalCode}${province}${country}`;
};

const CreateClaimQuickFormSummary = () => {
  const { t } = useTranslation();

  const { createClaimData } = useCreateClaimContext();

  return (
    <>
      <CreateClaimQuickFormSummaryWrapper>
        <SelectUserTenants
          label={t("LABELS.company")}
          value={createClaimData?.companyId}
          disabled={true}
        />

        <InputTextStyled
          label={t("LABELS.place-happening")}
          value={fullAddress(createClaimData?.address)}
          onChange={(value) => {}}
          disabled={true}
        />

        <DatePickerStyled
          label={t("LABELS.date-happening")}
          styleInput={{ flexGrow: 4 }}
          value={createClaimData?.date}
          onChange={(value) => {}}
          placeholder={"gg/mm/aaaa"}
          disabled={true}
        />

        <TimePickerStyled
          label={t("LABELS.time-happening")}
          value={createClaimData?.time}
          onChange={(value) => {}}
          disabled={true}
        />

        <div style={{ minWidth: 140 }}>
          <InputTextStyled
            label={t("LABELS.plate-number")}
            onChange={(value) => {}}
            value={createClaimData?.plateNumber}
            disabled={true}
          />
        </div>
      </CreateClaimQuickFormSummaryWrapper>
    </>
  );
};

export default CreateClaimQuickFormSummary;
