import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { mainTabs } from "../../../config/testIds";

import { WidgetWrapper } from "../../../style/DashbordWidgetWrappers";
import { useEffect, useState } from "react";
import MapBase, { Marker } from "../../Maps/MapBase";

const testIds = mainTabs.tabs.supplierNetwork.appraiserStatusDialog.mapLegend;

export const StatusColor = {
  T: "#8fc123",
  A: "#00c123",
  M: "#00c123", // attivo con automatismo, da eliminare ?????
  S: "#ffcc00",
  F: "#ef7c00", // Ferie
  B: "#afabab",
  C: "#3b3838",
};

const MapLegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  padding-bottom: 1em;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 1em;

    .legend-item {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      font-size: 0.8em;
      text-transform: uppercase;

      .legend-circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-top: -3px;

        margin-right: 5px;
      }
    }
  }
`;

const SupplierNetworkAppraiserStatusDialogMapLegend = () => {
  return (
    <MapLegendWrapper data-testid={testIds.container}>
      <div className="row">
        <LegendItem status="T" />
        <LegendItem status="A" />
        <LegendItem status="S" />
      </div>
      <div className="row">
        <LegendItem status="F" />
        <LegendItem status="B" />
        <LegendItem status="C" />
      </div>
    </MapLegendWrapper>
  );
};

interface ILegendItemProps {
  status: keyof typeof StatusColor;
}

const LegendItem = (props: ILegendItemProps) => {
  const { status } = props;
  const { t } = useTranslation();

  let text = t(
    `supplier-network-dashboard-appriaser-status-dialog-status-${status}`
  );

  if (text.length >= 15) text = text.substring(0, 14) + ".";

  return (
    <div className={`legend-item`}>
      <div
        className="legend-circle"
        style={{ backgroundColor: StatusColor[status] }}
      ></div>
      <div className="legende-text">{text}</div>
    </div>
  );
};

export default SupplierNetworkAppraiserStatusDialogMapLegend;
