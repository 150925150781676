import { useEffect } from "react";
import {
  useCreateClaimContext,
  useCreateClaimDispatch,
} from "../CreateClaimContext";
import { Alert } from "antd";
import PoliciesList from "../../../../../../Widgets/Policies/PoliciesList";
import styled from "styled-components";
import { ButtonConfirm } from "../../../../../../Layout/Buttons";
import { BasePolicy } from "../../../../../../../redux/apiSpecifications/apiClaims";
import { SearchedPoliciesItem } from "../../../../../../../redux/apiSpecifications/apiPolicies";
import { useNotifications } from "../../../../../../../hooks/useNotifications";
import { usePolicy } from "../../../../../../../hooks/usePolicy";
import useSearchValidPolicies from "../../../../../../../hooks/useSearchValidPolicies";
import { useTranslation } from "react-i18next";

const PoliciesListStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-end;
`;

interface IPoliciesListStepProps {
  onNextStep: () => void;
}

const PoliciesListStep = ({ onNextStep }: IPoliciesListStepProps) => {
  const { t } = useTranslation();

  const { createClaimData } = useCreateClaimContext();

  const dispatch = useCreateClaimDispatch();

  const { displayFetchBaseQueryErrorNotification } = useNotifications();

  const { policiesList, searchValidPoliciesPromise, changePaginationPromise } =
    useSearchValidPolicies();

  const { getPolicyPromise } = usePolicy();

  useEffect(() => {
    loadPolicies();
  }, []);

  const loadPolicies = async () => {
    dispatch!({ type: "SET_LOADING_STATUS", payload: "refreshing" });

    return searchValidPoliciesPromise({
      pageNum: 1,
      pageLen: 5,
      searchValidPoliciesParams: {
        tenantId: createClaimData?.companyId,
        insuredVehiclePlateNumber: createClaimData?.plateNumber,
        eventDate: createClaimData?.date,
      },
    })
      .then(() => {
        dispatch!({ type: "SET_LOADING_STATUS", payload: "idle" });
      })
      .catch((error) => {
        displayFetchBaseQueryErrorNotification(error);

        dispatch!({ type: "SET_LOADING_STATUS", payload: "idle" });
      });
  };

  const handleOnCreateNewPolicy = () => {
    dispatch!({
      type: "CHANGE_CLAIM_DATA",
      payload: {
        value: {
          tenantId: createClaimData?.companyId,
          insuredProperty: {
            plateNumber: createClaimData?.plateNumber,
          },
          guarantees: [],
        } as BasePolicy,
        field: "selectedPolicy",
      },
    });

    onNextStep();
  };

  const handleOnSelectPolicy = (policy: SearchedPoliciesItem) => {
    dispatch!({ type: "SET_LOADING_STATUS", payload: "refreshing" });

    return getPolicyPromise(policy.id!).then((policy) => {
      dispatch!({
        type: "CHANGE_CLAIM_DATA",
        payload: { value: policy as BasePolicy, field: "selectedPolicy" },
      });

      onNextStep();

      dispatch!({ type: "SET_LOADING_STATUS", payload: "idle" });
    });
  };

  const handlePaginationChange = async (
    pageIndex: number,
    pageSize: number
  ) => {
    dispatch!({ type: "SET_LOADING_STATUS", payload: "refreshing" });

    return changePaginationPromise(pageIndex, pageSize).then(() => {
      dispatch!({ type: "SET_LOADING_STATUS", payload: "idle" });
    });
  };

  return (
    <PoliciesListStepWrapper>
      {policiesList && policiesList.items && (
        <>
          {policiesList.items.length > 0 && (
            <Alert
              message={t("create-claim-wizard-select-policy-message")}
              type="info"
            />
          )}

          {policiesList.items.length === 0 && (
            <Alert
              message={t("create-claim-wizard-policy-not-found-message")}
              type="info"
            />
          )}

          <ButtonsWrapper>
            <ButtonConfirm onClick={handleOnCreateNewPolicy}>
              {t("BUTTONS.create-new-policy")!}
            </ButtonConfirm>
          </ButtonsWrapper>

          {policiesList.items.length > 0 && (
            <PoliciesList
              data={policiesList}
              onSelectItem={handleOnSelectPolicy}
              onPaginationChange={handlePaginationChange}
            />
          )}
        </>
      )}
    </PoliciesListStepWrapper>
  );
};

export default PoliciesListStep;
