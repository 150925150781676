import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { setInfoAlwaysVisibleTop } from "../../../../../redux/features/userSlice";
import {
  CreatedClaim,
  DashboardA1Summary,
  ReadOnlyClaim,
  SearchedClaimsList,
  useLazyGetClaimsSummaryA1Query,
  useSearchClaimsMutation,
} from "../../../../../redux/apiSpecifications/apiClaims";
import ClaimsDonutsHighlighted, {
  ClaimsDonutKey,
} from "./ClaimsDonutsHighlighted/ClaimsDonutsHighlighted";
import CreateClaim from "./CreateClaim/CreateClaim";
import CreateSubject from "./CreateSubject/CreateSubject";
import ClaimsTable from "./ClaimsTable";
import {
  DashboardWrapper,
  LoadingInTabWrapper,
  RefreshInTabWrapper,
} from "../../../../../style/DashbordWidgetWrappers";
import { IconLoading } from "../../../../../config/icons";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { CreateClaimData } from "./CreateClaim/CreateClaimReducer";
import { usePolicy } from "../../../../../hooks/usePolicy";
import {
  BasePolicy,
  Guarantee,
} from "../../../../../redux/apiSpecifications/apiPolicies";
import { useClaims } from "../../../../../hooks/useClaims";
import { useNotifications } from "../../../../../hooks/useNotifications";

const DashboardA1Wrapper = styled(DashboardWrapper)`
  display: grid;
  grid-template-areas:
    "claimsInEvidence createClaim createSubject"
    "claimsList claimsList claimsList"
    "spacer spacer spacer";
  grid-template-columns: 3fr 2fr 2fr;
  grid-template-rows: min-content auto auto;
  column-gap: 1em;
  row-gap: 1em;
`;

type SearchClaimsParams = {
  pageNum: number;
  pageLen: number;
  companyList: number[];
  claimStatus?: string;
  isReopened?: boolean;
};

type LoadingStatus = "idle" | "loading" | "refreshing" | undefined;
type SavingStatus = "idle" | "saving" | undefined;

const PAGE_LEN: number = 5;

interface IDashboardA1Props {
  tabKey?: number;
}

const DashBoard_A1: React.FC<IDashboardA1Props> = ({ tabKey }) => {
  const { t } = useTranslation();

  const { displayFetchBaseQueryErrorNotification } = useNotifications();

  const dispatch = useDispatch();

  const { selectedTenants, activeRole } = useSelector(
    (state: RootState) => state.user
  );

  const { activeKey } = useSelector((state: RootState) => state.mainTabs);

  const [loadingSummaryStatus, setLoadingSummaryStatus] =
    useState<LoadingStatus>(undefined);

  const [loadingClaimsListStatus, setLoadingClaimsListStatus] =
    useState<LoadingStatus>(undefined);

  const [createClaimsStatus, setCreateClaimsStatus] =
    useState<SavingStatus>(undefined);

  const [summary, setSummary] = useState<DashboardA1Summary | undefined>(
    undefined
  );

  const [claimsList, setClaimsList] = useState<
    SearchedClaimsList | undefined
  >();

  const [searchClaimsParams, setSearchClaimsParams] = useState<
    SearchClaimsParams | undefined
  >(undefined);

  const { createClaimDraftPromise } = useClaims();
  const { addPolicyGuaranteePromise, createPolicyPromise } = usePolicy();

  const [claimsSummaryData] = useLazyGetClaimsSummaryA1Query();
  const [searchClaims] = useSearchClaimsMutation();

  const loadingStatus: LoadingStatus = useMemo(() => {
    if (
      loadingSummaryStatus === "loading" ||
      loadingClaimsListStatus === "loading"
    )
      return "loading";

    if (
      loadingSummaryStatus === "refreshing" ||
      loadingClaimsListStatus === "refreshing" ||
      createClaimsStatus === "saving"
    )
      return "refreshing";

    if (
      loadingSummaryStatus === "idle" ||
      loadingClaimsListStatus === "idle" ||
      createClaimsStatus === "idle"
    )
      return "idle";

    return undefined;
  }, [loadingSummaryStatus, loadingClaimsListStatus, createClaimsStatus]);

  const selectedDonutItem: string | undefined = useMemo(() => {
    if (searchClaimsParams?.claimStatus !== undefined) {
      if (searchClaimsParams.isReopened === undefined)
        return `inDraft-${searchClaimsParams.claimStatus}`;

      if (searchClaimsParams.isReopened) {
        return `inProgressReopened-${searchClaimsParams.claimStatus}`;
      }

      return `inProgress-${searchClaimsParams.claimStatus}`;
    }

    return undefined;
  }, [searchClaimsParams?.claimStatus, searchClaimsParams?.isReopened]);

  // Primo caricamento o riattivazione del tab
  useEffect(() => {
    if (activeKey === tabKey) {
      if (
        loadingSummaryStatus === undefined ||
        loadingSummaryStatus === "idle"
      ) {
        setLoadingSummaryStatus(
          loadingSummaryStatus ? "refreshing" : "loading"
        );
      }

      if (searchClaimsParams === undefined) {
        setSearchClaimsParams({
          pageLen: PAGE_LEN,
          pageNum: 1,
          companyList: selectedTenants?.map((tenant) => tenant.id!),
        } as SearchClaimsParams);
      } else {
        if (
          loadingClaimsListStatus === undefined ||
          loadingClaimsListStatus === "idle"
        ) {
          setLoadingClaimsListStatus(
            loadingClaimsListStatus === undefined ? "loading" : "refreshing"
          );
        }
      }
    }
  }, [activeKey]);

  // Caricamento Summary
  useEffect(() => {
    if (
      loadingSummaryStatus &&
      ["loading", "refreshing"].includes(loadingSummaryStatus)
    ) {
      (async () => {
        await loadSummary();

        setLoadingSummaryStatus("idle");
      })();
    }
  }, [loadingSummaryStatus]);

  // Caricamento Claims
  useEffect(() => {
    if (
      loadingClaimsListStatus &&
      ["loading", "refreshing"].includes(loadingClaimsListStatus)
    ) {
      (async () => {
        await loadClaims();

        setLoadingClaimsListStatus("idle");
      })();
    }
  }, [loadingClaimsListStatus]);

  // Aggiornamento filtro di ricerca Claims
  useEffect(() => {
    // console.info(searchClaimsParams);
    if (searchClaimsParams !== undefined) {
      if (
        loadingClaimsListStatus === undefined ||
        loadingClaimsListStatus === "idle"
      ) {
        setLoadingClaimsListStatus(
          loadingClaimsListStatus === undefined ? "loading" : "refreshing"
        );
      }
    }
  }, [searchClaimsParams]);

  // useEffect(() => {

  // }, [selectedTenants]);

  const loadSummary = async () => {
    try {
      const tenantsIds = selectedTenants?.map((tenant) => tenant.id!);
      const response = await claimsSummaryData({
        tenants: tenantsIds!,
      });

      if (response?.data) {
        setSummary(response.data);
      }
    } catch (err) {
      dispatch(
        setInfoAlwaysVisibleTop({
          type: "error",
          message: t("http-error-loading-dashboard"),
        })
      );
    }
  };

  const loadClaims = async () => {
    // const { pageNum, pageLen, companyList, claimStatus, isReopened } =
    //     searchClaimsParams;

    const response = await searchClaims({
      pageNum: searchClaimsParams?.pageNum!,
      pageLen: searchClaimsParams?.pageLen!,
      scenario: "dashboard",
      activeRole: activeRole!,
      searchClaimsParams: {
        companyList: searchClaimsParams?.companyList,
        claimStatus: searchClaimsParams?.claimStatus,
        isReopened: searchClaimsParams?.isReopened,
      },
    });

    const correctResponse = response as {
      data: SearchedClaimsList;
    };

    if (correctResponse && correctResponse.data) {
      setClaimsList(correctResponse.data);
    } else {
      const errorResponse = response as {
        error: FetchBaseQueryError;
      };
    }
  };

  const handleDonutItemClick = (donutKey: ClaimsDonutKey, itemKey: string) => {
    setSearchClaimsParams({
      ...searchClaimsParams!,
      pageNum: 1,
      pageLen: PAGE_LEN,
      claimStatus: itemKey,
      isReopened:
        donutKey === "inDraft" ? undefined : donutKey === "inProgressReopened",
    });
  };

  const handleResetFilter = () => {
    setSearchClaimsParams({
      ...searchClaimsParams!,
      pageNum: 1,
      pageLen: PAGE_LEN,
      claimStatus: undefined,
      isReopened: undefined,
    });
  };

  const handlePaginationChange = (pageIndex: number, pageSize: number) => {
    setSearchClaimsParams({
      ...searchClaimsParams!,
      pageNum: pageIndex,
      pageLen: pageSize,
    });
  };

  const handleOnCreateClaimPromise = (
    createClaimData: CreateClaimData
  ): Promise<ReadOnlyClaim> =>
    new Promise(async (resolve, reject) => {
      const { selectedPolicy, selectedGuarantee } = createClaimData;

      const createdClaim: CreatedClaim = {
        companyId: createClaimData.companyId,
        policyId: selectedPolicy?.id,
        claimsBranchCode: selectedGuarantee?.branch,
        date: createClaimData.date,
        time: createClaimData.time,
        city: createClaimData.address?.city,
        civic: createClaimData.address?.civic,
        country: createClaimData.address?.country,
        latitude: createClaimData.address?.latitude,
        longitude: createClaimData.address?.longitude,
        postalCode: createClaimData.address?.postalCode,
        province: createClaimData.address?.province,
        street: createClaimData.address?.street,
      };

      if (selectedPolicy!.id === undefined) {
        const newPolicy = {
          ...selectedPolicy,
          guarantees: [{ ...selectedGuarantee } as Guarantee],
        } as BasePolicy;

        createPolicyPromise(newPolicy)
          .then((policy) => {
            createdClaim.policyId = policy.id;

            // const createdClaim: CreatedClaim = {
            //   companyId: createClaimData.companyId,
            //   policyId: policy.id,
            //   claimsBranchCode: selectedGuarantee?.branch,
            //   date: createClaimData.date,
            //   time: createClaimData.time,
            //   city: createClaimData.address?.city,
            //   civic: createClaimData.address?.civic,
            //   country: createClaimData.address?.country,
            //   latitude: createClaimData.address?.latitude,
            //   longitude: createClaimData.address?.longitude,
            //   postalCode: createClaimData.address?.postalCode,
            //   province: createClaimData.address?.province,
            //   street: createClaimData.address?.street,
            // };

            createClaimDraftPromise(createdClaim)
              .then((claim) => {
                resolve(claim);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      } else if (selectedGuarantee!.id === undefined) {
        addPolicyGuaranteePromise(selectedPolicy!.id, selectedGuarantee!)
          .then((guarantees) => {
            // const createdClaim: CreatedClaim = {
            //   companyId: createClaimData.companyId,
            //   policyId: selectedPolicy?.id,
            //   claimsBranchCode: selectedGuarantee?.branch,
            //   date: createClaimData.date,
            //   time: createClaimData.time,
            //   city: createClaimData.address?.city,
            //   civic: createClaimData.address?.civic,
            //   country: createClaimData.address?.country,
            //   latitude: createClaimData.address?.latitude,
            //   longitude: createClaimData.address?.longitude,
            //   postalCode: createClaimData.address?.postalCode,
            //   province: createClaimData.address?.province,
            //   street: createClaimData.address?.street,
            // };

            createClaimDraftPromise(createdClaim)
              .then((claim) => {
                resolve(claim);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        createClaimDraftPromise(createdClaim)
          .then((claim) => {
            resolve(claim);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });

  const handleOnCreateClaim = (
    createClaimData: CreateClaimData,
    complete: boolean
  ) => {
    setCreateClaimsStatus("saving");

    handleOnCreateClaimPromise(createClaimData)
      .then((claim) => {
        setCreateClaimsStatus("idle");
        setLoadingSummaryStatus("refreshing");
        setLoadingClaimsListStatus("refreshing");
      })
      .catch((error) => {
        setCreateClaimsStatus("idle");
        displayFetchBaseQueryErrorNotification(error);
      });
  };

  return (
    <div>
      {/* <pre>{JSON.stringify(searchClaimsParams, null, 2)}</pre> */}

      {loadingStatus === "loading" && (
        <LoadingInTabWrapper>
          <div className="box">{IconLoading}</div>
        </LoadingInTabWrapper>
      )}

      {(loadingStatus === "refreshing" || loadingStatus === "idle") && (
        <>
          <DashboardA1Wrapper>
            <ClaimsDonutsHighlighted
              summary={summary}
              selectedDonutItem={selectedDonutItem}
              gridArea="claimsInEvidence"
              onDonutItemClick={handleDonutItemClick}
              onResetFilter={handleResetFilter}
            />

            {/* <div onClick={() => setCreateClaimsStatus("saving")}>click me</div> */}

            <CreateClaim
              gridArea="createClaim"
              onCreateClaim={handleOnCreateClaim}
            />

            <CreateSubject gridArea="createSubject" />

            {claimsList && (
              <ClaimsTable
                data={claimsList}
                onPaginationChange={handlePaginationChange}
                gridArea="claimsList"
              />
            )}
          </DashboardA1Wrapper>

          {loadingStatus === "refreshing" && (
            <RefreshInTabWrapper>
              <div className="box">{IconLoading}</div>
            </RefreshInTabWrapper>
          )}
        </>
      )}
    </div>
  );
};

export default DashBoard_A1;
