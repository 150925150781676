import { getAzureMapAddressConfig } from "./azureConfig";

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
const localhostCrud = "http://localhost:7071";
const localhostFesf = "http://localhost:7072";

export const baseUrlCrud = isDev ? `${localhostCrud}/api` : "/api";
export const attachmentApiBaseUrl = isDev ? `${localhostCrud}/api` : "/api";
export const baseUrlFesf = isDev ? `${localhostFesf}/fesf` : "/fesf";

export const Urls = {
  subject: {
    get: (id: number) => `${baseUrlCrud}/subjects/${id}`,
    post: (id: number) => `${baseUrlCrud}/subjects/${id}`,
    put: (id: number) => `${baseUrlCrud}/subjects/${id}`,
  },
  me: `${baseUrlFesf}/me`,
  azureAddress: (query: string, language?: string, limit?: number) => {
    const azureMapAddressConfig = getAzureMapAddressConfig();
    const laguageCode =
      (language || azureMapAddressConfig.defaultLanguage) === "it"
        ? "it-IT"
        : "en-US"; // find a better way to convert language to code

    const params = [
      `subscription-key=${azureMapAddressConfig.subscriptionKey}`,
      `api-version=${azureMapAddressConfig.apiVersion}`,
      `language=${laguageCode}`,
      `limit=${limit || azureMapAddressConfig.defaultLimit}`,
      `query=${query}`,
    ];

    return `${azureMapAddressConfig.baseUrl}?${params.join("&")}`;
  },
};
