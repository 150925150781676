import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AreaCoverage } from "../../../../../redux/apiSpecifications/apiFesf";
import { FormRow } from "../../../../../style/form";
import { RowSpacer } from "../../../../../style/containers";
import { InputNumberStyled } from "../../../../../style/Input";
import { IconDelete } from "../../../../../config/icons";
import { mainTabs } from "../../../../../config/testIds";

const testIds = mainTabs.tabs.supplierNetwork.appraiserDetails.management;

const AppraiserDetailsManagementCoverageAreaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em;

  .area-row {
    margin-bottom: 0;
  }

  .name-delere-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 19em;
  }

  .name {
    flex: 1;
  }

  .dispo-input {
    width: 8em;
    flex: 1;
  }

  .zip-code {
    flex: 1;
    text-align: center;
  }

  .button-add-district-wrapper {
    button {
      font-size: 0.9em;
      width: 12em;
    }
  }
`;

interface IAppraiserDetailsManagementCoverageAreaProps {
  coverage: AreaCoverage | undefined;
  onChange: (value: any, field: string) => void;
  onDeleteZone: (id: number) => void;
}

const AppraiserDetailsManagementCoverageArea = (
  props: IAppraiserDetailsManagementCoverageAreaProps
) => {
  const { t } = useTranslation();
  const { coverage, onChange, onDeleteZone } = props;

  return (
    <AppraiserDetailsManagementCoverageAreaWrapper
      data-testid={testIds.coverageData.district.areasCoverage.container}
    >
      <FormRow className="area-row">
        <div className="name-delere-wrapper">
          <div
            className="delete-icon-button"
            onClick={() => onDeleteZone(coverage?.id!)}
            data-testid={
              testIds.coverageData.district.buttonAreaDelete + coverage?.id
            }
          >
            <IconDelete />
          </div>
          <div className="name">{coverage?.city}</div>
        </div>
        <RowSpacer />
        <div className="zip-code">{coverage?.zipCode}</div>
        <RowSpacer />
        {/* <InputNumberStyled
          classNameItem="dispo-input"
          classNameInput="dispo-input"
          value={coverage?.areaMaxPracticeCountDaily}
          onChange={(val) =>
            onChange({ val, id: coverage?.id }, "areaMaxPracticeCountDaily")
          }
          min={0}
          dataTestId={testIds.coverageData.district.maxDaily + coverage?.id}
        /> */}
        <div style={{ flex: 1 }}></div>
        <RowSpacer />
        {/* <InputNumberStyled
          classNameItem="dispo-input"
          classNameInput="dispo-input"
          value={coverage?.areaMaxPracticeCountMonthly}
          onChange={(val) =>
            onChange({ val, id: coverage?.id }, "areaMaxPracticeCountMonthly")
          }
          min={0}
          dataTestId={testIds.coverageData.district.maxMonthly + coverage?.id}
        /> */}
        <div style={{ flex: 1 }}></div>
        <RowSpacer />
        <div style={{ flex: 1 }}></div>
        <RowSpacer />
        <div style={{ flex: 1 }}></div>
      </FormRow>
    </AppraiserDetailsManagementCoverageAreaWrapper>
  );
};

export default AppraiserDetailsManagementCoverageArea;
