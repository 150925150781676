import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { getMsalConfig } from "./config/azureConfig";

import "./style/general.css";
import { isLocalhost, isSandbox } from "./utils/devHelper";

const msalInsance = new PublicClientApplication(getMsalConfig());

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

(async () => {
  root.render(
    // <React.StrictMode>
      <MsalProvider instance={msalInsance}>
        <Provider store={store}>
          <App />
        </Provider>
      </MsalProvider>
    // </React.StrictMode>
  );

  // restrict DEMO mode to localhost & sandbox
  if (isLocalhost())
    if ("serviceWorker" in navigator)
      await navigator.serviceWorker.register("/sw.js");
})();

reportWebVitals();
