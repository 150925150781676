import styled from "styled-components";
import { ButtonCancel, ButtonConfirm } from "../../../../../../Layout/Buttons";
import PolicyDataForm from "./PolicyDataForm";
import { useCreateClaimContext } from "../CreateClaimContext";
import GuaranteeDataForm from "./GuaranteeDataForm";
import { SelectPair } from "../../../../../../../types/common.types";
import InsuredPropertyDataForm from "./InsuredPropertyDataForm";

const SummaryStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-end;
`;

interface ISummaryStepProps {
  onPreviousStep: () => void;
  onCreateClaim: (complete: boolean) => void;
}

const SummaryStep = ({ onPreviousStep, onCreateClaim }: ISummaryStepProps) => {
  const { createClaimData } = useCreateClaimContext();

  return (
    <SummaryStepWrapper>
      <h3>Dati polizza</h3>

      <PolicyDataForm
        model={createClaimData?.selectedPolicy!}
        disableForm={true}
      />

      <h3>Dati veicolo</h3>

      <InsuredPropertyDataForm
        model={createClaimData?.selectedPolicy?.insuredProperty!}
        disableForm={true}
      />

      <h3>Dati garanzia</h3>

      <GuaranteeDataForm
        model={createClaimData?.selectedGuarantee!}
        disableForm={true}
        branchesOptions={[]}
      />

      <ButtonsWrapper>
        <ButtonCancel onClick={onPreviousStep}>Indietro</ButtonCancel>

        <ButtonConfirm onClick={() => onCreateClaim(false)}>Crea</ButtonConfirm>

        <ButtonConfirm onClick={() => onCreateClaim(true)}>
          Crea e completa
        </ButtonConfirm>
      </ButtonsWrapper>
    </SummaryStepWrapper>
  );
};

export default SummaryStep;
