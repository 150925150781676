import styled from "styled-components";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppraiserMapData } from "../../../../../redux/apiSpecifications/apiCrud";

const AppraisersListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
`;

const CellWrapper = styled.div`
  cursor: pointer;
  padding: 1em 0;

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    button {
      font-size: 0.8em;
    }
  }
`;

export type AssignmentMapAppraisersListDataItem = {
  id: number;
  nominative: string;
  managed: number;
  dailyManaged: number;
  leftMonthly: number;
  monthlyManageable: number;
  score: number;
  positions: number[][];
};

interface IAssignmentMapAppraisersListProps {
  items: AppraiserMapData[] | undefined;
  onSelectAppraiser: (idAppraiser: number, positions: number[][]) => void;
}

const AssignmentMapAppraisersList = ({
  items,
  onSelectAppraiser,
}: IAssignmentMapAppraisersListProps) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const handleFormateNominative = (
    appraiserName: string | undefined
  ): string => {
    let nominative: string = "";

    if (appraiserName != undefined) {
      let arrStr = appraiserName.split(" ");
      const arr = arrStr?.map((str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      });
      nominative = arr.join(" ");
    }

    return nominative;
  };

  const data = useMemo(
    () =>
      items?.map(
        (item) =>
          ({
            id: item.id,
            nominative: handleFormateNominative(item.name),
            managed: item.totalManagedPractices,
            dailyManaged: item.totalDailyCapacity ?? 0,
            leftMonthly: item.monthlyResidual ?? 0,
            monthlyManageable: item.totalMonthlyCapacity ?? 0,
            score: item.totalScore ?? 0,
            positions: item.coverageDistricts?.map((_) => [
              _.longitude,
              _.latitude,
            ]),
          } as AssignmentMapAppraisersListDataItem)
      ) || [],
    [items]
  );

  const resultsColumns = useMemo<
    MRT_ColumnDef<AssignmentMapAppraisersListDataItem>[]
  >(
    () => [
      {
        accessorKey: "nominative",
        header: t("assignment-map-appriasers-list-col-nominative-label"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            {row.original.nominative}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "managed",
        header: t("assignment-map-appriasers-list-col-managed-label"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            <div style={{ textAlign: "center" }}>{row.original.managed}</div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "dailyManaged",
        header: t("assignment-map-appriasers-list-col-daily-managed-label"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            <div style={{ textAlign: "center" }}>
              {row.original.dailyManaged}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "leftMonthly",
        header: t("assignment-map-appriasers-list-col-left-monthly-label"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            <div style={{ textAlign: "center" }}>
              {row.original.leftMonthly}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "monthlyManageable",
        header: t(
          "supplier-network-dashboard-appriasers-list-col-monthly-manageably-label"
        ),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            <div style={{ textAlign: "center" }}>
              {row.original.monthlyManageable}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "score",
        header: t("assignment-map-appriasers-list-col-score"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            <div style={{ textAlign: "center" }}>{row.original.score}</div>
          </CellWrapper>
        ),
      },
    ],
    [items]
  );

  const handleSelectRow = (row: AssignmentMapAppraisersListDataItem) => {
    onSelectAppraiser(row.id, row.positions);
  };

  return (
    <AppraisersListWrapper>
      {/* <pre>{JSON.stringify(rowSelection, null, 2)}</pre> */}
      <MaterialReactTable
        columns={resultsColumns}
        data={data}
        enableDensityToggle={false}
        enableColumnOrdering
        onPaginationChange={setPagination}
        state={{ pagination }}
        muiTableBodyCellProps={{
          sx: {
            textAlign: "left",
          },
        }}
      />
    </AppraisersListWrapper>
  );
};

export default AssignmentMapAppraisersList;
