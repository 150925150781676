import { ButtonCancel, ButtonConfirm } from "../../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import { mainTabs } from "../../../../../config/testIds";
import styled from "styled-components";
import {
    Address,
    AppraisalSubject,
    OutOfCoverageAppraisalSubject,
} from "../../../../../redux/apiSpecifications/apiCrud";
import { useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { Popconfirm } from "antd";
import { checkPostalCode, isNotEmpty } from "../../../../../validation/common";
import SubjectDetailsForm from "./SubjectDetailsForm";
import { FormRow } from "../../../../../style/form";
import { TextAreaStyled } from "../../../../../style/Input";

const testIds = mainTabs.tabs.activities.outOfCoverage;

const OutOfCoverageWrapper = styled.div`
    display: flex;
    flex-direction: column;

    .title {
        text-transform: uppercase;
        margin-bottom: 2em;
    }

    .row {
        display: flex;
        gap: 2em;
        flex-direction: row;

        .show-out-of-area-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .text {
                flex: 1;
            }
        }

        .data-wrapper {
            width: 100%;

            .data {
                display: flex;
                flex-direction: row;
                width: 100%;
                gap: 1em;

                .appraisalLocation {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .address-wrapper {
                        .ant-form-item-row {
                            flex-direction: column;
                        }

                        .ant-form-item-label {
                            text-align: left;
                        }
                    }

                    .apprailsaSubject {
                        margin-bottom: 1em;
                    }
                }

                .note {
                    flex: 1;

                    .label {
                        text-transform: uppercase;
                        margin-bottom: 1em;
                        font-size: 0.9em;
                    }
                }
            }

            .button-wrapper {
                display: flex;
                justify-content: flex-end;
                margin-top: 2em;
                gap: 1em;
            }
        }
    }
`;

interface IOutOfCoverageProps {
    appraisalLocation: AppraisalSubject | undefined;
    onConfirmOutOfArea: (subject: OutOfCoverageAppraisalSubject) => void;
}

const OutOfCoverage = (props: IOutOfCoverageProps) => {
    const { t } = useTranslation();

    const [appraisalLocation, setAppraisalLocation] = useState(
        props.appraisalLocation
    );

    const [note, setNote] = useState<string | undefined>();

    const [showOutOfArea, setShowOutOfArea] = useState<boolean>(false);

    const checkAddress = (address: Address): boolean => {
        const { street, civic, city, postalCode, province, country } = address;
        return (
            isNotEmpty(street) &&
            isNotEmpty(civic) &&
            isNotEmpty(city) &&
            checkPostalCode(postalCode?.toString() || "") &&
            isNotEmpty(province) &&
            isNotEmpty(country)
        );
    };

    const [isValid, setIsValid] = useState(
        checkAddress(appraisalLocation?.address || {})
    );

    const handleOnChangeSubjectDetails = (
        subject: AppraisalSubject,
        isValid: boolean
    ) => {
        setAppraisalLocation(subject);

        console.info(isValid)

        setIsValid(isValid);
    };

    const handleConfirmOutOfArea = () => {
        if (!appraisalLocation) return;

        props.onConfirmOutOfArea({
            ...appraisalLocation,
            outOfCoverageNote: note,
        });
    };

    return (
        <OutOfCoverageWrapper data-testid={testIds.container}>
            <div className="row">
                {!showOutOfArea && (
                    <div className="show-out-of-area-wrapper">
                        <div className="text">
                            {t(
                                "appraisals-details-tab-activities-collapsable-confirm-out-of-zone-show-label"
                            )}
                        </div>

                        <ButtonConfirm
                            onClick={() => setShowOutOfArea(true)}
                            dataTestId={testIds.buttonModifyInOutOfArea}
                        >
                            {
                                t(
                                    "appraisals-details-tab-activities-collapsable-confirm-out-of-zone-show-button"
                                )!
                            }
                        </ButtonConfirm>
                    </div>
                )}

                {showOutOfArea && (
                    <div className="data-wrapper">
                        <div className="title">Modifica in fuorizona</div>

                        <SubjectDetailsForm
                            subject={appraisalLocation}
                            onChange={handleOnChangeSubjectDetails}
                        />

                        <FormRow>
                            <TextAreaStyled
                                label={`${t(
                                    "appraisals-details-tab-activities-collapsable-confirm-out-of-zone-note-label"
                                )}`}
                                rows={5}
                                value={note}
                                onChange={(note) => setNote(note)}
                                placeholder={
                                    t(
                                        "appraisals-details-tab-activities-collapsable-confirm-out-of-zone-note-placeholder"
                                    )!
                                }
                            />
                            {/* <div className="label">
                                {t(
                                    "appraisals-details-tab-activities-collapsable-confirm-out-of-zone-note-label"
                                )}
                            </div>

                            <TextArea
                                rows={5}
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                placeholder={
                                    t(
                                        "appraisals-details-tab-activities-collapsable-confirm-out-of-zone-note-placeholder"
                                    )!
                                }
                            /> */}
                        </FormRow>

                        <div className="button-wrapper">
                            <ButtonCancel
                                onClick={() => setShowOutOfArea(false)}
                            >
                                {t("button-cancel")!}
                            </ButtonCancel>

                            <Popconfirm
                                placement="leftBottom"
                                icon={null}
                                title={t(
                                    "appraisals-details-tab-activities-collapsable-confirm-out-of-zone-label"
                                )}
                                onConfirm={handleConfirmOutOfArea}
                                okText={
                                    <div data-testid={testIds.confirmButton}>
                                        {t("switch-yes")}
                                    </div>
                                }
                                cancelText={t("switch-no")}
                            >
                                <ButtonConfirm
                                    disabled={!isValid}
                                    onClick={() => {}}
                                    dataTestId={testIds.buttonOutOfArea}
                                >
                                    {t("button-confirm")!}
                                </ButtonConfirm>
                            </Popconfirm>
                        </div>
                    </div>
                )}
            </div>
        </OutOfCoverageWrapper>
    );
};

export default OutOfCoverage;
