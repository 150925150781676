import { baseSplitApiPolicies as api } from "./config/baseApiPolicies";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchValidPolicies: build.mutation<
      SearchValidPoliciesApiResponse,
      SearchValidPoliciesApiArg
    >({
      query: (queryArg) => ({
        url: `/policies/search-valid`,
        method: "POST",
        body: queryArg.searchValidPoliciesParams,
        params: {
          pageNum: queryArg.pageNum,
          pageLen: queryArg.pageLen,
          activeRole: queryArg.activeRole,
        },
      }),
    }),
    searchPolicies: build.mutation<
      SearchPoliciesApiResponse,
      SearchPoliciesApiArg
    >({
      query: (queryArg) => ({
        url: `/policies/search`,
        method: "POST",
        body: queryArg.searchPoliciesParams,
        params: {
          pageNum: queryArg.pageNum,
          pageLen: queryArg.pageLen,
          scenario: queryArg.scenario,
          activeRole: queryArg.activeRole,
        },
      }),
    }),
    createPolicy: build.mutation<CreatePolicyApiResponse, CreatePolicyApiArg>({
      query: (queryArg) => ({
        url: `/policies`,
        method: "POST",
        body: queryArg.basePolicy,
      }),
    }),
    getReadOnlyPolicy: build.query<
      GetReadOnlyPolicyApiResponse,
      GetReadOnlyPolicyApiArg
    >({
      query: (queryArg) => ({ url: `/policies/${queryArg.id}` }),
    }),
    deletePolicy: build.mutation<DeletePolicyApiResponse, DeletePolicyApiArg>({
      query: (queryArg) => ({
        url: `/policies/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getBranchesList: build.query<
      GetBranchesListApiResponse,
      GetBranchesListApiArg
    >({
      query: () => ({ url: `/policies/guarantees/branches` }),
    }),
    addPolicyGuarantee: build.mutation<
      AddPolicyGuaranteeApiResponse,
      AddPolicyGuaranteeApiArg
    >({
      query: (queryArg) => ({
        url: `/policies/${queryArg.id}/guarantees`,
        method: "POST",
        body: queryArg.guarantee,
      }),
    }),
    deletePolicyGuarantee: build.mutation<
      DeletePolicyGuaranteeApiResponse,
      DeletePolicyGuaranteeApiArg
    >({
      query: (queryArg) => ({
        url: `/policies/${queryArg.policyId}/guarantees/${queryArg.guaranteeId}`,
        method: "DELETE",
      }),
    }),
    updatesPolicyGuarantee: build.mutation<
      UpdatesPolicyGuaranteeApiResponse,
      UpdatesPolicyGuaranteeApiArg
    >({
      query: (queryArg) => ({
        url: `/policies/${queryArg.policyId}/guarantees/${queryArg.guaranteeId}`,
        method: "PUT",
      }),
    }),
    setRolesToPolicySubjects: build.mutation<
      SetRolesToPolicySubjectsApiResponse,
      SetRolesToPolicySubjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/policy/${queryArg.id}/subjects-roles`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    createPolicySubject: build.mutation<
      CreatePolicySubjectApiResponse,
      CreatePolicySubjectApiArg
    >({
      query: (queryArg) => ({
        url: `/policies/${queryArg.id}/subjects`,
        method: "POST",
        body: queryArg.policySubject,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiPolicies };
export type SearchValidPoliciesApiResponse =
  /** status 200 a searched claims list */ SearchedPoliciesList;
export type SearchValidPoliciesApiArg = {
  /** result page number */
  pageNum: number;
  /** result page item list */
  pageLen: number;
  /** the active role selected by the user */
  activeRole: string;
  searchValidPoliciesParams: SearchValidPoliciesParams;
};
export type SearchPoliciesApiResponse =
  /** status 200 a searched claims list */ SearchedPoliciesList;
export type SearchPoliciesApiArg = {
  /** result page number */
  pageNum: number;
  /** result page item list */
  pageLen: number;
  /** identifie the scenario to get items list from the correct source */
  scenario: string;
  /** the active role selected by the user */
  activeRole: string;
  searchPoliciesParams: SearchPoliciesParams;
};
export type CreatePolicyApiResponse =
  /** status 200 a JSON representing policy data */ ReadOnlyPolicy;
export type CreatePolicyApiArg = {
  basePolicy: BasePolicy;
};
export type GetReadOnlyPolicyApiResponse =
  /** status 200 item successfully deleted */ ReadOnlyPolicy;
export type GetReadOnlyPolicyApiArg = {
  id: number;
};
export type DeletePolicyApiResponse = unknown;
export type DeletePolicyApiArg = {
  id: number;
};
export type GetBranchesListApiResponse =
  /** status 200 a JSON representing branch data */ Branch[];
export type GetBranchesListApiArg = void;
export type AddPolicyGuaranteeApiResponse =
  /** status 200 a JSON representing policy data */ Guarantee[];
export type AddPolicyGuaranteeApiArg = {
  id: number;
  guarantee: Guarantee;
};
export type DeletePolicyGuaranteeApiResponse =
  /** status 200 a JSON representing policy data */ Guarantee[];
export type DeletePolicyGuaranteeApiArg = {
  policyId: number;
  guaranteeId: number;
};
export type UpdatesPolicyGuaranteeApiResponse =
  /** status 200 a JSON representing policy data */ Guarantee[];
export type UpdatesPolicyGuaranteeApiArg = {
  policyId: number;
  guaranteeId: number;
};
export type SetRolesToPolicySubjectsApiResponse = unknown;
export type SetRolesToPolicySubjectsApiArg = {
  id: number;
  body: PolicySubjectRole[];
};
export type CreatePolicySubjectApiResponse =
  /** status 200 a JSON representing policy' subject data */ PolicySubject[];
export type CreatePolicySubjectApiArg = {
  id: number;
  policySubject: PolicySubject;
};
export type SearchedPoliciesItem = {
  id?: number;
  number?: string;
  numberCompany?: string;
  company?: string;
  insuredVehiclePlateNumber?: string;
  effectDate?: string;
  expirationDate?: string;
  isEffective?: boolean;
  vehicleOwnerName?: string;
  guaranteesNumber?: number;
  guarantees?: string[];
};
export type SearchedPoliciesList = {
  totalCount?: number;
  currentPageNum?: number;
  totalPageNum?: number;
  pageLen?: number;
  items?: SearchedPoliciesItem[];
};
export type SearchValidPoliciesParams = {
  tenantId?: number;
  eventDate?: string;
  insuredVehiclePlateNumber?: string;
};
export type SearchPoliciesParams = {
  tenantId?: number;
  number?: string;
  numberCompany?: string;
  startDate?: string;
  endDate?: string;
  insuredVehiclePlateNumber?: string;
  vehicleOwnerTaxId?: string;
  policyholderTaxId?: string;
};
export type Guarantee = {
  id?: number;
  branch?: string;
  excessAmount?: number;
  overdraftAmount?: number;
};
export type BasePolicy = {
  id?: number;
  numberCompany?: string;
  tenantId?: number;
  effectDate?: string;
  expirationDate?: string;
  administrativeCompliance?: boolean;
  insuredProperty?: {
    id?: number;
    plateNumber?: string;
    chassisNumber?: string;
    type?: string;
    brand?: string;
    model?: string;
  };
  guarantees?: Guarantee[];
};
export type BirthDateAndPlace = {
  date?: string;
  city?: string;
  province?: string;
  country?: string;
};
export type Address = {
  id?: number;
  type?: string;
  street?: string;
  civic?: string;
  postalCode?: string;
  city?: string;
  province?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  preferred?: boolean;
  isDeleted?: boolean;
  isIncorrect?: boolean;
};
export type Contact = {
  id?: number;
  type?: string;
  value?: string;
  description?: string;
  useCase?: string;
  preferred?: boolean;
  isDeleted?: boolean;
};
export type SubjectDocument = {
  id?: number;
  documentCode?: string;
  type?: string;
  number?: string;
  issuingDate?: string;
  expirationDate?: string;
  issuingInstitution?: string;
};
export type SubjectPayment = {
  id?: number;
  type?: string;
  iban?: string | null;
  bicSwift?: string | null;
  addressSendCheck?: Address | null;
  preferred?: boolean;
};
export type Subject = {
  id?: number;
  subjectType?: string;
  name?: string;
  lastname?: string;
  gender?: string;
  fiscalCode?: string;
  birth?: BirthDateAndPlace | null;
  businessName?: string;
  giuridicalType?: string;
  registeredOffice?: Address | null;
  pIva?: string;
  contacts?: Contact[] | null;
  addresses?: Address[] | null;
  documents?: SubjectDocument[] | null;
  payments?: SubjectPayment[] | null;
};
export type PolicySubject = Subject & {
  roleCode?: string;
  roleId?: number;
};
export type ReadOnlyPolicy = BasePolicy & {
  number?: string;
  company?: string;
  policySubjects?: PolicySubject[];
  isEditable?: boolean;
};
export type Branch = {
  id?: number;
  branchGroupCode?: string;
  branchCode?: string;
  branchGroupDescription?: string;
  branchDescription?: string;
};
export type PolicySubjectRole = {
  subjctId?: number;
  roleId?: number;
};
export const {
  useSearchValidPoliciesMutation,
  useSearchPoliciesMutation,
  useCreatePolicyMutation,
  useGetReadOnlyPolicyQuery,
  useLazyGetReadOnlyPolicyQuery,
  useDeletePolicyMutation,
  useGetBranchesListQuery,
  useLazyGetBranchesListQuery,
  useAddPolicyGuaranteeMutation,
  useDeletePolicyGuaranteeMutation,
  useUpdatesPolicyGuaranteeMutation,
  useSetRolesToPolicySubjectsMutation,
  useCreatePolicySubjectMutation,
} = injectedRtkApi;
