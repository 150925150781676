import styled from "styled-components";
import { WidgetWrapper } from "../../../../style/DashbordWidgetWrappers";
import { t } from "i18next";
import { FormRow } from "../../../../style/form";
import { SelectStyled, SwitchStyled } from "../../../../style/Input";
import { RowSpacer } from "../../../../style/containers";
import { SelectPair } from "../../../../types/common.types";
import { useMemo } from "react";
import { IconRemoveFilter } from "../../../../config/icons";

const FilterWrapper = styled(WidgetWrapper)`
  flex-direction: column;

  display: flex;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0 5px #aaa;
  overflow: hidden;

  .header {
    color: #333;
    background-color: #ededed;

    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;

    display: flex;

    .title {
      flex: 1 1 0%;
      padding: 0.5em 2em;
    }

    .actions {
      .icon-wrapper {
        font-size: 1.4em;
        margin-top: 6px;
        margin-right: 1em;
        cursor: pointer;
      }
    }
  }

  .body {
    padding: 1em 2em;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .col {
      flex: 1;
    }

    .map {
      width: 100%;
    }
  }
`;

export type AssignmentMapFilterType = {
  practiceStatus: string;
  isUrgent: boolean;
  appraiserType: "I" | "E" | "";
  saturationCluster: string;
};

interface IAssignmentMapFilterProps {
  filter: AssignmentMapFilterType;
  onChange: (value: any, field: string) => void;
  onResetFilter: () => void;
}

const AssignmentMapFilter = ({
  filter,
  onChange,
  onResetFilter,
}: IAssignmentMapFilterProps) => {
  const practiceStatusOptions: SelectPair[] = useMemo(
    () =>
      ["", "SC", "ST", "PA", "AD"].map((code) => ({
        value: code,
        label: t(`assignment-map-filter-practice-status-description-${code}`),
      })),
    []
  );

  const appraiserTypeOptions: SelectPair[] = useMemo(
    () =>
      ["", "I", "E"].map((code) => ({
        value: code,
        label: t(`assignment-map-filter-appraiser-type-description-${code}`),
      })),
    []
  );

  const saturationClusterOptions: SelectPair[] = useMemo(
    () =>
      ["", "S3", "S2", "S1"].map((code) => ({
        value: code,
        label: t(`assignment-map-filter-saturation-cluster-${code}`),
      })),
    []
  );

  return (
    <FilterWrapper>
      <div className="header">
        <div className="title">{t("assignment-map-filter-title")}</div>

        <div className="actions">
          <div className="icon-wrapper" onClick={onResetFilter}>
            <IconRemoveFilter />
          </div>
        </div>
      </div>

      <div className="body">
        <FormRow>
          <SelectStyled
            label={t("assignment-map-filter-practice-status-label")}
            onChange={(practiceStatus) =>
              onChange(practiceStatus, "practiceStatus")
            }
            value={filter.practiceStatus}
            options={practiceStatusOptions}
          />

          <RowSpacer />

          <FormRow>
            <SwitchStyled
              label={t("assignment-map-filter-priority-label")}
              onChange={(val) => onChange(val, "isUrgent")}
              unCheckedChildren={t("switch-no")}
              checkedChildren={t("switch-yes")}
              checked={filter.isUrgent}
            />

            <RowSpacer />

            <SelectStyled
              label={t("assignment-map-filter-appraiser-type")}
              onChange={(appraiserType) =>
                onChange(appraiserType, "appraiserType")
              }
              value={filter.appraiserType}
              options={appraiserTypeOptions}
            />

            <RowSpacer />

            <SelectStyled
              label={t("assignment-map-filter-saturation-cluster")}
              onChange={(saturationCluster) =>
                onChange(saturationCluster, "saturationCluster")
              }
              value={filter.saturationCluster}
              options={saturationClusterOptions}
            />
          </FormRow>
        </FormRow>
      </div>
    </FilterWrapper>
  );
};

export default AssignmentMapFilter;
