import { Layout } from "antd";

export const Address = {
  container: "address-container",
  fullAddress: "address-fulladdress",
  modal: "address-modal",
  autocomplete: "address-autocomplete",
  street: "address-street",
  civic: "address-civic",
  postalCode: "address-postalCode",
  city: "address-city",
  province: "address-province",
  country: "address-country",
  buttonCancel: "button-cancel",
  buttonConfirm: "button-confirm",
  results: {
    container: "address-results-container",
  },
};

export const BirthPlace = {
  container: "birthplace-container",
  fullplace: "birthplace-fullplace",
  city: "birthplace-city",
  province: "birthplace-province",
  country: "birthplace-country",
  buttonCancel: "birthplace-button-cancel",
  buttonConfirm: "birthplace-button-confirm",
};

export const IconButtons = {
  iconButtonEdit: "icon-button-edit",
  iconButtonEditSvg: "icon-button-edit-svg",
  iconButtonDelete: "icon-button-delete",
  iconButtonDeleteSvg: "icon-button-delete-svg",
};

export const InputFiscalCode = {
  fiscalCode: "fiscal-code",
};

export const InputVatNumber = {
  vatNumber: "vat-number",
};

export const InputIBAN = {
  iban: "iban",
};

export const InputIBicSwift = {
  bicSwift: "bicSwift",
};

export const segmentedSubjectType = {
  subjectType: "segmented-subject-type",
};

export const selectBusinessType = {
  businessType: "select-business-type",
};

export const selectCountry = {
  country: "select-country",
};

export const selectInsuranceCompany = {
  company: "select-insurance-company",
};

export const selectGender = {
  gender: "select-gender",
};

export const addressType = {
  type: "address-type",
};

export const contactType = {
  type: "contact-type",
};

export const contacUseCase = {
  type: "use-case-type",
};

export const switchPreferred = {
  switch: "switch-preferred",
};

export const buttons = {
  confirm: "button-confirm",
  cancel: "button-cancel",
  delete: "button-delete",
};

export const forms = {
  mainForm: "form-main",
};

export const header = {
  container: "header-container",
};

export const logo = {
  container: "logo-container",
};

export const sideMenu = {
  container: "side-menu-container",
};

export const sider = {
  container: "sider-container",
};

export const titles = {
  hr: "title-hr",
};

export const waitForNetworkDelay = {
  container: "wait-for-network-delay-container",
  buttonGoBack: "wait-for-network-delay-container-go-back",
  buttonDismiss: "wait-for-network-delay-container-dismiss",
};

export const subject = {
  clickableSubject: "clickable-subject",
  container: "subject-container",
  buttonCancel: "subject-button-cancel",
  buttonSave: "subject-button-save",
  buttonClear: "subject-button-clear",
  name: "subject-name",
  lastname: "subject-lastname",
  birthDate: "subject-birth-date",
  gender: "subject-birth-gender",
  subjectData: "subject-data",
  businessName: "business-name",
  businessType: "business-type",
  pIva: "p-iva",
  proprietorship: {
    name: "subject-proprietorship-name",
    lastname: "subject-proprietorship-lastname",
    birthDate: "subject-proprietorship-birth-date",
    fiscalCode: "subject-proprietorship-fiscal-code",
  },
  addresses: {
    container: "subject-addresses-container",
    buttonAddNew: "subject-addresses-button-add-new",
    address: {
      container: "subject-address-container",
      title: "subject-address-title",
      type: "subject-address-type",
      preferred: "subject-address-preferred",
      buttonDelete: "subject-addresses-button-delete",
      summarize: "subject-addresses-summarize",
    },
  },
  contacts: {
    container: "subject-contacts-container",
    buttonAddNew: "subject-contacts-button-add-new",
    contact: {
      container: "subject-contact-container",
      title: "subject-contact-title",
      type: "subject-contact-type",
      useCase: "subject-contact-use-case",
      preferred: "subject-contact-preferred",
      value: "subject-contact-value",
      description: "subject-contact-description",
      buttonDelete: "subject-contact-button-delete",
    },
  },
  payments: {
    container: "subject-payments-container",
    buttonAddNew: "subject-payments-button-add-new",
    payment: {
      container: "subject-payment-container",
      title: "subject-payment-title",
      type: "subject-payment-type",
      iban: "subject-payment-iban",
      bicSwift: "subject-payment-bic-swift",
      addressSendCheck: "subject-payment-check-address",
      preferred: "subject-payment-preferred",
      buttonDelete: "subject-payment-button-delete",
    },
  },
  documents: {
    container: "subject-documents-container",
    buttonAddNew: "subject-documents-button-add-new",
    document: {
      container: "subject-document-container",
      title: "subject-document-title",
      type: "subject-document-type",
      number: "subject-document-number",
      issueDate: "subject-document-issue-date",
      expiringDate: "subject-document-expiring-date",
      issuer: "subject-document-issuer",
      buttonDelete: "subject-document-button-delete",
    },
  },
  modal: {
    container: "subject-modal",
  },
};

export const attachments = {
  container: "attachments-container",
  label: "subject-document-attachments-label",
  buttonAddNew: "attachment-button-add-new",
  attachement: {
    container: "attachment-container",
    buttonPreview: "attachment-button-preview",
    buttonDelete: "attachment-button-delete",
  },
};

export const claim = {
  container: "claim-container",
  buttonCancel: "claim-button-cancel",
  buttonSave: "claim-button-save",
  buttonForward: "claim-button-forward",
  buttonBackward: "claim-button-backward",
  buttonSubmit: "claim-button-submit",
  buttonClear: "claim-button-clear",
  buttonClose: "claim-button-close",
  buttonCheckSic: "claim-button-check-sic",

  modal: {
    container: "claim-modal-container",
  },

  policyDetails: {
    header: "claim-policy-details-header",
    number: "claim-policy-details-number",
    plate: "claim-policy-details-plate",
    insurance: "claim-policy-details-insurance",
    effectDate: "claim-policy-details-effect-date",
    expiringDate: "claim-policy-details-expiring-date",
    ownerName: "claim-policy-details-owner-name",
    ownerFiscalCode: "claim-policy-details-owner-fiscal-code",
    ownerBusinessName: "claim-policy-details-owner-business-name",
    ownerPiva: "claim-policy-details-owner-piva",
  },

  claimData: {
    container: "claim-data-container",
    registrationDate: "claim-registration-date",
    receiptDate: "claim-receipt-date",
    dateOfReceiptCompany: "claim-date-of-receipt-company",
    dateOfReceiptDekra: "claim-date-of-receipt-dekra",
    occurrenceDate: "claim-occurence-date",
    occurrenceTime: "claim-occurence-time",
    occurencePlace: "claim-occurence-place",
    policeIntervention: "claim-police-intervention",
    witnesses: "claim-witnesses",
    note: "note",
  },
  counterpartData: {
    container: "claim-data-counterpart-container",
    onwerSubjectType: "claim-counterpart-owner-subject-type",
    isDriverSameOwner: "claim-counterpart-is-driver-same-owner",
    ownerName: "claim-counterpart-owner-name",
    ownerLastname: "claim-counterpart-owner-last-name",
    ownerBusinessName: "claim-counterpart-owner-business-name",
    driverName: "claim-counterpart-driver-name",
    driverLastname: "claim-counterpart-driver-last-name",
    plate: "claim-counterpart-plate",
    insuranceCode: "claim-counterpart-insurance-code",
  },
  responsability: {
    container: "claim-data-responsability-container",
    barems: {
      container: "claim-data-responsability-barems-container",
      resultButton: "claim-data-responsability-barems-result-button",
      modal: {
        container: "claim-data-responsability-barems-modal-container",
        colomnA: "claim-data-responsability-barems-modal-vehicle-a-",
        colomnB: "claim-data-responsability-barems-modal-vehicle-b-",
        buttonConfirm: "claim-data-responsability-barems-modal-button-confirm",
        buttonCancel: "claim-data-responsability-barems-modal-button-cancel",
        resultLabel: "claim-data-responsability-barems-modal-result-label",
        resultText: "claim-data-responsability-barems-modal-result-text",
      },
      vehicleADescription:
        "claim-data-responsability-barems-vehicle-a-description",
      vehicleBDescription:
        "claim-data-responsability-barems-vehicle-b-description",
    },
    forcedReason: "claim-data-responsability-forced-reason",
    responsabilityType: "claim-data-responsability-barems-responsability-type",
    responsabilityPercentage:
      "claim-data-responsability-barems-responsability-percentage",
    signatureType: "claim-data-responsability-signature-type",
  },
  damagedParts: {
    container: "claim-data-damaged-parts-container",
    buttonAddNew: "claim-data-damaged-parts-button-add-new",
    row: {
      container: "claim-data-damaged-parts-row-container",
    },
    damagedPart: {
      container: "claim-data-damaged-parts-damaged-part-container",
      role: "claim-data-damaged-parts-damaged-part-role",
    },
    modal: {
      container: "claim-data-damaged-parts-modal-container",
    },
  },
};

export const cardWizard = {
  container: "card-wizard-container",
  numberOfVehicles: "card-wizard-number-of-vehicles",
  vehicleAType: "card-wizard-vehicle-a-type",
  vehicleBType: "card-wizard-vehicle-b-type",
  collision: "card-wizard-collision",
  inItaly: "card-wizard-italy",
  fieldResultNumberOfVehicles: "field-result-number-of-vehicles",
  fieldResultVehicleA: "field-result-vehicle-a",
  fieldResultVehicleB: "field-result-vehicle-b",
  fieldResultCollision: "field-result-collision",
  fieldResultInItaly: "field-result-in-italy",
  finalResult: "card-wizard-final-result",
};

export const selectVehicleType = {
  container: "select-vehicle-type-container",
};

export const buttonScrollToTop = {
  container: "button-scroll-to-top-container",
};

export const authentication = {
  signin: "sign-in-container",
  signedIn: "signed-in-container",
  userName: "signend-in-user-name",
  userLastname: "signend-in-user-lastname",
  buttonSignout: "signed-in-button-signout",
};

export const layout = {
  container: "layout-container",
};

export const clickToCopy = {
  container: "click-to-copy-container",
};

export const selectForcedReason = {
  container: "select-forced-reason-container",
};

export const selectSignatureType = {
  container: "select-signature-type-container",
};

export const selectDamagedPartRole = {
  container: "select-damaged-part-role-container",
};

export const damagedPart = {
  container: "damaged-part-container",
  selectRole: "damaged-part-select-role",
  damagedPerson: {
    container: "damaged-part-damaged-person-container",
    wounds: {
      container: "damaged-part-damaged-person-wounds-container",
      front_head: "damaged-part-damaged-person-wounds-front-head",
      front_trunc: "damaged-part-damaged-person-wounds-front-trunc",
      front_arm_left: "damaged-part-damaged-person-wounds-front-arm-left",
      front_arm_right: "damaged-part-damaged-person-wounds-front-arm-right",
      front_leg_left: "damaged-part-damaged-person-wounds-front-leg-left",
      front_leg_right: "damaged-part-damaged-person-wounds-front-leg-right",
      rear_head: "damaged-part-damaged-person-wounds-rear-head",
      rear_trunc: "damaged-part-damaged-person-wounds-rear-trunc",
      rear_arm_left: "damaged-part-damaged-person-wounds-rear-arm-left",
      rear_arm_right: "damaged-part-damaged-person-wounds-rear-arm-right",
      rear_leg_left: "damaged-part-damaged-person-wounds-rear-leg-left",
      rear_leg_right: "damaged-part-damaged-person-wounds-rear-leg-right",
    },
  },
  damagedVehicle: {
    container: "damaged-part-damaged-vehicle-container",
    collisionPoints: {
      empty: "damaged-part-damaged-vehicle-collision-points-",
      front: "damaged-part-damaged-vehicle-collision-points-front",
      ["front-right"]:
        "damaged-part-damaged-vehicle-collision-points-front-right",
      right: "damaged-part-damaged-vehicle-collision-points-right",
      ["back-right"]:
        "damaged-part-damaged-vehicle-collision-points-back-right",
      back: "damaged-part-damaged-vehicle-collision-points-back",
      ["back-left"]: "damaged-part-damaged-vehicle-collision-points-back-left",
      left: "damaged-part-damaged-vehicle-collision-points-left",
      ["front-left"]:
        "damaged-part-damaged-vehicle-collision-points-front-left",
    },
  },
  attributes: {
    container: "damaged-part-attributes-container",
    managementType: "damaged-part-attributes-management-type",
    status: "damaged-part-attributes-status",
    convention: "damaged-part-attributes-convention",
    passiveReserve: "damaged-part-attributes-passive-reserve",
    recoveryReserve: "damaged-part-attributes-recovery-reserve",
  },
};

export const clickableSearch = {
  container: "clickable-search-container",
  buttonOpen: "clickable-search-button-open",
  tabClaims: {
    container: "clickable-search-tab-claims-container",
    fields: {
      company: "clickable-search-tab-claims-fields-company",
      nominative: "clickable-search-tab-claims-fields-nominative",
      code: "clickable-search-tab-claims-fields-code",
      plate: "clickable-search-tab-claims-fields-plate",
      claimNumber: "clickable-search-tab-claims-fields-claim-number",
      policyNumber: "clickable-search-tab-claims-fields-policy-number",
    },
    buttonSearch: "clickable-search-tab-claims-button-search",
  },
  tabAppraisals: {
    container: "clickable-search-tab-appraisals-container",
    fields: {
      plate: "clickable-search-tab-appraisals-fields-plate",
      claimsNumber: "clickable-search-tab-appraisals-fields-claimNumber",
      practicesNumber: "clickable-search-tab-appraisals-fields-practicesNumber",
      practiceNumberCompany:
        "clickable-search-tab-appraisals-fields-practiceNumberCompany",
      status: "clickable-search-tab-appraisals-fields-status",
      practiceDateFrom:
        "clickable-search-tab-appraisals-fields-practiceDateFrom",
      practiceDateTo: "clickable-search-tab-appraisals-fields-practiceDateTo",
      returnDateFrom: "clickable-search-tab-appraisals-fields-returnDateFrom",
      returnDateTo: "clickable-search-tab-appraisals-fields-returnDateTo",
      idAppraiser: "clickable-search-tab-appraisals-fields-idAppraiser",
      agreed: "clickable-search-tab-appraisals-fields-agreed",
    },
    buttonSearch: "clickable-search-tab-appraisals-button-search",
  },
  results: {
    claims: {
      container: "clickable-search-results-claims-container",
    },
    appraisals: {
      container: "clickable-search-results-appraisals-container",
    },
  },
};

export const mainTabs = {
  container: "main-tabs-container",
  dashboards: {
    NoRole: "main-tabs-dashboards-NoRole",
    D1: "main-tabs-dashboards-D1",
    D2: "main-tabs-dashboards-D2",
    E1: "main-tabs-dashboards-E1",
    E2: "main-tabs-dashboards-E2",
    E4: "main-tabs-dashboards-E4",
    F1: "main-tabs-dashboards-F1",
    NF: "main-tabs-dashboards-EF",
    G1: "main-tabs-dashboards-G1",
    G2: "main-tabs-dashboards-G2",
    H1: "main-tabs-dashboards-H1",
  },
  NoRole: {
    messageWrapper: "main-tabs-NoRole-message-wrapper",
  },
  D1: {
    loader: "main-tabs-D1-loader",
    topSpacer: "main-tabs-D1-top-spacer",
    testFilterByManagedButton: "main-tabs-D1-test-filter-by-managed-button",
    statuses: {
      daily: {
        container: "main-tabs-D1-statuses-daily-container",
        value: "main-tabs-D1-statuses-daily-value",
      },
      assigned: {
        container: "main-tabs-D1-statuses-assigned-container",
        value: "main-tabs-D1-statuses-assigned-value",
      },
      discarded: {
        container: "main-tabs-D1-statuses-discarded-container",
        value: "main-tabs-D1-statuses-discarded-value",
      },
      cancelled: {
        container: "main-tabs-D1-statuses-cancelled-container",
        value: "main-tabs-D1-statuses-cancelled-value",
      },
    },
  },
  D2: {
    loader: "main-tabs-D2-loader",
    topSpacer: "main-tabs-D2-top-spacer",
    testFilterByManagedButton: "main-tabs-D2-test-filter-by-managed-button",
    statuses: {
      daily: {
        container: "main-tabs-D2-statuses-daily-container",
        value: "main-tabs-D2-statuses-daily-value",
      },
      assigned: {
        container: "main-tabs-D2-statuses-assigned-container",
        value: "main-tabs-D2-statuses-assigned-value",
      },
      discarded: {
        container: "main-tabs-D2-statuses-discarded-container",
        value: "main-tabs-D2-statuses-discarded-value",
      },
      inVerification: {
        container: "main-tabs-D2-statuses-in-verification-container",
        value: "main-tabs-D2-statuses-in-verification-value",
      },
      cancelled: {
        container: "main-tabs-D2-statuses-cancelled-container",
        value: "main-tabs-D2-statuses-cancelled-value",
      },
    },
  },
  E1: {
    loader: "main-tabs-E1-loader",
    topSpacer: "main-tabs-E1-top-spacer",
    statuses: {
      inVerification: {
        container: "main-tabs-E1-statuses-in-verification-container",
        value: "main-tabs-E1-statuses-in-verification-value",
      },
    },
  },
  E2: {
    loader: "main-tabs-E2-loader",
    testFilterByManagedButton: "main-tabs-E2-test-filter-by-managed-button",
    statuses: {
      confirmed: {
        container: "main-tabs-E2-statuses-confirmed-container",
        value: "main-tabs-E2-statuses-confirmed-value",
      },
      withoutAppointment: {
        container: "main-tabs-E2-statuses-withot-appointment-container",
        value: "main-tabs-E2-statuses-withot-appointment-value",
      },
      withAppointment: {
        container: "main-tabs-E2-statuses-with-appointment-container",
        value: "main-tabs-E2-statuses-with-appointment-value",
      },
      finishPractice: {
        container: "main-tabs-E2-statuses-finish-practice-container",
        value: "main-tabs-E2-statuses-finish-practice-value",
      },
    },
  },
  E4: {
    loader: "main-tabs-E4-loader",
    topSpacer: "main-tabs-E4-top-spacer",
    statuses: {
      inVerification: {
        container: "main-tabs-E1-statuses-in-verification-container",
        value: "main-tabs-E1-statuses-in-verification-value",
      },
    },
  },
  F1: {
    loader: "main-tabs-F1-loader",
    statuses: {
      isUrgent: {
        container: "main-tabs-F1-statuses-isUrgent-container",
        value: "main-tabs-F1-statuses-isUrgent-value",
      },
    },
  },
  NF: {
    loader: "main-tabs-NF-loader",
    wrapper: "main-tabs-NF-wrapper",
  },
  G1: {
    loader: "main-tabs-G1-loader",
  },
  G2: {
    loader: "main-tabs-G2-loader",
  },
  H1: {
    loader: "main-tabs-H1-loader",
  },
  tabs: {
    appraisalDetails: {
      container: "main-tabs-appraisal-details-container",
      loader: "main-tabs-appraisal-details-loader",
      resumeInfo: "main-tabs-appraisal-details-resume-info",
      collapsables: {
        details: {
          status: "main-tabs-appraisal-details-collapsables-details-status",
          priority: "main-tabs-appraisal-details-collapsables-details-priority",
          isUrgent: "main-tabs-appraisal-details-collapsables-details-isUrgent",
          practiceTypeCode:
            "main-tabs-appraisal-details-collapsables-details-practice-type-code",
          practiceNumber:
            "main-tabs-appraisal-details-collapsables-details-practice-number",
          emailAppraiser:
            "main-tabs-appraisal-details-collapsables-details-email-appraiser",
          damageTypeCode:
            "main-tabs-appraisal-details-collapsables-details-damage-type-code",
          secondAssignment:
            "main-tabs-appraisal-details-collapsables-details-second-assignment",
          isDocumental:
            "main-tabs-appraisal-details-collapsables-details-is-documental",
          isCustomRepair:
            "main-tabs-appraisal-details-collapsables-details-is-custom-repair",
          isAgreed:
            "main-tabs-appraisal-details-collapsables-details-is-agreed",
          isCreditAssignment:
            "main-tabs-appraisal-details-collapsables-details-is-credit-assignment",
          isCounterpart:
            "main-tabs-appraisal-details-collapsables-details-is-counterpart",
          dateAppoinment:
            "main-tabs-appraisal-details-collapsables-details-date-appoinment",
          appraisalsExpertizer:
            "main-tabs-appraisal-details-collapsables-details-appraisals-expertizer",
          dateReturn:
            "main-tabs-appraisal-details-collapsables-details-date-return",
          location: {
            container:
              "main-tabs-appraisal-details-collapsables-details-location-container",
            history: {
              container:
                "main-tabs-appraisal-details-collapsables-details-location-history-container",
            },
            subjectModal: {
              container:
                "main-tabs-appraisal-details-collapsables-details-location-subject-modal-container",
              subjectType:
                "main-tabs-appraisal-details-collapsables-details-location-subject-modal-subject-type",
              name: "main-tabs-appraisal-details-collapsables-details-location-subject-modal-name",
              lastName:
                "main-tabs-appraisal-details-collapsables-details-location-subject-modal-last-name",
              businessName:
                "main-tabs-appraisal-details-collapsables-details-location-subject-modal-business-name",
              fiscalCode:
                "main-tabs-appraisal-details-collapsables-details-location-subject-modal-fiscal-code",
              pIva: "main-tabs-appraisal-details-collapsables-details-location-subject-modal-p-iva",
              email:
                "main-tabs-appraisal-details-collapsables-details-location-subject-modal-email",
              pec: "main-tabs-appraisal-details-collapsables-details-location-subject-modal-pec",
              phone:
                "main-tabs-appraisal-details-collapsables-details-location-subject-modal-phone",
              address:
                "main-tabs-appraisal-details-collapsables-details-location-subject-modal-address",
              buttonConfirm:
                "main-tabs-appraisal-details-collapsables-details-location-subject-modal-button-confirm",
              buttonCancel:
                "main-tabs-appraisal-details-collapsables-details-location-subject-modal-button-cancel",
            },
            clickableSubject: {
              container:
                "main-tabs-appraisal-details-collapsables-details-location-clickable-subject-container",
              label:
                "main-tabs-appraisal-details-collapsables-details-location-clickable-subject-label",
              text: "main-tabs-appraisal-details-collapsables-details-location-clickable-subject-text",
            },
          },
        },
        claim: {
          header: "main-tabs-appraisal-details-collapsables-claim-header",
          claimCode:
            "main-tabs-appraisal-details-collapsables-details-claim-code",
          overdraft:
            "main-tabs-appraisal-details-collapsables-details-overdraft",
          ceiling: "main-tabs-appraisal-details-collapsables-details-ceiling",
          deductible:
            "main-tabs-appraisal-details-collapsables-details-deductible",
          signatureType:
            "main-tabs-appraisal-details-collapsables-details-signature-type",
          claimType:
            "main-tabs-appraisal-details-collapsables-details-claim-type",
        },
        vehicle: {
          header: "main-tabs-appraisal-details-collapsables-vehicle-header",
          plate: "main-tabs-appraisal-details-collapsables-vehicle-plate",
          vin: "main-tabs-appraisal-details-collapsables-vehicle-vin",
          model: "main-tabs-appraisal-details-collapsables-vehicle-model",
        },
      },
    },
    activities: {
      container: "main-tabs-appraisal-activities-container",
      loadingContainer: "main-tabs-appraisal-activities-loading-container",
      buttonReactivatePractice:
        "main-tabs-appraisal-activities-button-reactivate-practice",
      buttonReactivatePracticeConfirm:
        "main-tabs-appraisal-activities-button-reactivate-practice-confirm",
      appraiserDetails: {
        container: "main-tabs-appraisal-activities-appraiser-details-container",
        nominative:
          "main-tabs-appraisal-activities-appraiser-details-nominative",
        buttonChange:
          "main-tabs-appraisal-activities-appraiser-details-button-change",
        buttonChangeConfirm:
          "main-tabs-appraisal-activities-appraiser-details-button-change-confirm",
        buttonSuspendPractice:
          "main-tabs-appraisal-activities-appraiser-details-button-suspend-practice",
        buttonSuspendPracticeConfirm:
          "main-tabs-appraisal-activities-appraiser-details-button-suspend-practice-confirm",
      },
      appraiserSelection: {
        container:
          "main-tabs-appraisal-activities-appraiser-selection-container",
        selectAppraiserTitle:
          "main-tabs-appraisal-activities-appraiser-details-select-appraiser-title",

        suggestedAppraisers: {
          container:
            "main-tabs-appraisal-activities-appraiser-selection-selected-container",
        },
        buttonAssign:
          "main-tabs-appraisal-activities-appraiser-selection-button-assign",
        buttonAssignConfirm:
          "main-tabs-appraisal-activities-appraiser-selection-button-assign-confirm",
      },
      scheduleAppointment: {
        header: "main-tabs-appraisal-activities-schedule-appointment-header",
        container:
          "main-tabs-appraisal-activities-schedule-appointment-container",
        buttonModify:
          "main-tabs-appraisal-activities-schedule-appointment-button-confirm",
        buttonSchedule:
          "main-tabs-appraisal-activities-schedule-appointment-button-schedule",
        testFunctionSetDate:
          "main-tabs-appraisal-activities-schedule-appointment-test-function-set-date",
        testFunctionSchedule:
          "main-tabs-appraisal-activities-schedule-appointment-test-function-schedule",
      },
      acceptPractice: {
        container: "main-tabs-appraisal-activities-accept-practice-container",
        buttonAcceptPractice:
          "main-tabs-appraisal-activities-accept-practice-button-accept-practice",
        buttonAcceptOutOfArea:
          "main-tabs-appraisal-activities-accept-practice-button-accept-out-of-area",
      },
      outOfCoverage: {
        container: "main-tabs-appraisal-activities-out-of-coverage-container",
        buttonModifyInOutOfArea:
          "main-tabs-appraisal-activities-out-of-coverage-button-modify-in-out-of-area",
        buttonOutOfArea:
          "main-tabs-appraisal-activities-out-of-coverage-button-out-of-area",
        confirmButton:
          "main-tabs-appraisal-activities-out-of-coverage-confirm-button",
        autocomplete:
          "main-tabs-appraisal-activities-out-of-coverage-autocomplete",
        street: "main-tabs-appraisal-activities-out-of-coverage-street",
        civic: "main-tabs-appraisal-activities-out-of-coverage-civic",
        postalCode: "main-tabs-appraisal-activities-out-of-coverage-postalCode",
        city: "main-tabs-appraisal-activities-out-of-coverage-city",
        province: "main-tabs-appraisal-activities-out-of-coverage-province",
        country: "main-tabs-appraisal-activities-out-of-coverage-country",
      },
      cancelPractice: {
        container: "main-tabs-appraisal-activities-cancel-practice-container",
        note: "main-tabs-appraisal-activities-cancel-practice-note",
        cancelPracticeButton:
          "main-tabs-appraisal-activities-cancel-practice-button-cancel-practice",
        confirmButton:
          "main-tabs-appraisal-activities-cancel-practice-confirm-button",
      },
      note: {
        container: "main-tabs-note-container",
        collapsables: {
          notes: {
            textArea: "main-tabs-note-collapsables-notes-textArea",
            buttonEdit: "main-tabs-note-collapsables-notes-buttonEdit",
            buttonDelete: "main-tabs-note-collapsables-notes-buttonDelete",
            table: "main-tabs-note-collapsables-notes-table",
            loadingContainer:
              "main-tabs-note-collapsables-notes-loadingContainer",
          },
        },
      },
    },
    historicalActions: {
      container: "main-tabs-historical-actions-container",
      table: "main-tabs-historical-actions-table",
      loadingContainer: "main-tabs-historical-actions-loadingContainer",
    },
    supplierNetwork: {
      container: "main-tabs-supplier-network-container",
      appriasersList: {
        container: "main-tabs-supplier-network-appraisers-list-container",
      },
      appraiserStatusDialog: {
        container:
          "main-tabs-supplier-network-appraiser-status-dialog-container",
        buttonModifyStatus:
          "main-tabs-supplier-network-appraiser-status-dialog-button-modify-status",
        buttonSaveStatus:
          "main-tabs-supplier-network-appraiser-status-dialog-button-save-status",
        appraiserStatusCode:
          "main-tabs-supplier-network-appraiser-status-dialog-appraiser-status-code",
        selectedDictrict:
          "main-tabs-supplier-network-appraiser-status-dialog-selected-dictrict",
        buttonSetSelecteDistrict:
          "main-tabs-supplier-network-appraiser-status-dialog-button-set-selecte-district",
        mapLegend: {
          container:
            "main-tabs-supplier-network-appraiser-status-dialog-map-legend-container",
        },
        calendar: {
          container:
            "main-tabs-supplier-network-appraiser-status-dialog-calendar-container",
        },
        networkCommunications: {
          container:
            "main-tabs-supplier-network-appraiser-status-dialog-network-communications-container",
        },
      },
      appraiserDetails: {
        container: "main-tabs-supplier-network-appraiser-details-container",
        subject: {
          container:
            "main-tabs-supplier-network-appraiser-details-subject-container",
          buttonSave:
            "main-tabs-supplier-network-appraiser-details-subject-button-save",
          name: "main-tabs-supplier-network-appraiser-details-subject-name",
          lastname:
            "main-tabs-supplier-network-appraiser-details-subject-lastname",
          fiscalcode:
            "main-tabs-supplier-network-appraiser-details-subject-fiscalcode",
          businessname:
            "main-tabs-supplier-network-appraiser-details-subject-businessname",
          pIva: "main-tabs-supplier-network-appraiser-details-subject-piva",
          giuridicalType:
            "main-tabs-supplier-network-appraiser-details-subject-giuridical-type",
          saveLoader:
            "main-tabs-supplier-network-appraiser-details-subject-save-loader",
          personalData:
            "main-tabs-supplier-network-appraiser-details-subject-personal-data",
        },
        additionalInfo: {
          container:
            "main-tabs-supplier-network-appraiser-details-additional-info-container",
          buttonSetManagementTool:
            "main-tabs-supplier-network-appraiser-details-additional-info-button-set-management-tool",
          buttonAddManagementTool:
            "main-tabs-supplier-network-appraiser-details-additional-info-button-add-management-tool",
          buttonSetTrustCompany:
            "main-tabs-supplier-network-appraiser-details-additional-info-button-set-trust-company",
          buttonAddTrustCompany:
            "main-tabs-supplier-network-appraiser-details-additional-info-button-add-trust-company",
        },
        management: {
          container: "main-tabs-supplier-network-management-container",
          mainData: {
            container:
              "main-tabs-supplier-network-management-main-data-container",
            buttonClientPermissions:
              "main-tabs-supplier-network-management-main-data-button-client-permissions",
          },
          statusModal: {
            container: "main-tabs-supplier-network-management-status-modal",
          },
          clientPermissions: {
            modal:
              "main-tabs-supplier-network-management-client-permissions-modal",
            container:
              "main-tabs-supplier-network-management-client-permissions-container",
            checkboxAllM:
              "main-tabs-supplier-network-management-client-permissions-checkbox-all-am",
            buttonSave: "main-tabs-supplier-network-management-button-save",
            buttonSaveConfirm:
              "main-tabs-supplier-network-management-button-save-confirm",
          },
          coverageData: {
            container:
              "main-tabs-supplier-network-management-coverage-data-container",
            buttonAddProvince:
              "main-tabs-supplier-network-management-coverage-data-button-add-province",
            buttonDeleteProvince:
              "main-tabs-supplier-network-management-coverage-data-button-delete-province",
            district: {
              container:
                "main-tabs-supplier-network-management-coverage-data-district-container",
              toggleZipcode:
                "main-tabs-supplier-network-management-coverage-data-district-toggle-zipcode",
              buttonAddDistrict:
                "main-tabs-supplier-network-management-coverage-data-district-button-add-district",
              maxDaily:
                "main-tabs-supplier-network-management-coverage-data-district-max-daily",
              maxMonthly:
                "main-tabs-supplier-network-management-coverage-data-district-max-monthly",
              selectStatus:
                "main-tabs-supplier-network-management-coverage-data-district-areas-coverage-select-status",

              selectAddAreaByName:
                "main-tabs-supplier-network-management-coverage-data-district-button-add-area-by-name",
              selectAddAreaByZipcode:
                "main-tabs-supplier-network-management-coverage-data-district-button-add-area-by-zipcode",
              areasCoverage: {
                container:
                  "main-tabs-supplier-network-management-coverage-data-district-areas-coverage-container",
              },
              filterByCityName:
                "main-tabs-supplier-network-management-coverage-data-district-filter-by-city-name",
              filterByZipcode:
                "main-tabs-supplier-network-management-coverage-data-district-filter-by-zipcode",
              buttonAreaDelete:
                "main-tabs-supplier-network-management-coverage-data-district-button-area-delete",
            },
          },
        },
      },
    },
  },
};

export const selectClient = {
  container: "select-client-container",
  openPopover: "select-client-open-popover",
  selectableTenantSwitch: "select-client-selectable-tenant-switch",
};

export const charts = {
  donut: {
    container: "charts-donut-container",
    legend: "charts-donut-legend",
    centerValue: "charts-donut-center-value",
  },
  horizontalBar: {
    container: "charts-horizontalBar-container",
  },
  communications: {
    container: "charts-communications-container",
    name: "charts-communications-name-",
    valuesName: "charts-communications-values-name-",
    valuesValue: "charts-communications-values-value-",
  },
  practicesInProgress: {
    container: "charts-practices-in-progress-container",
  },
};

export const drawerMenu = {
  container: "drawer-menu-container",
  buttonOpen: "drawer-menu-button-open",
};

export const infoAlwaysVisibleTop = {
  container: "info-always-visible-top-container",
};

export const widgets = {
  header: {
    container: "widgets-header-container",
    buttonResetFilters: "widgets-header-button-reset-filters",
    button1: "widgets-header-button-1",
  },
  appraisalsList: {
    container: "widgets-appraisals-list-container",
    buttonSetAppointment: "widgets-appraisals-list-button-set-appointment",
  },
  appraisalsCommunications: {
    container: "widgets-appraisals-communications-container",
  },
  appraisalsStatuses: {
    container: "widgets-appraisals-statuses-container",
    extention: "widgets-appraisals-statuses-extention",
  },
  appraisalsToManage: {
    container: "widgets-appraisals-to-manage-container",
  },
  negativePractices: {
    container: "widgets-appraisals--negative-practices-container",
  },
  mapAppraisals: {
    container: "widgets-appraisals-map-appraisals-container",
  },
  mapExpertizers: {
    container: "widgets-appraisals-map-expertizer-container",
  },
  inProgress: {
    container: "widgets-appraisals--in-progress-container",
    buttonApply: "widgets-appraisals-in-progress-button-apply",
  },
  communicationsList: {
    container: "widgets-communications-list-container",
  },
  expertisesStatuses: {
    container: "widgets-expertizer-statuses-container",
    initialDialog: {
      container: "widgets-expertizer-statuses-initial-dialog-container",
      buttonConfirmed:
        "widgets-expertizer-statuses-initial-dialog-button-confirm",
      buttonWithoutAppointment:
        "widgets-expertizer-statuses-initial-dialog-button-without-appointment",
      buttonWithAppointment:
        "widgets-expertizer-statuses-initial-dialog-button-with-appointment",
      buttonFinishPractice:
        "widgets-expertizer-statuses-initial-dialog-button-finish-practice",
      buttonCommunications:
        "widgets-expertizer-statuses-initial-dialog-button-communications",
    },
  },
  experitizerCommunications: {
    container: "widgets-expertizer-communications-container",
  },
  expertizerInProgress: {
    container: "widgets-expertizer-in-progress-container",
  },
  expertizerList: {
    container: "widgets-expertizer-list-container",
    buttonConfirmPractice: "widgets-expertizer-list-button-confirm-practice",
    buttonConfirmPracticeConfirmation:
      "widgets-expertizer-list-button-confirm-practice-confirmation",
    buttonSetAppointment: "widgets-expertizer-list-button-set-appointment",
    buttonSetAppointmentConfirmation:
      "widgets-expertizer-list-button-set-appointment-confirmation",
    buttonFinishPractice: "widgets-expertizer-list-button-finish-practice",
    buttonFinishPracticeConfirmation:
      "widgets-expertizer-list-button-finish-practice-confirmation",
  },
  customerCommunications: {
    container: "widgets-customer-communications-container",
  },
  customerList: {
    container: "widgets-customer-list-container",
  },
};

export const dashboards = {
  wrapperContainer: "dashboards-wrapper-container",
};

export const selectClaimType = {
  container: "select-claim-type-container",
};

export const selectPracticeType = {
  container: "select-practice-type-container",
};

export const timerPie = {
  container: "timer-pie-container",
};

export const map = {
  marker: "map-marker",
  expertizer: "map-expertizer",
  supplyNetworkAppraiser: "map-supply-network-appraiser",
  expertizerPopup: {
    container: "map-popup-container",
    idAssignment: "map-popup-id-assignment",
    typeExpertise: "map-popup-type-expertise",
    numberExpertise: "map-popup-number-expertise",
    plate: "map-popup-plate",
  },
  supplyNetworkAppraiserPopup: {
    container: "map-popup-container",
    place: "map-popup-place",
    status: "map-popup-status",
    dateFrom: "map-popup-date-from",
    dateTo: "map-popup-date-to",
  },
};

export const selectActiveRole = {
  container: "select-active-role-container",
};

export const selectAppraiser = {
  container: "select-appraiser-container",
};

export const selectWithAddNew = {
  container: "select-with-add-new-container",
  buttonAdd: "select-with-add-new-button-add",
  inputNew: "select-with-add-new-input-new",
};
