import { useMemo, useState } from "react";
import { SecondaryPracticeRequest } from "../../../../../../redux/apiSpecifications/apiCrud";
import { ManagePracticeRequestFormModel } from "./ManagePracticeRequestFormModel";
import { updateObject } from "../../../../../../utils/updateObject";
import { isNotEmpty } from "../../../../../../validation/common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { ProfileHelper } from "../ProfileHelper";

type ManagePracticeRequestFormState = {
    data: ManagePracticeRequestFormModel;
    canSubmit: boolean;
};

const useManagePracticeRequestForm = (
    practiceRequest: SecondaryPracticeRequest,
    mode: "closeWithMessage" | "reply" | "forward"
) => {
    const checkIfCanSubmit = (data: ManagePracticeRequestFormModel) => {
        const { to, text } = data;

        return isNotEmpty(to) && isNotEmpty(text);
    };

    const validateForm = (data: ManagePracticeRequestFormModel) => {
        return data;
    };

    const { activeRole } = useSelector(
        (rootState: RootState) => rootState.user
    );

    const GetRequestReceivers = (
        activeRole: string,
        mode: "closeWithMessage" | "reply" | "forward"
    ): number[] => {
        let result: number[] = [];

        const userProfile = ProfileHelper.GetProfileFromRole(activeRole!);

        if (mode === "reply") {
            if (practiceRequest.senderProfile! === userProfile)
                result.push(practiceRequest.recipientProfile!);
            else if (practiceRequest.recipientProfile! === userProfile)
                result.push(practiceRequest.senderProfile!);
        } else if (mode === "forward") {
            if (practiceRequest.messages) {
                if (practiceRequest.messages[0].senderProfile === 6) {
                    result.push(5);
                } else if (practiceRequest.messages[0].senderProfile === 5) {
                    result.push(6);
                }
            }
        } else if (mode === "closeWithMessage") {
            result.push(5);
        }

        return result;
    };

    const [toList, setToList] = useState<number[]>(
        GetRequestReceivers(activeRole!, mode)
    );

    const initState = (practiceRequest: SecondaryPracticeRequest) => {
        const data: ManagePracticeRequestFormModel = {
            to: toList.length === 1 ? toList[0].toString() : "",
            priority: practiceRequest.isUrgent ? "U" : "NU",
            object: practiceRequest.requestObject,
        };

        if (mode === "forward") {
            if (practiceRequest.messages) {
                data.text = "\r\n" + practiceRequest.messages[0].messageText;
            }
        }

        return {
            data,
            canSubmit: checkIfCanSubmit(data),
        } as ManagePracticeRequestFormState;
    };

    const [state, setState] = useState<ManagePracticeRequestFormState>(
        initState(practiceRequest)
    );

    const handleDataChange = (value: any, field: string) => {
        const { data } = state;

        let updatedData = updateObject(
            data,
            value === "" ? undefined : value,
            field
        );

        updatedData = validateForm(updatedData);

        setState({
            ...state,
            data: { ...updatedData },
            canSubmit: checkIfCanSubmit(updatedData),
        });
    };

    return {
        data: state.data,
        toList,
        canSubmit: state.canSubmit,
        handleDataChange,
    };
};

export default useManagePracticeRequestForm;
