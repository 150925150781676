import { useTranslation } from "react-i18next";
import { InputTextStyled } from "../../style/Input";
import { ValidateStatusTypes } from "../../validation/subjectValidation";
import { InputIBAN as testId } from "../../config/testIds";
import { SubjectPropertyValue } from "../../types/subject-data.types";
import { AdditionalValidationField } from "../../types/common.types";

interface InputIBANProps {
  classNameItem?: string;
  style?: any;
  value: string | undefined | null;
  validation?: AdditionalValidationField;
  onChange: (val: SubjectPropertyValue) => void;
  index: number;
  required?: boolean | undefined;
  showTooltip?: boolean | undefined;
  dataTestId?: string | undefined;
}

/**
 * Render an input field for an IBAN code.
 */

const InputIBAN = (props: InputIBANProps) => {
  const { t } = useTranslation();

  const index = props.index;
  const required = props.required ?? true;
  const showTooltip = props.showTooltip ?? true;

  const validateStatus = props.validation
    ?.validateStatus as ValidateStatusTypes;
  const help = t(props.validation?.messageKey || "") || "";

  // console.log("window.location.href", window.location.href);

  return (
    <InputTextStyled
      label={
        required
          ? `${t("subject-payment-iban-label")} *`
          : t("subject-payment-iban-label")
      }
      tooltip={showTooltip ? t("subject-payment-iban-tooltip") : undefined}
      placeholder={`${t("subject-payment-iban-placeholder")}`}
      onChange={(iban) => props.onChange({ value: iban, index })}
      value={props.value}
      styleInput={props.style}
      validateStatus={validateStatus}
      help={help}
      dataTestId={props.dataTestId || testId.iban}
    />
  );
};

export default InputIBAN;
