import styled from "styled-components";
import {
    PracticeFee,
    ReadOnlyPractice,
    useUpdatePracticeFeesMutation,
} from "../../../../../redux/apiSpecifications/apiCrud";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import { FormRow } from "../../../../../style/form";
import { InputTextStyled } from "../../../../../style/Input";
import { RowSpacer } from "../../../../../style/containers";
import { useState } from "react";
import { IconLoading } from "../../../../../config/icons";
import { Popconfirm } from "antd";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { useAuthorization } from "../../../../../hooks/useAuthorization";

const AppraisalDetailsFeeWrapper = styled.div`
    .value-input {
        text-align: right;
    }

    .button-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    .loading-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .box {
            height: 5em;
            width: 5em;
        }
    }
`;

interface IAppraisalDetailsFeeProps {
    // practiceFee: PracticeFee | undefined;
    practice: ReadOnlyPractice | undefined;
}

const AppraisalDetailsFee = ({ practice }: IAppraisalDetailsFeeProps) => {
    const { t } = useTranslation();

    const { activeRole } = useSelector((state: RootState) => state.user);
    const { getAuthorization } = useAuthorization();

    const [hasChanged, setHasChanged] = useState(false);
    const [finalFeeValue, setFinalFeeValue] = useState<
        number | undefined | null
    >(practice?.fee?.finalFee);
    const [savingStatus, setSavingStatus] = useState<"idle" | "saving">("idle");
    const [error, setError] = useState<string | undefined>(undefined);

    const [updatePracticeFees] = useUpdatePracticeFeesMutation();

    const handleOnChange = (value: number | undefined | null) => {
        setFinalFeeValue(value);
        setHasChanged(true);
    };

    const handleOnConfirm = async () => {
        setSavingStatus("saving");

        if (!practice || !practice!.fee || !activeRole) return;

        const idPractice = practice.id!;
        const practiceFee = practice.fee!;

        const response = await updatePracticeFees({
            authorization: await getAuthorization(),
            idPractice,
            activeRole,
            practiceFee: {
                ...practiceFee,
                finalFee: finalFeeValue,
            } as PracticeFee,
        });

        const correctResponse = response as { data: ReadOnlyPractice };
        const errorResponse = response as { error: string };

        if (correctResponse?.data?.id) {
            //
        } else {
            setError("fee-practice-error-saving");
        }

        setHasChanged(false);
        setSavingStatus("idle");
    };

    const basicFee = practice?.fee?.basicFee
        ? `${practice?.fee?.basicFee?.toString()}€`
        : "";

    const finalFee = finalFeeValue ? `${finalFeeValue?.toString()}€` : "";

    return (
        <AppraisalDetailsFeeWrapper data-testid={""}>
            {savingStatus === "saving" && (
                <div className="loading-wrapper">
                    <div className="box" data-testid={""}>
                        {IconLoading}
                    </div>
                </div>
            )}

            {savingStatus === "idle" && error && (
                <div className="error">{error}</div>
            )}

            {savingStatus === "idle" && !error && (
                <>
                    <FormRow>
                        <InputTextStyled
                            label={`${t("appraisals-fee-client-name-label")}`}
                            // placeholder={t("appraisals-fee-client-name-placeholder")}
                            value={practice?.fee?.clientName}
                            disabled={true}
                        />

                        <RowSpacer />

                        <InputTextStyled
                            label={`${t("appraisals-fee-fee-type-label")}`}
                            // placeholder={t("appraisals-fee-fee-type-placeholder")}
                            value={t(`fee-type-code-${practice?.fee?.feeType}`)}
                            disabled={true}
                        />

                        <RowSpacer />

                        <FormRow>
                            <InputTextStyled
                                classNameInput="value-input"
                                label={`${t("appraisals-fee-basic-fee-label")}`}
                                // placeholder={t("appraisals-fee-basic-fee-placeholder")}
                                value={basicFee}
                                disabled={true}
                            />

                            <RowSpacer />

                            <InputTextStyled
                                classNameInput="value-input"
                                label={`${t("appraisals-fee-final-fee-label")}`}
                                placeholder={t(
                                    "appraisals-fee-final-fee-placeholder"
                                )}
                                value={finalFee}
                                onChange={(val: string) =>
                                    handleOnChange(
                                        parseInt(val.replace("€", "")) || 0
                                    )
                                }
                                disabled={activeRole === "G2" || practice?.status === "CF"}
                            />
                        </FormRow>
                    </FormRow>

                    <div className="button-wrapper">
                        <Popconfirm
                            title={
                                t(
                                    "edit-expertizer-client-permissions-button-save-confirm"
                                )!
                            }
                            onConfirm={handleOnConfirm}
                            onCancel={() => {}}
                            okText={
                                <div data-testid={""}>{t("switch-yes")!}</div>
                            }
                            cancelText={t("switch-no")!}
                        >
                            <ButtonConfirm
                                onClick={() => {}}
                                disabled={!hasChanged || activeRole === "G2" || practice?.status === "CF"}
                            >
                                {t("button-confirm")!}
                            </ButtonConfirm>
                        </Popconfirm>
                    </div>
                </>
            )}
        </AppraisalDetailsFeeWrapper>
    );
};

export default AppraisalDetailsFee;
