import styled from "styled-components";

export const ContentWrapper = styled.div`
  .ant-collapse {
    background-color: transparent !important;
    border: none !important;
  }
  .ant-collapse {
    background-color: transparent !important;
    border: none !important;
  }

  .ant-collapse-item {
    background-color: #fff;
    margin-bottom: 1em;
    border-radius: 1em;
    overflow: hidden;
    .ant-collapse-item {
      background-color: #fff;
      margin-bottom: 1em;
      border-radius: 1em;
      overflow: hidden;

      &:last-child {
        border-radius: 1em;
      }
    }
    &:last-child {
      border-radius: 1em;
    }
  }

  .ant-collapse-header {
    text-transform: uppercase;
    background-color: #ededed;
  }
  .ant-collapse-header {
    text-transform: uppercase;
    background-color: #ededed;
  }

  .ant-collapse-content-box {
    padding: 1em 3em;
  }
  .ant-collapse-content-box {
    padding: 1em 3em;
  }
`;

export const FormRowTitle = styled.div`
  text-transform: uppercase;
  text-transform: uppercase;
`;

export const TabsWrapper = styled.div`
  .ant-tabs-nav {
    height: fit-content;
    border-radius: 0.5em;
    overflow: hidden;
    box-shadow: 0 0 5px #aaa;
  }

  .ant-tabs-nav-list {
    background-color: #fff !important;
  }

  .ant-tabs-tab {
    margin: 0 !important;
    padding: 1em 2em !important;
  }

  .ant-tabs-content-holder {
    border: none;
  }
`;

export const EmptyContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
  border-radius: 0.5em;
  overflow: hidden;
  box-shadow: 0 0 5px #aaa;
  background-color: #fff;
  font-size: 2em;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const GenericWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
  border-radius: 0.5em;
  overflow: hidden;
  box-shadow: 0 0 5px #aaa;
  background-color: #fff;
`;
