import { Button, Tag } from "antd";
import { FormItemStyled, SelectStyled } from "../../../../style/Input";
import { useTranslation } from "react-i18next";
import { SelectPair } from "../../../../types/common.types";
import styled from "styled-components";
import { AppraiserScoreCluster } from "../../../../redux/apiSpecifications/apiFesf";
import { ConfigureClusterActionType, DistrictCluster } from "../types";
import { useMemo, useState } from "react";
import { useExpertizeScoreContext } from "../ExpertizeScoreContext";
import { EditIconButton } from "../../../Inputs/IconButtons";
import ConfigureClusterNameModal from "./ConfigureClusterNameModal/ConfigureClusterNameModal";

const ConfigureClusterInputWrapper = styled.div`
  width: 100%;
  div.ant-form-item-row {
    display: flex;
    flex-direction: column;

    div.ant-form-item-label {
      text-align: left;
    }
  }

  .actions-wrapper {
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    right: 0;
    padding: 5px;
  }

  .actions-text {
    padding: 5px;
  }

`;

const TagsWrapper = styled.div`
  .tag {
    margin-top: 0.5em;
    font-size: 1.2em;
  }
`;

interface IConfigureClusterInputProps {
  cluster: AppraiserScoreCluster;
  availableProvincesOptions: SelectPair[] | undefined;
  onAction: (action: ConfigureClusterActionType, payload: any) => void;
}

const ConfigureClusterInput = ({
  cluster,
  availableProvincesOptions,
  onAction,
}: IConfigureClusterInputProps) => {
  const { t } = useTranslation();

  const { provincesOptions, canAddAllDistricts, canRemoveAllDistricts } =
    useExpertizeScoreContext();

  const handleFilter = (input: string, option: any) => {
    const text = input.toLowerCase();

    const optionLabel = (option?.label ?? "").toLowerCase();

    return optionLabel.includes(text);
  };

  return (
    <>
      <ConfigureClusterInputWrapper>
        <FormItemStyled>
          <div className="label-wrapper">
            <label>{cluster.clusterName}</label>
            <EditIconButton
              onClick={() =>
                onAction("edit-cluster-name", {
                  clusterId: cluster.id,
                })
              }
            />
          </div>
          <SelectStyled
            onChange={(value) => {
              onAction("add-district", {
                clusterId: cluster.id,
                districtId: value,
              });
            }}
            value={null}
            options={availableProvincesOptions ?? []}
            filterOption={handleFilter}
            showSearch
          />
          <div className="actions-wrapper">
            <Button
              type="text"
              disabled={!canAddAllDistricts(cluster.id!)}
              className={"action-text"}
              onClick={() => {
                onAction("add-all", { clusterId: cluster.id });
              }}
            >
              Aggiungi tutte
            </Button>
            <Button
              type="text"
              disabled={!canRemoveAllDistricts(cluster.id!)}
              className={"action-text"}
              onClick={() => {
                onAction("clear-all", { clusterId: cluster.id });
              }}
            >
              Svuota
            </Button>
          </div>
        </FormItemStyled>

        <TagsWrapper>
          {cluster.districts?.map((districtId) => (
            <span key={districtId}>
              <Tag
                closable
                onClose={(e) => {
                  e.preventDefault();

                  onAction("remove-district", {
                    clusterId: cluster.id,
                    districtId: districtId,
                  });
                }}
                className="tag"
              >
                {provincesOptions?.find((_) => _.value === districtId)?.label}
              </Tag>
            </span>
          ))}
        </TagsWrapper>
      </ConfigureClusterInputWrapper>
    </>
  );
};

export default ConfigureClusterInput;
