import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { mainTabs as testIds } from "../../../config/testIds";
import {
    DashboardWrapper,
    LoadingInTabWrapper,
    RefreshInTabWrapper,
} from "../../../style/DashbordWidgetWrappers";
import { RootState } from "../../../redux/store";
import { useEffect, useMemo, useState } from "react";
import { IconLoading } from "../../../config/icons";
import {
    Communication,
    DashboardD2Summary,
    GetPracticesD2SummaryByTenantApiArg,
    GetRequestListApiArg,
    GetUserCommunicationsListApiArg,
    PracticesInProgressSummary,
    Request,
    useLazyGetPracticesD2SummaryByTenantQuery,
    useLazyGetRequestListQuery,
    useLazyGetUserCommunicationsListQuery,
} from "../../../redux/apiSpecifications/apiCrud";
import { setInfoAlwaysVisibleTop } from "../../../redux/features/userSlice";
import { useTranslation } from "react-i18next";
import AppraisalsStatuses from "../../Widgets/Appraisals/AppraisalsStatuses";
import PracticesToManage, {
    PracticesToManageType,
} from "../../Widgets/Appraisals/AppraisalsToManage";
import AppraisalsCommunications, {
    AppraisalsCommunicationSelectionType,
} from "../../Widgets/Appraisals/AppraisalsCommunications";
import AppraisalsList from "../../Widgets/Appraisals/AppraisalsList";
import { AppraisalsStatusesType } from "../../../config/const";
import AppraisalsNegatives from "../../Widgets/Appraisals/AppraisalsNegatives";
import AppraisalsMap from "../../Widgets/Appraisals/AppraisalsMap";
import AppraisalsInProgress, {
    FilterPracticesInProgress,
} from "../../Widgets/Appraisals/AppraisalsInProgress";
import {
    AggregateSummaryDataD2,
    filterStatusByDiscardedD2,
} from "./common-utils";
import CommunicationList from "../../Widgets/Communications/CommunicationsList";
import { useInterval } from "../../../hooks/useInterval";
import TimerPie, { TimerUpdateWrapper } from "../../Layout/TimerPie";
import { useAuthorization } from "../../../hooks/useAuthorization";
import AppraisalsRequestsChartBO from "../../Widgets/Appraisals/AppraisalsRequestsCharts/AppraisalsRequestsChartBO";
import { AppraisalsRequestsChartSelectionType } from "../../Widgets/Appraisals/AppraisalsRequestsCharts/AppraisalsRequestsChartSelectionType";
import { Tenant } from "../../../redux/apiSpecifications/apiFesf";
import PracticeRequestsList from "../../Widgets/PracticeRequests/PracticeRequestsList";
import RegisteredMailErrorList from "../../Widgets/RegisteredMail/RegisteredMailErrorList";

const DashboardD2Wrapper = styled(DashboardWrapper)`
    display: grid;
    grid-template-areas:
        "leftArea rightColumn"
        "resultTable resultTable";
    grid-template-columns: 4fr 1fr;
    column-gap: 1em;
    row-gap: 1em;
`;

const LeftArea = styled.div`
    display: grid;
    flex-direction: column;
    grid-template-columns: 2fr 3fr 50px;
    grid-area: leftArea;
    grid-template-areas:
        "statuses statuses refresh"
        "toManage inProgress inProgress"
        "comunications comunications comunications";
    grid-template-rows: 3em auto auto;
    column-gap: 1em;
    row-gap: 1em;
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1em;
    grid-area: rightColumn;
`;

type laodingStatusType = "loading" | "refreshing" | "load-in-progress";

type FiltersValues = {
    status?: AppraisalsStatusesType;
    toManageTotal?: string;
    toManageDrill?: string;
};

const defaultFilters: FiltersValues = {
    status: "daily",
    toManageTotal: "urgent",
    toManageDrill: undefined,
};

interface IDashBoard_D2Props {
    disableMaps?: boolean;
    tabKey?: number;
}

const DashBoard_D2 = (props: IDashBoard_D2Props) => {
    const { disableMaps } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [retrieveSummaryData] = useLazyGetPracticesD2SummaryByTenantQuery();
    const [retriveCommunications] = useLazyGetUserCommunicationsListQuery();
    const [getRequestList] = useLazyGetRequestListQuery();

    const { selectedTenants, activeRole } = useSelector(
        (state: RootState) => state.user
    );

    const { getAuthorization } = useAuthorization();

    const { activeKey } = useSelector((state: RootState) => state.mainTabs);
    const autoRefreshTabData = useSelector(
        (state: RootState) => state.user.configParams?.autoRefreshTabData || 600
    ); // 10 minutes

    const {
        restart: restartRefrehInterval,
        reset: resetRefrehInterval,
        clear: clearRefreshInterval,
        percentage,
        remainingTime,
    } = useInterval({
        seconds: autoRefreshTabData,
        callback: () => {
            setLoadingStatus("refreshing");
            loadPractices("refreshing", true);
        },
    });

    const [allData, setAllData] = useState<DashboardD2Summary>();
    const [communicationsData, setCommunicationsData] = useState<
        Communication[]
    >([]);

    const [requestsData, setRequestsData] = useState<Request[]>([]);

    const [filters, setFilters] = useState(defaultFilters);
    const [filteredByStatusData, setFilteredByStatusData] =
        useState<DashboardD2Summary>();
    const [filteredByManagedData, setFilteredByManagedData] =
        useState<DashboardD2Summary>();
    const [filteredByInProgress, setFilteredByInProgress] =
        useState<DashboardD2Summary>();
    const [filteredByDiscarded, setFilteredByDiscarded] =
        useState<DashboardD2Summary>();

    const [loadingStatus, setLoadingStatus] = useState<
        "idle" | "loading" | "refreshing" | "load-in-progress"
    >("idle");
    const [filteringType, setFilteringType] = useState<
        | "toManage"
        | "inProgress"
        | "communications"
        | "in-verification"
        | "cancelled"
    >("toManage");
    const [communicationsFilterSelection, setCommunicationsFilterSelection] =
        useState<AppraisalsCommunicationSelectionType>({
            senderType: "",
            categoryCode: "",
            status: "",
        });

    const [requestsFilterSelection, setRequestsFilterSelection] =
        useState<AppraisalsRequestsChartSelectionType>({
            senderType: "",
            categoryCode: "",
        });

    const [resetPracticeInProgress, setResetPracticeInProgress] = useState(
        Date.now()
    );

    const [retrieveInProgressArgs, setRetrieveInProgressArgs] =
        useState<GetPracticesD2SummaryByTenantApiArg>({
            authorization: "",
            idTenant: 0,
            isDocumental: undefined,
            appraiserType: undefined,
            hasAppointment: undefined,
            practiceStatus: undefined,
        });

    const [blockTopOpen, setBlockTopOpen] = useState(true);

    useEffect(() => {
        if (activeKey === props.tabKey) {
            (async () => {
                setLoadingStatus("refreshing");
                await loadPractices("refreshing", true);
                restartRefrehInterval();
            })();
        } else clearRefreshInterval();
    }, [activeKey]);

    const loadPractices = async (
        loadingStatus: laodingStatusType,
        keepFilter?: boolean
    ) => {
        if (!selectedTenants) return;
        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        const tenantsSummaryData: DashboardD2Summary[] = [];

        for (let tenant of selectedTenants) {
            const idTenant = tenant.id!;

            const {
                isDocumental,
                appraiserType,
                hasAppointment,
                practiceStatus,
                practiceStatusAggr,
            } =
                loadingStatus === "loading" || loadingStatus === "refreshing"
                    ? {
                          isDocumental: undefined,
                          appraiserType: undefined,
                          hasAppointment: undefined,
                          practiceStatus: undefined,
                          practiceStatusAggr: undefined,
                      }
                    : loadingStatus === "load-in-progress"
                    ? retrieveInProgressArgs
                    : {
                          isDocumental: undefined,
                          appraiserType: undefined,
                          hasAppointment: undefined,
                          practiceStatus: undefined,
                          practiceStatusAggr: undefined,
                      };

            const response = await retrieveSummaryData({
                idTenant,
                authorization: await getAuthorization(),
                isDocumental,
                appraiserType,
                hasAppointment,
                practiceStatus,
                practiceStatusAggr,
            });

            const { data, error, isSuccess, isError } = response;

            if (isSuccess && data) {
                tenantsSummaryData.push(data as DashboardD2Summary);
            } else if (isError) {
                dispatch(
                    setInfoAlwaysVisibleTop({
                        type: "error",
                        message: t("http-error-loading-dashboard"),
                    })
                );
                setLoadingStatus("idle");
                return;
            } else {
                console.log("unknown error");
            }
        }

        const aggregatedSummaryData =
            AggregateSummaryDataD2(tenantsSummaryData);

        if (loadingStatus === "loading" || loadingStatus === "refreshing") {
            setAllData(aggregatedSummaryData);
            setFilteredByStatusData(aggregatedSummaryData);
            const discarded = filterStatusByDiscardedD2(aggregatedSummaryData);
            setFilteredByManagedData(discarded);
            setFilteredByDiscarded(discarded);
        } else if (loadingStatus === "load-in-progress") {
            setFilteredByInProgress(aggregatedSummaryData);
        }

        setLoadingStatus("idle");
    };

    const loadCommunications = async (
        selection: AppraisalsCommunicationSelectionType
    ) => {
        if (!selectedTenants) return;

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("refreshing");

        const params: any = {
            authorization: await getAuthorization(),
            tenants: selectedTenants.map((t) => t.id!),
        };

        if (selection.senderType) params.senderType = selection.senderType;
        if (selection.categoryCode)
            params.categoryCode = selection.categoryCode;
        if (selection.status) params.statusCode = selection.status;

        const response = await retriveCommunications(
            params as GetUserCommunicationsListApiArg
        );
        const { data, error, isSuccess, isError } = response;

        if (isSuccess && data) {
            setCommunicationsData(data as Communication[]);
        } else if (isError) {
            dispatch(
                setInfoAlwaysVisibleTop({
                    type: "error",
                    message: t("http-error-loading-dashboard"),
                })
            );
            setLoadingStatus("idle");
            return;
        } else {
            console.log("unknown error");
        }

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("idle");
    };

    const loadRequests = async (
        selection: AppraisalsRequestsChartSelectionType
    ) => {
        if (!selectedTenants) return;

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("refreshing");

        const params: GetRequestListApiArg = {
            authorization: await getAuthorization(),
            activeRole: activeRole!,
            tenants: selectedTenants.map((t: Tenant) => t.id!),
            lastMessageSenderType: selection.lastMessageSenderType ?? "",
            lastMessageReceiverType: selection.lastMessageReceiverType ?? "",
            categoryCode: selection.categoryCode ?? "",
            statusCode: selection.statusCode,
        };

        const response = await getRequestList(params);

        const { data, error, isSuccess, isError } = response;

        if (isSuccess && data) {
            setRequestsData(data as Request[]);
        } else if (isError) {
            dispatch(
                setInfoAlwaysVisibleTop({
                    type: "error",
                    message: t("http-error-loading-dashboard"),
                })
            );
            setLoadingStatus("idle");
            return;
        } else {
            console.log("unknown error");
        }

        dispatch(
            setInfoAlwaysVisibleTop({
                type: "empty",
                message: "",
            })
        );

        setLoadingStatus("idle");
    };

    useEffect(() => {
        if (loadingStatus !== "idle" || !selectedTenants) return;
        setLoadingStatus("loading");
        loadPractices("loading");
    }, [selectedTenants]);

    useEffect(() => {
        if (loadingStatus !== "idle" || !selectedTenants) return;
        setLoadingStatus("load-in-progress");
        loadPractices("load-in-progress");
    }, [retrieveInProgressArgs]);

    const handleFilterStatus = (status: AppraisalsStatusesType) => {
        if (status === "in-verification") {
            setFilteringType("in-verification");
        } else if (status === "cancelled") {
            setFilteringType("cancelled");
        } else {
            setFilteringType("toManage");
        }

        setFilters({ ...defaultFilters, status });

        const filteredPractices = (
            allData?.dashboardSummary?.practices || []
        ).filter((practice) => {
            if (status === "daily")
                return true; // status "daily" reset all filters
            else if (status === "assigned") return !!practice.appraiser;
            else if (status === "discarded") return !practice.appraiser;
            else if (status === "in-verification" && practice.status === "IV")
                return true;
            else if (status === "cancelled" && practice.status === "CAN")
                return true;
            else return false;
        });

        const practicesToManage = {
            total: filteredPractices.length,
            urgent: {
                rca: filteredPractices.filter(
                    (practice) =>
                        practice.priority === 1 &&
                        practice.claimsBranchGroup === "RCA"
                ).length,
                cvt: filteredPractices.filter(
                    (practice) =>
                        practice.priority === 1 &&
                        practice.claimsBranchGroup === "CVT"
                ).length,
            },
            due: {
                rca: filteredPractices.filter(
                    (practice) =>
                        practice.priority === 2 &&
                        practice.claimsBranchGroup === "RCA"
                ).length,
                cvt: filteredPractices.filter(
                    (practice) =>
                        practice.priority === 2 &&
                        practice.claimsBranchGroup === "CVT"
                ).length,
            },
            new: {
                rca: filteredPractices.filter(
                    (practice) =>
                        practice.priority === 3 &&
                        practice.claimsBranchGroup === "RCA"
                ).length,
                cvt: filteredPractices.filter(
                    (practice) =>
                        practice.priority === 3 &&
                        practice.claimsBranchGroup === "CVT"
                ).length,
            },
            suspended: {
                rca: filteredPractices.filter(
                    (practice) =>
                        practice.priority === 4 &&
                        practice.claimsBranchGroup === "RCA"
                ).length,
                cvt: filteredPractices.filter(
                    (practice) =>
                        practice.priority === 4 &&
                        practice.claimsBranchGroup === "CVT"
                ).length,
            },
        };

        const totalDailyPractices =
            allData?.dashboardSummary?.totalDailyPractices;
        const totalAssignedPractices =
            allData?.dashboardSummary?.totalAssignedPractices;
        const totalDiscardedPractices =
            allData?.dashboardSummary?.totalDiscardedPractices;

        const communicationSummary =
            allData?.dashboardSummary?.communicationSummary;

        const result = {
            dashboardSummary: {
                totalDailyPractices,
                totalAssignedPractices,
                totalDiscardedPractices,
                practicesToManage,
                communicationSummary,
                practices: filteredPractices,
            },
            practicesInProgress: allData?.practicesInProgress,
            practicesToBeVerified: allData?.practicesToBeVerified,
            practicesCancelled: allData?.practicesCancelled,
        };

        setFilteredByStatusData(result);
        setFilteredByManagedData(result);

        setResetPracticeInProgress(Date.now());
        setBlockTopOpen(true);
    };

    const handleFilterToManage = (
        totalType: string | undefined,
        drillType: string | undefined
    ) => {
        setFilteringType("toManage");
        const updatedFilters = {
            ...defaultFilters,
            toManageTotal: totalType,
            toManageDrill: drillType,
        };

        setFilters(updatedFilters);
        const practices = (
            filteredByDiscarded?.dashboardSummary?.practices || []
        ).filter((practice) => {
            let toInclude = true;

            switch (totalType) {
                case "urgent":
                    toInclude = (toInclude && practice.priority === 1) || false;
                    break;
                case "due":
                    toInclude = (toInclude && practice.priority === 2) || false;
                    break;
                case "new":
                    toInclude = (toInclude && practice.priority === 3) || false;
                    break;
                case "suspended":
                    toInclude = (toInclude && practice.priority === 4) || false;
                    break;
            }

            if (drillType) {
                switch (drillType) {
                    case "rca":
                        toInclude =
                            (toInclude &&
                                practice.claimsBranchGroup === "RCA") ||
                            false;
                        break;
                    case "cvt":
                        toInclude =
                            (toInclude &&
                                practice.claimsBranchGroup === "CVT") ||
                            false;
                        break;
                }
            }

            return toInclude;
        });

        const practicesToManage = {
            total: practices.length,
            urgent: {
                rca: practices.filter(
                    (practice) =>
                        practice.priority === 1 &&
                        practice.claimsBranchGroup === "RCA"
                ).length,
                cvt: practices.filter(
                    (practice) =>
                        practice.priority === 1 &&
                        practice.claimsBranchGroup === "CVT"
                ).length,
            },
            due: {
                rca: practices.filter(
                    (practice) =>
                        practice.priority === 2 &&
                        practice.claimsBranchGroup === "RCA"
                ).length,
                cvt: practices.filter(
                    (practice) =>
                        practice.priority === 2 &&
                        practice.claimsBranchGroup === "CVT"
                ).length,
            },
            new: {
                rca: practices.filter(
                    (practice) =>
                        practice.priority === 3 &&
                        practice.claimsBranchGroup === "RCA"
                ).length,
                cvt: practices.filter(
                    (practice) =>
                        practice.priority === 3 &&
                        practice.claimsBranchGroup === "CVT"
                ).length,
            },
            suspended: {
                rca: practices.filter(
                    (practice) =>
                        practice.priority === 4 &&
                        practice.claimsBranchGroup === "RCA"
                ).length,
                cvt: practices.filter(
                    (practice) =>
                        practice.priority === 4 &&
                        practice.claimsBranchGroup === "CVT"
                ).length,
            },
        };

        const totalDailyPractices =
            allData?.dashboardSummary?.totalDailyPractices;
        const totalAssignedPractices =
            allData?.dashboardSummary?.totalAssignedPractices;
        const totalDiscardedPractices =
            allData?.dashboardSummary?.totalDiscardedPractices;

        const communicationSummary =
            allData?.dashboardSummary?.communicationSummary;

        const result = {
            dashboardSummary: {
                totalDailyPractices,
                totalAssignedPractices,
                totalDiscardedPractices,
                practicesToManage,
                communicationSummary,
                practices,
            },
            practicesInProgress: allData?.practicesInProgress,
            practicesToBeVerified: allData?.practicesToBeVerified,
            practicesCancelled: allData?.practicesCancelled,
        };

        setFilteredByManagedData(result);
        setResetPracticeInProgress(Date.now());
        setBlockTopOpen(true);
    };

    const handleApplyFilterInProgress = async (
        filter: FilterPracticesInProgress
    ) => {
        setFilteringType("inProgress");

        const args: any = {
            authorization: await getAuthorization(),
            practiceStatusAggr: "2",
        };

        if (filter.level1 !== undefined)
            args.isDocumental = filter.level1 === "documental";
        if (filter.level2 !== undefined) args.appraiserType = filter.level2;
        if (filter.level3 !== undefined)
            args.hasAppointment = filter.level3 === "yes";

        setRetrieveInProgressArgs(args);
        setBlockTopOpen(true);
        setFilters({
            status: "in-progress",
            toManageTotal: "urgent",
            toManageDrill: undefined,
        } as FiltersValues);
    };

    const handleFilterCommunications = (
        selection: AppraisalsCommunicationSelectionType
    ) => {
        setFilteringType("communications");
        setCommunicationsFilterSelection(selection);

        // loadCommunications(selection);
        setBlockTopOpen(false);
    };

    const handleFilterRequests = (
        selection: AppraisalsRequestsChartSelectionType
    ) => {
        setFilteringType("communications");
        setRequestsFilterSelection(selection);

        loadRequests(selection);

        setBlockTopOpen(false);
    };

    const practicesToShowInList = useMemo(() => {
        const toManageTotal =
            filters.toManageTotal === "urgent"
                ? 1
                : filters.toManageTotal === "due"
                ? 2
                : filters.toManageTotal === "new"
                ? 3
                : filters.toManageTotal === "suspended"
                ? 4
                : "";

        return filters.status === "daily" && loadingStatus === "idle"
            ? filteredByManagedData?.dashboardSummary?.practices
            : filteringType === "toManage" && loadingStatus === "idle"
            ? filteredByManagedData?.dashboardSummary?.practices?.filter(
                  (x) => x.priority == toManageTotal
              )
            : filteringType === "inProgress" && loadingStatus === "idle"
            ? filteredByInProgress?.dashboardSummary?.practices
            : filteringType === "in-verification" && loadingStatus === "idle"
            ? allData?.dashboardSummary?.practices?.filter(
                  (x) => x.status === "IV"
              )
            : filteringType === "cancelled" && loadingStatus === "idle"
            ? allData?.dashboardSummary?.practices?.filter(
                  (x) => x.status === "CAN"
              )
            : [];
    }, [
        filters,
        allData,
        filteredByManagedData,
        filteredByInProgress,
        filteringType,
        loadingStatus,
    ]);

    return (
        <div data-testid={testIds.dashboards.D2}>
            {loadingStatus === "loading" && (
                <LoadingInTabWrapper>
                    <div className="box" data-testid={testIds.D2.loader}>
                        {IconLoading}
                    </div>
                </LoadingInTabWrapper>
            )}
            {(loadingStatus === "refreshing" ||
                loadingStatus === "load-in-progress" ||
                loadingStatus === "idle") && (
                <>
                    <DashboardD2Wrapper>
                        <LeftArea>
                            <TimerUpdateWrapper>
                                <TimerPie
                                    percentage={percentage as number}
                                    tooltip={`${t(
                                        "timer-dashboard-refresh-tooltip-1"
                                    )!}  ${remainingTime}`}
                                    innerText={remainingTime as string}
                                    bgColor="#32b677"
                                    gridArea="refresh"
                                    onClick={() => {
                                        setLoadingStatus("refreshing");
                                        loadPractices("refreshing", true);
                                        resetRefrehInterval();
                                    }}
                                />
                            </TimerUpdateWrapper>
                            <AppraisalsStatuses
                                daily={
                                    allData?.dashboardSummary
                                        ?.totalDailyPractices
                                }
                                assigned={
                                    allData?.dashboardSummary
                                        ?.totalAssignedPractices
                                }
                                discarded={
                                    allData?.dashboardSummary
                                        ?.totalDiscardedPractices
                                }
                                inVerification={allData?.practicesToBeVerified}
                                cancelled={allData?.practicesCancelled}
                                showInVerfications={true}
                                showCancelled={true}
                                onSelect={handleFilterStatus}
                                selected={filters.status}
                                gridArea={"statuses"}
                            />
                            <PracticesToManage
                                practices={
                                    filteredByDiscarded?.dashboardSummary
                                        ?.practicesToManage as PracticesToManageType
                                }
                                onSelect={handleFilterToManage}
                                statusSelected={filters.status}
                                totalSelected={filters.toManageTotal}
                                gridArea={"toManage"}
                                isActive={filteringType === "toManage"}
                                isOpen={blockTopOpen}
                                selectedLevel1={filters.toManageTotal}
                                selectedLevel2={filters.toManageDrill}
                            />
                            <AppraisalsInProgress
                                gridArea={"inProgress"}
                                summary={allData?.practicesInProgress}
                                onApplyFilter={handleApplyFilterInProgress}
                                isActive={filteringType === "inProgress"}
                                isOpen={blockTopOpen}
                                resetFilter={resetPracticeInProgress}
                            />

                            <AppraisalsRequestsChartBO
                                isActive={filteringType === "communications"}
                                userRequestsSummary={
                                    allData?.dashboardSummary
                                        ?.userRequestsSummary!
                                }
                                gridArea={"comunications"}
                                onSelect={handleFilterRequests}
                                isOpen={false}
                                selection={requestsFilterSelection}
                            />
                            {/* <AppraisalsCommunications
                              isActive={filteringType === "communications"}
                              communications={allData?.dashboardSummary?.communicationSummary}
                              gridArea={"comunications"}
                              onSelect={handleFilterCommunications}
                              selection={communicationsFilterSelection}
                            /> */}
                        </LeftArea>
                        {/* <RightColumn> */}
                            {/* <AppraisalsNegatives isOpen={false} /> */}
                            <RegisteredMailErrorList
                                registeredLetterErrors={
                                    allData?.dashboardSummary
                                        ?.registeredLetterErrors!
                                }
                                isOpen={true}
                                
                            />
                            {/* <AppraisalsMap disable={disableMaps} /> */}
                        {/* </RightColumn> */}

                        {(filteringType === "toManage" ||
                            filteringType === "inProgress" ||
                            filteringType === "in-verification" ||
                            filteringType === "cancelled") && (
                            <AppraisalsList
                                items={practicesToShowInList}
                                gridArea={"resultTable"}
                                isActive={activeKey === props.tabKey}
                                scenario="dashboard_bo"
                                onRefresh={() => {}}
                            />
                        )}
                        {filteringType === "communications" && (
                            <PracticeRequestsList
                                items={requestsData}
                                gridArea={"resultTable"}
                            />
                            // <CommunicationList
                            //     items={communicationsData}
                            //     gridArea={"resultTable"}
                            // />
                        )}

                        {/*  testing purpose only */}
                        <div
                            data-testid={testIds.D2.testFilterByManagedButton}
                            style={{ display: "none" }}
                            onClick={() =>
                                handleFilterToManage("urgent", "rca")
                            }
                        ></div>
                    </DashboardD2Wrapper>
                    {(loadingStatus === "refreshing" ||
                        loadingStatus === "load-in-progress") && (
                        <RefreshInTabWrapper>
                            <div
                                className="box"
                                data-testid={testIds.D2.loader}
                            >
                                {IconLoading}
                            </div>
                        </RefreshInTabWrapper>
                    )}
                </>
            )}
        </div>
    );
};

export default DashBoard_D2;
