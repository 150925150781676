/**
 * Convert a file to base64 string
 */

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export const downloadFile = (base64: string, originalName: string) => {
  const blob = base64ToBlob(base64);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", originalName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

const base64ToBlob = (base64: string): Blob => {
  const byteString = atob(base64);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: getContentType(base64) });
};

const getContentType = (base64: string): string => {
  const header = base64.substring(0, 30);

  if (header.includes("data:image/jpeg") || header.includes("data:image/jpg")) {
    return "image/jpeg";
  } else if (header.includes("data:image/png")) {
    return "image/png";
  } else if (header.includes("data:application/pdf")) {
    return "application/pdf";
  } else {
    return "application/octet-stream";
  }
};
