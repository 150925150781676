import styled from "styled-components";
import {
  Pie,
  PieChart,
  Legend,
  Sector,
  ResponsiveContainer,
  Label,
  Tooltip,
} from "recharts";
import { charts as testIds } from "../../config/testIds";
import { useCallback, useState } from "react";
import { DefaultTFuncReturn } from "i18next";

const Wrapper = styled.div`
  margin-top: 1em;
  width: 100%;
`;

const Title = styled.div`
  padding-left: 2em;
`;

const ChartWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 280px;
  position: relative;

  .recharts-pie {
    outline: none;
  }

  .recharts-legend-wrapper {
    display: flex;
    justify-content: center;
  }

  .recharts-legend-item-text {
    text-transform: uppercase;
    font-size: 0.8em;
  }

  .recharts-pie-sector {
    path {
      cursor: pointer;
    }
  }

  .recharts-pie-labels {
    text {
      font-size: 0.9em;
    }
  }

  .legend-label {
    color: #333;
  }

  .total-number {
    display: flex;
    font-size: 24px;
    font-weight: bold;
    color: #000;
  }
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  background-color: #fff;
  padding: 0.5em 1em 0.25em 1em;
  box-shadow: 0 0 5px #555;
  border-radius: 1em;

  .value {
    font-weight: bold;
    font-size: 1.2em;
    margin-right: 0.5em;
  }

  .name {
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 2px;
  }
`;

export type DonutDataItemType = {
  key: string;
  name: string;
  value: number;
  fill: string;
};

interface IDonutChartProps {
  title: string | JSX.Element | DefaultTFuncReturn;
  data: DonutDataItemType[];
  centerValue: string;
  onClick: (key: string | undefined) => void;
  selected?: string;
  unselectOnClick?: boolean;
  hideLegend?: boolean;
}

const DonutChart = (props: IDonutChartProps) => {
  const {
    title,
    data,
    onClick,
    centerValue,
    selected,
    unselectOnClick,
    hideLegend,
  } = props;
  const selectedIndex = data.findIndex((item) => item.key === selected);

  const renderActiveShape = useCallback((props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props;
    return (
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 4}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    );
  }, []);

  const handleOnClick = (key: any) => {
    if (key === selected && unselectOnClick) {
      onClick(undefined);
    } else onClick(key);
  };

  const renderCustomizedLabel = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, value, key } = props;

    if (!value) return <></>;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        onClick={() => handleOnClick(key)}
      >
        {value}
      </text>
    );
  };

  const renderTooltip = (props: any) => {
    const { payload } = props;
    if (!payload || !payload[0]) return;

    const { name, value } = payload[0];
    return (
      <TooltipWrapper>
        <div className="value">{value}</div>
        <div className="name">{name}</div>
      </TooltipWrapper>
    );
  };

  const emptyData = data.reduce((acc, item) => acc + item.value, 0) === 0;

  return emptyData ? (
    <Wrapper data-testid={testIds.donut.container}></Wrapper>
  ) : (
    <Wrapper data-testid={testIds.donut.container}>
      <Title>{title}</Title>
      <ChartWrapper>
        <ResponsiveContainer>
          <PieChart>
            <Tooltip
              active={true}
              content={renderTooltip}
              isAnimationActive={false}
            />
            {!(hideLegend ?? false) && (
              <Legend
                height={36}
                iconType="circle"
                layout="horizontal"
                verticalAlign="bottom"
                align="center"
                iconSize={10}
                formatter={(value, entry, index) => (
                  <div className="legend-label">{value}</div>
                )}
              />
            )}
            <Pie
              label={renderCustomizedLabel}
              labelLine={false}
              data={data}
              innerRadius={50}
              outerRadius={75}
              fill="-----"
              paddingAngle={0}
              dataKey="value"
              onClick={(e) => handleOnClick(e.key)}
              cursor={"pointer"}
              activeShape={renderActiveShape}
              activeIndex={selectedIndex}
            >
              <Label
                value={centerValue}
                position="center"
                className="total-number"
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </Wrapper>
  );
};

export default DonutChart;
