import styled from "styled-components";

import { useState } from "react";
import { Appraiser } from "../../../../../redux/apiSpecifications/apiCrud";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import imgIconAppraiser from "../../../../../images/icons/expertizer.png";
import {
  IconCheck,
  IconEmail,
  IconPhone,
  IconPlace,
} from "../../../../../config/icons";
import { useTranslation } from "react-i18next";
import dekra from "../../../../../style/dekra";
import { Popconfirm } from "antd";
import { mainTabs } from "../../../../../config/testIds";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

const testIds = mainTabs.tabs.activities.appraiserDetails;

const AppraiserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2em;

  .col {
    flex: 1;
    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      flex-direction: row;
      margin-bottom: 1em;

      &:second-child {
        margin-bottom: 3em;
      }
      .icon {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1.8em;
        img {
          width: 36px;
        }
      }

      .text {
        flex: 1;

        .text-row {
          display: flex;
          flex-direction: row;
          flex: 1;
        }

        .label {
          width: 12em;
        }

        .value {
          flex: 1;
          text-align: right;
        }
      }
    }

    .nominative {
      font-size: 1.2em;
      margin-bottom: 1em;
      padding-left: 1em;
      letter-spacing: 1px;
    }

    .practice-status {
      padding: 3.2em 0 0 3em;

      .label {
        text-transform: uppercase;
      }

      .status {
      }
    }
  }
  .actions {
    flex: 1;

    width: 100%;
    display: flex;
    gap: 1em;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 2em;
  }
`;

interface IAppraiserDetailsProps {
  appraiser: Appraiser | undefined;
  practiceStatus: string;
  onChange: () => void;
  onSuspend: () => void;
}

const AppraiserDetails = (props: IAppraiserDetailsProps) => {
  const { appraiser, practiceStatus, onChange, onSuspend } = props;
  const { t } = useTranslation();
  const activeRole = useSelector((state: RootState) => state.user.activeRole);

  const subject = props.appraiser?.subject;
  const address = subject?.addresses?.find((a) => a.type === "O");

  const showSuspendeButton = ["E1", "E4"].includes(activeRole || "") === false;

  const nominative =
    subject?.subjectType === "PF"
      ? `${subject?.name} ${subject?.lastname}`
      : subject?.subjectType === "PG"
      ? subject?.businessName
      : "";
  const formattedAddress = `${address?.street} ${address?.civic} ${address?.city} ${address?.postalCode} ${address?.province} ${address?.country}`;

  const phone = subject?.contacts?.find(
    (c) => c.type === "T" && c.useCase === "PUB"
  )?.value;
  const email = subject?.contacts?.find(
    (c) => c.type === "E" && c.useCase === "ICO"
  )?.value;

  return (
    <AppraiserWrapper data-testid={testIds.container}>
      <div className="col">
        <div className="nominative" data-testid={testIds.nominative}>
          {nominative}
        </div>
        <div className="row">
          <div className="icon">
            <img src={imgIconAppraiser} alt="appraiser icon" />
          </div>

          <div className="text">
            <div className="text-row">
              <div className="label">
                {t(
                  "appraisals-details-tab-activities-appraiser-details-count-montly-label"
                )}
              </div>
              <div className="value"> {appraiser?.maxPracticeCountDaily}</div>
            </div>
            <div className="text-row">
              <div className="label">
                {t(
                  "appraisals-details-tab-activities-appraiser-details-count-daily-label"
                )}
              </div>
              <div className="value"> {appraiser?.practiceCountDaily}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="icon">
            <IconPlace />
          </div>
          <div className="text">
            <div className="text-row">{formattedAddress}</div>
          </div>
        </div>
        <div className="row">
          <div className="icon">
            <IconPhone />
          </div>
          <div className="text">
            <div className="text-row">{phone}</div>
          </div>
        </div>
        <div className="row">
          <div className="icon">
            <IconEmail />
          </div>
          <div className="text">
            <div className="text-row">{email}</div>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="practice-status">
          <div className="label">
            {t("appraisals-details-tab-activities-appraisals-status-label")}
          </div>
          <div className="status">
            {t(`appraisal-statuses-${practiceStatus}`)}
          </div>
        </div>
      </div>
      <div className="actions">
        {showSuspendeButton && (
          <Popconfirm
            placement="leftBottom"
            icon={null}
            title={t(
              "appraisals-details-tab-activities-select-appraiser-confirm-suspend"
            )}
            onConfirm={onSuspend}
            okText={
              <div data-testid={testIds.buttonSuspendPracticeConfirm}>
                {t("switch-yes")}
              </div>
            }
            cancelText={t("switch-no")}
          >
            <ButtonConfirm
              onClick={() => {}}
              dataTestId={testIds.buttonSuspendPractice}
              disabled={practiceStatus === "CF"}
            >
              {
                t(
                  "appraisals-details-tab-activities-appraiser-details-button-suspend"
                )!
              }
            </ButtonConfirm>
          </Popconfirm>
        )}
        <Popconfirm
          placement="leftBottom"
          icon={null}
          title={t(
            "appraisals-details-tab-activities-select-appraiser-confirm-change"
          )}
          onConfirm={onChange}
          okText={
            <div data-testid={testIds.buttonChangeConfirm}>
              {t("switch-yes")}
            </div>
          }
          cancelText={t("switch-no")}
        >
          <ButtonConfirm
            onClick={() => {}}
            dataTestId={testIds.buttonChange}
            disabled={practiceStatus === "CF"}
          >
            {
              t(
                "appraisals-details-tab-activities-appraiser-details-button-change"
              )!
            }
          </ButtonConfirm>
        </Popconfirm>
      </div>
    </AppraiserWrapper>
  );
};

export default AppraiserDetails;
