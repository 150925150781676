import styled from "styled-components";
import { WidgetWrapper } from "../../../../style/containers";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { SelectStyled } from "../../../../style/Input";
import { SelectPair } from "../../../../types/common.types";
import { useExpertizeScoreContext } from "../ExpertizeScoreContext";
import AppraiserScoreConfigForm from "./AppraiserScoreConfigForm";
import { Prompt } from "../Prompt/Prompt";
import { Alert, Pagination, Popconfirm } from "antd";
import { ButtonConfirm } from "../../../Layout/Buttons";

export const ConfigureThresholdWeightWrapper = styled(WidgetWrapper)`
    align-items: unset;
    flex-direction: column;
    gap: 2em;
    max-width: 100%;
    overflow: auto;
    padding: 2em;

    .buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em;
    }
`;

const ConfigurationPanelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const RateHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 22em;
    width: -webkit-fill-available;

    .label {
        // display: flex;
        // justify-content: flex-end;
        text-align: center;
        font-weight: bold;
        width: 5em;
        margin-right: 1em;
        // overflow: hidden;
    }
`;

const ConfigureThresholdWeight = () => {
    const { t } = useTranslation();

    const {
        appraiserScoreClusterList,
        selectedClusterId,
        selectedClusterConfig,
        isFormChanged,
        onChangeSelectedCluster,
        onSubmitConfigureThresholdWeightForm,
    } = useExpertizeScoreContext();

    const [showPrompt, setShowPrompt] = useState<boolean>(false);

    const [clusterToSelect, setClusterToSelect] = useState<number | undefined>(
        undefined
    );

    const [quantitativeError, setQuantitativeError] = useState<boolean | null>(
        null
    );

    const [qualitativeError, setQualitativeError] = useState<boolean | null>(
        null
    );

    const clusterOptions = useMemo(() => {
        const clusterOptions = appraiserScoreClusterList?.map(
            (cluster) =>
                ({
                    value: cluster.id,
                    label: cluster.clusterName,
                } as SelectPair)
        );

        return clusterOptions;
    }, [appraiserScoreClusterList]);

    const handleOnChangeCluster = (clusterId: number) => {
        if (isFormChanged) {
            setClusterToSelect(clusterId);

            setShowPrompt(true);
        } else onChangeSelectedCluster(clusterId);
    };

    const handleOnCancelPrompt = () => {
        setShowPrompt(false);
        setQuantitativeError(null);
        setQualitativeError(null);
    };

    const handleOnConfirmPrompt = () => {
        setShowPrompt(false);
        setQuantitativeError(null);
        setQualitativeError(null);
        // onCancelFormChanges();

        onChangeSelectedCluster(clusterToSelect!);
    };

    const validateWeights = () => {
        let quantitativeWeightTotal = 0;
        let qualitativeWeightTotal = 0;

        quantitativeWeightTotal += Number(
            selectedClusterConfig?.confirmationReactivityWeight || 0
        );
        quantitativeWeightTotal += Number(
            selectedClusterConfig?.visionReactivityWeight || 0
        );
        quantitativeWeightTotal += Number(
            selectedClusterConfig?.appraisalTimeWeight || 0
        );
        quantitativeWeightTotal += Number(
            selectedClusterConfig?.meanAppraisalAmountWeight || 0
        );

        qualitativeWeightTotal += Number(
            selectedClusterConfig?.accessesCountWeight || 0
        );
        qualitativeWeightTotal += Number(
            selectedClusterConfig?.noAccessesCountWeight || 0
        );
        qualitativeWeightTotal += Number(
            selectedClusterConfig?.agreementsCountWeight || 0
        );
        qualitativeWeightTotal += Number(
            selectedClusterConfig?.rejectedCountWeight || 0
        );
        qualitativeWeightTotal += Number(
            selectedClusterConfig?.accountingVerifyCountWeight || 0
        );

        if (quantitativeWeightTotal !== 100) {
            setQuantitativeError(true);
        } else {
            setQuantitativeError(null);
        }

        if (qualitativeWeightTotal !== 100) {
            setQualitativeError(true);
        } else {
            setQualitativeError(null);
        }

        return {
            hasQuantitativeError: quantitativeWeightTotal !== 100,
            hasQualitativeError: qualitativeWeightTotal !== 100,
        };
    };

    const handleOnSubmitConfigureThresholdWeightForm = () => {
        const { hasQuantitativeError, hasQualitativeError } = validateWeights();

        if (hasQuantitativeError || hasQualitativeError) {
            return;
        }

        onSubmitConfigureThresholdWeightForm();
    };

    return (
        <ConfigureThresholdWeightWrapper>
            <div>
                <SelectStyled
                    label={"Cluster"}
                    options={clusterOptions!}
                    onChange={handleOnChangeCluster}
                    value={selectedClusterId}
                />
            </div>

            {quantitativeError && (
                <Alert
                    message={t("error-message-on-quantities-weight")}
                    type="error"
                />
            )}

            {qualitativeError && (
                <Alert
                    message={t("error-message-on-qualitative-weight")}
                    type="error"
                />
            )}

            <ConfigurationPanelWrapper>
                <AppraiserScoreConfigForm />
            </ConfigurationPanelWrapper>

            <div className="buttons-wrapper">
                <Popconfirm
                    placement="top"
                    icon={null}
                    title={t("configuration-panel-button-save-confirm")!}
                    onConfirm={handleOnSubmitConfigureThresholdWeightForm}
                    okText={t("switch-yes")!}
                    cancelText={t("switch-no")!}
                >
                    <ButtonConfirm disabled={!isFormChanged} onClick={() => {}}>
                        {t("button-save")!}
                    </ButtonConfirm>
                </Popconfirm>
            </div>

            <Prompt
                open={showPrompt}
                onCancel={handleOnCancelPrompt}
                onConfirm={handleOnConfirmPrompt}
            />

            {/* <pre>{JSON.stringify(selectedClusterConfig, null, 2)}</pre> */}
        </ConfigureThresholdWeightWrapper>
    );
};

export default ConfigureThresholdWeight;
