import { Modal } from "antd";
import { ButtonsWrapper } from "../../style/ButtonsWrapper";
import { ButtonCancel, ButtonConfirm } from "../../../../Layout/Buttons";
import styled from "styled-components";
import { UserItem } from "../../UserItem";
import { useTranslation } from "react-i18next";
import useUserCollaboratorConfigForm from "./useUserCollaboratorConfigForm";
import WaitingForNetworkDelay from "../../../../Layout/WaitingForNetworkDelay";
import { ModalTitleWrapper } from "../../../../../style/containers";
import { IconNaturalSubject } from "../../../../../config/icons";
import UserCollaboratorConfigForm from "./UserCollaboratorConfigForm";
import { useMemo } from "react";
import { SelectPair } from "../../../../../types/common.types";

const ModalContent = styled.div`
  padding: 2em;

  .ant-form-item-explain {
    color: red !important;
  }
`;

const UserCollaboratorConfigModalTitleWrapper = styled(ModalTitleWrapper)`
  padding: 0 0 1em 0;
  svg {
    margin-left: 0;
  }
  span {
    margin-left: 1em;
    color: #333;
    letter-spacing: 1px;
  }
`;

interface IUserCollaboratorConfigModalProps {
  user: UserItem;
  onCancel: () => void;
  onConfirm: (studioId: number) => void;
}

const UserCollaboratorConfigModal = ({
  user,
  onCancel,
  onConfirm,
}: IUserCollaboratorConfigModalProps) => {
  const { t } = useTranslation();

  const {
    data,
    canSubmit,
    userUICommunications,
    studiosList,
    handleDataChange,
  } = useUserCollaboratorConfigForm(user);

  const handleOnSave = () => {
    const studioId = data.studioId;

    onConfirm(studioId!);
  };

  const studioOptions = useMemo(() => {
    if (studiosList === undefined) return [];

    const studioOptionsArray: SelectPair[] = [];

    studiosList.forEach((studio) => {
      studioOptionsArray.push({
        value: studio.id!.toString(),
        label: studio.appraiserName!,
      });
    });

    return studioOptionsArray;
  }, [studiosList]);

  return (
    <Modal
      open={true}
      footer={null}
      closable={false}
      onCancel={onCancel}
      width="600px"
    >
      <ModalContent>
        {userUICommunications.action !== "idle" ? (
          <WaitingForNetworkDelay
            action={userUICommunications.action}
            status={userUICommunications.status}
            result={userUICommunications.result}
            errorDetails={userUICommunications.errorDetails}
            onGoBack={() => {}}
            onDismiss={() => {}}
          />
        ) : (
          <>
            <UserCollaboratorConfigModalTitleWrapper>
              <IconNaturalSubject />
              <span>{user.contact}</span>
            </UserCollaboratorConfigModalTitleWrapper>

            <UserCollaboratorConfigForm
              model={data}
              studioOptions={studioOptions}
              onChange={handleDataChange}
            />

            <ButtonsWrapper>
              <ButtonCancel onClick={onCancel}>
                {t("button-cancel")!}
              </ButtonCancel>

              <ButtonConfirm onClick={handleOnSave} disabled={!canSubmit}>
                {t("button-confirm")!}
              </ButtonConfirm>
            </ButtonsWrapper>

            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default UserCollaboratorConfigModal;
