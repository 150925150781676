import { Button, Dropdown, MenuProps, Space } from "antd";
import dekra from "../../../../../style/dekra";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useMemo } from "react";
import { ReadOnlyPractice } from "../../../../../redux/apiSpecifications/apiCrud";
import { RequestHelper } from "./RequestHelper";

interface IButtonNewRequestProps {
    practice: ReadOnlyPractice;
    disabled?: boolean;
    onMenuClick: (key: string) => void;
}

const ButtonNewRequest = ({
    disabled,
    onMenuClick,
}: IButtonNewRequestProps) => {
    const { t } = useTranslation();

    const { activeRole } = useSelector(
        (rootState: RootState) => rootState.user
    );

    const handleMenuClick: MenuProps["onClick"] = (e) => {
        onMenuClick(e.key);
    };

    const items = useMemo(() => {
        const availableCategories = RequestHelper.GetCategoriesForNewButton(
            activeRole!
        );

        const result: MenuProps["items"] = [];

        availableCategories.forEach((category) => {
            result.push({
                label: t(`practice-request-category-description-${category}`)!,
                key: category,
            });
        });

        return result;
    }, [activeRole]);

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <Dropdown menu={menuProps}>
            <Button
                type="primary"
                style={Object.assign(
                    {},
                    {
                        backgroundColor: disabled ? "#fff" : dekra.primaryColor,
                        borderColor: disabled ? "#ddd" : dekra.primaryColor,
                        color: disabled ? "#ddd" : dekra.secondaryColors.white,
                    }
                )}
            >
                <Space>
                    {t("button-new-request")!}
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
};

export default ButtonNewRequest;
