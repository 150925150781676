import styled from "styled-components";
import { ButtonConfirm } from "../../Layout/Buttons";
import { Popconfirm, notification } from "antd";
import { InputTextStyled } from "../../../style/Input";
import { t } from "i18next";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useEffect, useMemo, useState } from "react";
import {
  CoverageLevel,
  CoverageLevelSettings,
  SetCoverageLevelSettingsApiArg,
  useLazyGetCoverageLevelSettingsQuery,
  useSetCoverageLevelSettingsMutation,
} from "../../../redux/apiSpecifications/apiCrud";
import { IconLoading } from "../../../config/icons";
import { LoadingFullScreenWrapper } from "../../../style/DashbordWidgetWrappers";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useNotifications } from "../../../hooks/useNotifications";

const ConfigurationPanelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1em;

  .wrapper-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 2em;
    border-radius: 0.5em;
    overflow: hidden;
    box-shadow: 0 0 5px #aaa;
    background-color: #fff;
    gap: 2em 10em;
  }

  .buttons-wrapper {
    width: 100%;
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .header {
      text-align: center;
      font-size: 1em;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 2em;
    }

    th {
      font-weight: normal;
    }

    td {
      .square-text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-right: 1em;
      }
    }
  }

  .input-percentage {
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input {
      -moz-appearance: textfield;
      padding-right: 1.8em;
    }
  }
`;

const ThresoldsWrapper = styled(Wrapper)`
  grid-area: thresolds;

  .ant-form-item {
    width: 5em;
  }

  .square-S1,
  .square-S2,
  .square-S3 {
    width: 3em;
    height: 2em;
    border-radius: 5px;
    margin-right: 1em;
  }

  .square-S1 {
    background-color: #ef7c00;
  }

  .square-S2 {
    background-color: #ffcc00;
  }

  .square-S3 {
    background-color: #64b32c;
  }
`;

const VariablesWrapper = styled(Wrapper)`
  grid-area: variables;

  .ant-form-item {
    width: 5em;
    margin-left: 2em;
  }

  .subtitle {
    text-transform: uppercase;
    font-size: 0.9em;
    padding-top: 2em;
  }

  .margin-left {
    padding-left: 2em;
  }
`;

const CoverageLevelConfiguration = () => {
  const { getAuthorization } = useAuthorization();

  const [status, setStatus] = useState<"idle" | "loading" | "saving" | "error">(
    "idle"
  );
  const [data, setData] = useState<CoverageLevelSettings | undefined>();
  const [popconfirmSave, setPopconfirmSave] = useState<boolean>(false);

  // API
  const [getCoverageLevelSettings] = useLazyGetCoverageLevelSettingsQuery();
  const [setCoverageLevelSettings] = useSetCoverageLevelSettingsMutation();

  const { displaySuccess, displayFetchBaseQueryErrorNotification } =
    useNotifications();

  const levels = useMemo(() => {
    if (data === undefined) return {};

    return {
      S1: data.coverageLevels?.find((cl) => cl.coverageLevelCode === "S1"),
      S2: data.coverageLevels?.find((cl) => cl.coverageLevelCode === "S2"),
      S3: data.coverageLevels?.find((cl) => cl.coverageLevelCode === "S3"),
    };
  }, [data]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setStatus("loading");

    const { data, isError, isSuccess } = await getCoverageLevelSettings({
      authorization: await getAuthorization(),
    });

    if (isSuccess) {
      const _data = data as CoverageLevelSettings;

      const formattedDate = {
        ..._data,
        coverageLevels: _data.coverageLevels?.map((cl) => {
          return {
            coverageLevelCode: cl.coverageLevelCode,
            minValue: Math.floor((cl.minValue || 0) * 1000) / 10,
            maxValue: Math.round((cl.maxValue || 0) * 1000) / 10,
          };
        }),
      };

      setData(formattedDate);
    } else if (isError) {
      setStatus("error");
      // to do
      return;
    }

    setStatus("idle");
  };

  const handleChange = (val: any, type: string, levelValue: "minValue" | "maxValue" | "") => {
    let updatedData: CoverageLevelSettings = {
      ...data,
    };

    if (type === "S1" || type === "S2" || type === "S3") {
      const parsedVal = parseFloat(val);
      if (parsedVal < 0 || parsedVal > 100) return;

      const step = 0.1;
      let S1 =
        updatedData.coverageLevels?.find((r) => r.coverageLevelCode === "S1") ||
        ({} as CoverageLevel);
      let S2 =
        updatedData.coverageLevels?.find((r) => r.coverageLevelCode === "S2") ||
        ({} as CoverageLevel);

      let S3 =
        updatedData.coverageLevels?.find((r) => r.coverageLevelCode === "S3") ||
        ({} as CoverageLevel);

      if (type === "S1") {
        if (levelValue === "minValue") S1.minValue = parsedVal;
        else if (levelValue === "maxValue") {
          S1.maxValue = parsedVal;
          S2.minValue = parsedVal + step;
        }
      }

      if (type === "S2") {
        if (levelValue === "minValue") {
          S2.minValue = parsedVal;
          S1.maxValue = parsedVal - step;
        } else if (levelValue === "maxValue") {
          S2.maxValue = parsedVal;
          S3.minValue = parsedVal + step;
        }
      }

      if (type === "S3") {
        if (levelValue === "minValue") {
          S3.minValue = parsedVal;
          S2.maxValue = parsedVal - step;
        }
      }

      updatedData.coverageLevels = [S1, S2, S3];
    } else {
      const parsedVal = parseFloat(val);
      if (parsedVal < 0) return;

      (updatedData[type as keyof CoverageLevelSettings] as number) = parsedVal;
    }

    setData(updatedData);
  };

  const handleSaveStatus = async () => {
    setPopconfirmSave(false);
    
    setStatus("saving");

    const coverageLevelSettings = {
      ...data,
      coverageLevels: data?.coverageLevels?.map((cl) => {
        return {
          coverageLevelCode: cl.coverageLevelCode,
          minValue: cl.minValue! / 100,
          maxValue: cl.maxValue! / 100,
        };
      }),
    };

    const payload: SetCoverageLevelSettingsApiArg = {
      authorization: await getAuthorization(),
      coverageLevelSettings,
    };

    const response = await setCoverageLevelSettings(payload);

    const correctResponse = response as {
      data: SetCoverageLevelSettingsApiArg;
    };

    if (correctResponse.data) {
      await loadData();

      displaySuccess(t("coverage-level-configuration-success-message"));

      setStatus("idle");
    } else {
      const errorReponse = response as {
        error: FetchBaseQueryError;
      };

      displayFetchBaseQueryErrorNotification(errorReponse.error);
    }
  };

  const disableConfirmButton = useMemo(() => {
    const S2 = data?.coverageLevels?.find(
      (cl) => cl.coverageLevelCode === "S2"
    );

    if (S2?.minValue! >= S2?.maxValue! || data?.assignmentMapRange === 0)
      return true;

    return false;
  }, [data]);

  return (
    <>
      {["loading", "saving"].includes(status) && (
        <LoadingFullScreenWrapper>
          <div className="box">{IconLoading}</div>
        </LoadingFullScreenWrapper>
      )}

      <ConfigurationPanelWrapper>
        <div className="wrapper-content">
          <ThresoldsWrapper>
            <div className="inner-wrapper">
              <div className="header">
                {t("coverage-level-configuration-thresolds-header")}
              </div>

              <div className="content">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        {t("coverage-level-configuration-thresolds-table-from")}
                      </th>
                      <th>
                        {t("coverage-level-configuration-thresolds-table-to")}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {["S1", "S2", "S3"].map((levelCode) => (
                      <tr key={levelCode}>
                        <td>
                          <div className="square-text">
                            <div className={`square-${levelCode}`}></div>

                            <div className="label">
                              {t(
                                `coverage-level-configuration-saturation-cluster-${levelCode}`
                              )}
                            </div>
                          </div>
                        </td>

                        <td>
                          <InputTextStyled
                            type="number"
                            label=""
                            value={
                              levels[levelCode as keyof typeof levels]?.minValue
                            }
                            onChange={(val) =>
                              handleChange(val, levelCode, "minValue")
                            }
                            disabled={["S1", "S3"].includes(levelCode)}
                          />
                        </td>

                        <td>
                          <InputTextStyled
                            type="number"
                            label=""
                            value={
                              levels[levelCode as keyof typeof levels]?.maxValue
                            }
                            onChange={(val) =>
                              handleChange(val, levelCode, "maxValue")
                            }
                            disabled={["S1", "S3"].includes(levelCode)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </ThresoldsWrapper>

          <VariablesWrapper>
            <div className="inner-wrapper">
              <div className="header">
                {t("coverage-level-configuration-variables-header")}
              </div>

              <div className="content">
                <table>
                  <tbody>
                    <tr>
                      <td>{t("coverage-level-configuration-panel-param-1")}</td>

                      <td>
                        <InputTextStyled
                          type="number"
                          label=""
                          value={data?.assignmentMapRange}
                          onChange={(val) =>
                            handleChange(val, "assignmentMapRange", "")
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </VariablesWrapper>

          <div className="buttons-wrapper">
            <Popconfirm
              placement="top"
              icon={null}
              open={popconfirmSave}
              title={t("configuration-panel-button-save-confirm")!}
              onConfirm={handleSaveStatus}
              onCancel={() => setPopconfirmSave(false)}
              okText={t("switch-yes")!}
              cancelText={t("switch-no")!}
            >
              <ButtonConfirm
                onClick={() => setPopconfirmSave(true)}
                disabled={disableConfirmButton}
              >
                {t("button-save")!}
              </ButtonConfirm>
            </Popconfirm>
          </div>
        </div>
      </ConfigurationPanelWrapper>
    </>
  );
};

export default CoverageLevelConfiguration;
