import { useTranslation } from "react-i18next";
import { SelectStyled } from "../../style/Input";
import { useDispatch, useSelector } from "react-redux";
import { setActiveRole } from "../../redux/features/userSlice";
import { RootState } from "../../redux/store";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { SelectPair } from "../../types/common.types";
import { updateHomeDashboard } from "../../redux/features/mainTabsSlice";
import { selectActiveRole as testId } from "../../config/testIds";

const SelectActiveRoleWrapper = styled.div`
  .ant-select-selection-item {
    text-transform: uppercase;
  }
`;

interface ISelectActiveRoleProps {
  onChange: () => void;
}

const SelectActiveRole = (props: ISelectActiveRoleProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { userDetails, activeRole } = useSelector(
    (state: RootState) => state.user
  );

  const [availableRoles, setAvailableRoles] = useState<SelectPair[]>([]);

  useEffect(() => {
    if (!userDetails?.roles) return;

    const _availableRoles = userDetails?.roles.map((role) => ({
      value: role.roleCode!,
      label: t(`role-description-${role.roleCode!}`),
    }));

    setAvailableRoles(_availableRoles);
  }, [userDetails?.roles]);

  const handleChangeActiveRole = (newRole: string) => {
    dispatch(setActiveRole(newRole));
    if (userDetails && userDetails?.roles) {
      const fullRole = userDetails?.roles.find((r) => r.roleCode === newRole);
      if (fullRole) {
        dispatch(updateHomeDashboard(fullRole));
        props.onChange();
      }
    }
  };

  return (
    <SelectActiveRoleWrapper data-testid={testId.container}>
      <SelectStyled
        label={t("select-active-role-label")}
        tooltip={t("select-active-role-tooltip")}
        onChange={handleChangeActiveRole}
        value={activeRole}
        options={availableRoles}
      />
    </SelectActiveRoleWrapper>
  );
};

export default SelectActiveRole;
