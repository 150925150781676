import { SubjectType } from "../config/const";
import { DamagedPartAdditionalInfo, SubjectPayment } from "../redux/apiSpecifications/apiCrud";
import { Contact } from "../redux/apiSpecifications/apiCrud";
import { DamagedPart, Subject as SubjectModel, SubjectMainData, SubjectDocument } from "../redux/apiSpecifications/apiCrud";
import { SelectPair } from "./common.types";
import { SubjectContactData, SubjectGiuridicalPersonData, SubjectNaturalPersonData } from "./uses-data.types";

export type ClaimDataOwnerVehiclePlateType = {
	number: string;
	format: string;
};

export type ClaimOwnerVehicleType = {
	type: string;
	plate: ClaimDataOwnerVehiclePlateType;
};

export type ActiveClaimCardQuestion = {
	howManyVehicles: number;
	vehicleAType: string;
	vehicleBType: string;
	collision: boolean;
	inItaly: boolean;
};

export type ClaimDataType = {
	number: string;
	registrationDate: string;
	receiptDate: string;
	dateOfReceiptCompany: string;
	dateOfReceiptDekra: string;
	occurrenceDate: string;
	occurrenceTime: string;
	occurrencePlace?: any;
	policeIntervention: boolean;
	witnesses: boolean;
	note: string;
};

export type UpdateNewClaimDataFieldsType =
	| "receiptDate"
	| "dateOfReceiptCompany"
	| "dateOfReceiptDekra"
	| "occurrenceDate"
	| "occurrenceTime"
	| "occurrencePlace"
	| "policeIntervention"
	| "witnesses"
	| "note"
	| "isOwnerNaturalPerson"
	| "ownerName"
	| "ownerLastname"
	| "ownerBusinessName"
	| "driverName"
	| "driverLastname"
	| "plate"
	| "insuranceCode";

export type UpdateNewClaimResponsabilityDataFieldsType = "barems" | "forced-reason" | "signature-type";

export type PartChangeType =
	| "damage-type"
	| "collision-point"
	| "person-damage"
	| "role-type"
	| "subject"
	| "owner-vehicle-note"
	| "person-note"
	| "thing-note"
	| "management-type";

export type PartChangeTypeType = "" | "Person" | "Thing" | "Vehicle" | "Location" | "Generic";

export type SubjectPersonalDataType = {
	id: number;
};

export type PartDamagedDetailsPerson = {
	personWoundedPoints: string[];
	personlData?: SubjectPersonalDataType;
	note?: string;
};

export type PartDamagedDetailsVehicle = {
	plate: string;
	format: string;
	type: string;
	collisionPoints: string[];
	note: string;
};

export type PartDamagedDetailsThing = {
	note: string;
};

export type DamagedType = {
	damageType: PartChangeTypeType;
	details: PartDamagedDetailsPerson | PartDamagedDetailsVehicle | PartDamagedDetailsThing;
};

export type DamagedPartType = {
	pdNumber: string;
	subject?: SubjectNaturalPersonData | SubjectGiuridicalPersonData;
	roleType: string;
	damages: DamagedType[];
};

export type DamagedPartPair = {
	damagedPart: DamagedPart;
	index: number;
};

export type NewClaimStepTextType = {
	title: string;
	description: string;
};

export type NewClaimStepItem = {
	number: number;
	waiting: NewClaimStepTextType;
	inProgress: NewClaimStepTextType;
	done: NewClaimStepTextType;
};

export type ResponsabilityTypes = "1" | "2" | "3" | "4" | "5" | "---";
export type ResponsabilitiesType = {
	card: SelectPair[];
	noCard: SelectPair[];
};

export type SteppedChangeDataType = "vehicles_number" | "vehicle_a_type" | "vehicle_b_type" | "collision" | "inItaly";

export type VehicoleTypeType = "A" | "B" | "C" | "M" | "Q" | "T" | "W" | "R" | "S" | "---";

export type ClaimType = "CARD" | "NO CARD" | "---";

export type CardWizardDataType = {
	numberOfVehicles: number;
	isVehicleAVisible: boolean;
	vehicleAType: VehicoleTypeType;
	isVehicleBVisible: boolean;
	vehicleBType: VehicoleTypeType;
	isCollisionVisible: boolean;
	collision: boolean;
	isInItalyVisible: boolean;
	inItaly: boolean;
	claimType: ClaimType;
};

export enum ActiveClaimStateType {
	Unknown,
	MandatoryData,
	CheckingData,
	VerifingSic,
	SicCorrect,
	SicError,
	AdditionalData,
	Resume,
}

export type BaremsResultType = {
	vehicleA: number;
	vehicleB: number;
	result: string;
};

export type ResponsabilityDataType = {
	barems: BaremsResultType;
	forcedReason: string;
	responsabilityType: string;
	responsabilityPercentage: string;
	signatureType: string;
};

export type ClaimDataCounterpartDataType = {
	isOwnerNaturalPerson: boolean;
	ownerName: string;
	ownerLastname: string;
	ownerBusinessName: string;
	driverName: string;
	driverLastname: string;
	plate: string;
	insuranceCode: string;
};

export type AdditionaInfoTypes = undefined | "subject" | "document" | "payment" | "contact";
export type AdditionalInfoSubjectRoleTypes = "---" | "coachbuilder" | "expert" | "lawyer";

export type AdditionalInfoSubjectType = {
	role: AdditionalInfoSubjectRoleTypes;
	subject?: SubjectNaturalPersonData | SubjectGiuridicalPersonData;
};

export type AdditionalInfoDocumentTypes = "---" | "id-card" | "PO" | "expertise" | "police-report";

export type AdditionalInfoDocumentType = {
	type: AdditionalInfoDocumentTypes;
	filename?: string[];
};

// A = check
// B = bank transfer
export type AdditionalInfoPaymentTypes = "---" | "A" | "B";

export type AdditionalInfoPaymentType = {
	type: AdditionalInfoPaymentTypes;
	iban?: string;
};

export type AdditionalInfoContactType = {
	shippingAddress?: string;
	email?: string;
	phone?: string;
};

// export type AdditionalInfoDataType = {
// 	id: number;
// 	type: AdditionaInfoTypes;
// 	damagedPartNumber: string;
// 	subject?: SubjectMainData;
// 	document?: SubjectDocument;
// 	payment?: SubjectPayment;
// 	contact?: Contact;
// };

export type AdditionalInfoDetails = {
	additionalInfo?: DamagedPartAdditionalInfo;
	additionalInfoIndex?: number;
	damagedPartIndex: number;
};

////////////////////////////

export type ClaimModel = {};

export type ClaimCounterpartModel = {
	subjectType?: SubjectType;
	ownerName?: string;
	ownerLastname?: string;
	ownerBusinessName?: string;
	driverName?: string;
	driverLastname?: string;
	plate?: string;
	insuranceCode?: string;
};

export type ClaimResponsabilityModel = {
	barems: BaremsResultType;
	forcedReason: string;
	responsabilityPercentage: string;
	signatureType: SignatureTypes;
	responsabilityType: ClaimResponsabilityType;
};

export type ResponsabilityPercentageType = "" | "0%" | "50%" | "100%";
export type SignatureTypes = "" | "0" | "1" | "2";

export type ClaimManagementType = "" | "managed" | "insolvency" | "debtor";
export type ClaimResponsabilityType = "" | "R" | "C" | "T";

export type DamagedPartRoleType = "" | "CP" | "CN" | "TP" | "TN" | "TS" | "NP" | "TD";
export type DamagedPartManagementType = "" | "CG" | "NC";

// A = open
// D = denunciato
// CS = Chiuso senza seguito
// C = Chiuso
// DC = Denuncia chiusa
// R = Riaperto
export type DamagedPartStatusType = "" | "A" | "D" | "CS" | "C" | "DC" | "R";
export type DamagedPartConventionType = "" | "CID" | "CTT";

export type DamagedPartAttributes = {
	managementType: DamagedPartManagementType;
	status: DamagedPartStatusType;
	convention: DamagedPartConventionType;
	preventivePassiveReserve: number;
	recoveryReserve: number;
};

export type VehicleCollisionPoint = "front" | "front-right" | "right" | "back-right" | "back" | "back-left" | "left" | "front-left";

export type WoundPointType =
	| "front_head"
	| "front_trunc"
	| "front_arm_left"
	| "front_arm_right"
	| "front_leg_left"
	| "front_leg_left"
	| "front_leg_right"
	| "rear_head"
	| "rear_trunc"
	| "rear_arm_left"
	| "rear_arm_right"
	| "rear_arm_right"
	| "rear_leg_left"
	| "rear_leg_right";
