import styled from "styled-components";
import { ButtonConfirm } from "../../Layout/Buttons";
import { IconLoading } from "../../../config/icons";
import { useTranslation } from "react-i18next";
import {
    AccountingReportList,
    useCloseAccountingReportMutation,
    useLazyGetAccountingReportQuery,
} from "../../../redux/apiSpecifications/apiFesf";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { AttachmentFile } from "../../../redux/apiSpecifications/apiCrud";
import React from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Tag } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const ActionsWrapper = styled.div`
    display: flex;
    gap: 1em;

    .confirm-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
    }

    button {
        font-size: 0.9em;
    }
`;

interface IReportActionsProps {
    reportId: number;
    generationStatus: string;
    reportStatus: string;
    canReview: boolean;
    canClose: boolean;
    onChangeStatus?: () => void;
}

const ReportActions = ({
    reportId,
    generationStatus,
    reportStatus,
    canReview,
    canClose,
    onChangeStatus,
}: IReportActionsProps) => {
    const { t } = useTranslation();

    const refDownload = React.useRef<HTMLAnchorElement>(null);

    const { authorization, activeRole } = useSelector(
        (state: RootState) => state.user
    );

    const [getAccountingReport] = useLazyGetAccountingReportQuery();
    const [closeAccountingReport] = useCloseAccountingReportMutation();

    const handleOnDownloadReport = async (reportId: number) => {
        const response = await getAccountingReport({
            authorization: authorization,
            id: reportId,
            activeRole: activeRole!,
        });

        const { data, error, isSuccess, isError } = response;

        if (isSuccess && data) {
            const attachmentFile = data as AttachmentFile;

            const link = document.createElement("a");

            link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${attachmentFile?.base64File!}`;
            link.target = "_blank";
            link.download = attachmentFile?.originalName!; // `${attachmentFile?.originalName}.xlsx`;
            link.click();

            refDownload?.current?.click();
        } else {
            console.info(error);
        }
    };

    const handleOnChangeReportStatus = async (reportId: number) => {
        const response = await closeAccountingReport({
            authorization: authorization,
            id: reportId,
        });

        const correctResponse = response as {
            data: AccountingReportList;
        };

        const errorResponse = response as {
            error: FetchBaseQueryError;
        };

        if (onChangeStatus) onChangeStatus();
    };

    return (
        <ActionsWrapper>
            {["Q", "W"].includes(generationStatus) && (
                <div className="confirm-loader">{IconLoading}</div>
            )}

            {generationStatus === "E" && (
                <Tag icon={<CloseCircleOutlined />} color="error">
                    {t("report-generation-status-code-E")}
                </Tag>
            )}

            {generationStatus === "C" && (
                <ButtonConfirm onClick={() => handleOnDownloadReport(reportId)}>
                    {t("button-download-report")!}
                </ButtonConfirm>
            )}

            {canReview && generationStatus === "C" && reportStatus === "CI" && (
                <ButtonConfirm
                    onClick={() => handleOnChangeReportStatus(reportId)}
                >
                    {t("button-review-report")!}
                </ButtonConfirm>
            )}

            {canClose && generationStatus === "C" && reportStatus === "CR" && (
                <ButtonConfirm
                    onClick={() => handleOnChangeReportStatus(reportId)}
                >
                    {t("button-close-report")!}
                </ButtonConfirm>
            )}
        </ActionsWrapper>
    );
};

export default ReportActions;
