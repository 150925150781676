import { useEffect, useState } from "react";
import styled from "styled-components";
import { LoadingStatus } from "../AppraiserDetails/AppraiserDetailsPresentational";
import { RefreshInTabWrapper } from "../../../../style/DashbordWidgetWrappers";
import { IconLoading } from "../../../../config/icons";
import {
    CreateTenantFeeConfigurationApiResponse,
    TenantFeesByType,
    useCreateTenantFeeConfigurationMutation,
    useLazyGetAllTenantFeeConfigsQuery,
} from "../../../../redux/apiSpecifications/apiFesf";
import { useAuthorization } from "../../../../hooks/useAuthorization";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { GenericWrapper } from "../Commons/Wrappers";
import { InputTextStyled } from "../../../../style/Input";
import { useTranslation } from "react-i18next";
import { Pagination } from "antd";
import TenantFees, { ChangeFeeValueType } from "./TenantFees";
import { ButtonConfirm } from "../../../Layout/Buttons";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";

const FeesClientsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
`;

const FeesClientsContent = styled(GenericWrapper)`
    display: flex;
    width: auto;
    flex-direction: column;
    margin: 1em;
    align-items: flex-start;
    overflow-x: auto;

    .tenants-wrapper {
        margin: 2em 0;
        display: flex;
        flex-direction: column;
        gap: 3em;
    }

    .header-wrapper {
        display: flex;
        flex-direction: row;

        .filter-wrapper {
            input {
                width: 15em;
            }
        }
    }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 10em;
        align-self: center;
        flex: 1;

        .pagination-wrapper {
            flex: 1;
            display: flex;
            margin-bottom: 2em;
        }
    }
`;

type TenantData = {
    tenantData: TenantFeesByType;
    modified: boolean;
};

const FeesClients = () => {
    const { t } = useTranslation();

    const { getAuthorization } = useAuthorization();

    const activeRole = useSelector((state: RootState) => state.user.activeRole);

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");
    const [tenantsFees, setTenantsFees] = useState<TenantData[]>();
    const [feesCode, setFeesCode] = useState<string[]>();
    const [error, setError] = useState<string | undefined>(undefined);
    const [filter, setFilter] = useState<string>("");
    const [filteredTenantsFees, setFilteredTenantsFees] =
        useState<TenantData[]>();
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [canSave, setCanSave] = useState<boolean>(false);

    const [getAllTenantFeeConfigs] = useLazyGetAllTenantFeeConfigsQuery();
    const [saveTenantFees] = useCreateTenantFeeConfigurationMutation();

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (!tenantsFees) return;

        const lcFilter = filter.toLowerCase();

        const ftr = tenantsFees.filter((tenantsFee) =>
            filter === ""
                ? true
                : tenantsFee.tenantData.tenantName
                      ?.toLowerCase()
                      .includes(lcFilter)
        );

        setFilteredTenantsFees(ftr);
    }, [filter, tenantsFees]);

    useEffect(() => {
        validateData();
    }, [tenantsFees]);

    const loadData = async () => {
        setLoadingStatus("loading");

        const response = await getAllTenantFeeConfigs({
            authorization: await getAuthorization(),
            activeRole: activeRole!,
        });

        if (response.isSuccess && response.data) {
            const newTenantsFees: TenantData[] = response.data.map(
                (tenant) => ({
                    tenantData: tenant,
                    modified: false,
                })
            );

            setTenantsFees(newTenantsFees);

            const newFeesCode =
                newTenantsFees[0].tenantData.tenantFeeConfig?.map(
                    (c) => c.feeType!
                );

            setFeesCode(newFeesCode);
        } else {
            // error
            // to do
        }

        setLoadingStatus("idle");
    };

    const handleOnChange = (
        tenantId: number | undefined,
        feeCode: string,
        value: string | undefined | null,
        type: ChangeFeeValueType
    ) => {
        if (!tenantId) return;

        const updatedTenantsRates = tenantsFees?.map((tenantFees) => {
            if (tenantFees.tenantData.tenantId !== tenantId) return tenantFees;

            return {
                modified: true,
                tenantData: {
                    tenantId: tenantFees.tenantData.tenantId,
                    tenantName: tenantFees.tenantData.tenantName,
                    tenantFeeConfig: tenantFees.tenantData.tenantFeeConfig?.map(
                        (fee) => {
                            if (fee.feeType !== feeCode) return fee;

                            if (type === "fee-base") {
                                return {
                                    ...fee,
                                    feeAmount: value,
                                };
                            }
                        }
                    ),
                } as TenantFeesByType,
            } as TenantData;
        });

        setTenantsFees(updatedTenantsRates);
    };

    const validateData = () => {
        let isValid = true;
        let atLeastOneModified = false;

        tenantsFees?.forEach((tenantFees) => {
            if (tenantFees.modified) atLeastOneModified = true;

            // const feesWithValue = tenantFees.tenantData.tenantFeeConfig?.filter(
            //     (fee) => fee.feeAmount !== null && fee.feeAmount !== 0
            // );

            // if ((feesWithValue?.length || 0) < 1) isValid = false; // at least one fee with value
        });

        setCanSave(isValid && atLeastOneModified);
    };

    const handleOnSave = async () => {
        setLoadingStatus("loading");

        const tenantsFiltered =
            tenantsFees
                ?.filter((tenantFees) => tenantFees.modified)
                .map((TenantFees) => TenantFees.tenantData)
                .flat() || [];

        const tenantsToSave = tenantsFiltered.map((tenantFeesByType) => {
            return {
                ...tenantFeesByType,
                tenantFeeConfig: tenantFeesByType.tenantFeeConfig?.map(
                    (tenantFee) => {
                        return {
                            ...tenantFee,
                            feeAmount: Number(
                                tenantFee.feeAmount
                                    ?.toString()
                                    .replace(",", ".")
                            ),
                        };
                    }
                ),
            };
        });

        for (const tenant of tenantsToSave) {
            const response = await saveTenantFees({
                authorization: await getAuthorization(),
                tenantFeesByType: tenant,
                id: tenant.tenantId!,
                activeRole: activeRole!,
            });

            const correctResponse = response as {
                data: CreateTenantFeeConfigurationApiResponse;
            };

            const errorResponse = response as {
                error: FetchBaseQueryError;
            };

            if (correctResponse.data) {
                // reset modified
                const newTenantsFees = tenantsFees?.map(
                    (tenantData) =>
                        ({
                            modified: false,
                            tenantData: tenantData.tenantData,
                        } as TenantData)
                );

                setTenantsFees(newTenantsFees);
            } else if (errorResponse.error) {
                notifyError(errorResponse.error);
            }
        }

        setLoadingStatus("idle");
    };

    const [api, contextHolder] = notification.useNotification();

    const notifyError = (error: FetchBaseQueryError) => {
        let description: string = t("notification-default-description");

        if (error.status === "FETCH_ERROR") {
            description = error["error"];
        } else if (["400", "404"].includes(error.status.toString())) {
            if (Array.isArray(error["data"])) {
                description = error["data"].map((m) => m).join(" | ");
            } else if (typeof error["data"] === "string") {
                description = error["data"];
            }
        } else {
            console.log(error);
        }

        api.error({
            message: t("notification-default-message"),
            description: description,
            duration: 5,
            placement: "top" as NotificationPlacement,
        });
    };

    return (
        <FeesClientsWrapper>
            {contextHolder}
            {loadingStatus === "loading" && (
                <RefreshInTabWrapper>
                    <div className="box" data-testid={""}>
                        {IconLoading}
                    </div>
                </RefreshInTabWrapper>
            )}

            {error === undefined && (
                <FeesClientsContent>
                    <div className="header-wrapper">
                        <div className="filter-wrapper">
                            <InputTextStyled
                                placeholder={t(
                                    "fees-clients-filter-placeholder"
                                )}
                                value={filter}
                                onChange={(txt) => setFilter(txt)}
                            />
                        </div>
                    </div>

                    <div className="tenants-wrapper">
                        {filteredTenantsFees
                            ?.slice((page - 1) * pageSize, page * pageSize)
                            .map((tenantFees, i) => (
                                <TenantFees
                                    key={i}
                                    tenantData={tenantFees.tenantData}
                                    onChange={handleOnChange}
                                />
                            ))}

                        {/* <pre>{JSON.stringify(tenantsFees, null, 2)}</pre> */}
                    </div>

                    <div className="footer">
                        <div className="pagination-wrapper">
                            <Pagination
                                current={page}
                                defaultPageSize={pageSize}
                                onChange={setPage}
                                total={filteredTenantsFees?.length}
                            />
                        </div>

                        <ButtonConfirm
                            disabled={!canSave}
                            onClick={handleOnSave}
                        >
                            {t("button-save")!}
                        </ButtonConfirm>
                    </div>
                </FeesClientsContent>
            )}
        </FeesClientsWrapper>
    );
};

export default FeesClients;
