import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { baseUrlClaims } from "../../../config/const";
import { RootState } from "../../store";
import { checkToken } from "./baseApi";

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrlClaims,
  prepareHeaders: (headers, { getState }) => {
    const { authorization } = (getState() as RootState)?.user;

    if (authorization !== "") {
      headers.set("Authorization", authorization);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await checkToken(api);

  let result = await baseQuery(args, api, extraOptions);

  return result;
};

export const baseSplitApiClaims = createApi({
  reducerPath: "apiClaims",
  baseQuery: baseQueryWithReauth, // fetchBaseQuery({ baseUrl: baseUrlClaims }),
  endpoints: () => ({}),
});
