import { Modal } from "antd";
import styled from "styled-components";
import { ButtonsWrapper } from "../../../../../MenuContent/UserManagement/style/ButtonsWrapper";
import { ButtonCancel, ButtonConfirm } from "../../../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import NewRequestForm from "./NewRequestForm";
import useNewRequestForm from "./useNewRequestForm";
import { SecondaryPracticeRequest } from "../../../../../../redux/apiSpecifications/apiCrud";

const ModalContent = styled.div`
    padding: 2em;

    .ant-form-item-explain {
        color: red !important;
    }
`;

const ModalTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.4em;
    letter-spacing: 1px;
    width: 100%;
    text-transform: uppercase;
    color: #333;
`;

interface INewRequestModalProps {
    category: string;
    onCancel: () => void;
    onSend: (secondaryPracticeRequest: SecondaryPracticeRequest) => void;
}

const NewRequestModal = ({
    category,
    onCancel,
    onSend,
}: INewRequestModalProps) => {
    const { t } = useTranslation();

    const { data, toList, canSubmit, handleDataChange } =
        useNewRequestForm(category);

    const handleOnSend = () => {
        const secondaryPracticeRequest: SecondaryPracticeRequest = {
            isUrgent: data.priority! === "U",
            categoryCode: data.categoryCode!,
            recipientProfile: Number(data.to!),
            requestObject: data.object!,
            messages: [{ messageText: data.text! }],
        };

        onSend(secondaryPracticeRequest);
    };

    const handleOnCancel = () => {
        onCancel();
    };

    return (
        <Modal
            open={true}
            footer={null}
            closable={false}
            onCancel={handleOnCancel}
            width="800px"
        >
            <ModalContent>
                <ModalTitleWrapper>
                    <span>{t(`new-request-form-modal-title`)!} </span>
                </ModalTitleWrapper>

                <NewRequestForm
                    model={data}
                    toList={toList}
                    onChange={handleDataChange}
                />

                <ButtonsWrapper>
                    <ButtonCancel onClick={handleOnCancel}>
                        {t("button-cancel")!}
                    </ButtonCancel>

                    <ButtonConfirm onClick={handleOnSend} disabled={!canSubmit}>
                        {t("button-send")!}
                    </ButtonConfirm>
                </ButtonsWrapper>
            </ModalContent>
        </Modal>
    );
};

export default NewRequestModal;
