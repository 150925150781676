import { useMemo, useState } from "react";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { t } from "i18next";
import styled from "styled-components";
import {
  Address,
  PracticeMapData,
} from "../../../../../redux/apiSpecifications/apiCrud";

const PracticesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
`;

const CellWrapper = styled.div`
  cursor: pointer;
  padding: 1em 0;

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    button {
      font-size: 0.8em;
    }
  }
`;

export type PracticeDataItem = {
  id: number;
  practiceNumber: string;
  practiceStatus: string;
  assignedAppraiser: string;
  receiptDate: string;
  appraisalLocationAddress: string;
  latitude?: number;
  longitude?: number;
};

interface IAssignmentMapPracticesListProps {
  practices?: PracticeMapData[] | undefined;
  onSelectPractice: (
    idPractice: number,
    latitude: number,
    longitude: number
  ) => void;
}

const AssignmentMapPracticesList = ({
  practices,
  onSelectPractice,
}: IAssignmentMapPracticesListProps) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const handleFormatAddress = (
    appraisalLocationAddress: Address | undefined
  ): string => {
    if (!appraisalLocationAddress) {
      return "";
    }

    const { street, civic, city, province } = appraisalLocationAddress;

    const addressParts = [
      street,
      civic && `${civic},`,
      city,
      province && `(${province})`,
    ].filter(Boolean);

    return addressParts.join(" ");
  };

  const data = useMemo(
    () =>
      practices?.map(
        (item) =>
          ({
            id: item.id,
            practiceNumber: item.practiceNumber,
            practiceStatus: item.practiceStatus ?? "",
            assignedAppraiser: item.assignedAppraiser ?? "",
            receiptDate: item.receiptDate ?? "",
            appraisalLocationAddress:
              handleFormatAddress(item.appraisalLocationAddress) ?? "",
            latitude: item.appraisalLocationAddress?.latitude,
            longitude: item.appraisalLocationAddress?.longitude,
          } as PracticeDataItem)
      ) || [],
    [practices]
  );

  const resultsColumns = useMemo<MRT_ColumnDef<PracticeDataItem>[]>(
    () => [
      {
        accessorKey: "practiceNumber",
        header: t("assignment-map-practices-list-col-practice-number"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            {row.original.practiceNumber}
          </CellWrapper>
        ),
      },
      {
        accessorKey: "practiceStatus",
        header: t("assignment-map-practices-list-col-practice-status"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            <div style={{ textAlign: "center" }}>
              {t(
                `assignment-map-practices-list-practice-status-description-${row.original.practiceStatus}`
              )}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "assignedAppraiser",
        header: t("assignment-map-practices-list-col-assigned-appraiser"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            <div style={{ textAlign: "center" }}>
              {row.original.assignedAppraiser}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "appraisalLocationAddress",
        header: t(
          "assignment-map-practices-list-col-appraisal-location-address"
        ),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            <div style={{ textAlign: "center" }}>
              {row.original.appraisalLocationAddress}
            </div>
          </CellWrapper>
        ),
      },
      {
        accessorKey: "receiptDate",
        header: t("assignment-map-practices-list-col-receipt-date"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapper onClick={() => handleSelectRow(row.original)}>
            <div style={{ textAlign: "center" }}>
              {row.original.receiptDate}
            </div>
          </CellWrapper>
        ),
      },
    ],
    [practices]
  );

  const handleSelectRow = (row: PracticeDataItem) => {
    onSelectPractice(row.id, row.latitude!, row.longitude!);
  };

  return (
    <PracticesListWrapper>
      {/* <pre>{JSON.stringify(rowSelection, null, 2)}</pre> */}
      <MaterialReactTable
        columns={resultsColumns}
        data={data}
        enableDensityToggle={false}
        enableColumnOrdering
        onPaginationChange={setPagination}
        state={{ pagination }}
        muiTableBodyCellProps={{
          sx: {
            textAlign: "left",
          },
        }}
      />
    </PracticesListWrapper>
  );
};

export default AssignmentMapPracticesList;
