import { useMemo, useState } from "react";
import { RowSpacer } from "../../style/containers";
import { InputTextStyled, SelectStyled } from "../../style/Input";
import styled from "styled-components";
import { FormRow } from "../../style/form";
import { useTranslation } from "react-i18next";
import { ButtonCancel, ButtonConfirm } from "../Layout/Buttons";
import { clickableSearch as testIds } from "../../config/testIds";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { SelectPair } from "../../types/common.types";
import { SearchClaimsParams } from "../../redux/apiSpecifications/apiClaims";
import { InputWithBadge } from "../Inputs/InputWithBadge";

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em 4em;
  position: relative;
  min-height: 22em;
`;

const SearchButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;

  svg {
    font-size: 1.4em;
    margin-right: 0.5em;
  }

  span {
    letter-spacing: 2px;
    margin-top: 0px;
    font-size: 1.2em;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 2em;
  }
`;

interface ISearchFormClaimsProps {
  onSearch: (params: SearchClaimsParams) => void;
  onCancel?: () => void;
}

const SearchFormClaims = (props: ISearchFormClaimsProps) => {
  const { t } = useTranslation();

  const { selectedTenants } = useSelector((state: RootState) => state.user);

  const [searchParams, setSearchParams] = useState({} as SearchClaimsParams);

  const insuranceCompanyOptions = useMemo(() => {
    const insuranceCompanyOptionsArray: SelectPair[] = [
      { label: "", value: "" },
    ];

    if (selectedTenants) {
      selectedTenants.forEach((tenant) => {
        insuranceCompanyOptionsArray.push({
          value: tenant.id?.toString(),
          label: tenant.companyName!,
        });
      });
    }

    return insuranceCompanyOptionsArray;
  }, [selectedTenants]);

  const handleChangeSearchParams = (val: any, field: string) => {
    setSearchParams((prev) => ({ ...prev, [field]: val }));
  };

  const handleChangeIncuranceCompany = (val: any) => {
    const companyList: number[] = [];

    if (val !== "") {
      companyList.push(Number(val));
    }

    setSearchParams((prev) => ({ ...prev, companyList: companyList }));
  };

  const handleOnSearch = () => {
    props.onSearch(searchParams);
  };

  return (
    <SearchWrapper data-testid={testIds.tabClaims.container}>
      <FormRow>
        <SelectStyled
          label={t("select-incurance-company-label")}
          tooltip={t("select-incurance-company-tooltip")}
          styleInput={{ flex: 1 }}
          onChange={(val) => handleChangeIncuranceCompany(val)}
          value={
            searchParams.companyList && searchParams.companyList?.length > 0
              ? searchParams.companyList[0].toString()
              : ""
          }
          options={insuranceCompanyOptions}
        />
        <RowSpacer />
        <InputTextStyled
          label={t("search-claims-nominative-label")}
          tooltip={t("search-claims-nominative-tooltip")}
          onChange={(name) => handleChangeSearchParams(name, "subjectName")}
          value={searchParams.subjectName}
        />
        <RowSpacer />
        <InputTextStyled
          label={t("search-claims-code-label")}
          tooltip={t("search-claims-code-tooltip")}
          onChange={(code) =>
            handleChangeSearchParams(code.toUpperCase(), "subjectTaxId")
          }
          value={searchParams.subjectTaxId}
        />
      </FormRow>
      <FormRow>
        {/* TARGA */}
        <InputWithBadge
          label={t("search-claims-plate-label")}
          labelAlign="left"
          updateFilterTag={(val) =>
            handleChangeSearchParams(val, "plateNumberList")
          }
        />
        <RowSpacer />
        {/* NUMERO SINISTRO */}
        <InputWithBadge
          label={t("search-claims-claim-label")}
          labelAlign="left"
          updateFilterTag={(val) =>
            handleChangeSearchParams(val, "claimNumberList")
          }
        />
        <RowSpacer />
        <InputTextStyled
          label={t("search-claims-policy-label")}
          tooltip={t("search-claims-policy-tooltip")}
          onChange={(name) => handleChangeSearchParams(name, "policyNumber")}
          value={searchParams.policyNumber}
          dataTestId={testIds.tabClaims.fields.policyNumber}
        />
      </FormRow>
      {/* <FormRow></FormRow> */}
      <SearchButtonWrapper>
        {props.onCancel && (
          <ButtonCancel onClick={props.onCancel}>
            {t("button-cancel")!}
          </ButtonCancel>
        )}

        <ButtonConfirm
          onClick={handleOnSearch}
          dataTestId={testIds.tabClaims.buttonSearch}
        >
          {t("button-search")!}
        </ButtonConfirm>
      </SearchButtonWrapper>
    </SearchWrapper>
  );
};

export default SearchFormClaims;
