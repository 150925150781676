import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RowSpacer } from "../../style/containers";
import { ModalButtonsCentered } from "../../style/form";
import { ButtonConfirm, ButtonCancel } from "./Buttons";
import { waitForNetworkDelay as testId } from "../../config/testIds";
import { ActionResults, ActionStatus, ActionTypes } from "../../types/common.types";
import { IconLoading } from "../../config/icons";

const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 3em 0;
`;

const IconContainer = styled.div`
	width: 5em;
	height: 5em;
`;

const InProgressContainer = styled.div`
	display: flex;
	align-items: center;
`;

const MessageContainer = styled.div`
	margin-left: 2em;
	color: #555;
	text-transform: uppercase;
	font-size: 1.2em;
	letter-spacing: 2px;
`;

const VerticalSpace = styled.div`
	height: 3em;
`;

interface WaitingForNetworkDelayProps {
	action: ActionTypes | undefined;
	status: ActionStatus | undefined;
	result: ActionResults | undefined;
	text?: string | undefined;
	errorDetails?: string | undefined;
	onGoBack: () => void;
	onDismiss: () => void;
}

const WaitingForNetworkDelay = (props: WaitingForNetworkDelayProps) => {
	const { t } = useTranslation();
	const { action, status, result, text } = props;

	const buildMessage = () => {
		if (text) return text;

		let txtAction = "";
		let txtStatus = "";
		if (action === "idle") {
			//
		} else if (status === "in-progress") {
			txtAction = t(`waiting-for-network-delay-action-${action}`);
			txtStatus = t(`waiting-for-network-delay-status-${status}`);
		} else if (status === "done") {
			txtAction = t(`waiting-for-network-delay-action-${action}`);
			txtStatus = t(`waiting-for-network-delay-result-${result}`);
		}
		return `${txtAction}: ${txtStatus}`;
	};

	const message = buildMessage();

	return (
		<InfoContainer data-testid={testId.container}>
			<InProgressContainer>
				{status === "in-progress" && <IconContainer>{IconLoading}</IconContainer>}
				<MessageContainer>{message}</MessageContainer>
			</InProgressContainer>
			{status === "done" && result === "error" && (
				<>
					<VerticalSpace />
					<ModalButtonsCentered>
						{action !== "load" && (
							<>
								<ButtonConfirm onClick={props.onGoBack} dataTestId={testId.buttonGoBack}>
									{t("button-back")!}
								</ButtonConfirm>
								<RowSpacer />
							</>
						)}
						<ButtonCancel onClick={props.onDismiss} dataTestId={testId.buttonDismiss}>
							{t("button-close")!}
						</ButtonCancel>
					</ModalButtonsCentered>
				</>
			)}
		</InfoContainer>
	);
};

export default WaitingForNetworkDelay;
