import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Users } from "../../../../redux/apiSpecifications/apiFesf";
import { UserItem } from "../UserItem";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { CellWrapper, ClickableCellWrapper } from "../style/CellWrapper";
import styled from "styled-components";
import { SwitchStyled } from "../../../../style/Input";
import { Popconfirm } from "antd";
import { ButtonConfirm } from "../../../Layout/Buttons";

const AssociatedUsersListWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
  margin-top: 1em;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0 5px #aaa;
  padding: 1em 2em;

  .MuiPaper-root {
    box-shadow: none;
    width: 95%;
  }

  .MuiInputBase-root {
    &:before {
      border-bottom: 1px solid white;
    }
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiInputBase-input {
    color: #555;
  }

  .MuiTableCell-root {
    padding: 0.5em 1em;
  }
`;

interface IAssociatedUsersListProps {
  users: Users;
  onEditUserContact: (userItem: UserItem) => void;
  onEditUserConfig: (userItem: UserItem) => void;
  onEditUserCollaboratorConfig: (userItem: UserItem) => void;
  onToggleUserActivation: (userItem: UserItem, activation: boolean) => void;
  onResetConfig: (userId: number) => void;
}

const AssociatedUsersList = (props: IAssociatedUsersListProps) => {
  const { t } = useTranslation();

  const {
    users,
    onEditUserContact,
    onEditUserConfig,
    onToggleUserActivation,
    onEditUserCollaboratorConfig,
    onResetConfig,
  } = props;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const [popConfirm, setPopConfirm] = useState(false);

  const data = useMemo(
    () =>
      users?.map((user) => {
        return {
          id: user.id,
          idSubject: user.idSubject,
          account: user.userName,
          loginCode: user.loginCode,
          isCollaborator: user.isCollaborator,
          appraisalStudioId: user.appraisalStudioId,
          appraisalStudio: user.appraisalStudio,
          email: user.email,
          contact: user.firstname + " " + user.surname,
          roles: user.roles,
          rolesText: user.roles
            ?.map((r) => {
              if (r.roleCode === "E3") {
                return t(`role-description-${r.roleCode!}-${user.isCollaborator ? "collaborator" : "studio"}`)
              }
              else return t(`role-description-${r.roleCode!}`)
            })
            .join(", "),
          tenants: user.tenants,
          tenantsText: user.tenants?.map((t) => t.companyName).join(", "),
          isActive: user.isActive,
          userConfigLists: {
            allRoles: user.roles,
            allTenants: user.tenants,
          },
        } as UserItem;
      }) || [],
    [users]
  );

  const columns = useMemo<MRT_ColumnDef<UserItem>[]>(
    () => [
      {
        accessorKey: "account",
        header: t("associated-users-list-account-column-header"),
        size: 100,
        Cell: ({ row }) => <CellWrapper>{row.original.account}</CellWrapper>,
      },
      {
        accessorKey: "contact",
        header: t("associated-users-list-contact-column-header"),
        size: 200,
        Cell: ({ row }) => (
          <ClickableCellWrapper
            onClick={() => handleSelectContanct(row.original)}
          >
            {row.original.contact}
          </ClickableCellWrapper>
        ),
      },
      {
        accessorKey: "loginCode",
        header: t("associated-users-list-login-code-column-header"),
        size: 200,
        Cell: ({ row }) => (
          <ClickableCellWrapper
            onClick={() => handleSelectContanct(row.original)}
          >
            {row.original.loginCode}
          </ClickableCellWrapper>
        ),
      },
      {
        accessorKey: "roles",
        header: t("associated-users-list-roles-column-header"),
        size: 400,
        Cell: ({ row }) => (
          <ClickableCellWrapper
            onClick={() => handleSelectConfig(row.original)}
          >
            {row.original.isCollaborator
              ? `${t(`associated-users-list-role-collaborator-description`)} (${row.original.appraisalStudio})`
              : row.original.rolesText}
          </ClickableCellWrapper>
        ),
      },
      {
        accessorKey: "tenants",
        header: t("associated-users-list-customer-management-column-header"),
        size: 400,
        Cell: ({ row }) => (
          <ClickableCellWrapper
            onClick={() => handleSelectConfig(row.original)}
          >
            {row.original.tenantsText}
          </ClickableCellWrapper>
        ),
      },
      {
        accessorKey: "isActive",
        header: t("associated-users-list-enable-account-column-header"),
        size: 100,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <CellWrapper>
            <div style={{ textAlign: "center" }}>
              <SwitchStyled
                onChange={(value) =>
                  onToggleUserActivation(row.original, value)
                }
                unCheckedChildren={t("switch-no")}
                checkedChildren={t("switch-yes")}
                checked={row.original.isActive}
              />
            </div>
          </CellWrapper>
        ),
      },
      {
        header: t(" "),
        size: 100,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <CellWrapper>
            <Popconfirm
              placement="left"
              icon={null}
              title={t("associated-users-list-button-confirm-reset-config")}
              onConfirm={(value) => handleOnPopConfirm(row.original.id!)}
              onCancel={() => setPopConfirm(false)}
              okText={t("switch-yes")!}
              cancelText={t("switch-no")!}
            >
              <ButtonConfirm onClick={() => { }}>{t("RESET")!}</ButtonConfirm>
            </Popconfirm>
          </CellWrapper>
        ),
      },
    ],
    [users]
  );

  const handleSelectContanct = (row: UserItem) => {
    onEditUserContact(row);
  };

  const handleOnPopConfirm = (userId: number) => {
    setPopConfirm(false);
    onResetConfig(userId);
  };

  const handleSelectConfig = (row: UserItem) => {
    if (row.isCollaborator !== true) {
      onEditUserConfig(row);
    } else onEditUserCollaboratorConfig(row);
  };



  return (
    <AssociatedUsersListWrapper>
      {data.length > 0 && (
        <MaterialReactTable
          muiTablePaperProps={{ style: { width: "100%" } }}
          columns={columns}
          data={data}
          enableDensityToggle={false}
          enableColumnOrdering
          muiTableBodyCellProps={{
            sx: {
              textAlign: "left",
            },
          }}
          onPaginationChange={setPagination}
          state={{ pagination }}
          autoResetPageIndex={false}
        />
      )}
    </AssociatedUsersListWrapper>
  );
};

export default AssociatedUsersList;
