import { getLoginRequest, getMsalConfig } from "../../../config/azureConfig";
import {
  BrowserAuthOptions,
  EndSessionPopupRequest,
  PublicClientApplication,
} from "@azure/msal-browser";
import { BaseQueryApi } from "@reduxjs/toolkit/query";
import { logout, setAuthorization } from "../../features/userSlice";
import { RootState } from "../../store";
import jwtDecode from "jwt-decode";
import moment from "moment";

export const checkToken = async (api: BaseQueryApi) => {
  const authorization = (api.getState() as RootState)?.user.authorization;

  const token = authorization.replace("Bearer ", "");

  let decoded: any = jwtDecode(token);

  const now = moment();

  // forzo il logout se il token è scaduto
  const mustLogout = now.isAfter(moment(decoded.exp * 1000)); //.add(30, "minutes"));

  // Refresh token se mancano meno di 60 minuti alla scadenza del token
  const mustRefreshToken = moment(decoded.exp * 1000)
    .add(-60, "minutes")
    .isBefore(now);

  // console.info("mustLogout: ", mustLogout)
  // console.info("moment(decoded.exp * 1000): ", moment(decoded.exp * 1000).toString())
  // console.info("mustRefreshToken: ", mustRefreshToken)
  // console.info("moment(decoded.exp * 1000).add(-60, minutes): ", moment(decoded.exp * 1000).add(-60, "minutes").toString())

  if (mustLogout) {
    console.info('log out')
    const auth = getMsalConfig().auth as BrowserAuthOptions;

    const msalInstance = new PublicClientApplication({
      auth: auth,
    });

    msalInstance.logoutRedirect({
      postLogoutRedirect: "/",
      mainWindowRedirectUri: "/",
    } as EndSessionPopupRequest);

    api.dispatch(logout());
  } else if (mustRefreshToken) {
    console.info('refresh token')

    const auth = getMsalConfig().auth as BrowserAuthOptions;

    const msalInstance = new PublicClientApplication({
      auth: auth,
    });

    const accounts = msalInstance.getAllAccounts();

    const response = await msalInstance.acquireTokenSilent({
      ...getLoginRequest(),
      forceRefresh: true,
      account: accounts[0],
    });

    const jwt = "Bearer " + response.accessToken;
    api.dispatch(setAuthorization(jwt));
  }
};
