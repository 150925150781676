import { useTranslation } from "react-i18next";
import {
  AppraiserManagementData,
  AppraisersListItem,
  AreaCoverage,
  Coverage,
  DistrictBaseCoverage,
  DistrictCoverage,
  Province,
  ProvincesAndZones,
  Zone,
  useLazyGetAllCoverageProvincesAndZonesQuery,
  useLazyGetAppraiserManagementQuery,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { ContentWrapper, GenericWrapper } from "../../Commons/Wrappers";
import { useEffect, useState } from "react";
import styled from "styled-components";
import AppraiserDetailsManagementMainData from "./AppraiserDetailsManagementMainData";
import { SelectPair } from "../../../../../types/common.types";
import moment from "moment";
import { mainTabs } from "../../../../../config/testIds";
import { DateFormat, maxDate } from "../../../../../config/const";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import AppraiserDetailsManagementCoverageData from "./AppraiserDetailsManagementCoverageData";
import {
  setAppraiserError,
  setAppraiserManagementData,
  setAppraiserStatus,
  setValidation,
  setZonesAndProvinces,
} from "../../../../../redux/features/appraisersSlice";

const testIds = mainTabs.tabs.supplierNetwork.appraiserDetails.management;

const AddressesContainer = styled(ContentWrapper)`
  overflow: auto;
  max-height: calc(100vh - 16em);
  padding-right: 2em;
`;

const AppraiserDetailsManagementWrapper = styled(GenericWrapper)`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 3em 5em;
  min-width: 960px;

  .button-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
  }
`;

type modalStatusesData = {
  showModal: boolean;
  appraiser?: AppraisersListItem | undefined;
  provinces?: DistrictBaseCoverage[] | undefined;
  defaultDistrictCode?: string | undefined;
};
export interface IDistrictZoneOptions {
  [key: string]: SelectPair[];
}

export type CoverageZoneSelect = {
  provinces: SelectPair[];
  districts: IDistrictZoneOptions[];
  zipCodes: IDistrictZoneOptions[];
};

interface IAppraiserDetailsManagementProps {
  idAppraiser: number | undefined;
  searchDate: string | undefined;
  onShowAgenda: (date: string, status: string, province: string) => void;
  disableMap?: boolean;
}

const AppraiserDetailsManagement = (
  props: IAppraiserDetailsManagementProps
) => {
  const { t } = useTranslation();
  const {
    idAppraiser,
    onShowAgenda: showAgenda,
    searchDate,
    disableMap,
  } = props;

  const { getAuthorization } = useAuthorization();
  const dispatch = useDispatch();

  const appraiserData = useSelector((state: RootState) =>
    state.appraisers.appraisers.find((a) => a.id === idAppraiser)
  );

  const { zones: rawZones, provinces: rawProvinces } = useSelector(
    (state: RootState) => state.appraisers
  );

  const { managementData: appraiserManagementData } = appraiserData || {};

  const [loadAppraiser] = useLazyGetAppraiserManagementQuery();
  const [loadZones] = useLazyGetAllCoverageProvincesAndZonesQuery();

  // all provinces
  const [selectsOptions, setSelectsOptions] = useState<CoverageZoneSelect>();

  // not already selected provinces
  const [availableCoverageZonesSelect, setAvailableCoverageZonesSelect] =
    useState<CoverageZoneSelect>();

  // initial load appraiser data
  useEffect(() => {
    if (!idAppraiser || idAppraiser < 1) return;

    dispatch(setAppraiserStatus({ id: idAppraiser, status: "loading" }));

    (async () => {
      //initial load zones and provinces
      if (!(rawZones.length > 0 && rawProvinces.length > 0)) {
        const responseZones = await loadZones({
          authorization: await getAuthorization(),
        });

        if (responseZones.isSuccess && responseZones.data) {
          const { zones, provinces } = responseZones.data as ProvincesAndZones;

          buildSelectOptions(zones || [], provinces || []);
          dispatch(setZonesAndProvinces({ zones, provinces }));
        } else {
          dispatch(
            setAppraiserError({
              id: idAppraiser || 0,
              message: "Errore caricamento zone e province",
            })
          );

          return;
        }
      }

      const responseAppraiser = await loadAppraiser({
        authorization: await getAuthorization(),
        id: idAppraiser,
      });

      if (responseAppraiser.isSuccess && responseAppraiser.data) {
        dispatch(
          setAppraiserManagementData({
            id: idAppraiser,
            data: responseAppraiser.data as AppraiserManagementData,
            isUpdate: false,
          })
        );

        // better build them here for async reasons
        if (rawZones.length > 0 && rawProvinces.length > 0)
          buildSelectOptions(rawZones, rawProvinces);
      } else {
        dispatch(
          setAppraiserError({
            id: idAppraiser || 0,
            message: "Errore caricamento dettagli perito",
          })
        );
      }
    })();
  }, [idAppraiser]);

  const validateData = (coverage: Coverage | undefined) => {
    let isValid = true;

    if (!coverage) {
      isValid = false;
    }

    // check provinces
    for (const districtCoverage of coverage?.districtCoverages || []) {
      if (
        (districtCoverage.districtCode?.length || 0) <= 0 ||
        !districtCoverage.districtMaxPracticeCountDaily ||
        !districtCoverage.districtMaxPracticeCountMonthly ||
        (districtCoverage.validityStartDate?.length || 0) <= 0 ||
        (districtCoverage.validityEndDate?.length || 0) <= 0
      ) {
        isValid = false;
      }
    }

    dispatch(
      setValidation({
        id: appraiserData?.id!,
        validationErrors: {
          management: isValid ? [] : ["invalid data"],
        },
      })
    );

    //setIsDataValid(true);
  };

  const buildSelectOptions = (zones: Zone[], provinces: Province[]) => {
    const provincesSelect: SelectPair[] = [];
    const districtsSelect: IDistrictZoneOptions[] = [];
    const zipCodesSelect: IDistrictZoneOptions[] = [];

    provinces.forEach((p) => {
      provincesSelect.push({
        value: p.districtCode,
        label: p.districtName,
      } as SelectPair);

      const uniqueLocationName = Array.from(
        new Set(
          zones
            ?.filter((z) => z.provinceCode === p.districtCode)
            .map((z) => z.locationName)
        )
      );

      const uniqueZonesByName = uniqueLocationName.map((l) => {
        const zone = zones?.find((z) => z.locationName === l);
        return {
          value: zone?.id,
          label: zone?.locationName,
        } as SelectPair;
      });

      districtsSelect.push({
        [p.districtCode!]: uniqueZonesByName,
      } as IDistrictZoneOptions);

      const uniqueZipCode = Array.from(
        new Set(
          zones
            ?.filter((z) => z.provinceCode === p.districtCode)
            .map((z) => z.zipCode)
        )
      );

      const uniqueZonesByZipcode = uniqueZipCode.map((uzc) => {
        const zone = zones?.find((z) => z.zipCode === uzc);
        return {
          value: zone?.id,
          label: zone?.zipCode,
        } as SelectPair;
      });

      zipCodesSelect.push({
        [p.districtCode!]: uniqueZonesByZipcode,
      } as IDistrictZoneOptions);
    });

    const sortedProvinces = provincesSelect.sort((a, b) =>
      a.label > b.label ? 1 : -1
    );

    setSelectsOptions({
      provinces: sortedProvinces,
      districts: districtsSelect,
      zipCodes: zipCodesSelect,
    });
  };

  useEffect(() => {
    if (selectsOptions && appraiserManagementData?.coverage) {
      const selectedProvinces = Array.from(
        new Set(
          appraiserManagementData?.coverage?.districtCoverages?.map(
            (dc) => dc.districtCode
          )
        )
      );

      const updatedDistrictCoverages: CoverageZoneSelect = {
        provinces:
          selectsOptions?.provinces?.filter(
            (p) => !selectedProvinces.includes(p.value)
          ) || [],
        districts: selectsOptions?.districts,
        zipCodes: selectsOptions?.zipCodes,
      };

      setAvailableCoverageZonesSelect(updatedDistrictCoverages);
    }
  }, [selectsOptions, appraiserManagementData?.coverage]);

  const handleOnAddNewDistrict = () => {
    const newDistrictCoverages: DistrictCoverage = {
      districtCode: "",
      districtName: "",
      districtLatitude: 0,
      districtLongitude: 0,
      validityStartDate: moment().format(DateFormat),
      validityEndDate: maxDate,
      districtMaxPracticeCountDaily: 0,
      districtMaxPracticeCountMonthly: 0,
      hasAreaCoverageParams: false,
      areaCoverages: [],
    };

    const updatedAppraiserManagementData: AppraiserManagementData = {
      ...appraiserManagementData,
      coverage: {
        coverageTypeCodes: appraiserManagementData?.coverage?.coverageTypeCodes,
        districtCoverages: [
          ...appraiserManagementData!.coverage!.districtCoverages!,
          newDistrictCoverages,
        ],
      },
    };
    dispatch(
      setAppraiserManagementData({
        id: idAppraiser!,
        data: updatedAppraiserManagementData,
        isUpdate: true,
      })
    );

    validateData(updatedAppraiserManagementData?.coverage);
    // setIsModified(true);
  };

  const handleOnDeleteDistrict = (index: number) => {
    const updatedAppraiserManagementData: AppraiserManagementData = {
      ...appraiserManagementData,
      coverage: {
        coverageTypeCodes: appraiserManagementData?.coverage?.coverageTypeCodes,
        districtCoverages:
          appraiserManagementData!.coverage!.districtCoverages!.filter(
            (_, i) => i !== index
          ),
      },
    };
    dispatch(
      setAppraiserManagementData({
        id: idAppraiser!,
        data: updatedAppraiserManagementData,
        isUpdate: true,
      })
    );

    validateData(updatedAppraiserManagementData?.coverage);
    //setIsModified(true);
  };

  const handleOnChange = (value: any, field: string) => {
    let updatedAppraiserManagementData: AppraiserManagementData | undefined;

    if (field === "coverageLeaveLossPerc") {
      updatedAppraiserManagementData = {
        ...appraiserManagementData,
        coverage: {
          ...appraiserManagementData?.coverage,
          coverageLeaveLossPerc: value,
        },
      };
      //setIsModified(true);
    } else if (field === "appraiserStatusCode") {
      // skip update id we want to change status (suspesion, block, closed)
      const status = value.value;
      const districtCode = value.districtCode;

      const updatedDistrictCoverages =
        appraiserManagementData?.coverage?.districtCoverages?.map(
          (district) => {
            if (district.districtCode !== districtCode) return district;

            const validityStartDate =
              status === "A"
                ? moment().format(DateFormat)
                : district.validityStartDate;
            const validityEndDate = maxDate;
            const isTest = status === "T" ? true : false;
            return {
              ...district,
              validityStartDate,
              validityEndDate,
              isTest,
            };
          }
        );

      const updatedCoverage = {
        ...appraiserManagementData?.coverage,
        districtCoverages: updatedDistrictCoverages,
      };

      updatedAppraiserManagementData = {
        ...appraiserManagementData,
        coverage: updatedCoverage,
      };
    } else if (field === "newAreaCoverage-city") {
      const { id, districtCode } = value;

      const newCities =
        id === "ADD-ALL"
          ? rawZones
              ?.filter((z) => z.provinceCode === districtCode)
              .map((z) => z.locationName)
          : [rawZones?.find((z) => z.id === id)?.locationName];

      const alreadySelectedAreas =
        appraiserManagementData?.coverage?.districtCoverages?.find(
          (d) => d.districtCode === districtCode
        )?.areaCoverages || [];

      const newAreas =
        rawZones
          ?.filter(
            (z) =>
              newCities?.includes(z.locationName) &&
              alreadySelectedAreas.findIndex(
                (asa) => asa.city === z.locationName
              ) === -1
          )
          .map(
            (z) =>
              ({
                id: z.id,
                city: z.locationName,
                zipCode: z.zipCode,
                areaMaxPracticeCountDaily: 0,
                areaMaxPracticeCountMonthly: 0,
              } as AreaCoverage)
          ) || [];

      const updatedAreaCoverages = [...alreadySelectedAreas, ...newAreas].sort(
        (a, b) => (a.city! > b.city! ? 1 : -1)
      );

      const selectedRawProvince = rawProvinces?.find(
        (p) => p.districtCode === value.districtCode
      );

      const selectedValuedProvince =
        appraiserManagementData?.coverage?.districtCoverages?.find(
          (d) => d.districtCode === value.districtCode
        );

      // update updatedAreaCoverages with new repartitions of maxPracticeCountDaily and maxPracticeCountMonthly
      const maxDaily =
        selectedValuedProvince?.districtMaxPracticeCountDaily || 0;
      const maxMontly =
        selectedValuedProvince?.districtMaxPracticeCountMonthly || 0;
      const maxDailyPerArea = Math.floor(
        maxDaily / updatedAreaCoverages.length
      );
      const maxMonthlyPerArea = Math.floor(
        maxMontly / updatedAreaCoverages.length
      );
      const firstMaxDailyPerArea =
        maxDailyPerArea + (maxDaily % updatedAreaCoverages.length);
      const firstMaxMonthlyPerArea =
        maxMonthlyPerArea + (maxMontly % updatedAreaCoverages.length);

      const updatedAreaCoveragesWithMaxPracticeCount = updatedAreaCoverages.map(
        (area, index) => {
          if (index === 0) {
            return {
              ...area,
              areaMaxPracticeCountDaily: firstMaxDailyPerArea,
              areaMaxPracticeCountMonthly: firstMaxMonthlyPerArea,
            };
          } else {
            return {
              ...area,
              areaMaxPracticeCountDaily: maxDailyPerArea,
              areaMaxPracticeCountMonthly: maxMonthlyPerArea,
            };
          }
        }
      );

      const updatedDistrictCoverages: DistrictCoverage = {
        ...selectedRawProvince,
        districtMaxPracticeCountDaily:
          selectedValuedProvince?.districtMaxPracticeCountDaily,
        districtMaxPracticeCountMonthly:
          selectedValuedProvince?.districtMaxPracticeCountMonthly,
        isTest: selectedValuedProvince?.isTest,
        validityStartDate: selectedValuedProvince?.validityStartDate,
        validityEndDate: selectedValuedProvince?.validityEndDate,
        hasAreaCoverageParams: true, // if we can choose a district then the flag must be selected
        areaCoverages: updatedAreaCoveragesWithMaxPracticeCount,
      };

      updatedAppraiserManagementData = {
        ...appraiserManagementData,
        coverage: {
          coverageTypeCodes:
            appraiserManagementData?.coverage?.coverageTypeCodes,
          districtCoverages:
            appraiserManagementData?.coverage?.districtCoverages?.map((c, i) =>
              c.districtCode !== value.districtCode
                ? c
                : updatedDistrictCoverages
            ),
        },
      };
    } else if (field === "newAreaCoverage-cap") {
      const { id, districtCode } = value;

      let newAreas = [] as AreaCoverage[];

      const alreadySelectedAreas =
        appraiserManagementData?.coverage?.districtCoverages?.find(
          (d) => d.districtCode === districtCode
        )?.areaCoverages || [];

      if (id === "ADD-ALL") {
        const zipCode = rawZones?.find((z) => z.id === id)?.zipCode;

        newAreas =
          rawZones
            ?.filter((z) => z.provinceCode === districtCode)
            .map(
              (city) =>
                ({
                  id: city.id,
                  city: city.locationName,
                  zipCode: city.zipCode,
                  areaMaxPracticeCountDaily: 0,
                  areaMaxPracticeCountMonthly: 0,
                } as AreaCoverage)
            ) || [];
      } else {
        const zipCode = rawZones?.find((z) => z.id === id)?.zipCode;
        const cities = rawZones?.filter((z) => z.zipCode === zipCode);

        newAreas =
          cities
            ?.filter(
              (city) =>
                alreadySelectedAreas.findIndex(
                  (asa) => asa.zipCode === city.zipCode
                ) === -1
            )
            .map(
              (city) =>
                ({
                  id: city.id,
                  city: city.locationName,
                  zipCode: city.zipCode,
                  areaMaxPracticeCountDaily: 0,
                  areaMaxPracticeCountMonthly: 0,
                } as AreaCoverage)
            ) || [];
      }

      const updatedAreaCoverages = [...alreadySelectedAreas, ...newAreas].sort(
        (a, b) => (a.city! > b.city! ? 1 : -1)
      );

      const selectedRawProvince = rawProvinces?.find(
        (p) => p.districtCode === value.districtCode
      );

      const selectedValuedProvince =
        appraiserManagementData?.coverage?.districtCoverages?.find(
          (d) => d.districtCode === value.districtCode
        );

      // update updatedAreaCoverages with new repartitions of maxPracticeCountDaily and maxPracticeCountMonthly
      const maxDaily =
        selectedValuedProvince?.districtMaxPracticeCountDaily || 0;
      const maxMontly =
        selectedValuedProvince?.districtMaxPracticeCountMonthly || 0;
      const maxDailyPerArea = Math.floor(
        maxDaily / updatedAreaCoverages.length
      );
      const maxMonthlyPerArea = Math.floor(
        maxMontly / updatedAreaCoverages.length
      );
      const firstMaxDailyPerArea =
        maxDailyPerArea + (maxDaily % updatedAreaCoverages.length);
      const firstMaxMonthlyPerArea =
        maxMonthlyPerArea + (maxMontly % updatedAreaCoverages.length);

      const updatedAreaCoveragesWithMaxPracticeCount = updatedAreaCoverages.map(
        (area, index) => {
          if (index === 0) {
            return {
              ...area,
              areaMaxPracticeCountDaily: firstMaxDailyPerArea,
              areaMaxPracticeCountMonthly: firstMaxMonthlyPerArea,
            };
          } else {
            return {
              ...area,
              areaMaxPracticeCountDaily: maxDailyPerArea,
              areaMaxPracticeCountMonthly: maxMonthlyPerArea,
            };
          }
        }
      );

      const updatedDistrictCoverages: DistrictCoverage = {
        ...selectedRawProvince,
        districtMaxPracticeCountDaily:
          selectedValuedProvince?.districtMaxPracticeCountDaily,
        districtMaxPracticeCountMonthly:
          selectedValuedProvince?.districtMaxPracticeCountMonthly,
        isTest: selectedValuedProvince?.isTest,
        validityStartDate: selectedValuedProvince?.validityStartDate,
        validityEndDate: selectedValuedProvince?.validityEndDate,

        hasAreaCoverageParams: true, // if we can choose a district then the flag must be selected
        areaCoverages: updatedAreaCoveragesWithMaxPracticeCount,
      };

      updatedAppraiserManagementData = {
        ...appraiserManagementData,
        coverage: {
          coverageTypeCodes:
            appraiserManagementData?.coverage?.coverageTypeCodes,
          districtCoverages:
            appraiserManagementData?.coverage?.districtCoverages?.map((c, i) =>
              c.districtCode !== value.districtCode
                ? c
                : updatedDistrictCoverages
            ),
        },
      };
    } else if (field === "district") {
      const selectedProvince = rawProvinces?.find(
        (p) => p.districtCode === value.value.districtCode
      );

      const hasAreaCoverageParams =
        appraiserManagementData!.coverage!.districtCoverages![value.value.index]
          .hasAreaCoverageParams;

      const areaCoverages = hasAreaCoverageParams
        ? rawZones
            ?.filter(
              (z) =>
                z.locationName?.toLowerCase() ===
                selectedProvince?.districtName?.toLowerCase()
            )
            .map((z) => ({
              id: z.id,
              city: z.locationName,
              zipCode: z.zipCode,
              areaMaxPracticeCountDaily: 0,
              areaMaxPracticeCountMonthly: 0,
            }))
        : [];

      const updatedDistrictCoverages: DistrictCoverage = {
        districtCode: selectedProvince?.districtCode,
        districtName: selectedProvince?.districtName,
        districtLatitude: selectedProvince?.districtLatitude,
        districtLongitude: selectedProvince?.districtLongitude,
        validityStartDate: moment().format(DateFormat),
        validityEndDate: maxDate,
        isTest: false,
        districtMaxPracticeCountDaily: 0,
        districtMaxPracticeCountMonthly: 0,
        hasAreaCoverageParams,
        areaCoverages,
      };
      updatedAppraiserManagementData = {
        ...appraiserManagementData,
        coverage: {
          coverageTypeCodes:
            appraiserManagementData?.coverage?.coverageTypeCodes,
          districtCoverages:
            appraiserManagementData?.coverage?.districtCoverages?.map((c, i) =>
              i !== value.value.index ? c : updatedDistrictCoverages
            ),
        },
      };
    } else if (field === "setDefaultAreas") {
      const districtCoverages =
        appraiserManagementData?.coverage?.districtCoverages?.map((dc) => {
          if (dc.districtCode !== value) return dc;

          const province = rawProvinces?.find((p) => p.districtCode === value);
          const areaCoverages = rawZones
            ?.filter(
              (z) =>
                z.locationName?.toLowerCase() ===
                province?.districtName?.toLowerCase()
            )
            .map(
              (z) =>
                ({
                  id: z.id,
                  city: z.locationName,
                  zipCode: z.zipCode,
                  areaMaxPracticeCountDaily: 0,
                  areaMaxPracticeCountMonthly: 0,
                } as AreaCoverage)
            );

          return {
            ...dc,
            hasAreaCoverageParams: true,
            areaCoverages,
          };
        }) || [];

      const coverage: Coverage = {
        coverageTypeCodes: appraiserManagementData?.coverage?.coverageTypeCodes,
        districtCoverages,
      };

      updatedAppraiserManagementData = {
        ...appraiserManagementData,
        coverage,
      };
    } else {
      updatedAppraiserManagementData = {
        ...appraiserManagementData,
        [field]: value,
      };
    }

    dispatch(
      setAppraiserManagementData({
        id: idAppraiser!,
        data: updatedAppraiserManagementData,
        isUpdate: true,
      })
    );

    validateData(updatedAppraiserManagementData?.coverage);

    if (field === "appraiserStatusCode" && value.value === "change") {
      showAgenda(searchDate!, value.value, value.districtCode);
    }
  };

  return (
    <AddressesContainer>
      <AppraiserDetailsManagementWrapper data-testid={testIds.container}>
        <AppraiserDetailsManagementMainData
          idAppraiser={idAppraiser}
          appraiserData={appraiserManagementData}
          onChange={handleOnChange}
        />

        <AppraiserDetailsManagementCoverageData
          coverage={appraiserManagementData?.coverage}
          zones={availableCoverageZonesSelect}
          maxDistrict={selectsOptions?.provinces.length}
          onChange={handleOnChange}
          onAddNew={handleOnAddNewDistrict}
          onDeleteDistrinct={handleOnDeleteDistrict}
        />
      </AppraiserDetailsManagementWrapper>
    </AddressesContainer>
  );
};

export default AppraiserDetailsManagement;
