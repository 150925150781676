import { useCallback, useMemo, useState } from "react";
import {
  Address,
  AppraisalSubject,
} from "../../../../../../redux/apiSpecifications/apiCrud";
import ClickableApprailsaSubject from "./ClickableAppraisalSubjects";
import styled from "styled-components";
import { SwitchStyled } from "../../../../../../style/Input";
import { t } from "i18next";
import ApprailsalSubjectsHistory from "./AppraisalSubjectsHistory";
import { mainTabs } from "../../../../../../config/testIds";
import AppraisalSubjectDetails from "./AppraisalSubjectDetails";
import { on } from "events";
import { Alert } from "antd";

const testIds = mainTabs.tabs.appraisalDetails.collapsables.details.location;

const ApprailsaLocationAndSubjectsWrapper = styled.div`
  width: 100%;
`;

const SubjectsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  

  .right-subject {
    padding-left: 2em;
  }
`;

const HistoryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3em;
  width: 100%;
`;

export type HistoryItem = AppraisalSubject & {
  isActive?: boolean;
  modificationDateTime?: string;
  outOfCoverageNote?: string;
  isOutCoverage?: boolean;
};

interface IApprailsaLocationAndSubjectsProps {
  owner: AppraisalSubject | undefined;
  communicationRecipient: AppraisalSubject | undefined;
  appraisalLocation: AppraisalSubject | undefined;
  history: HistoryItem[];
  onChange: (value: AppraisalSubject, field: string, isValid: boolean) => void;
  roleActive?: string;
  practiceBlocked?: boolean;
  isInvoiced?: boolean;
}

const ApprailsaLocationAndSubjects = (
  props: IApprailsaLocationAndSubjectsProps
) => {
  const {
    owner,
    communicationRecipient,
    appraisalLocation,
    history,
    roleActive,
    practiceBlocked,
    isInvoiced,
    onChange,
  } = props;

  const handleIsSameAsOwner = (subjectType: string) => {
    onChange(props.owner!, subjectType, true);
  };

  const compareAddresses = useCallback(
    (address1: Address | undefined, address2: Address | undefined) => {
      return (
        address1?.street === address2?.street &&
        address1?.civic === address2?.civic &&
        address1?.city === address2?.city &&
        address1?.postalCode === address2?.postalCode &&
        address1?.province === address2?.province &&
        address1?.country === address2?.country
      );
    },
    []
  );

  const communicationRecipientIsSameAsOwner = (() => {
    if (owner?.subjectType === "PF") {
      return (
        owner.subjectType === communicationRecipient?.subjectType &&
        owner.name === communicationRecipient?.name &&
        owner.lastname === communicationRecipient?.lastname &&
        owner.email === communicationRecipient?.email &&
        owner.phone === communicationRecipient?.phone &&
        owner.pec === communicationRecipient?.pec &&
        compareAddresses(owner.address, communicationRecipient?.address)
      );
    } else if (owner?.subjectType === "PG") {
      return (
        owner.subjectType === communicationRecipient?.subjectType &&
        owner.businessName === communicationRecipient?.businessName &&
        owner.pIva === communicationRecipient?.pIva &&
        owner.email === communicationRecipient?.email &&
        owner.phone === communicationRecipient?.phone &&
        owner.pec === communicationRecipient?.pec &&
        compareAddresses(owner.address, communicationRecipient?.address)
      );
    }
  })();

  const appraisalLocationIsSameAsOwner = (() => {
    if (owner?.subjectType === "PF") {
      return (
        owner.subjectType === appraisalLocation?.subjectType &&
        owner.name === appraisalLocation?.name &&
        owner.lastname === appraisalLocation?.lastname &&
        owner.email === appraisalLocation?.email &&
        owner.phone === appraisalLocation?.phone &&
        owner.pec === appraisalLocation?.pec &&
        compareAddresses(owner.address, appraisalLocation?.address)
      );
    } else if (owner?.subjectType === "PG") {
      return (
        owner.subjectType === appraisalLocation?.subjectType &&
        owner.businessName === appraisalLocation?.businessName &&
        owner.pIva === appraisalLocation?.pIva &&
        owner.email === appraisalLocation?.email &&
        owner.phone === appraisalLocation?.phone &&
        owner.pec === appraisalLocation?.pec &&
        compareAddresses(owner.address, appraisalLocation?.address)
      );
    }
    return true;
  })();

  const outOfCoverageNote = useMemo(() => {
    if (history?.length > 0) {
      if (history[history.length - 1].outOfCoverageNote) {
        return history[history.length - 1].outOfCoverageNote;
      }
    }

    return undefined;
  }, [history]);

  return (
    <ApprailsaLocationAndSubjectsWrapper data-testid={testIds.container}>
      <SubjectsWrapper>
        <AppraisalSubjectDetails
          subject={owner}
          onChange={(s, iv) => onChange(s, "owner", iv)}
          type={"owner"}
          practiceBlocked={practiceBlocked}
          readonly={roleActive === "E3" || isInvoiced}
          roleActive={roleActive!}
          isPracticeInvoced={isInvoiced}
          
        />

        <AppraisalSubjectDetails
          subject={communicationRecipient}
          onChange={(s, iv) => onChange(s, "communicationRecipient", iv)}
          type={"recipient"}
          isSameAsOwner={communicationRecipientIsSameAsOwner}
          onSetSameAsOwner={() => handleIsSameAsOwner("communicationRecipient")}
          practiceBlocked={practiceBlocked}
          readonly={roleActive === "E3" || isInvoiced}
          roleActive={roleActive!}
          isPracticeInvoced={isInvoiced}

        />

        <AppraisalSubjectDetails
          subject={appraisalLocation}
          onChange={(s, iv) => onChange(s, "appraisalLocation", iv)}
          type={"contact"}
          isSameAsOwner={appraisalLocationIsSameAsOwner}
          onSetSameAsOwner={() => handleIsSameAsOwner("appraisalLocation")}
          practiceBlocked={practiceBlocked}
          outOfCoverageNote={outOfCoverageNote}
          roleActive={roleActive!}
          isIncorrectAddress={appraisalLocation?.address?.isIncorrect}
          isPracticeInvoced={isInvoiced}
        />
      </SubjectsWrapper>

      {/* {roleActive !== "E3" && (
        <HistoryWrapper>
          <ApprailsalSubjectsHistory
            history={history}
            practiceBlocked={practiceBlocked}
          />
        </HistoryWrapper>
      )} */}
    </ApprailsaLocationAndSubjectsWrapper>
  );
};

export default ApprailsaLocationAndSubjects;
