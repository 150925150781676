import { useTranslation } from "react-i18next";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";
import { useMemo } from "react";

type AdzUploadedDescriptionDetails = {
    client_name: string;
    finalize_version: string;
};

interface IAdzUploadedDescriptionProps {
    modifyDetailsJson: Modifydetail[];
}

const AdzUploadedDescription = ({
    modifyDetailsJson,
}: IAdzUploadedDescriptionProps) => {
    const { t } = useTranslation();

    const adzUploadedDescriptionDetails: AdzUploadedDescriptionDetails =
        useMemo(() => {
            return {
                client_name:
                    modifyDetailsJson.find((_) => _.key === "client_name")
                        ?.value ?? "",
                finalize_version:
                    modifyDetailsJson.find((_) => _.key === "finalize_version")
                        ?.value ?? "",
            } as AdzUploadedDescriptionDetails;
        }, [modifyDetailsJson]);

    return (
        <>
            {t(
                `historical-actions-adz-uploaded-${adzUploadedDescriptionDetails.finalize_version}-description`,
                {
                    client_name: adzUploadedDescriptionDetails.client_name,
                }
            )}
        </>
    );
};

export default AdzUploadedDescription;
