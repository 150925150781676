import { useTranslation } from "react-i18next";
import { SelectStyled } from "../../style/Input";

export type AgreementType = "S" | "SN" | "N" | undefined;

interface ISelectAgreementProps {
  classNameItem?: string;
  styleItem?: string;
  onChange: (val: AgreementType) => void;
  value: AgreementType;
}

const SelectAgreement = (props: ISelectAgreementProps) => {
  const { t } = useTranslation();

  return (
    <SelectStyled
      classNameItem={props.classNameItem}
      styleItem={props.styleItem}
      label={t("select-agreement-label")}
      tooltip={t("select-agreement-tooltip")}
      onChange={props.onChange}
      value={props.value}
      options={[
        {
          value: "",
          label: t("select-agreement-"),
        },
        {
          value: "S",
          label: t("select-agreement-S"),
        },
        {
          value: "SN",
          label: t("select-agreement-SN"),
        },
        {
          value: "N",
          label: t("select-gender-N"),
        },
      ]}
    />
  );
};

export default SelectAgreement;
