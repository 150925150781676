import { useState } from "react";
import { updateObject } from "../../../../../utils/updateObject";
import { CreateReportDataStep2 } from "./CreateReportData";
import { CreateReportFormModel } from "./CreateReportFormModel";

const useCreateReportForm = (createReportDataStep2: CreateReportDataStep2) => {
    const checkIfCanSubmit = (data: CreateReportFormModel) => {
        return false;
    };

    const initState = (createReportDataStep2: CreateReportDataStep2) => {
        const today = new Date();

        let data: CreateReportFormModel = {
            idTenant: createReportDataStep2.idTenant!,
            startDate: createReportDataStep2.startDate!,
            endDate: createReportDataStep2.endDate!,
            fileNamePrefix: createReportDataStep2.tenantName!.replaceAll(
                " ",
                "_"
            ),
            fileNameYear: today.getFullYear().toString(),
            fileNameMonth: (today.getMonth() + 1).toString(),
            excludedPractices: [],
            isSimulated: true,
            totalPractices: createReportDataStep2.totalPractices,
            totalDelta: createReportDataStep2.totalDelta,
            invoicedPractices: [...createReportDataStep2.invoicedPractices!],
        };

        return {
            data,
            canSubmit: checkIfCanSubmit(data),
        };
    };

    const [state, setState] = useState(initState(createReportDataStep2));

    const handleDataChange = (value: any, field: string) => {
        const { data } = state;

        const updatedData = updateObject(data, value, field);

        setState({
            ...state,
            data: { ...updatedData },
            canSubmit: checkIfCanSubmit(updatedData),
        });
    };

    return {
        data: state.data,
        canSubmit: state.canSubmit,
        handleDataChange,
    };
};

export default useCreateReportForm;
