import { Modal } from "antd";
import styled from "styled-components";
import { CreateReportDataStep2 } from "./CreateReportData";
import { ButtonsWrapper } from "../../../../MenuContent/UserManagement/style/ButtonsWrapper";
import { ButtonCancel, ButtonConfirm } from "../../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import useCreateReportForm from "./useCreateReportForm";
import CreateReportForm from "./CreateReportForm";
import { ReportMetadata } from "../../../../../redux/apiSpecifications/apiFesf";
import { IconLoading } from "../../../../../config/icons";
import { ModalTitleWrapper } from "../../../../../style/containers";

const ModalContent = styled.div`
    padding: 2em;

    .ant-form-item-explain {
        color: red !important;
    }

    .loader-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2em 2em 0 2em;
        z-index: 101;

        .loader {
            width: 6em;
            height: 6em;
        }
    }
`;

const CreateReportModalTitleWrapper = styled(ModalTitleWrapper)`
    padding: 0 0 1em 0;
    // svg {
    //     margin-left: 0;
    // }
    // span {
    //     margin-left: 1em;
    //     color: #333;
    //     letter-spacing: 1px;
    // }
`;

interface ICreateReportModalProps {
    createReportDataStep2: CreateReportDataStep2;
    onCancel: () => void;
    onConfirm: (createReportRequest: ReportMetadata) => void;
}

const CreateReportModal = ({
    createReportDataStep2,
    onCancel,
    onConfirm,
}: ICreateReportModalProps) => {
    const { t } = useTranslation();

    const { data, canSubmit, handleDataChange } = useCreateReportForm(
        createReportDataStep2
    );

    const handleOnSave = () => {
        const createReportRequest = {
            idTenant: createReportDataStep2.idTenant,
            name: `${data.fileNamePrefix}_${data.fileNameMonth}_${data.fileNameYear}`,
            startDate: createReportDataStep2.startDate,
            endDate: createReportDataStep2.endDate,
            isSimulated: data.isSimulated,
            exclusions: data.excludedPractices.map((_) => _.id),
        } as ReportMetadata;

        // add prefix SIM if report is simulated
        if (data.isSimulated ?? false) {
            createReportRequest.name = `SIM_${createReportRequest.name}`;
        }

        onConfirm(createReportRequest);
    };

    return (
        <Modal
            open={true}
            footer={null}
            closable={false}
            onCancel={onCancel}
            width="1200px"
        >
            <ModalContent>
                <>
                    <CreateReportModalTitleWrapper>{createReportDataStep2.tenantName} {createReportDataStep2.startDate} - {createReportDataStep2.endDate}</CreateReportModalTitleWrapper>

                    <CreateReportForm
                        model={data}
                        onChange={handleDataChange}
                    />

                    <ButtonsWrapper>
                        <ButtonCancel onClick={onCancel}>
                            {t("button-cancel")!}
                        </ButtonCancel>

                        <ButtonConfirm
                            onClick={handleOnSave}
                            // disabled={!canSubmit}
                        >
                            {t("button-confirm")!}
                        </ButtonConfirm>
                    </ButtonsWrapper>
                </>

                {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
            </ModalContent>
        </Modal>
    );
};

export default CreateReportModal;
