import { baseSplitApiSubjects as api } from "./config/baseApiSubjects";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    retriveSubjectData: build.query<
      RetriveSubjectDataApiResponse,
      RetriveSubjectDataApiArg
    >({
      query: (queryArg) => ({
        url: `/subjects/${queryArg.id}`,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    updateExistingSubject: build.mutation<
      UpdateExistingSubjectApiResponse,
      UpdateExistingSubjectApiArg
    >({
      query: (queryArg) => ({
        url: `/subjects/${queryArg.id}`,
        method: "PUT",
        body: queryArg.subject,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    deleteSubject: build.mutation<
      DeleteSubjectApiResponse,
      DeleteSubjectApiArg
    >({
      query: (queryArg) => ({
        url: `/subjects/${queryArg.id}`,
        method: "DELETE",
        headers: { Authorization: queryArg.authorization },
      }),
    }),
    insertNewSubject: build.mutation<
      InsertNewSubjectApiResponse,
      InsertNewSubjectApiArg
    >({
      query: (queryArg) => ({
        url: `/subjects`,
        method: "POST",
        body: queryArg.subject,
        headers: { Authorization: queryArg.authorization },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiSubjects };
export type RetriveSubjectDataApiResponse =
  /** status 200 A JSON representing the subject data */
    | Subject
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type RetriveSubjectDataApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type UpdateExistingSubjectApiResponse =
  /** status 200 A JSON representing the subject data */
    | Subject
    | /** status 204 The request was correct but the item does not exists */ {
        message?: string;
      };
export type UpdateExistingSubjectApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
  /** Update a subject data */
  subject: Subject;
};
export type DeleteSubjectApiResponse = /** status 200 The item is deleted */ {
  id?: number;
  message?: string;
};
export type DeleteSubjectApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** Numeric Id of the resource */
  id: number;
};
export type InsertNewSubjectApiResponse =
  /** status 200 A JSON representing the subject data */ Subject;
export type InsertNewSubjectApiArg = {
  /** The JSON Web Token  that confirms the successfull authentication of the user on the frontend */
  authorization: string;
  /** New subject data */
  subject: Subject;
};
export type BirthDateAndPlace = {
  date?: string;
  city?: string;
  province?: string;
  country?: string;
};
export type Address = {
  id?: number;
  type?: string;
  street?: string;
  civic?: string;
  postalCode?: string;
  city?: string;
  province?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  preferred?: boolean;
  isDeleted?: boolean;
  isIncorrect?: boolean;
};
export type Contact = {
  id?: number;
  type?: string;
  value?: string;
  description?: string;
  useCase?: string;
  preferred?: boolean;
  isDeleted?: boolean;
};
export type SubjectDocument = {
  id?: number;
  documentCode?: string;
  type?: string;
  number?: string;
  issuingDate?: string;
  expirationDate?: string;
  issuingInstitution?: string;
};
export type SubjectPayment = {
  id?: number;
  type?: string;
  iban?: string | null;
  bicSwift?: string | null;
  addressSendCheck?: Address | null;
  preferred?: boolean;
};
export type Subject = {
  id?: number;
  subjectType?: string;
  name?: string;
  lastname?: string;
  gender?: string;
  fiscalCode?: string;
  birth?: BirthDateAndPlace | null;
  businessName?: string;
  giuridicalType?: string;
  registeredOffice?: Address | null;
  pIva?: string;
  contacts?: Contact[] | null;
  addresses?: Address[] | null;
  documents?: SubjectDocument[] | null;
  payments?: SubjectPayment[] | null;
};
export type Schema = {
  errorCode?: string;
  errorMessage?: string;
};
export const {
  useRetriveSubjectDataQuery,
  useLazyRetriveSubjectDataQuery,
  useUpdateExistingSubjectMutation,
  useDeleteSubjectMutation,
  useInsertNewSubjectMutation,
} = injectedRtkApi;
