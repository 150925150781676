import { useTranslation } from "react-i18next";
import { RowSpacer } from "../../../../../../../style/containers";
import { FormRow } from "../../../../../../../style/form";
import {
  InputTextStyled,
  SelectStyled,
} from "../../../../../../../style/Input";
import { Guarantee } from "../../../../../../../redux/apiSpecifications/apiPolicies";
import { SelectPair } from "../../../../../../../types/common.types";

interface IGuaranteeDataFormProps {
  model: Guarantee;
  disableForm?: boolean;
  branchesOptions: SelectPair[];
  onChange?: (value: any, field: string) => void;
}

const GuaranteeDataForm = ({
  model,
  disableForm,
  branchesOptions,
  onChange,
}: IGuaranteeDataFormProps) => {
  const { t } = useTranslation();

  const handleOnChange = (value: any, field: string) => {
    if (onChange) onChange(value, field);
  };

  return (
    <FormRow>
      {model.id ? (
        <InputTextStyled
          label={t("LABELS.branch")}
          placeholder={t("PLACEHOLDERS.branch")}
          required={true}
          value={t(`policy-branch-${model.branch}`)}
          onChange={(value) => handleOnChange(value, "branch")}
          disabled={disableForm}
        />
      ) : (
        <SelectStyled
          label={t("LABELS.branch")}
          required={true}
          value={model.branch}
          options={branchesOptions}
          onChange={(value) => handleOnChange(value, "branch")}
        />
      )}

      <RowSpacer />

      <InputTextStyled
        label={t("LABELS.excess-amount")}
        placeholder={t("PLACEHOLDERS.excess-amount")}
        required={true}
        value={model.excessAmount}
        onChange={(value) => handleOnChange(value, "excessAmount")}
        disabled={disableForm}
      />

      <RowSpacer />

      <InputTextStyled
        label={t("LABELS.overdraft-amount")}
        placeholder={t("PLACEHOLDERS.overdraft-amount")}
        required={true}
        value={model.overdraftAmount}
        onChange={(value) => handleOnChange(value, "overdraftAmount")}
        disabled={disableForm}
      />
    </FormRow>
  );
};

export default GuaranteeDataForm;
