import {
	AdditionaInfoTypes,
	AdditionalInfoDocumentType,
	AdditionalInfoDocumentTypes,
	AdditionalInfoPaymentTypes,
	AdditionalInfoSubjectRoleTypes,
	AdditionalInfoSubjectType,
	DamagedPartType,
} from "../types/claim.types";
import { SelectPair } from "../types/common.types";
import { SubjectData, SubjectGiuridicalPersonData, SubjectNaturalPersonData } from "../types/uses-data.types";

export const insuranceCodes: SelectPair[] = [
	{ label: "ACCELERANT INSURANCE EUROPE SA N.V. *", value: "673" },
	{ label: "ACCREDITED INSURANCE (EUROPE) LIMITED *", value: "672" },
	{ label: "ADMIRAL EUROPE COMPAÑÍA DE SEGUROS SA", value: "911" },
	{ label: "ADRIATIC OSIGURANJE D.D.", value: "667" },
	{ label: "AFI ESCA IARD *", value: "609" },
	{ label: "AFI ESCA SA *", value: "612" },
	{ label: "AIG EUROPE SA", value: "344" },
	{ label: "AIOI NISSAY DOWA INS. CO. OF EUROPE SE", value: "872" },
	{ label: "ALLEANZA ASSICURAZIONI S.p.A.", value: "484" },
	{ label: "ALLIANZ DIRECT Società per Azioni", value: "139" },
	{ label: "ALLIANZ GLOBAL CORPORATE & SPECIALTY *", value: "880" },
	{ label: "ALLIANZ GLOBAL LIFE DAC *", value: "884" },
	{ label: "ALLIANZ LEBENSVERSICHERUNGS AG *", value: "660" },
	{ label: "ALLIANZ Società per Azioni", value: "3" },
	{ label: "ALLIANZ VIVA S.p.A.", value: "352" },
	{ label: "AMTRUST ASSICURAZIONI S.p.A.", value: "471" },
	{ label: "AMTRUST INTERNATIONAL UNDERWRITERS DAC *", value: "610" },
	{ label: "ARAG SE Rappresentanza Generale e Direzione per l'Italia", value: "616" },
	{ label: "ARCA ASSICURAZIONI S.p.A. *", value: "417" },
	{ label: "ARCA VITA S.p.A. *", value: "341" },
	{ label: "ARCH INSURANCE (EU) DAC *", value: "666" },
	{ label: "ARGOGLOBAL ASSICURAZIONI S.p.A.", value: "469" },
	{ label: "ASISA ASISTENCIA SANITARIA INTERPROVINCIAL DE SEGUROS SA *", value: "644" },
	{ label: "ASSICURATRICE MILANESE S.p.A.", value: "327" },
	{ label: "ASSICURATRICE VAL PIAVE S.p.A.", value: "85" },
	{ label: "ASSICURAZIONI GENERALI S.p.A.", value: "483" },
	{ label: "ASSICURAZIONI RISCHI AGRICOLI VMG 1857 S.p.A.", value: "45" },
	{ label: "ASSIMOCO S.p.A.", value: "254" },
	{ label: "ASSIMOCO VITA S.p.A.", value: "357" },
	{ label: "ATHORA ITALIA S.p.A.", value: "213" },
	{ label: "ATRADIUS CRÉDITO Y CAUCIÓN S.A. DE SEGUROS Y REASEGUROS", value: "816" },
	{ label: "AVIVA LIFE & PENSIONS IRELAND DAC *", value: "670" },
	{ label: "AWP P&C SA", value: "896" },
	{ label: "AXA ASSICURAZIONI S.p.A.", value: "14" },
	{ label: "AXA FRANCE IARD *", value: "653" },
	{ label: "AXA FRANCE VIE *", value: "654" },
	{ label: "AXA LIFE EUROPE DAC *", value: "875" },
	{ label: "AXA MPS ASSICURAZIONI DANNI S.p.A.", value: "243" },
	{ label: "AXA MPS ASSICURAZIONI VITA S.p.A.", value: "232" },
	{ label: "AXERIA IARD SA *", value: "879" },
	{ label: "AZIMUT LIFE DAC", value: "847" },
	{ label: "BANCO BPM ASSICURAZIONI S.p.A.", value: "482" },
	{ label: "BANCO BPM VITA S.p.A.", value: "402" },
	{ label: "BCC ASSICURAZIONI S.p.A.", value: "418" },
	{ label: "BCC VITA S.p.A. Compagnia di assicurazioni sulla vita *", value: "449" },
	{ label: "BENE ASSICURAZIONI S.p.A. Società Benefit", value: "486" },
	{ label: "BERKSHIRE HATHAWAY EUROPEAN INSURANCE DAC *", value: "665" },
	{ label: "BERKSHIRE HATHAWAY INTERNATIONAL INSURANCE LIMITED *", value: "676" },
	{ label: "BIM VITA S.p.A. *", value: "391" },
	{ label: "BNP PARIBAS CARDIF VITA S.p.A.", value: "421" },
	{ label: "CACI LIFE DAC *", value: "889" },
	{ label: "CACI NON LIFE DAC", value: "890" },
	{ label: "CARDIF ASSURANCE VIE SA *", value: "331" },
	{ label: "CARDIF ASSURANCES RISQUES DIVERS SA *", value: "332" },
	{ label: "CF ASSICURAZIONI S.p.A.", value: "464" },
	{ label: "CF LIFE COMPAGNIA DI ASSICURAZIONI VITA S.p.A.", value: "361" },
	{ label: "CG CAR-GARANTIE VERS.AKTIENGESELLSCHAFT *", value: "837" },
	{ label: "CGPA EUROPE SA", value: "619" },
	{ label: "CHUBB EUROPEAN GROUP SE", value: "853" },
	{ label: "CHUBB LIFE EUROPE SE *", value: "878" },
	{ label: "CIGNA LIFE INSURANCE CO. OF EUROPE *", value: "813" },
	{ label: "CNA INSURANCE COMPANY (EUROPE) SA *", value: "659" },
	{ label: "CNP ASSURANCES SA", value: "846" },
	{ label: "CNP CAUTION SA *", value: "633" },
	{ label: "CNP PARTNERS DE SEGUROS Y REASEGUROS SA *", value: "624" },
	{ label: "CNP SANTANDER INSURANCE EUROPE DAC *", value: "603" },
	{ label: "CNP SANTANDER INSURANCE LIFE DAC", value: "604" },
	{ label: "CNP UNICREDIT VITA S.p.A.", value: "426" },
	{ label: "CNP VITA ASSICURA S.p.A.", value: "339" },
	{ label: "CNP VITA ASSICURAZIONE S.p.A.", value: "379" },
	{ label: "COMP.FRANÇ.D'ASS.POUR LE COMMERCE EXTÉR.", value: "614" },
	{ label: "COMPAGNIA ASSICURATRICE LINEAR S.p.A. *", value: "416" },
	{ label: "CREDEMASSICURAZIONI S.p.A.", value: "428" },
	{ label: "CREDEMVITA S.p.A.", value: "383" },
	{ label: "CREDENDO - GUARANTEES & SPECIALITY RISKS *", value: "860" },
	{ label: "CREDENDO - SHORT TERM NON-EU RISKS *", value: "622" },
	{ label: "CRÉDIT AGRICOLE ASSICURAZIONI S.p.A.", value: "472" },
	{ label: "CRÉDIT AGRICOLE VITA S.p.A.", value: "350" },
	{ label: "D.A.S.", value: "142" },
	{ label: "DARAG ITALIA S.p.A.", value: "293" },
	{ label: "DOMESTIC & GENERAL INSURANCE EUROPE AG *", value: "668" },
	{ label: "ELBA COMPAGNIA DI ASSICURAZIONE E RIASSICURAZIONI S.p.A.", value: "473" },
	{ label: "ELIPS LIFE LIMITED", value: "637" },
	{ label: "ERGO REISEVERSICHERUNG AKTIENGES. *", value: "876" },
	{ label: "EULER HERMES S.A. N.V.", value: "606" },
	{ label: "EURO INSURANCES DAC", value: "909" },
	{ label: "EUROHERC OSIGURANJE D.D.", value: "971" },
	{ label: "EUROP ASSISTANCE ITALIA S.p.A.", value: "390" },
	{ label: "EUROVITA S.p.A.", value: "381" },
	{ label: "FIDEURAM VITA S.p.A.", value: "479" },
	{ label: "FM INSURANCE EUROPE SA *", value: "647" },
	{ label: "FWU LIFE INSURANCE LUX SA", value: "868" },
	{ label: "GARTENBAU VERSICHERUNG VVA *", value: "677" },
	{ label: "GENERAL REINSURANCE", value: "821" },
	{ label: "GENERALI ITALIA S.p.A.", value: "5" },
	{ label: "GENERTEL S.p.A.", value: "247" },
	{ label: "GENERTELLIFE S.p.A.", value: "445" },
	{ label: "GLOBAL ASSISTANCE S.p.A.", value: "395" },
	{ label: "GRAZER WECHSELSEITIGE VERSICHERUNG AG", value: "919" },
	{ label: "GREAT LAKES INSURANCE SE", value: "899" },
	{ label: "GREENVAL INSURANCE DAC", value: "964" },
	{ label: "GROUPAMA ASSICURAZIONI S.p.A.", value: "151" },
	{ label: "HARMONIE MUTUELLE *", value: "621" },
	{ label: "HDI ASSICURAZIONI S.p.A.", value: "133" },
	{ label: "HDI GLOBAL SE", value: "218" },
	{ label: "HDI GLOBAL SPECIALTY SE *", value: "629" },
	{ label: "HDI ITALIA S.p.A.", value: "86" },
	{ label: "HELVETIA COMPAGNIA SVIZZERA D'ASSICURAZIONI", value: "19" },
	{ label: "HELVETIA ITALIA ASSICURAZIONI S.p.A.", value: "292" },
	{ label: "HELVETIA VITA S.p.A.", value: "448" },
	{ label: "HOK OSIGURANJE D.D.", value: "962" },
	{ label: "IMA ITALIA ASSISTANCE S.p.A.", value: "398" },
	{ label: "INCONTRA ASSICURAZIONI S.p.A. *", value: "281" },
	{ label: "INSURANCE JSC DALLBOGG LIFE AND HEALTH", value: "920" },
	{ label: "INTER PARTNER ASSISTANCE SA", value: "399" },
	{ label: "INTESA SANPAOLO ASSICURA S.p.A.", value: "419" },
	{ label: "INTESA SANPAOLO LIFE DAC", value: "910" },
	{ label: "INTESA SANPAOLO RBM SALUTE S.p.A.", value: "468" },
	{ label: "INTESA SANPAOLO VITA S.p.A.", value: "306" },
	{ label: "IPTIQ EMEA P&C SA", value: "671" },
	{ label: "ITALIANA ASSICURAZIONI S.p.A.", value: "23" },
	{ label: "ITAS VITA S.p.A.", value: "183" },
	{ label: "ITAS-ISTITUTO TRENTINO ALTO ADIGE PER ASSICURAZIONI", value: "22" },
	{ label: "LA MONDIALE EUROPARTNER S.A.", value: "810" },
	{ label: "LE ASSICURAZIONI DI ROMA", value: "212" },
	{ label: "LIBERTY MUTUAL INSURANCE EUROPE SE *", value: "608" },
	{ label: "LLOYD'S INSURANCE COMPANY SA *", value: "656" },
	{ label: "LOMBARD INTERNATIONAL ASSURANCE S.A. *", value: "627" },
	{ label: "LONDON GENERAL INSURANCE COMPANY LTD *", value: "807" },
	{ label: "MAPFRE ASISTENCIA - COMPANIA INT. DE SEGUROS Y REAS. SA", value: "844" },
	{ label: "MAPFRE RE COMPANIA DE REASEGUROS SA *", value: "601" },
	{ label: "MEDIOLANUM ASSICURAZIONI S.p.A.", value: "55" },
	{ label: "MEDIOLANUM INTERNATIONAL LIFE DAC *", value: "841" },
	{ label: "MEDIOLANUM VITA S.p.A.", value: "217" },
	{ label: "METLIFE EUROPE DAC", value: "618" },
	{ label: "METLIFE EUROPE INSURANCE DAC *", value: "617" },
	{ label: "MMA IARD SA", value: "915" },
	{ label: "MONUMENT ASSURANCE LUXEMBOURG SA", value: "829" },
	{ label: "MSIG INSURANCE EUROPE AG *", value: "625" },
	{ label: "MUNCHENER RUCKVERSICHERUNGS-GESELLSCHAFT", value: "882" },
	{ label: "NET INSURANCE LIFE S.p.A.", value: "470" },
	{ label: "NET INSURANCE S.p.A.", value: "441" },
	{ label: "NOBIS COMPAGNIA DI ASSICURAZIONI S.p.A.", value: "474" },
	{ label: "NOBIS VITA S.p.A.", value: "337" },
	{ label: "OCTIUM ASSURANCE AG", value: "917" },
	{ label: "OCTIUM ASSURANCE AG *", value: "854" },
	{ label: "ONIX ASIGURARI SA *", value: "663" },
	{ label: "OPTEVEN ASSURANCES SA *", value: "646" },
	{ label: "POSTE ASSICURA S.p.A.", value: "480" },
	{ label: "POSTE VITA S.p.A.", value: "431" },
	{ label: "PRISMALIFE AG", value: "921" },
	{ label: "PROBUS INSURANCE COMPANY EUROPE DAC", value: "906" },
	{ label: "QBE EUROPE SA/NV *", value: "652" },
	{ label: "QUIXA ASSICURAZIONI S.p.A.", value: "440" },
	{ label: "R+V ALLGEMEINE VERSICHERUNG AG", value: "812" },
	{ label: "REAL GARANT VERSICHERUNG AKTIENGESELLSCHAFT *", value: "649" },
	{ label: "RGA INTERNATIONAL REINSURANCE COMPANY DAC", value: "887" },
	{ label: "ROLAND RECHTSSCHUTZ VERSICHERUNG AG *", value: "867" },
	{ label: "S.L.P. S.p.A. *", value: "226" },
	{ label: "S2C S.p.A.", value: "481" },
	{ label: "SACE BT S.p.A.", value: "455" },
	{ label: "SARA ASSICURAZIONI S.p.A.", value: "32" },
	{ label: "SARA VITA S.p.A.", value: "294" },
	{ label: "SCOR SE", value: "874" },
	{ label: "SCOTTISH WIDOWS EUROPE SA *", value: "664" },
	{ label: "SI INSURANCE (EUROPE) SA", value: "655" },
	{ label: "SIAT S.p.A. *", value: "175" },
	{ label: "SOC. SVIZZERA D'ASSIC.CONTRO LA GRANDINE", value: "851" },
	{ label: "SOCIETÀ CATTOLICA DI ASSICURAZIONE S.p.A.", value: "57" },
	{ label: "SOCIETÀ REALE MUTUA DI ASSICURAZIONI S.p.A.", value: "35" },
	{ label: "SOCIETE HOSPITALIERE D'ASSURANCES MUTUELLE (SHAM)", value: "634" },
	{ label: "SOGECAP", value: "895" },
	{ label: "SOGESSUR S.A.", value: "600" },
	{ label: "STEWART TITLE EUROPE LIMITED *", value: "674" },
	{ label: "SWISS RE EUROPE S.A.", value: "817" },
	{ label: "SWISS RE INTERNATIONAL SE", value: "886" },
	{ label: "TIROLER VERSICHERUNG VAG", value: "862" },
	{ label: "TOKIO MARINE EUROPE SA *", value: "657" },
	{ label: "TUA ASSICURAZIONI S.p.A.", value: "429" },
	{ label: "TUTELA LEGALE S.p.A. *", value: "475" },
	{ label: "UCA - ASSICURAZIONI SPESE LEGALI S.p.A. *", value: "127" },
	{ label: "UNICREDIT ALLIANZ ASSICURAZIONI S.p.A.", value: "346" },
	{ label: "UNICREDIT ALLIANZ VITA S.p.A. *", value: "415" },
	{ label: "UNIPOLSAI ASSICURAZIONI S.p.A. *", value: "34" },
	{ label: "UNIQA ÖSTERREICH VERSICHERUNGEN AG *", value: "828" },
	{ label: "UNISALUTE S.p.A. *", value: "342" },
	{ label: "USAA SA", value: "958" },
	{ label: "VERA ASSICURAZIONI S.p.A.", value: "457" },
	{ label: "VERA PROTEZIONE S.p.A. *", value: "477" },
	{ label: "VERA VITA S.p.A. *", value: "437" },
	{ label: "VEREINIGTE HAGELVERSICHERUNG VVAG", value: "849" },
	{ label: "VERTI ASSICURAZIONI S.p.A.", value: "432" },
	{ label: "VITTORIA ASSICURAZIONI S.p.A.", value: "38" },
	{ label: "WAKAM", value: "955" },
	{ label: "WEFOX INSURANCE AG", value: "922" },
	{ label: "WIENER STÄDTISCHE VERS. AG VIENNA", value: "819" },
	{ label: "XL INSURANCE COMPANY SE", value: "818" },
	{ label: "XL RE EUROPE SE *", value: "678" },
	{ label: "ZURICH INSURANCE COMPANY LIMITED", value: "198" },
	{ label: "ZURICH INSURANCE PLC", value: "864" },
	{ label: "ZURICH INVESTMENTS LIFE S.p.A.", value: "143" },
];

//https://www.fakenamegenerator.com/gen-random-it-it.php
export const Subjects: SubjectData[] = [
	{
		person: {
			id: "1",
			name: "Tranquillino",
			lastname: " Pirozzi",
			gender: "M",
			fiscalCode: "BK35710027",
			birth: {
				date: "25/11/1939",
				city: "San Giuseppe Alla Rena",
				province: "Catania",
				country: "Italia",
			},
		},
		contacts: [
			{
				type: "T",
				value: "0383 9285872",
				description: "numero di telefono principale",
				useCase: "contact-mode",
				preferred: true,
			},
			{
				type: "E",
				value: "TranquillinoPirozzi@rhyta.com",
				description: "email principale",
				useCase: "send-informations",
				preferred: false,
			},
		],
		addresses: [
			{
				type: "R",
				street: "Viale delle Province",
				civic: "78",
				cap: "95121",
				city: "San Giuseppe Alla Rena",
				province: "CT",
				country: "Italia",
			},
		],
		documents: [
			{
				type: "CI",
				number: "526652153518",
				issuingDate: "01/01/2020",
				expirationDate: "01/01/2030",
				issuingInstitution: "comune di Catania",
			},
		],
	},
	{
		person: {
			id: "2",
			name: "Nadia",
			lastname: " Marcelo",
			gender: "F",
			fiscalCode: "FM45872015",
			birth: {
				date: "30/08/1971",
				city: "Lauro Di Sessa Aurunca",
				province: "Cesena",
				country: "Italia",
			},
		},
		contacts: [
			{
				type: "T",
				value: "0350 3221582",
			},
			{
				type: "E",
				value: "NadiaMarcelo@armyspy.com",
			},
		],
		addresses: [
			{
				type: "R",
				street: "Via Cavour",
				civic: "93",
				cap: "81030",
				city: "Lauro Di Sessa Aurunca",
				province: "CE",
				country: "Italia",
			},
		],
		documents: [
			{
				type: "PO",
				number: "6867222728377",
				issuingDate: "01/01/2021",
				expirationDate: "01/01/2031",
				issuingInstitution: "carabinieri di Cesena",
			},
		],
	},
	{
		person: {
			id: "3",
			isProprietorship: true,
			businessName: "Ornella Pirozzi",
			type: "proprietorship",
			pIva: "123456789",
			proprietorship: {
				id: "4",
				name: "Ornella",
				lastname: " Pirozzi",
				gender: "F",
				fiscalCode: "VM87422970",
				birth: {
					date: "26/03/1949",
					city: "Gaiato",
					province: "Modena",
					country: "Italia",
				},
			},
		},
		contacts: [
			{
				type: "T",
				value: "0368 6001893",
			},
			{
				type: "E",
				value: "OrnellaPirozzi@jourrapide.com",
			},
		],
		addresses: [
			{
				type: "R",
				street: "Piazza Bovio",
				civic: "133",
				cap: "41026",
				city: "Gaiato",
				province: "MO",
				country: "Italia",
			},
			{
				type: "S",
				street: "Via Giotto",
				civic: "111",
				cap: "37030",
				city: "Velo Veronese",
				province: "VR",
				country: "Italia",
			},
		],
		documents: [
			{
				type: "CI",
				number: "544029655075",
				issuingDate: "01/01/2022",
				expirationDate: "01/01/2032",
				issuingInstitution: "comune di Gaiato",
			},
			{
				type: "PO",
				number: "WL99890921",
				issuingDate: "01/01/2019",
				expirationDate: "01/01/2029",
				issuingInstitution: "polizia di Gaiato",
			},
			{
				type: "PG",
				number: "47819964",
				issuingDate: "01/01/2018",
				expirationDate: "01/01/2028",
				issuingInstitution: "motorizzazione civile",
			},
		],
	},
];

const subject_1 = Subjects[0].person as SubjectNaturalPersonData;
const subject_2 = Subjects[1].person as SubjectNaturalPersonData;
const subject_3 = Subjects[2].person as SubjectGiuridicalPersonData;

export const defaultClaimPolicyData = {
	policy_number: "AB-12345789",
	effect_date: "01/01/2022",
	expiration_date: "31/12/2022",
	owner: {
		natural_person: {
			id: 1,
			name: subject_1.name,
			lastname: subject_1.lastname,
			fiscal_code: subject_1.fiscalCode,
			province_of_residence: "Milano",
			city_of_residence: "Rho",
		},
	},
	contractor: {
		giuridical_person: {
			id: 2,
			businessName: subject_3.businessName,
			pIva: subject_3.pIva,
			registered_office_province: "Bologna",
			registered_office_city: "Cadriano",
		},
	},
	ownerVehicle: {
		type: "A",
		plate: {
			number: "AB123CD",
			format: "Targa Italiana",
		},
	},
	claimNote: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque",
	damagedParts: [
		{
			pdNumber: "1669798161516",
			subject: {
				id: "1",
				name: "Tranquillino",
				lastname: " Pirozzi",
				gender: "M",
				fiscalCode: "BK35710027",
				birth: {
					date: "25/11/1939",
					city: "San Giuseppe Alla Rena",
					province: "Catania",
					country: "Italia",
				},
			},
			roleType: "CP",
			damages: [
				{
					damageType: "Vehicle",
					details: {
						plate: "AB123CD",
						format: "Targa Italiana",
						type: "A",
						collisionPoints: ["11", "12", "13"],
						note: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur",
					},
				},
				{
					damageType: "Person",
					details: {
						personWoundedPoints: ["front_trunc", "rear_trunc", "front_arm_right"],
						note: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque",
					},
				},
				{
					damageType: "Thing",
					details: {
						note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
					},
				},
			],
			note: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
		} as DamagedPartType,
		{
			pdNumber: "1669909946930",
			subject: {
				id: "2",
				name: "Nadia",
				lastname: " Marcelo",
				gender: "F",
				fiscalCode: "FM45872015",
				birth: {
					date: "30/08/1971",
					city: "Lauro Di Sessa Aurunca",
					province: "Cesena",
					country: "Italia",
				},
			},
			roleType: "TN",
			damages: [
				{
					damageType: "Person",
					details: {
						personWoundedPoints: ["front_head", "rear_head", "front_arm_left"],
						note: "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur",
					},
				},
				{
					damageType: "Thing",
					details: {
						note: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
					},
				},
				{
					damageType: "Thing",
					details: {
						note: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
					},
				},
			],
		} as DamagedPartType,
		{
			pdNumber: "1669909992417",
			subject: {
				id: "3",
				name: "Marianna",
				lastname: " Palerma",
				gender: "F",
				fiscalCode: "RR61475525",
				birth: {
					date: "21/01/1981",
					city: "San Giovanni Ilarione",
					province: "Verona",
					country: "Italia",
				},
			},
			roleType: "TD",
			damages: [
				{
					damageType: "Thing",
					details: {
						note: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam",
					},
				},
				{
					damageType: "Thing",
					details: {
						note: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione",
					},
				},
			],
		} as DamagedPartType,
	],
	additionalInfo: [
		{
			id: 1670246570138,
			type: "subject" as AdditionaInfoTypes,
			damagedPartNumber: "1669798161516",
			details: {
				role: "coachbuilder",
				subject: undefined,
			} as AdditionalInfoSubjectType,
		},
		{
			id: 1670246577652,
			type: "document" as AdditionaInfoTypes,
			damagedPartNumber: "1669798161516",
			details: {
				type: "expertise",
				filename: ["fronte.jpg", "retro.jpg"],
			} as AdditionalInfoDocumentType,
		},
	],
};

export const CarsData = [
	{
		brand: "Seat",
		models: [
			"Alhambra",
			"Altea",
			"Altea XL",
			"Arosa",
			"Cordoba",
			"Cordoba Vario",
			"Exeo",
			"Ibiza",
			"Ibiza ST",
			"Exeo ST",
			"Leon",
			"Leon ST",
			"Inca",
			"Mii",
			"Toledo",
		],
	},
	{
		brand: "Renault",
		models: [
			"Captur",
			"Clio",
			"Clio Grandtour",
			"Espace",
			"Express",
			"Fluence",
			"Grand Espace",
			"Grand Modus",
			"Grand Scenic",
			"Kadjar",
			"Kangoo",
			"Kangoo Express",
			"Koleos",
			"Laguna",
			"Laguna Grandtour",
			"Latitude",
			"Mascott",
			"Mégane",
			"Mégane CC",
			"Mégane Combi",
			"Mégane Grandtour",
			"Mégane Coupé",
			"Mégane Scénic",
			"Scénic",
			"Talisman",
			"Talisman Grandtour",
			"Thalia",
			"Twingo",
			"Wind",
			"Zoé",
		],
	},
	{
		brand: "Peugeot",
		models: [
			"1007",
			"107",
			"106",
			"108",
			"2008",
			"205",
			"205 Cabrio",
			"206",
			"206 CC",
			"206 SW",
			"207",
			"207 CC",
			"207 SW",
			"306",
			"307",
			"307 CC",
			"307 SW",
			"308",
			"308 CC",
			"308 SW",
			"309",
			"4007",
			"4008",
			"405",
			"406",
			"407",
			"407 SW",
			"5008",
			"508",
			"508 SW",
			"605",
			"806",
			"607",
			"807",
			"Bipper",
			"RCZ",
		],
	},
	{ brand: "Dacia", models: ["Dokker", "Duster", "Lodgy", "Logan", "Logan MCV", "Logan Van", "Sandero", "Solenza"] },
	{
		brand: "Citroën",
		models: [
			"Berlingo",
			"C-Crosser",
			"C-Elissée",
			"C-Zero",
			"C1",
			"C2",
			"C3",
			"C3 Picasso",
			"C4",
			"C4 Aircross",
			"C4 Cactus",
			"C4 Coupé",
			"C4 Grand Picasso",
			"C4 Sedan",
			"C5",
			"C5 Break",
			"C5 Tourer",
			"C6",
			"C8",
			"DS3",
			"DS4",
			"DS5",
			"Evasion",
			"Jumper",
			"Jumpy",
			"Saxo",
			"Nemo",
			"Xantia",
			"Xsara",
		],
	},
	{
		brand: "Opel",
		models: [
			"Agila",
			"Ampera",
			"Antara",
			"Astra",
			"Astra cabrio",
			"Astra caravan",
			"Astra coupé",
			"Calibra",
			"Campo",
			"Cascada",
			"Corsa",
			"Frontera",
			"Insignia",
			"Insignia kombi",
			"Kadett",
			"Meriva",
			"Mokka",
			"Movano",
			"Omega",
			"Signum",
			"Vectra",
			"Vectra Caravan",
			"Vivaro",
			"Vivaro Kombi",
			"Zafira",
		],
	},
	{
		brand: "Alfa Romeo",
		models: [
			"145",
			"146",
			"147",
			"155",
			"156",
			"156 Sportwagon",
			"159",
			"159 Sportwagon",
			"164",
			"166",
			"4C",
			"Brera",
			"GTV",
			"MiTo",
			"Crosswagon",
			"Spider",
			"GT",
			"Giulietta",
			"Giulia",
		],
	},
	{
		brand: "Škoda",
		models: [
			"Favorit",
			"Felicia",
			"Citigo",
			"Fabia",
			"Fabia Combi",
			"Fabia Sedan",
			"Felicia Combi",
			"Octavia",
			"Octavia Combi",
			"Roomster",
			"Yeti",
			"Rapid",
			"Rapid Spaceback",
			"Superb",
			"Superb Combi",
		],
	},
	{
		brand: "Chevrolet",
		models: [
			"Alero",
			"Aveo",
			"Camaro",
			"Captiva",
			"Corvette",
			"Cruze",
			"Cruze SW",
			"Epica",
			"Equinox",
			"Evanda",
			"HHR",
			"Kalos",
			"Lacetti",
			"Lacetti SW",
			"Lumina",
			"Malibu",
			"Matiz",
			"Monte Carlo",
			"Nubira",
			"Orlando",
			"Spark",
			"Suburban",
			"Tacuma",
			"Tahoe",
			"Trax",
		],
	},
	{
		brand: "Porsche",
		models: ["911 Carrera", "911 Carrera Cabrio", "911 Targa", "911 Turbo", "924", "944", "997", "Boxster", "Cayenne", "Cayman", "Macan", "Panamera"],
	},
	{
		brand: "Honda",
		models: [
			"Accord",
			"Accord Coupé",
			"Accord Tourer",
			"City",
			"Civic",
			"Civic Aerodeck",
			"Civic Coupé",
			"Civic Tourer",
			"Civic Type R",
			"CR-V",
			"CR-X",
			"CR-Z",
			"FR-V",
			"HR-V",
			"Insight",
			"Integra",
			"Jazz",
			"Legend",
			"Prelude",
		],
	},
	{
		brand: "Subaru",
		models: [
			"BRZ",
			"Forester",
			"Impreza",
			"Impreza Wagon",
			"Justy",
			"Legacy",
			"Legacy Wagon",
			"Legacy Outback",
			"Levorg",
			"Outback",
			"SVX",
			"Tribeca",
			"Tribeca B9",
			"XV",
		],
	},
	{
		brand: "Mazda",
		models: [
			"121",
			"2",
			"3",
			"323",
			"323 Combi",
			"323 Coupé",
			"323 F",
			"5",
			"6",
			"6 Combi",
			"626",
			"626 Combi",
			"B-Fighter",
			"B2500",
			"BT",
			"CX-3",
			"CX-5",
			"CX-7",
			"CX-9",
			"Demio",
			"MPV",
			"MX-3",
			"MX-5",
			"MX-6",
			"Premacy",
			"RX-7",
			"RX-8",
			"Xedox 6",
		],
	},
	{
		brand: "Mitsubishi",
		models: [
			"3000 GT",
			"ASX",
			"Carisma",
			"Colt",
			"Colt CC",
			"Eclipse",
			"Fuso canter",
			"Galant",
			"Galant Combi",
			"Grandis",
			"L200",
			"L200 Pick up",
			"L200 Pick up Allrad",
			"L300",
			"Lancer",
			"Lancer Combi",
			"Lancer Evo",
			"Lancer Sportback",
			"Outlander",
			"Pajero",
			"Pajeto Pinin",
			"Pajero Pinin Wagon",
			"Pajero Sport",
			"Pajero Wagon",
			"Space Star",
		],
	},
	{
		brand: "Lexus",
		models: ["CT", "GS", "GS 300", "GX", "IS", "IS 200", "IS 250 C", "IS-F", "LS", "LX", "NX", "RC F", "RX", "RX 300", "RX 400h", "RX 450h", "SC 430"],
	},
	{
		brand: "Toyota",
		models: [
			"4-Runner",
			"Auris",
			"Avensis",
			"Avensis Combi",
			"Avensis Van Verso",
			"Aygo",
			"Camry",
			"Carina",
			"Celica",
			"Corolla",
			"Corolla Combi",
			"Corolla sedan",
			"Corolla Verso",
			"FJ Cruiser",
			"GT86",
			"Hiace",
			"Hiace Van",
			"Highlander",
			"Hilux",
			"Land Cruiser",
			"MR2",
			"Paseo",
			"Picnic",
			"Prius",
			"RAV4",
			"Sequoia",
			"Starlet",
			"Supra",
			"Tundra",
			"Urban Cruiser",
			"Verso",
			"Yaris",
			"Yaris Verso",
		],
	},
	{
		brand: "BMW",
		models: [
			"i3",
			"i8",
			"M3",
			"M4",
			"M5",
			"M6",
			"Rad 1",
			"Rad 1 Cabrio",
			"Rad 1 Coupé",
			"Rad 2",
			"Rad 2 Active Tourer",
			"Rad 2 Coupé",
			"Rad 2 Gran Tourer",
			"Rad 3",
			"Rad 3 Cabrio",
			"Rad 3 Compact",
			"Rad 3 Coupé",
			"Rad 3 GT",
			"Rad 3 Touring",
			"Rad 4",
			"Rad 4 Cabrio",
			"Rad 4 Gran Coupé",
			"Rad 5",
			"Rad 5 GT",
			"Rad 5 Touring",
			"Rad 6",
			"Rad 6 Cabrio",
			"Rad 6 Coupé",
			"Rad 6 Gran Coupé",
			"Rad 7",
			"Rad 8 Coupé",
			"X1",
			"X3",
			"X4",
			"X5",
			"X6",
			"Z3",
			"Z3 Coupé",
			"Z3 Roadster",
			"Z4",
			"Z4 Roadster",
		],
	},
	{
		brand: "Volkswagen",
		models: [
			"Amarok",
			"Beetle",
			"Bora",
			"Bora Variant",
			"Caddy",
			"Caddy Van",
			"Life",
			"California",
			"Caravelle",
			"CC",
			"Crafter",
			"Crafter Van",
			"Crafter Kombi",
			"CrossTouran",
			"Eos",
			"Fox",
			"Golf",
			"Golf Cabrio",
			"Golf Plus",
			"Golf Sportvan",
			"Golf Variant",
			"Jetta",
			"LT",
			"Lupo",
			"Multivan",
			"New Beetle",
			"New Beetle Cabrio",
			"Passat",
			"Passat Alltrack",
			"Passat CC",
			"Passat Variant",
			"Passat Variant Van",
			"Phaeton",
			"Polo",
			"Polo Van",
			"Polo Variant",
			"Scirocco",
			"Sharan",
			"T4",
			"T4 Caravelle",
			"T4 Multivan",
			"T5",
			"T5 Caravelle",
			"T5 Multivan",
			"T5 Transporter Shuttle",
			"Tiguan",
			"Touareg",
			"Touran",
		],
	},
	{
		brand: "Suzuki",
		models: [
			"Alto",
			"Baleno",
			"Baleno kombi",
			"Grand Vitara",
			"Grand Vitara XL-7",
			"Ignis",
			"Jimny",
			"Kizashi",
			"Liana",
			"Samurai",
			"Splash",
			"Swift",
			"SX4",
			"SX4 Sedan",
			"Vitara",
			"Wagon R+",
		],
	},
	{
		brand: "Mercedes-Benz",
		models: [
			"100 D",
			"115",
			"124",
			"126",
			"190",
			"190 D",
			"190 E",
			"200 - 300",
			"200 D",
			"200 E",
			"210 Van",
			"210 kombi",
			"310 Van",
			"310 kombi",
			"230 - 300 CE Coupé",
			"260 - 560 SE",
			"260 - 560 SEL",
			"500 - 600 SEC Coupé",
			"Trieda A",
			"A",
			"A L",
			"AMG GT",
			"Trieda B",
			"Trieda C",
			"C",
			"C Sportcoupé",
			"C T",
			"Citan",
			"CL",
			"CL",
			"CLA",
			"CLC",
			"CLK Cabrio",
			"CLK Coupé",
			"CLS",
			"Trieda E",
			"E",
			"E Cabrio",
			"E Coupé",
			"E T",
			"Trieda G",
			"G Cabrio",
			"GL",
			"GLA",
			"GLC",
			"GLE",
			"GLK",
			"Trieda M",
			"MB 100",
			"Trieda R",
			"Trieda S",
			"S",
			"S Coupé",
			"SL",
			"SLC",
			"SLK",
			"SLR",
			"Sprinter",
		],
	},
	{
		brand: "Saab",
		models: ["9-3", "9-3 Cabriolet", "9-3 Coupé", "9-3 SportCombi", "9-5", "9-5 SportCombi", "900", "900 C", "900 C Turbo", "9000"],
	},
	{
		brand: "Audi",
		models: [
			"100",
			"100 Avant",
			"80",
			"80 Avant",
			"80 Cabrio",
			"90",
			"A1",
			"A2",
			"A3",
			"A3 Cabriolet",
			"A3 Limuzina",
			"A3 Sportback",
			"A4",
			"A4 Allroad",
			"A4 Avant",
			"A4 Cabriolet",
			"A5",
			"A5 Cabriolet",
			"A5 Sportback",
			"A6",
			"A6 Allroad",
			"A6 Avant",
			"A7",
			"A8",
			"A8 Long",
			"Q3",
			"Q5",
			"Q7",
			"R8",
			"RS4 Cabriolet",
			"RS4/RS4 Avant",
			"RS5",
			"RS6 Avant",
			"RS7",
			"S3/S3 Sportback",
			"S4 Cabriolet",
			"S4/S4 Avant",
			"S5/S5 Cabriolet",
			"S6/RS6",
			"S7",
			"S8",
			"SQ5",
			"TT Coupé",
			"TT Roadster",
			"TTS",
		],
	},
	{
		brand: "Kia",
		models: [
			"Avella",
			"Besta",
			"Carens",
			"Carnival",
			"Cee`d",
			"Cee`d SW",
			"Cerato",
			"K 2500",
			"Magentis",
			"Opirus",
			"Optima",
			"Picanto",
			"Pregio",
			"Pride",
			"Pro Cee`d",
			"Rio",
			"Rio Combi",
			"Rio sedan",
			"Sephia",
			"Shuma",
			"Sorento",
			"Soul",
			"Sportage",
			"Venga",
		],
	},
	{
		brand: "Land Rover",
		models: ["109", "Defender", "Discovery", "Discovery Sport", "Freelander", "Range Rover", "Range Rover Evoque", "Range Rover Sport"],
	},
	{
		brand: "Dodge",
		models: ["Avenger", "Caliber", "Challenger", "Charger", "Grand Caravan", "Journey", "Magnum", "Nitro", "RAM", "Stealth", "Viper"],
	},
	{
		brand: "Chrysler",
		models: [
			"300 C",
			"300 C Touring",
			"300 M",
			"Crossfire",
			"Grand Voyager",
			"LHS",
			"Neon",
			"Pacifica",
			"Plymouth",
			"PT Cruiser",
			"Sebring",
			"Sebring Convertible",
			"Stratus",
			"Stratus Cabrio",
			"Town & Country",
			"Voyager",
		],
	},
	{
		brand: "Ford",
		models: [
			"Aerostar",
			"B-Max",
			"C-Max",
			"Cortina",
			"Cougar",
			"Edge",
			"Escort",
			"Escort Cabrio",
			"Escort kombi",
			"Explorer",
			"F-150",
			"F-250",
			"Fiesta",
			"Focus",
			"Focus C-Max",
			"Focus CC",
			"Focus kombi",
			"Fusion",
			"Galaxy",
			"Grand C-Max",
			"Ka",
			"Kuga",
			"Maverick",
			"Mondeo",
			"Mondeo Combi",
			"Mustang",
			"Orion",
			"Puma",
			"Ranger",
			"S-Max",
			"Sierra",
			"Street Ka",
			"Tourneo Connect",
			"Tourneo Custom",
			"Transit",
			"Transit",
			"Transit Bus",
			"Transit Connect LWB",
			"Transit Courier",
			"Transit Custom",
			"Transit kombi",
			"Transit Tourneo",
			"Transit Valnik",
			"Transit Van",
			"Transit Van 350",
			"Windstar",
		],
	},
	{ brand: "Hummer", models: ["H2", "H3"] },
	{
		brand: "Hyundai",
		models: [
			"Accent",
			"Atos",
			"Atos Prime",
			"Coupé",
			"Elantra",
			"Galloper",
			"Genesis",
			"Getz",
			"Grandeur",
			"H 350",
			"H1",
			"H1 Bus",
			"H1 Van",
			"H200",
			"i10",
			"i20",
			"i30",
			"i30 CW",
			"i40",
			"i40 CW",
			"ix20",
			"ix35",
			"ix55",
			"Lantra",
			"Matrix",
			"Santa Fe",
			"Sonata",
			"Terracan",
			"Trajet",
			"Tucson",
			"Veloster",
		],
	},
	{ brand: "Infiniti", models: ["EX", "FX", "G", "G Coupé", "M", "Q", "QX"] },
	{
		brand: "Jaguar",
		models: [
			"Daimler",
			"F-Pace",
			"F-Type",
			"S-Type",
			"Sovereign",
			"X-Type",
			"X-type Estate",
			"XE",
			"XF",
			"XJ",
			"XJ12",
			"XJ6",
			"XJ8",
			"XJ8",
			"XJR",
			"XK",
			"XK8 Convertible",
			"XKR",
			"XKR Convertible",
		],
	},
	{ brand: "Jeep", models: ["Cherokee", "Commander", "Compass", "Grand Cherokee", "Patriot", "Renegade", "Wrangler"] },
	{
		brand: "Nissan",
		models: [
			"100 NX",
			"200 SX",
			"350 Z",
			"350 Z Roadster",
			"370 Z",
			"Almera",
			"Almera Tino",
			"Cabstar E - T",
			"Cabstar TL2 Valnik",
			"e-NV200",
			"GT-R",
			"Insterstar",
			"Juke",
			"King Cab",
			"Leaf",
			"Maxima",
			"Maxima QX",
			"Micra",
			"Murano",
			"Navara",
			"Note",
			"NP300 Pickup",
			"NV200",
			"NV400",
			"Pathfinder",
			"Patrol",
			"Patrol GR",
			"Pickup",
			"Pixo",
			"Primastar",
			"Primastar Combi",
			"Primera",
			"Primera Combi",
			"Pulsar",
			"Qashqai",
			"Serena",
			"Sunny",
			"Terrano",
			"Tiida",
			"Trade",
			"Vanette Cargo",
			"X-Trail",
		],
	},
	{
		brand: "Volvo",
		models: [
			"240",
			"340",
			"360",
			"460",
			"850",
			"850 kombi",
			"C30",
			"C70",
			"C70 Cabrio",
			"C70 Coupé",
			"S40",
			"S60",
			"S70",
			"S80",
			"S90",
			"V40",
			"V50",
			"V60",
			"V70",
			"V90",
			"XC60",
			"XC70",
			"XC90",
		],
	},
	{
		brand: "Daewoo",
		models: ["Espero", "Kalos", "Lacetti", "Lanos", "Leganza", "Lublin", "Matiz", "Nexia", "Nubira", "Nubira kombi", "Racer", "Tacuma", "Tico"],
	},
	{
		brand: "Fiat",
		models: [
			"1100",
			"126",
			"500",
			"500L",
			"500X",
			"850",
			"Barchetta",
			"Brava",
			"Cinquecento",
			"Coupé",
			"Croma",
			"Doblo",
			"Doblo Cargo",
			"Doblo Cargo Combi",
			"Ducato",
			"Ducato Van",
			"Ducato Kombi",
			"Ducato Podvozok",
			"Florino",
			"Florino Combi",
			"Freemont",
			"Grande Punto",
			"Idea",
			"Linea",
			"Marea",
			"Marea Weekend",
			"Multipla",
			"Palio Weekend",
			"Panda",
			"Panda Van",
			"Punto",
			"Punto Cabriolet",
			"Punto Evo",
			"Punto Van",
			"Qubo",
			"Scudo",
			"Scudo Van",
			"Scudo Kombi",
			"Sedici",
			"Seicento",
			"Stilo",
			"Stilo Multiwagon",
			"Strada",
			"Talento",
			"Tipo",
			"Ulysse",
			"Uno",
			"X1/9",
		],
	},
	{
		brand: "MINI",
		models: [
			"Cooper",
			"Cooper Cabrio",
			"Cooper Clubman",
			"Cooper D",
			"Cooper D Clubman",
			"Cooper S",
			"Cooper S Cabrio",
			"Cooper S Clubman",
			"Countryman",
			"Mini One",
			"One D",
		],
	},
	{ brand: "Rover", models: ["200", "214", "218", "25", "400", "414", "416", "620", "75"] },
	{
		brand: "Smart",
		models: ["Cabrio", "City-Coupé", "Compact Pulse", "Forfour", "Fortwo cabrio", "Fortwo coupé", "Roadster"],
	},
];
