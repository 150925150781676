import { useTranslation } from "react-i18next";
import { Modifydetail } from "../../../../../../redux/apiSpecifications/apiCrud";

interface IPracticeSuspendeddDescription {
    modifyDetailsJson: Modifydetail[];
}
const PracticeSuspendedDescription = ({
    modifyDetailsJson,
}: IPracticeSuspendeddDescription) => {
    const { t } = useTranslation();
    return <>{t(`historical-actions-practice-suspended-description`)}</>;
}

export default PracticeSuspendedDescription;