import { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../../../hooks/useAuthorization";
import { LoadingFullScreenWrapper } from "../../../../style/DashbordWidgetWrappers";
import { IconLoading } from "../../../../config/icons";
import PriorityFilter from "./PriorityFilter";
import {
  FilterPriorityValues,
  FilterStatusValues,
  StatusTypes,
} from "../../Dashboards/Dashboard-E2";
import StatusFilter from "./StatusFilter";
import AppriaserFilter, {
  AppraiserFilterValues,
  AppraiserType,
} from "./AppraiserFilter";
import { useInterval } from "../../../../hooks/useInterval";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import TimerPie from "../../../Layout/TimerPie";
import PracticeList from "./PracticeList";
import {
  ReminderPractice,
  ReminderSupplierNetwork,
  useLazyGetRemindersSupplierNetworkQuery,
} from "../../../../redux/apiSpecifications/apiCrud";

//Styled components
const RemindersPanelWrapper = styled.div`
  display: grid;
  grid-template-columns: 5fr 2fr;
  grid-template-rows: 1fr 1fr calc(100vh - 18em);
  grid-template-areas:
    "priority  appraiser"
    "status  refresh"
    "practices  practices";
  padding: 1em;
  gap: 0 1em;
  overflow: hidden;

  .appraiser-filter-wrapper {
    grid-area: appraiser;
  }

  .priority-filter-wrapper {
    grid-area: priority;
  }

  .status-filter-wrapper {
    grid-area: status;
  }

  .refresh-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      grid-area: refresh;
      background-color: #fff;
      padding: 0.4em;
      border-radius: 50%;
      box-shadow: 0 0 3px #999;
    }
  }

  .practices-wrapper {
    grid-area: practices;
    overflow: auto;
    margin-right: -1em;
    padding-right: 1em;
  }
`;

interface IRemindersPanelProps {
  tabKey?: number;
}

const RemindesPanel = (props: IRemindersPanelProps) => {
  const { t } = useTranslation();
  const { getAuthorization } = useAuthorization();
  const activeRole = useSelector((state: RootState) => state.user.activeRole);

  const [practices, setPractices] = useState<ReminderPractice[]>([]);
  const [filteredPractices, setFilteredPractices] = useState<
    ReminderPractice[]
  >([]);

  const [appraiserValues, setAppraiserValues] = useState<AppraiserFilterValues>(
    {
      internal: 0,
      external: 0,
    }
  );

  const [priorityValues, setPriorityValues] = useState<FilterPriorityValues>({
    urgent: 0,
    important: 0,
    expiring: 0,
    expired: 0,
    standard: 0,
  });

  const [statusValues, setStatusValues] = useState<FilterStatusValues>({
    rejected: 0,
    confirmed: 0,
    withoutAppointment: 0,
    withAppointment: 0,
    withAppointmentToday: 0,
    concluded: 0,
    waitingForAgreement: 0
  });

  const [appraiserSelected, setAppraiserSelected] = useState<AppraiserType>(
    activeRole == "NF" ? "E" : activeRole == "E1" ? "I" : undefined
  );
  const [prioritySelected, setPrioritySelected] = useState<number>();
  const [statusSelected, setStatusSelected] = useState<StatusTypes>();

  const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");

  const { activeKey } = useSelector((state: RootState) => state.mainTabs);
  const autoRefreshTabData = useSelector(
    (state: RootState) => state.user.configParams?.autoRefreshTabData || 600
  ); // 10 minutes

  const {
    restart: restartRefrehInterval,
    reset: resetRefrehInterval,
    clear: clearRefreshInterval,
    percentage,
    remainingTime,
  } = useInterval({
    seconds: autoRefreshTabData,
    callback: () => {
      loadData();
    },
  });

  useEffect(() => {
    if (activeKey === props.tabKey) {
      (async () => {
        await loadData();
        restartRefrehInterval();
      })();
    } else clearRefreshInterval();
  }, [activeKey]);

  // API
  const [retrieveSummaryData] = useLazyGetRemindersSupplierNetworkQuery();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setStatus("loading");

    const response = await retrieveSummaryData({
      authorization: await getAuthorization(),
    });

    if (response.error) {
      setStatus("error");
      return;
    } else if (response.data) {
      const data = response.data;
      const _practices = (data as ReminderSupplierNetwork).practices || [];

      const urgents = _practices.filter((p) => p.priority === 1);
      const importants = _practices.filter((p) => p.priority === 2);
      const expirings = _practices.filter((p) => p.priority === 3);
      const expireds = _practices.filter((p) => p.priority === 4);

      setPractices([...urgents, ...importants, ...expireds, ...expirings]);
      setFilteredPractices(_practices);
      setStatus("idle");
    }
  };

  useEffect(() => {
    let _filteredPractices = practices;

    if (appraiserSelected) {
      _filteredPractices = _filteredPractices.filter(
        (p) => p.appraiserType === appraiserSelected
      );
    }

    if (prioritySelected) {
      _filteredPractices = _filteredPractices.filter(
        (p) => p.priority === prioritySelected
      );
    }

    if (statusSelected) {
      _filteredPractices = _filteredPractices.filter(
        (p) => p.status === statusSelected
      );
    }

    setFilteredPractices(_filteredPractices);

    const _filteredByPriorityAndStatus = practices.filter(
      (p) =>
        (prioritySelected ? p.priority === prioritySelected : true) &&
        (statusSelected ? p.status === statusSelected : true)
    );

    const _filteredByPriorityAndAppraiser = practices.filter(
      (p) =>
        (prioritySelected ? p.priority === prioritySelected : true) &&
        (appraiserSelected ? p.appraiserType === appraiserSelected : true)
    );

    const _filteredByStatusAndAppraiser = practices.filter(
      (p) =>
        (statusSelected ? p.status === statusSelected : true) &&
        (appraiserSelected ? p.appraiserType === appraiserSelected : true)
    );

    const appraiserValues: AppraiserFilterValues = {
      internal: _filteredByPriorityAndStatus.filter(
        (p) => p.appraiserType === "I"
      ).length,
      external: _filteredByPriorityAndStatus.filter(
        (p) => p.appraiserType === "E"
      ).length,
    };

    const priorityValues: FilterPriorityValues = {
      urgent: _filteredByStatusAndAppraiser.filter((p) => p.priority === 1)
        .length,
      important: _filteredByStatusAndAppraiser.filter((p) => p.priority === 2)
        .length,
      expiring: _filteredByStatusAndAppraiser.filter((p) => p.priority === 3)
        .length,
      expired: _filteredByStatusAndAppraiser.filter((p) => p.priority === 4)
        .length,
      standard: 0,
    };

    const statusValues: FilterStatusValues = {
      rejected: _filteredByPriorityAndAppraiser.filter((p) => p.status === "RE")
        .length,
      confirmed: _filteredByPriorityAndAppraiser.filter(
        (p) => p.status === "PA"
      ).length,
      withoutAppointment: _filteredByPriorityAndAppraiser.filter(
        (p) => p.status === "AD"
      ).length,
      withAppointment: 0,
      withAppointmentToday: 0,
      concluded: _filteredByPriorityAndAppraiser.filter(
        (p) => p.status === "CP"
      ).length,
      waitingForAgreement: _filteredByPriorityAndAppraiser.filter(
        (p) => p.status === "AC"
      ).length,
    };

    setAppraiserValues(appraiserValues);
    setPriorityValues(priorityValues);
    setStatusValues(statusValues);
  }, [practices, appraiserSelected, prioritySelected, statusSelected]);

  return (
    <>
      {["loading", "saving"].includes(status) && (
        <LoadingFullScreenWrapper>
          <div className="box">{IconLoading}</div>
        </LoadingFullScreenWrapper>
      )}

      <RemindersPanelWrapper>
        <div className="appraser-filter-wrapper">
          <AppriaserFilter
            values={appraiserValues}
            onSelect={setAppraiserSelected}
            selected={appraiserSelected}
          />
        </div>

        <div className="priority-filter-wrapper">
          <PriorityFilter
            values={priorityValues}
            onSelect={setPrioritySelected}
            selected={prioritySelected}
          />
        </div>
        
        <div className="status-filter-wrapper">
          <StatusFilter
            values={statusValues}
            onSelect={setStatusSelected}
            selected={statusSelected}
          />
        </div>

        <div className="refresh-wrapper">
          <div className="content">
            <TimerPie
              percentage={percentage as number}
              tooltip={`${t(
                "timer-dashboard-refresh-tooltip-1"
              )!}  ${remainingTime}`}
              innerText={remainingTime as string}
              bgColor="#32b677"
              gridArea="refresh"
              onClick={() => {
                loadData();
                resetRefrehInterval();
              }}
            />
          </div>
        </div>

        <div className="practices-wrapper">
          <PracticeList items={filteredPractices} />
        </div>
      </RemindersPanelWrapper>
    </>
  );
};

export default RemindesPanel;
