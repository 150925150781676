import styled from "styled-components";
import ConservativeAgreementAttachment from "../../../../Inputs/ConservativeAgreementAttachment";
import { useState } from "react";
import {
  PracticeAttachment,
  PracticeDocument,
  useLazyGetConservativeAgreementFileQuery,
  useLazyGetIniFileQuery,
} from "../../../../../redux/apiSpecifications/apiCrud";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import { addMimeType } from "../../../../../utils/base64";

const AttachmentsWrapper = styled.div`
  width: "auto";
  display: flex;
  flex-direction: column;
  align-items: "center";
  justify-content: center;
  width: 12em;
  text-align: center;
  font-size: 0.9em;
`;

const AttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  .ant-upload-list-item-actions {
    a {
      display: none;
    }
  }
  .type-name {
    margin-top: 1em;
    text-transform: uppercase;
    font-size: 0.9em;
  }
`;

const FilesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

interface IAppraisalConservativeAgreementProps {
  idPractice: number;
}

const AppraisalConservativeAgreement = ({
  idPractice,
}: IAppraisalConservativeAgreementProps) => {
  const [attachment, setAttachment] = useState<PracticeAttachment | undefined>(
    undefined
  );

  const { getAuthorization } = useAuthorization();

  const [getGetConservativeAgreement, result] =
    useLazyGetConservativeAgreementFileQuery();

  const handleOnDownloadFile = async () => {
    setAttachment(undefined);

    const authorization = await getAuthorization();
    const response = await getGetConservativeAgreement({
      authorization,
      id: idPractice,
    });

    if (response.isSuccess && response.data) {
      const practiceAttachment = response.data as PracticeAttachment;

      setAttachment({
        ...practiceAttachment,
        base64File: addMimeType(
          practiceAttachment.base64File!,
          practiceAttachment.originalName!
        ),
      });
    }
  };

  return (
    <AttachmentsWrapper>
      <AttachmentsContainer>
        <FilesContainer>
          <ConservativeAgreementAttachment
            onDownload={handleOnDownloadFile}
            isLoading={result.status === "pending"}
            attachment={attachment}
          ></ConservativeAgreementAttachment>
        </FilesContainer>
      </AttachmentsContainer>
    </AttachmentsWrapper>
  );
};

export default AppraisalConservativeAgreement;
