import React from "react";
import styled from "styled-components";
import {
  Appointment,
  DailyAgenda,
  District,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addTab } from "../../../../../redux/features/mainTabsSlice";

// Styled components

const AppraiserAgendaAppointmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .header {
    padding: 0.25em;
    border-radius: 5px;
    background-color: #eee;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9em;
  }

  .appointments {
    display: flex;
    flex-direction: column;
    margin-top: 1em;

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }

      .item-name {
        padding-left: 1em;
        flex: 1;
      }

      .item-timeslot {
        font-size: 1.2em;
        width: 2em;
      }
    }
  }
`;

interface IAppraiserAgendaAppointmentsProps {
  appointments: Appointment[] | undefined;
}

const AppraiserAgendaAppointments = ({
  appointments,
}: IAppraiserAgendaAppointmentsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickAppointment = (
    idPractice: number,
    practiceNumber: string
  ) => {
    dispatch(
      addTab({
        key: 0,
        label: practiceNumber,
        type: "appraisal-details",
        externalData: {
          idPractice: idPractice,
        },
      })
    );
  };

  return (
    <AppraiserAgendaAppointmentsWrapper>
      <div className="header">{t("agenda-legend-appointments")}</div>
      <div className="appointments">
        {appointments?.map((appointment, i) => (
          <div
            className="item"
            key={i}
            onClick={() =>
              handleClickAppointment(
                appointment.practiceId!,
                appointment.practiceNumber!
              )
            }
          >
            <div className="item-name">{appointment.appointmentAddress}</div>
            <div className="item-timeslot">
              {t(`appointment-slot-${appointment.appointmentTimeSlot}`)}
            </div>
          </div>
        ))}
      </div>
    </AppraiserAgendaAppointmentsWrapper>
  );
};

export default AppraiserAgendaAppointments;
