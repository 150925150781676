import { notification } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useEffect } from "react";
import { NotificationPlacement } from "antd/lib/notification";

export const Notifications = () => {
    const appNotification = useSelector(
        (state: RootState) => state.notifications
    );

    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (appNotification.type === "error") {
            api.error({
                message: appNotification.message,
                description: "",
                duration: 5,
                placement: "bottomRight" as NotificationPlacement,
            });
        }
        else if (appNotification.type === "success") {
            api.success({
                message: appNotification.message,
                description: "",
                duration: 5,
                placement: "bottomRight" as NotificationPlacement,
            });
        }
        else if (appNotification.type === "warning") {
            api.warning({
                message: appNotification.message,
                description: "",
                duration: 5,
                placement: "bottomRight" as NotificationPlacement,
            });
        }
        else if (appNotification.type === "info") {
            api.info({
                message: appNotification.message,
                description: "",
                duration: 5,
                placement: "bottomRight" as NotificationPlacement,
            });
        }
    }, [appNotification]);

    return <>{contextHolder}</>;
};

export default Notifications;
