import { useTranslation } from "react-i18next";
import { InputTextStyled } from "../../../../../style/Input";
import { FormRow } from "../../../../../style/form";
import { RowSpacer } from "../../../../../style/containers";
import { UserSubjectFormModel } from "./UserSubjectFormModel";
import InputFiscalCode from "../../../../Inputs/InputFiscalCode";

interface IUserSubjectFormProps {
    model: UserSubjectFormModel;
    onChange: (value: any, field: string) => void;
}

const UserSubjectForm = (props: IUserSubjectFormProps) => {
    const { model, onChange } = props;

    const { t } = useTranslation();

    return (
        <>
            <FormRow>
                <InputTextStyled
                    label={`${t("edit-subject-tab-data-name")} *`}
                    tooltip={t("edit-subject-tab-data-name-tooltip")}
                    placeholder={t("edit-subject-tab-data-name-placeholder")}
                    onChange={(name) => onChange(name, "name")}
                    value={model.name}
                />
                <RowSpacer />
                <InputTextStyled
                    label={`${t("edit-subject-tab-data-lastname")} *`}
                    tooltip={t("edit-subject-tab-data-lastname-tooltip")}
                    placeholder={t(
                        "edit-subject-tab-data-lastname-placeholder"
                    )}
                    onChange={(lastname) => onChange(lastname, "lastName")}
                    value={model.lastName}
                />
            </FormRow>

            <FormRow>
                <InputFiscalCode
                    onChange={(fiscalCode) =>
                        onChange(fiscalCode.toUpperCase(), "fiscalCode")
                    }
                    value={model.fiscalCode}
                    validation={{
                        state:
                            model.fiscalCodeValidation!.validateStatus ===
                            "success",
                        messageKey: model.fiscalCodeValidation!.messageKey,
                    }}
                />
                {/* <RowSpacer />
                <InputTextStyled
                    label={`${t("subject-contact-type-T")}`}
                    tooltip={t("subject-contact-type-T")}
                    placeholder={t("subject-contact-type-T")}
                    onChange={(phone) => onChange(phone, "phone")}
                    value={model.phone}
                    validateStatus={model.phoneValidation!.validateStatus}
                    help={model.phoneValidation!.messageKey}
                /> */}
            </FormRow>

            {/* <FormRow>
                <InputTextStyled
                    label={`${t("subject-contact-type-E")}`}
                    tooltip={t("subject-contact-type-E")}
                    placeholder={t("subject-contact-type-E")}
                    onChange={(email) => onChange(email, "email")}
                    value={model.email}
                    validateStatus={model.emailValidation!.validateStatus}
                    help={model.emailValidation!.messageKey}
                />
                <RowSpacer />
                <InputTextStyled
                    label={`${t("subject-contact-type-P")}`}
                    tooltip={t("subject-contact-type-P")}
                    placeholder={t("subject-contact-type-P")}
                    onChange={(pec) => onChange(pec, "pec")}
                    value={model.pec}
                    validateStatus={model.pecValidation!.validateStatus}
                    help={model.pecValidation!.messageKey}
                />
            </FormRow> */}
        </>
    );
};

export default UserSubjectForm;
