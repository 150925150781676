import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../../../redux/store";
import styled from "styled-components";
import { LoadingStatus } from "../AppraiserDetails/AppraiserDetailsPresentational";
import { GenericWrapper } from "../Commons/Wrappers";
import {
    TenantRatesByType,
    useCreateTenantRateConfigurationMutation,
    useLazyGetAllTenantRateConfigsQuery,
} from "../../../../redux/apiSpecifications/apiFesf";
import { InputTextStyled } from "../../../../style/Input";
import { t } from "i18next";
import TenantRates from "./TenantRates";
import { Pagination, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { ButtonConfirm } from "../../../Layout/Buttons";
import { RefreshInTabWrapper } from "../../../../style/DashbordWidgetWrappers";
import { IconLoading } from "../../../../config/icons";
import RatesClientModalHistory from "./RatesClientModalHistory";
import { useAuthorization } from "../../../../hooks/useAuthorization";

const RatesClientsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
`;

const RatesClientsContent = styled(GenericWrapper)`
    display: flex;
    width: auto;
    flex-direction: column;
    margin: 1em;
    align-items: flex-start;
    overflow-x: auto;

    .tenants-wrapper {
        margin: 2em 0;
        display: flex;
        flex-direction: column;
        gap: 3em;
    }

    .header-wrapper {
        display: flex;
        flex-direction: row;

        .filter-wrapper {
            input {
                width: 15em;
            }
        }
    }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 10em;
        align-self: center;
        flex: 1;

        .pagination-wrapper {
            flex: 1;
            display: flex;
            margin-bottom: 2em;
        }
    }
`;
type TenantData = {
    tenantData: TenantRatesByType;
    modified: boolean;
};

export type ChangeValueType = "rate-base" | "penalty-perc" | "cash-perc";

type ShowHistoryData = {
    show: boolean;
    id: number;
    name: string;
};

interface IRatesClientsProps {
    tabKey?: number;
}

const RatesClients = (props: IRatesClientsProps) => {
    const { tabKey } = props;

    const { getAuthorization } = useAuthorization();
    const activeRole = useSelector((state: RootState) => state.user.activeRole);

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

    const [error, setError] = useState<string | undefined>(undefined);
    const [tenantsRates, setTenantsRates] = useState<TenantData[]>();
    const [filter, setFilter] = useState<string>("");
    const [filteredTenantsRates, setFilteredTenantsRates] =
        useState<TenantData[]>();
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [ratesCode, setRatesCode] = useState<string[]>();

    const [canSave, setCanSave] = useState<boolean>(false);

    const [showHistory, setShowHistory] = useState<ShowHistoryData>({
        show: false,
        id: 0,
        name: "",
    });

    const [getAllTenantsRates] = useLazyGetAllTenantRateConfigsQuery();
    const [saveTenantRates] = useCreateTenantRateConfigurationMutation();

    const loadData = async () => {
        setLoadingStatus("loading");
        const response = await getAllTenantsRates({
            authorization: await getAuthorization(),
            activeRole: activeRole!,
        });

        if (response.isSuccess && response.data) {
            const newTenantsRates: TenantData[] = response.data.map(
                (tenant) => ({
                    tenantData: tenant,
                    modified: false,
                })
            );

            setTenantsRates(newTenantsRates);

            const newRatesCode =
                newTenantsRates[0].tenantData.tenantRateConfig?.map(
                    (c) => c.practiceTypeCode!
                );

            setRatesCode(newRatesCode);
        } else {
            // error
            // to do
        }
        setLoadingStatus("idle");
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (!tenantsRates) return;
        const lcFilter = filter.toLowerCase();
        const ftr = tenantsRates.filter((tenantRates) =>
            filter === ""
                ? true
                : tenantRates.tenantData.tenantName
                      ?.toLowerCase()
                      .includes(lcFilter)
        );

        setFilteredTenantsRates(ftr);
    }, [filter, tenantsRates]);

    useEffect(() => {
        validateData();
    }, [tenantsRates]);

    const handleOnChange = (
        tenantId: number | undefined,
        rateCode: string,
        value: string | number | undefined | null,
        type: ChangeValueType
    ) => {
        if (!tenantId) return;

        const updatedTenantsRates = tenantsRates?.map((tenantRates) => {
            if (tenantRates.tenantData.tenantId !== tenantId)
                return tenantRates;

            return {
                modified: true,
                tenantData: {
                    tenantId: tenantRates.tenantData.tenantId,
                    tenantName: tenantRates.tenantData.tenantName,
                    tenantRateConfig:
                        tenantRates.tenantData.tenantRateConfig?.map((rate) => {
                            if (rate.practiceTypeCode !== rateCode) return rate;

                            if (type === "rate-base") {
                                let newPerc =
                                    value === null
                                        ? null
                                        : rate.practiceTypePenaltyPerc;

                                if (
                                    rate.practiceTypePenaltyPerc === null &&
                                    value !== null
                                )
                                    newPerc = 0;

                                return {
                                    ...rate,
                                    practiceTypeBaseRateAmount: value,
                                    practiceTypePenaltyPerc: newPerc,
                                };
                            }

                            if (type === "penalty-perc")
                                return {
                                    ...rate,
                                    practiceTypePenaltyPerc: value,
                                };
                        }),
                },
            } as TenantData;
        });

        setTenantsRates(updatedTenantsRates);
    };

    const validateData = () => {
        let isValid = true;
        let atLeastOneModified = false;

        tenantsRates?.forEach((tenantRates) => {
            if (tenantRates.modified) atLeastOneModified = true;

            const ratesWithValue =
                tenantRates.tenantData.tenantRateConfig?.filter(
                    (rate) =>
                        rate.practiceTypeBaseRateAmount !== null &&
                        rate.practiceTypeBaseRateAmount !== 0
                );
            if ((ratesWithValue?.length || 0) < 1) isValid = false; // at least one rate with value
        });
        setCanSave(isValid && atLeastOneModified);
    };

    const handleOnSave = async () => {
        setLoadingStatus("loading");

        const tenantsFiltered =
            tenantsRates
                ?.filter((tenantRates) => tenantRates.modified)
                .map((TenantRates) => TenantRates.tenantData)
                .flat() || [];

        const tenantsToSave = tenantsFiltered.map((tenantRatesByType) => {
            return {
                ...tenantRatesByType,
                tenantRateConfig: tenantRatesByType.tenantRateConfig?.map(
                    (tenantRate) => {
                        return {
                            ...tenantRate,
                            practiceTypeBaseRateAmount: Number(
                                tenantRate.practiceTypeBaseRateAmount
                                    ?.toString()
                                    .replace(",", ".")
                            ),
                        };
                    }
                ),
            };
        });

        for (var tenant of tenantsToSave) {
            const result = await saveTenantRates({
                authorization: await getAuthorization(),
                tenantRatesByType: tenant,
                id: tenant.tenantId!,
                activeRole: activeRole!,
            });

            if (result) {
                // reset modified
                const newTenantsRates = tenantsRates?.map(
                    (tenantData) =>
                        ({
                            modified: false,
                            tenantData: tenantData.tenantData,
                        } as TenantData)
                );
                setTenantsRates(newTenantsRates);
            } else {
                // error
            }
        }

        setLoadingStatus("idle");
    };

    return (
        <RatesClientsWrapper>
            {loadingStatus === "loading" && (
                <RefreshInTabWrapper>
                    <div className="box" data-testid={""}>
                        {IconLoading}
                    </div>
                </RefreshInTabWrapper>
            )}

            {error === undefined && (
                <RatesClientsContent>
                    <div className="header-wrapper">
                        <div className="filter-wrapper">
                            <InputTextStyled
                                placeholder={t(
                                    "rates-clients-filter-placeholder"
                                )}
                                value={filter}
                                onChange={(txt) => setFilter(txt)}
                            />
                        </div>
                        <RatesHeader ratesCode={ratesCode} />
                    </div>
                    <div className="tenants-wrapper">
                        {filteredTenantsRates
                            ?.slice((page - 1) * pageSize, page * pageSize)
                            .map((tenantRates, i) => (
                                <TenantRates
                                    key={i}
                                    tenantData={tenantRates.tenantData}
                                    onChange={handleOnChange}
                                    onShowHistory={(id: number, name: string) =>
                                        setShowHistory({ show: true, id, name })
                                    }
                                />
                            ))}
                    </div>
                    <div className="footer">
                        <div className="pagination-wrapper">
                            <Pagination
                                current={page}
                                defaultPageSize={pageSize}
                                onChange={setPage}
                                total={filteredTenantsRates?.length}
                            />
                        </div>
                        <ButtonConfirm
                            disabled={!canSave}
                            onClick={handleOnSave}
                        >
                            SALVA
                        </ButtonConfirm>
                    </div>
                </RatesClientsContent>
            )}

            {showHistory.show && (
                <RatesClientModalHistory
                    tenantId={showHistory.id}
                    tenantName={showHistory.name}
                    onClose={() =>
                        setShowHistory({ show: false, id: 0, name: "" })
                    }
                />
            )}
        </RatesClientsWrapper>
    );
};

const RateHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 10em;

    .rate-name {
        width: 5em;
        margin-right: 1em;
        overflow: hidden;

        .rate-rate-text {
            font-size: 0.9em;
            text-transform: uppercase;
            text-align: center;
        }
    }
`;

interface IRateHeaderProps {
    ratesCode: string[] | undefined;
}

export const RatesHeader = (props: IRateHeaderProps) => {
    const { t } = useTranslation();
    const { ratesCode } = props;

    return (
        <RateHeaderWrapper>
            {ratesCode?.map((rateCode) => (
                <div key={rateCode} className="rate-name">
                    <div className="rate-rate-text">
                        <Tooltip title={t(`rate-name-tooltip-${rateCode}`)}>
                            {t(`rate-name-label-${rateCode}`)}
                        </Tooltip>
                    </div>
                </div>
            ))}
        </RateHeaderWrapper>
    );
};
export default RatesClients;
