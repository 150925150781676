import { useTranslation } from "react-i18next";
import {
  AppraisalBranchCodeTypes,
  AppraisalTypeTypes,
} from "../../config/const";
import { SelectStyled } from "../../style/Input";
import { selectWithAddNew as testId } from "../../config/testIds";
import { Button, Divider, Input, InputRef, Space } from "antd";
import { PlusOneOutlined } from "@mui/icons-material";
import { SelectPair } from "../../types/common.types";
import { RefObject, useMemo, useRef, useState } from "react";
import { ButtonConfirm } from "../Layout/Buttons";
import styled from "styled-components";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5em 0.75em;
  gap: 0.5em;

  input {
    height: 2em;
  }

  button {
    height: 2em;
    width: 2em;
  }
`;

interface ISelectWithAddNewProps {
  items: SelectPair[];
  onSelect: (index: number) => void;
  onAddNew: (value: string) => void;
  value: any;
  dataTestId?: string;
  label?: string;
  tooltip?: string;
}

const SelectWithAddNew = (props: ISelectWithAddNewProps) => {
  const { onSelect, onAddNew, value, items, dataTestId, label } = props;

  const [newOption, setNewOption] = useState<string>("");

  const handleAddNew = () => {
    onAddNew(newOption);
    setNewOption("");
  };

  return (
    <SelectStyled
      label={label}
      onChange={onSelect}
      value={value}
      dataTestId={props.dataTestId || testId.container}
      showSearch
      filterOption={(input, option) => (option?.label ?? "").includes(input)}
      dropdownRender={(menu) => (
        <>
          {menu}
          <InputWrapper>
            <Input
              placeholder="aggiungi nuovo"
              value={newOption}
              onChange={(e) => setNewOption(e.target.value)}
              data-testid={testId.inputNew}
            />
            <ButtonConfirm
              size="small"
              onClick={handleAddNew}
              dataTestId={testId.buttonAdd}
            >
              +
            </ButtonConfirm>
          </InputWrapper>
        </>
      )}
      options={items}
    />
  );
};

export default SelectWithAddNew;
