import { useSelector } from "react-redux";
import { useState } from "react";
import { RootState } from "../../../../redux/store";
import styled from "styled-components";
import { TenantRatesByType } from "../../../../redux/apiSpecifications/apiFesf";
import { InputTextStyled } from "../../../../style/Input";
import { ButtonConfirm } from "../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import { ChangeValueType } from "./RatesClients";
import dekra from "../../../../style/dekra";
import RatesClientModalAppraisers from "./RatesClientModalAppraisers";

const TenantRatesWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .tenant-name {
    .tenant-name-txt {
      width: 15em;
      text-align: center;
      background-color: #f5f5f5;
      padding: 0.5em 0;
    }

    button {
      width: 15em;
    }

    .show-historial {
      color: ${dekra.primaryColor};
      width: 15em;
      margin-top: 0.5em;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 0.9em;
    }
  }

  .rates-names {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 10em;
    padding-left: 2em;

    .rate-name {
      height: 1.8em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .rates-value-wrapper {
    display: flex;
    flex-direction: row;

    .rates-values {
      display: flex;
      flex-direction: column;
      gap: 1em;

      .rate-value {
        height: 1.8em;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .value-input {
      border-radius: 0.5em;
      width: 5em;
      height: 1.8em;
      margin-right: 1em;
      text-align: right;
      cursor: pointer;

      &.readonly {
        background-color: #ccc;
      }

      &.percent {
        border-color: #f96767;
      }
    }
  }
`;

interface ITenantRatesProps {
  tenantData?: TenantRatesByType;
  onChange: (
    idTenant: number,
    rateCode: string,
    value: string | number | undefined | null,
    type: ChangeValueType
  ) => void;
  onShowHistory: (id: number, name: string) => void;
}

const TenantRates = (props: ITenantRatesProps) => {
  const { tenantData, onChange, onShowHistory } = props;
  const { t } = useTranslation();
  const { tenantId, tenantName, tenantRateConfig } = tenantData || {};
  const [showSearchAppraiser, setShowSearchAppraiser] = useState(false);

  const { activeRole } = useSelector((state: RootState) => state.user);

  const handleOnChange = (
    rateCode: string,
    value: string | number | undefined | null,
    type: ChangeValueType
  ) => {
    if (!tenantId) return;

    onChange(tenantId, rateCode, value, type);
  };

  const handleDoubleClick = (
    rateCode: string,
    value: string | undefined | null,
    type: ChangeValueType
  ) => {
    if (!tenantId) return;

    const newValue = value === null || value === undefined ? "0" : null;
    onChange(tenantId, rateCode, newValue, type);
  };

  const handleShowHistory = () => onShowHistory(tenantId!, tenantName!);

  return (
    <TenantRatesWrapper>
      <div className="tenant-name">
        {activeRole === "NF" && (
          <ButtonConfirm onClick={() => setShowSearchAppraiser(true)}>
            {tenantName || ""}
          </ButtonConfirm>
        )}
        {activeRole !== "NF" && (
          <div className="tenant-name-txt">{tenantName || ""}</div>
        )}

        <div className="show-historial" onClick={handleShowHistory}>
          {t("rates-clients-shwow-historial-label")}
        </div>
      </div>
      <div className="rates-names">
        <div className="rate-name">{t("rate-name-base-rate")}</div>
        <div className="rate-name">{t("rate-name-penalty-perc")}</div>
      </div>

      <div className="rates-value-wrapper">
        {tenantRateConfig?.map((rate, i) => (
          <div key={i} className="rates-values">
            <div className="rate-value">
              <ValueButton
                value={rate.practiceTypeBaseRateAmount}
                type="number"
                onChange={(val: string) =>
                  handleOnChange(
                    rate.practiceTypeCode!,
                    val,
                    "rate-base"
                  )
                }
                onDoubleClick={() =>
                  handleDoubleClick(
                    rate.practiceTypeCode!,
                    rate.practiceTypeBaseRateAmount?.toString(),
                    "rate-base"
                  )
                }
              />
            </div>
            <div className="rate-value">
              <ValueButton
                value={rate.practiceTypePenaltyPerc}
                type="percent"
                onChange={(val: string) =>
                  handleOnChange(
                    rate.practiceTypeCode!,
                    parseInt(val) || 0,
                    "penalty-perc"
                  )
                }
              />
            </div>
          </div>
        ))}
      </div>
      {showSearchAppraiser && (
        <RatesClientModalAppraisers
          onCancel={() => setShowSearchAppraiser(false)}
        />
      )}
    </TenantRatesWrapper>
  );
};

type IValueButtonProps = {
  value: number | string | undefined;
  type: "number" | "percent";
  onChange: (val: string) => void;
  onDoubleClick?: () => void;
};

const ValueButton = (props: IValueButtonProps) => {
  const { type, onChange, onDoubleClick } = props;

  const readonly = (props.value?.toString().length || 0) < 1;
  let value = "";

  if (props.value?.toString()) {
    value = props.value?.toString();
    if (type === "percent") value += "%";
  }

  const handleOnChange = (val: string) => {
    if (type === "number") {

      if (val === "") onChange("0");
      else if (
        val.length === 2 &&
        val.startsWith("0") &&
        !val.endsWith(",")
      ) {
        onChange(val.substring(1));
      } else if (/^\d+(\,\d{0,})?$/.test(val)) {
        onChange(val);
      }
    } else if (type === "percent") {

      onChange(val.replace("%", ""));
    }
  };


  return (
    <InputTextStyled
      classNameInput={`value-input ${readonly ? "readonly" : ""} ${
        type === "percent" ? "percent" : ""
      }`}
      value={value?.replace(".", ",")}
      readOnly={readonly}
      onChange={handleOnChange}
      onDoubleClick={onDoubleClick}
      onClick={(e) => e.target.select()}
    />
  );
};

export default TenantRates;
