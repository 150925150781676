import { useTranslation } from "react-i18next";
import { Vehicle } from "../../../../../../../redux/apiSpecifications/apiClaims";
import { RowSpacer } from "../../../../../../../style/containers";
import { FormRow } from "../../../../../../../style/form";
import { InputTextStyled } from "../../../../../../../style/Input";
import SelectVehicleType from "../../../../../../Inputs/SelectVehicleType";
import { VehicoleTypeType } from "../../../../../../../types/claim.types";

interface IInsuredPropertyDataFormProps {
  model: Vehicle;
  disableForm?: boolean;
  onChange?: (value: any, field: string) => void;
}

const InsuredPropertyDataForm = ({
  model,
  disableForm,
  onChange,
}: IInsuredPropertyDataFormProps) => {
  const { t } = useTranslation();

  const handleOnChange = (value: any, field: string) => {
    if (onChange) onChange(value, field);
  };

  return (
    <FormRow>
      <InputTextStyled
        label={t("LABELS.plate-number")}
        placeholder={t("PLACEHOLDERS.plate-number")}
        onChange={(value) =>
          handleOnChange(value, "plateNumber")
        }
        required={true}
        value={model.plateNumber}
        disabled={true}
      />

      <RowSpacer />

      <InputTextStyled
        label={t("LABELS.chassis-number")}
        placeholder={t("PLACEHOLDERS.chassis-number")}
        onChange={(value) =>
          handleOnChange(value, "chassisNumber")
        }
        required={true}
        value={model.chassisNumber}
        disabled={disableForm}
      />

      <RowSpacer />

      <SelectVehicleType
        label={t("LABELS.vehicle-type")}
        required={true}
        value={model.type as VehicoleTypeType}
        onChange={(value) => handleOnChange(value, "type")}
        disabled={disableForm}
      />

      <RowSpacer />

      <InputTextStyled
        label={t("LABELS.brand")}
        placeholder={t("PLACEHOLDERS.brand")}
        onChange={(value) => handleOnChange(value, "brand")}
        required={true}
        value={model.brand}
        disabled={disableForm}
      />

      <RowSpacer />

      <InputTextStyled
        label={t("LABELS.model")}
        placeholder={t("PLACEHOLDERS.model")}
        onChange={(value) => handleOnChange(value, "model")}
        required={true}
        value={model.model}
        disabled={disableForm}
      />
    </FormRow>
  );
};

export default InsuredPropertyDataForm;
