import styled from "styled-components";
import { WidgetWrapper } from "../../../../style/DashbordWidgetWrappers";
import { G1SummaryPractice } from "../../../../redux/apiSpecifications/apiFesf";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { CellWrapperWithCursorPointer } from "../../../../style/CellWrapper";
import { useDispatch } from "react-redux";
import { addTab } from "../../../../redux/features/mainTabsSlice";

const PracticeListWrapper = styled(WidgetWrapper)`
  display: flex;
  align-items: center;
  overflow: auto;
  margin-top: 1em;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0 5px #aaa;
  padding: 1em 2em;

  .MuiPaper-root {
    box-shadow: none;
    width: 95%;
  }

  .MuiInputBase-root {
    &:before {
      border-bottom: 1px solid white;
    }
  }

  .MuiToolbar-root > .MuiBox-root {
    justify-content: flex-start;
  }

  .MuiInputBase-input {
    color: #555;
  }

  .MuiTableCell-root {
    padding: 0.5em 1em;
  }
`;

export type PracticeItem = {
  id?: number;
  reportName?: string;
  brokeredCompany?: string;
  practiceNumber?: string;
  practiceNumberCompany?: string;
  claimNumber?: string;
  plateNumber?: string;
  originalFeeTypeCode?: string;
  originalFeeType?: string;
  feeTypeCode?: string;
  feeType?: string;
  appraisalTypeCode?: string;
  appraisalType?: string;
  practiceDate?: string;
  conclusionDate?: string;
  practiceRateAmount?: number;
  practiceFeeAmount?: number;
  inAuditingDate?: string;
  isAccountingVerify?: boolean;
  accountingVerifyDescription: string;
};

export interface IPracticeListProps {
  practices: G1SummaryPractice[];
  gridArea?: string;
}

const PracticeList = ({ practices, gridArea }: IPracticeListProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const data = useMemo(
    () =>
      practices.map((practice) => {
        return {
          ...practice,
          originalFeeType: practice.originalFeeTypeCode
            ? t(`fee-type-code-${practice.originalFeeTypeCode!}`)
            : "",
          feeType: practice.feeTypeCode
            ? t(`fee-type-code-${practice.feeTypeCode!}`)
            : "",
          appraisalType: t(
            `appraisals-details-tab-details-appraisals-expertise-type-${practice.appraisalTypeCode!}`
          ),
          accountingVerifyDescription:
            practice.isAccountingVerify === true
              ? t("switch-yes")
              : t("switch-no"),
        } as PracticeItem;
      }) || [],
    [practices]
  );

  const columns = useMemo<MRT_ColumnDef<PracticeItem>[]>(
    () => [
      {
        accessorKey: "reportName",
        header: t("dashboard-g-practice-list-report-name-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapperWithCursorPointer
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.reportName}
          </CellWrapperWithCursorPointer>
        ),
      },
      {
        accessorKey: "brokeredCompany",
        header: t("dashboard-g-practice-list-brokered-company-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapperWithCursorPointer
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.brokeredCompany}
          </CellWrapperWithCursorPointer>
        ),
      },
      {
        accessorKey: "practiceNumber",
        header: t("dashboard-g-practice-list-practice-number-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapperWithCursorPointer
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.practiceNumber}
          </CellWrapperWithCursorPointer>
        ),
      },
      {
        accessorKey: "practiceNumberCompany",
        header: t(
          "dashboard-g-practice-list-practice-number-company-column-header"
        ),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapperWithCursorPointer
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.practiceNumberCompany}
          </CellWrapperWithCursorPointer>
        ),
      },
      {
        accessorKey: "claimNumber",
        header: t("dashboard-g-practice-list-claim-number-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapperWithCursorPointer
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.claimNumber}
          </CellWrapperWithCursorPointer>
        ),
      },
      {
        accessorKey: "plateNumber",
        header: t("dashboard-g-practice-list-plate-number-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapperWithCursorPointer
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.plateNumber}
          </CellWrapperWithCursorPointer>
        ),
      },
      {
        accessorKey: "originalFeeType",
        header: t("dashboard-g-practice-list-original-fee-type-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapperWithCursorPointer
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.originalFeeType}
          </CellWrapperWithCursorPointer>
        ),
      },
      {
        accessorKey: "feeType",
        header: t("dashboard-g-practice-list-fee-type-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapperWithCursorPointer
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.feeType}
          </CellWrapperWithCursorPointer>
        ),
      },
      {
        accessorKey: "appraisalType",
        header: t("dashboard-g-practice-list-appraisal-type-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapperWithCursorPointer
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.appraisalType}
          </CellWrapperWithCursorPointer>
        ),
      },
      {
        accessorKey: "practiceDate",
        header: t("dashboard-g-practice-list-practice-date-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapperWithCursorPointer
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.practiceDate}
          </CellWrapperWithCursorPointer>
        ),
      },
      {
        accessorKey: "conclusionDate",
        header: t("dashboard-g-practice-list-conclusion-date-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapperWithCursorPointer
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.conclusionDate}
          </CellWrapperWithCursorPointer>
        ),
      },
      {
        accessorKey: "practiceRateAmount",
        header: t(
          "dashboard-g-practice-list-practice-rate-amount-column-header"
        ),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapperWithCursorPointer
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.practiceRateAmount}
          </CellWrapperWithCursorPointer>
        ),
      },
      {
        accessorKey: "practiceFeeAmount",
        header: t(
          "dashboard-g-practice-list-practice-fee-amount-column-header"
        ),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapperWithCursorPointer
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.practiceFeeAmount}
          </CellWrapperWithCursorPointer>
        ),
      },
      {
        accessorKey: "accountingVerifyDescription",
        header: t("dashboard-g-practice-list-is-accounting-verify-column-header"),
        size: 100,
        Cell: ({ row }) => (
          <CellWrapperWithCursorPointer
            onClick={() => handleSelectRow(row.original)}
          >
            {row.original.accountingVerifyDescription}
          </CellWrapperWithCursorPointer>
        ),
      },
    ],
    [practices]
  );

  const handleSelectRow = (row: PracticeItem) => {
    dispatch(
      addTab({
        key: 0,
        label: row.practiceNumber!,
        type: "appraisal-details",
        externalData: {
          idPractice: row.id,
        },
      })
    );
  };

  return (
    <PracticeListWrapper gridArea={gridArea}>
      {data.length > 0 && (
        <MaterialReactTable
          muiTablePaperProps={{ style: { width: "100%" } }}
          columns={columns}
          data={data}
          enableDensityToggle={false}
          enableColumnOrdering
          muiTableBodyCellProps={{
            sx: {
              textAlign: "left",
            },
          }}
          onPaginationChange={setPagination}
          state={{ pagination }}
          autoResetPageIndex={false}
        />
      )}
    </PracticeListWrapper>
  );
};

export default PracticeList;
