import { useTranslation } from "react-i18next";
import { SelectStyled } from "../../style/Input";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../redux/features/userSlice";
import { RootState } from "../../redux/store";
import styled from "styled-components";
import i18next from "i18next";

const SelectLanguageWrapper = styled.div`
  .ant-form-item-row {
    flex-direction: column;
    align-items: stretch;
  }

  .ant-form-item-label {
    text-align: left;
  }
`;

const SelectLanguage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const language = useSelector((state: RootState) => state.user.language);

  const handleOnChangeLanguage = (language: string) => {
    dispatch(setLanguage(language));
    i18next.changeLanguage(language);
  };

  return (
    <SelectLanguageWrapper>
      <SelectStyled
        label={t("select-language")}
        onChange={handleOnChangeLanguage}
        value={language}
        options={[
          {
            value: "it",
            label: "Italiano",
          },
          {
            value: "en",
            label: "English",
          },
        ]}
        dataTestId={""}
      />
    </SelectLanguageWrapper>
  );
};

export default SelectLanguage;
