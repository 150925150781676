import { useEffect, useState } from "react";
import { UserSubjectFormModel } from "./UserSubjectFormModel";
import { UserItem } from "../../UserItem";
import { updateObject } from "../../../../../utils/updateObject";
import { UserUICommunication } from "../../../../../types/common.types";
import { logUnhandledException } from "../../../../../utils/console";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import {
    InputFieldValidation,
    formalValidationFiscalCode,
} from "../../../../../validation/subjectValidation";
import {
    checkEmailFormat,
    checkTelephone,
    isNotEmpty,
} from "../../../../../validation/common";
import { Subject, useLazyRetriveSubjectDataQuery } from "../../../../../redux/apiSpecifications/apiSubjects";

type UserSubjectFormState = {
    data: UserSubjectFormModel;
    canSubmit: boolean;
};

const DEFAULT_SUCCESS_VALIDATION: InputFieldValidation = {
    validateStatus: "success",
    messageKey: "",
};

const useUserSubjectForm = (user: UserItem) => {
    const checkIfCanSubmit = (data: UserSubjectFormModel) => {
        const {
            name,
            lastName,
            fiscalCodeValidation,
            phoneValidation,
            emailValidation,
            pecValidation,
        } = data;

        return (
            isNotEmpty(name) &&
            isNotEmpty(lastName) &&
            fiscalCodeValidation.validateStatus === "success" &&
            phoneValidation.validateStatus === "success" &&
            emailValidation.validateStatus === "success" &&
            pecValidation.validateStatus === "success"
        );
    };

    const validateForm = (data: UserSubjectFormModel) => {
        const { fiscalCode, phone, email, pec } = data;

        const neEmail = isNotEmpty(email);
        const nePec = isNotEmpty(pec);
        const nePhone = isNotEmpty(phone);

        const fiscalCodeValidation = formalValidationFiscalCode(
            fiscalCode || ""
        );

        const phoneValidation: InputFieldValidation =
            !nePhone || checkTelephone(phone || "")
                ? DEFAULT_SUCCESS_VALIDATION
                : {
                      validateStatus: "error",
                      messageKey: t("subject-contact-invalid-phone"),
                  };

        const emailValidation: InputFieldValidation =
            !neEmail || checkEmailFormat(data.email!)
                ? DEFAULT_SUCCESS_VALIDATION
                : {
                      validateStatus: "error",
                      messageKey: t("subject-contact-invalid-email"),
                  };

        const pecValidation: InputFieldValidation =
            !nePec || checkEmailFormat(data.pec!)
                ? DEFAULT_SUCCESS_VALIDATION
                : {
                      validateStatus: "error",
                      messageKey: t("subject-contact-invalid-pec"),
                  };

        data = updateObject(data, fiscalCodeValidation, "fiscalCodeValidation");
        data = updateObject(data, phoneValidation, "phoneValidation");
        data = updateObject(data, emailValidation, "emailValidation");
        data = updateObject(data, pecValidation, "pecValidation");

        return data;
    };

    const initState = (subject: Subject | null) => {
        let data: UserSubjectFormModel = {
            fiscalCodeValidation: DEFAULT_SUCCESS_VALIDATION,
            phoneValidation: DEFAULT_SUCCESS_VALIDATION,
            emailValidation: DEFAULT_SUCCESS_VALIDATION,
            pecValidation: DEFAULT_SUCCESS_VALIDATION,
        };

        if (subject !== null) {
            data.idSubject = subject.id;
            data.name = subject.name;
            data.lastName = subject.lastname;
            data.fiscalCode = subject.fiscalCode;
            data.phone = subject.contacts!.find((_) => _.type === "T")?.value;
            data.email = subject.contacts!.find((_) => _.type === "E")?.value;
            data.pec = subject.contacts!.find((_) => _.type === "P")?.value;
        } else {
            data.name = user.firstname;
            data.lastName = user.surname;
        }

        data = validateForm(data);

        return {
            data,
            canSubmit: checkIfCanSubmit(data),
        } as UserSubjectFormState;
    };

    const [state, setState] = useState<UserSubjectFormState>(initState(null));

    const { t } = useTranslation();

    const { authorization } = useSelector((state: RootState) => state.user);

    const [userUICommunications, setUserUICommunications] =
        useState<UserUICommunication>({
            action: "idle",
        } as UserUICommunication);

    const [retrieveSubject] = useLazyRetriveSubjectDataQuery();

    useEffect(() => {
        if (user.idSubject !== null) {
            loadData(user.idSubject!);
        }
    }, []);

    const loadData = async (idSubject: number) => {
        try {
            setUserUICommunications({
                action: "load",
                status: "in-progress",
            } as UserUICommunication);

            const response = await retrieveSubject({
                id: idSubject,
                authorization: authorization,
            });

            if (response.data) {
                setState(initState(response.data as Subject));

                setUserUICommunications({
                    action: "idle",
                } as UserUICommunication);
            } else {
                const status = "";
                setUserUICommunications({
                    action: "load",
                    status: "done",
                    result: "error",
                    errorDetails: t(`http-error-${status}`),
                } as UserUICommunication);
            }
        } catch (err) {
            const status = "";

            setUserUICommunications({
                action: "load",
                status: "done",
                result: "error",
                errorDetails: t(`http-error-${status}`),
            } as UserUICommunication);

            logUnhandledException(err);
        }
    };

    const handleDataChange = (value: any, field: string) => {
        const { data } = state;

        let updatedData = updateObject(
            data,
            value === "" ? undefined : value,
            field
        );

        updatedData = validateForm(updatedData);

        setState({
            ...state,
            data: { ...updatedData },
            canSubmit: checkIfCanSubmit(updatedData),
        });
    };

    return {
        data: state.data,
        canSubmit: state.canSubmit,
        userUICommunications,
        handleDataChange,
    };
};

export default useUserSubjectForm;
