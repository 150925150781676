import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useTranslation } from "react-i18next";
import { Popover, Segmented } from "antd";
import { useMemo, useState } from "react";
import { Tenant } from "../../redux/apiSpecifications/apiFesf";
import { toggleSelectedTenants } from "../../redux/features/userSlice";
import { SwitchStyled } from "../../style/Input";
import { selectClient as testId } from "../../config/testIds";

const Wrapper = styled.div`
  display: flex;
  height: 2em;
  align-self: flex-end;
  padding-bottom: 5px;
  gap: 0.5em;
  font-size: 0.9em;
  padding-bottom: 1em;
`;

const TenantsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
  color: #fff;
  cursor: pointer;
`;

const TenantWrapper = styled.div`
  line-height: 1em;
`;

const TenantsLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1em;
  text-transform: uppercase;
  color: #fff;
`;

const SelectableTenantsTitle = styled.div`
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0.5em 1em;
  flex: 1;
`;

const SelectableTenantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const SelectableTenantWrapper = styled.div`
  display: flex;
  align-items: center;

  .ant-row {
    justify-content: space-between;

    .ant-form-item-control {
      align-items: flex-end;
    }
  }
`;

const SelectClient = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const availableTenants =
    useSelector((state: RootState) => state.user.userDetails?.tenants) || [];
  const selectedTenants =
    useSelector((state: RootState) => state.user.selectedTenants) || [];

  const labelId = `appraisals-select-client-label-${
    selectedTenants.length > 1 ? "plural" : "single"
  }`;
  const selectTenantOptions = useMemo(
    () => [
      t("appraisals-select-client-selected"),
      t("appraisals-select-client-not-selected"),
    ],
    [availableTenants]
  );

  const handleToggleSelectTenant = (id: number) => {
    const tenant = availableTenants.find((t) => t.id === id);
    if (tenant) dispatch(toggleSelectedTenants(tenant));
  };

  const SelectableTenants = () => (
    <SelectableTenantsWrapper>
      {availableTenants.map((tenant) => {
        const isSelected =
          selectedTenants.find((st) => st.id === tenant.id) !== undefined;

        return (
          <SelectableTenantWrapper key={tenant.id}>
            <SwitchStyled
              label={tenant.companyName}
              unCheckedChildren={t("switch-no")}
              checkedChildren={t("switch-yes")}
              onChange={() => handleToggleSelectTenant(tenant.id!)}
              checked={isSelected}
              dataTestId={`${testId.selectableTenantSwitch}${tenant.id}`}
            />
          </SelectableTenantWrapper>
        );
      })}
    </SelectableTenantsWrapper>
  );

  return (
    <Wrapper data-testid={testId.container}>
      <TenantsLabel>{t(labelId)}</TenantsLabel>
      <Popover
        content={SelectableTenants}
        title={
          <SelectableTenantsTitle>
            {t("appraisals-select-client-popover-title")}
          </SelectableTenantsTitle>
        }
        trigger={"click"}
        open={open}
        onOpenChange={(open) => setOpen(open)}
      >
        <TenantsWrapper data-testid={testId.openPopover}>
          {selectedTenants.map((st) => {
            return <TenantWrapper key={st.id}>{st.companyName}</TenantWrapper>;
          })}
        </TenantsWrapper>
      </Popover>
    </Wrapper>
  );
};

export default SelectClient;
