import { useTranslation } from "react-i18next";
import { FormItemStyled, InputTextStyled } from "../../../../../style/Input";
import { FormRow } from "../../../../../style/form";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { Alert, Tag } from "antd";
import { RowSpacer } from "../../../../../style/containers";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import {
  ExcludedPractice,
  useLazyExcludePracticeFromReportQuery,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

const PracticeExcludeInputWrapper = styled.div`
  flex-direction: column;
  height: 100%;
  width: 100% .body {
    padding: 1em 1em;
    display: block;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
  }
`;

const ExcludeButtonWrapper = styled.div`
  // display: flex;
  // justify-content: flex-end;
  // align-self: flex-end;
  margin-top: 32px;
`;

const TagsWrapper = styled.div`
  .tag {
    margin-top: 0.5em;
    font-size: 1.2em;
  }
`;

interface IPracticeExcludeInputProps {
  idTenant: number;
  startDate: string;
  endDate: string;
  excludedPractices: ExcludedPractice[];
  onAddPractice: (practice: ExcludedPractice) => void;
  onRemovePractice: (practiceId: number) => void;
}

const PracticeExcludeInput = ({
  idTenant,
  startDate,
  endDate,
  excludedPractices,
  onAddPractice,
  onRemovePractice,
}: IPracticeExcludeInputProps) => {
  const { t } = useTranslation();

  const [excludePracticeFromReport] = useLazyExcludePracticeFromReportQuery();

  const { authorization } = useSelector((state: RootState) => state.user);

  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [loadingStatus, setLoadingStatus] = useState<
    "idle" | "loading" | "refreshing" | "load-in-progress"
  >("idle");

  const handleExcludePractice = async () => {
    setLoadingStatus("loading");

    console.info(idTenant)

    const response = await excludePracticeFromReport({
      authorization: authorization,
      practiceNumber: inputValue,
      tenant: idTenant,
      startDate: startDate,
      endDate: endDate,
    });

    const { data, error, isSuccess, isError } = response;

    if (isSuccess && data) {
      onAddPractice(data as ExcludedPractice);

      setInputValue("");
    } else if (isError) {
      setError(t("practice-not-found-message-error")!);
    } else {
      console.log("unknown error");
    }

    setLoadingStatus("idle");
  };

  const handleOnChange = (text: string) => {
    if (error) {
      setError(undefined);
    }

    setInputValue(text);
  };

  const disablePracticeNumberInput: boolean = useMemo(() => {
    return loadingStatus === "loading";
  }, [loadingStatus]);

  const disableExcludeButton: boolean = useMemo(() => {
    return inputValue.trim() === "" || loadingStatus === "loading";
  }, [inputValue, loadingStatus]);

  return (
    // <PracticeExcludeInputWrapper>
    <FormRow>
      <FormItemStyled label={t("practice-exclude-practice-number-label")}>
        <InputTextStyled
          value={inputValue}
          onChange={handleOnChange}
          disabled={disablePracticeNumberInput}
        />

        {error && (
          <Alert style={{ marginTop: 8 }} message={error} type="error" />
        )}

        <TagsWrapper>
          {excludedPractices.map((practice) => (
            <span key={practice.id!}>
              <Tag
                closable
                onClose={(e) => {
                  e.preventDefault();

                  onRemovePractice(practice.id!);
                }}
                className="tag"
              >
                {practice.practiceNumber}
              </Tag>
            </span>
          ))}
        </TagsWrapper>
      </FormItemStyled>

      <RowSpacer />

      <ExcludeButtonWrapper>
        <ButtonConfirm
          onClick={handleExcludePractice}
          disabled={disableExcludeButton}
        >
          {t("button-exclude")!}
        </ButtonConfirm>
      </ExcludeButtonWrapper>
    </FormRow>
    // </PracticeExcludeInputWrapper>
  );
};

export default PracticeExcludeInput;
