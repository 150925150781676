import { useEffect, useState } from "react";
import styled from "styled-components";
import { getAzureMapAddressConfig } from "../../../../../config/azureConfig";
import i18next from "i18next";
import { ReadOnlyPractice } from "../../../../../redux/apiSpecifications/apiCrud";

const MapContainerWrapper = styled.div`
  flex-direction: column;
  flex: 1;

  max-height: 2.5em;
  transition: max-height 1s;

  &.open {
    max-height: 100em;
    min-height: 30em;
  }
`;

const MapBaseWrapper = styled.div`
  width: 100%;
  height: 100%;

  .popup-close {
    color: white;
  }
`;

const getLanguageCode = (language: string) =>
  language === "it" ? "it-IT" : language === "en" ? "en-US" : "en-US";

const buildHtmlContent = (marker: MapMarker) => {
  if (marker.type === "Appraiser") {
    // const appraiser = data.info as SelectedAppraiser;

    let imageSrc;

    // if (appraiser.isPosition) {
    //   imageSrc = appraiser.isInternal
    //     ? `/images/markers/new/appraisers/internal-${appraiser.saturationClusterCode}-live-big.png`
    //     : `/images/markers/new/appraisers/external-${appraiser.saturationClusterCode}-live-big.png`;
    // } else {
    //   imageSrc = appraiser.isInternal
    //     ? `/images/markers/new/appraisers/internal-${appraiser.saturationClusterCode}-big.png`
    //     : `/images/markers/new/appraisers/external-${appraiser.saturationClusterCode}-big.png`;
    // }

    return `<img src="${imageSrc}"/>`;
  } else {
    // const practice = data.info as SelectedPractice;

    return `<img src="/images/markers/practiceWithAppointment.png"/>`;
  }
};

export type MapMarker = {
  longitude: number;
  latitude: number;
  type: "Appraiser" | "Practice";
  // data: any; // MarkerData<SelectedAppraiser | SelectedPractice>;
  // onClick?: (m: Marker) => void;
};

interface IAppraisalMapProps {
  practice: ReadOnlyPractice | undefined;
  // markers: MapMarker[];
}

const AppraisalMap = ({ practice }: IAppraisalMapProps) => {
  const wrapperId: string = `appraisal-map-${practice?.id}`;

  const [map, setMap] = useState<any>();
  const [atlas, setAtlas] = useState<any>();

  useEffect(() => {
    const azureMapAddressConfig = getAzureMapAddressConfig();

    import("azure-maps-control").then((_atlas) => {
      const mapInstance = new _atlas.Map(wrapperId, {
        language: getLanguageCode(i18next.language),
        authOptions: {
          authType: _atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: azureMapAddressConfig.subscriptionKey,
        },
        showLogo: false,
        showFeedbackLink: false,
        style: "grayscale_light",
      });

      setMap(mapInstance);
      setAtlas(_atlas);
    });
  }, []);

  useEffect(() => {
    if (!map || !atlas || !practice) return;

    map.popups.clear();
    map.markers.clear();

    const markers: MapMarker[] = [];
    const center: number[] = [0, 0];

    if (practice) {
      const appraisalLocationAddress = practice.appraisalLocation?.address;

      if (
        appraisalLocationAddress &&
        appraisalLocationAddress.latitude &&
        appraisalLocationAddress.longitude
      ) {
        markers.push({
          latitude: appraisalLocationAddress.latitude,
          longitude: appraisalLocationAddress.longitude,
          type: "Practice",
        });

        center[0] = appraisalLocationAddress.longitude;
        center[1] = appraisalLocationAddress.latitude;
      }
    }

    map.events.add("ready", () => {
      const popup = new atlas.Popup({
        position: [0, 0],
        pixelOffset: [0, -18],
      });

      markers.forEach((marker) => {
        const htmlMarker = new atlas.HtmlMarker({
          htmlContent: buildHtmlContent(marker),
          position: [marker.longitude, marker.latitude],
        });

        map.markers.add(htmlMarker);

        // map.events.add("click", htmlMarker, () => {
        //   if (marker.data.type === "Practice") {
        //     onSelectPractice(marker.data.info.id);
        //   } else if (marker.data.type === "Appraiser") {
        //     const appraiserInfo = marker.data.info as SelectedAppraiser;

        //     onSelectAppraiser(appraiserInfo.id, appraiserInfo.idZone!);
        //   }
        // });

        // map.events.add("mousemove", htmlMarker, () => {
        //   const html: string[] = ['<div style="padding:10px;">'];

        //   if (marker.type === "Practice") {
        //     //const practice = marker.data.info as SelectedPractice;

        //     html.push(marker.latitude.toString());
        //   } else if (marker.type === "Appraiser") {
        //     // const appraiser = marker.data.info as SelectedAppraiser;

        //     html.push(marker.latitude.toString());
        //   }

        //   html.push("</div>");

        //   popup.setOptions({
        //     position: [marker.longitude, marker.latitude],
        //     content: html.join(""),
        //   });

        //   popup.open(map);
        // });

        map.events.add("mouseout", htmlMarker, () => {
          popup.close();
        });
      });

      const bounds = atlas.data.BoundingBox.fromPositions(
        markers.map((m) => [m.longitude, m.latitude])
      );

      console.info(bounds);

      map.setCamera({
        center: center,
        zoom: 15,
      });
    });
  }, [map, atlas, practice]);

  return (
    <MapContainerWrapper className="open">
      <MapBaseWrapper id={wrapperId}></MapBaseWrapper>
    </MapContainerWrapper>
  );
};

export default AppraisalMap;
