import moment, { Moment } from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import dekra from "../../style/dekra";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { SelectPair } from "../../types/common.types";
import { Select } from "antd";

// Styled components

const AgendaBaseWrapper = styled.div``;

const CalendarWrapper = styled.div<{
  dayMinHeight?: string;
  isDayClickable?: boolean;
}>`
  border: 2px solid #777;
  border-radius: 0.5em;
  overflow: hidden;

  table {
    border-collapse: collapse;

    thead {
      td {
        border-top: none;
        background-color: #eee;
        color: #333;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }

    tbody {
      td {
        padding: 0;
      }

      tr:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

  td {
    border: 2px solid #777;
    vertical-align: top;
  }

  td:first-child {
    border-left: none;
  }

  td:last-child {
    border-right: none;
  }

  .days-of-the-week td {
    font-size: 0.9em;
    width: 8em;
    padding: 0.25em;
    text-align: center;
  }

  .current-month,
  .past-month,
  .next-month {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${(props) => (props.dayMinHeight ? props.dayMinHeight : "3em")};
    position: relative;
    padding: 0.15em 0.2em;

    &.selected {
      border: 5px solid #555;
    }
  }

  .selected-day {
    border: 5px solid #777;
  }

  .day-num {
    position: realative;
    top: 1.8em;
    left: 1em;
    cursor: ${(props) => (props.isDayClickable ? "pointer" : "default")};
  }

  .past-month,
  .next-month {
    .day-num {
      cursor: default;
      color: #999;
    }
  }
`;

const MonthNavigator = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1em;

  .back,
  .forward {
    font-size: 2em;
    line-height: 1em;
    cursor: pointer;
  }

  .month-year {
    font-size: 1.2em;
    line-height: 1em;
    display: flex;
    gap: 2em;

    align-items: center;
    justify-content: center;

    .ant-select-selector {
      border: none !important;
      outline: none !important;

      .ant-select-selection-item {
        font-size: 1.2em;
        text-transform: uppercase;
      }
    }
  }
`;

export type DayContent = {
  day: Moment;
  content: React.ReactNode;
};

/**
 * AgendaBase component
 * @param   currentDate,
 * @param dayMinHeight
 * @param daysContent
 * @param onDayClick
 * @param onDateChange
 */
interface IAgendaBaseProps {
  dayMinHeight?: string;
  currentDate: Moment;
  daysContent: DayContent[];
  isDayClickable: boolean;
  onDayClick: (day: Moment) => void;
  onDateChange: (date: Moment) => void;
}

const AgendaBase = ({
  dayMinHeight,
  currentDate,
  daysContent,
  isDayClickable,
  onDayClick,
  onDateChange,
}: IAgendaBaseProps) => {
  // Common variables
  const { t } = useTranslation();

  const optionsMonths: SelectPair[] = useMemo(
    () =>
      Array.from({ length: 12 }, (_, i) => i).map((m) => ({
        value: m,
        label: t(`month-name-${m}`),
      })),
    []
  );

  const optionsYears: SelectPair[] = useMemo(
    () =>
      Array.from({ length: 10 }, (_, i) => i + moment().year() - 4).map(
        (m) => ({
          value: m,
          label: m.toString(),
        })
      ),
    []
  );

  const dayCells = useMemo(() => {
    if (!daysContent) return;

    const todayNum = currentDate.date();
    const month = currentDate.month();
    const year = currentDate.year();
    const daysInMonth = currentDate.daysInMonth();
    const firstDayOfWeek = moment([year, month]).weekday();

    const pastMonth = moment(currentDate).subtract(1, "months");
    const previuosMonth = pastMonth.month();
    const daysPreviousMonth = firstDayOfWeek == 0 ? 6 : firstDayOfWeek - 1;

    const futureMonth = moment(currentDate).add(1, "months");
    const nextMonth = futureMonth.month();
    const daysInPreviousMonth = moment([year, previuosMonth]).daysInMonth();
    let daysNextMonth = 42 - (daysInMonth + daysPreviousMonth);

    const _dayCells = [];

    // previous month's days
    for (
      let dayNum = daysInPreviousMonth - daysPreviousMonth + 1;
      dayNum <= daysInPreviousMonth;
      dayNum++
    ) {
      const day = moment([pastMonth.year(), pastMonth.month(), dayNum]);
      const content = daysContent.find((d) => {
        return d.day.isSame(day);
      })?.content;

      _dayCells.push(
        <div
          key={`past-${dayNum}`}
          className={`past-month ${
            dayNum === todayNum && month === previuosMonth ? "selected" : ""
          }`}
        >
          <div className="day-num" onClick={() => onDayClick(day)}>
            {dayNum}
          </div>
          <div className="day-content">{content}</div>
        </div>
      );
    }

    // current month's days
    for (let dayNum = 1; dayNum <= daysInMonth; dayNum++) {
      const day = moment([year, month, dayNum]);
      const content = daysContent.find((d) => {
        return d.day.isSame(day);
      })?.content;

      _dayCells.push(
        <div
          key={`current-${dayNum}`}
          className={`current-month ${dayNum === todayNum ? "selected" : ""}`}
        >
          <div className="day-num" onClick={() => onDayClick(day)}>
            {dayNum}
          </div>
          <div className="day-content">{content}</div>
        </div>
      );
    }

    // next month's days
    for (let dayNum = 1; dayNum <= daysNextMonth; dayNum++) {
      const day = moment([futureMonth.year(), futureMonth.month(), dayNum]);
      const content = daysContent.find((d) => d.day.isSame(day))?.content;

      _dayCells.push(
        <div key={`next-${dayNum}`} className={`next-month`}>
          <div className="day-num" onClick={() => onDayClick(day)}>
            {dayNum}
          </div>
          <div className="day-content">{content}</div>
        </div>
      );
    }

    return _dayCells;
  }, [currentDate, daysContent, onDayClick, onDateChange]);

  // Event handlers
  const handleGoToPreviousMonth = () => {
    const previousMonth = moment(currentDate);
    previousMonth.subtract(1, "months");
    onDateChange(previousMonth);
  };

  const handleGoToNextMonth = () => {
    const nextMonth = moment(currentDate);
    nextMonth.add(1, "months");
    onDateChange(nextMonth);
  };

  const renderDaysRow = (rowNum: number) => {
    const daysRow = [];
    const startDay = rowNum * 7;
    const endDay = startDay + 7;

    for (let i = startDay; i < endDay; i++) {
      daysRow.push(<td key={`${rowNum}-${i}`}>{dayCells![i]}</td>);
    }

    return daysRow;
  };

  const renderDaysRows = () => {
    const rows = [];

    for (let i = 0; i < 6; i++) {
      rows.push(<tr key={i}>{renderDaysRow(i)}</tr>);
    }

    return rows;
  };

  const handleChangeMonth = (value: number, type: string) => {
    if (type === "month") {
      const currentYear = currentDate.year();
      const newDate = moment([currentYear, value, 1]);
      onDateChange(newDate);
    } else if (type === "year") {
      const currentMonth = currentDate.month();
      const newDate = moment([value, currentMonth, 1]);
      onDateChange(newDate);
    }
  };

  return (
    <AgendaBaseWrapper>
      <MonthNavigator>
        <div className="back" onClick={handleGoToPreviousMonth}>
          <MdKeyboardDoubleArrowLeft />
        </div>
        <div className="month-year">
          <Select
            options={optionsMonths}
            onChange={(v) => handleChangeMonth(v, "month")}
            value={currentDate.month()}
            style={{
              border: "none",
              appearance: "none",
              MozAppearance: "none",
              WebkitAppearance: "none",
            }}
          />
          <Select
            options={optionsYears}
            onChange={(v) => handleChangeMonth(v, "year")}
            value={currentDate.year()}
          />
        </div>
        <div className="forward" onClick={handleGoToNextMonth}>
          <MdKeyboardDoubleArrowRight />
        </div>
      </MonthNavigator>
      <CalendarWrapper
        dayMinHeight={dayMinHeight}
        isDayClickable={isDayClickable}
      >
        <table>
          <thead>
            <tr className="days-of-the-week">
              <td>{t("day-of-the-week-mon")}</td>
              <td>{t("day-of-the-week-tue")}</td>
              <td>{t("day-of-the-week-wed")}</td>
              <td>{t("day-of-the-week-thu")}</td>
              <td>{t("day-of-the-week-fri")}</td>
              <td>{t("day-of-the-week-sat")}</td>
              <td>{t("day-of-the-week-sun")}</td>
            </tr>
          </thead>
          <tbody>{renderDaysRows()}</tbody>
        </table>
      </CalendarWrapper>
    </AgendaBaseWrapper>
  );
};

export default AgendaBase;
