import { Modal } from "antd";
import styled from "styled-components";
import { ButtonsWrapper } from "../../../../../../MenuContent/UserManagement/style/ButtonsWrapper";
import { ButtonCancel, ButtonConfirm } from "../../../../../../Layout/Buttons";
import { useTranslation } from "react-i18next";
import useRejectPracticeForm from "./useRejectPracticeForm";
import RejectPracticeForm from "./RejectPracticeForm";
import { RejectionRequest } from "../../../../../../../redux/apiSpecifications/apiCrud";

const ModalContent = styled.div`
    padding: 2em;
    .ant-form-item-explain {
        color: red !important;
    }
`;

interface IRejectPracticeModalProps {
    onCancel: () => void;
    onConfirm: (rejectRequest: RejectionRequest) => void;
}

const RejectPracticeModal = ({
    onCancel,
    onConfirm,
}: IRejectPracticeModalProps) => {
    const { t } = useTranslation();

    const { data, canSubmit, status, allRejectionReasons, handleDataChange } =
        useRejectPracticeForm();

    const handleOnReject = () => {
        const rejectRequest: RejectionRequest = {
            rejectionReasonCode: data.rejectionReasonCode,
            rejectionReasonNote: data.rejectionReasonNote,
        };

        onConfirm(rejectRequest);
    };

    return (
        <Modal
            open={true}
            footer={null}
            closable={false}
            onCancel={onCancel}
            width="800px"
        >
            <ModalContent>
                <>
                    {/* <UserSubjectForm model={data} onChange={handleDataChange} /> */}

                    <RejectPracticeForm
                        model={data}
                        allRejectionReasons={allRejectionReasons}
                        onChange={handleDataChange}
                    />

                    <ButtonsWrapper>
                        <ButtonCancel onClick={onCancel}>
                            {t("button-cancel")!}
                        </ButtonCancel>

                        <ButtonConfirm
                            onClick={handleOnReject}
                            disabled={!canSubmit}
                        >
                            {t("appraisals-conclude-practice-button-reject")!}
                        </ButtonConfirm>
                    </ButtonsWrapper>
                </>
            </ModalContent>
        </Modal>
    );
};

export default RejectPracticeModal;
