import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DocProcessingAlert } from "../../../../../../redux/apiSpecifications/apiCrud";

// Styled components
const LoadADZWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3em;

  .icon-label {
    margin-top: 0.25em;
    font-size: 0.9em;
    text-transform: uppercase;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;

  .icon-and-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  img {
    width: 48px;
    cursor: pointer;
  }
`;

const ErrorsWarningsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1em;

  .title {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    padding: 0.5em 0;
  }

  .errors-wrapper {
    background-color: #eaf971;
  }

  .warnings-wrapper {
    background-color: #ddd;
  }

  .message-wrapper {
    background-color: orange;
    color: #fff;
  }
`;

/**
 * @desccription
 * @interface ILoadAdzProps
 * @property {DocProcessingAlert[]} alerts - list of alerts
 * @property {string} message - generic error message
 */

interface IADZALertsProps {
  alerts?: DocProcessingAlert[];
  message?: string;
}

/**
 * ADZALerts component
 *
 * @component
 * @example
 * <ADZALerts alerts={[]} />
 */
const ADZALerts: React.FC<IADZALertsProps> = ({ alerts, message }) => {
  // COMMON VARIABLES
  const { t } = useTranslation();

  const { errors, warnings } = useMemo(() => {
    return {
      errors: alerts?.filter((alert) => alert.alertType === "error") || [],
      warnings: alerts?.filter((alert) => alert.alertType === "warning") || [],
    };
  }, [alerts]);

  if (errors.length === 0 && warnings.length === 0 && !message) return null;

  return (
    <ErrorsWarningsWrapper>
      {errors.length > 0 && (
        <div className="errors-wrapper">
          <div className="title">
            {t("appraisals-conclude-practice-load-adz-errors-title")}
          </div>
          <ul>
            {errors.map((error, i) => (
              <li key={i}>
                {t(
                  `appraisals-conclude-practice-load-adz-error-${error.alertCode}`
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      {warnings.length > 0 && (
        <div className="warnings-wrapper">
          <div className="title">
            {t("appraisals-conclude-practice-load-adz-warnings-title")}
          </div>
          <ul>
            {warnings.map((warning, i) => (
              <li key={i}>
                {t(
                  `appraisals-conclude-practice-load-adz-warning-${warning.alertCode}`
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      {(message?.length || 0) > 0 && (
        <div className="message-wrapper">
          <div className="title">{message}</div>
        </div>
      )}
    </ErrorsWarningsWrapper>
  );
};

export default ADZALerts;
