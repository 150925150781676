const authority =
  "https://login.microsoftonline.com/cf81581f-cf8c-405d-97e3-34a295c8d882";

export default {
  local: {
    urls: ["localhost"],
    scopes: {
      scopes: ["api://57be54a4-2aa7-4132-8a38-b40062623df9/Tpa.ApiAccess"],
    },
    clientId: "58a9b751-4850-4fe3-b989-13e880fb7c8e",
    authority,
    color: "red",
    maps: {
      subscriptionKey: "i1JV7oPwVYa_ecc2runyjW8A8oYg8n-2q04b60smogI",
      baseUrl: "https://atlas.microsoft.com/search/address/json",
      apiVersion: "1.0",
      defaultLanguage: "it",
      defaultLimit: 10,
    },
  },
  sandbox: {
    urls: ["https://tpa-sanbox-apim.azure-api.net"],
    scopes: {
      scopes: ["api://57be54a4-2aa7-4132-8a38-b40062623df9/Tpa.ApiAccess"],
    },
    clientId: "58a9b751-4850-4fe3-b989-13e880fb7c8e",
    authority,
    color: "blue",
    maps: {
      subscriptionKey: "i1JV7oPwVYa_ecc2runyjW8A8oYg8n-2q04b60smogI",
      baseUrl: "https://atlas.microsoft.com/search/address/json",
      apiVersion: "1.0",
      defaultLanguage: "it",
      defaultLimit: 10,
    },
  },
  dev: {
    urls: ["https://tpa-dev.dekra.com", "https://claient-dev.dekra.com"],
    scopes: {
      scopes: ["api://b66084bc-d128-4c89-a8b4-61c770b3e2a0/Tpa.ApiAccess"],
    },
    clientId: "6eec0b72-9a53-4d9c-bae9-65c856fb7577",
    authority,
    color: "yellow",
    maps: {
      subscriptionKey: "i1JV7oPwVYa_ecc2runyjW8A8oYg8n-2q04b60smogI",
      baseUrl: "https://atlas.microsoft.com/search/address/json",
      apiVersion: "1.0",
      defaultLanguage: "it",
      defaultLimit: 10,
    },
  },
  test: {
    urls: ["https://tpa-test.dekra.com", "https://claient-test.dekra.com"],
    scopes: {
      scopes: ["api://22c6d4e9-7e0c-407c-af46-b076172bcd81/Tpa.ApiAccess"],
    },
    clientId: "54d4a58c-7c9f-403e-a850-01f82280e61b",
    authority,
    color: "green",
    maps: {
      subscriptionKey: "4UC-nKDIaYvDaXKzSARlgToq-rVkWMusCZ0-PqeCLRo",
      baseUrl: "https://atlas.microsoft.com/search/address/json",
      apiVersion: "1.0",
      defaultLanguage: "it",
      defaultLimit: 10,
    },
  },
  prod: {
    urls: ["https://tpa.dekra.com", "https://claient.dekra.com"],
    scopes: {
      scopes: ["api://dded4f55-ad12-4d7d-bff6-2a79b1104575/Tpa.ApiAccess"],
    },
    clientId: "fc8fa18c-2fd7-4fe2-ba4d-80847a4ef244",
    authority,
    color: "",
    maps: {
      subscriptionKey: "aR6cQXnZvf_sYf8PTBRgS8xvW4FF3tgeYbzm8ObsWvQ",
      baseUrl: "https://atlas.microsoft.com/search/address/json",
      apiVersion: "1.0",
      defaultLanguage: "it",
      defaultLimit: 10,
    },
  },
};
