import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  DailyAgenda,
  District,
  DistrictAppraiserStatus,
  DistrictAppraiserStatuses,
  useSetAppraiserStatusOnDistrictsMutation,
} from "../../../../../redux/apiSpecifications/apiFesf";
import { useTranslation } from "react-i18next";
import { Checkbox, DatePicker, Input, Popconfirm, Tooltip } from "antd";
import { MdDoneAll, MdOutlineCancel } from "react-icons/md";
import { IconDelete } from "../../../../../config/icons";
import { SelectStyled } from "../../../../../style/Input";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import type { Moment } from "moment";
import moment from "moment";
import { SelectPair } from "../../../../../types/common.types";
import { RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { DayContent } from "../../../../Inputs/AgendaBase";
import { getAppraiserStatusSelectsOptions } from "./utils";
import { ModeType } from "./AppraiserAgendaAppraiserStatusNF";
import {
  setAppraiserActiveStatusValues,
  setAppraiserError,
  setAppraiserStatus,
  setValidation,
} from "../../../../../redux/features/appraisersSlice";
import { useAuthorization } from "../../../../../hooks/useAuthorization";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export type RangeValue = [string | null, string | null] | null;

// Styled components

const AppraiserAgendaInsertLeaveWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  gap: 1em 1em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "header header "
    "reason-range provinces-filter "
    "reason-range provinces-list ";

  .header-wrapper {
    grid-area: header;
    padding: 0.25em;
    border-radius: 5px;
    background-color: #eee;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9em;
  }
  .provinces-list-wrapper {
    grid-area: provinces-list;
    overflow-y: auto;
    padding: 0 0 0 1em;
    margin-left: 1em;
  }

  .province-filter {
    grid-area: provinces-filter;
    padding: 2.2em 1em 0 0;
    position: relative;
    display: flex;
    flex-direction: row;

    .clear-filter-provinces {
      position: relative;
      cursor: pointer;
      font-size: 1.6em;
      margin-left: 0.5em;
      top: 0.2em;
    }

    .clear-selected-provinces,
    .select-all-provinces {
      font-size: 1.6em;
      cursor: pointer;
      margin-left: 0.25em;
      position: relative;
      top: 0.2em;
    }
  }

  .reason-range-column {
    grid-area: reason-range;

    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-left: 1em;
  }

  .date-wrapper {
    margin-bottom: 0.5em;

    .range-label {
      text-transform: uppercase;
      font-size: 0.9em;
      letter-spacing: 1px;
      margin-bottom: 0.5em;
    }
  }

  .reason-wrapper {
    gap: 1em;
    .ant-form-item-row {
      flex-direction: column;
    }

    .ant-form-item-label {
      text-align: left;
    }
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    gap: 1em;

    .already-taken-range-message {
      width: 100%;
      color: red;
    }
  }
`;

const today = moment();

export type ProvinceSelection = District & { selected: boolean };

export type AppraiserStatusValues = {
  provinces: string[] | undefined;
  range: RangeValue | undefined;
  status: string | undefined;
  statusCode?: string | undefined;
};

interface IAppraiserAgendaAppraiserStatusProps {
  idAppraiser: number | undefined;
  allProvinces: District[];
  agenda: DailyAgenda | undefined;
  minDate: Moment | undefined;
  daysContent: DayContent[];
  readonly?: boolean;
  reloadAgendaMonth: () => void;
}

const AppraiserAgendaAppraiserStatusLeave = ({
  idAppraiser,
  allProvinces,
  agenda,
  minDate,
  daysContent = [],
  readonly,
  reloadAgendaMonth,
}: IAppraiserAgendaAppraiserStatusProps) => {
  const { t } = useTranslation();
  const { getAuthorization } = useAuthorization();
  const dispatch = useDispatch();

  const activeRole = useSelector((state: RootState) => state.user.activeRole);
  const [saveAppraiserStatuses] = useSetAppraiserStatusOnDistrictsMutation();

  const appraiserData = useSelector((state: RootState) =>
    state.appraisers.appraisers.find((a) => a.id === idAppraiser)
  );

  const { myAgenda } = appraiserData || {};

  const {
    defaultStatusValues: defaultValues,
    activeStatusValues,
    districts,
    days,
    selectedProvince,
  } = myAgenda || {};

  const {
    status: appraiserStatus,
    statusCode: appraiserStatusCode,
    range: rangeDate,
    provinces: provincesSelection,
  } = activeStatusValues || {};

  const isAppraiser = () => ["E2", "E3"].includes(activeRole || "");

  const [visibleProvinces, setVisibleProvinces] = React.useState<
    ProvinceSelection[]
  >(
    provincesSelection ||
      districts?.map((d) => ({ ...d, selected: false })) ||
      []
  );

  const [provinceFilter, setProvinceFilter] = useState<string>("");
  const [originalProvinceSelection, setOriginalProvinceSelection] =
    useState<ProvinceSelection[]>();

  const [mode, setMode] = useState<ModeType>("new");

  const [rangeIsAlreadySet, setRangeIsAlreadySet] = useState<boolean>(false);
  const [reasonsOptions, setReasonsOptions] = useState<SelectPair[]>([]);
  const [poconfirmSave, setPoconfirmSave] = useState<boolean>(false);

  useEffect(() => {
    if (appraiserStatus == "" || rangeDate == null || rangeDate[0] == null)
      setRangeIsAlreadySet(false);
  }, [appraiserStatus, rangeDate]);

  useEffect(() => {
    let provinces: ProvinceSelection[] = [];
    if ((provincesSelection?.length || 0) > 0) {
      provinces =
        provincesSelection?.filter((p) =>
          p.districtName?.toLowerCase().includes(provinceFilter.toLowerCase())
        ) || [];
    } else if (districts?.length || 0 > 0) {
      provinces = districts?.map((d) => ({ ...d, selected: false })) || [];

      dispatch(
        setAppraiserActiveStatusValues({
          id: appraiserData!.id,
          range: rangeDate,
          provinces: provinces || [],
          status: appraiserStatus || "",
          statusCode: appraiserStatusCode || "",
          isUpdate: false,
        })
      );
    }

    setVisibleProvinces(provinces || []);
  }, [provincesSelection, provinceFilter, districts]);

  useEffect(() => {
    if (!defaultValues || !appraiserData) return;

    const newProvincesSelection = allProvinces.map((p) => {
      const selected = defaultValues?.provinces?.includes(p.districtCode || "");
      return { ...p, selected: selected || false };
    });

    let newMode: ModeType = "new";

    // revert previous comments
    if ((defaultValues?.provinces?.length || 0) > 0) {
      if (defaultValues?.status === "leave") newMode = "modify-leave";
      if (defaultValues?.status === "saint") newMode = "modify-saint";
      if (defaultValues?.status === "illness") newMode = "modify-illness";
    }

    setMode(newMode);

    let newStatus = newMode === "new" ? "" : (newMode as string);
    if (defaultValues?.provinces?.length === 0) newStatus = "";

    dispatch(
      setAppraiserActiveStatusValues({
        id: appraiserData!.id,
        range: defaultValues?.range!,
        provinces: newProvincesSelection!,
        status: newStatus,
        statusCode: defaultValues?.statusCode!,
        isUpdate: false,
      })
    );

    setOriginalProvinceSelection(newProvincesSelection);
  }, [defaultValues]);

  const selectedOptions = useMemo(
    () => getAppraiserStatusSelectsOptions(t),
    []
  );

  useEffect(() => {
    let newReasonOptions = selectedOptions.defaultSelectOptions;

    if (defaultValues?.status === "leave" && appraiserStatus === "modify-leave")
      newReasonOptions = [...newReasonOptions, ...selectedOptions.leaveOptions];
    else if (
      defaultValues?.status === "saint" &&
      appraiserStatus === "modify-saint"
    )
      newReasonOptions = [...newReasonOptions, ...selectedOptions.saintOptions];
    else if (
      defaultValues?.status === "illness" &&
      appraiserStatus === "modify-illness"
    )
      newReasonOptions = [
        ...newReasonOptions,
        ...selectedOptions.illnessOptions,
      ];

    setReasonsOptions(newReasonOptions);
  }, [defaultValues, mode, appraiserStatus]);

  const canSave = useMemo(() => {
    let isValid = true;

    if ((provincesSelection?.filter((p) => p.selected)?.length || 0) < 1)
      isValid = false;

    if (appraiserStatus === "add-saint") {
      isValid = isValid && (rangeDate?.[0] ? true : false);

      // check if the user is trying to add leaves over illnes or viceversa
      let _rangeIsAlreadySet = false;
      if (isValid) {
        const _dayContent = daysContent.find((d) =>
          d.day.isSame(rangeDate?.[0], "day")
        );

        if (_dayContent?.content != null) _rangeIsAlreadySet = true;

        setRangeIsAlreadySet(_rangeIsAlreadySet);
      }
    }

    if (["add-illness", "add-leave"].includes(appraiserStatus || "")) {
      isValid = isValid && (rangeDate?.[0] && rangeDate?.[1] ? true : false);

      // check if the user is trying to add leaves over illnes or viceversa
      let _rangeIsAlreadySet = false;
      if (isValid) {
        let currentDay = moment(rangeDate?.[0]);
        while (currentDay.isSameOrBefore(rangeDate?.[1])) {
          const _dayContent = daysContent.find((d) =>
            d.day.isSame(currentDay, "day")
          );

          if (_dayContent?.content != null) {
            _rangeIsAlreadySet = true;
            currentDay = moment(rangeDate?.[1]).add(1, "days"); // break the loop
          } else currentDay.add(1, "days");
        }

        setRangeIsAlreadySet(_rangeIsAlreadySet);
      }
    }

    if (["remove-illness", "remove-leave"].includes(appraiserStatus || "")) {
      setRangeIsAlreadySet(false);
    }

    if (["modify-illness", "modify-leave"].includes(appraiserStatus || "")) {
      const haveValues =
        rangeDate && rangeDate[0] && rangeDate[1] ? true : false;
      if (!haveValues) isValid = false;

      const startDate = rangeDate?.[0];
      const endDate = rangeDate?.[1];
      const originalStartDate = defaultValues?.range
        ? defaultValues?.range[0]
        : undefined;
      const originalEndDate = defaultValues?.range
        ? defaultValues?.range[1]
        : undefined;

      const sameProvinces =
        JSON.stringify(provincesSelection?.filter((p) => p.selected).sort()) ===
        JSON.stringify(
          originalProvinceSelection?.filter((p) => p.selected).sort()
        );

      isValid =
        !(startDate == originalStartDate) ||
        !(endDate == originalEndDate) ||
        !sameProvinces
          ? true
          : false;
    }

    return isValid;
  }, [
    appraiserStatus,
    appraiserStatusCode,
    rangeDate,
    provincesSelection,
    originalProvinceSelection,
  ]);

  const onChangeProvinceSelection = (districtCode: string) => {
    const provinces = provincesSelection?.map((p) =>
      p.districtCode === districtCode ? { ...p, selected: !p.selected } : p
    );

    handleChangeAppraiserProvinces(provinces || []);

    if (
      ["add-leave", "add-illness", "add-saint"].includes(appraiserStatus || "")
    ) {
    } else {
      setMode("new");
    }
  };

  const handleClearProvincesSelection = () => setAllProvincesTo(false);

  const handleSelectAllProvinces = () => setAllProvincesTo(true);

  const setAllProvincesTo = (selected: boolean) => {
    const provinces = allProvinces.map((province) => ({
      ...province,
      selected,
    }));
    handleChangeAppraiserProvinces(provinces);
  };

  const handleDisableRangeDates = (current: Moment) => {
    let disableDay: boolean = false;

    if (
      ["modify-leave", "modify-illness"].includes(mode) ||
      ["add-leave"].includes(appraiserStatus || "")
    )
      disableDay = current < today;

    const strCurrent = current.format("DD/MM/YYYY");
    const _day = days?.find((d) => d.date == strCurrent);

    if (!_day) return false;

    const _selectedProvinces = provincesSelection?.filter((p) => p.selected);

    if (
      _day.onIllnessDistricts?.find(
        (d) =>
          _selectedProvinces?.find((sp) => sp.districtCode == d.districtCode) !=
          null
      )
    )
      return true;
    if (
      _day.onLeaveDistricts?.find(
        (d) =>
          _selectedProvinces?.find((sp) => sp.districtCode == d.districtCode) !=
          null
      )
    )
      return true;
    if (
      _day.onPatronSaintDistricts?.find(
        (d) =>
          _selectedProvinces?.find((sp) => sp.districtCode == d.districtCode) !=
          null
      )
    )
      return true;

    return false;
  };

  const handleChangeStatus = (value: string) => {
    let newRange = activeStatusValues?.range;

    if (["add-leave", "add-illness", "add-saint"].includes(value)) {
      newRange = undefined;
      setAllProvincesTo(false);
    }

    dispatch(
      setAppraiserActiveStatusValues({
        id: appraiserData!.id,
        range: newRange,
        provinces: activeStatusValues?.provinces || [],
        status: value,
        statusCode: activeStatusValues?.statusCode || "",
        isUpdate: true,
      })
    );
  };

  const handleChangeAppraiserRange = (value: RangeValue) => {
    dispatch(
      setAppraiserActiveStatusValues({
        id: appraiserData!.id,
        range: value,
        provinces: activeStatusValues?.provinces || [],
        status: activeStatusValues?.status || "",
        statusCode: activeStatusValues?.statusCode || "",
        isUpdate: true,
      })
    );
  };

  const handleChangeAppraiserProvinces = (value: ProvinceSelection[]) => {
    dispatch(
      setAppraiserActiveStatusValues({
        id: appraiserData!.id,
        range: activeStatusValues?.range,
        provinces: value,
        status: activeStatusValues?.status || "",
        statusCode: activeStatusValues?.statusCode || "",
        isUpdate: true,
      })
    );
  };

  const handleSaveAgenda = async () => {
    setPoconfirmSave(false);

    const defaultStatusValues = appraiserData?.myAgenda?.defaultStatusValues;

    const { provinces, range, status, statusCode } =
      appraiserData?.myAgenda?.activeStatusValues || {};

    dispatch(setAppraiserStatus({ id: idAppraiser!, status: "saving" }));

    let type = "";
    let suspensionReason = "";
    let originalSuspensionReason = "";
    let appraiserStatusCode = "";

    switch (status) {
      case "add-leave":
        type = "N";
        suspensionReason = "FGE";
        appraiserStatusCode = "F";
        break;
      case "modify-leave":
        type = "U";
        suspensionReason = "FGE";
        appraiserStatusCode = "F";
        break;
      case "remove-leave":
        type = "D";
        suspensionReason = "";
        appraiserStatusCode = "F";
        break;
      case "add-saint":
        type = "N";
        suspensionReason = "FSP";
        appraiserStatusCode = "F";
        break;
      case "modify-saint":
        type = "U";
        suspensionReason = "FSP";
        appraiserStatusCode = "F";
        break;
      case "remove-saint":
        type = "D";
        suspensionReason = "FSP";
        appraiserStatusCode = "F";
        break;
      case "add-illness":
        type = "N";
        suspensionReason = "SMP";
        appraiserStatusCode = "S";
        break;
      case "modify-illness":
        type = "U";
        suspensionReason = "SMP";
        appraiserStatusCode = "S";
        break;
      case "remove-illnes":
        type = "D";
        suspensionReason = "";
        appraiserStatusCode = "S";
        break;
    }

    let originalDistrictAppraiserStatuses: DistrictAppraiserStatus[] = [];
    let newDistrictAppraiserStatuses: DistrictAppraiserStatus[] = [];

    // if its a change (modify or remove) we need to send the original values
    if (
      status === "modify-leave" ||
      status === "modify-saint" ||
      status === "modify-illness" ||
      status === "remove-leave" ||
      status === "remove-saint" ||
      status === "remove-illnes"
    ) {
      originalDistrictAppraiserStatuses =
        defaultStatusValues!.provinces?.map(
          (p) =>
            ({
              districtCode: p,
              validityStartDate: defaultStatusValues!.range![0] || "",
              validityEndDate: defaultStatusValues!.range![1] || "",
              suspensionReason: originalSuspensionReason,
              appraiserStatusCode,
              description: "",
            } as DistrictAppraiserStatus)
        ) || [];
    }

    // we have the new values only if its not a remove action
    if (
      status !== "remove-leave" &&
      status !== "remove-saint" &&
      status !== "remove-illnes"
    ) {
      newDistrictAppraiserStatuses = provinces!
        .filter((p) => p.selected)
        .map((p) => ({
          districtCode: p.districtCode?.toUpperCase() || "",
          validityStartDate: range![0] || "",
          validityEndDate:
            suspensionReason === "FSP" ? range![0] || "" : range![1] || "",
          suspensionReason,
          reasonCode: statusCode,
          appraiserStatusCode,
          description: "",
        }));
    }

    const districtAppraiserStatuses: DistrictAppraiserStatuses = {
      originalDistrictAppraiserStatuses,
      newDistrictAppraiserStatuses,
    };

    const response = await saveAppraiserStatuses({
      authorization: await getAuthorization(),
      appraiserid: idAppraiser!,
      districtAppraiserStatuses,
    });

    const errorResponse = response as {
      error: FetchBaseQueryError | SerializedError;
    };

    if (errorResponse?.error) {
      dispatch(
        setAppraiserError({
          id: idAppraiser!,
          message: "Si è verificato un errore durante il salvataggio",
        })
      );
    } else {
      dispatch(
        setAppraiserActiveStatusValues({
          id: idAppraiser!,
          range: undefined,
          provinces:
            provincesSelection?.map((p) => ({ ...p, selected: false })) || [],
          status: "",
          statusCode: "",
          isUpdate: false,
        })
      );
      await reloadAgendaMonth();
    }
  };

  const disableProvincesCheckbox =
    !appraiserStatus ||
    appraiserStatus == "" ||
    [
      "modify-leave",
      "modify-illness",
      "modify-saint",
      "modify-suspension",
      "modify-blocked",
      "modify-closed",
      "remove-leave",
      "remove-illness",
      "remove-saint",
      "remove-suspension",
      "remove-blocked",
      "remove-closed",
    ].includes(appraiserStatus || "");

  const disableDatesRange =
    readonly ||
    (provincesSelection?.filter((ps) => ps.selected).length || 0) < 1 ||
    [
      "",
      "remove-leave",
      "remove-illness",
      "remove-suspension",
      "remove-blocked",
    ].includes(appraiserStatus || "");

  const displaySingleDate = [
    "add-saint",
    "modify-saint",
    "remove-saint",
    "add-closed",
    "modify-closed",
    "remove-closed",
  ].includes(appraiserStatus || "");

  const displayRange = !displaySingleDate;

  // const disableProvincesCheckbox =
  //   // it's a modify
  //   ["", "modify-leave", "modify-illness"].includes(appraiserStatus || "") ||
  //   // it's a  remove
  //   ["", "remove-leave", "remove-illness"].includes(appraiserStatus || "") ||
  //   // it'a and add but we don't have a range selected
  //   (["add-leave", "add-illness"].includes(appraiserStatus || "") &&
  //     (rangeDate === undefined ||
  //       rangeDate?.[0] == undefined ||
  //       rangeDate?.[1] == undefined)) ||
  //   // it's an add and we don't have a date selected
  //   (["add-saint"].includes(appraiserStatus || "") &&
  //     (rangeDate === undefined || rangeDate?.[0] == undefined));

  const headerText =
    defaultValues?.status === "illness"
      ? t("agenda-send-header-illness")
      : defaultValues?.status === "leave"
      ? t("agenda-send-header-leave")
      : defaultValues?.status === "saint"
      ? t("agenda-send-header-saint")
      : t("agenda-send-header-new");

  const showRangeValue = appraiserStatus && appraiserStatus !== "" && rangeDate;

  return (
    <>
      <AppraiserAgendaInsertLeaveWrapper>
        <div className="header-wrapper">{headerText}</div>
        <div className="province-filter">
          <Input
            value={provinceFilter}
            onChange={(e) => setProvinceFilter(e.target.value)}
            placeholder={t("agenda-clear-provinces-filter-placeholder")!}
          />

          <Tooltip title={t("agenda-clear-provinces-filter")!}>
            <div
              className="clear-filter-provinces"
              onClick={() => setProvinceFilter("")}
            >
              <MdOutlineCancel />
            </div>
          </Tooltip>

          <Tooltip title={t("agenda-clear-selected-provinces")!}>
            <div
              className="clear-selected-provinces"
              onClick={handleClearProvincesSelection}
            >
              <IconDelete />
            </div>
          </Tooltip>

          <Tooltip title={t("agenda-select-all-provinces")!}>
            <div
              className="select-all-provinces"
              onClick={handleSelectAllProvinces}
            >
              <MdDoneAll />
            </div>
          </Tooltip>
        </div>

        <div className="provinces-list-wrapper">
          {visibleProvinces?.map((p, index) => {
            return (
              <div key={index}>
                <Checkbox
                  checked={
                    provincesSelection?.find(
                      (ps) => ps.districtCode === p.districtCode
                    )?.selected
                  }
                  onChange={() => onChangeProvinceSelection(p.districtCode!)}
                  disabled={readonly || disableProvincesCheckbox}
                >
                  {p.districtName}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className="reason-range-column">
          <div className="reason-wrapper">
            <SelectStyled
              onChange={handleChangeStatus}
              value={appraiserStatus}
              options={reasonsOptions}
              label={t("agenda-select-reason-label")}
              disabled={readonly}
            />
          </div>

          {displayRange && (
            <div className="date-wrapper">
              <div className="range-label">
                {t("agenda-select-date-range-label")}
              </div>
              <DatePicker.RangePicker
                style={{ width: "100%" }}
                placeholder={[
                  t("agenda-select-range-start")!,
                  t("agenda-select-range-end")!,
                ]}
                format={"DD/MM/YYYY"}
                onChange={(r) =>
                  handleChangeAppraiserRange([
                    r![0]!.format("DD/MM/YYYY") || null,
                    r![1]!.format("DD/MM/YYYY") || null,
                  ])
                }
                value={
                  showRangeValue
                    ? [
                        moment(rangeDate[0], "DD/MM/YYYY"),
                        moment(rangeDate[1], "DD/MM/YYYY"),
                      ]
                    : undefined
                }
                disabled={disableDatesRange}
                disabledDate={handleDisableRangeDates}
              />
            </div>
          )}

          {displaySingleDate && (
            <div className="date-wrapper">
              <div className="range-label">
                {t("agenda-select-date-saint-label")}
              </div>
              <DatePicker
                style={{ width: "100%" }}
                format={"DD/MM/YYYY"}
                placeholder={t("agenda-select-date-saint-placeholder")!}
                onChange={(d) =>
                  handleChangeAppraiserRange([
                    d?.format("DD/MM/YYYY") || null,
                    null,
                  ])
                }
                value={
                  showRangeValue
                    ? moment(rangeDate[0], "DD/MM/YYYY")
                    : undefined
                }
                disabled={disableDatesRange}
                disabledDate={handleDisableRangeDates}
              />
            </div>
          )}

          <div className="button-wrapper">
            {rangeIsAlreadySet && (
              <div className="already-taken-range-message">
                {t("agenda-already-taken-selection")}
              </div>
            )}

            <Popconfirm
              placement="top"
              icon={null}
              open={poconfirmSave}
              title={t("agenda-button-save-confirm")!}
              onConfirm={handleSaveAgenda}
              onCancel={() => setPoconfirmSave(false)}
              okText={t("switch-yes")!}
              cancelText={t("switch-no")!}
            >
              <ButtonConfirm
                onClick={() => setPoconfirmSave(true)}
                disabled={readonly || !canSave || rangeIsAlreadySet}
              >
                {t("agenda-button-save")!}
              </ButtonConfirm>
            </Popconfirm>
          </div>
        </div>
      </AppraiserAgendaInsertLeaveWrapper>
    </>
  );
};

export default AppraiserAgendaAppraiserStatusLeave;
