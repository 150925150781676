import { useTranslation } from "react-i18next";
import {
  Address,
  Contact,
} from "../../../../../redux/apiSpecifications/apiCrud";
import styled from "styled-components";
import { FormRow } from "../../../../../style/form";
import SubjectContactType from "../../../../Inputs/SelectSubjectContactType";
import {
  SubjectAddressDataType,
  SubjectContactDataType,
} from "../../../../../types/uses-data.types";
import SubjectContactUseCase from "../../../../Inputs/SelectSubjectContactUseCase";
import { ContactUseCase, SubjectType } from "../../../../../config/const";
import { IconDelete } from "../../../../../config/icons";
import { RowSpacer } from "../../../../../style/containers";
import { InputTextStyled, SwitchStyled } from "../../../../../style/Input";
import SubjectAddressType from "../../../../Inputs/SelectSubjectAddressType";
import InputAddress from "../../../../Inputs/Address";
import AddressAutocomplete from "../../../../AzureMapsAddress/AddressAutocomplete";
import { ContentWrapper } from "../../Commons/Wrappers";

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 900px;

  .title {
    text-transform: uppercase;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 0.25em 1em;
  }

  .data {
    padding: 1em 2em;
  }

  .delete-icon-button {
    font-size: 1.6em;
    color: red;
    svg {
      cursor: pointer;
    }
    margin: 0 0.5em -5px 0;
  }

  
  }
  
`;

interface IAppraiserDetailsAddressProps {
  address: Address | undefined;
  subjectType: SubjectType;
  index: number;
  onDelete: (index: number) => void;
  onChange: (contact: Contact, index: number) => void;
}

const AppraiserDetailsAddress = (props: IAppraiserDetailsAddressProps) => {
  const { t } = useTranslation();
  const { address, index, subjectType, onDelete, onChange } = props;

  const handleOnChange = (value: any, field: string) => {
    if (field === "address")
      onChange(
        { ...value, type: address?.type, preferred: address?.preferred },
        index
      );
    else {
      const updatedAddress = { ...address, [field]: value };
      onChange(updatedAddress, index);
    }
  };

  return (
    <AddressWrapper>
      <div className="header">
        <div className="title">
          {index + 1}° {t("edit-expertizer-management-addresses-address-title")}
        </div>
        <div className="delete-icon-button" onClick={() => onDelete(index)}>
          <IconDelete />
        </div>
      </div>
      <div className="data">
        <FormRow>
          <SubjectAddressType
            index={index || -1}
            onChange={(type) => handleOnChange(type.value, "type")}
            type={address?.type as SubjectAddressDataType}
            subjectType={subjectType}
            required={true}
          />

          <RowSpacer />

          <SwitchStyled
            label={t("switch-preferred-label")}
            checkedChildren={t("switch-yes")}
            unCheckedChildren={t("switch-no")}
            checked={address?.preferred}
            onChange={(preferred) => handleOnChange(preferred, "preferred")}
          />
        </FormRow>
        <FormRow>
          <AddressAutocomplete
            labelPlaceholder={t("subject-address-title")}
            address={address}
            onChange={(addr) => handleOnChange(addr, "address")}
          />
        </FormRow>
      </div>
    </AddressWrapper>
  );
};

export default AppraiserDetailsAddress;
