import { useTranslation } from "react-i18next";
import {
  AppraisalBranchCodeTypes,
  AppraisalTypeTypes,
} from "../../config/const";
import { SelectStyled } from "../../style/Input";
import { selectClaimType as testId } from "../../config/testIds";

const groupValues = (value: AppraisalBranchCodeTypes | undefined) => {
  const iValue = parseInt(value || "-1");
  if (iValue >= 2 && iValue <= 8) return "2";
  return value;
};

interface ISelectClaimTypeProps {
  classNameItem?: string;
  styleItem?: string;
  onChange: (val: AppraisalBranchCodeTypes) => void;
  value: AppraisalBranchCodeTypes | undefined;
  disabled?: boolean | undefined;
  dataTestId?: string;
}

const SelectClaimType = (props: ISelectClaimTypeProps) => {
  const { t } = useTranslation();

  return (
    <SelectStyled
      classNameItem={props.classNameItem}
      styleItem={props.styleItem}
      label={t("appraisals-details-tab-details-appraisals-claim-type-label")}
      onChange={(val) => props.onChange(val as AppraisalBranchCodeTypes)}
      //value={groupValues(props.value)}
      value={props.value}
      disabled={props.disabled}
      options={[
        {
          value: "1",
          label: t("appraisals-details-tab-details-appraisals-claim-type-rca"),
        },
        {
          value: "2",
          label: t(
            "appraisals-details-tab-details-appraisals-claim-type-cvt-vandalism"
          ),
        },
        {
          value: "3",
          label: t(
            "appraisals-details-tab-details-appraisals-claim-type-cvt-collision"
          ),
        },
        {
          value: "4",
          label: t(
            "appraisals-details-tab-details-appraisals-claim-type-cvt-natural events"
          ),
        },
        {
          value: "5",
          label: t(
            "appraisals-details-tab-details-appraisals-claim-type-cvt-partial-theft"
          ),
        },
        {
          value: "6",
          label: t(
            "appraisals-details-tab-details-appraisals-claim-type-cvt-total-theft"
          ),
        },
        {
          value: "7",
          label: t(
            "appraisals-details-tab-details-appraisals-claim-type-cvt-fire"
          ),
        },
        {
          value: "8",
          label: t(
            "appraisals-details-tab-details-appraisals-claim-type-cvt-kasko"
          ),
        },
        {
          value: "9",
          label: t(
            "appraisals-details-tab-details-appraisals-claim-type-cvt-cristalli"
          ),
        },
      ]}
      dataTestId={props.dataTestId || testId.container}
    />
  );
};

export default SelectClaimType;
